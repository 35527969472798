import React, { useState } from 'react';
import ToolTipBody from './ToolTipBody';
import useClickOutside from 'use-click-outside';

import SpaceBetween from 'components/elements/SpaceBetween';

export type Info = {
  label?: string;
  value: string;
};

interface ToolTipProps {
  title: string;
  infos: Array<Info>;
  icon?: string;
  asGrid?: boolean;
  classNames?: Array<string>;
  style?: Object;
  top?: boolean;
}

const ToolTip: React.FC<ToolTipProps> = ({
  title,
  infos,
  icon = 'info',
  top = false,
  asGrid,
  classNames,
  ...others
}) => {
  const ref = React.createRef<HTMLDivElement>();
  const [showToolTip, setShowToolTip] = useState(false);

  const onClose = () => {
    if (showToolTip) {
      setShowToolTip(false);
    }
  };

  useClickOutside(ref, onClose);

  const ListComponent = ({ children }) =>
    asGrid ? <div>{children}</div> : <SpaceBetween>{children}</SpaceBetween>;

  const component = () => {
    return (
      <>
        {title && <span>{title}</span>}
        <ul>
          {infos?.map((info) => (
            <li key={info.label}>
              <ListComponent>
                {info.label && <span>{info.label}:</span>}
                <span>{info.value}</span>
              </ListComponent>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <ToolTipBody
      asGrid={asGrid}
      top={top}
      component={component}
      classNames={classNames}
      icon={icon}
      {...others}
    />
  );
};
export default ToolTip;
