import { Provider, observer } from 'mobx-react';
import React from 'react';
import OpportunityFormComponent from 'opportunities/components/OpportunityFormComponent';
import OpportunityStore from '../stores/opportunityStore';

const OpportunityContainer = (opportunityArgs) => {
  return (
    <Provider opportunityStore={new OpportunityStore(opportunityArgs.fields)}>
      <OpportunityFormComponent {...opportunityArgs} />
    </Provider>
  );
};
export default observer(OpportunityContainer);
