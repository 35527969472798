import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import SampleTransportModel from 'samples/receive_samples/models/SampleTransport';
import Tile from 'shared/Tile';
import Errors from 'shared/Errors/components/Errors';
import ReceiveSampleTransportHeader from './ReceiveSampleTransportHeader';
import ReceiveSampleTransportTable from './ReceiveSampleTransportTable';

interface ReceiveSampleTransportRowProps {
  transport: SampleTransportModel;
}

function ReceiveSampleTransportRow({
  transport,
}: ReceiveSampleTransportRowProps): JSX.Element {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (transport.closed) {
      setCollapsed(true);
    }
  }, [transport.closed]);

  return (
    <Tile disabled={transport.closed}>
      <ReceiveSampleTransportHeader
        collapsed={collapsed}
        handleCollapsible={(collapsed) => setCollapsed(collapsed)}
        transport={transport}
      />

      <Errors errors={transport.errors} />
      <br />

      {!collapsed && <ReceiveSampleTransportTable transport={transport} />}
    </Tile>
  );
}

export default observer(ReceiveSampleTransportRow);
