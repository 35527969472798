import React, { useState } from 'react';
import { customFetch } from 'utils/fetch';

interface SubmitButtonProps {
  cancelPath?: string;
  submitEnabled?: boolean;
  submitPath: string;
  submitText: string;
  submitMethod?: 'POST' | 'PATCH' | 'DELETE';
  body: any;
  dataCy?: string;
  handleError?: (messages: Array<string>) => void;
}

const SubmitButtonsComponent: React.FC<SubmitButtonProps> = ({
  cancelPath,
  submitEnabled = true,
  submitText,
  submitPath,
  submitMethod,
  body,
  dataCy = '',
  handleError,
  ...rest
}) => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmitClick = () => {
    if (!submitted) {
      if (body) {
        setSubmitted(true);
        customFetch(submitPath, body, submitMethod)
          .then((response) => {
            if (response.success || (!response.success && response.path)) {
              window.location = response.path;
            } else {
              if (handleError) {
                handleError(response.errors);
                setSubmitted(false);
              }
            }
          })
          .catch((reason) => {
            // @TODO: think about a good solution for a e.g. 500server response, what we wanna do with it
            alert(
              `something went wrong, please reload the page and try again: ${reason}`
            );
          });
      } else {
        window.location.href = submitPath;
      }
    }
  };

  return (
    <>
      {(cancelPath && (
        <a className='button button--gray' href={cancelPath}>
          {I18n.translate('buttons.cancel')}
        </a>
      )) || <div />}
      <input
        className='button'
        type='submit'
        onClick={handleSubmitClick}
        disabled={!submitEnabled || submitted}
        value={submitText}
        data-cy={dataCy}
        {...rest}
      />
    </>
  );
};

export default SubmitButtonsComponent;
