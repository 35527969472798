import React from 'react';
import useStores from 'utils/useStores';
import SampleTableRow from 'cuppingTable/components/SampleTableRow';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  margin: 50px 0 20px;
`;

const SelectedSamples = (): JSX.Element => {
  const { cuppingTableStore } = useStores();
  return (
    <StyledWrapper>
      <h2 style={{ marginBottom: '20px' }}> Selected Samples</h2>
      <table>
        <tbody>
          {cuppingTableStore.selectedSamples.map((sample) => {
            return <SampleTableRow key={sample.id} sample={sample} />;
          })}
        </tbody>
      </table>
    </StyledWrapper>
  );
};

export default observer(SelectedSamples);
