import IExportDocument from '../types/IExportDocument';
import ExportDocumentType from 'exports/types/ExportDocumentType';
import { AccordionItemType } from 'shared/Accordion/components/AccordionWrapper';

function filterRequiredOnly(
  documents: ExportDocumentType,
  requiredDocuments: IExportDocument[]
): (AccordionItemType | undefined)[] {
  return [...documents.keys()]
    .filter((key) => requiredDocuments.includes(key))
    .map((key) => documents.get(key));
}

export default filterRequiredOnly;
