import React, { useEffect, useMemo } from 'react';

import InputGroupComponent from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';
import Grid from 'components/elements/Grid';
import { useFormContext } from 'react-hook-form';

export interface UnitsAndFractionsProps {
  capacity: number;
  units: number;
  fractionsConfigurable: boolean;
}

const UnitsAndFractions = ({
  capacity,
  units,
  fractionsConfigurable = true,
}: UnitsAndFractionsProps) => {
  const { register, watch, getValues } = useFormContext();

  const [fractionsInfo, setFractionsInfo] = React.useState(0);

  useEffect(() => {
    const fractions = parseFloat(getValues('fractions_amount'));
    let bags = 0;

    if (fractions && fractions > 0) {
      bags = Math.ceil(units / fractions) || 0;
    }
    setFractionsInfo(bags);
  }, [watch('fractions_amount')]);

  const unitInfo = useMemo(() => {
    let info = 0;

    if (units) {
      info = units * capacity;
    }

    return info;
  }, []);

  return (
    <Grid grid={13}>
      <div className='input-group'>
        <InputGroupComponent
          label={I18n.translate('production_orders.form.units_to_produce')}
          min={units}
          value={units || ''}
          append='#'
          info={`${unitInfo}kg`}
          placeholder='0'
          variant={VARIANT.INTEGER}
          dataCy='production_order_units'
          disabled
        />
      </div>

      <div className='input-group'>
        <InputGroupComponent
          label={I18n.translate('production_orders.form.fractions')}
          append='#'
          info={`${fractionsInfo} ${I18n.translate('production_orders.form.units')}`}
          placeholder='0'
          min={1}
          required={true}
          variant={VARIANT.INTEGER}
          readOnly={!fractionsConfigurable}
          name='production_order[fractions_amount]'
          ref={register}
        />
      </div>
    </Grid>
  );
};

export default UnitsAndFractions;
