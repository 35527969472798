import React from 'react';

import { ProducerOption } from 'types/model/producer';

import InputGroup from 'components/groups/InputGroup';
import Checkbox from 'shared/Checkbox';
import Search, { Option } from 'components/elements/Search';
import BaseGroupComponent from 'components/groups/BaseGroup';
import { customFetch } from 'utils/fetch';
import styled from 'styled-components';

interface IdOption {
  id: number;
  name: string;
}
interface CertificateOption {
  id: number;
  name: string;
  remaining_quota: null | string;
}

interface ProducerFormProps {
  producer_id: number | null;
  producers: Array<ProducerOption>;
  farm_id: number | null;
  certificate_id: number | null;
  bags_input_id: string;
  agreed_to_no_premium_price: boolean;
  editable: boolean;
}

interface ProducerGetResponse {
  farms: IdOption[];
  fiscal_id?: string;
  association?: string;
}

interface FarmGetResponse {
  certificates: CertificateOption[];
  complies_eudr: boolean;
}

interface ProducerFormState {
  producer_id?: number;
  farm_id?: number;
  farms: IdOption[];
  certificate_id?: number;
  certificates: CertificateOption[];
  complies_eudr: boolean;
  fiscal_id: string;
  association: string;
  farmCertificateQuotaReached: boolean;
  agreedToNoPremiumPrice: boolean;
  editable: boolean;
}

class ProducerForm extends React.Component<ProducerFormProps, ProducerFormState> {
  private static DEFAULT_STATE = {
    agreedToNoPremiumPrice: false,
    farmCertificateQuotaReached: false,
    association: '',
    certificate_id: undefined,
    certificates: [],
    complies_eudr: false,
    farm_id: undefined,
    farms: [],
    fiscal_id: '',
    producer_id: undefined,
    editable: true,
  };

  constructor(props: ProducerFormProps) {
    super(props);

    const { producer_id, farm_id, certificate_id, agreed_to_no_premium_price, editable } =
      this.props;

    this.state = {
      ...ProducerForm.DEFAULT_STATE,
      agreedToNoPremiumPrice: agreed_to_no_premium_price,
      farmCertificateQuotaReached: agreed_to_no_premium_price,
      certificate_id: certificate_id ?? undefined,
      farm_id: farm_id ?? undefined,
      producer_id: producer_id ?? undefined,
      editable,
    };

    if (producer_id) {
      this.populateProducerFields(producer_id);
    }
  }
  eudrLabel = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  private populateProducerFields = async (producerId: number): Promise<void> => {
    const { farms, fiscal_id, association } = await customFetch<ProducerGetResponse>(
      `/api/v1/producers/${producerId}`,
      undefined,
      'GET'
    );

    this.setState({
      farms,
      fiscal_id: fiscal_id || '',
      association: association || '',
    });

    if (farms.length > 0) {
      const farmId = this.state.farm_id || farms[0].id;

      this.setState({ farm_id: farmId }, () => this.populateFarmFields(farmId));
    } else {
      // Hint: Every producer should have a farm
    }
  };

  private populateFarmFields = async (farmId: number): Promise<void> => {
    const { certificates, complies_eudr } = await customFetch<FarmGetResponse>(
      `/api/v1/farms/${farmId}`,
      undefined,
      'GET'
    );

    this.setState({ complies_eudr: complies_eudr });

    this.setState({ certificates }, () => {
      if (certificates.length > 0) {
        let certificateId = this.state.certificate_id;

        if (this.state.editable) {
          certificateId = certificates[0].id;
        }

        this.setCertification(certificateId);
      } else {
        this.setState({
          farmCertificateQuotaReached: false,
          agreedToNoPremiumPrice: false,
        });
      }
    });
  };

  private handleOnProducerChange = (option: Option): void => {
    if (option) {
      this.setState({ ...ProducerForm.DEFAULT_STATE, producer_id: option.value });
      this.populateProducerFields(option.value);
    }
  };

  private resetForm = (): void => {
    this.setState(ProducerForm.DEFAULT_STATE);
  };

  private handleOnProducerClear = (): void => {
    this.resetForm();
  };

  private onAgreeToNoPremiumPriceChange = (checked: boolean): void => {
    this.setState({ agreedToNoPremiumPrice: checked });
    this.setMaxNumberOfBags(null);
  };

  private setMaxNumberOfBags = (_bagsCount: number | null) => {
    // const { bags_input_id } = this.props;
    // const bagsCountStr = bagsCount == null ? '' : bagsCount.toString();
    // Currently do not validate in frontend
    // document.getElementById(bags_input_id)?.setAttribute('max', bagsCountStr)
  };

  private setCertification = (certificateId?: number): void => {
    const { certificates, agreedToNoPremiumPrice } = this.state;
    const currentCertificate = certificates.find(
      (certificate) => certificate.id === certificateId
    );

    let maximumNumberOfBags: number | null = null;
    let farmCertificateQuotaReached = false;

    if (currentCertificate && currentCertificate.remaining_quota !== null) {
      const remaining_quota = parseFloat(currentCertificate.remaining_quota);

      if (remaining_quota === 0 || agreedToNoPremiumPrice) {
        farmCertificateQuotaReached = true;
        maximumNumberOfBags = 0;
      } else {
        maximumNumberOfBags = remaining_quota;
      }
    }

    this.setMaxNumberOfBags(maximumNumberOfBags);
    this.setState({ certificate_id: certificateId, farmCertificateQuotaReached });
  };

  private renderFarm = (inputId: string): JSX.Element => {
    const inputChange = (event: any) => {
      const farmId = parseInt(event.target.value, 10);

      this.setState({ farm_id: farmId });

      this.populateFarmFields(farmId);
    };

    const { farms, farm_id } = this.state;

    if (farm_id) {
      if (farms.length > 1) {
        return (
          <select id={inputId} value={farm_id} onChange={inputChange}>
            {farms.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        );
      } else if (farms.length === 1) {
        return <input type='text' id={inputId} value={farms[0].name} disabled readOnly />;
      }
    }

    return <input type='text' id={inputId} value='' disabled readOnly />;
  };

  private renderCertification = (inputId: string): JSX.Element => {
    const { certificate_id, certificates, agreedToNoPremiumPrice } = this.state;

    const inputChange = (event: any) => {
      const certificateId = parseInt(event.target.value, 10);
      this.setCertification(certificateId);
    };

    const certificateDisplayText = ({
      name,
      remaining_quota,
    }: Pick<CertificateOption, 'name' | 'remaining_quota'>): string => {
      let certificateInputValue = name;

      if (remaining_quota != null) {
        certificateInputValue += ` -  ${parseFloat(
          remaining_quota
        ).toString()} ${I18n.translate('producer_form.weight_remaining')} `;
      }
      return certificateInputValue;
    };

    if (agreedToNoPremiumPrice) {
      return (
        <>
          <input type='hidden' id={inputId} value={-1} />
          <input
            type='text'
            id={inputId + '_presentational'}
            value={I18n.translate('attributes.uncertified')}
            readOnly={true}
            disabled={true}
          />
        </>
      );
    }

    if (certificate_id) {
      if (certificates.length > 1) {
        return (
          <select
            id={inputId}
            value={certificate_id}
            onChange={inputChange}
            data-cy='certificate_select'
          >
            {certificates.map(({ id, name, remaining_quota }) => (
              <option key={id} value={id} data-bag-quota={remaining_quota}>
                {certificateDisplayText({ name, remaining_quota })}
              </option>
            ))}
          </select>
        );
      } else if (certificates.length === 1) {
        return (
          <>
            <input type='hidden' id={inputId} value={certificates[0].name} />
            <input
              type='text'
              id={inputId + '_presentational'}
              value={certificateDisplayText(certificates[0])}
              readOnly={true}
              disabled={true}
            />
          </>
        );
      }
    }

    return <input type='text' id={inputId} disabled />;
  };

  public render(): JSX.Element {
    const {
      producer_id,
      farm_id,
      certificate_id,
      complies_eudr,
      fiscal_id,
      association,
      agreedToNoPremiumPrice,
      farmCertificateQuotaReached,
      editable,
    } = this.state;

    const { producers } = this.props;

    let certificate_id_for_form: any = '';

    if (agreedToNoPremiumPrice) {
      certificate_id_for_form = '';
    } else {
      certificate_id_for_form = certificate_id || '';
    }

    return (
      <>
        <input type='hidden' value={producer_id || ''} name='lot[producer_id]' />
        <input type='hidden' value={farm_id || ''} name='lot[farm_id]' />
        <input type='hidden' value={certificate_id_for_form} name='lot[certificate_id]' />

        <div className='l-auto-fill-grid' data-cy='lot_producer_form'>
          <BaseGroupComponent label={I18n.translate('attributes.id_name')}>
            <Search
              options={producers}
              isDisabled={!editable}
              placeholder={I18n.translate('producer_form.select_a_producer')}
              onChange={this.handleOnProducerChange}
              onClear={this.handleOnProducerClear}
              value={producer_id}
            />
          </BaseGroupComponent>

          <div className='input-group'>
            <label htmlFor='farm'>{I18n.translate('producer_form.farm')}</label>
            {this.renderFarm('farm')}
          </div>

          <InputGroup
            id='fiscal_id'
            label='Fiscal ID'
            value={fiscal_id}
            disabled={true}
          />

          <InputGroup
            id='association'
            label={I18n.translate('producer_form.association')}
            value={association}
            disabled={true}
          />

          <div className='input-group'>
            <label htmlFor='certification'>
              {I18n.translate('producer_form.certification')}
            </label>

            {this.renderCertification('certification')}
          </div>

          {certificate_id && (
            <div
              className='input-group'
              data-cy='agree_non_premium_price_checkbox_wrapper'
            >
              <Checkbox
                label={
                  farmCertificateQuotaReached
                    ? I18n.translate('producer_form.conventional_max_quota_reached')
                    : I18n.translate('producer_form.conventional')
                }
                labelMultiline={true}
                checked={agreedToNoPremiumPrice}
                name='lot[agreed_to_no_premium_price]'
                value='yes'
                required={farmCertificateQuotaReached}
                onChange={this.onAgreeToNoPremiumPriceChange}
                dataCy='agree_non_premium_price'
                disabled={!editable}
              />
            </div>
          )}

          {complies_eudr && (
            <this.eudrLabel>
              <b>EUDR</b>
            </this.eudrLabel>
          )}
        </div>
      </>
    );
  }
}

export default ProducerForm;
