import classNames from 'classnames';
import React from 'react';

import BaseGroupComponent, { BaseGroupProps } from './BaseGroup';
import ErrorsComponent from 'shared/Errors/components/Errors';
import Select, { SelectProps } from 'components/elements/Select';

interface SelectGroupProps extends BaseGroupProps, SelectProps {
  errors?: string[];
}

const SelectGroup = React.forwardRef<HTMLSelectElement, SelectGroupProps>(
  (
    { className, label, condensed, inline, required, noMargin, errors, ...props },
    ref
  ) => {
    const selectClasses = classNames({
      'input--condensed': condensed,
    });

    return (
      <BaseGroupComponent
        label={label}
        noMargin={noMargin}
        condensed={condensed}
        inline={inline}
        required={required}
        className={className}
      >
        <Select ref={ref} className={selectClasses} required={required} {...props} />

        {errors && <ErrorsComponent errors={errors} />}
      </BaseGroupComponent>
    );
  }
);

SelectGroup.displayName = 'SelectGroup';

export default SelectGroup;
