import React from 'react';
import StatusText from 'shared/StatusText';
import { observer } from 'mobx-react';
import Sample from 'customerSamples/models/Sample';
import CustomerSampleValuesButton from 'customerSamples/components/CustomerSampleValuesButton';

export interface CustomerSampleValuesDetailsProps {
  customerSample: Sample;
}

function CustomerSampleValuesDetails({
  customerSample,
}: CustomerSampleValuesDetailsProps): JSX.Element {
  return customerSample.customerSampleValue?.customerSampleReason &&
    customerSample.customerSampleValue?.isLocked ? (
    <StatusText
      status={customerSample.customerSampleValue.customerSampleReason?.status}
      withIcon={false}
    >
      {customerSample.customerSampleValue.customerSampleReason?.name}
    </StatusText>
  ) : (
    <CustomerSampleValuesButton
      className='button button--small button--ghost'
      customerSample={customerSample}
    >
      {I18n.translate('customer_samples_dashboard.see_details')}
    </CustomerSampleValuesButton>
  );
}

export default observer(CustomerSampleValuesDetails);
