import { observable, action, computed } from 'mobx';

import type ResponsibilityLetterType from '../types/responsibilityLetter';
import FinalizeableResource from './FinalizableResource';

class ResponsibilityLetter extends FinalizeableResource<ResponsibilityLetterType> {
  @observable finalized: boolean;

  @observable id?: number;
  @observable invoice_number?: string;
  @observable is_vuce?: boolean;

  @action protected updateAttributes({
    id,
    invoice_number,
    is_vuce,
    finalized,
  }: ResponsibilityLetterType) {
    this.id = id;
    this.finalized = finalized;
    this.invoice_number = invoice_number;
    this.is_vuce = is_vuce;
  }

  @computed get isFinalizable() {
    return this.id != null;
  }
}

export default ResponsibilityLetter;
