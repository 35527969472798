import { Provider } from 'mobx-react';
import React from 'react';

import { ReasonGet } from 'types/model/reason';
import PeabiesPhysicalAnalysisForm from '../components/PeabiesPhysicalAnalysisForm';
import { PeabiesPhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';
import SpecialtyGradingSpecificationType from 'types/model/specialtyGradingSpecification';
import OverlayStore from 'stores/OverlayStore';
import PeabiesPhysicalAnalysisStore from 'qaPhysicalAnalyses/stores/PeabiesPhysicalAnalysisStore';
import { JsonApi } from 'types/utils/jsonApi';
import Contract from '../types/Contract';
import Validation from 'types/utils/validations';
import ISample from 'millingOrders/types/ISample';

export interface PeabiesPhysicalAnalysisContainerProps {
  sample: JsonApi<ISample>;
  physical_analysis: JsonApi<PeabiesPhysicalAnalysisType>;
  contract: JsonApi<Contract>;
  reasons: ReasonGet[];
  specialty_grading_specifications: SpecialtyGradingSpecificationType[];
  cancel_url: string;
  validations: {
    flat_bean_peaberry_percentage: Validation;
  };
}

const PeabiesPhysicalAnalysisContainer = ({
  sample,
  contract,
  reasons,
  physical_analysis,
  specialty_grading_specifications,
  cancel_url = '',
  validations,
}: PeabiesPhysicalAnalysisContainerProps) => (
  <Provider
    peabiesPhysicalAnalysisStore={
      new PeabiesPhysicalAnalysisStore(
        sample,
        contract,
        physical_analysis,
        specialty_grading_specifications,
        reasons,
        cancel_url,
        validations
      )
    }
    overlayStore={new OverlayStore()}
  >
    <PeabiesPhysicalAnalysisForm />
  </Provider>
);

export default PeabiesPhysicalAnalysisContainer;
