import React from 'react';

import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

import type UnloadableIcoModel from 'transports/model/UnloadableIco';
import { observer } from 'mobx-react';
import UnloadTransportableOverlay from './UnloadTransportableOverlay';
import IdentifierTableData from 'shared/Table/components/IdentifierTableData';
import placeholder from 'utils/placeholder';

export interface UnloadableIcoProps {
  transportable: UnloadableIcoModel;
}

function UnloadableIco({
  transportable,
  transportable: { identifier, quality, grade, bags, expectedWeight },
}: UnloadableIcoProps) {
  return (
    <tr key={identifier}>
      <IdentifierTableData className='table-border align-left'>
        {identifier}
      </IdentifierTableData>

      <td className='table-border align-center'>
        <TextWithSub text={placeholder(quality)} sub={placeholder(grade)} />
      </td>

      <td className='table-border align-center'>{bags}</td>

      <td className='table-border align-center'>{expectedWeight} kg</td>

      <td className='table-border transport-unload--tags' />

      <td className='table-border transport-unload--additions align-center'></td>

      <td>
        <UnloadTransportableOverlay transportable={transportable} />
      </td>
    </tr>
  );
}

export default observer(UnloadableIco);
