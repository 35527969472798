import React from 'react';

import FilterBar from 'components/elements/FilterBar';
import Checkbox from 'shared/Checkbox';
import SelectOptionArray from 'types/model/selectOption';
import DateRange from './DateRange';
import Input from 'components/elements/Input';

export type FilterOption<T> = {
  name: string;
  value: T;
};

export type SelectFilterOption = FilterOption<string> & {
  options: SelectOptionArray;
  placeholder?: string;
};

export type FilterOptions = Partial<Record<string, FilterOption<any>>>;

export interface SearchBarFilterOptions extends FilterOptions {
  withDispatched?: FilterOption<boolean>;
  identifier?: FilterOption<string>;
  createdAtGt?: FilterOption<string>;
  createdAtLt?: FilterOption<string>;
}

export interface SearchBarProps {
  filters: SearchBarFilterOptions;
}

function SearchBar({ filters = {} }: SearchBarProps) {
  return (
    <FilterBar
      as='form'
      method='GET'
      columns='minmax(10em, 1fr) 9.75em minmax(20em, 1fr) 7em'
    >
      {filters.identifier && (
        <Input
          name={filters.identifier.name}
          type='text'
          defaultValue={filters.identifier.value}
        />
      )}

      {filters.withDispatched && (
        <Checkbox
          value='true'
          name={filters.withDispatched.name}
          checked={filters.withDispatched.value}
          label={I18n.translate('traceability.show_dispatched')}
        />
      )}

      <DateRange startDate={filters.createdAtGt} endDate={filters.createdAtLt} />

      <button className='button' type='submit'>
        {I18n.translate('buttons.search')}
      </button>
    </FilterBar>
  );
}

export default SearchBar;
