import { action, computed, observable } from 'mobx';

import { ExcelsoPhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';
import SpecialtyGradingSpecification from './SpecialtyGradingSpecification';
import QaDefect from './QaDefect';
import Norm from './Norm';

class ExcelsoPhysicalAnalysis {
  public sampleId: number;
  @observable public sampleWeight: number;
  public type: string;
  public id?: number;
  public fullDefectsGroup1: number;
  public fullDefectsGroup2: number;
  public group1: string[];
  public group2: string[];
  public norm: Norm;
  @observable public sieve14: number;
  @observable public sieve15: number;
  @observable public observations?: string;
  @observable public acceptingReasonId?: number;
  public specialtyGradingSpecifications: SpecialtyGradingSpecification[];
  // These fields keep snail_case to work with group definitions from backend
  @observable public full_black: QaDefect;
  @observable public partial_black: QaDefect;
  @observable public full_sour: QaDefect;
  @observable public partial_sour: QaDefect;
  @observable public dried_cherry: QaDefect;
  @observable public fungus_sour: QaDefect;
  @observable public foreign_matter: QaDefect;
  @observable public severe_insect_damage: QaDefect;
  @observable public parchment: QaDefect;
  @observable public broken: QaDefect;
  @observable public withered: QaDefect;
  @observable public immature: QaDefect;
  @observable public floater: QaDefect;
  @observable public hull: QaDefect;
  @observable public shell: QaDefect;
  @observable public slight_insect_damage: QaDefect;
  @observable public uv: number;
  @observable public state: string;

  // TODO: Check: Move sample_id to physical
  constructor(
    sample_id: number,
    physicaAnalysis: ExcelsoPhysicalAnalysisType,
    specialtyGradingSpecifications: SpecialtyGradingSpecification[],
    norm: Norm
  ) {
    this.sampleId = sample_id;
    this.sampleWeight = physicaAnalysis.sample_weight;
    this.id = physicaAnalysis.id;
    this.type = physicaAnalysis.type;
    this.observations = physicaAnalysis.observations;
    this.uv = physicaAnalysis.uv;
    this.sieve14 = physicaAnalysis.sieve_14;
    this.sieve15 = physicaAnalysis.sieve_15;
    this.fullDefectsGroup1 = physicaAnalysis.full_defects_group_1;
    this.fullDefectsGroup2 = physicaAnalysis.full_defects_group_2;
    this.acceptingReasonId = physicaAnalysis.accepting_reason_id;
    this.group1 = physicaAnalysis.group_1;
    this.group2 = physicaAnalysis.group_2;
    this.specialtyGradingSpecifications = specialtyGradingSpecifications;
    this.norm = norm;
    this.state = physicaAnalysis.state;

    this.full_black = new QaDefect(physicaAnalysis.full_black);
    this.partial_black = new QaDefect(physicaAnalysis.partial_black);
    this.full_sour = new QaDefect(physicaAnalysis.full_sour);
    this.partial_sour = new QaDefect(physicaAnalysis.partial_sour);
    this.dried_cherry = new QaDefect(physicaAnalysis.dried_cherry);
    this.fungus_sour = new QaDefect(physicaAnalysis.fungus_sour);
    this.foreign_matter = new QaDefect(physicaAnalysis.foreign_matter);
    this.severe_insect_damage = new QaDefect(physicaAnalysis.severe_insect_damage);
    this.parchment = new QaDefect(physicaAnalysis.parchment);
    this.broken = new QaDefect(physicaAnalysis.broken);
    this.withered = new QaDefect(physicaAnalysis.withered);
    this.immature = new QaDefect(physicaAnalysis.immature);
    this.floater = new QaDefect(physicaAnalysis.floater);
    this.hull = new QaDefect(physicaAnalysis.hull);
    this.shell = new QaDefect(physicaAnalysis.shell);
    this.slight_insect_damage = new QaDefect(physicaAnalysis.slight_insect_damage);
  }

  @action setSampleWeight = (sampleWeight: number) => {
    this.sampleWeight = sampleWeight;
  };

  @action setSieve14 = (sieve14: number) => {
    this.sieve14 = sieve14 ? sieve14 : 0;
  };

  @action setSieve15 = (sieve15: number) => {
    this.sieve15 = sieve15 ? sieve15 : 0;
  };

  @action setUv = (uv: number) => {
    this.uv = uv ? uv : 0;
  };

  @action setObservations = (observations: string) => {
    this.observations = observations;
  };

  @computed get group1FullDefects() {
    const fullDefects = this.group1?.map((groupIdentifier) => {
      const defectGrains = this[groupIdentifier]?.grains;

      const specialtyGrading = this.specialtyGradingSpecifications.find(
        (specification) => {
          return specification.gradingIdentifier === groupIdentifier;
        }
      );

      const fullDefectEquivalent = specialtyGrading?.fullDefectEquivalent;

      return defectGrains && fullDefectEquivalent
        ? defectGrains / fullDefectEquivalent
        : 0;
    });

    return fullDefects.reduce((prev, current) => prev + current, 0);
  }

  @computed get group1FullWeight() {
    const fullWeights = this.group1.map((groupIdentifier) => {
      const defectWeight = this[groupIdentifier]?.weight;
      return defectWeight ? defectWeight : 0;
    });

    return fullWeights.reduce((prev, current) => prev + current, 0);
  }

  @computed get group1FullGrains() {
    const fullGrains = this.group1.map((groupIdentifier) => {
      const defectGrains = this[groupIdentifier]?.grains;
      return defectGrains ? defectGrains : 0;
    });

    return fullGrains.reduce((prev, current) => prev + current, 0);
  }

  @computed get group2FullDefects(): number {
    const fullDefects = this.group2.map((groupIdentifier) => {
      const defectGrains = this[groupIdentifier]?.grains;

      const specialtyGrading = this.specialtyGradingSpecifications.find(
        (specification) => {
          return specification.gradingIdentifier === groupIdentifier;
        }
      );

      const fullDefectEquivalent = specialtyGrading?.fullDefectEquivalent;

      return defectGrains && fullDefectEquivalent
        ? defectGrains / fullDefectEquivalent
        : 0;
    });

    return fullDefects.reduce((prev, current) => prev + current, 0);
  }

  @computed get group2FullWeight() {
    const fullWeights = this.group2.map((groupIdentifier) => {
      const defectWeight = this[groupIdentifier]?.weight;
      return defectWeight ? defectWeight : 0;
    });

    return fullWeights.reduce((prev, current) => prev + current, 0);
  }

  @computed get group2FullGrains() {
    const fullGrains = this.group2.map((groupIdentifier) => {
      const defectGrains = this[groupIdentifier]?.grains;
      return defectGrains ? defectGrains : 0;
    });

    return fullGrains.reduce((prev, current) => prev + current, 0);
  }

  @computed get exceedsNorm() {
    const exceedsGroup1 =
      this.group1FullDefects > this.norm.group1Defects + this.norm.tolerance;
    const exceedsGroup2 =
      this.group2FullDefects > this.norm.group2Defects + this.norm.tolerance;
    const exceedsSum =
      this.group1FullDefects + this.group2FullDefects >
      this.norm.group1Defects + this.norm.group2Defects + this.norm.tolerance;

    return exceedsGroup1 || exceedsGroup2 || exceedsSum;
  }

  @computed get isRejectedInitiallyChecked() {
    if (this.id) {
      return this.exceedsNorm
        ? this.acceptingReasonId
          ? false
          : true
        : this.state === 'failed'
        ? true
        : false;
    } else {
      return this.exceedsNorm;
    }
  }

  @computed get isValid() {
    return this.sieve14 > 0 || this.sieve15 > 0;
  }

  getWeightPercentage = (weight: number): number => {
    return weight > 0 ? (weight * 100) / this.sampleWeight : 0;
  };

  getWeightPercentageString = (weight: number): string => {
    const percentage = this.getWeightPercentage(weight);

    return percentage > 0 ? `${percentage.toFixed(2)}%` : '---';
  };
}

export default ExcelsoPhysicalAnalysis;
