import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import SubNavigationBlock from '../sidebar/SubNavigationBlock';

export interface MenuItemProps extends React.HTMLAttributes<HTMLLIElement> {
  active?: boolean;
}

export const MenuItemTitle = styled.div`
  display: flex;
  height: 3.625em;
  padding: 0 0.9375em;
  align-items: center;
  justify-content: space-between;

  > a {
    color: ${COLORS.WHITE};
  }
`;

export const MenuItemDivider = styled.div`
  height: 1px;
  opacity: 0.1;
  margin: 0.46875em 0.9375em;
  background: ${COLORS.MENU_TEXT_COLOR};
`;

export default styled.li<MenuItemProps>`
  list-style: none;
  text-transform: uppercase;
  background: ${(props) => (props.active ? COLORS.MENU_ITEM_BG_COLOR : 'transparent')};
  color: ${COLORS.MENU_TEXT_COLOR};
  overflow: ${(props) => (props.active ? 'auto' : 'hidden')};
  cursor: ${(props) => (props.active ? 'normal' : 'pointer')};

  &:hover ${MenuItemTitle} {
    background: ${(props) => (props.active ? 'transparent' : 'rgba(0,0,0,0.1)')};
    color: ${(props) => (props.active ? 'inherit' : COLORS.WHITE)};
  }

  > ${SubNavigationBlock} {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  ${SubNavigationBlock} & {
    text-transform: none;
    padding: 0 0.9375em;
    line-height: 2.125em;
  }

  ${MenuItemTitle} {
    transition: background 0.3s ease-out;
    border-bottom: ${(props) =>
      `1px solid ${props.active ? COLORS.BLUE : COLORS.MENU_ITEM_BG_COLOR}`};
  }
`;
