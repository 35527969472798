import React from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { ContractSelectGroup, ContractTextInput } from '../ContractFields';

export const CompaniesInvolved = ({
  customerField,
  customerReferenceField,
  caravelaSalesStaffField,
  shipperIdField,
  shipperId,
  setShipperId,
  thirdPartyShipperIdField,
  thirdPartyShipperId,
  setThirdPartyShipperId,
  intermediaryIdField,
  sellerIdField,
}) => (
  <FieldSetWithTitle title={I18n.translate('contracts.form.companies_involved')}>
    <Grid>
      <ContractSelectGroup field={customerField} />
      <ContractTextInput
        field={customerReferenceField}
        value={customerReferenceField?.value}
      />
      <ContractTextInput
        field={caravelaSalesStaffField}
        value={caravelaSalesStaffField?.value}
      />
      <ContractSelectGroup
        field={thirdPartyShipperIdField}
        value={thirdPartyShipperId}
        onChange={(val) => setThirdPartyShipperId(val)}
      />
      <ContractSelectGroup
        field={shipperIdField}
        value={shipperId}
        onChange={(val) => setShipperId(val)}
      />
      <ContractTextInput field={intermediaryIdField} />
      <ContractTextInput field={sellerIdField} />
    </Grid>
  </FieldSetWithTitle>
);
