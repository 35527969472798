import { Provider } from 'mobx-react';
import React from 'react';

import OverlayStore from 'stores/OverlayStore';
import WetPhysicalAnalysisStore from '../stores/WetPhysicalAnalysisStore';

import WetPhysicalAnalysisComponent from '../components/WetPhysicalAnalysisComponent';
import { ReasonGet } from 'types/model/reason';
import SelectOptionArray from 'types/model/selectOption';
import { Validation } from 'components/forms/lot/PhysicalAnalysisComponent';

export interface WetPhysicalAnalysisContainerProps {
  validations: {
    [key: string]: Validation;
  };
  smells: SelectOptionArray;
  reasons: ReasonGet[];
  hasPrepayment: boolean;
  wetPhysicalAnalysis: any;
  estimatedGreen: number;
}

function WetPhysicalAnalysisContainer({
  wetPhysicalAnalysis,
  ...props
}: WetPhysicalAnalysisContainerProps) {
  return (
    <Provider
      overlayStore={new OverlayStore({ valid: true, isFilled: true })}
      wetPhysicalAnalysisStore={new WetPhysicalAnalysisStore(wetPhysicalAnalysis)}
    >
      <WetPhysicalAnalysisComponent {...props} />
    </Provider>
  );
}

export default WetPhysicalAnalysisContainer;
