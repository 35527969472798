import { Pagy } from 'shared/Pagynation';
import { JsonApi } from 'types/utils/jsonApi';
import { customFetch, methodInterface } from 'utils/fetch';

export type PaginatedSuccessResponse<T> = {
  success: true;
  data: JsonApi<T>;
  pagy: Pagy;
};

export type PaginatedErrorResponse = {
  success: false;
  errors: Array<string>;
};

export type PaginatedResponse<T> = PaginatedSuccessResponse<T> | PaginatedErrorResponse;

export async function paginatedTableCustomFetch<T = any>(
  url: string,
  body?: object | undefined | FormData | string,
  method?: keyof typeof methodInterface
): Promise<PaginatedSuccessResponse<T>> {
  const response = await customFetch<PaginatedResponse<T>>(url, body, method);

  if (!response.success) {
    throw response;
  }

  return response;
}
