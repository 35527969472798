// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
// require("@rails/activestorage").start()
// require("channels")

//= require('js-routes');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
import 'core-js/stable';
import 'regenerator-runtime/runtime';

var componentRequireContext = require.context('../', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

const alertElements = [...document.getElementsByClassName('alert-message')];
for (const [, alert] of alertElements.entries()) {
  alert.addEventListener('click', () => {
    alert.remove();
  });
}

const newTabsInputs = [...document.getElementsByClassName('new_tabs_to_open')];
for (const [, newTabsInput] of newTabsInputs.entries()) {
  window.open(newTabsInput.value);
}

window.ReactEventBus = {
  on(event, callback) {
    window.addEventListener(event, (event) => callback(event));
  },
  dispatch(event) {
    window.dispatchEvent(event);
  },
  remove(event, callback) {
    window.removeEventListener(event, callback);
  },
};

document
  .querySelectorAll("[name='fixed_price_contract[process_type_id]']")
  .forEach((element) => {
    element.addEventListener('change', (event) => {
      const currentOption = Array.from(event.target.options).find(
        (option) => option.value == event.target.value
      );

      window.ReactEventBus.dispatch(
        new CustomEvent('fixedPriceContracts/processType', {
          detail: {
            processType: {
              value: event.target.value,
              name: currentOption.text.toLowerCase(),
            },
          },
        })
      );
    });
  });
