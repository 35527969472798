import type DestinationWarehouseCostType from '../types/DestinationWarehouseCost';
import { action, computed, observable } from 'mobx';
import DeliveryOrderCostType from '../types/DeliveryOrderCost';
import DestinationWarehouseCost from './DestinationWarehouseCost';
import DeliveryOrder from '../models/DeliveryOrder';

export class DeliveryOrderCost {
  public deliveryOrder: DeliveryOrder;
  public destinationWarehouseCost: DestinationWarehouseCost;
  public deliveryOrderCost: DeliveryOrderCostType;

  // Readonly DeliveryOrderCosts
  public readonly rebaggingFee: number | null;
  public readonly orderPickFeeCost: number | null;

  // Values from UI
  @observable public loadoutChecked = false;
  @observable public palletizingChecked = false;
  @observable public ipccHeatTreatedFeeChecked = false;
  @observable public nonHeatTreatedFeeChecked = false;
  @observable public rushFeeChecked = false;
  @observable public orderPickFeeChecked = false;
  @observable public samplingFeeChecked = false;
  @observable public rebaggingFeeChecked = false;
  @observable public freightFeeChecked = false;
  @observable public noCostChecked = false;
  @observable public loadoutFee: number;
  @observable public palletQuantity = 1;
  @observable public freightFee = 0.0;
  @observable public poNumber = '';

  constructor(
    deliveryOrder: DeliveryOrder,
    deliveryOrderCost: DeliveryOrderCostType,
    destinationWarehouseCost: DestinationWarehouseCostType
  ) {
    this.deliveryOrder = deliveryOrder;
    this.deliveryOrderCost = deliveryOrderCost;

    this.destinationWarehouseCost = new DestinationWarehouseCost(
      destinationWarehouseCost
    );

    this.loadoutFee = parseFloat(this.deliveryOrderCost.total_loadout_cost || '') || 0.0;
    this.rebaggingFee = parseFloat(deliveryOrderCost.total_rebagging_cost || '') || null;
    this.orderPickFeeCost =
      parseFloat(deliveryOrderCost.total_order_pick_fee_cost || '') || null;
  }

  @action public toggleLoadoutChecked = () => {
    this.loadoutChecked = !this.loadoutChecked;
    this.uncheckNoCostChecked();
  };

  @action public setLoadoutFee = (value: string): void => {
    this.loadoutFee = parseFloat(value) || 0;
  };

  @action public togglePalletizingChecked = (): void => {
    this.palletizingChecked = !this.palletizingChecked;
    this.uncheckNoCostChecked();
  };

  @action public toggleIpccHeatTreatedFeeChecked = (): void => {
    this.ipccHeatTreatedFeeChecked = !this.ipccHeatTreatedFeeChecked;
    this.uncheckNoCostChecked();
  };

  @action public toggleNonHeatTreatedFeeChecked = (): void => {
    this.nonHeatTreatedFeeChecked = !this.nonHeatTreatedFeeChecked;
    this.uncheckNoCostChecked();
  };

  @action public toggleOrderPickFeeChecked = (): void => {
    this.orderPickFeeChecked = !this.orderPickFeeChecked;
    this.uncheckNoCostChecked();
  };

  @action public toggleRushFeeChecked = (): void => {
    this.rushFeeChecked = !this.rushFeeChecked;
    this.uncheckNoCostChecked();
  };

  @action public toggleRebaggingFeeChecked = (): void => {
    this.rebaggingFeeChecked = !this.rebaggingFeeChecked;
    this.uncheckNoCostChecked();
  };

  @action public toggleSamplingFeeChecked = (): void => {
    this.samplingFeeChecked = !this.samplingFeeChecked;
    this.uncheckNoCostChecked();
  };

  @action private uncheckNoCostChecked = (): void => {
    this.noCostChecked = false;
  };

  @action public toggleNoCostChecked = (): void => {
    this.noCostChecked = !this.noCostChecked;
    if (this.noCostChecked) {
      this.resetCheckboxes();
    }
  };

  @action public toggleFreightFeeChecked = (): void => {
    this.freightFeeChecked = !this.freightFeeChecked;
  };

  @action public setFreightFee = (freightFee: string): void => {
    this.freightFee = parseFloat(freightFee) || 0.0;
  };

  @action public setPalletQuantity = (palletQuantity: number): void => {
    this.palletQuantity = palletQuantity;
  };

  @action public setPoNumber = (poNumber: string): void => {
    this.poNumber = poNumber;
  };

  @action private resetCheckboxes = (): void => {
    this.loadoutChecked = false;
    this.palletizingChecked = false;
    this.ipccHeatTreatedFeeChecked = false;
    this.nonHeatTreatedFeeChecked = false;
    this.rushFeeChecked = false;
    this.orderPickFeeChecked = false;
    this.samplingFeeChecked = false;
    this.rebaggingFeeChecked = false;
    this.freightFeeChecked = false;
  };

  @computed get palletizingCosts(): number {
    const { palletizingPerUnitCost, palletizingMinimumCost } =
      this.destinationWarehouseCost;

    const perUnitPalletizingCosts =
      this.palletQuantity * (palletizingPerUnitCost as number);
    return perUnitPalletizingCosts > palletizingMinimumCost
      ? perUnitPalletizingCosts
      : palletizingMinimumCost;
  }

  @computed get deliveryOrderCostsValid() {
    return this.noCostChecked || this.totalCosts !== 0;
  }

  @computed get totalCosts(): number {
    const {
      rushFeeCost,
      samplingFeeCost,
      ipccHeatTreatedFeeCost,
      nonHeatTreatedFeeCost,
    } = this.destinationWarehouseCost;

    const costTuples: [boolean, number | null][] = [
      [this.loadoutChecked, this.loadoutFee],
      [this.palletizingChecked, this.palletizingCosts],
      [this.rushFeeChecked, rushFeeCost],
      [this.samplingFeeChecked, samplingFeeCost],
      [this.ipccHeatTreatedFeeChecked, ipccHeatTreatedFeeCost],
      [this.nonHeatTreatedFeeChecked, nonHeatTreatedFeeCost],
      [this.orderPickFeeChecked, this.orderPickFeeCost],
      [this.freightFeeChecked, this.freightFee],
      [this.rebaggingFeeChecked, this.rebaggingFee],
    ];

    return costTuples
      .filter((entries) => entries[0])
      .reduce((memo, value) => {
        return memo + (value[1] || 0.0);
      }, 0.0);
  }

  @computed get urlParams(): {} {
    return {
      po_number: this.poNumber,
      loadout_fee: (this.loadoutChecked && this.loadoutFee) || 0,
      palletizing_amount: (this.palletizingChecked && this.palletQuantity) || 0,
      ipcc_heat_treated_fee: this.ipccHeatTreatedFeeChecked,
      non_heat_treated_fee: this.nonHeatTreatedFeeChecked,
      order_pick_fee: this.orderPickFeeChecked,
      rebagging_per_unit: this.rebaggingFeeChecked,
      rush_fee: this.rushFeeChecked,
      sampling_fee: this.samplingFeeChecked,
      freight_fee: this.freightFee,
      no_costs: this.noCostChecked,
    };
  }

  @computed get showExchangeRate(): boolean {
    return this.destinationWarehouseCost.sameCurrency;
  }
}

export default DeliveryOrderCost;
