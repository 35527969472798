import styled from 'styled-components';

const TableCellData = styled.div`
  margin-bottom: 1rem;
  width: max-content;
  max-width: 100%;
  word-break: break-word;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default TableCellData;
