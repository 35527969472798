import { observer } from 'mobx-react';
import React from 'react';
import useStores from 'utils/useStores';

import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import PercentageAppendComponent from './PercentageAppendComponent';
import { VARIANT } from 'constants/inputTypes';
import FieldSet from 'components/elements/FieldSet';
import { addTestingIdentifier } from 'utils/testing';

const MachinePhysicalAnalysisForm = () => {
  const { machinePhysicalAnalysisStore } = useStores();
  const physicalAnalysis = machinePhysicalAnalysisStore.machinePhysicalAnalysis;

  return (
    <>
      {physicalAnalysis && (
        <>
          <FieldSet disabled={!!physicalAnalysis.id}>
            <Grid grid={9}>
              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sample_weight')}
                value={physicalAnalysis.sampleWeight}
                onChange={(e) =>
                  physicalAnalysis.setSampleWeight(parseInt(e.target.value))
                }
                append='gr'
                name='sample_weight'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sieve_14')}
                value={physicalAnalysis.sieve14}
                onChange={(e) => physicalAnalysis.setSieve14(parseInt(e.target.value))}
                selectTextOnFocus={true}
                append={
                  <PercentageAppendComponent
                    percentage={physicalAnalysis.getWeightPercentage(
                      physicalAnalysis.sieve14
                    )}
                  />
                }
                name='sieve_14'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sieve_15')}
                value={physicalAnalysis.sieve15}
                onChange={(e) => physicalAnalysis.setSieve15(parseInt(e.target.value))}
                selectTextOnFocus={true}
                append={
                  <PercentageAppendComponent
                    percentage={physicalAnalysis.getWeightPercentage(
                      physicalAnalysis.sieve15
                    )}
                  />
                }
                name='sieve_15'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.uv')}
                value={physicalAnalysis.uv}
                onChange={(e) => physicalAnalysis.setUv(parseInt(e.target.value))}
                selectTextOnFocus={true}
                append='#'
                name='uv'
                type='number'
              />
            </Grid>
          </FieldSet>

          <FieldSet disabled={!!physicalAnalysis.id}>
            <Grid>
              <InputGroupComponent
                label={I18n.translate(
                  'qa_physical_analysis.non_conformant_pasilla_percentage'
                )}
                value={physicalAnalysis.nonConformantPasillaPercentage}
                onChange={(e) =>
                  physicalAnalysis.setNonConformantPasillaPercentage(
                    parseInt(e.target.value)
                  )
                }
                variant={VARIANT.DECIMAL}
                selectTextOnFocus={true}
                min={0}
                max={100}
                append='%'
                name='non_conformant_pasilla_percentage'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.conformant_pasilla')}
                value={physicalAnalysis.calculatedConformantPasilla}
                disabled={true}
                readOnly={true}
                append='kg'
                name='conformant_pasilla'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.non_conformant_pasilla')}
                value={physicalAnalysis.calculatedNonConformantPasilla}
                disabled={true}
                readOnly={true}
                append='kg'
                name='non_conformant_pasilla'
                type='number'
              />
            </Grid>
          </FieldSet>

          <FieldSet disabled={!!physicalAnalysis.id}>
            <TextAreaGroupComponent
              label={I18n.translate('qa_physical_analysis.observations')}
              value={physicalAnalysis.observations || ''}
              onChange={(e) => physicalAnalysis.setObservations(e.target.value)}
            />
          </FieldSet>

          <div className='fieldset'>
            <div className='l-distribute-l-r'>
              <a
                href={machinePhysicalAnalysisStore.cancelUrl}
                className='button button--gray'
              >
                {I18n.translate('buttons.cancel')}
              </a>

              {!physicalAnalysis.id && (
                <button
                  type='submit'
                  className='button'
                  disabled={
                    !machinePhysicalAnalysisStore.machinePhysicalAnalysis.formValid
                  }
                  {...addTestingIdentifier('save_machine_physical_analysis')}
                >
                  {I18n.translate('buttons.save')}
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default observer(MachinePhysicalAnalysisForm);
