import React from 'react';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
import InputGroupComponent from 'components/groups/InputGroup';

const DestroyableSamples = (): JSX.Element => {
  const { cuppingTableStore } = useStores();
  return (
    <div className='hidden--important'>
      {cuppingTableStore.destroyableSamples.map((sample) => {
        return (
          sample.cupping_table_sample_id && (
            <>
              <InputGroupComponent
                id={`cupping_table[cupping_table_samples_attributes][_destroy][${sample.cupping_table_sample_id.toString()}]`}
                name={`cupping_table[cupping_table_samples_attributes][${sample.id}][_destroy]`}
                value={1}
              />
              <InputGroupComponent
                id={`cupping_table[cupping_table_samples_attributes][id][${sample.cupping_table_sample_id.toString()}]`}
                name={`cupping_table[cupping_table_samples_attributes][${sample.id}][id]`}
                value={sample.cupping_table_sample_id}
                className='hidden--important'
              />
            </>
          )
        );
      })}
    </div>
  );
};

export default observer(DestroyableSamples);
