import React, { useEffect, useState } from 'react';
import InputGroupComponent from 'components/groups/InputGroup';

export interface FactorFields extends React.HTMLAttributes<HTMLDivElement> {
  defaultFactor14: string;
  defaultFactor15: string;
  defaultProcessType: string;
  factorSet14: {
    normal: string;
    natural: string;
    honey: string;
  };
  factorSet15: {
    normal: string;
    natural: string;
    honey: string;
  };
}

export const FIXED_PRICE_CONTRACT_PROCESS_TYPE = 'fixedPriceContracts/processType';
export type FixedPriceContractProcessTypeDetail = {
  processType: {
    name: string;
    value: string;
  };
};

const FactorFields: React.FC<FactorFields> = ({
  defaultProcessType,
  defaultFactor14,
  defaultFactor15,
  factorSet14,
  factorSet15,
}) => {
  const [factor_14, setFactor_14] = useState(defaultFactor14 || factorSet14.normal);
  const [factor_15, setFactor_15] = useState(defaultFactor15 || factorSet15.normal);
  const [processType, setProcessType] = useState('');

  const updateFactors = ({
    detail: {
      processType: { name },
    },
  }: CustomEvent<FixedPriceContractProcessTypeDetail>) => {
    updateProcessType(name);
    setFactor_14(factorSet14[name] || factorSet14.normal);
    setFactor_15(factorSet15[name] || factorSet15.normal);
  };

  const updateProcessType = (name) => {
    if (['natural', 'honey'].includes(name)) {
      setProcessType(`${name.charAt(0).toUpperCase()}${name.slice(1, name.length)}`);
    } else {
      setProcessType('');
    }
  };

  useEffect(() => {
    updateProcessType(defaultProcessType);
  }, []);

  useEffect(() => {
    window.ReactEventBus.on<FixedPriceContractProcessTypeDetail>(
      FIXED_PRICE_CONTRACT_PROCESS_TYPE,
      updateFactors
    );
    return () => {
      window.ReactEventBus.on<FixedPriceContractProcessTypeDetail>(
        FIXED_PRICE_CONTRACT_PROCESS_TYPE,
        updateFactors
      );
    };
  }, []);

  return (
    <div className='l-auto-fill-grid'>
      <InputGroupComponent
        label={`Factor 14 ${processType ? `(${processType})` : ''}`}
        id='fixed_price_contract_factor_14'
        name='fixed_price_contract[factor_14]'
        append='%'
        value={factor_14}
        onChange={(event) => setFactor_14(event.target.value)}
      />

      <InputGroupComponent
        label={`Factor 15 ${processType ? `(${processType})` : ''}`}
        id='fixed_price_contract_factor_15'
        name='fixed_price_contract[factor_15]'
        append='%'
        value={factor_15}
        onChange={(event) => setFactor_15(event.target.value)}
      />
    </div>
  );
};

export default FactorFields;
