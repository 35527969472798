import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';

import Icon from 'components/elements/Icon';
import ExportIcon, { IconType } from './ExportIcon';

export interface TabHeaderProps {
  icon: string;
  stateIcon: IconType;
  date?: string;
}

const TabHeaderWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  font-size: 1.5rem;
`;

const TextWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: inline-flex;
  padding-top: 0.325rem;
  font-size: 1rem;
  font-weight: normal;
  color: ${COLORS.BLACK_70};
`;

const TabHeader = ({ icon, stateIcon, date }: TabHeaderProps) => {
  return (
    <TabHeaderWrapper>
      <IconWrapper>
        <Icon icon={icon} color='#111820' />
      </IconWrapper>

      <IconWrapper>
        <ExportIcon type={stateIcon} />
      </IconWrapper>

      {date && <TextWrapper>{date}</TextWrapper>}
    </TabHeaderWrapper>
  );
};

export default TabHeader;
