import React from 'react';
import styled from 'styled-components';

import IcoImportRow from 'exports/components/IcoImportRow';

import SelectOptionArray from 'types/model/selectOption';
import IcoImport from 'exports/models/IcoImport';

export interface ImportContainerProps {
  icoImports: IcoImport[];
  isThirdPartyImport: boolean;
  destinationWarehouses: SelectOptionArray;
  destinationWarehousesData?: SelectOptionArray;
}

const IcoImportTable = styled.table`
  td {
    padding: 0.5rem 1rem !important;
  }

  th {
    padding: 0.5rem 1rem !important;
    box-shadow: none;
  }

  .input-table-data {
    padding: 0.5rem 0.325rem !important;
  }
`;

const ImportIcosContainer = ({
  icoImports,
  destinationWarehouses,
  destinationWarehousesData,
  isThirdPartyImport = true,
}: ImportContainerProps) => {
  return (
    <IcoImportTable className='table'>
      <thead>
        <tr>
          <th className='align-left'>
            {I18n.translate('exports.transports_popup.ico_amount')}
          </th>

          {isThirdPartyImport && (
            <th className='align-left'>{I18n.translate('exports.origin_dest')}</th>
          )}

          <th className='align-left'>
            {I18n.translate('exports.transports_popup.warehouse')}
          </th>

          <th className='align-left'>
            {I18n.translate('exports.transports_popup.arrival')}
          </th>

          <th className='align-left'>{I18n.translate('exports.transports_popup.bin')}</th>

          <th className='align-left'></th>
          <th className='align-left'></th>
        </tr>
      </thead>

      <tbody>
        {icoImports.map((icoImports) => (
          <IcoImportRow
            key={icoImports.id}
            isThirdPartyImport={isThirdPartyImport}
            icoImport={icoImports}
            destinationWarehouses={destinationWarehouses}
            destinationWarehousesData={destinationWarehousesData}
          />
        ))}
      </tbody>
    </IcoImportTable>
  );
};

export default ImportIcosContainer;
