import React, { useState } from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import Checkbox from 'shared/Checkbox';
import { OpportunitySelectGroup } from '../OpportunityFields';
import { IField } from 'components/shared/form/IField';

interface OpportunityLostProps {
  lostOpportunityReasonIdField?: IField;
  onChange: (value: boolean) => void;
}

export const OpportunityLost = ({
  lostOpportunityReasonIdField,
  onChange,
}: OpportunityLostProps) => {
  if (lostOpportunityReasonIdField == undefined) {
    return null;
  }

  const [lostOpportuntiy, setLostOpportunity] = useState(
    !!lostOpportunityReasonIdField?.value
  );

  const onOpportunityChangeHandler = (value: boolean) => {
    setLostOpportunity(value);

    onChange && onChange(value);
  };

  return (
    <FieldSetWithTitle title={I18n.translate('opportunities.form.opportunity_lost')}>
      {lostOpportunityReasonIdField.visible && (
        <div>
          <Checkbox
            icon='tick'
            inline={true}
            checked={lostOpportuntiy}
            disabled={
              !lostOpportunityReasonIdField.enabled ||
              lostOpportunityReasonIdField.readonly
            }
            label={I18n.translate('opportunities.form.opportunity_lost')}
            onChange={onOpportunityChangeHandler}
          />

          {lostOpportuntiy && (
            <Grid>
              <OpportunitySelectGroup
                field={lostOpportunityReasonIdField}
                value={lostOpportunityReasonIdField.value}
              />
            </Grid>
          )}
        </div>
      )}
    </FieldSetWithTitle>
  );
};
