import React from 'react';

import BaseGroupComponent, { BaseGroupProps } from './BaseGroup';
import classNames from 'classnames';
export interface TextAreaGroupProps
  extends BaseGroupProps,
    React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  dataCy?: string;
}

const TextAreaGroupComponent = React.forwardRef<HTMLTextAreaElement, TextAreaGroupProps>(
  (
    { value, defaultValue, placeholder, onChange, dataCy, changeIndicated, ...rest },
    ref
  ) => {
    return (
      <BaseGroupComponent {...rest}>
        <textarea
          ref={ref}
          onChange={onChange}
          placeholder={placeholder}
          data-cy={dataCy}
          value={value}
          defaultValue={defaultValue}
          className={classNames({
            'input--change-indicated': changeIndicated,
          })}
          {...rest}
        ></textarea>
      </BaseGroupComponent>
    );
  }
);

TextAreaGroupComponent.displayName = 'InputGroupComponent';

export default TextAreaGroupComponent;
