import styled from 'styled-components';

import { COLORS } from 'constants/colors';

const OverlayTitle = styled.div<{ center?: boolean; hideBorder?: boolean }>`
  font-size: 1.5em;
  font-weight: 800;
  color: ${COLORS.BLACK};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin-bottom: ${(props) => (props.hideBorder ? '0.6em' : '0')};

  &:after {
    display: ${(props) => (props.hideBorder ? 'none' : 'block')};
    margin: 0.6em 0;
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${COLORS.BORDERS};
  }
`;

export default OverlayTitle;
