import { computed, observable } from 'mobx';
import { deserialise } from 'kitsu-core';
import Routes from 'routes';

import { Subproduct } from 'types/model/subproduct';
import Grade from 'types/model/grade';
import Sample from 'types/model/ISample';
import SelectOptionArray from 'types/model/selectOption';

import SelectableSubproductModel from 'subproducts/models/SelectableSubproduct';

export interface SubproductsData {
  data: Array<Subproduct>;
  included: Array<Sample | Grade>;
}

type SubproductBody = {
  subproduct_id?: number;
  quantity: number | string;
};

type AssignedCoffeesBody = {
  assigned_coffee: SubproductBody[];
};

class SubproductsStore {
  private productionOrderId?: number;
  public subproducts = observable.array<SelectableSubproductModel>();
  public qualities: SelectOptionArray;

  constructor(
    subproducts: SubproductsData,
    productionOrderId: number | undefined,
    selectedSubproducts: Array<{ local_identifier: string; quantity: number }> = [],
    qualities: SelectOptionArray
  ) {
    const deserialisedSubproducts: Subproduct[] = deserialise(subproducts).data;

    const selectableSubproducts = deserialisedSubproducts.map(
      (subproduct: Subproduct) => new SelectableSubproductModel(subproduct)
    );

    this.subproducts = observable.array(selectableSubproducts);
    this.qualities = qualities;
    this.productionOrderId = productionOrderId;

    if (selectedSubproducts) {
      this.subproducts.forEach((subproduct: SelectableSubproductModel) =>
        selectedSubproducts.forEach((selectedSubproduct) => {
          if (selectedSubproduct.local_identifier === subproduct.attributes.identifier) {
            subproduct.setSelected(true);
            subproduct.setQuantity(selectedSubproduct.quantity.toString());
          }
        })
      );
    }
  }

  @computed get selectedSubproducts(): Array<SelectableSubproductModel> {
    return this.subproducts.filter((subproduct) => subproduct.selected);
  }

  @computed get totalWeight(): number {
    return this.selectedSubproducts.reduce(
      (totalWeight, { quantity }) => totalWeight + quantity,
      0
    );
  }

  @computed get submitMethod(): 'PATCH' | 'POST' {
    return this.productionOrderId ? 'PATCH' : 'POST';
  }

  @computed get submitPath(): string {
    let submitPath = Routes.subproducts_production_orders_path();

    if (this.productionOrderId) {
      submitPath = Routes.subproducts_production_order_path(this.productionOrderId);
    }

    return submitPath;
  }

  @computed get submitButtonText(): string {
    return this.productionOrderId
      ? I18n.translate('buttons.save')
      : I18n.translate('buttons.create');
  }

  @computed get isFormValid(): boolean {
    return this.selectedSubproducts.length > 0 && this.totalWeight > 0;
  }

  @computed get submitBody(): AssignedCoffeesBody {
    const data: AssignedCoffeesBody = { assigned_coffee: [] };

    this.selectedSubproducts.forEach((source) => {
      data.assigned_coffee.push({
        subproduct_id: source.attributes.id,
        quantity: source.quantity,
      });
    });

    return data;
  }
}

export default SubproductsStore;
