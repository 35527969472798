import { Provider, observer } from 'mobx-react';
import React from 'react';

import FractionsStore from 'productionOrders/stores/FractionsStore';
import FractionsComponent from 'productionOrders/components/FractionsComponent';
import OverlayStore from 'stores/OverlayStore';
import RoastingResult from 'types/utils/roastingResult';
import { ReasonGet } from 'types/model/reason';

import FractionType from 'types/model/fraction';
import MillingMachineType from 'types/model/millingMachine';

export interface FractionsContainerProps {
  estimatedWeight: number;
  maxWeight: string;
  fractions: FractionType[];
  roastingResults: RoastingResult[];
  roastingValidations: { pppt: { min: number; max: number } };
  humidityAnalysisAcceptingReasons: ReasonGet[];
  machines: MillingMachineType[];
  run: number;
  createdAt: string;
  cuppingSessionEnabled: boolean;
  id?: number;
}

function FractionsContainer({
  estimatedWeight,
  maxWeight,
  fractions,
  roastingResults,
  roastingValidations,
  humidityAnalysisAcceptingReasons,
  machines,
  run,
  createdAt,
  cuppingSessionEnabled,
}: FractionsContainerProps): JSX.Element {
  return (
    <Provider
      fractionsStore={
        new FractionsStore(
          estimatedWeight,
          maxWeight,
          fractions,
          roastingResults,
          roastingValidations,
          humidityAnalysisAcceptingReasons,
          machines
        )
      }
      overlayStore={new OverlayStore()}
    >
      <FractionsComponent
        run={run}
        createdAt={createdAt}
        cuppingSessionEnabled={cuppingSessionEnabled}
      />
    </Provider>
  );
}

export default observer(FractionsContainer);
