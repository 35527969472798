import { action, observable } from 'mobx';

import type AvailableMillingOutputType from 'availableCoffee/types/AvailableMillingOutput';

class AvailableMillingOutput {
  public attributes: AvailableMillingOutputType;

  @observable public quantity: string;
  @observable public selected: boolean;
  @observable public reserved: boolean;

  constructor(item: AvailableMillingOutputType) {
    this.attributes = item;
    this.selected = false;
    this.reserved = false;
    this.quantity = item.available_weight.toString();
  }

  @action public setQuantity = (value: string) => {
    this.quantity = value;
  };

  @action public setSelected = (selected: boolean) => {
    this.selected = selected;
  };

  @action public setReserved = (reserved: boolean) => {
    this.reserved = reserved;
  };

  get sourceId(): number {
    return this.attributes.id;
  }
}

export default AvailableMillingOutput;
