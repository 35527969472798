import React from 'react';
import styled from 'styled-components';

import Routes from 'routes';

import COLORS from 'constants/colors';
import {
  LotTraceability,
  LotSetTraceability,
  IcoTraceability,
} from '../types/traceability';

import TraceabilitiesList from './TraceabilitiesList';
import SelectOptionArray from 'types/model/selectOption';
import SearchBar, { FilterOptions, SearchBarFilterOptions } from './SearchBar';

import TraceabilityTabBar, { Tabs } from './TraceabilityTabBar';
import Pagynation, { Pagy } from 'shared/Pagynation';
import parseUSVString from 'utils/parseUSVString';
import ContractSearchBar, { ContractSearchBarFilterOptions } from './ContractsSearchBar';
import type {
  GeneralContractTraceability,
  InteralContractTraceability,
} from 'traceability/types/ContractTraceability';

export interface TraceabilitiesProps {
  traceabilities:
    | LotTraceability[]
    | LotSetTraceability[]
    | IcoTraceability[]
    | GeneralContractTraceability[]
    | InteralContractTraceability[];
  filters?: FilterOptions;
  specialMarks: SelectOptionArray;
  tabs: Tabs;
  pagy: Pagy;
}

const GrayContent = styled.div`
  min-height: calc(100vh - 10em);
  background: ${COLORS.LIGHT_GRAY};
`;

type tabConfiguration = {
  url: (...args) => string;
  searchBar: (filters: FilterOptions) => JSX.Element;
};

const tabsConfiguration: Record<string, tabConfiguration> = {
  lots: {
    url: Routes.lots_traceability_path,
    searchBar: (filters: SearchBarFilterOptions) => <SearchBar filters={filters} />,
  },
  lot_sets: {
    url: Routes.lot_sets_traceability_path,
    searchBar: (filters: SearchBarFilterOptions) => <SearchBar filters={filters} />,
  },
  millings: {
    url: Routes.millings_traceability_path,
    searchBar: (filters: SearchBarFilterOptions) => <SearchBar filters={filters} />,
  },
  icos: {
    url: Routes.icos_traceability_path,
    searchBar: (filters: SearchBarFilterOptions) => <SearchBar filters={filters} />,
  },
  contracts: {
    url: Routes.contracts_traceability_path,
    searchBar: (filters: ContractSearchBarFilterOptions) => (
      <ContractSearchBar filters={filters} />
    ),
  },
};

const Traceabilities: React.FC<TraceabilitiesProps> = ({
  traceabilities,
  filters,
  specialMarks,
  tabs,
  pagy,
  ...props
}) => {
  let activeTab = 'lots';

  Object.entries(tabs).find(([key, { active }]) => {
    if (active) {
      activeTab = key;
      return true;
    }
  });

  const tabConfiguration = tabsConfiguration[activeTab];

  const searchParams = parseUSVString(window.location?.search);

  return (
    <div {...props}>
      <div className='content'>
        {filters &&
          Object.keys(filters).length > 0 &&
          tabConfiguration.searchBar(filters)}
      </div>

      <GrayContent>
        <div className='content'>
          <TraceabilityTabBar tabs={tabs} />

          <TraceabilitiesList
            traceabilities={traceabilities}
            specialMarks={specialMarks}
          />
          <Pagynation
            pagy={pagy}
            url={tabConfiguration.url}
            searchParams={searchParams}
          />
        </div>
      </GrayContent>
    </div>
  );
};

export default Traceabilities;
