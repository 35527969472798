import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';

const DragHandleWrapper = styled.div`
  width: 3em;
  padding-right: 0.1em;
  cursor: grab;
  fill: ${COLORS.GRAY};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-out;

  &:hover {
    fill: ${COLORS.BLUE};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-right: thick solid ${COLORS.GRAY};
    transition: 0.2s ease-out;
  }

  &:hover::before {
    border-right: thick solid ${COLORS.BLUE};
  }
`;

const DragHandleIcon = styled.svg`
  height: 1.75em;
  width: 1.75em;
`;

function DragHandle() {
  return (
    <DragHandleWrapper>
      <DragHandleIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'>
        <rect fill='none'></rect>
        <circle cx='60' cy='60' r='16'></circle>
        <circle cx='128' cy='60' r='16'></circle>
        <circle cx='196' cy='60' r='16'></circle>
        <circle cx='60' cy='128' r='16'></circle>
        <circle cx='128' cy='128' r='16'></circle>
        <circle cx='196' cy='128' r='16'></circle>
        <circle cx='60' cy='196' r='16'></circle>
        <circle cx='128' cy='196' r='16'></circle>
        <circle cx='196' cy='196' r='16'></circle>
      </DragHandleIcon>
    </DragHandleWrapper>
  );
}

export default DragHandle;
