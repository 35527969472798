import { action, observable } from 'mobx';
import IConsolidateSample from '../../types/IConsolidateSample';
import ConsolidateSampleSelectable from '../models/ConsolidateSampleSelectable';

class SelectedSamplesStore {
  @observable public selectedSamples = observable.array<ConsolidateSampleSelectable>();
  public actionUrl: () => string;

  constructor(actionUrl: () => string) {
    this.actionUrl = actionUrl;
  }

  @action public toggleSampleSelection = (sample: ConsolidateSampleSelectable) => {
    if (sample.selected) {
      this.removeSample(sample);
    } else {
      this.addSample(sample);
    }
  };

  @action private addSample = (sample: ConsolidateSampleSelectable) => {
    // Select sample
    sample.selected = true;
    this.selectedSamples.push(sample);
  };

  @action private removeSample = (sample: ConsolidateSampleSelectable) => {
    this.selectedSamples.remove(sample);

    sample.selected = false;
  };

  public findSample = (sample: IConsolidateSample) => {
    return this.selectedSamples.find(
      (selectedSample) => selectedSample.attributes.id === sample.id
    );
  };

  public isSelected = (id: string | number): boolean => {
    const index = this.selectedSamples.findIndex((selectedSample) => {
      return selectedSample.attributes.id.toString() === id.toString();
    });
    return index > 0;
  };

  @action public setSampleSize = (sample: ConsolidateSampleSelectable, value: string) => {
    sample.attributes.sampleSize = value;

    if (!sample.attributes.sampleSize) {
      this.removeSample(sample);
    }
  };

  @action public setSampleReservedSize = (sample: IConsolidateSample, value: string) => {
    if (
      sample.source_current_weight_string &&
      Number(value) <= Number(sample.source_current_weight_string.replace('kg', ''))
    ) {
      sample.reservedSize = value;
    }
  };
}

export default SelectedSamplesStore;
