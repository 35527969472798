import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import COLORS from 'constants/colors';

import Grid from 'components/elements/Grid';
import InputGroup from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import FormFieldSet from 'exports/components/FormFieldSet';
import TransitGuideModel from 'exports/models/TransitGuide';
import useStores from 'utils/useStores';
import AccordionFormBody from '../AccordionFormBody';
import CustomsOfficer from 'exports/types/customsOfficer';
import Consignee from 'exports/types/consignee';
import transformStringToNumber from 'utils/transformStringToNumber';
import type TransitGuideType from '../../types/transitGuide';

export interface TransitGuideProps {
  transitGuide: TransitGuideModel;
  customsOfficer: CustomsOfficer;
  consignee: Consignee;
  originCountryId?: number;
}

const TransitGuideWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

function TransitGuide({
  transitGuide,
  customsOfficer,
  consignee,
  originCountryId,
}: TransitGuideProps) {
  const {
    exportsStore: { docks, ports, transportCompanies, customsBrokers },
  } = useStores();

  const {
    register,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: transitGuide,
  });

  const selectablePorts = React.useMemo(
    () => ports.filter(({ country_id }) => country_id === originCountryId),
    [originCountryId]
  );

  const selectableDocks = React.useMemo(
    () => docks.filter(({ port: { id } }) => id === watch('port_id')),
    [watch('port_id')]
  );

  const handleOnSubmitClick = handleSubmit(async (data: TransitGuideType) => {
    await transitGuide.save(data);

    reset(data);
  });

  const handleOnFinalizeClick = handleSubmit(async () => {
    await transitGuide.finalize();
  });

  return (
    <TransitGuideWrapper>
      <AccordionFormBody
        finalized={transitGuide.finalized}
        isFinalizable={transitGuide.isFinalizable}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onSubmitClick={handleOnSubmitClick}
        onFinalizeClick={handleOnFinalizeClick}
        downloadUrl={transitGuide.downloadUrl}
      >
        <FormFieldSet disabled={transitGuide.finalized}>
          <Grid grid={16}>
            <SelectGroup
              label={I18n.translate('exports.form.transit_guide.port_label')}
              placeholder={I18n.translate('exports.form.transit_guide.port_placeholder')}
              options={selectablePorts}
              ref={register({ setValueAs: transformStringToNumber })}
              name='port_id'
            />

            <SelectGroup
              label={I18n.translate('exports.form.transit_guide.dock_label')}
              placeholder={I18n.translate('exports.form.transit_guide.dock_placeholder')}
              options={selectableDocks}
              ref={register({ setValueAs: transformStringToNumber })}
              name='dock_id'
            />
          </Grid>
        </FormFieldSet>

        <FormFieldSet disabled={transitGuide.finalized}>
          <Grid grid={16}>
            <InputGroup
              id='transit_number'
              label={I18n.translate('exports.form.transit_guide.transit_number_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.transit_number_placeholder'
              )}
              ref={register}
              name='number'
            />

            <InputGroup
              id='transit_revision_number'
              label={I18n.translate('exports.form.transit_guide.revision_number_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.revision_number_placeholder'
              )}
              ref={register}
              name='revision_number'
            />

            <InputGroup
              id='customs_officer'
              label={I18n.translate('exports.form.transit_guide.customs_officer_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.customs_officer_placeholder'
              )}
              disabled={true}
              value={customsOfficer.name}
            />

            <InputGroup
              id='addressee'
              label={I18n.translate('exports.form.transit_guide.addressee_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.addressee_placeholder'
              )}
              disabled={true}
              value={consignee?.name}
            />

            <SelectGroup
              id='transporter'
              label={I18n.translate('exports.form.transit_guide.transporter_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.transporter_placeholder'
              )}
              options={transportCompanies}
              ref={register({ setValueAs: transformStringToNumber })}
              name='transport_company_id'
            />

            <InputGroup
              id='vehicle_registration'
              label={I18n.translate(
                'exports.form.transit_guide.vehicle_registration_label'
              )}
              placeholder={I18n.translate(
                'exports.form.transit_guide.vehicle_registration_placeholder'
              )}
              ref={register}
              name='vehicle_registration'
            />

            <InputGroup
              id='trailer_no'
              label={I18n.translate('exports.form.transit_guide.trailer_number_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.trailer_number_placeholder'
              )}
              ref={register}
              name='trailer_registration'
            />

            <InputGroup
              id='container'
              label={I18n.translate('exports.form.transit_guide.container_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.container_placeholder'
              )}
              ref={register}
              name='container'
            />

            <InputGroup
              id='tags'
              label={I18n.translate('exports.form.transit_guide.tags_label')}
              placeholder={I18n.translate('exports.form.transit_guide.tags_placeholder')}
              ref={register}
              name='tag'
            />

            <SelectGroup
              label={I18n.translate('exports.form.transit_guide.customs_broker_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.customs_broker_placeholder'
              )}
              options={customsBrokers}
              ref={register({ setValueAs: transformStringToNumber })}
              name='customs_broker_id'
            />
          </Grid>
        </FormFieldSet>

        <FormFieldSet disabled={transitGuide.finalized}>
          <Grid grid={16}>
            <InputGroup
              id='driver_personal_id'
              label={I18n.translate(
                'exports.form.transit_guide.drivers_personal_id_label'
              )}
              placeholder={I18n.translate(
                'exports.form.transit_guide.drivers_personal_id_placeholder'
              )}
              ref={register}
              name='driver_personal_id'
            />

            <InputGroup
              id='driver_license'
              label={I18n.translate('exports.form.transit_guide.drivers_license_label')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.drivers_license_placeholder'
              )}
              ref={register}
              name='driver_licence'
            />

            <InputGroup
              id='driver_name'
              label={I18n.translate('exports.form.transit_guide.drivers_name')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.drivers_name_placeholder'
              )}
              ref={register}
              name='driver_name'
            />

            <InputGroup
              id='driver_surname'
              label={I18n.translate('exports.form.transit_guide.drivers_surname')}
              placeholder={I18n.translate(
                'exports.form.transit_guide.drivers_surname_placeholder'
              )}
              ref={register}
              name='driver_surname'
            />
          </Grid>
        </FormFieldSet>
      </AccordionFormBody>
    </TransitGuideWrapper>
  );
}

export default observer(TransitGuide);
