import { observer } from 'mobx-react';
import React from 'react';

import Certificate from 'types/model/certificate';
import Grade from 'types/model/grade';
import ProcessType from 'types/model/processType';
import Quality from 'types/model/quality';
import Variety from 'types/model/variety';
import Municipality from 'types/model/municipality';

import { useFilterContext } from 'utils/filterContext';
import useStores from 'utils/useStores';

import LotFilter from './LotFilter';
import LotTable from './LotTable';
import SelectedLots from './SelectedLots';
import Sample from 'types/model/ISample';
import type { JsonApiResource } from 'types/utils/jsonApi';

type Filter =
  | Sample
  | Certificate
  | Grade
  | ProcessType
  | Quality
  | Variety
  | Municipality;

export interface CreateSetProps {
  included: Array<JsonApiResource<Filter>>;
}

function CreateSet({ included }: CreateSetProps) {
  const { setStore } = useStores();

  const { filters } = useFilterContext();

  const filteredLots = React.useMemo(
    () =>
      setStore.lots.filter((lot) =>
        Object.entries(filters).every(([key, { filter, value }]) =>
          value ? filter(lot.attributes[key], value) : true
        )
      ),
    [filters]
  );

  const certificates = (
    included.filter(
      ({ type }) => type === 'certificate'
    ) as JsonApiResource<Certificate>[]
  ).map(({ attributes }) => attributes.name);

  const processTypes = (
    included.filter(({ type }) => type === 'process_type') as Array<
      JsonApiResource<ProcessType>
    >
  ).map(({ attributes }) => attributes.name);

  const varieties = (
    included.filter(({ type }) => type === 'variety') as Array<JsonApiResource<Variety>>
  ).map(({ attributes }) => attributes.name);

  const qualities = (
    included.filter(({ type }) => type === 'quality') as Array<JsonApiResource<Quality>>
  ).map(({ attributes }) => attributes.code);

  const municipalities = (
    included.filter(({ type }) => type === 'municipality') as Array<
      JsonApiResource<Municipality>
    >
  ).map(({ attributes }) => attributes.name);

  return (
    <>
      <SelectedLots />

      <LotFilter
        options={{ processTypes, qualities, varieties, certificates, municipalities }}
      />

      <LotTable lots={filteredLots} />
    </>
  );
}

export default observer(CreateSet);
