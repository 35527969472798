import React from 'react';
import styled from 'styled-components';
import SelectOptionArray from 'types/model/selectOption';

import {
  LotTraceability,
  LotSetTraceability,
  IcoTraceability as IcoTraceabilityType,
  MillingTraceability as MillingTraceabilityType,
} from '../types/traceability';

import type {
  GeneralContractTraceability,
  InteralContractTraceability,
} from '../types/ContractTraceability';

import LotLotSetTraceabilityItem from './TraceabilityItem';
import IcoTraceability from './IcoTraceability';
import ContractTraceability from './ContractTraceability';
import MillingTraceability from './MillingTraceability';

export interface TraceabilitiesListProps extends React.HTMLAttributes<HTMLDivElement> {
  traceabilities:
    | LotTraceability[]
    | LotSetTraceability[]
    | IcoTraceabilityType[]
    | GeneralContractTraceability[]
    | InteralContractTraceability[]
    | MillingTraceabilityType[];

  specialMarks: SelectOptionArray;
}

const traceabilityFactory = {
  lot: LotLotSetTraceabilityItem,
  ico: IcoTraceability,
  contract: ContractTraceability,
  milling: MillingTraceability,
};

const TraceabilitiesList = ({
  traceabilities,
  specialMarks,
  ...props
}: TraceabilitiesListProps) => (
  <div {...props} data-cy='traceabilities_list'>
    {traceabilities.map(
      (
        traceability:
          | LotTraceability
          | LotSetTraceability
          | IcoTraceabilityType
          | GeneralContractTraceability
          | InteralContractTraceability
          | MillingTraceabilityType,
        index
      ) => {
        let traceabilityItem: React.ReactNode;

        if (traceability.type === 'ico') {
          const Component = traceabilityFactory.ico;
          traceabilityItem = <Component key={index} traceability={traceability} />;
        } else if (
          traceability.type === 'contract' ||
          traceability.type === 'internal_contract'
        ) {
          const Component = traceabilityFactory.contract;

          traceabilityItem = <Component key={index} traceability={traceability} />;
        } else if (traceability.type === 'milling') {
          const Component = traceabilityFactory.milling;

          traceabilityItem = <Component key={index} traceability={traceability} />;
        } else {
          const Component = traceabilityFactory.lot;

          traceabilityItem = (
            <Component
              key={index}
              traceability={traceability}
              specialMarks={specialMarks}
            />
          );
        }

        return traceabilityItem;
      }
    )}
  </div>
);

export default styled(TraceabilitiesList)`
  display: grid;
  grid-row-gap: 0.625em;
`;
