import ControlledSearch from 'components/elements/ControlledSearch';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { SampleDataContext } from 'samples/create/components/CreateSample';

const SampleMarkSelect = (): JSX.Element => {
  const { control } = useFormContext();
  const { marks } = useContext(SampleDataContext);

  return (
    <ControlledSearch
      label={I18n.translate('samples.create.mark')}
      dataCy='select_mark'
      prompt={I18n.translate('samples.create.select_mark')}
      options={marks}
      control={control}
      name='sample[mark_id]'
      required
    />
  );
};
export default SampleMarkSelect;
