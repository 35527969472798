import React from 'react';
import Routes from 'routes';

import Sample from 'types/model/ISample';

import { NewTestCard } from 'shared/LotInfoBox';
import { TestResult } from 'shared/TestGrid';
import IPhysicalAnalysisResult from 'samples/types/IPhysicalAnalysisResult';
import useStores from 'utils/useStores';

export interface PhysicalAnalysesProps {
  sample: Sample;
  physicalAnalyses: IPhysicalAnalysisResult[];
  enableNewButton: boolean;
}

function PhysicalAnalyses({
  sample,
  physicalAnalyses,
  enableNewButton,
}: PhysicalAnalysesProps): JSX.Element {
  const { analyseAndAdjustStore } = useStores();
  const handleOnButtonClick = (event: React.MouseEvent) => {
    if (!enableNewButton) {
      event.preventDefault();
      analyseAndAdjustStore.setEnableFlash(true);
    }
  };
  return (
    <div>
      {physicalAnalyses.map((physicalAnalysis) => (
        <TestResult key={physicalAnalysis.id} test={physicalAnalysis} />
      ))}

      <NewTestCard>
        <a
          onClick={handleOnButtonClick}
          className={`button button--small button--${enableNewButton ? 'green' : 'gray'}`}
          href={Routes.new_sample_physical_analysis_path(sample.id)}
        >
          + {I18n.translate('buttons.new')}
        </a>
      </NewTestCard>
    </div>
  );
}

export default PhysicalAnalyses;
