import React from 'react';
import Routes from 'routes';
import Can from 'components/shared/Can';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';
import OneClickButton from 'shared/OneClickButton';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import AddMark from './AddMark';
import ProductionOrder from '../types/ProductionOrder';

export interface ActionsColumnProps {
  productionOrder: ProductionOrder;
}

function ActionsColumn({ productionOrder }: ActionsColumnProps) {
  const {
    milling_order_id,
    mark,
    id,
    sendable,
    contract: { approval_requested },
  } = productionOrder;

  if (milling_order_id) {
    return (
      <TextWithSub
        text={I18n.translate('milling.production_orders.sent')}
        sub={milling_order_id}
      />
    );
  }

  if (mark || approval_requested) {
    return (
      <Can I='send' a='ProductionOrder'>
        <form action={Routes.milling_orders_path()} acceptCharset='UTF-8' method='post'>
          <AuthenticityToken />

          <input type='hidden' name='production_order_id' value={id} />
          <OneClickButton
            type='submit'
            className='button button--ghost button--small'
            disabled={!(mark && sendable)}
            data-cy='send_production_order'
          >
            {I18n.translate('buttons.send')}
          </OneClickButton>
        </form>
      </Can>
    );
  } else {
    return <AddMark productionOrder={productionOrder} />;
  }
}

export default ActionsColumn;
