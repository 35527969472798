import React from 'react';
import { SpotContract } from './useSpotContracts';
import WSRSpotContractsTableRow from './WSRSpotContractTableRow';

export interface ISelectableSpotContract extends SpotContract {
  selected: boolean;
}

interface IWSRSpotContractsTableProps {
  spotContracts: ISelectableSpotContract[];
  onSelect: (index: number, spotContract: ISelectableSpotContract) => void;
  onDeselect: (spotContract: ISelectableSpotContract | number) => void;
}

const WSRSpotContractsTable = ({
  spotContracts,
  onSelect,
  onDeselect,
}: IWSRSpotContractsTableProps): JSX.Element => {
  return (
    <table data-cy='WSRIcosTable'>
      <thead>
        <tr>
          <th className='table-border align-left'>
            {I18n.translate('activerecord.models.spot_contract')}
          </th>

          <th className='table-border'>
            {I18n.translate('activerecord.models.customer')}
          </th>

          <th className='table-border'>{I18n.translate('attributes.quantity')}</th>

          <th className=''>{I18n.translate('buttons.request_samples')}</th>
        </tr>
      </thead>

      <tbody>
        {spotContracts.map((spotContract, index) => {
          return (
            <WSRSpotContractsTableRow
              spotContract={spotContract}
              key={spotContract.id}
              onSelect={() => onSelect(index, spotContract)}
              onDeselect={() => onDeselect(spotContract)}
            />
          );
        })}
      </tbody>
    </table>
  );
};
export default WSRSpotContractsTable;
