import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Routes from 'routes';
import COLORS from 'constants/colors';
import Grid from 'components/elements/Grid';
import useStores from 'utils/useStores';

const TransportWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

function Transport(): JSX.Element {
  const {
    exportsStore: { selectedExport },
  } = useStores();

  let button: React.ReactNode;

  if (selectedExport) {
    const transport = selectedExport.shipmentSettings?.transport;

    if (transport) {
      const { state } = transport;

      switch (state) {
        case 'initialized':
          button = (
            <a
              className='button button--second'
              href={Routes.new_export_transport_transport_leg_path(selectedExport?.id)}
            >
              {I18n.translate('exports.transport.add_transport_leg')}
            </a>
          );
          break;
        case 'in_transit':
          button = (
            <a
              className='button button--second'
              href={Routes.unload_export_transport_path(selectedExport?.id)}
            >
              {I18n.translate('exports.transport.unload_transport')}
            </a>
          );
          break;
        case 'completed':
          button = (
            <a
              className='button button--second'
              href={Routes.print_export_transport_path(selectedExport?.id)}
              target='_blank'
              rel='noreferrer'
            >
              {I18n.translate('exports.transport.open_transport_document')}
            </a>
          );
          break;
      }
    } else {
      button = (
        <a
          className='button button--second'
          href={Routes.new_export_transport_path(selectedExport.id)}
        >
          {I18n.translate('exports.transport.create_transport')}
        </a>
      );
    }
  }

  return (
    <TransportWrapper>
      <Grid grid={16}>{button}</Grid>
    </TransportWrapper>
  );
}

export default observer(Transport);
