import { observer, Provider } from 'mobx-react';
import React from 'react';
import PaginatedTile from './components/PaginatedTile';
import TableWrapper from './components/TableWrapper';
import PaginatedTableStore from './stores/PaginatedTableStore';

interface PaginatedTableProps<T> {
  renderTableHeader: (dataRow: T) => React.ReactNode;
  renderTableData: (dataRow: T) => React.ReactNode;
  renderSelectedData?: () => React.ReactNode;
  renderTopBar?: () => React.ReactNode;
  paginatedTableStore: PaginatedTableStore<T>;
  hasSelectedData: boolean;
}

function PaginatedTable<T>({
  renderTableHeader,
  renderTableData,
  renderSelectedData,
  renderTopBar,
  paginatedTableStore,
  hasSelectedData,
}: PaginatedTableProps<T>) {
  return (
    <Provider paginatedTableStore={paginatedTableStore}>
      {renderSelectedData ? (
        <PaginatedTile
          renderSelectedData={renderSelectedData}
          hasSelectedData={hasSelectedData}
        >
          {renderTopBar && renderTopBar()}
        </PaginatedTile>
      ) : (
        renderTopBar && renderTopBar()
      )}
      <TableWrapper
        renderTableData={renderTableData}
        renderTableHeader={renderTableHeader}
      />
    </Provider>
  );
}

export default observer(PaginatedTable);
