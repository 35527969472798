import { observer } from 'mobx-react';
import React from 'react';
import Routes from 'routes';

import { LOT_STATE } from 'types/model/lot';

import { customFetch } from 'utils/fetch';
import useStores from 'utils/useStores';

import Icon from 'components/elements/Icon';
import Checkbox from 'shared/Checkbox';

import HumidityAnalysis from 'shared/HumidityAnalysis';
import Roasting from 'shared/Roasting';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import ISample from '../types/ISample';

interface OverviewTableRowProps {
  sample: ISample;
  roastingSampleWeight: number;
}

function OverviewTableRow({
  sample,
  roastingSampleWeight,
}: OverviewTableRowProps): JSX.Element {
  const { overviewTableStore } = useStores();
  const { acceptingReasons, roastingResults } = overviewTableStore;

  const {
    id,
    current_humidity_analysis,
    current_physical_analysis,
    current_roasting,
    created_at,
    humidity_analysis_validations,
    roasting_validations,
  } = sample;

  const lot = sample.lot || null;
  const parent = lot || sample.lot_set || null;
  const weight_string = lot ? lot.weight_string : sample.weight_string_g;
  const state = lot ? lot.state : '-';
  const local_identifier = parent ? parent.local_identifier : sample.frontend_identifier;

  const handleOnHumidityPrimaryClick = (
    _event: React.MouseEvent,
    values: {}
  ): Promise<void> =>
    customFetch(
      Routes.lot_humidity_analysis_path(lot.id),
      values,
      current_humidity_analysis ? 'PATCH' : 'POST'
    ).then(() => {
      window.location.reload();
    });

  const handlePhysicalAnalysisClick = () => {
    if (lot) {
      window.location.href = Routes.edit_lot_physical_analysis_path(lot.id);
    } else {
      window.location.href = Routes.edit_sample_physical_analyses_path(sample.id);
    }
  };

  const handleOnRoastingPrimaryClick = (
    _event: React.MouseEvent,
    values: {}
  ): Promise<void> =>
    customFetch(Routes.lot_roasting_path(lot.id), values).then(() => {
      window.location.reload();
    });

  const handleCheckboxChange = () => {
    overviewTableStore.toggleSelectedLot(id);
  };

  return (
    <tr>
      <td className='lots-index--edit align-center'>
        {lot && (
          <a href={Routes.edit_lot_path(lot.id)}>
            <Icon icon='pen' />
          </a>
        )}
      </td>

      <td className='lots-index--delivery'>{I18n.l('time.formats.short', created_at)}</td>

      <td className='lot--id'>
        {(sample.considered_for_cupping && (
          <TextWithSub
            text={local_identifier}
            textClass='lot--id'
            sub={sample.local_identifier}
            subClass='lot--id'
          />
        )) ||
          local_identifier}
      </td>

      <td className='lots-index--weight align-right table-border'>{weight_string}</td>

      <td className='align-center table-border'>
        {(current_humidity_analysis && (
          <HumidityAnalysis
            identifier={local_identifier}
            validations={humidity_analysis_validations}
            humidityAnalysis={current_humidity_analysis}
            reasons={acceptingReasons}
            text={I18n.translate('shared.status.passed')}
            status={current_humidity_analysis.passed}
            icon={current_humidity_analysis.accepting_reason_id ? 'failed' : 'passed'}
            lotFailed={state === LOT_STATE.FAILED}
            onPrimaryClick={handleOnHumidityPrimaryClick}
          />
        )) ||
          '-'}
      </td>

      <td className='align-center table-border'>
        {(current_physical_analysis && (
          <div
            className={`status status--${!current_physical_analysis.accepting_reason_id}`}
            onClick={handlePhysicalAnalysisClick}
          >
            {I18n.translate('shared.status.passed')}
            <svg className='icon'>
              <use
                xlinkHref={`#${
                  current_physical_analysis.accepting_reason_id ? 'failed' : 'passed'
                }`}
              ></use>
            </svg>
          </div>
        )) ||
          '-'}
      </td>

      <td className='align-center lots-index--roasting table-border'>
        {(current_roasting && (
          <Roasting
            identifier={local_identifier}
            results={roastingResults}
            roasting={current_roasting}
            roastingSampleWeight={roastingSampleWeight}
            validations={roasting_validations}
            text={I18n.translate(
              `widgets.lot_status_table.${current_roasting.passed ? 'done' : 'failed'}`
            )}
            status={current_roasting.passed}
            icon={current_roasting.passed ? 'passed' : 'failed'}
            onPrimaryClick={handleOnRoastingPrimaryClick}
          />
        )) ||
          '-'}
      </td>

      <td>
        <Checkbox
          checked={overviewTableStore.selectedSamples.toJS().includes(id)}
          name={`samples[${id}]`}
          dataCy={`select_sample_checkbox`}
          value={id.toString()}
          onChange={handleCheckboxChange}
        />
      </td>
    </tr>
  );
}

export default observer(OverviewTableRow);
