import { Provider } from 'mobx-react';
import React from 'react';

import type RoastingResult from 'types/utils/roastingResult';
import type { ReasonGet } from 'types/model/reason';
import type { JsonApi } from 'types/utils/jsonApi';

import OverlayStore from 'stores/OverlayStore';
import SampleQaRow from 'millingOrders/components/SampleQaRow';
import MillingOrderQaStore from 'millingOrders/stores/MillingOrderQaStore';
import type MillingOutputType from 'millingOrders/types/MillingOutput';
import type { Validations } from 'shared/Roasting/components/RoastingOverlay';

export interface SampleQaContainerProps {
  humidityAnalysisAcceptingReasons: ReasonGet[];
  roastingValidations: Validations;
  roastingResults: RoastingResult[];
  roastingSampleWeight: number;
  weight: number;
  millingOutput: JsonApi<MillingOutputType>;
  cuppingSessionEnabled: boolean;
}

const SampleQaContainer = ({
  humidityAnalysisAcceptingReasons,
  roastingValidations,
  roastingResults,
  weight,
  millingOutput,
  roastingSampleWeight,
  cuppingSessionEnabled,
}: SampleQaContainerProps) => {
  return (
    <Provider
      millingOrderQaStore={
        new MillingOrderQaStore(
          millingOutput,
          humidityAnalysisAcceptingReasons,
          roastingValidations,
          roastingResults,
          roastingSampleWeight,
          cuppingSessionEnabled
        )
      }
      overlayStore={new OverlayStore()}
    >
      <SampleQaRow weight={weight} />
    </Provider>
  );
};

export default SampleQaContainer;
