import React from 'react';

import DeliveryOrder from 'traceability/types/DeliveryOrder';
import DeliveryOrderItem from './DeliveryOrderItem';

interface DeliveryOrderListProps {
  deliveryOrders: DeliveryOrder[];
}

function DeliveryOrderList({ deliveryOrders, ...props }: DeliveryOrderListProps) {
  return (
    <div {...props}>
      {deliveryOrders.map((deliveryOrder) => (
        <DeliveryOrderItem key={deliveryOrder.identifier} deliveryOrder={deliveryOrder} />
      ))}
    </div>
  );
}

export default DeliveryOrderList;
