import { Provider } from 'mobx-react';
import React from 'react';

import { ISampleTransport } from 'samples/receive_samples/types/ISampleTransport';
import SelectOptionArray from 'samples/receive_samples/types/SelectOption';

import ReceiveSampleTransports from 'samples/receive_samples/components/ReceiveSampleTransports';
import ReceiveSampleTransportsStore from 'samples/receive_samples/stores/ReceiveSampleTransportsStore';
import { JsonApiResource } from 'types/utils/jsonApi';

interface ReceiveSampleTransportsContainerProps {
  transports: JsonApiResource<ISampleTransport>;
  receptionDifferences: SelectOptionArray;
  receptionDamages: SelectOptionArray;
}

function ReceiveSampleTransportsContainer({
  transports,
  receptionDifferences,
  receptionDamages,
}: ReceiveSampleTransportsContainerProps): JSX.Element {
  return (
    <Provider
      receiveSampleTransportsStores={
        new ReceiveSampleTransportsStore(
          transports,
          receptionDifferences,
          receptionDamages
        )
      }
    >
      <ReceiveSampleTransports />
    </Provider>
  );
}

export default ReceiveSampleTransportsContainer;
