import { Provider } from 'mobx-react';
import React from 'react';
import ConsolidateSamplesStore from 'samples/consolidate/stores/ConsolidateSamplesStore';
import ConsolidateSamples from '../components/ConsolidateSamples';
import SelectOptionArray from 'types/model/selectOption';

interface ConsolidateSamplesContainerProps {
  qualities: SelectOptionArray;
  sampleTypes: SelectOptionArray;
  marks: SelectOptionArray;
  selectedSampleType?: number;
}

function ConsolidateSamplesContainer({
  sampleTypes,
  marks,
  qualities,
  selectedSampleType,
}: ConsolidateSamplesContainerProps): JSX.Element {
  return (
    <Provider
      consolidateSamplesStore={
        new ConsolidateSamplesStore(sampleTypes, marks, qualities, selectedSampleType)
      }
    >
      <ConsolidateSamples />
    </Provider>
  );
}

export default ConsolidateSamplesContainer;
