/**
 * File generated by js-routes RubyVariables.GEM_VERSION
 * Based on Rails RubyVariables.RAILS_VERSION routes of RubyVariables.APP_CLASS
 */
declare type Optional<T> = {
    [P in keyof T]?: T[P] | null;
};
declare type BaseRouteParameter = string | boolean | Date | number;
declare type MethodRouteParameter = BaseRouteParameter | (() => BaseRouteParameter);
declare type ModelRouteParameter = {
    id: MethodRouteParameter;
} | {
    to_param: MethodRouteParameter;
} | {
    toParam: MethodRouteParameter;
};
declare type RequiredRouteParameter = BaseRouteParameter | ModelRouteParameter;
declare type OptionalRouteParameter = undefined | null | RequiredRouteParameter;
declare type QueryRouteParameter = OptionalRouteParameter | QueryRouteParameter[] | {
    [k: string]: QueryRouteParameter;
};
declare type RouteParameters = Record<string, QueryRouteParameter>;
declare type Serializable = Record<string, unknown>;
declare type Serializer = (value: Serializable) => string;
declare type RouteHelperExtras = {
    requiredParams(): string[];
    toString(): string;
};
declare type RequiredParameters<T extends number> = T extends 1 ? [RequiredRouteParameter] : T extends 2 ? [RequiredRouteParameter, RequiredRouteParameter] : T extends 3 ? [RequiredRouteParameter, RequiredRouteParameter, RequiredRouteParameter] : T extends 4 ? [
    RequiredRouteParameter,
    RequiredRouteParameter,
    RequiredRouteParameter,
    RequiredRouteParameter
] : RequiredRouteParameter[];
declare type RouteHelperOptions<T extends string> = RouteOptions & Optional<Record<T, OptionalRouteParameter>>;
declare type RouteHelper<T extends number = number, U extends string = string> = ((...args: [...RequiredParameters<T>, RouteHelperOptions<U>]) => string) & RouteHelperExtras;
declare type RouteHelpers = Record<string, RouteHelper>;
declare type Configuration = {
    prefix: string;
    default_url_options: RouteParameters;
    special_options_key: string;
    serializer: Serializer;
};
interface RouterExposedMethods {
    config(): Configuration;
    configure(arg: Partial<Configuration>): Configuration;
    serialize: Serializer;
}
declare type KeywordUrlOptions = Optional<{
    host: string;
    protocol: string;
    subdomain: string;
    port: string | number;
    anchor: string;
    trailing_slash: boolean;
}>;
declare type RouteOptions = KeywordUrlOptions & RouteParameters;
declare type PartsTable = Record<string, {
    r?: boolean;
    d?: OptionalRouteParameter;
}>;
declare type ModuleType = "CJS" | "AMD" | "UMD" | "ESM" | "DTS" | "NIL";
declare const RubyVariables: {
    PREFIX: string;
    DEPRECATED_GLOBBING_BEHAVIOR: boolean;
    SPECIAL_OPTIONS_KEY: string;
    DEFAULT_URL_OPTIONS: RouteParameters;
    SERIALIZER: Serializer;
    NAMESPACE: string;
    ROUTES_OBJECT: RouteHelpers;
    MODULE_TYPE: ModuleType;
    WRAPPER: <T>(callback: T) => T;
};
declare const define: undefined | (((arg: unknown[], callback: () => unknown) => void) & {
    amd?: unknown;
});
declare const module: {
    exports: any;
} | undefined;
export const configure: RouterExposedMethods['configure'];

export const config: RouterExposedMethods['config'];

export const serialize: RouterExposedMethods['serialize'];

/**
 * Generates rails route to
 * /accept_price(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_price_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /accept_price/accept/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_price_accept_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /accept_price/recup/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_price_recup_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customers/:id/account_integration(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_integration_basedata_customer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/contracts/:id/add_mark(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_mark_api_v1_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:id/adjust_weight(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adjust_weight_api_v1_sample_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/analyse_and_adjust(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const analyse_and_adjust_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/lots/:id/annul(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const annul_api_v1_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/business/v1/fulfillment/delivery_orders/:delivery_order_id/accept(.:format)
 * @param {any} delivery_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_business_v1_fulfillment_delivery_order_accept_path: ((
  delivery_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/business/v1/fulfillment/delivery_orders/:delivery_order_id/reject(.:format)
 * @param {any} delivery_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_business_v1_fulfillment_delivery_order_reject_path: ((
  delivery_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/availability_notices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_availability_notice_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/bundles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_bundle_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/contract_prices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_contract_prices_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/customer_samples/customer_sample_values/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_customer_samples_customer_sample_value_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/customer_samples/customer_sample_values(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_customer_samples_customer_sample_values_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/customer_samples/opportunities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_customer_samples_opportunities_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/decaf/decaf_orders/:decaf_order_id/humidity_analyses(.:format)
 * @param {any} decaf_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_decaf_decaf_order_humidity_analyses_path: ((
  decaf_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/destination_ports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_destination_port_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/drivers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_driver_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/availability_notices(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_availability_notices_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/ico_lists(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_ico_lists_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/importer_security_fillings(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_importer_security_fillings_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/packing_declarations(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_packing_declarations_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/packing_lists(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_packing_lists_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/responsibility_letters(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_responsibility_letters_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/shipment_instructions(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_shipment_instructions_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/shipping_advices(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_shipping_advices_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/transit_guides(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_export_transit_guides_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/shipping_line_contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_exports_shipping_line_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/third_party_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_exports_third_party_imports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/farms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_farm_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fractions/:fraction_id/fraction_reprocesses/:id(.:format)
 * @param {any} fraction_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_fraction_fraction_reprocess_path: ((
  fraction_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fractions/:fraction_id/fraction_reprocesses(.:format)
 * @param {any} fraction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_fraction_fraction_reprocesses_path: ((
  fraction_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_fulfillment_delivery_orders_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/get_spot_inventory(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_fulfillment_get_spot_inventory_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/ico_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_fulfillment_ico_list_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/ico_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_ico_list_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/importer_security_fillings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_importer_security_filling_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/lots/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/lots/:lot_id/final_prices/:id(.:format)
 * @param {any} lot_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_lot_final_price_path: ((
  lot_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/lot_sets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_lot_set_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/lot_sets/:lot_set_id/lots/:id(.:format)
 * @param {any} lot_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_lot_set_lot_path: ((
  lot_set_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/milling_orders/:milling_order_id/fractions/:id(.:format)
 * @param {any} milling_order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_milling_order_fraction_path: ((
  milling_order_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/milling_orders/:milling_order_id/fractions(.:format)
 * @param {any} milling_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_milling_order_fractions_path: ((
  milling_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/packing_declarations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_packing_declaration_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/packing_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_packing_list_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/producers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_producer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/production_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_production_log_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/production_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_production_logs_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/production_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_production_orders_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/responsibility_letters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_responsibility_letter_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:sample_id/humidity_analyses(.:format)
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_sample_humidity_analyses_path: ((
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:sample_id/physical_analyses(.:format)
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_sample_physical_analyses_path: ((
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:sample_id/physical_analyses/:id(.:format)
 * @param {any} sample_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_sample_physical_analysis_path: ((
  sample_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:sample_id/roastings/:id(.:format)
 * @param {any} sample_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_sample_roasting_path: ((
  sample_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:sample_id/roastings(.:format)
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_sample_roastings_path: ((
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/create_arrival_sample(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_create_arrival_sample_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/create_mill_sample(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_create_mill_sample_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/create_spot_sample(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_create_spot_sample_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/destination_warehouses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_destination_warehouses_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/icos/arrival_sample(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_icos_arrival_sample_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/icos/mill_sample(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_icos_mill_sample_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/icos/spot_sample(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_icos_spot_sample_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/spot_contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_spot_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/transport_samples/paginated_index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_samples_transport_samples_paginated_index_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/shipment_instructions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_shipment_instruction_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/shipping_advices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_shipping_advice_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/transit_guides/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_transit_guide_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/warehouse_sampling_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_warehouse_sampling_requests_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/approval(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approval_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/:id/approve_by_caravela(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_by_caravela_fixed_price_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/:id/approve_by_producer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_by_producer_fixed_price_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:contract_id/contract_versions/:id/approve_reject(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_reject_contract_contract_version_path: ((
  contract_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /available_coffee(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const available_coffee_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/accepting_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_accepting_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/accepting_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_accepting_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/alternatives/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_alternative_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/alternatives(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_alternatives_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/approval_terms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_approval_term_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/approval_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_approval_terms_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/arbitrations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_arbitration_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/arbitrations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_arbitrations_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/arrival_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_arrival_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/arrival_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_arrival_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/associations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_association_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/associations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_associations_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/certificates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_certificate_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/certificates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_certificates_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/certificates_prices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_certificates_price_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/certificates_prices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_certificates_prices_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/coffee_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_coffee_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/coffee_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_coffee_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_comment_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_comments_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/company_sizes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_company_size_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/company_sizes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_company_sizes_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/consignees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_consignee_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/consignees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_consignees_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/container_stuffings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_container_stuffing_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/container_stuffings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_container_stuffings_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/container_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_container_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/container_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_container_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_closure_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_contract_closure_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_closure_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_contract_closure_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_prices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_contract_price_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_prices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_contract_prices_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_qualities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_contract_qualities_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_qualities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_contract_quality_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_version_rejection_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_contract_version_rejection_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_version_rejection_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_contract_version_rejection_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_countries_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/countries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_country_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/couriers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_courier_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/couriers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_couriers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/cupping_customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_cupping_customer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/cupping_customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_cupping_customers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_customer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customer_sample_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_customer_sample_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customer_sample_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_customer_sample_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_customers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customs_brokers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_customs_broker_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customs_brokers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_customs_brokers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customs_officers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_customs_officer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customs_officers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_customs_officers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_dashboard_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/decaf_plants/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_decaf_plant_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/decaf_plants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_decaf_plants_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/defects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_defect_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/defects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_defects_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/deltas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_delta_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/deltas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_deltas_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/departments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_department_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/departments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_departments_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_bodies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_descriptor_bodies_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_bodies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_descriptor_body_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_flavours/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_descriptor_flavour_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_flavours(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_descriptor_flavours_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_overalls/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_descriptor_overall_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_overalls(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_descriptor_overalls_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_roasts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_descriptor_roast_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_roasts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_descriptor_roasts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/destination_warehouses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_destination_warehouse_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/destination_warehouses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_destination_warehouses_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/docks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_dock_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/docks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_docks_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/drivers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_driver_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/drivers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_drivers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/exchange_rates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_exchange_rate_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/exchange_rates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_exchange_rates_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/exports_packing_declaration_configurations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_exports_packing_declaration_configuration_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/exports_packing_declaration_configurations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_exports_packing_declaration_configurations_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/farms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_farm_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/farm_certificate_quotas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_farm_certificate_quota_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/farm_certificate_quotas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_farm_certificate_quotas_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/farms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_farms_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fermentation_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_fermentation_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fermentation_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_fermentation_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fiscal_id_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_fiscal_id_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fiscal_id_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_fiscal_id_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fraction_change_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_fraction_change_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fraction_change_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_fraction_change_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/freights/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_freight_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/freights(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_freights_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/general_setting(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_general_setting_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/grades/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_grade_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/grade_ranges/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_grade_range_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/grades(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_grades_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/import_markups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_import_markup_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/import_markups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_import_markups_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lead_sources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_lead_source_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lead_sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_lead_sources_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lost_opportunity_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_lost_opportunity_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lost_opportunity_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_lost_opportunity_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lot_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_lot_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lot_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_lot_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/marks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_mark_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/market_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_market_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/market_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_market_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/marks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_marks_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/milling_machines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_milling_machine_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/milling_machines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_milling_machines_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/municipalities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_municipalities_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/municipalities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_municipality_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/norms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_norm_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/norms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_norms_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/notifies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_notifies_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/notifies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_notify_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/offices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_office_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/offices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_offices_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/opportunity_sources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_opportunity_source_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/opportunity_sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_opportunity_sources_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/packaging_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_packaging_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/packaging_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_packaging_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/payment_terms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_payment_term_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/payment_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_payment_terms_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/permissions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_permission_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/permissions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_permissions_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/personal_id_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_personal_id_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/personal_id_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_personal_id_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/ports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_port_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/ports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_ports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/preparations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_preparation_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/preparations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_preparations_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/price_variables/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_price_variable_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/price_variables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_price_variables_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/process_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_process_types_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/process_types_prices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_process_types_price_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/process_types_prices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_process_types_prices_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/producers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_producer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/producers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_producers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/qualifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_qualification_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/qualifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_qualifications_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/qualities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_qualities_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/qualities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_quality_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/reception_difference_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_reception_difference_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/reception_difference_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_reception_difference_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/rejecting_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_rejecting_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/rejecting_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_rejecting_reasons_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/release_terms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_release_term_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/release_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_release_terms_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roasting_results/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_roasting_result_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roasting_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_roasting_results_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_role_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_roles_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_root_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_shipping_line_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_line_contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_shipping_line_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_line_contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_shipping_line_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_shipping_lines_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_modes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_shipping_mode_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_modes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_shipping_modes_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_variables/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_shipping_variable_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_variables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_shipping_variables_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/special_marks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_special_mark_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/special_marks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_special_marks_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/specialty_grading_specifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_specialty_grading_specification_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/specialty_grading_specifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_specialty_grading_specifications_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/standards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_standard_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/standards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_standards_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/third_party_shippers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_third_party_shipper_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/third_party_shippers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_third_party_shippers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/transport_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_transport_companies_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/transport_companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_transport_company_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_user_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_users_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/varieties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_varieties_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/varieties_prices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_varieties_price_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/varieties_prices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_varieties_prices_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/varieties/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_variety_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/warehouses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_warehouse_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/warehouses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basedata_warehouses_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /blocked_lots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blocked_lots_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /bundles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bundle_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /bundles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bundles_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /calibration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calibration_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /calibration/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calibration_calibrate_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:id/change_reason(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_reason_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /opportunities/:id/change_sales_representative(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_sales_representative_opportunity_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_fixed_price_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /opportunities/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_opportunity_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/decaf/decaf_orders/:id/close(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const close_api_v1_decaf_decaf_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/third_party_imports/close(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const close_api_v1_exports_third_party_imports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:id/close(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const close_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/milling_orders/:id/coffee_location_check(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const coffee_location_check_api_v1_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/consolidate_icos_opportunities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consolidate_icos_opportunities_api_v1_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/consolidate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consolidate_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contact_infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_info_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:contract_id/contract_versions/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contract_contract_version_path: ((
  contract_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /traceability/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contracts_traceability_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/create_consolidate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_consolidate_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/create_for_ico(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_for_ico_api_v1_exports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/ico_imports/create_or_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_or_update_api_v1_exports_ico_imports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /cupping_tables/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cupping_table_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /cupping_tables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cupping_tables_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customer_leads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lead_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customer_leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_leads_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customer_sample_dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_sample_dashboard_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /cupping_tables/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_cupping_tables_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/decaf_orders/:decaf_order_id/physical_analysis(.:format)
 * @param {any} decaf_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decaf_decaf_order_physical_analysis_path: ((
  decaf_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/quality_assurance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decaf_quality_assurance_index_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/tail(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decaf_tail_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/transports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decaf_transport_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/transports/:transport_id/transport_leg(.:format)
 * @param {any} transport_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decaf_transport_transport_leg_path: ((
  transport_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/transports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decaf_transports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/decaf_orders/:id/decaffeinate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decaffeinate_decaf_decaf_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/:id/delivery_order_address(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delivery_order_address_api_v1_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/:id/delivery_order_costs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delivery_order_costs_api_v1_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:export_id/importer_security_fillings/destination_warehouses(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destination_warehouses_api_v1_export_importer_security_fillings_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/business/v1/customer/disable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_api_business_v1_customer_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/dispatched(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dispatched_transports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/:export_id/download/download_all(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_all_export_download_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/availability_notices/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_availability_notice_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/ico_lists/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_ico_list_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/importer_security_fillings/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_importer_security_filling_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/packing_declarations/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_packing_declaration_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/packing_lists/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_packing_list_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/responsibility_letters/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_responsibility_letter_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/shipment_instructions/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_shipment_instruction_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/shipping_advices/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_shipping_advice_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/transit_guides/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v1_transit_guide_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:id/label_dry_reception(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dry_lot_reception_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roles/:id/duplicate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_basedata_role_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/accepting_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_accepting_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/alternatives/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_alternative_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/approval_terms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_approval_term_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/arbitrations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_arbitration_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/arrival_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_arrival_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/associations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_association_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/certificates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_certificate_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/certificates_prices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_certificates_price_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/coffee_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_coffee_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/company_sizes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_company_size_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/consignees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_consignee_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/container_stuffings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_container_stuffing_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/container_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_container_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_closure_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_contract_closure_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_prices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_contract_price_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_qualities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_contract_quality_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_version_rejection_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_contract_version_rejection_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/countries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_country_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/couriers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_courier_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/cupping_customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_cupping_customer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_customer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customer_sample_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_customer_sample_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customs_brokers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_customs_broker_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customs_officers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_customs_officer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/decaf_plants/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_decaf_plant_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/defects/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_defect_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/deltas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_delta_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/departments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_department_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_bodies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_descriptor_body_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_flavours/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_descriptor_flavour_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_overalls/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_descriptor_overall_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_roasts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_descriptor_roast_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/destination_warehouses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_destination_warehouse_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/docks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_dock_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/drivers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_driver_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/exchange_rates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_exchange_rate_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/exports_packing_declaration_configurations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_exports_packing_declaration_configuration_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/farm_certificate_quotas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_farm_certificate_quota_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fermentation_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_fermentation_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fiscal_id_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_fiscal_id_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fraction_change_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_fraction_change_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/freights/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_freight_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/general_setting/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_general_setting_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/grades/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_grade_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/import_markups/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_import_markup_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lead_sources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_lead_source_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lost_opportunity_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_lost_opportunity_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lot_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_lot_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/marks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_mark_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/market_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_market_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/milling_machines/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_milling_machine_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/municipalities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_municipality_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/norms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_norm_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/notifies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_notify_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/offices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_office_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/opportunity_sources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_opportunity_source_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/packaging_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_packaging_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/payment_terms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_payment_term_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/permissions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_permission_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/personal_id_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_personal_id_type_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/ports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_port_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/preparations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_preparation_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/price_variables/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_price_variable_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/process_types_prices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_process_types_price_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/producers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_producer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/qualifications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_qualification_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/qualities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_quality_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/reception_difference_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_reception_difference_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/rejecting_reasons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_rejecting_reason_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/release_terms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_release_term_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roasting_results/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_roasting_result_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_role_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_lines/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_shipping_line_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_line_contracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_shipping_line_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_modes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_shipping_mode_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_variables/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_shipping_variable_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/special_marks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_special_mark_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/specialty_grading_specifications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_specialty_grading_specification_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/standards/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_standard_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/third_party_shippers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_third_party_shipper_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/transport_companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_transport_company_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_user_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/varieties_prices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_varieties_price_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/varieties/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_variety_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/warehouses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_basedata_warehouse_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /cupping_tables/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_cupping_table_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customer_leads/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_lead_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/decaf_orders/:decaf_order_id/physical_analysis/edit(.:format)
 * @param {any} decaf_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_decaf_decaf_order_physical_analysis_path: ((
  decaf_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_fixed_price_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fulfillment/delivery_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/physical_analysis/edit(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_lot_physical_analysis_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/wet_physical_analysis/edit(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_lot_wet_physical_analysis_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/milling_outputs/:milling_output_id/machine_physical_analysis/edit(.:format)
 * @param {any} milling_order_id
 * @param {any} milling_output_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_milling_order_milling_output_machine_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  milling_output_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/samples/:sample_id/excelso_physical_analysis/:id/edit(.:format)
 * @param {any} milling_order_id
 * @param {any} sample_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_milling_order_sample_excelso_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  sample_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/samples/:sample_id/peabies_physical_analysis/:id/edit(.:format)
 * @param {any} milling_order_id
 * @param {any} sample_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_milling_order_sample_peabies_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  sample_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /opportunities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_opportunity_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /opportunities/:opportunity_id/contracts/:id/edit(.:format)
 * @param {any} opportunity_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_opportunity_contract_path: ((
  opportunity_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /production_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_production_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/:sample_id/physical_analyses/edit(.:format)
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_sample_physical_analyses_path: ((
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /sensorial_analyses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_sensorial_analysis_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /subproducts/production_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_subproducts_production_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_transport_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:transport_id/transport_legs/:id/edit(.:format)
 * @param {any} transport_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_transport_transport_leg_path: ((
  transport_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/business/v1/customer/enable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_api_business_v1_customer_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/:export_id/transport(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_transport_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/:export_id/transport/transport_leg(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_transport_transport_leg_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fake_contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fake_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fake_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fake_data_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fake_login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fake_login_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/milling_orders/:id/finalise(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalise_api_v1_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/availability_notices/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_availability_notice_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/ico_lists/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_ico_list_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/importer_security_fillings/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_importer_security_filling_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/packing_declarations/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_packing_declaration_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/packing_lists/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_packing_list_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/responsibility_letters/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_responsibility_letter_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/shipment_instructions/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_shipment_instruction_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/shipping_advices/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_shipping_advice_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/transit_guides/:id/finalize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finalize_api_v1_transit_guide_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /cupping_tables/:id/finish_table(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finish_table_cupping_table_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fixed_price_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fixed_price_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:id/fractionate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fractionate_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fulfillment/delivery_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fulfillment/delivery_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fulfillment_delivery_orders_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fulfillment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fulfillment_root_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /futures(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const futures_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:contract_id/contract_versions/:id/generate_documents(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_documents_contract_contract_version_path: ((
  contract_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /sensorial_analyses/horizontal_session(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const horizontal_session_sensorial_analyses_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /icos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ico_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/ico_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ico_imports_exports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /icos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const icos_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /traceability/icos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const icos_traceability_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/contracts/import_markup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_markup_api_v1_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/:id/in_progress(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const in_progress_api_v1_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/in_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const in_progress_transports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/:id/invoice(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_api_v1_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/:id/label(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const label_sample_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/authorize_return(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_authorize_return_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/humidity_analysis(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_humidity_analysis_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/physical_analysis(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_physical_analysis_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/returned(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_returned_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/roasting(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_roasting_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/lot_set_sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_set_sources_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lot_sets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_sets_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /traceability/lot_sets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_sets_traceability_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/lot_sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_sources_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/wet_physical_analysis(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lot_wet_physical_analysis_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lots_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /traceability/lots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lots_traceability_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:id/mark_for_cupping(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_for_cupping_api_v1_sample_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /marking_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const marking_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /marking_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const marking_orders_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/fractions(.:format)
 * @param {any} milling_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_order_fractions_path: ((
  milling_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/milling_outputs/:milling_output_id/machine_physical_analysis(.:format)
 * @param {any} milling_order_id
 * @param {any} milling_output_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_order_milling_output_machine_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  milling_output_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/production_logs(.:format)
 * @param {any} milling_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_order_production_logs_path: ((
  milling_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/samples/:sample_id/excelso_physical_analysis/:id(.:format)
 * @param {any} milling_order_id
 * @param {any} sample_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_order_sample_excelso_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  sample_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/samples/:sample_id/excelso_physical_analysis(.:format)
 * @param {any} milling_order_id
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_order_sample_excelso_physical_analysis_index_path: ((
  milling_order_id: RequiredRouteParameter,
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/samples/:sample_id/peabies_physical_analysis/:id(.:format)
 * @param {any} milling_order_id
 * @param {any} sample_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_order_sample_peabies_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  sample_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/samples/:sample_id/peabies_physical_analysis(.:format)
 * @param {any} milling_order_id
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_order_sample_peabies_physical_analysis_index_path: ((
  milling_order_id: RequiredRouteParameter,
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_orders_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/milling_orders/:id/milling_outputs_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milling_outputs_info_api_v1_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/close_marking_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_close_marking_order_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/ico_orphans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_ico_orphan_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/ico_orphans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_ico_orphans_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/production_candidates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_production_candidates_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/production_log(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_production_log_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/production_tail(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_production_tail_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/send_production_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_send_production_order_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/subproduct_returns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_subproduct_returns_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /traceability/millings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const millings_traceability_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/move_ico(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_ico_api_v1_exports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_fulfillment_delivery_order_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/accepting_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_accepting_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/alternatives/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_alternative_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/approval_terms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_approval_term_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/arbitrations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_arbitration_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/arrival_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_arrival_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/associations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_association_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/certificates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_certificate_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/certificates_prices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_certificates_price_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/coffee_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_coffee_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/company_sizes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_company_size_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/consignees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_consignee_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/container_stuffings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_container_stuffing_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/container_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_container_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_closure_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_contract_closure_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_prices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_contract_price_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_qualities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_contract_quality_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/contract_version_rejection_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_contract_version_rejection_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/countries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_country_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/couriers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_courier_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/cupping_customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_cupping_customer_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customer_sample_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_customer_sample_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customs_brokers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_customs_broker_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/customs_officers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_customs_officer_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/decaf_plants/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_decaf_plant_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/defects/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_defect_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/deltas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_delta_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/departments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_department_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_bodies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_descriptor_body_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_flavours/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_descriptor_flavour_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_overalls/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_descriptor_overall_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/descriptor_roasts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_descriptor_roast_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/destination_warehouses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_destination_warehouse_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/docks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_dock_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/drivers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_driver_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/exports_packing_declaration_configurations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_exports_packing_declaration_configuration_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/farm_certificate_quotas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_farm_certificate_quota_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fermentation_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_fermentation_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fiscal_id_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_fiscal_id_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/fraction_change_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_fraction_change_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/freights/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_freight_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/grades/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_grade_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/import_markups/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_import_markup_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lead_sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_lead_source_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lost_opportunity_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_lost_opportunity_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/lot_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_lot_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/marks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_mark_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/market_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_market_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/milling_machines/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_milling_machine_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/municipalities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_municipality_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/norms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_norm_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/notifies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_notify_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/offices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_office_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/opportunity_sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_opportunity_source_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/packaging_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_packaging_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/payment_terms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_payment_term_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/permissions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_permission_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/personal_id_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_personal_id_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/ports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_port_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/preparations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_preparation_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/price_variables/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_price_variable_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/process_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_process_type_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/process_types_prices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_process_types_price_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/qualifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_qualification_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/qualities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_quality_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/reception_difference_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_reception_difference_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/rejecting_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_rejecting_reason_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/release_terms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_release_term_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roasting_results/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_roasting_result_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_role_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_lines/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_shipping_line_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_line_contracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_shipping_line_contract_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_modes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_shipping_mode_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/shipping_variables/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_shipping_variable_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/special_marks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_special_mark_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/standards/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_standard_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/third_party_shippers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_third_party_shipper_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/transport_companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_transport_company_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_user_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/varieties_prices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_varieties_price_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/varieties/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_variety_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /basedata/warehouses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_basedata_warehouse_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_contract_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /cupping_tables/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cupping_table_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customers/new(/:lead_id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_path: ((
  options?: {lead_id?: OptionalRouteParameter, format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customer_leads/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_lead_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/decaf_orders/:decaf_order_id/physical_analysis/new(.:format)
 * @param {any} decaf_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_decaf_decaf_order_physical_analysis_path: ((
  decaf_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/transports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_decaf_transport_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/transports/:transport_id/transport_leg/new(.:format)
 * @param {any} transport_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_decaf_transport_transport_leg_path: ((
  transport_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/:export_id/transport/new(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_export_transport_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/:export_id/transport/transport_leg/new(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_export_transport_transport_leg_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_fixed_price_contract_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fulfillment/delivery_orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_fulfillment_delivery_order_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_lot_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/physical_analysis/new(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_lot_physical_analysis_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:lot_id/wet_physical_analysis/new(.:format)
 * @param {any} lot_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_lot_wet_physical_analysis_path: ((
  lot_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/milling_outputs/:milling_output_id/machine_physical_analysis/new(.:format)
 * @param {any} milling_order_id
 * @param {any} milling_output_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_milling_order_milling_output_machine_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  milling_output_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/samples/:sample_id/excelso_physical_analysis/new(.:format)
 * @param {any} milling_order_id
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_milling_order_sample_excelso_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:milling_order_id/samples/:sample_id/peabies_physical_analysis/new(.:format)
 * @param {any} milling_order_id
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_milling_order_sample_peabies_physical_analysis_path: ((
  milling_order_id: RequiredRouteParameter,
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /opportunities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_opportunity_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /opportunities/:opportunity_id/contracts/new(.:format)
 * @param {any} opportunity_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_opportunity_contract_path: ((
  opportunity_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /production_orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_production_order_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /production_orders/new(/:ico_id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_production_orders_path: ((
  options?: {ico_id?: OptionalRouteParameter, format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sample_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/:sample_id/physical_analyses/new(.:format)
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sample_physical_analysis_path: ((
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/transport/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sample_transport_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/transport/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sample_transports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /sensorial_analyses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sensorial_analysis_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_transport_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:transport_id/transport_legs/new(.:format)
 * @param {any} transport_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_transport_transport_leg_path: ((
  transport_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:transport_id/transport_legs/new(.:format)
 * @param {any} transport_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_transport_transport_legs_path: ((
  transport_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/offer_sample_sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const offer_sample_sources_api_v1_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /opportunities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const opportunities_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /opportunities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const opportunity_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/outgoing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const outgoing_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/paginated_index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paginated_index_api_v1_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/pending(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_fixed_price_contracts_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /pending_random_samples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_random_samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /pending_random_samples/:id/create(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_random_samples_create_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /pending_random_samples/:id/discard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_random_samples_discard_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /pending_random_samples/generate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_random_samples_generate_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /cupping_tables/pending_tables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_tables_cupping_tables_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:contract_id/contract_versions/:id/preview(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_contract_contract_version_path: ((
  contract_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fulfillment/delivery_orders/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/transports/:id/print(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_decaf_transport_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/:export_id/transport/print(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_export_transport_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/:id/print(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_fixed_price_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /icos/:id/print_liquidation_information(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_liquidation_information_ico_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:id/print_producer_receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:id/print_purchase_price_approval(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_purchase_price_approval_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:id/print_storage(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_storage_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lot_sets/:id/print_storage(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_storage_lot_set_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:transport_id/transport_legs/:id/print(.:format)
 * @param {any} transport_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_transport_transport_leg_path: ((
  transport_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:id/print_wet_coffee_reception(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_wet_coffee_reception_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/:id/print_wet_coffee_return_order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_wet_coffee_return_order_lot_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:id/produce(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const produce_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /production_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const production_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /production_orders/:production_order_id/assign_coffee(.:format)
 * @param {any} production_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const production_order_assign_coffee_path: ((
  production_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /production_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const production_orders_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:id/qa(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const qa_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:id/qualify(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const qualify_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /sensorial_analyses/quality(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quality_sensorial_analyses_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path: ((
  signed_blob_id: RequiredRouteParameter,
  variation_key: RequiredRouteParameter,
  filename: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path: ((
  signed_blob_id: RequiredRouteParameter,
  variation_key: RequiredRouteParameter,
  filename: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path: ((
  inbound_email_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path: ((
  encoded_key: RequiredRouteParameter,
  filename: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path: ((
  signed_id: RequiredRouteParameter,
  filename: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path: ((
  signed_id: RequiredRouteParameter,
  filename: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/transport/receive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receive_sample_transports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:id/refresh_signatures(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_signatures_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:id/regrade(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const regrade_api_v1_sample_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /milling_orders/:id/reject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reject_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /lots/rejected(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rejected_lots_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/lots/:lot_id/final_prices/:id/reprice(.:format)
 * @param {any} lot_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reprice_api_v1_lot_final_price_path: ((
  lot_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/milling_orders/:id/request_orphan_ico(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request_orphan_ico_api_v1_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:contract_id/contract_versions/:id/request_signature(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request_signature_contract_contract_version_path: ((
  contract_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:id/retrieve_pdf_signature(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_pdf_signature_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /millings/subproduct_returns/return(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const return_millings_subproduct_returns_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path: ((
  options?: {} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/:sample_id/physical_analyses(.:format)
 * @param {any} sample_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sample_physical_analyses_path: ((
  sample_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/:sample_id/physical_analyses/:id(.:format)
 * @param {any} sample_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sample_physical_analysis_path: ((
  sample_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/transport/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sample_transport_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/transport/:sample_transport_id/close_transport(.:format)
 * @param {any} sample_transport_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sample_transport_close_transport_path: ((
  sample_transport_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples/transport(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sample_transports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /samples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const samples_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/:id/send_to_accounting(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_accounting_api_v1_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /sensorial_analyses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sensorial_analyses_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /sensorial_analyses/fractions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sensorial_analyses_fractions_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /sensorial_analyses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sensorial_analysis_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /services/adobe_sign/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const services_adobe_sign_webhook_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /services/twilio/whatsapp_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const services_twilio_whatsapp_callback_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:id/set_destination_port(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_destination_port_api_v1_export_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/set_export_date(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_export_date_api_v1_exports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/set_import_date(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_import_date_api_v1_exports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /set_issueable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_issueable_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /set_issueable_and_redirect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_issueable_and_redirect_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:id/set_shipping_port(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_shipping_port_api_v1_export_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/fulfillment/delivery_orders/:id/shipping_options(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shipping_options_api_v1_fulfillment_delivery_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_customer_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /background_dashboard
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path: ((
  options?: {} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /contracts/:contract_id/contract_versions/:id/sign_agreement(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_agreement_contract_contract_version_path: ((
  contract_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/:id/sign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_fixed_price_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/milling_orders/:id/start(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_api_v1_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /styleguide(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const styleguide_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /subproducts/milling_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subproducts_milling_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /subproducts/milling_orders/:milling_order_id/qa(.:format)
 * @param {any} milling_order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subproducts_milling_order_qa_path: ((
  milling_order_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /subproducts/milling_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subproducts_milling_orders_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /subproducts/production_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subproducts_production_order_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /subproducts/production_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subproducts_production_orders_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /subproducts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subproducts_root_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /switch_language/:language(.:format)
 * @param {any} language
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const switch_language_path: ((
  language: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/third_party_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const third_party_imports_exports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /traceability(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const traceability_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transport_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:transport_id/transport_legs/:id(.:format)
 * @param {any} transport_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transport_transport_leg_path: ((
  transport_id: RequiredRouteParameter,
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:transport_id/transport_legs(.:format)
 * @param {any} transport_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transport_transport_legs_path: ((
  transport_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:transport_id/unload(.:format)
 * @param {any} transport_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transport_unload_path: ((
  transport_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports/:transport_id/unloaded(.:format)
 * @param {any} transport_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transport_unloaded_path: ((
  transport_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /transports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transports_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/decaf/transports/:id/unload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unload_api_v1_decaf_transport_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/transports/:id/unload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unload_api_v1_exports_transport_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /decaf/transports/:id/unload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unload_decaf_transport_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /exports/:export_id/transport/unload(.:format)
 * @param {any} export_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unload_export_transport_path: ((
  export_id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/business/v1/customer/update_payment_term(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_payment_term_api_business_v1_customer_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path: ((
  encoded_token: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/samples/:id/update_tracking_note(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_tracking_note_api_v1_sample_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /api/v1/exports/:id/upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_api_v1_export_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /fixed_price_contracts/:id/upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_fixed_price_contract_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /users/auth/azure_activedirectory_v2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_azure_activedirectory_v2_omniauth_authorize_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /users/auth/azure_activedirectory_v2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_azure_activedirectory_v2_omniauth_callback_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /sensorial_analyses/vertical_session(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vertical_session_sensorial_analyses_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /wet_reception(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wet_reception_path: ((
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /wet_reception/set_lot_to_dry/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wet_reception_set_dry_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /wet_reception/update_lot_drying/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wet_reception_update_lot_drying_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

/**
 * Generates rails route to
 * /wet_reception/update_lot_location/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wet_reception_update_lot_location_path: ((
  id: RequiredRouteParameter,
  options?: {format?: OptionalRouteParameter} & RouteOptions
) => string) & RouteHelperExtras;

// By some reason this line prevents all types in a file
// from being automatically exported
export {};
