import { action, observable } from 'mobx';
import {
  DeliveryOrderItem,
  GPExcelsoInventory,
  GPSpotExcelsoInventory,
} from '../types/types';

class ExcelsoInventoryModel {
  @observable public gpExcelsoInventory: GPExcelsoInventory | GPSpotExcelsoInventory;
  @observable public deliveryOrderItem?: DeliveryOrderItem;
  @observable public checked: boolean;
  @observable public reserved: boolean;
  @observable public quantity = '';

  constructor(
    gpExcelsoInventory: GPExcelsoInventory | GPSpotExcelsoInventory,
    deliveryOrderItem?: DeliveryOrderItem,
    reserved = false
  ) {
    this.gpExcelsoInventory = gpExcelsoInventory;
    this.deliveryOrderItem = deliveryOrderItem;
    this.checked = deliveryOrderItem !== undefined;
    this.reserved = reserved;
    this.quantity = String(deliveryOrderItem?.units_to_deliver || 0);
  }

  @action public toggleChecked = () => {
    this.checked = !this.checked;
  };

  @action public setQuantity = (value: string) => {
    this.quantity = value;
  };
}

export default ExcelsoInventoryModel;
