import React, { useState } from 'react';
import styled from 'styled-components';

import Icon from 'components/elements/Icon';
import LoadingSpinner from './LoadingSpinner';

const FileUploader = styled.div`
  flex-grow: 1;
`;

const FileInput = styled.input`
  opacity: 0;
  cursor: pointer;
  top: 5px;
  left: 10px;
  width: 60px;
  height: 46px;
  position: absolute;
`;

const LinkWrapper = styled.a``;

interface FileUploaderIconImageProps {
  uploadUrl: string;
  onDoneCallback: (success: {}) => void;
  onErrorCallback: (errors: {}) => void;
}
const FileUploaderIconComponent: React.FC<FileUploaderIconImageProps> = ({
  uploadUrl,
  onDoneCallback,
  onErrorCallback,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    const files = e.currentTarget.files;
    const formData = new FormData();

    if (files) {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        formData.append('export[external_documents][]', file);
      }
      fetch(uploadUrl, {
        method: 'PUT',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setIsUploading(false);
          onDoneCallback(result);
        })
        .catch((error) => {
          setIsUploading(false);
          onErrorCallback(error);
        });
    }

    // reset the input file to recognize same files more then once
    e.target.value = '';
  };

  return (
    <div>
      <FileUploader data-cy='file_uploader_icon'>
        <FileInput
          type='file'
          multiple={true}
          onChange={handleFileChange}
          data-cy='file_input'
          disabled={isUploading}
        />
        <LinkWrapper className='button button--medium-blue'>
          {isUploading ? <LoadingSpinner /> : <Icon icon='upload' color='#ffffff' />}
        </LinkWrapper>
      </FileUploader>
    </div>
  );
};

export default FileUploaderIconComponent;
