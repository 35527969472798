import { Provider } from 'mobx-react';
import React from 'react';

import { LotGet } from 'types/model/lot';

import CalibrationTablePackagingComponent from '../components/CalibrationTablePackagingComponent';
import CalibrationStore from '../stores/CalibrationStore';

interface CreateSetContainerProps {
  bags: [];
  lots: Array<LotGet>;
  tagColors: Array<{ id: number; name: string; color: string }>;
}

const CalibrationTablePackaging = ({
  lots,
  bags,
  tagColors,
}: CreateSetContainerProps) => (
  <Provider calibrationStore={new CalibrationStore(lots, bags, tagColors)}>
    <CalibrationTablePackagingComponent />
  </Provider>
);

export default CalibrationTablePackaging;
