import React from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { ContractTextInput, ContractSelectGroup } from '../ContractFields';
import { formatFutureFixingDate, nextFutureFixingDate } from 'contracts/contractUtils';
import { IField } from 'components/shared/form/IField';
import { usePriceContext } from 'contracts/utils/priceContext';
import Markups from '../Markups';

export interface PriceProps {
  showMarkups: boolean;
  priceTypeField?: IField;
  futurePriceFixingDateField?: IField;
  fixationRightsField?: IField;
  shipmentDate: Date;
}

export function Price({
  showMarkups,
  futurePriceFixingDateField,
  fixationRightsField,
  shipmentDate,
}: PriceProps) {
  const {
    fixedPriceType,
    fixedPrice,
    differentialPrice,
    fobPrice,
    spotIcoCost,
    opportunityPrice,
    internalContractPrice,
  } = usePriceContext();

  return (
    <FieldSetWithTitle title={I18n.translate('contracts.form.price')}>
      <Grid>
        <ContractTextInput field={fixedPriceType?.field} />

        {fobPrice && (
          <ContractTextInput
            field={fobPrice.field}
            value={fobPrice.value}
            type='number'
            step='0.0001'
            min='0.0001'
            onChange={fobPrice.set}
          />
        )}

        {spotIcoCost && (
          <ContractTextInput
            field={spotIcoCost.field}
            value={spotIcoCost.value}
            type='number'
            step='0.0001'
            min='0.0001'
            onChange={spotIcoCost.set}
          />
        )}

        {showMarkups && <Markups />}

        {fixedPriceType?.value == 'fixed' && fixedPrice && (
          <ContractTextInput
            field={fixedPrice.field}
            value={fixedPrice.value}
            onChange={fixedPrice.set}
            type='number'
            step='0.0001'
            min='0.0001'
          />
        )}

        {fixedPriceType?.value == 'differential' && differentialPrice && (
          <>
            <ContractTextInput
              field={differentialPrice.field}
              value={differentialPrice.value}
              onChange={differentialPrice.set}
              type='number'
              step='0.0001'
              min='0.0001'
              append='USD/lb'
            />

            <ContractTextInput
              field={futurePriceFixingDateField}
              onChange={() => true}
              value={formatFutureFixingDate(nextFutureFixingDate(shipmentDate, false))}
            />
            <ContractSelectGroup
              field={fixationRightsField}
              value={fixationRightsField?.value}
              placeholder={false}
            />
          </>
        )}
        {opportunityPrice && (
          <ContractTextInput field={opportunityPrice.field}></ContractTextInput>
        )}
        {internalContractPrice && (
          <ContractTextInput field={internalContractPrice.field}></ContractTextInput>
        )}
      </Grid>
    </FieldSetWithTitle>
  );
}

export default Price;
