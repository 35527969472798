import { observable, action, computed } from 'mobx';

import LostCoffee from 'types/model/lostCoffee';
import type Storable from '../types/Storable';
import Storage from './Storage';

class UnloadableLostCoffee implements Storable {
  public id: string;
  public localIdentifier: string;
  public quality: string;
  public grade: number;
  public bags: number;
  public weight: number;
  public storage: Storage;

  @observable private unloaded = false;

  public constructor({ id, bags, weight, lot, lot_set }: LostCoffee) {
    this.id = id;
    this.bags = bags;
    this.weight = weight;

    const origin = lot || lot_set;

    if (origin) {
      const { local_identifier, quality, grade, area, bin, level, position } = origin;

      this.localIdentifier = local_identifier;
      this.quality = quality;
      this.grade = grade;

      this.storage = new Storage(area, bin, level, position);
    }
  }

  @action setUnloaded = (status: boolean): void => {
    this.unloaded = status;
  };

  @computed get isUnloaded(): boolean {
    return this.unloaded;
  }
}

export default UnloadableLostCoffee;
