import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form-v7';
import styled from 'styled-components';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import { VARIANT } from 'constants/inputTypes';
import type IRoasting from 'types/model/IRoasting';
import MillingOrderRoasting from 'productionOrders/models/Roasting';
import Overlay from 'shared/Overlay';
import type SelectOptionArray from 'types/model/selectOption';
import type { Validation, MaxValidation } from 'types/utils/validations';
import { parseTimeInput, secondsToTime, timeToSeconds } from 'utils/time';
import { isInBound } from 'utils/validators';
import { calcDevelopment, calculatePPPT } from '../utils';

export type Validations = {
  pppt: Validation;
  sampleWeight?: MaxValidation;
};

export interface RoastingOverlayProps {
  identifier: string;
  results: SelectOptionArray;
  roasting?: IRoasting | MillingOrderRoasting;
  reroast?: boolean;
  roastingSampleWeight: number;
  validations?: Validations;
  onPrimaryClick?: (event: React.MouseEvent, values: {}) => void;
  onSecondaryClick?: (event: React.MouseEvent) => void;
}

const RoastingBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 1.625em;
`;

const MIN_ROASTING_TIME = 59;

type formValues = {
  initial_weight: number;
  roasted_weight: number | undefined;
  time_roasting: string | undefined;
  time_to_first_crack: string | undefined;
  roasting_result_id: string | undefined;
  pppt: number | undefined;
  development: number | undefined;
};

function RoastingOverlay({
  identifier,
  results,
  roasting,
  onPrimaryClick,
  onSecondaryClick,
  reroast,
  validations,
  roastingSampleWeight,
}: RoastingOverlayProps): JSX.Element {
  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<formValues>({
    mode: 'all',
    defaultValues: {
      initial_weight: roasting?.initial_weight || roastingSampleWeight,
      roasted_weight: roasting?.roasted_weight
        ? parseFloat(roasting.roasted_weight)
        : undefined,
      time_roasting: roasting ? secondsToTime(roasting?.time_roasting) : undefined,
      time_to_first_crack: roasting
        ? secondsToTime(roasting?.time_to_first_crack)
        : undefined,
      roasting_result_id: roasting?.roasting_result_id,
      pppt: undefined,
      development: undefined,
    },
  });

  useEffect(() => {
    if (reroast) {
      reset();
    }
  }, [reroast]);

  const disableFields = roasting !== null && reroast === false;

  const handlePrimaryClick = ({
    initial_weight,
    roasted_weight,
    roasting_result_id,
    time_roasting,
    time_to_first_crack,
  }: formValues) => {
    const body = {
      reroast,
      roasted_weight,
      roasting_result_id,
      time_roasting,
      time_to_first_crack,
      roasting_sample_weight: initial_weight,
    };

    if (onPrimaryClick) {
      // @ts-ignore: custom event
      onPrimaryClick(null, body);
    }
  };

  const handleSecondaryClick = (event: React.MouseEvent) => {
    reset();

    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
  };

  const watchInitialWeight: number | undefined = watch('initial_weight');
  const watchRoastedWeight: number | undefined = watch('roasted_weight');
  const watchTimeRoasting = watch('time_roasting');

  const watchTimeRoastingSeconds = useMemo(
    () => (watchTimeRoasting && timeToSeconds(watchTimeRoasting)) || 0,
    [watchTimeRoasting]
  );
  const watchTimeToFirstCrack = watch('time_to_first_crack');
  const watchTimeToFirstCrackSeconds = useMemo(
    () => (watchTimeToFirstCrack && timeToSeconds(watchTimeToFirstCrack)) || 0,
    [watchTimeToFirstCrack]
  );

  useEffect(() => {
    if (watchInitialWeight && watchRoastedWeight) {
      setValue(
        'pppt',
        parseFloat(calculatePPPT(watchInitialWeight, watchRoastedWeight).toFixed(2)),
        {
          shouldValidate: true,
        }
      );
    } else {
      setValue('pppt', undefined);
    }
  }, [watchInitialWeight, watchRoastedWeight]);
  useEffect(() => {
    if (watchTimeRoasting && watchTimeToFirstCrack) {
      setValue(
        'development',
        parseFloat(
          calcDevelopment(watchTimeRoastingSeconds, watchTimeToFirstCrackSeconds).toFixed(
            3
          )
        )
      );
    } else {
      setValue('development', undefined);
    }
  }, [watchTimeRoasting, watchTimeToFirstCrack]);

  return (
    <form>
      <Overlay
        id={identifier}
        title={`${identifier} ${I18n.translate('lots.index.roasting')}`}
        primaryDisabled={false}
        primaryText={I18n.translate('buttons.save')}
        onPrimaryClick={handleSubmit(handlePrimaryClick)}
        secondaryText={I18n.translate('buttons.close')}
        onSecondaryClick={handleSecondaryClick}
      >
        <RoastingBody>
          <InputGroupComponent
            label={I18n.translate('attributes.initial_weight')}
            variant={VARIANT.INTEGER}
            disabled={disableFields}
            readOnly={disableFields}
            min={1}
            max={validations?.sampleWeight?.max}
            required
            append='gr'
            data-cy='roasting_initial_weight'
            externalValid={!errors?.initial_weight}
            {...register('initial_weight', {
              valueAsNumber: true,
              deps: ['roasted_weight'],
            })}
          />

          <InputGroupComponent
            label={I18n.translate('attributes.roasted_weight')}
            variant={VARIANT.DECIMAL}
            append='gr'
            min={1}
            step='.1'
            max={watchInitialWeight}
            data-cy='roasting_roasted_weight'
            externalValid={!errors?.roasted_weight}
            required
            {...register('roasted_weight', {
              valueAsNumber: true,
              required: true,
            })}
          />

          <InputGroupComponent
            label={I18n.translate('attributes.time_roasting')}
            variant={VARIANT.TIME}
            data-cy='roasting_time_roasting'
            externalValid={!errors?.time_roasting}
            required
            {...register('time_roasting', {
              required: true,
              validate: {
                isBiggerThanMin: (timeRoasting) =>
                  timeRoasting && timeToSeconds(timeRoasting) > MIN_ROASTING_TIME,
              },
              deps: ['time_to_first_crack'],
            })}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue('time_roasting', parseTimeInput(e.target.value))
            }
          />

          <InputGroupComponent
            label={I18n.translate('attributes.time_to_first_crack')}
            variant={VARIANT.TIME}
            data-cy='roasting_time_to_first_crack'
            externalValid={!errors?.time_to_first_crack}
            required
            {...register('time_to_first_crack', {
              required: true,
              validate: {
                isBiggerThanTimeRoasting: (timeToFirstCrackSeconds) =>
                  timeToFirstCrackSeconds &&
                  watchTimeRoasting &&
                  timeToSeconds(timeToFirstCrackSeconds) > 0 &&
                  timeToSeconds(timeToFirstCrackSeconds) <
                    timeToSeconds(watchTimeRoasting),
              },
            })}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue('time_to_first_crack', parseTimeInput(e.target.value))
            }
          />

          <InputGroupComponent
            label={I18n.translate('attributes.development')}
            variant={VARIANT.TIME}
            disabled={true}
            readOnly={true}
            data-cy='roasting_development'
            required
            externalValid={!errors?.development}
            {...register('development', { required: true })}
          />

          <InputGroupComponent
            label='PPPT'
            append='%'
            disabled={true}
            readOnly={true}
            data-cy='roasting_pppt'
            required
            externalValid={!errors?.pppt}
            {...register('pppt', {
              required: true,
              validate: {
                isInBounds: (ppptValue) =>
                  !!ppptValue &&
                  validations &&
                  isInBound(ppptValue, validations.pppt.min, validations.pppt.max),
              },
            })}
          />

          <SelectGroup
            label={I18n.translate('attributes.result')}
            placeholder={I18n.translate('lots.roasting.result')}
            data-cy='result'
            options={results}
            errors={
              errors?.roasting_result_id?.type ? [errors?.roasting_result_id?.type] : []
            }
            required
            {...register('roasting_result_id', { required: true })}
          />
        </RoastingBody>
      </Overlay>
    </form>
  );
}

export default observer(RoastingOverlay);
