import { ErrorMessage } from '@hookform/error-message';
import { customSelectStyles, MultiOptions, Option } from 'components/elements/Search';
import BaseGroupComponent from 'components/groups/BaseGroup';
import React, { useCallback } from 'react';
import {
  Control,
  Controller,
  useController,
  UseControllerOptions,
} from 'react-hook-form';
import Select from 'react-select';
export interface ControlledSearchProps
  extends Pick<UseControllerOptions, 'rules' | 'defaultValue'> {
  name: string;
  value?: number;
  options: Array<Option | MultiOptions>;
  prompt: string;
  control: Control;
  required: boolean;
  label: string;
  dataCy: string;
}

const ControlledSearch: React.FC<ControlledSearchProps> = ({
  value: _value,
  name,
  options,
  prompt,
  defaultValue = '',
  control,
  required,
  label,
  dataCy,
  rules = {},
  ...rest
}) => {
  useController({
    name,
    control,
    rules: {
      required: required ? I18n.translate('errors.messages.blank') : undefined,
      ...rules,
    },
    defaultValue,
  });

  const WrapperComponent = useCallback(
    ({ children }) =>
      label ? (
        <BaseGroupComponent label={label} data-cy={dataCy} required={required}>
          {children}
        </BaseGroupComponent>
      ) : (
        <div>{children}</div>
      ),
    [label]
  );

  return (
    <WrapperComponent>
      <Controller
        defaultValue={defaultValue}
        render={({ onChange }) => (
          <Select
            options={options}
            name={name}
            styles={customSelectStyles}
            className='select react-select'
            classNamePrefix='react-select'
            isClearable={true}
            placeholder={prompt}
            onChange={(option: any) => onChange((option as Option)?.value)}
          />
        )}
        name={name}
        control={control}
        {...rest}
      />
      <ErrorMessage name={name} render={({ message }) => <p>{message}</p>} />
    </WrapperComponent>
  );
};

export default ControlledSearch;
