import { observable, computed, action } from 'mobx';
import ImporterSecurityFillingType from '../types/importerSecurityFilling';
import FinalizeableResource from './FinalizableResource';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';
import DestinationWarehouse from 'types/model/destinationWarehouse';
import { JsonApi } from 'types/utils/jsonApi';
import { deserialise } from 'kitsu-core';
import SelectOptionArray from 'types/model/selectOption';

class ImporterSecurityFilling extends FinalizeableResource<ImporterSecurityFillingType> {
  @observable public finalized: boolean;
  @observable public container_stuffing_id?: number;
  @observable public container_stuffer_id?: number;
  @observable public destination_warehouse_id?: number;
  @observable public carrier_booking?: string;
  @observable public fda_registration_stuffing?: string;
  @observable public fda_registration_stuffer?: string;
  @observable public destination_warehouse_options: SelectOptionArray =
    observable.array();

  @action protected updateAttributes({
    id,
    finalized,
    container_stuffing_id,
    container_stuffer_id,
    destination_warehouse_id,
    carrier_booking,
    fda_registration_stuffing,
    fda_registration_stuffer,
  }: ImporterSecurityFillingType) {
    this.id = id;
    this.finalized = finalized;
    this.container_stuffing_id = container_stuffing_id;
    this.container_stuffer_id = container_stuffer_id;
    this.destination_warehouse_id = destination_warehouse_id;
    this.carrier_booking = carrier_booking;
    this.fda_registration_stuffing = fda_registration_stuffing;
    this.fda_registration_stuffer = fda_registration_stuffer;
  }

  @computed public get isFinalizable() {
    return !['carrier_booking', 'destination_warehouse_id'].find((attribute) =>
      [undefined, null, ''].includes(this[attribute])
    );
  }

  @computed get downloadUrl() {
    return this.id
      ? Routes.download_api_v1_importer_security_filling_path(this.id)
      : undefined;
  }

  fetchDestinationWarehouses = async (destinationPortId: number): Promise<void> => {
    const destinationWarehouses = await customFetch<JsonApi<DestinationWarehouse>>(
      Routes.destination_warehouses_api_v1_export_importer_security_fillings_path(1, {
        port_id: destinationPortId,
      }),
      undefined,
      'GET'
    );

    const deserializedDestinationWarehouses: DestinationWarehouse[] =
      deserialise(destinationWarehouses).data;

    if (
      !deserializedDestinationWarehouses.find(
        ({ id }) => id === this.destination_warehouse_id
      )
    ) {
      this.destination_warehouse_id = undefined;
    }

    this.destination_warehouse_options = observable.array(
      deserializedDestinationWarehouses
    );
  };
}

export default ImporterSecurityFilling;
