import styled from 'styled-components';

import { COLORS } from 'constants/colors';

const OverlayHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${COLORS.BLUE};
  color: #fff;
  padding: 0.875em 0;
  margin-bottom: 1.25em;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0.05em;
  z-index: 20;
  box-shadow: none;

  .header__id {
    font-weight: 400;
    font-size: 0.875em;
    opacity: 0.6;
  }

  .header__number {
    left: 0.25em;
    top: 0.25em;
    opacity: 1;
    line-height: 1;
    font-weight: 800;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .header__number--big {
    font-size: 1.5em;
  }

  .header__button {
    &--first {
      margin-right: auto;
    }

    &--last {
      margin-left: auto;
    }
  }
`;

export default OverlayHeader;
