import { deserialise } from 'kitsu-core';
import { observable } from 'mobx';
import { ISampleTransport } from 'samples/receive_samples/types/ISampleTransport';
import { JsonApiResource } from 'types/utils/jsonApi';
import SelectOptionArray from 'samples/receive_samples/types/SelectOption';
import SampleTransportModel from 'samples/receive_samples/models/SampleTransport';
import ReceptionOption from '../models/ReceptionOption';

export type ReceptionOptions = {
  [key: string]: ReceptionOption[];
};

class ReceiveSampleTransportsStore {
  public transports = observable.array<SampleTransportModel>();
  public receptionOptions: ReceptionOptions;

  constructor(
    transports: JsonApiResource<ISampleTransport>,
    differences: SelectOptionArray,
    damages: SelectOptionArray
  ) {
    const receptionSuccess = new ReceptionOption({
      name: I18n.translate('samples.receive.received'),
      type: 'received',
    });

    const receptionWrongCro = new ReceptionOption({
      name: I18n.translate('samples.receive.wrong_cro_data'),
      type: 'wrong',
    });

    const deserializedItems = deserialise(transports).data as ISampleTransport[];

    const transportItems = deserializedItems.map(
      (transport) => new SampleTransportModel(transport)
    );

    this.receptionOptions = {
      loss: this.prepareReceptionOptions(differences),
      damage: this.prepareReceptionOptions(damages),
      received: [receptionSuccess],
      wrong: [receptionWrongCro],
    };

    this.transports = observable.array(transportItems);
  }

  private prepareReceptionOptions(
    optionDefinitions: SelectOptionArray
  ): Array<ReceptionOption> {
    return optionDefinitions.map(
      (optionDefinition) => new ReceptionOption(optionDefinition)
    );
  }

  public findReceptionOption = (identifier: string): ReceptionOption | undefined => {
    const [type, id] = identifier.split('_');

    const options = this.receptionOptions[type];

    let option: ReceptionOption | undefined = undefined;

    if (options) {
      if (id) {
        option = options.find(({ id }) => id === id);
      } else {
        option = options[0];
      }
    }

    return option;
  };
}

export default ReceiveSampleTransportsStore;
