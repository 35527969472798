import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import STATUS from 'types/utils/status';
import useStores from 'utils/useStores';

import StatusText from 'shared/StatusText';
import Tile, { TileDisclosure, TileHeader } from 'shared/Tile';

import SubproductItem from 'subproducts/candidates/components/SubproductItem';
import SubmitButtonsComponent from 'shared/SubmitButtons/components/SubmitButtons';

const SubmitButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const SelectedSubproducts: React.FC<{}> = () => {
  const { subproductsStore } = useStores();

  const [collapsed, setCollapsed] = React.useState(true);

  React.useEffect(() => {
    if (!subproductsStore.selectedSubproducts.length) {
      setCollapsed(true);
    }
  }, [subproductsStore.selectedSubproducts.length]);

  const handleDisclosureClick = React.useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => setCollapsed(!collapsed),
    [collapsed]
  );

  return (
    <>
      <Tile
        collapsibleIndicator={!!subproductsStore.selectedSubproducts.length && collapsed}
      >
        <>
          <TileHeader className='selected-lots__header'>
            <TileDisclosure
              disabled={!subproductsStore.selectedSubproducts.length}
              handleCollapsible={handleDisclosureClick}
              collapsed={collapsed}
            />
            <span className='selected-lots__total-amount'>
              {I18n.translate('subproducts.index.total_amount')}
            </span>

            <StatusText
              className='selected-lots__total-weight'
              status={subproductsStore.isFormValid ? STATUS.OK : STATUS.FAILED}
            >
              {`${subproductsStore.totalWeight} kg`}
            </StatusText>

            <SubmitButton>
              <SubmitButtonsComponent
                body={subproductsStore.submitBody}
                submitEnabled={!!subproductsStore.selectedSubproducts.length}
                submitMethod={subproductsStore.submitMethod}
                submitPath={subproductsStore.submitPath}
                submitText={subproductsStore.submitButtonText}
                dataCy='submit_subproduct_selection_button'
              />
            </SubmitButton>
          </TileHeader>

          {!collapsed && !!subproductsStore.selectedSubproducts.length && (
            <table
              className={classNames('table', 'table--inverted-stripes', 'sets-table', {
                'sets-table--collapsed': collapsed,
              })}
            >
              <tbody>
                {subproductsStore.selectedSubproducts.map((selectedSubproduct) => (
                  <SubproductItem
                    key={selectedSubproduct.attributes.id}
                    subproduct={selectedSubproduct}
                  />
                ))}
              </tbody>
            </table>
          )}
        </>
      </Tile>
    </>
  );
};

export default observer(SelectedSubproducts);
