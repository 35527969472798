import React, { useMemo } from 'react';
import { ArrayField, useFormContext } from 'react-hook-form';
import WSRResourcesObservationField from 'samples/create/components/warehouse_sample_request/WSRResourcesObservationField';
import Checkbox from 'shared/Checkbox';
import placeholder from 'utils/placeholder';
import { truncateText } from 'utils/string';
import { addTestingIdentifier } from 'utils/testing';
import WSRSampleWeightInput from './WSRSampleWeightInput';

interface SelectedWSRResourcesRowProps {
  onUncheck: (index: number) => void;
  field: Partial<ArrayField<Record<string, any>>>;
  index: number;
  fieldArrayName: string;
  resourceIdName: string;
  showOfficeWeightInput: boolean;
}

const SelectedWSRResourcesRow = ({
  field,
  onUncheck,
  index,
  fieldArrayName,
  resourceIdName,
  showOfficeWeightInput,
}: SelectedWSRResourcesRowProps) => {
  const { register } = useFormContext();
  const namePrefix = `${fieldArrayName}[${index}]`;

  const customerName = useMemo(
    () => field.contract?.customer?.name || field.customer?.name,
    [field.contract, field.customer]
  );

  return (
    <tr {...addTestingIdentifier(`selected_wsr_row`)}>
      <td className='table-border'>
        {field.main_identifier}
        <input
          type='hidden'
          value={field.id}
          name={`${namePrefix}.${resourceIdName}`}
          ref={register()}
        />
      </td>
      <td className='table-border'>
        {placeholder(truncateText(customerName || '', 25))}
      </td>
      {showOfficeWeightInput && (
        <td className='table-border'>
          <WSRSampleWeightInput
            name={`${namePrefix}.office_sample_weight`}
            description={I18n.translate('activerecord.models.office')}
            dataCy={'office_sample_weight'}
          />
        </td>
      )}
      <td className='table-border'>
        <WSRSampleWeightInput
          name={`${namePrefix}.customer_sample_weight`}
          description={I18n.translate('activerecord.models.customer')}
          readOnly={!customerName}
          dataCy={'customer_sample_weight'}
        />
      </td>
      <td className='table-border'>
        <WSRResourcesObservationField namePrefix={namePrefix} />
      </td>
      <td className='align-center'>
        <Checkbox
          checked={true}
          onClick={() => onUncheck(index)}
          dataCy='selected_wsr_row_checkbox'
        />
      </td>
    </tr>
  );
};

export default SelectedWSRResourcesRow;
