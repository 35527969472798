import { observer } from 'mobx-react';
import React from 'react';
import useStores from 'utils/useStores';
import classNames from 'classnames';
import Fraction from '../models/Fraction';
import MainFractionsComponent from './MainFractionsComponent';
import FractionHeader from './FractionHeader';
import Routes from 'routes';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

export interface FractionsComponentProps {
  fractions: Fraction[];
}

const FractionsComponent = ({
  run,
  createdAt,
  cuppingSessionEnabled,
}: {
  run: number;
  createdAt: string;
  cuppingSessionEnabled: boolean;
}) => {
  const { fractionsStore } = useStores();

  return (
    <form
      action={Routes.vertical_session_sensorial_analyses_path()}
      acceptCharset='UTF-8'
      method='post'
      className='content'
    >
      <AuthenticityToken />
      <FractionHeader run={run} createdAt={createdAt} />
      <table className='table fractions-table'>
        <thead>
          <tr>
            <th></th>
            <th className='align-left'>{I18n.translate('fractions.frac')}</th>
            <th className='align-left'>{I18n.translate('fractions.excelso')}</th>
            <th className='align-left'>{I18n.translate('fractions.rejected')}</th>
            <th className='align-left'>{I18n.translate('fractions.loss')}</th>
            <th>{I18n.translate('fractions.roasting')}</th>
            <th className='border-right-none'>{I18n.translate('fractions.sensorial')}</th>
            <th className='border-right-black'></th>
            <th>{I18n.translate('fractions.humidity')}</th>
            <th>{I18n.translate('fractions.physical')}</th>
          </tr>
        </thead>

        <tbody>
          <tr className='fractions-table__spacer'></tr>

          {fractionsStore.fractions.map((fraction) => (
            <MainFractionsComponent
              key={fraction.node_identifier}
              fraction={fraction}
              isMainFraction={true}
              cuppingSessionEnabled={cuppingSessionEnabled}
            />
          ))}
        </tbody>
      </table>
      {cuppingSessionEnabled ? (
        <a
          href={Routes.new_cupping_table_path()}
          className={classNames('button', 'button--text-center', {
            'button--disabled': fractionsStore.roastingSamplesCount === 0,
          })}
          data-cy='cupping_session_run_with_samples_button'
        >
          {I18n.translate('headlines.organize_cupping_table')}
        </a>
      ) : (
        <button
          name='button'
          type='submit'
          className='button'
          disabled={fractionsStore.selectedSamplesCount === 0}
          data-cy='cupping_session_run_with_samples_button'
        >
          {I18n.translate('headlines.organize_cupping_session')}
        </button>
      )}
    </form>
  );
};

export default observer(FractionsComponent);
