import styled from 'styled-components';

import Details from './Details';
import Detail from './Detail';

export const SensorialAndOriginDetails = styled(Details)``;

export const SensorialAnalysisDetail = styled(Detail)`
  display: flex;

  & > span {
    margin-left: 0.625em;

    &:first-child {
      margin-left: 0;
    }
  }
`;
