import { deserialise } from 'kitsu-core';
import { action, observable } from 'mobx';
import Routes from 'routes';

import DefaultDateRangeType from 'types/model/defaultDateRange';
import DateRange from 'customerSamples/models/DateRange';
import Opportunity from 'customerSamples/models/Opportunity';
import Sample from 'customerSamples/models/Sample';
import SelectOptionArray from 'types/model/selectOption';

import { customFetch } from 'utils/fetch';
import OpportunityType from 'customerSamples/types/OpportunityType';
import SpotOpportunity from 'customerSamples/models/SpotOpportunity';
import SpotOpportunityType from 'customerSamples/types/SpotOpportunityType';
import PssInternalContract from 'customerSamples/models/PssInternalContract';
import PssInternalContractType from 'customerSamples/types/PssInternalContractType';

class CustomerSamplesDashboardStore {
  @observable public dateRange: DateRange;
  @observable public search?: string = '';
  @observable public type?: string = '';
  @observable public state?: string = '';
  @observable public originCountryId?: number;
  @observable public sampleRowEntities = observable.array<
    Opportunity | SpotOpportunity | PssInternalContract
  >([]);
  @observable public selectedSample?: Sample;

  constructor(
    default_date_range: DefaultDateRangeType,
    public types: SelectOptionArray = [],
    public originCountries: SelectOptionArray = [],
    public months: SelectOptionArray = [],
    public years: SelectOptionArray = [],
    public grades: SelectOptionArray = [],
    public states: SelectOptionArray = [],
    public customerSampleReasons: SelectOptionArray = [],
    search = '',
    type = ''
  ) {
    this.dateRange = new DateRange(default_date_range);
    this.search = search;
    this.type = type;
  }

  @action setSearch(search?: string): void {
    this.search = search;
  }

  @action setType(type?: string): void {
    this.type = type;
  }

  @action setStatus(state?: string): void {
    this.state = state;
  }

  @action setSelectedSample(sample?: Sample): void {
    this.selectedSample = sample;
  }

  @action setOriginCountryId = (countryId?: string): void => {
    this.originCountryId = countryId ? Number(countryId) : undefined;
  };

  @action setSampleRowEntities(
    opportunities: Array<Opportunity | SpotOpportunity | PssInternalContract>
  ): void {
    this.sampleRowEntities.replace(observable.array(opportunities));
  }

  @action async fetchOpportunities(): Promise<void> {
    const response = await customFetch(
      Routes.api_v1_customer_samples_opportunities_path(),
      {
        filter: {
          search: this.search,
          type: this.type,
          state: this.state,
          origin_country_id: this.originCountryId,
          start_range: this.dateRange.startRange,
          end_range: this.dateRange.endRange,
        },
      },
      'POST'
    );

    if (response.success) {
      const deserializedSampleRowEntities = deserialise(response.opportunities).data as (
        | OpportunityType
        | SpotOpportunityType
        | PssInternalContractType
      )[];

      const sampleRowEntities = deserializedSampleRowEntities.reduce((memo, entity) => {
        let item: Opportunity | SpotOpportunity | PssInternalContract | undefined;

        if (entity.type == 'opportunity') {
          item = new Opportunity(entity);
        } else if (entity.type == 'spot_opportunity') {
          item = new SpotOpportunity(entity);
        } else if (entity.type == 'pss_internal_contract') {
          item = new PssInternalContract(entity);
        }

        if (item) {
          memo.push(item);
        }

        return memo;
      }, [] as (SpotOpportunity | Opportunity | PssInternalContract)[]);

      this.setSampleRowEntities(sampleRowEntities);
    }
  }
}

export default CustomerSamplesDashboardStore;
