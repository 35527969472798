export {};

declare global {
  const I18n: {
    translate: (translation: string, object?: any) => string;
    l: (format: string, time: string) => string;
    translations: [];
  };

  interface Window {
    ReactEventBus: {
      on: <T>(event: string, callback: (event: CustomEvent<T>) => any) => void;
      dispatch(event): void;
      remove(event, callback): void;
    };
  }
}
