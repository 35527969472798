import { action, computed, observable } from 'mobx';
import { deserialise } from 'kitsu-core';
import Routes from 'routes';

import type * as API from '../types/api';

import Ico from '../models/Ico';
import ShipmentSettings from './ShipmentSettings';
import StartShipment from './StartShipment';

import { customFetch } from 'utils/fetch';
import EndShipment from './EndShipment';
import ShippingLineContract from 'exports/types/shippingLineContract';
import ContainerType from 'exports/types/containerType';
import { JsonApi } from 'types/utils/jsonApi';
import IExportDocument from 'exports/types/IExportDocument';
import type { ExportState, IExport } from '../types/IExport';
import { IconType } from 'exports/components/ExportIcon';
import Incoterm from 'types/model/Incoterm';
import IncotermGroup from 'exports/types/IIncotermGroup';

export type ShipmentSettingsStateIcon = 'CHECKMARK' | 'YELLOW_RING';

export type EndShipmentStateIcon = 'CHECKMARK' | 'YELLOW_RING' | 'GREY_CLOCK';

class Export {
  static FOB_GROUP = [Incoterm.FOB, Incoterm.CNF, Incoterm.CIF, Incoterm.FOT];
  static EXW_GROUP = [
    Incoterm.EXW,
    Incoterm.DLD,
    Incoterm.FCA,
    Incoterm.DAP,
    Incoterm.DDP,
  ];

  @observable public id: number;
  @observable public shipmentMonth?: Date;
  @observable public exportDate?: string;
  @observable public importDate?: string;
  @observable public mainIdentifier: string;
  @observable public requiredDocuments: IExportDocument[];
  @observable public collapsed: boolean;
  @observable public shipmentSettings?: ShipmentSettings;
  @observable public startShipment?: StartShipment;
  @observable public endShipment?: EndShipment;

  @observable public destinationPortId: number;
  @observable public shippingPortId: number;
  @observable public originOffice: string;
  @observable public incoterm: Incoterm;

  @observable public state: ExportState;
  @observable public canAddIcos: boolean;
  @observable public canRemoveIcos: boolean;
  @observable public canPrepareTransport: boolean;
  @observable public canBeExported: boolean;
  @observable public canBeImported: boolean;
  @observable public blCanBeEdited: boolean;
  @observable public willBeImported: boolean;

  @observable public shippingLineContract: ShippingLineContract | null = null;

  public useSecondaryIcoIdentifier: boolean;
  public availableContainerTypes: ContainerType[];
  public containerTypeId?: number;

  @observable public icos = observable.array<Ico>();
  @observable public isLoading = false;

  constructor(exportData: IExport) {
    this.id = exportData.id;
    this.shipmentMonth = exportData.shipment_month
      ? new Date(exportData.shipment_month)
      : undefined;

    this.exportDate = exportData.export_date;
    this.importDate = exportData.import_date;
    this.mainIdentifier = exportData.main_identifier;
    this.requiredDocuments = exportData.required_documents;
    this.shippingPortId = exportData.shipping_port_id;
    this.incoterm = exportData.incoterm;
    this.collapsed = true;

    const icos = exportData.icos.map((ico) => new Ico(ico));
    this.icos = observable.array(icos);

    this.state = exportData.state;
    this.canAddIcos = exportData.can_add_icos;
    this.canRemoveIcos = exportData.can_remove_icos;
    this.willBeImported = exportData.will_be_imported;
    this.availableContainerTypes = exportData.destination_port.container_types;
    this.containerTypeId = exportData.container_type_id;
  }

  @action public updateAttributes({
    export_date = this.exportDate,
    import_date = this.importDate,
    required_documents = this.requiredDocuments,
    state = this.state,
    can_prepare_transport = this.canPrepareTransport,
    can_be_exported = this.canBeExported,
    bl_can_be_edited = this.blCanBeEdited,
    can_be_imported = this.canBeImported,
    origin_office = this.originOffice,
    use_secondary_ico_identifier = this.useSecondaryIcoIdentifier,
    destination_port_id = this.destinationPortId,
    shipping_port_id = this.shippingPortId,
  }: Partial<API.IExport>): void {
    this.exportDate = export_date;
    this.importDate = import_date;
    this.requiredDocuments = required_documents;
    this.state = state;
    this.canPrepareTransport = can_prepare_transport;
    this.canBeExported = can_be_exported;
    this.blCanBeEdited = bl_can_be_edited;
    this.canBeImported = can_be_imported;
    this.originOffice = origin_office;
    this.useSecondaryIcoIdentifier = use_secondary_ico_identifier;
    this.destinationPortId = destination_port_id;
    this.shippingPortId = shipping_port_id;
  }

  @action updateShippingPort = async (shippingPortId: number): Promise<void> => {
    const attributes = { shipping_port_id: shippingPortId };

    this.updateAttributes(attributes);

    const response = await customFetch(
      Routes.set_shipping_port_api_v1_export_path(this.id),
      attributes,
      'PATCH'
    );

    if (!response.success) {
      // Error: Transport already exists
    }
  };

  @action updateDestinationPort = async (destinationPortId: number): Promise<void> => {
    const attributes = { destination_port_id: destinationPortId };

    this.updateAttributes(attributes);

    const response = await customFetch(
      Routes.set_destination_port_api_v1_export_path(this.id),
      attributes,
      'PATCH'
    );

    if (!response.success) {
      // Error: Transport already exists
    }
  };

  @action setCollapsed = (bool: boolean): void => {
    this.collapsed = bool;
  };

  @action toggleCollapsed = (): void => {
    this.setCollapsed(!this.collapsed);
  };

  @action fetchExportDetails = async (): Promise<void> => {
    this.isLoading = true;

    const response: JsonApi<API.IExport> = await customFetch(
      Routes.api_v1_export_path(this.id),
      undefined,
      'GET'
    );

    const exportData: API.IExport = deserialise(response).data;

    this.updateAttributes(exportData);

    this.shipmentSettings = new ShipmentSettings(
      {
        shipmentInstruction: exportData.shipment_instruction,
        transitGuide: exportData.transit_guide,
        responsibilityLetter: exportData.responsibility_letter,
        importerSecurityFilling: exportData.importer_security_filling,
        transport: exportData.transport,
        icoList: exportData.ico_list,
      },
      this
    );

    this.startShipment = new StartShipment(
      {
        shippingAdvice: exportData.shipping_advice,
        packingList: exportData.packing_list,
        packingDeclaration: exportData.packing_declaration,
        importerSecurityFilling: this.shipmentSettings.importerSecurityFilling,
        shipmentInstruction: this.shipmentSettings.shipmentInstruction,
      },
      this
    );

    this.endShipment = new EndShipment(
      {
        availabilityNotice: exportData.availability_notice,
        icos: exportData.icos,
      },
      this
    );

    this.isLoading = false;
  };

  @action updateExportDate = async (date: string): Promise<void> => {
    const payload = { id: this.id, export: { export_date: date } };
    const response = await customFetch(
      Routes.set_export_date_api_v1_exports_path(),
      payload,
      'POST'
    );
    this.updateAttributes(deserialise(response.export).data);
  };

  @action updateImportDate = async (date: string): Promise<void> => {
    const payload = { id: this.id, export: { import_date: date } };
    const response = await customFetch(
      Routes.set_import_date_api_v1_exports_path(),
      payload,
      'POST'
    );
    this.updateAttributes(deserialise(response.export).data);
  };

  @action updateExportBillOfLading = async (
    shipmentInstructionId: any,
    billOfLading: any
  ): Promise<void> => {
    const payload = {
      id: this.id,
      shipment_instruction: {
        id: shipmentInstructionId,
        bill_of_lading: billOfLading,
      },
    };

    let response;
    if (shipmentInstructionId == null) {
      response = await customFetch(
        Routes.api_v1_export_shipment_instructions_path(this.id),
        payload,
        'POST'
      );
    } else {
      response = await customFetch(
        Routes.api_v1_shipment_instruction_path(shipmentInstructionId),
        {
          id: shipmentInstructionId,
          bill_of_lading: billOfLading,
        },
        'PATCH'
      );
    }

    this.updateAttributes(deserialise(response.export).data);
  };

  @action fetchShippingLineContract = async (shippingLineId?: number): Promise<void> => {
    try {
      const query = {
        shipping_line_id: shippingLineId,
        shipment_date: this.shipmentMonth?.toISOString(),
        loading_port_id: this.shippingPortId,
        destination_port_id: this.destinationPortId,
      };

      const response = await customFetch(
        Routes.api_v1_exports_shipping_line_contracts_path(query),
        undefined,
        'GET'
      );

      if (response.success === true) {
        const deserializedShippingLineContracts = deserialise(
          response.shipping_line_contracts
        ).data as ShippingLineContract[];

        if (deserializedShippingLineContracts?.length > 0) {
          this.shippingLineContract = deserializedShippingLineContracts[0];
        }
      }
    } catch (e) {
      // TODO: Handle error
    }
  };

  @computed get totalCapacity(): number {
    const containerTypeId =
      this.shipmentSettings?.shipmentInstruction?.container_type_id ||
      this.containerTypeId;

    return (
      this.availableContainerTypes.find(({ id }) => id == containerTypeId)?.max_weight ||
      0
    );
  }

  @computed get currentCapacity(): number {
    return this.icos.reduce((totalCapacity, { weightToBeProduced }) => {
      return (totalCapacity += weightToBeProduced);
    }, 0);
  }

  @computed get countIcosInProductionOrder(): number {
    return this.icos.filter((ico) => ico.icoStateIcon == 'ORANGE_RING').length;
  }

  @computed get countIcosInMilling(): number {
    return this.icos.filter((ico) => ico.icoStateIcon == 'YELLOW_RING').length;
  }

  @computed get countIcosQaApproved(): number {
    return this.icos.filter((ico) => ico.icoStateIcon == 'CHECKMARK').length;
  }

  @computed get countIcosWithError(): number {
    return this.icos.filter((ico) => ico.icoStateIcon == 'RED_CROSS').length;
  }

  @computed get shipmentSettingsStateIcon(): ShipmentSettingsStateIcon {
    if (['completed', 'exported', 'imported', 'closed'].includes(this.state)) {
      return 'CHECKMARK';
    } else {
      return 'YELLOW_RING';
    }
  }

  @computed get startShipmentStateIcon(): IconType {
    if (['exported', 'imported', 'closed'].includes(this.state)) {
      return 'CHECKMARK';
    } else if (this.canBeExported) {
      return 'YELLOW_RING';
    } else if (this.state == 'preparing_export') {
      return 'ORANGE_RING';
    } else {
      return 'GREY_CLOCK';
    }
  }

  @computed get incotermGroup(): IncotermGroup | undefined {
    let group: IncotermGroup | undefined;

    if (Export.FOB_GROUP.includes(this.incoterm)) {
      group = 'FOB_GROUP';
    } else if (Export.EXW_GROUP.includes(this.incoterm)) {
      group = 'EXW_GROUP';
    }

    return group;
  }

  @computed get endShipmentStateIcon(): EndShipmentStateIcon {
    if (['imported', 'closed'].includes(this.state)) {
      // IMPORT COMPLETED
      return 'CHECKMARK';
    }

    if (this.state == 'exported') {
      return 'YELLOW_RING';
    }

    return 'GREY_CLOCK';
  }

  @computed get editableDestinationPort(): boolean {
    return (
      this.canRemoveIcos &&
      this.incotermGroup !== 'FOB_GROUP' &&
      !(
        this.requiredDocuments.includes('importer_security_filling') &&
        this.shipmentSettings?.importerSecurityFilling?.finalized
      )
    );
  }

  canBeTargetable = (sourceExport: Export, ico: Ico): boolean => {
    return (
      this.canAddIcos &&
      this !== sourceExport &&
      this.incoterm === sourceExport.incoterm &&
      this.hasEnoughCapacityLeft(ico)
    );
  };

  hasEnoughCapacityLeft = ({ weightToBeProduced }: Ico): boolean => {
    const requiredCapacity = this.currentCapacity + weightToBeProduced;

    return requiredCapacity <= this.totalCapacity;
  };
}

export default Export;
