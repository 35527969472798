import { observable, action, reaction, computed } from 'mobx';
import Routes from 'routes';

import ProductionLog from 'types/model/production_log';

import { customFetch } from 'utils/fetch';
import { deserialise } from 'kitsu-core';

class LogEntry {
  @observable attributes: ProductionLog;
  private inProgress: boolean;
  private mllingOrderId: number;

  constructor(mllingOrderId: number, entry: ProductionLog) {
    this.attributes = entry;
    this.inProgress = false;
    this.mllingOrderId = mllingOrderId;

    reaction(() => this.serialize, this.updateEntry);
  }

  @computed get serialize() {
    return JSON.stringify({
      machine: this.attributes.machine,
      start_date: this.attributes.start_date,
      end_date: this.attributes.end_date,
      fraction: this.attributes.fraction,
      open: this.attributes.open,
    });
  }

  @action private updateEntry = async () => {
    let route: string;
    let method: 'POST' | 'PATCH';

    if (this.attributes.id) {
      route = Routes.api_v1_production_log_path(this.attributes.id);
      method = 'PATCH';
    } else {
      route = Routes.api_v1_production_logs_path();
      method = 'POST';
    }

    this.inProgress = true;
    const response = await customFetch(
      route,
      {
        millingOrderId: this.mllingOrderId,
        attributes: this.attributes,
      },
      method
    );
    this.inProgress = false;

    if (response.ok) {
      const log = deserialise(response.log).data;
      this.attributes = {
        ...this.attributes,
        id: log.id,
        meridiem: log.meridiem,
        start_date: log.start_date,
        start_time: log.start_time,
        end_time: log.end_time,
      };
    } else {
      alert('something went wrong');
    }
  };

  @action public setStartDate = (value: any) => {
    this.attributes.start_date = value;
  };

  @action public setEndDate = (value: any) => {
    this.attributes.end_date = value;
  };

  @action public setFraction = (fraction: string): void => {
    this.attributes.fraction = fraction;
  };

  @action public setMachine = (machine: string): void => {
    this.attributes.machine = machine;
  };

  @action public setObservations = (observations: string): void => {
    this.attributes.observations = observations;
  };

  @action public close = () => {
    this.attributes.open = false;
  };
}

export default LogEntry;
