import { deserialise } from 'kitsu-core';
import { action, observable } from 'mobx';
import { Pagy } from 'shared/Pagynation';
import { JsonApi } from 'types/utils/jsonApi';
import {
  PaginatedErrorResponse,
  PaginatedSuccessResponse,
  paginatedTableCustomFetch,
} from 'components/shared/PaginatedTable/utils/paginatedTableCustomFetch';
import parseUSVString from 'utils/parseUSVString';

type FetchState = 'idle' | 'loading' | 'error';

type UrlParams = {
  [key: string]: string;
};

type PaginateParams = {
  page: number;
  params: UrlParams;
};

type DataUrlFn = (params?: UrlParams) => string;

type SuccessResponseFn<T> = (response: PaginatedSuccessResponse<T>) => void;

type ErrorResponseFn = (response: PaginatedErrorResponse) => void;

class PaginatedTableStore<T> {
  @observable public tileHeaderCollapsed = true;
  @observable public pagy: Pagy | undefined;
  @observable public storeState: FetchState = 'idle';

  public loadedRows = observable.array<T>();

  public page = 1;

  public dataUrl: DataUrlFn;
  public actionUrl: DataUrlFn | undefined;

  private successHandler: SuccessResponseFn<T>;
  private errorHandler: ErrorResponseFn;

  constructor(
    dataUrl: DataUrlFn,
    successHandler: SuccessResponseFn<T>,
    errorHandler: ErrorResponseFn,
    actionUrl?: DataUrlFn
  ) {
    this.dataUrl = dataUrl;
    this.successHandler = successHandler;
    this.errorHandler = errorHandler;
    this.actionUrl = actionUrl;
  }

  @action public loadPage = (page = 1, params = {}): void => {
    this.setStoreState('loading');

    this.setPage(page);

    const urlParams = new URLSearchParams({
      ...params,
      page: this.page.toString(),
    }).toString();

    const urlWithParams = `${this.dataUrl()}?${urlParams}`;

    paginatedTableCustomFetch<T>(urlWithParams, undefined, 'GET')
      .then((response) => {
        // TODO implement clean error handling in customFetch
        this.successHandler(response);
      })
      .catch((response: PaginatedErrorResponse) => {
        this.errorHandler(response);
      })
      .finally(() => {
        const currentUrlWithParams = `${window.location.origin}${window.location.pathname}?${urlParams}`;
        window.history.replaceState(params, '', currentUrlWithParams); //'Page 3', '/page3.html');
        this.setStoreState('idle');
      });
  };

  @action public setStoreState = (newState: FetchState): void => {
    this.storeState = newState;
  };

  @action public updatePagy(pagy: Pagy): void {
    this.pagy = pagy;
  }

  @action public setPage = (page: number): void => {
    this.page = page;
  };

  @action setLoadedRows = (rows: JsonApi<T>): void => {
    const deserialisedData: T[] = deserialise(rows).data;
    this.loadedRows.replace(deserialisedData);
  };

  @action public toggleTileHeaderCollapsed = (): void => {
    this.tileHeaderCollapsed = !this.tileHeaderCollapsed;
  };

  public urlStringToPaginateParams = (urlString: string): PaginateParams => {
    const urlSearchParams = new URLSearchParams(urlString);
    const urlParams = parseUSVString(urlSearchParams.toString());
    const page = urlParams['page'];
    const params = Object.keys(urlParams)
      .filter((paramName) => paramName !== 'page')
      .reduce((memo, paramName) => {
        memo[paramName] = urlParams[paramName];
        return memo;
      }, {});

    return { page, params };
  };
}

export default PaginatedTableStore;
