import { action, observable } from 'mobx';

class FileUploaderStore {
  @observable public files = Array<File>();

  constructor(_files: Array<File> = []) {
    // tbd
  }

  @action public addFiles = (files: Array<File>) => {
    files.forEach((file) => this.files.push(file));
  };

  @action public removeFile = (file: File) => {
    this.files = this.files.filter((f) => f.name !== file.name);
  };
}

export default FileUploaderStore;
