import type NormType from 'types/model/norm';

class Norm {
  public name: string;
  public group1Defects: number;
  public group2Defects: number;
  public tolerance: number;
  public alternativeName?: string;

  constructor(norm: NormType) {
    this.name = norm.name;
    this.group1Defects = norm.group_1_defects;
    this.group2Defects = norm.group_2_defects;
    this.tolerance = norm.tolerance;
    this.alternativeName = norm.alternative_name;
  }
}

export default Norm;
