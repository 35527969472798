import React from 'react';

import ConfirmationOverlay from 'shared/ConfirmationOverlay';
import ToolTip from 'shared/ToolTip/components/ToolTip';

export interface LotRemoveButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  lotSetIdentifier: string;
  onConfirm: (event: React.MouseEvent) => any;
  disabled?: boolean;
  disabledText?: string;
}

const LotSetRemoveButton: React.FC<LotRemoveButtonProps> = ({
  onConfirm,
  lotSetIdentifier,
  disabled,
  disabledText,
}) => {
  const [showOverlay, setOverlay] = React.useState(false);

  const handleOnRemoveClick = () => {
    setOverlay(true);
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  return (
    <>
      {showOverlay && (
        <ConfirmationOverlay
          title={I18n.translate('traceability.remove.confirm.title', {
            lot_set: lotSetIdentifier,
          })}
          confirmText={I18n.translate('traceability.remove.confirm.primary')}
          onConfirmClick={onConfirm}
          cancelText={I18n.translate('traceability.remove.confirm.secondary')}
          onCancelClick={handleOnSecondaryClick}
        />
      )}
      <div style={{ display: 'flex' }}>
        <button
          onClick={handleOnRemoveClick}
          type='button'
          disabled={disabled}
          className='button button--small button--ghost'
        >
          {I18n.translate('traceability.remove.button')}
        </button>
        {disabled && <ToolTip title={disabledText || ''} infos={[]} />}
      </div>
    </>
  );
};

export default LotSetRemoveButton;
