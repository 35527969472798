export default function parseUSVString(usvString: string) {
  const usvEntries = {};

  const searchParams = new URLSearchParams(usvString);

  for (const [key, value] of searchParams) {
    usvEntries[key] = value;
  }

  return usvEntries;
}
