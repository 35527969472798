import { observer } from 'mobx-react';
import React from 'react';
import useStores from 'utils/useStores';

import { Icons } from 'shared/Checkbox';
import StandaloneAcceptingReasonCheckbox from 'shared/Checkbox/components/StandaloneAcceptingReasonCheckbox';
import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import PercentageAppendComponent from './PercentageAppendComponent';

import FirstGroupComponent from './FirstGroupComponent';
import SecondGroupComponent from './SecondGroupComponent';

const PeabiesPhysicalAnalysisForm = () => {
  const { peabiesPhysicalAnalysisStore } = useStores();
  const physicalAnalysis = peabiesPhysicalAnalysisStore.peabiesPhysicalAnalysis;

  return (
    <>
      {physicalAnalysis && (
        <>
          <div className='fieldset'>
            <Grid grid={9}>
              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sample_weight')}
                value={physicalAnalysis.sampleWeight}
                onChange={(e) =>
                  physicalAnalysis.setSampleWeight(parseInt(e.target.value))
                }
                selectTextOnFocus={true}
                append='gr'
                name='sample_weight'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sieve_0')}
                value={physicalAnalysis.sieve0}
                onChange={(e) => physicalAnalysis.setSieve0(parseInt(e.target.value))}
                selectTextOnFocus={true}
                append={
                  <PercentageAppendComponent
                    percentage={physicalAnalysis.getWeightPercentageString(
                      physicalAnalysis.sieve0
                    )}
                  />
                }
                name='sieve_0'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sieve_12')}
                value={physicalAnalysis.sieve12}
                onChange={(e) => physicalAnalysis.setSieve12(parseInt(e.target.value))}
                append={
                  <PercentageAppendComponent
                    percentage={physicalAnalysis.getWeightPercentageString(
                      physicalAnalysis.sieve12
                    )}
                  />
                }
                name='sieve_12'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sieve_14')}
                value={physicalAnalysis.sieve14}
                onChange={(e) => physicalAnalysis.setSieve14(parseInt(e.target.value))}
                selectTextOnFocus={true}
                append={
                  <PercentageAppendComponent
                    percentage={physicalAnalysis.getWeightPercentageString(
                      physicalAnalysis.sieve14
                    )}
                  />
                }
                name='sieve_14'
                type='number'
              />
            </Grid>
          </div>

          <FirstGroupComponent physicalAnalysis={physicalAnalysis} />

          <SecondGroupComponent physicalAnalysis={physicalAnalysis} />

          <div className='fieldset'>
            <Grid grid={9}>
              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.flat_bean_grains')}
                value={physicalAnalysis.flatBeanGrains}
                onChange={(e) =>
                  physicalAnalysis.setFlatBeanGrains(parseInt(e.target.value))
                }
                selectTextOnFocus={true}
                append='#'
                name='flat_bean_grains'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.flat_bean_weight')}
                value={physicalAnalysis.flatBeanWeight}
                onChange={(e) =>
                  physicalAnalysis.setFlatBeanWeight(parseInt(e.target.value))
                }
                selectTextOnFocus={true}
                append={
                  <PercentageAppendComponent
                    percentage={physicalAnalysis.getWeightPercentageString(
                      physicalAnalysis.flatBeanWeight
                    )}
                  />
                }
                name='flat_bean_weight'
                type='number'
              />
            </Grid>
          </div>

          <div className='fieldset'>
            <TextAreaGroupComponent
              label={I18n.translate('qa_physical_analysis.observations')}
              value={physicalAnalysis.observations}
              name='observations'
              onChange={(e) => physicalAnalysis.setObservations(e.target.value)}
            />
          </div>

          <div className='fieldset'>
            <div
              className='l-auto-fill-grid'
              style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
            >
              <StandaloneAcceptingReasonCheckbox
                reasons={peabiesPhysicalAnalysisStore.reasons}
                name='rejected'
                fieldName='accepting_reason_id'
                icon={Icons.cross}
                isRejected={physicalAnalysis.isRejectedInitiallyChecked}
                initialReasonId={physicalAnalysis.acceptingReasonId}
                showReasons={
                  physicalAnalysis.exceedsNorm ||
                  physicalAnalysis.exceedsFlatBeanPeaberryPercentage
                }
                label={I18n.translate('attributes.rejected')}
              />
            </div>
          </div>

          <div className='fieldset'>
            <div className='l-distribute-l-r'>
              <a
                href={peabiesPhysicalAnalysisStore.cancelUrl}
                className='button button--gray'
              >
                {I18n.translate('buttons.cancel')}
              </a>

              <button type='submit' className='button'>
                {I18n.translate('buttons.save')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default observer(PeabiesPhysicalAnalysisForm);
