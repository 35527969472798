import { action, observable } from 'mobx';
import QaDefectType from 'types/model/qaDefect';

class QaDefect {
  @observable public grains: number;
  @observable public weight: number;

  constructor(qaDefect: QaDefectType) {
    this.grains = qaDefect.grains;
    this.weight = qaDefect.weight;
  }

  @action setGrains(grains: number) {
    this.grains = grains ? grains : 0;
  }

  @action setWeight(weight: number) {
    this.weight = weight ? weight : 0;
  }
}

export default QaDefect;
