import { observer } from 'mobx-react';
import React from 'react';
import SelectGroup from 'components/groups/SelectGroup';

interface Lead {
  id: string;
  title: string;
}

interface CustomerLeadSelectProps {
  selectedLead?: Lead;
  leads: Lead[];
  path: string;
}

const CustomerLeadSelect = ({ selectedLead, leads, path }: CustomerLeadSelectProps) => {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    window.location.href = id ? `${path}/${id}` : path;
  };

  const selectableLeads = leads.map((lead) => ({
    id: lead.id,
    name: lead.title,
    value: lead.id,
  }));

  return (
    <SelectGroup
      label={I18n.translate('customers.form.lead_label')}
      placeholder={I18n.translate('customers.form.select_customer_lead')}
      options={selectableLeads}
      value={selectedLead ? selectedLead.id : undefined}
      onChange={(e) => onChange(e)}
    />
  );
};

export default observer(CustomerLeadSelect);
