import type IcoWithIcoListing from '../types/IcoWithIcoListing';
import IcoListing from '../models/IcoListing';
import Ico from '../models/Ico';

function mapIcosWithIcoListing(
  icos: Ico[],
  icoListings: IcoListing[] = []
): IcoWithIcoListing[] {
  const mappedIcosWithIcoListings = icos.map((ico) => {
    const icoListing = icoListings.find(({ ico_id }) => ico_id === ico.id);

    return {
      ico,
      icoListing,
    };
  });

  return mappedIcosWithIcoListings;
}

export default mapIcosWithIcoListing;
