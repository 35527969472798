import styled from 'styled-components';
import COLORS from 'constants/colors';

export default styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${COLORS.BLUE};
  padding: 0 0.25rem;

  .icon {
    font-size: 0.875rem;
  }
`;
