import { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

const useSelectionFieldArray = <T extends { id: number | string }>(
  fieldArrayName: string,
  resources: T[]
) => {
  const { control, setValue } = useFormContext();

  const fieldArray = useFieldArray({
    control,
    name: fieldArrayName,
    keyName: 'fieldArrayId',
  });

  useEffect(() => {
    // change setValue to resetField after upgrade to react hook form 7
    // reset resets all fields and can not be used
    if (fieldArray.fields.length) {
      setValue(fieldArrayName, []);
    }
  }, [resources]);

  const selectedResources = useMemo(
    () =>
      resources.map((resource) => ({
        ...resource,
        selected: fieldArray.fields.some(
          (selected_resource) => resource.id == selected_resource.id
        ),
      })),
    [resources, fieldArray.fields]
  );

  const deselect = (resourceIndex: (T & { selected: boolean }) | number) => {
    let index: number;
    if (typeof resourceIndex !== 'number') {
      index = fieldArray.fields.findIndex((field) => field.id === resourceIndex.id);
    } else {
      index = resourceIndex;
    }
    fieldArray.remove(index);
  };

  return { ...fieldArray, deselect, selectedResources };
};

export default useSelectionFieldArray;
