import { action, computed, observable } from 'mobx';

import { customFetch } from 'utils/fetch';

import type IHumidityAnalysis from 'types/model/IHumidityAnalysis';
import type Validation from 'types/utils/validations';
import HumidityValidations from './HumidityValidations';

interface ValidationsType {
  humidity_green: Validation;
  water_activity: Validation;
  humidity_dry_parchment: Validation;
}

// Attributes are snail_case to work with existing components
class HumidityAnalysis {
  public validations: ValidationsType;
  public sample_id: number;
  @observable public id?: number;
  @observable public humidity_green?: number;
  @observable public humidity_dry_parchment?: number;
  @observable public water_activity?: number;
  @observable public accepting_reason_id?: number;
  @observable public passed = false;

  constructor(
    sample_id: number,
    humidityAnalysis: IHumidityAnalysis,
    validations: HumidityValidations,
    private apiEndpoint: string
  ) {
    this.validations = validations;

    if (humidityAnalysis) {
      this.id = humidityAnalysis.id;
      this.sample_id = humidityAnalysis.sample_id;
      this.humidity_green = humidityAnalysis.humidity_green;
      this.humidity_dry_parchment = humidityAnalysis.humidity_dry_parchment;
      this.water_activity = humidityAnalysis.water_activity;
      this.passed = humidityAnalysis.passed;
      this.accepting_reason_id = humidityAnalysis.accepting_reason_id;
    } else {
      this.sample_id = sample_id;
    }
  }

  @action synchronize = async (values: {}) => {
    values['with_production_order_serializer'] = true;

    await customFetch(this.apiEndpoint, values, 'POST');

    window.location.reload();
  };

  @action setId = (id: number) => {
    this.id = id;
  };

  @action setAcceptingReasonId = (acceptingReasonId: number) => {
    this.accepting_reason_id = acceptingReasonId;
  };

  @action setHumidityGreen = (humidityGreen: number) => {
    this.humidity_green = humidityGreen;
  };

  @action setHumidityDryParchment = (humidityDryParchment: number) => {
    this.humidity_dry_parchment = humidityDryParchment;
  };

  @action setWaterActivity = (waterActivity: number) => {
    this.water_activity = waterActivity;
  };

  @action setPassed = (passed: boolean) => {
    this.passed = passed;
  };

  @action setValidations = (validations: ValidationsType) => {
    this.validations = new HumidityValidations(validations);
  };

  @computed get status(): boolean {
    return Boolean(this.id && this.passed);
  }

  @computed get text() {
    return this.id && this.passed
      ? I18n.translate('shared.status.passed').toLocaleLowerCase()
      : I18n.translate('shared.status.failed').toLocaleLowerCase();
  }

  @computed get icon() {
    return this.id && this.passed ? 'passed' : 'failed';
  }

  @computed get failed(): boolean {
    return !!this.id && !this.passed;
  }
}

export default HumidityAnalysis;
