import styled from 'styled-components';

import COLORS from 'constants/colors';
import { TestResult } from './TestResult';

const TestGrid = styled.div`
  display: grid;
  grid-template-rows: 3em 1fr;
  grid-gap: 1.25em;

  > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12.5em, 1fr));
    grid-column-gap: 1.25em;
  }

  > div:nth-child(1) {
    border-bottom: 1px solid ${COLORS.BORDERS};
  }

  ${TestResult} {
    margin-bottom: 1.25em;
  }
`;

export default TestGrid;
