import styled from 'styled-components';
import COLORS from 'constants/colors';

const ExportsOverflowContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 8.125rem;
  height: 8.125rem;
  border: 1px dashed ${COLORS.BLUE};
  border-radius: 5px;
  background: ${COLORS.MEDIUM_LIGHT_GRAY};
  font-size: 1.75rem;
  cursor: pointer;

  .icon {
    transform: rotate(45deg);
  }

  &:hover {
    background: ${COLORS.LIGHT_GRAY};
  }
`;

export default ExportsOverflowContainer;
