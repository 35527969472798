import InputGroup from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useStores from 'utils/useStores';

function ContainerStuffing() {
  const {
    exportsStore: { containerStuffings, containerStuffers },
  } = useStores();
  const [stuffingFdaRegistration, setStuffingFdaRegistration] = useState('');
  const [stufferFdaRegistration, setStufferFdaRegistration] = useState('');

  const { register, watch } = useFormContext();

  useEffect(() => {
    const selectedContainerStuffing = containerStuffings.find(
      ({ id }) => id == watch('container_stuffing_id')
    );

    if (selectedContainerStuffing) {
      setStuffingFdaRegistration(selectedContainerStuffing.fda_registration || '');
    } else {
      setStuffingFdaRegistration('');
    }
  }, [watch('container_stuffing_id')]);

  useEffect(() => {
    const selectedContainerStuffer = containerStuffers.find(
      ({ id }) => id == watch('container_stuffer_id')
    );

    if (selectedContainerStuffer) {
      setStufferFdaRegistration(selectedContainerStuffer.fda_registration || '');
    } else {
      setStufferFdaRegistration('');
    }
  }, [watch('container_stuffer_id')]);

  return (
    <>
      <SelectGroup
        label={I18n.translate('exports.form.isf.container_stuffing_location_label')}
        placeholder={I18n.translate(
          'exports.form.isf.container_stuffing_location_placeholder'
        )}
        options={containerStuffings}
        ref={register}
        name='container_stuffing_id'
      />

      <SelectGroup
        label={I18n.translate('exports.form.isf.container_stuffing_label')}
        placeholder={I18n.translate('exports.form.isf.container_stuffing_placeholder')}
        options={containerStuffers}
        ref={register}
        name='container_stuffer_id'
      />

      <InputGroup
        label={I18n.translate('exports.form.isf.fda_registration_stuffing_label')}
        placeholder={I18n.translate(
          'exports.form.isf.fda_registration_stuffing_placeholder'
        )}
        value={stuffingFdaRegistration}
        ref={register}
        name='fda_registration_stuffing'
        readOnly
      />

      <InputGroup
        label={I18n.translate('exports.form.isf.fda_registration_export_label')}
        placeholder={I18n.translate(
          'exports.form.isf.fda_registration_export_placeholder'
        )}
        value={stufferFdaRegistration}
        ref={register}
        name='fda_registration_stuffer'
        readOnly
      />
    </>
  );
}

export default observer(ContainerStuffing);
