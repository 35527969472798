import { observer } from 'mobx-react';
import React from 'react';

import Fraction from 'productionOrders/models/Fraction';
import FractionsTableRow from './FractionsTableRow';

export interface RecursiveFractionsComponentProps {
  fractions: Fraction[];
  isMainFraction?: boolean;
  cuppingSessionEnabled?: boolean;
}

// Observer needs to be wrapped here, to work with recursive rendering
export const RecursiveFractionsComponent = observer(
  ({ fractions, cuppingSessionEnabled }: RecursiveFractionsComponentProps) => {
    return (
      <>
        {fractions.map((fraction) => (
          <React.Fragment key={fraction.node_identifier}>
            <FractionsTableRow
              fraction={fraction}
              cuppingSessionEnabled={cuppingSessionEnabled}
            />
            {fraction.subfractions && fraction.subfractions.length > 0 && (
              <RecursiveFractionsComponent fractions={fraction.subfractions} />
            )}
          </React.Fragment>
        ))}
      </>
    );
  }
);

export default RecursiveFractionsComponent;
