import React from 'react';
import { observer } from 'mobx-react';

import InputGroupComponent from 'components/groups/InputGroup';
import DeliveryOrderCost from 'fulfillment/models/DeliveryOrderCost';
import Checkbox from 'shared/Checkbox';
import { displayWithCurrency, exchangedValue } from 'utils/currency';

interface LoadoutFeeProps {
  deliveryOrderCost: DeliveryOrderCost;
}

function LoadoutFee({ deliveryOrderCost }: LoadoutFeeProps): JSX.Element | null {
  const {
    loadoutFee,
    setLoadoutFee,
    loadoutChecked,
    toggleLoadoutChecked,
    destinationWarehouseCost,
  } = deliveryOrderCost;

  const { sameCurrency, exchangeRate, loadCostsEditable } = destinationWarehouseCost;

  const handleOnLoadoutFeeHandler = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setLoadoutFee(event.target.value);

  return loadoutFee !== null ? (
    <tr>
      <td colSpan={2}>
        <Checkbox
          label='Loadout'
          checked={loadoutChecked}
          onChange={toggleLoadoutChecked}
          condensed
        />
      </td>

      <td>
        <InputGroupComponent
          disabled={!(loadoutChecked && loadCostsEditable)}
          append={exchangeRate.from.symbol}
          value={loadoutFee}
          onChange={handleOnLoadoutFeeHandler}
          inline
        />
      </td>

      {!sameCurrency && (
        <td>
          {displayWithCurrency(
            exchangedValue(loadoutFee, exchangeRate.rate),
            exchangeRate.to
          )}
        </td>
      )}
    </tr>
  ) : null;
}

export default observer(LoadoutFee);
