import { action, computed, observable } from 'mobx';

export interface Kind<T> {
  [key: string]: Array<T>;
}

class RadioGroup<T extends any> {
  public radios = observable.array<any>();
  @observable public selectedRadioId = -1;

  constructor(radios: Array<T>) {
    const selectableRadios = radios.map((checkbox) => ({
      ...(checkbox as Object),
    }));

    this.radios = observable.array(selectableRadios);
  }

  @action public selectRadio = (selectedRadioId: number) => {
    this.selectedRadioId = selectedRadioId;
  };

  @computed get radioKinds(): Kind<T> {
    return this.radios.reduce((kinds, value) => {
      kinds[value.kind] = [...(kinds[value.kind] || []), value];
      return kinds;
    }, {});
  }

  @computed get selectedRadio(): any {
    return this.radios.find(({ id }) => this.selectedRadioId === id);
  }
}

export default RadioGroup;
