import React from 'react';

import SpaceBetween from 'components/elements/SpaceBetween';
import { TileList } from 'shared/Tile';

import Detail, { IdentifierDetail, IdentifierDetailLink } from './Detail';
import { TraceabilityDetails } from './Details';
import LotPurchaseInformationOverlay from './LotPurchaseInformationOverlay';

import Routes from 'routes';
import type { LotTraceability } from '../types/traceability';
import { weightToString } from 'utils/weight';

interface LotTileListProps extends React.HTMLAttributes<HTMLDivElement> {
  lot: LotTraceability;
  lotSetIdentifier: string;
}

const LotTileList = ({
  lot,
  lot: { local_identifier, current_weight, producer, municipality },
}: LotTileListProps) => {
  const [showOverlay, setOverlay] = React.useState(false);

  return (
    <TileList>
      {showOverlay && (
        <LotPurchaseInformationOverlay
          traceability={lot}
          onPrimaryClick={() => setOverlay(!showOverlay)}
        />
      )}

      <TraceabilityDetails>
        <div></div>

        {lot.sample_local_identifier && (
          <a
            href={Routes.analyse_and_adjust_samples_path({
              'filter[local_identifier]': lot.sample_local_identifier,
            })}
          >
            <IdentifierDetailLink>{local_identifier}</IdentifierDetailLink>
          </a>
        )}
        {!lot.sample_local_identifier && (
          <IdentifierDetail>{local_identifier}</IdentifierDetail>
        )}

        <div></div>

        <Detail align='right'>{weightToString(current_weight)}</Detail>
      </TraceabilityDetails>

      <div>
        {producer && municipality && (
          <span>
            <strong>{municipality}</strong> – {producer}
          </span>
        )}
      </div>

      <SpaceBetween>
        <button
          onClick={(_event: React.MouseEvent) => setOverlay(!showOverlay)}
          type='button'
          className='button button--small button--ghost'
        >
          {I18n.translate('traceability.purchase_information')}
        </button>
      </SpaceBetween>
    </TileList>
  );
};

export default LotTileList;
