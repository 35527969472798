import styled from 'styled-components';
import COLORS from 'constants/colors';

const TableColumnHeader = styled.div`
  z-index: 9;
  position: sticky;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  height: 4.875rem;
  top: 0;
  padding: 1rem 1.875rem;
  font-size: 1.25rem;
  font-weight: 700;
  background: ${COLORS.WHITE};
  text-align: center;
`;

export default TableColumnHeader;
