import Sample from 'productionOrders/models/Sample';
import type MillingOutputType from 'millingOrders/types/MillingOutput';
import type { OutputType } from 'millingOrders/types/MillingOutput';
import SampleBuilder from 'utils/builders/SampleBuilder';
import { createSampleFactory } from 'utils/factories/createSampleFactory';
import { observable } from 'mobx';

class MillingOutput {
  public id: number;
  @observable public sample: Sample;
  public outputType: OutputType;

  public constructor({ output_type, sample }: MillingOutputType) {
    const sampleBuilder = new SampleBuilder(sample);

    this.sample = createSampleFactory(sample, sampleBuilder);

    this.outputType = output_type;
  }
}

export default MillingOutput;
