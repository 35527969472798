import SampleRowEntity from './SampleRowEntity';
import PssInternalContractType from 'customerSamples/types/PssInternalContractType';
import Ico from './Ico';

export class PssInternalContract extends SampleRowEntity {
  public icos: Ico[];

  constructor(internalContract: PssInternalContractType) {
    super(internalContract);

    this.icos = internalContract.icos.map((ico) => new Ico(ico));
  }
}

export default PssInternalContract;
