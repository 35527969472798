import React from 'react';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import DeliveryOrderRow from './DeliveryOrderRow';

const StyledTable = styled.table``;

const ContractIcoCol = styled.th`
  width: 15%;
`;

const StatusExportCol = styled.th`
  width: 10%;
`;

const UnitCol = styled.th`
  width: 10%;
`;

const DeliveryOrderTable: React.FC<React.HTMLAttributes<HTMLTableElement>> = ({
  ...props
}) => {
  const { deliveryOrderStore } = useStores();
  const { excelsoInventoryItems } = deliveryOrderStore;
  const deliveryOrdersRows = () =>
    excelsoInventoryItems.map((excelsoInventoryModel, index) => {
      return (
        <DeliveryOrderRow
          key={index}
          index={index}
          excelsoInventoryModel={excelsoInventoryModel}
          deliveryOrder={deliveryOrderStore.deliveryOrder}
        />
      );
    });

  return excelsoInventoryItems && excelsoInventoryItems.length > 0 ? (
    <StyledTable {...props}>
      <thead>
        <tr className='align-left'>
          <th className='table-border'>
            {I18n.translate('fulfillment.delivery_orders.client_warehouse')}
          </th>

          <ContractIcoCol className='table-border'>
            {I18n.translate('fulfillment.delivery_orders.contract_ico')}
          </ContractIcoCol>

          <th className='table-border'>
            {I18n.translate('fulfillment.delivery_orders.packaging_capacity')}
          </th>

          <th className='table-border'>
            {I18n.translate('fulfillment.delivery_orders.mark_origin')}
          </th>

          <UnitCol className='table-border'>
            {I18n.translate('fulfillment.delivery_orders.units_available_contract')}
          </UnitCol>

          <StatusExportCol className='table-border'>
            {I18n.translate('fulfillment.delivery_orders.export_status')}
          </StatusExportCol>

          <UnitCol className='table-border align-right'>
            {I18n.translate('fulfillment.delivery_orders.units_available')}
          </UnitCol>
          <th className='align-center'>
            {I18n.translate('fulfillment.delivery_orders.units_to_deliver')}
          </th>
        </tr>
      </thead>

      <tbody>{deliveryOrdersRows()}</tbody>
    </StyledTable>
  ) : (
    <span>No Inventory available</span>
  );
};

export default observer(DeliveryOrderTable);
