import { observer } from 'mobx-react';
import React from 'react';
import useStores from 'utils/useStores';

import ContentHeader from 'components/elements/ContentHeader';

export interface FractionHeaderProps {
  run: number;
  createdAt: string;
}

function FractionHeader({ run, createdAt }: FractionHeaderProps) {
  const { fractionsStore } = useStores();

  return (
    <div className='fieldset fieldset--full'>
      <ContentHeader title={`${I18n.translate('fractions.run')} ${run} - ${createdAt}`} />

      <div className='fieldset__info'>
        <legend>
          <span>{I18n.translate('fractions.production_details')}</span>
        </legend>
      </div>
      <div className='fieldset__form'>
        <div className='key-value-table'>
          <div className='key-value-table__wrapper'>
            <div className='key-value-table__column'>
              <div className='key-value-table__pair'>
                <span className='key-value-table__key'>
                  {I18n.translate('fractions.remaining_excelso')}
                </span>
                <span className='key-value-table__value'>
                  {fractionsStore.remainingExcelsoWeight} kg
                </span>
              </div>
            </div>
            <div className='key-value-table__column'>
              <div className='key-value-table__pair'>
                <span className='key-value-table__key'>
                  {I18n.translate('fractions.parchment_weight')}
                </span>
                <span className='key-value-table__value'>
                  {fractionsStore.maxWeight} kg
                </span>
              </div>
            </div>
            {run == 1 && (
              <div className='key-value-table__column'>
                <div className='key-value-table__pair'>
                  <span className='key-value-table__key'>
                    {I18n.translate('fractions.estimated_green')}
                  </span>
                  <span className='key-value-table__value'>
                    {fractionsStore.estimatedWeight.toFixed(2)} kg
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(FractionHeader);
