import React, { useState, useEffect } from 'react';
import InputGroupComponent from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';

type RecoverableCaracolContainerProps = {
  caracolWeight: string;
};

function RecoverableCaracolContainer({
  caracolWeight,
}: RecoverableCaracolContainerProps) {
  const [recoverablePercentage, setRecoverablePercentage] = useState<number>(100);
  const [recoverableCaracol, setRecoverableCaracol] = useState<string>(caracolWeight);

  const handleOnCaracolPercentageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const percentage = parseInt(event.target.value);

    if (isNaN(percentage)) return;

    setRecoverablePercentage(percentage);
  };

  useEffect(() => {
    const parsedCaracolWeight = parseInt(caracolWeight);

    const newRecoverableCaracol = (
      (parsedCaracolWeight / 100) *
      recoverablePercentage
    ).toFixed(2);

    setRecoverableCaracol(newRecoverableCaracol);
  }, [recoverablePercentage]);

  return (
    <div className='fieldset fieldset--full'>
      <div className='fieldset__info'>
        <legend>{`${I18n.translate(
          'activerecord.attributes.milling_order.recoverable_caracol'
        )} %`}</legend>

        <p className='small'>{`${recoverableCaracol} kg`}</p>
      </div>

      <div className='fieldset__form'>
        <div className='l-auto-fill-grid'>
          <InputGroupComponent
            name='milling_order[recoverable_caracol_percentage]'
            required
            onChange={handleOnCaracolPercentageChange}
            value={recoverablePercentage}
            min={1}
            max={100}
            variant={VARIANT.INTEGER}
          />
        </div>
      </div>
    </div>
  );
}

export default RecoverableCaracolContainer;
