import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Overlay from 'shared/Overlay';
import { addTestingIdentifier } from 'utils/testing';

interface IWSRResourcesObservationFieldProps {
  namePrefix: string;
}

const WSRResourcesObservationField = ({
  namePrefix,
}: IWSRResourcesObservationFieldProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const { register, setValue, watch } = useFormContext();
  const inputName = `${namePrefix}.observations`;
  const [inputState, setInputState] = useState('');

  const handleSave = () => {
    setValue(inputName, inputState);
    setIsVisible(false);
  };

  const observationWatcher = watch(inputName);

  return (
    <>
      <button
        className='button button--small button--ghost'
        type='button'
        onClick={() => setIsVisible(true)}
        {...addTestingIdentifier('ico_observation_field_button')}
      >
        {observationWatcher
          ? I18n.translate('buttons.update')
          : I18n.translate('buttons.create')}{' '}
        {I18n.translate('attributes.observations')}
      </button>
      <input type='hidden' name={inputName} ref={register()} />
      {isVisible && (
        <Overlay
          title={I18n.translate('attributes.observations')}
          primaryText={I18n.translate('buttons.save')}
          onPrimaryClick={handleSave}
        >
          <TextAreaGroupComponent
            placeholder={I18n.translate(
              'customer_samples_dashboard.observations_placeholder'
            )}
            value={inputState}
            onChange={(e) => setInputState(e.target.value)}
            id='observations'
            label={I18n.translate('attributes.observations')}
            {...addTestingIdentifier('ico_observation_field')}
          />
        </Overlay>
      )}
    </>
  );
};
export default WSRResourcesObservationField;
