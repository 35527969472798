import styled from 'styled-components';
import COLORS from 'constants/colors';

interface IcoItemProps {
  isSelectedIco?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
}

export const IcoItem = styled.li<IcoItemProps>`
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
  width: calc(50% - 0.5rem);
  margin-right: 0.325rem;
  margin-left: 0;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  border: 1px solid ${(props) => (props.highlighted ? COLORS.BLUE : COLORS.GRAY)};
  border-radius: 3px;
  background: ${COLORS.MEDIUM_LIGHT_GRAY};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: ${(props) => (props.highlighted ? '0px 0px 5px #006F94' : 'none')};

  // Overwrite Styling if selected
  ${(props) =>
    props.isSelectedIco
      ? `
        opacity: 1;
        border: 1px solid ${COLORS.BLUE};
        background: ${COLORS.BLUE};
        color: ${COLORS.WHITE};
      `
      : null}

  &:nth-child(2n) {
    margin-right: 0;
    margin-left: 0.325rem;
  }

  &:hover {
    filter: brightness(0.95);
  }
`;

export default IcoItem;
