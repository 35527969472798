import React from 'react';
import WSRSelectionTable from '../warehouse_sample_request/WSRSelectionTable';
import { SpotContract } from './useSpotContracts';
import { IPreShipmentSample } from './useSpotSampleIcos';
import WSRSpotContractsTable from './WSRSpotContractsTable';

type WSRSpotSampleSelectionTableProps = {
  spotContracts: SpotContract[];
  preShipmentSample?: IPreShipmentSample;
};

const WSRSpotSampleSelectionTable = ({
  spotContracts,
  preShipmentSample,
}: WSRSpotSampleSelectionTableProps): JSX.Element => {
  return (
    <WSRSelectionTable<SpotContract>
      resources={spotContracts}
      resourceFormName='warehouse_sample_request_spot_contracts'
      resourceTitle='Spot Contracts'
      resourceIdName='spot_contract_id'
      officeWeightInputInHeader={!preShipmentSample}
      officeSampleIdentifier={preShipmentSample?.main_identifier}
      officeWeightInputInTable={false}
    >
      {(insert, deselect, selectedSpotContracts) => (
        <WSRSpotContractsTable
          onSelect={insert}
          onDeselect={deselect}
          spotContracts={selectedSpotContracts}
        />
      )}
    </WSRSelectionTable>
  );
};
export default WSRSpotSampleSelectionTable;
