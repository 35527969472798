import React from 'react';
import styled from 'styled-components';

import { TileList } from 'shared/Tile';

import AssignedCoffeeType from '../types/assignedCoffee';
import Details from './Details';
import { Lot } from '../types/lot';
import { LotSet } from '../types/lotSet';
import SpaceBetween from 'components/elements/SpaceBetween';
import { IdentifierDetail } from './Detail';

export interface AssignedCoffeeProps {
  assignedCoffee: AssignedCoffeeType;
}

export const PrimaryDetails = styled(Details)`
  grid-template-columns: 2.5em 8.125em minmax(4em, 1fr) 6em;
  grid-column-gap: 0.9375em;
`;

export const OriginDetails = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

function AssignedCoffee({ assignedCoffee }: AssignedCoffeeProps) {
  let item: Lot | LotSet;

  if (assignedCoffee.kind === 'lot') {
    item = assignedCoffee.lot;
  } else {
    item = assignedCoffee.lot_set;
  }

  const { quantity } = assignedCoffee;
  const { local_identifier, producer, purchase_warehouse, certification } = item;

  return (
    <TileList>
      <PrimaryDetails>
        <div></div>
        <IdentifierDetail>{local_identifier}</IdentifierDetail>
        <div></div>
        <div className='align-right'>{`${quantity.value} ${quantity.unit}`}</div>
      </PrimaryDetails>

      <OriginDetails>
        {purchase_warehouse && (
          <>
            <strong>{purchase_warehouse}</strong> {producer && `– ${producer}`}
          </>
        )}
      </OriginDetails>

      <SpaceBetween>{certification}</SpaceBetween>
    </TileList>
  );
}

export default AssignedCoffee;
