import { deserialise } from 'kitsu-core';
import React from 'react';

import {
  LotTraceability,
  LotSetTraceability,
  IcoTraceability,
} from '../types/traceability';

import Traceabilities from '../components/Traceabilities';
import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';
import SelectOptionArray from 'types/model/selectOption';
import { Tabs } from 'traceability/components/TraceabilityTabBar';
import { Pagy } from 'shared/Pagynation';
import { FilterOptions } from 'traceability/components/SearchBar';
import { JsonApi } from 'types/utils/jsonApi';

export interface TraceabilityContainerProps {
  filters?: FilterOptions;
  specialMarks: SelectOptionArray;
  traceabilities: JsonApi<LotTraceability | LotSetTraceability | IcoTraceability>;
  tabs: Tabs;
  pagy: Pagy;
}

function TraceabilityContainer({
  traceabilities,
  filters,
  specialMarks,
  tabs,
  pagy,
}: TraceabilityContainerProps) {
  const deserializedTraceabilities:
    | LotTraceability[]
    | LotSetTraceability[]
    | IcoTraceability[] = deserialise(traceabilities).data;

  return (
    <AbilityContext.Provider value={Ability}>
      <Traceabilities
        traceabilities={deserializedTraceabilities}
        filters={filters}
        specialMarks={specialMarks}
        tabs={tabs}
        pagy={pagy}
      />
    </AbilityContext.Provider>
  );
}

export default TraceabilityContainer;
