import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Conclusion from '../models/Conclusion';

import Checkbox from 'shared/Checkbox';

interface ConclusionInputProps {
  sampleId: number;
  conclusion: Conclusion;
  label?: string;
  completed: boolean;
  withReason: boolean;
  cuppingSessionEnabled: boolean;
}

const ConclusionInput = ({
  sampleId,
  conclusion,
  label,
  completed,
  withReason,
  cuppingSessionEnabled,
}: ConclusionInputProps) => {
  const handleSelectChange = (id: string) => {
    conclusion.setReasonId(id);
  };

  const renderReason = () => (
    <div
      className={classNames({
        'l-auto-fill-grid': label,
        'sensorial-analyses__conclusion-select': true,
        'sensorial-analyses__conclusion-select--active':
          conclusion.accepted && withReason,
      })}
    >
      <div
        className={classNames('input-group', {
          'input-group--inline': !label,
        })}
      >
        {label && <label>{label}</label>}
        <select
          className='input--condensed'
          onChange={(e) => handleSelectChange(e.target.value)}
          value={conclusion.reasonId}
          name={`samples[${sampleId}][accepting_reason_id]`}
          id={`sample_${sampleId}_accepting_reason_id`}
        >
          {renderReasons()}
        </select>
      </div>
    </div>
  );

  const renderReasons = () => {
    return (
      <>
        <option value=''>{I18n.translate('sensorial_analysis.select_reason')}</option>
        {conclusion.reasons.map((reason) => (
          <option key={reason.name} value={reason.id}>
            {reason.name}
          </option>
        ))}
      </>
    );
  };

  return (
    <>
      {!label && renderReason()}
      <div
        className={classNames('check-group-list', 'check-group-list--two-columns', {
          'check-group-list--inline': !label,
        })}
      >
        <Checkbox
          label={I18n.translate('sensorial_analysis.accept_sample')}
          icon={'tick'}
          id={`samples[${sampleId}][accept_sample]`}
          onChange={conclusion.accept}
          checked={conclusion.accepted}
          required={!cuppingSessionEnabled && !completed && !conclusion.rejected}
          inline={true}
        />
        <Checkbox
          label={I18n.translate('sensorial_analysis.reject_sample')}
          icon={'cross'}
          id={`samples[${sampleId}][reject_sample]`}
          onChange={conclusion.reject}
          checked={conclusion.rejected}
          required={!cuppingSessionEnabled && !completed && !conclusion.accepted}
          inline={true}
        />
        <input
          value={conclusion.rejectedValue}
          type='hidden'
          name={`samples[${sampleId}][rejected]`}
        />
      </div>
      {label && renderReason()}
    </>
  );
};

export default observer(ConclusionInput);
