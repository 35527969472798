import Customer from 'customerSamples/models/Customer';
import SampleRowEntityType from 'customerSamples/types/SampleRowEntityType';

export class SampleRowEntity {
  public id: number;
  public estimatedDeliveryDate: string;
  public mainIdentifier: string;
  public customer?: Customer;

  constructor(entity: SampleRowEntityType) {
    this.id = entity.id;
    this.estimatedDeliveryDate = entity.estimated_delivery_date;
    this.mainIdentifier = entity.main_identifier;

    if (entity.customer) {
      this.customer = new Customer(entity.customer);
    }
  }
}

export default SampleRowEntity;
