import Icon from 'components/elements/Icon';
import React, { useMemo } from 'react';
import styled from 'styled-components';

interface ReceptionIcoProps {
  valid: boolean | undefined;
}

const LargeIcon = styled(Icon)`
  width: 2em;
  height: 2em;
`;

function ReceptionIcon({ valid }: ReceptionIcoProps): JSX.Element | null {
  const icon = useMemo(() => {
    if (valid) {
      return { name: 'check', color: 'green' };
    } else if (valid === undefined) {
      return undefined;
    } else {
      return { name: 'failed', color: 'red' };
    }
  }, [valid]);

  if (icon) {
    return (
      <div className='px-2'>
        <LargeIcon icon={icon.name} color={icon.color} />
      </div>
    );
  } else {
    return null;
  }
}

export default ReceptionIcon;
