import styled from 'styled-components';
import COLORS from 'constants/colors';

export default styled.div`
  width: 100%;
  background: ${COLORS.MEDIUM_LIGHT_GRAY};
  margin-top: 0.625rem;
  border-bottom: 3px solid ${COLORS.BLACK};
  padding-bottom: 0.625rem;

  &:last-child {
    border-bottom: none;
  }
`;
