import React from 'react';
import { observer } from 'mobx-react';

import type { AccordionHeaderProps } from 'shared/Accordion/components/AccordionWrapper';
import AccordionFormHeader from '../AccordionFormHeader';

export interface AvailabilityNoticeHeaderHeaderProps extends AccordionHeaderProps {
  completed: boolean;
}

const AvailabilityNoticeHeaderHeader = ({
  expanded,
  completed,
}: AvailabilityNoticeHeaderHeaderProps) => (
  <AccordionFormHeader
    expanded={expanded}
    headline={I18n.translate('exports.form.availability_notice.header')}
    completed={completed}
  />
);

export default observer(AvailabilityNoticeHeaderHeader);
