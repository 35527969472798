import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Input from 'components/elements/Input';
import InputGroupComponent from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';

import {
  CompletedTypes,
  MillingTypeResult,
} from 'millingOrders/components/FinalizeMillingOrderOverlay';
import { addTestingIdentifier } from 'utils/testing';

const TypeColumn = styled.td`
  width: 12.1875em;
`;
const LocationColumn = styled.td`
  width: 6.25em;
`;
const PositionColumn = styled.td`
  width: 10em;
`;
const QuantityColumn = styled.td`
  width: 16em;
  padding-right: 6.25em !important;
`;

interface FinalizeMillingRowProps {
  type: keyof CompletedTypes;
  values: MillingTypeResult;
  withLocation: boolean;
  readOnly: boolean;
  onComplete: (type: keyof CompletedTypes, values: MillingTypeResult | null) => void;
}

const FinalizeMillingRowComponent = ({
  type,
  values,
  withLocation,
  readOnly,
  onComplete,
}: FinalizeMillingRowProps) => {
  const [area, setArea] = useState(values?.area || '');
  const [bin, setBin] = useState(values?.bin || '');
  const [level, setLevel] = useState(values?.level || '');
  const [position, setPosition] = useState(values?.position || '');
  const [quantity, setQuantity] = useState(values?.quantity || '');

  useEffect(() => {
    if (
      (withLocation && area && bin && level && position && quantity) ||
      (!withLocation && quantity)
    ) {
      onComplete(type, {
        area,
        bin,
        level,
        position,
        quantity,
      });
    } else if (
      type !== 'excelso' &&
      parseInt(quantity, 10) === 0 &&
      !area &&
      !bin &&
      !level &&
      !position
    ) {
      onComplete(type, {
        area: undefined,
        bin: undefined,
        level: undefined,
        position: undefined,
        quantity,
      });
    } else if (
      ((!area || !bin || !level || !position || !quantity) && !readOnly) ||
      ((!area || !bin || !level || !position) && readOnly)
    ) {
      onComplete(type, null);
    }
  }, [area, bin, level, position, quantity]);

  const disableLocationInputs = readOnly && parseFloat(quantity) === 0.0;

  return (
    <tr key={type} {...addTestingIdentifier(`milling_row_${type}`)}>
      <TypeColumn className='table-border'>
        {I18n.translate(`milling_orders.finalise_milling.types.${type}`)}
      </TypeColumn>
      <LocationColumn className='table-border'>
        {withLocation && (
          <Input
            variant={VARIANT.INTEGER}
            placeholder=' '
            required={true}
            value={area}
            onChange={(e) => setArea(e.target.value)}
            disabled={disableLocationInputs}
            selectTextOnFocus={true}
            {...addTestingIdentifier('area_location_input')}
          />
        )}
      </LocationColumn>
      <LocationColumn className='table-border'>
        {withLocation && (
          <Input
            variant={VARIANT.INTEGER}
            placeholder=' '
            required={true}
            value={bin}
            onChange={(e) => setBin(e.target.value)}
            disabled={disableLocationInputs}
            selectTextOnFocus={true}
            {...addTestingIdentifier('bin_location_input')}
          />
        )}
      </LocationColumn>
      <LocationColumn className='table-border'>
        {withLocation && (
          <Input
            variant={VARIANT.INTEGER}
            placeholder=' '
            required={true}
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            disabled={disableLocationInputs}
            selectTextOnFocus={true}
            {...addTestingIdentifier('level_location_input')}
          />
        )}
      </LocationColumn>
      <PositionColumn className='table-border'>
        {withLocation && (
          <Input
            required={true}
            placeholder=' '
            value={position}
            maxLength={6}
            onChange={(e) => setPosition(e.target.value)}
            disabled={disableLocationInputs}
            selectTextOnFocus={true}
            {...addTestingIdentifier('position_location_input')}
          />
        )}
      </PositionColumn>
      <QuantityColumn>
        <InputGroupComponent
          variant={VARIANT.DECIMAL}
          placeholder=' '
          readOnly={readOnly}
          noMargin={true}
          append='kg'
          step={0.01}
          required={!readOnly}
          value={quantity}
          selectTextOnFocus={true}
          onChange={(e) => setQuantity(e.target.value)}
          {...addTestingIdentifier('quantity_input')}
        />
      </QuantityColumn>
    </tr>
  );
};

export default FinalizeMillingRowComponent;
