import React, { useState } from 'react';

import InputGroup from 'components/groups/InputGroup';
import Overlay from 'shared/Overlay';

export interface RequestOrphanIcoOverlayProps {
  onPrimaryClick?: (event: React.MouseEvent, values: any) => void;
  onSecondaryClick?: (event: React.MouseEvent) => void;
  onChange?: (event: React.ChangeEvent) => void;
  title: string;
  missingWeight: string;
}

const RequestOrphanIcoOverlay: React.FC<RequestOrphanIcoOverlayProps> = ({
  onPrimaryClick,
  onSecondaryClick,
  missingWeight,
  ...props
}) => {
  const [requestWeight, setRequestWeight] = useState(missingWeight);

  const handleOnPrimaryClick = (event: React.MouseEvent) => {
    if (onPrimaryClick) {
      onPrimaryClick(event, missingWeight);
    }
  };

  const handleOnSecondaryClick = (event: React.MouseEvent) => {
    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
  };

  return (
    <Overlay
      autoWidth={true}
      onPrimaryClick={handleOnPrimaryClick}
      primaryText={I18n.translate('buttons.request')}
      onSecondaryClick={handleOnSecondaryClick}
      secondaryText={I18n.translate('buttons.close')}
      {...props}
    >
      <InputGroup
        label={I18n.translate('milling_orders.show.missing_weight')}
        value={requestWeight}
        append='kg'
        onChange={(e) => setRequestWeight(e.target.value)}
      />
    </Overlay>
  );
};

export default RequestOrphanIcoOverlay;
