import React from 'react';
import styled from 'styled-components';
import { TileList } from 'shared/Tile';
import ContractTraceability from 'traceability/types/ContractTraceability';
import Details from './Details';
import Detail from './Detail';
import { ContractDetails } from './ContractDetails';

export interface MainContractDetailsProps {
  traceability: ContractTraceability;
  className?: string;
}

const QuantityDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(8em, 1fr));
  grid-column-gap: 0.9375em;
  grid-row-gap: 0.75em;
`;

function MainContractDetails({
  className,
  traceability: { identifier, initial_units, reserved_units, available_units, state },
}: MainContractDetailsProps) {
  return (
    <TileList className={className}>
      <ContractDetails>
        <Detail>{I18n.translate('traceability.contracts.identifier')}</Detail>
        <Detail>{I18n.translate('traceability.contracts.initial_quantity')}</Detail>
        <Detail>{identifier}</Detail>
        <Detail>
          {I18n.translate('traceability.contracts.units', {
            units: initial_units,
          })}
        </Detail>
      </ContractDetails>

      <QuantityDetails>
        <Detail>{I18n.translate('traceability.contracts.reserved_quantity')}</Detail>
        <Detail>{I18n.translate('traceability.contracts.available_quantity')}</Detail>

        <Detail>
          {I18n.translate('traceability.contracts.units', {
            units: reserved_units,
          })}
        </Detail>
        <Detail>
          {I18n.translate('traceability.contracts.units', {
            units: available_units,
          })}
        </Detail>
      </QuantityDetails>

      <Details>
        <Detail>{I18n.translate('traceability.contracts.status')}</Detail>
        <Detail>
          {I18n.translate(`activerecord.attributes.contract.aasm_state.${state}`)}
        </Detail>
      </Details>
    </TileList>
  );
}

export default styled(MainContractDetails)`
  margin-top: 0.42em;
`;
