import { observer } from 'mobx-react';
import React from 'react';
import Checkbox from 'shared/Checkbox';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import placeholder from 'utils/placeholder';
import SelectableTransportSampleModel from '../models/SelectableTransportSampleModel';

interface InventoryItemProps {
  inventory: SelectableTransportSampleModel;
  onCheck: () => any;
  checked?: boolean;
  disabled?: boolean;
}

const InventoryItem = ({ inventory, onCheck, checked, disabled }: InventoryItemProps) => {
  const handleOnChange = () => {
    if (disabled) {
      return;
    }

    onCheck();
  };

  return (
    <tr>
      <td className='lot--id'>
        <TextWithSub
          text={inventory.attributes.identifier}
          sub={inventory.attributes.parent_identifier}
        />
      </td>

      <td className='table-border'>
        <TextWithSub
          text={placeholder(
            inventory.attributes.customer || inventory.attributes.producer
          )}
          sub={inventory.attributes.certification}
        />
      </td>

      <td className='selected-lots selected-lots__quality table-border align-center'>
        {I18n.translate(
          `activerecord.attributes.sample.sample_type.${inventory.attributes.sample_type}`
        )}
      </td>

      <td className='selected-lots selected-lots__bags table-border align-center'>
        <TextWithSub
          text={inventory.attributes.quality}
          sub={inventory.attributes.grade}
        />
      </td>

      <td className='selected-lots selected-lots__weight table-border align-center'>
        {inventory.attributes.weight} gr
      </td>

      <td className='selected-lots selected-lots__whitespace table-border align-center'>
        {inventory.attributes.mark}
      </td>

      <td className='selected-lots selected-lots__checkbox align-center'>
        <Checkbox checked={checked} onChange={handleOnChange} />
      </td>
    </tr>
  );
};

export default observer(InventoryItem);
