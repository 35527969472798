import { observer } from 'mobx-react';
import classnames from 'classnames';
import React from 'react';
import useStores from 'utils/useStores';

import { Icons } from 'shared/Checkbox';
import StandaloneAcceptingReasonCheckbox from 'shared/Checkbox/components/StandaloneAcceptingReasonCheckbox';
import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import PercentageAppendComponent from './PercentageAppendComponent';

import FirstGroupComponent from './FirstGroupComponent';
import SecondGroupComponent from './SecondGroupComponent';
import { addTestingIdentifier } from 'utils/testing';

const ExcelsoPhysicalAnalysisForm = () => {
  const { excelsoPhysicalAnalysisStore } = useStores();
  const physicalAnalysis = excelsoPhysicalAnalysisStore.excelsoPhysicalAnalysis;

  return (
    <>
      {physicalAnalysis && (
        <>
          <div className='fieldset'>
            <Grid grid={9}>
              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sample_weight')}
                value={physicalAnalysis.sampleWeight}
                onChange={(e) =>
                  physicalAnalysis.setSampleWeight(parseInt(e.target.value))
                }
                selectTextOnFocus={true}
                append='gr'
                name='sample_weight'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sieve_14')}
                value={physicalAnalysis.sieve14}
                onChange={(e) => physicalAnalysis.setSieve14(parseInt(e.target.value))}
                selectTextOnFocus={true}
                append={
                  <PercentageAppendComponent
                    percentage={physicalAnalysis.getWeightPercentageString(
                      physicalAnalysis.sieve14
                    )}
                  />
                }
                name='sieve_14'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.sieve_15')}
                value={physicalAnalysis.sieve15}
                onChange={(e) => physicalAnalysis.setSieve15(parseInt(e.target.value))}
                selectTextOnFocus={true}
                append={
                  <PercentageAppendComponent
                    percentage={physicalAnalysis.getWeightPercentageString(
                      physicalAnalysis.sieve15
                    )}
                  />
                }
                name='sieve_15'
                type='number'
              />

              <InputGroupComponent
                label={I18n.translate('qa_physical_analysis.uv')}
                value={physicalAnalysis.uv}
                onChange={(e) => physicalAnalysis.setUv(parseInt(e.target.value))}
                selectTextOnFocus={true}
                append='#'
                name='uv'
                type='number'
              />
            </Grid>
          </div>

          <FirstGroupComponent physicalAnalysis={physicalAnalysis} />

          <SecondGroupComponent physicalAnalysis={physicalAnalysis} />

          <div className='fieldset'>
            <TextAreaGroupComponent
              label={I18n.translate('qa_physical_analysis.observations')}
              value={physicalAnalysis.observations}
              name='observations'
              onChange={(e) => physicalAnalysis.setObservations(e.target.value)}
            />
          </div>

          <div className='fieldset'>
            <div
              className='l-auto-fill-grid'
              style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
            >
              <StandaloneAcceptingReasonCheckbox
                reasons={excelsoPhysicalAnalysisStore.reasons}
                name='rejected'
                fieldName='accepting_reason_id'
                icon={Icons.cross}
                isRejected={physicalAnalysis.isRejectedInitiallyChecked}
                initialReasonId={physicalAnalysis.acceptingReasonId}
                showReasons={physicalAnalysis.exceedsNorm}
                label={I18n.translate('attributes.rejected')}
              />
            </div>
          </div>

          <div className='fieldset'>
            <div className='l-distribute-l-r'>
              <a
                href={excelsoPhysicalAnalysisStore.cancelUrl}
                className='button button--gray'
              >
                {I18n.translate('buttons.cancel')}
              </a>

              <button
                type='submit'
                className={classnames(
                  'button',
                  !physicalAnalysis.isValid && 'button--disabled'
                )}
                {...addTestingIdentifier('save_physical_analysis_button')}
              >
                {I18n.translate('buttons.save')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default observer(ExcelsoPhysicalAnalysisForm);
