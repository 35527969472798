import csrfToken from './csrfToken';
import type Response from '../types/utils/Response';

export enum methodInterface {
  'GET',
  'POST',
  'PATCH',
  'DELETE',
}

export async function customFetch<T = Response<any>>(
  url: string,
  body?: object | undefined | FormData | string,
  method?: keyof typeof methodInterface
): Promise<T> {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': csrfToken(),
  });

  let postData;

  if (body instanceof FormData) {
    headers.delete('Content-Type');
    postData = body;
  } else {
    postData = JSON.stringify(body);
  }

  const response = await fetch(url, {
    body: postData,
    headers,
    method: method ? method : 'POST',
  });

  // TODO: Errors from APIs should be handled here

  const data = await response.json<T>();

  return data;
}
