import React, { useState } from 'react';
import Icon from 'components/elements/Icon';
import COLORS from 'constants/colors';
import styled from 'styled-components';
import useClickOutside from 'use-click-outside';

import SpaceBetween from 'components/elements/SpaceBetween';

export type Info = {
  label: string;
  value: string;
};

interface SubToolTipProps {
  title: string;
  infos: Array<Info>;
  icon?: string;
  asGrid?: boolean;
}

const SubToolTipComponent = styled.div`
  display: inline-block;
  position: relative;
  text-align: initial;
  font-family: 'Nunito', sans-serif;

  > svg {
    cursor: pointer;
  }
`;

interface SubToolTipOverlayProps {
  asGrid?: boolean;
}

const SubToolTipOverlay = styled.div<SubToolTipOverlayProps>`
  position: absolute;
  right: 0.75em;
  bottom: 2em;
  min-width: ${(props) => (props.asGrid ? '30.625em' : '21.25em')};
  background: ${COLORS.WHITE};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  padding: 1em 1.25em;

  > span {
    display: block;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 0.475em;
    padding-bottom: 0.275em;
    border-bottom: 1px solid ${COLORS.BORDERS};
  }

  ul > li > div > span {
    &:nth-child(${(props) => (props.asGrid ? 1 : 2)}) {
      font-weight: 800;
      display: ${(props) => (props.asGrid ? 'block' : 'intial')};
    }
  }

  ul {
    display: ${(props) => (props.asGrid ? 'grid' : 'initial')};
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.625em;
  }
`;

const SubToolTip: React.FC<SubToolTipProps> = ({
  title,
  infos,
  icon = 'info',
  asGrid,
}) => {
  const ref = React.createRef<HTMLDivElement>();
  const [showSubToolTip, setShowSubToolTip] = useState(false);

  const onClose = () => {
    if (showSubToolTip) {
      setShowSubToolTip(false);
    }
  };

  useClickOutside(ref, onClose);

  const ListComponent = ({ children }) =>
    asGrid ? <div>{children}</div> : <SpaceBetween>{children}</SpaceBetween>;

  return (
    <SubToolTipComponent ref={ref}>
      {showSubToolTip && (
        <SubToolTipOverlay asGrid={asGrid}>
          <span>{title}</span>
          <ul>
            {infos?.map((info) => (
              <li key={info.label}>
                <ListComponent>
                  <span>{info.label}:</span>
                  <span>{info.value}</span>
                </ListComponent>
              </li>
            ))}
          </ul>
        </SubToolTipOverlay>
      )}
      <Icon
        icon={icon}
        color={COLORS.GRAY}
        onClick={() => setShowSubToolTip(!showSubToolTip)}
      />
    </SubToolTipComponent>
  );
};
export default SubToolTip;
