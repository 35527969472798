import { action, observable } from 'mobx';
import ITransportSample from 'samples/types/ITransportSample';

class SelectableModel {
  @observable public selected: boolean;

  constructor(selected = false) {
    this.selected = selected;
  }

  @action public setSelected = (selected: boolean) => {
    this.selected = selected;
  };
}

class SelectableTransportSampleModel extends SelectableModel {
  @observable public quantity: number;
  public attributes: ITransportSample;

  constructor(attributes: ITransportSample, selected = false) {
    super(selected);
    this.attributes = attributes;
  }
}

export default SelectableTransportSampleModel;
