import React from 'react';
import { observer } from 'mobx-react';
import Sample from 'customerSamples/models/Sample';
import { addTestingIdentifier } from 'utils/testing';
import TableData from 'customerSamples/components/TableData';

export interface InternalSampleIdentifierProps {
  internalSample: Sample;
  internalSampleIdentifier: string;
}

function InternalSampleIdentifier({
  internalSample,
  internalSampleIdentifier,
}: InternalSampleIdentifierProps): JSX.Element {
  return (
    <>
      <TableData className='align-left' {...addTestingIdentifier('csv_sample_row_type')}>
        {I18n.translate(`customer_samples_dashboard.${internalSample.sampleType}`)}
      </TableData>
      <TableData className='align-center' {...addTestingIdentifier('csv_sample_row_id')}>
        <b>{internalSampleIdentifier}</b>
      </TableData>
    </>
  );
}

export default observer(InternalSampleIdentifier);
