import React, { useEffect } from 'react';
import Routes from 'routes';

import Icon from 'components/elements/Icon';
import SpaceBetween from 'components/elements/SpaceBetween';
import { customFetch } from 'utils/fetch';
import Overlay from 'shared/Overlay';
import InputGroupComponent from 'components/groups/InputGroup';
import Grid from 'components/elements/Grid';
import { VARIANT } from 'constants/inputTypes';
import WetLotModel from 'wetCoffee/models/WetLot';

interface WetCoffeeLocationProps {
  lot: WetLotModel;
}

const WetCoffeeLocation = ({
  lot,
  lot: {
    attributes: {
      id,
      location: { area, bin, level, position },
    },
  },
}: WetCoffeeLocationProps) => {
  const [showOverlay, setOverlay] = React.useState(false);
  const [location, setLocation] = React.useState({ area, bin, level, position });

  useEffect(() => {
    lot.setLatestLocation(location);
  }, [location]);

  const handleLocationClick = () => {
    setOverlay(true);
  };

  const handleOnPrimaryClick = () => {
    customFetch(Routes.wet_reception_update_lot_location_path(id), {
      lot: location,
    }).then((response) => {
      if (!response.success) {
        alert('Something went wrong! Values reseted');
        setLocation({ area, bin, level, position });
      }
      setOverlay(false);
    });
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  return (
    <>
      {showOverlay && (
        <Overlay
          title={I18n.translate('wet_reception.index.location_update')}
          primaryText={I18n.translate('buttons.update')}
          onPrimaryClick={handleOnPrimaryClick}
          secondaryText={I18n.translate('buttons.cancel')}
          onSecondaryClick={handleOnSecondaryClick}
        >
          <Grid grid={6}>
            <InputGroupComponent
              label={I18n.translate('attributes.location_area')}
              defaultValue={location.area}
              variant={VARIANT.INTEGER}
              onChange={(e) => setLocation({ ...location, area: e.target.value })}
            />
            <InputGroupComponent
              label={I18n.translate('attributes.location_bin')}
              defaultValue={location.bin}
              variant={VARIANT.INTEGER}
              onChange={(e) => setLocation({ ...location, bin: e.target.value })}
            />
            <InputGroupComponent
              label={I18n.translate('attributes.location_level')}
              defaultValue={location.level}
              variant={VARIANT.INTEGER}
              onChange={(e) => setLocation({ ...location, level: e.target.value })}
            />
            <InputGroupComponent
              label={I18n.translate('attributes.location_position')}
              defaultValue={location.position}
              maxLength={6}
              onChange={(e) => setLocation({ ...location, position: e.target.value })}
            />
          </Grid>
        </Overlay>
      )}
      <SpaceBetween>
        <div
          className='button button--small button--icon button--ghost'
          onClick={handleLocationClick}
        >
          <Icon icon='pen' />
        </div>
        <span>{location.area || '-'}</span>
        <span>/</span>
        <span>{location.bin || '-'}</span>
        <span>/</span>
        <span>{location.level || '-'}</span>
        <span>/</span>
        <span>{location.position || '-'}</span>
      </SpaceBetween>
    </>
  );
};

export default WetCoffeeLocation;
