import { computed } from 'mobx';
import type ISensorialAnalysis from 'types/model/ISensorialAnalysis';

class SensorialAnalysis {
  public id?: number;
  public failed = false;
  public acceptingReasonId?: number;
  public finalized = false;
  public cuppingTableIdentifier?: string;

  constructor(sensorialAnalysis?: ISensorialAnalysis) {
    if (sensorialAnalysis) {
      this.id = sensorialAnalysis.id;
      this.acceptingReasonId = sensorialAnalysis.accepting_reason_id;
      this.failed = !!sensorialAnalysis.failed;
      this.finalized = sensorialAnalysis.finalized;
      this.cuppingTableIdentifier = sensorialAnalysis.cupping_table_identifier;
    }
  }

  @computed get analysisFailed() {
    return this.id && this.failed;
  }

  @computed get status() {
    return this.id && this.failed ? 'false' : 'true';
  }

  @computed get text() {
    return this.id && this.failed
      ? this.acceptingReasonId
        ? I18n.translate('shared.status.passed')
        : I18n.translate('shared.status.failed')
      : I18n.translate('shared.status.passed');
  }

  @computed get icon() {
    return this.id && this.failed ? 'failed' : 'passed';
  }

  @computed get passed() {
    return !this.failed;
  }
}

export default SensorialAnalysis;
