// import { observable } from 'mobx';
import { deserialise } from 'kitsu-core';

import { ReasonGet } from 'types/model/reason';
import { MachinePhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';
import NormType from 'types/model/norm';
import SpecialtyGradingSpecificationType from 'types/model/specialtyGradingSpecification';

import MachinePhysicalAnalysis from '../models/MachinePhysicalAnalysis';
import SpecialtyGradingSpecification from '../models/SpecialtyGradingSpecification';
import Norm from '../models/Norm';
import Sample from 'productionOrders/models/Sample';
import { createSampleFactory } from 'utils/factories/createSampleFactory';
import SampleBuilder from 'utils/builders/SampleBuilder';
import ISample from 'millingOrders/types/ISample';
import { JsonApi } from 'types/utils/jsonApi';

class MachinePhysicalAnalysisStore {
  public sample: Sample;
  public norm: Norm;
  public specialtyGradingSpecifications: SpecialtyGradingSpecification[];
  public machinePhysicalAnalysis: MachinePhysicalAnalysis;
  public reasons: ReasonGet[];
  public cancelUrl: string;

  constructor(
    serializedSample: JsonApi<ISample>,
    norm: NormType,
    physicalAnalysis: MachinePhysicalAnalysisType,
    specialtyGradingSpecifications: SpecialtyGradingSpecificationType[],
    reasons: ReasonGet[],
    cancelUrl: string,
    percentageGreenInPasilla: number
  ) {
    const deserializedSample = deserialise(serializedSample).data as ISample;
    const sampleBuilder = new SampleBuilder(deserializedSample);

    this.sample = createSampleFactory(deserializedSample, sampleBuilder);
    this.norm = new Norm(deserialise(norm).data);
    this.reasons = reasons;
    this.cancelUrl = cancelUrl;

    this.specialtyGradingSpecifications = deserialise(
      specialtyGradingSpecifications
    ).data.map((grading) => new SpecialtyGradingSpecification(grading));

    const deserialisedPhysicalAnalysis = deserialise(physicalAnalysis).data;

    this.machinePhysicalAnalysis = new MachinePhysicalAnalysis(
      this.sample.id,
      deserialisedPhysicalAnalysis,
      percentageGreenInPasilla
    );
  }
}

export default MachinePhysicalAnalysisStore;
