import { action, observable, computed } from 'mobx';
import { deserialise } from 'kitsu-core';

import FractionType from 'types/model/fraction';
import MillingMachineType from 'types/model/millingMachine';
import { ReasonGet } from 'types/model/reason';

import Fraction from 'productionOrders/models/Fraction';
import RoastingResult from 'types/utils/roastingResult';

class FractionsStore {
  public estimatedWeight: number;
  public maxWeight: number;
  public fractions = observable.array<Fraction>();
  public roastingResults: RoastingResult[];
  public roastingValidations: { pppt: { min: number; max: number } };
  public humidityAnalysisAcceptingReasons: ReasonGet[];
  public machines: MillingMachineType[];
  public selectedSamples = observable.array<number>();

  constructor(
    estimatedWeight: number,
    maxWeight: string,
    fractions: FractionType[],
    roastingResults: RoastingResult[],
    roastingValidations: { pppt: { min: number; max: number } },
    humidityAnalysisAcceptingReasons: ReasonGet[],
    machines: MillingMachineType[]
  ) {
    this.maxWeight = maxWeight ? parseFloat(maxWeight) : 0;
    this.estimatedWeight = estimatedWeight;

    const deserializedFractions = deserialise(fractions).data.map(
      (fraction) => new Fraction(fraction, undefined, undefined, this)
    );

    this.machines = machines ? deserialise(machines).data : [];
    this.roastingResults = deserialise(roastingResults).data as RoastingResult[];
    this.humidityAnalysisAcceptingReasons = humidityAnalysisAcceptingReasons;
    this.fractions = observable.array(deserializedFractions);
    this.roastingValidations = roastingValidations;
  }

  @action remainingFractionWeight(currentFraction: Fraction) {
    const otherFractions = this.fractions.filter(
      (fraction) => fraction !== currentFraction
    );

    const otherFractionsTotalWeight = otherFractions.reduce(
      (total, fraction) => total + fraction.weight,
      0
    );

    return this.maxWeight - otherFractionsTotalWeight;
  }

  @computed get remainingExcelsoWeight(): string {
    return this.fractions
      .reduce((total, fraction) => {
        let weight = fraction.remainingWeight;

        const lastReprocess = fraction.lastReprocess;

        if (lastReprocess) {
          weight = lastReprocess.weight;
        }

        return total + weight;
      }, 0)
      .toFixed(2);
  }

  @computed get totalRejected(): string {
    return this.fractions
      .reduce((totalLoss, fraction) => {
        return totalLoss + fraction.totalRejected;
      }, 0)
      .toFixed(2);
  }

  @computed get totalLoss(): string {
    return this.fractions
      .reduce((totalLoss, fraction) => {
        return (totalLoss += fraction.totalLoss);
      }, 0)
      .toFixed(2);
  }

  @computed get selectedSamplesCount(): number {
    return this.selectedSamples.length;
  }

  @computed get roastingSamplesCount(): number {
    return this.fractions.filter((fraction) => fraction.sample.current_roasting?.id)
      .length;
  }

  @action public toggleSelectedLot = (sampleId: number) => {
    if (this.selectedSamples.includes(sampleId)) {
      this.selectedSamples.remove(sampleId);
    } else {
      this.selectedSamples.push(sampleId);
    }
  };
}

export default FractionsStore;
