import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';

import InventoryItem from './InventoryItem';
import { SelectableTransportable } from 'transports/stores/TransportStore';

export interface InventoryTableProps extends React.HTMLAttributes<HTMLTableElement> {
  inventoryItems: SelectableTransportable[];
}

function InventoryTable({ inventoryItems, ...props }: InventoryTableProps) {
  const { transportStore } = useStores();

  return (
    <table {...props} data-cy='transportables_table'>
      <thead>
        <tr>
          <th className='align-left'>ID</th>

          <th className='table-border align-left'>
            {I18n.translate('transports.show.producer_certification')}
          </th>

          <th className='selected-lots selected-lots__quality table-border'>
            {I18n.translate('transports.show.quality')}
          </th>

          <th className='selected-lots selected-lots__bags table-border'>
            {I18n.translate('attributes.bags_count')}
          </th>

          <th className='selected-lots selected-lots__weight table-border'>
            {I18n.translate('transports.show.weight')}
          </th>

          <th className='selected-lots selected-lots__whitespace table-border'>
            {I18n.translate('transports.show.additional_information')}
          </th>

          <th className='selected-lots selected-lots__checkbox'>
            {I18n.translate('transports.show.add_to_leg')}
          </th>
        </tr>
      </thead>

      <tbody>
        {inventoryItems.map((inventory) => {
          const disabled = false;

          const onInventoryCheck = () => {
            if (!inventory.selected) {
              transportStore.selectInventory(inventory);
            } else {
              transportStore.deselectInventory(inventory);
            }
          };

          return (
            <InventoryItem
              key={inventory.attributes.localIdentifier}
              inventory={inventory.attributes}
              checked={inventory.selected}
              disabled={disabled}
              onCheck={onInventoryCheck}
            />
          );
        })}
      </tbody>
    </table>
  );
}

export default observer(InventoryTable);
