import { useState } from 'react';

export enum PRODUCER_MODES {
  'KNOWN',
  'LEAD',
}

const useProducerForm = () => {
  const [producerMode, setProducerMode] = useState<PRODUCER_MODES>(PRODUCER_MODES.KNOWN);

  const toggleProducerMode = () =>
    setProducerMode((producerMode) =>
      producerMode == PRODUCER_MODES.KNOWN ? PRODUCER_MODES.LEAD : PRODUCER_MODES.KNOWN
    );

  return {
    toggleProducerMode,
    producerMode,
  };
};
export default useProducerForm;
