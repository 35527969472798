import { observer } from 'mobx-react';
import React from 'react';
import { IField } from 'components/shared/form/IField';
import Select from '../../components/elements/Select';
export interface SalesRepresentativeSelectProps {
  field: IField;
}

const SalesRepresentativeSelect: React.FC<SalesRepresentativeSelectProps> = ({
  field,
}) => {
  const namespace = 'opportunity';
  const name = `${namespace}[${field.name}]`;
  const id = `${namespace}_${field.name}`;
  const changeIndicated = field.changeIndicated;
  const sendForm = (event) => {
    event.target.form.submit();
  };
  return (
    <Select
      options={field.options || []}
      value={field.value}
      name={name}
      id={id}
      onChange={sendForm}
      className={changeIndicated ? 'text-red' : undefined}
    ></Select>
  );
};

export default observer(SalesRepresentativeSelect);
