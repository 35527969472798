import { observable, computed, action } from 'mobx';

import PackingDeclarationType from '../types/packingDeclaration';
import TimberBambooPackaging from '../types/timberBambooPackaging';
import TreatmentCertification from '../types/treatmentCertification';
import FinalizeableResource from './FinalizableResource';

import Routes from 'routes';

class PackingDeclaration extends FinalizeableResource<PackingDeclarationType> {
  @observable finalized: boolean;
  @observable unacceptable_packing_materials?: boolean;
  @observable timber_bamboo_packaging?: TimberBambooPackaging;
  @observable treatment_certification?: TreatmentCertification;

  @action protected updateAttributes({
    id,
    finalized,
    unacceptable_packing_materials,
    timber_bamboo_packaging,
    treatment_certification,
  }: PackingDeclarationType) {
    this.finalized = finalized;

    this.id = id;
    this.unacceptable_packing_materials = unacceptable_packing_materials;
    this.timber_bamboo_packaging = timber_bamboo_packaging;
    this.treatment_certification = treatment_certification;
  }

  @computed get isFinalizable() {
    return !['unacceptable_packing_materials', 'timber_bamboo_packaging'].find(
      (attribute) => [undefined, null, ''].includes(this[attribute])
    );
  }

  @computed get downloadUrl() {
    return this.id ? Routes.download_api_v1_packing_declaration_path(this.id) : undefined;
  }
}

export default PackingDeclaration;
