import styled from 'styled-components';
import React from 'react';
import COLORS from 'constants/colors';

export interface AppendComponentProps {
  percentage: string;
}

const AppendWrapper = styled.div`
  line-height: 1.2rem;
  text-align: center;
`;

const AppendPercentage = styled.div`
  color: ${COLORS.BLACK_70};
`;

const PercentageAppendComponent = ({ percentage }: AppendComponentProps) => (
  <AppendWrapper>
    <div>gr</div>
    <AppendPercentage>{percentage}</AppendPercentage>
  </AppendWrapper>
);

export default PercentageAppendComponent;
