import React from 'react';
import { observer } from 'mobx-react';
import COLORS from 'constants/colors';

import styled from 'styled-components';

import useStores from 'utils/useStores';
import type { Bundle } from '../stores/FutureStore';
import Icon from 'components/elements/Icon';

const ShipperSellerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & > * {
    flex-basis: 100%;
    padding: 0.35em;
  }
`;

export interface EditPriceFixing {
  bundle: Bundle;
  future: string;
}

const EditPriceFixing = ({ future, bundle }: EditPriceFixing) => {
  const { futuresStore } = useStores();
  const editFixed = true;

  const click = () => {
    futuresStore.toggleOverlay({
      future,
      bundle,
      editFixed,
    });
  };

  return (
    <ShipperSellerFlex>
      <div>
        {
          <Icon
            onClick={bundle.exported ? () => undefined : click}
            color={bundle.exported ? COLORS.GRAY : COLORS.BLUE}
            icon='pen'
          />
        }
      </div>
    </ShipperSellerFlex>
  );
};

export default observer(EditPriceFixing);
