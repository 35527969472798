import { action, observable, computed } from 'mobx';

import Routes from 'routes';
import { customFetch } from 'utils/fetch';
import RoastingResult from 'types/utils/roastingResult';
import type IRoasting from 'types/model/IRoasting';

// Attributes are snail_case to work with existing components
class Roasting {
  public id?: number;
  public sample_id: number;
  public results?: RoastingResult[] = [];
  @observable public roasted_weight = '';
  @observable public time_roasting = 0;
  @observable public initial_weight = 0;
  @observable public time_to_first_crack = 0;
  @observable public roasting_result_id = '';
  @observable public passed = false;

  constructor(sample_id: number, roasting?: IRoasting, initial_weight?: number) {
    this.sample_id = sample_id;
    this.initial_weight = initial_weight ? initial_weight : 110;

    if (roasting) {
      this.id = roasting.id;
      this.roasted_weight = roasting.roasted_weight;
      this.time_roasting = roasting.time_roasting;
      this.time_to_first_crack = roasting.time_to_first_crack;
      this.roasting_result_id = roasting.roasting_result_id;
      this.passed = roasting.passed;
      this.initial_weight = roasting.initial_weight ? roasting.initial_weight : 110;
    }
  }

  @action setId = (id: number) => {
    this.id = id;
  };

  @action setRoastedWeight = (weight: string) => {
    this.roasted_weight = weight;
  };

  @action setTimeRoasting = (timeRoasting: number) => {
    this.time_roasting = timeRoasting;
  };

  @action setTimeToFirstCrack = (timeToFirstCrack: number) => {
    this.time_to_first_crack = timeToFirstCrack;
  };

  @action setRoastingResultId = (roastingResultId: string) => {
    this.roasting_result_id = roastingResultId;
  };

  @action setPassed = (passed: boolean) => {
    this.passed = passed;
  };

  @action synchronize = async (values: {}) => {
    values['with_production_order_serializer'] = true;
    const route = this.id
      ? Routes.api_v1_sample_roasting_path(this.sample_id, this.id)
      : Routes.api_v1_sample_roastings_path(this.sample_id);

    const method = this.id ? 'PATCH' : 'POST';

    await customFetch(route, values, method);

    window.location.reload();
  };

  @computed get failed(): boolean {
    return !!this.id && !this.passed;
  }
}

export default Roasting;
