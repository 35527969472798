import { observable, computed, action } from 'mobx';
import Routes from 'routes';

import TransitGuideType from '../types/transitGuide';
import FinalizeableResource from './FinalizableResource';

class TransitGuide extends FinalizeableResource<TransitGuideType> {
  @observable finalized: boolean;
  @observable port_id?: number;
  @observable dock_id?: number;
  @observable transport_company_id?: number;
  @observable number?: string;
  @observable revision_number?: string;
  @observable vehicle_registration?: string;
  @observable trailer_registration?: string;
  @observable container?: string;
  @observable tag?: string;
  @observable customs_broker_id?: number;
  @observable driver_personal_id?: string;
  @observable driver_licence?: string;
  @observable driver_name?: string;
  @observable driver_surname?: string;

  @action protected updateAttributes({
    id,
    finalized,
    transport_company_id,
    port_id,
    dock_id,
    number,
    revision_number,
    vehicle_registration,
    trailer_registration,
    container,
    tag,
    customs_broker_id,
    driver_personal_id,
    driver_licence,
    driver_name,
    driver_surname,
  }: TransitGuideType) {
    this.finalized = finalized;

    this.id = id;
    this.port_id = port_id;
    this.dock_id = dock_id;
    this.transport_company_id = transport_company_id;
    this.number = number;
    this.revision_number = revision_number;
    this.vehicle_registration = vehicle_registration;
    this.trailer_registration = trailer_registration;
    this.container = container;
    this.tag = tag;
    this.customs_broker_id = customs_broker_id;
    this.driver_personal_id = driver_personal_id;
    this.driver_licence = driver_licence;
    this.driver_name = driver_name;
    this.driver_surname = driver_surname;
  }

  @computed get isFinalizable() {
    return ![
      'port_id',
      'transport_company_id',
      'number',
      'revision_number',
      'vehicle_registration',
      'trailer_registration',
      'container',
      'tag',
      'customs_broker_id',
      'driver_personal_id',
      'driver_licence',
      'driver_name',
      'driver_surname',
    ].find((attribute) => [undefined, null, ''].includes(this[attribute]));
  }

  @computed get downloadUrl() {
    return this.id ? Routes.download_api_v1_transit_guide_path(this.id) : undefined;
  }
}

export default TransitGuide;
