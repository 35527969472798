import React, { useMemo } from 'react';
import { ArrayField, useFormContext } from 'react-hook-form';
import { addTestingIdentifier } from 'utils/testing';
import placeholder from 'utils/placeholder';
import { truncateText } from 'utils/string';
import WSRSampleWeightInput from '../warehouse_sample_request/WSRSampleWeightInput';
import WSRResourcesObservationField from '../warehouse_sample_request/WSRResourcesObservationField';
import Checkbox from 'shared/Checkbox';

export interface SpotSampleSelectedRowProps {
  field: Partial<ArrayField<Record<string, any>>>;
  fieldArrayName: string;
  index: number;
  resourceIdName: string;
  onUncheck: (index: number) => void;
}

function SpotSampleSelectedRow({
  field,
  fieldArrayName,
  index,
  onUncheck,
  resourceIdName,
}: SpotSampleSelectedRowProps): JSX.Element {
  const { register } = useFormContext();
  const namePrefix = `${fieldArrayName}.${index}`;

  const customerName = useMemo(
    () => field.contract?.customer?.name || field.customer?.name,
    [field.contract, field.customer]
  );

  return (
    <tr {...addTestingIdentifier(`selected_wsr_row`)}>
      <td className='table-border'>
        {field.main_identifier}

        <input
          type='hidden'
          value={field.id}
          name={`${namePrefix}.${resourceIdName}`}
          ref={register()}
        />
      </td>

      <td className='table-border'>
        {placeholder(truncateText(customerName || '', 25))}
      </td>

      <td className='table-border'>
        <WSRSampleWeightInput
          name={`${namePrefix}.customer_sample_weight`}
          description={I18n.translate('activerecord.models.customer')}
          readOnly={!customerName}
          dataCy={'customer_sample_weight'}
          required
        />
      </td>

      <td className='table-border'>
        <WSRResourcesObservationField namePrefix={namePrefix} />
      </td>

      <td className='align-center'>
        <Checkbox
          checked={true}
          onClick={() => onUncheck(index)}
          dataCy='selected_wsr_row_checkbox'
        />
      </td>
    </tr>
  );
}

export default SpotSampleSelectedRow;
