import styled from 'styled-components';

interface GridProps {
  grid?: number;
}

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ grid = 12 }) =>
    `repeat(auto-fill, minmax(${grid}em, 1fr))`};
  grid-gap: 0 1.625em;
  flex-grow: 1;
  width: 100%;
`;

export default Grid;
