import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import SensorialAnalysisType from '../models/SensorialAnalysis';

import BodyInput from './BodyInput';
import ConclusionInput from './ConclusionInput';
import DefectInput from './DefectInput';
import FlavourInput from './FlavourInput';
import ObservationInput from './ObservationInput';
import OverallInput from './OverallInput';
import RoastInput from './RoastInput';
import ScoreInput from './ScoreInput';
import AlternativeInput from './AlternativeInput';

interface FullscreenSensorialAnalysisProps {
  count: number;
  lotsCount: number;
  sensorialAnalysis: SensorialAnalysisType;
  cuppingSessionEnabled: boolean;
}

function FullscreenSensorialAnalysis({
  lotsCount,
  count,
  sensorialAnalysis: {
    defects,
    descriptorBodies,
    descriptorFlavours,
    descriptorOveralls,
    descriptorRoasts,
    sample,
    alternatives,
    conclusion,
    completed,
    defectsSelected,
    score,
    badScore,
    setScore,
    setRejectGrade,
    observation,
    setObservation,
    quality,
    setQuality,
  },
  cuppingSessionEnabled,
}: FullscreenSensorialAnalysisProps): JSX.Element {
  return (
    <>
      <div
        className={classNames('sensorial-analyses__head', {
          'sensorial-analyses__head--lightBackground': count % 2 === 0,
        })}
      >
        <span className='sensorial-analyses__head-number'>
          <span className='sensorial-analyses__head-number sensorial-analyses__head-number--big'>
            {count}
          </span>
          /{lotsCount}
        </span>

        <span className='sensorial-analyses__head-id identifier'>
          {sample.parent_frontend_identifier && (
            <>
              {sample.parent_frontend_identifier}
              <br />
              Sample:&nbsp;
            </>
          )}

          {sample.local_identifier}
        </span>
      </div>

      <div className='sensorial-analyses__cell sensorial-analyses__cell--score'>
        <ScoreInput
          sampleId={sample.id}
          completed={completed}
          value={score}
          onChange={setScore}
          onFetch={setRejectGrade}
          quality={quality}
          setQuality={setQuality}
        />
      </div>

      <div className='sensorial-analyses__cell sensorial-analyses__cell--no-padding'>
        <FlavourInput
          descriptorFlavours={descriptorFlavours}
          sampleId={sample.id}
          twoColumns={true}
          completed={completed}
        />
      </div>

      <div className='sensorial-analyses__cell sensorial-analyses__cell--no-padding'>
        <OverallInput
          sampleId={sample.id}
          descriptorOveralls={descriptorOveralls}
          completed={completed}
        />
      </div>

      <div className='sensorial-analyses__cell sensorial-analyses__cell--no-padding'>
        <BodyInput
          sampleId={sample.id}
          twoColumns={true}
          descriptorBodies={descriptorBodies}
          completed={completed}
        />
      </div>

      <div className='sensorial-analyses__cell sensorial-analyses__cell--no-padding'>
        <AlternativeInput
          sampleId={sample.id}
          twoColumns={true}
          alternatives={alternatives}
          completed={completed}
        />
      </div>

      <div className='sensorial-analyses__cell sensorial-analyses__cell--no-padding'>
        <DefectInput
          sampleId={sample.id}
          twoColumns={true}
          defects={defects}
          completed={completed}
        />
      </div>

      <div className='sensorial-analyses__cell sensorial-analyses__cell--no-padding'>
        <RoastInput
          sampleId={sample.id}
          descriptorRoasts={descriptorRoasts}
          completed={completed}
        />
      </div>

      <div className='sensorial-analyses__cell'>
        <ObservationInput
          sampleId={sample.id}
          value={observation}
          onChange={(e) => setObservation(e.target.value)}
          completed={completed}
        />
      </div>

      <div className='sensorial-analyses__cell sensorial-analyses__cell--conclusion'>
        <ConclusionInput
          sampleId={sample.id}
          conclusion={conclusion}
          completed={completed}
          withReason={defectsSelected || badScore}
          label={I18n.translate('sensorial_analysis.reason_for_proceeding')}
          cuppingSessionEnabled={cuppingSessionEnabled}
        />
      </div>
    </>
  );
}

export default observer(FullscreenSensorialAnalysis);
