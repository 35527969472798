import React from 'react';
import { observer } from 'mobx-react';

import type StorageModel from '../model/Storage';

import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';

export interface StorageProps {
  storage: StorageModel;
}

function Storage({ storage }: StorageProps) {
  return (
    <Grid grid={8}>
      <InputGroupComponent
        label={I18n.translate('unload_lot.area')}
        value={storage.area}
        data-cy='unloaded_area'
        onChange={(e) => storage.setArea(e.target.value)}
      />

      <InputGroupComponent
        label={I18n.translate('unload_lot.bin')}
        value={storage.bin}
        data-cy='unloaded_bin'
        onChange={(e) => storage.setBin(e.target.value)}
      />

      <InputGroupComponent
        label={I18n.translate('unload_lot.level')}
        value={storage.level}
        data-cy='unloaded_level'
        onChange={(e) => storage.setLevel(e.target.value)}
      />

      <InputGroupComponent
        label={I18n.translate('unload_lot.position')}
        value={storage.position}
        data-cy='unloaded_position'
        onChange={(e) => storage.setPosition(e.target.value)}
        type='text'
        maxLength={6}
      />
    </Grid>
  );
}

export default observer(Storage);
