import React from 'react';
import BaseFields from 'samples/create/components/BaseFields';
import ProducerFields from 'samples/create/components/ProducerFields';

function CreateExternalSample() {
  return (
    <>
      <ProducerFields />
      <BaseFields showCoffeeTypeSelection={true} />
    </>
  );
}
export default CreateExternalSample;
