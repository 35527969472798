import React from 'react';
import Routes from 'routes';

import { customFetch } from 'utils/fetch';
import useStores from 'utils/useStores';

import AdjustGradeOverlay from './AdjustGradeOverlay';

export interface AdjustGradeProps {
  sampleId: number;
  currentGrade: number;
  identifier: string;
}

function AdjustGrade({
  sampleId,
  currentGrade,
  identifier,
}: AdjustGradeProps): JSX.Element {
  const {
    analyseAndAdjustStore: { grades },
  } = useStores();

  const [showOverlay, setShowOverlay] = React.useState(false);

  const handleOnPrimaryClick = (event: React.MouseEvent, values: {}): void => {
    customFetch(Routes.regrade_api_v1_sample_path(sampleId), values).then(() => {
      setShowOverlay(false);
      window.location.reload();
    });
  };

  const handleOnSecondaryClick = (_event: React.MouseEvent): void => {
    setShowOverlay(false);
  };

  const handleOnButtonClick = (_event: React.MouseEvent): void => {
    setShowOverlay(true);
  };

  return (
    <>
      {showOverlay && (
        <AdjustGradeOverlay
          title={`${identifier} - ${I18n.translate('buttons.adjust_grade')}`}
          grades={grades}
          currentGrade={currentGrade}
          onPrimaryClick={handleOnPrimaryClick}
          onSecondaryClick={handleOnSecondaryClick}
        />
      )}

      <button className='button button--small' onClick={handleOnButtonClick}>
        {I18n.translate('buttons.adjust_grade')}
      </button>
    </>
  );
}

export default AdjustGrade;
