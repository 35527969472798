import classnames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Checkbox from 'shared/Checkbox';
import RadioGroup from '../models/RadioGroup';

interface RoastInputProps {
  sampleId: number;
  fourColumns?: boolean;
  useInOverlay?: boolean;
  completed?: boolean;
  descriptorRoasts: RadioGroup<any>;
}

const RoastInput = ({
  sampleId,
  fourColumns,
  useInOverlay,
  completed = false,
  descriptorRoasts,
}: RoastInputProps) => {
  return (
    <div
      className={classnames(
        'check-group-list',
        'check-group-list__dense',
        fourColumns && 'check-group-list__dense--four-columns',
        useInOverlay && 'check-group-list--overlay'
      )}
    >
      {descriptorRoasts.radios.map(({ id, name }) => (
        <Checkbox
          key={id}
          label={name}
          icon={'tick'}
          id={`sample_${sampleId}_descriptor_roast_id_${id}`}
          name={`samples[${sampleId}][descriptor_roast_id]`}
          value={`${id}`}
          square={true}
          asRadioButton={true}
          onChange={() => descriptorRoasts.selectRadio(id)}
          checked={descriptorRoasts.selectedRadioId === id}
          readOnly={completed}
          // required={true}
        />
      ))}
    </div>
  );
};

export default observer(RoastInput);
