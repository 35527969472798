import { observable, action, computed } from 'mobx';

class Storage {
  @observable public area: string;
  @observable public bin: string;
  @observable public level: string;
  @observable public position: string;

  public constructor(area = '', bin = '', level = '', position = '') {
    this.area = area;
    this.bin = bin;
    this.level = level;
    this.position = position;
  }

  @action public setArea = (value: string): void => {
    this.area = value;
  };

  @action public setBin = (value: string): void => {
    this.bin = value;
  };

  @action public setLevel = (value: string): void => {
    this.level = value;
  };

  @action public setPosition = (value: string): void => {
    this.position = value;
  };

  @computed public get isValid(): boolean {
    return !!(this.area && this.bin && this.level && this.position);
  }
}

export default Storage;
