import React from 'react';
import useStores from 'utils/useStores';
import DecafOrderRow from './DecafOrderRow';

function DecafOrdersTable() {
  const { qualityAssuranceStore } = useStores();

  return (
    <table>
      <thead>
        <tr>
          <th className='table-border'></th>

          <th className='table-border align-center'>
            {I18n.translate('milling_orders.qa.humidity')}
          </th>

          <th className='table-border align-center'>
            {I18n.translate('milling_orders.qa.physical')}
          </th>

          <th className='table-border align-center'>
            {I18n.translate('milling_orders.qa.roasting')}
          </th>

          <th className='table-border align-center'>
            {I18n.translate('milling_orders.qa.sensorial')}
          </th>

          <th className='align-center table-border'>
            {I18n.translate('milling_orders.qa.weight')}
          </th>

          <th></th>
        </tr>
      </thead>

      <tbody>
        {qualityAssuranceStore.decafOrders.map((decafOrder) => (
          <DecafOrderRow key={decafOrder.id} decafOrder={decafOrder} />
        ))}
      </tbody>
    </table>
  );
}

export default DecafOrdersTable;
