import DeliveryOrderCost from '../models/DeliveryOrderCost';
import type DeliveryOrderCostType from '../types/DeliveryOrderCost';
import { action, computed, observable } from 'mobx';
import DeliveryOrder from '../models/DeliveryOrder';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';
import DestinationWarehouseCost from '../types/DestinationWarehouseCost';
import { deserialise } from 'kitsu-core';

class DeliveryOrderCostStore {
  @observable deliveryOrderCost: DeliveryOrderCost;
  @observable selectedCostFields: any[];

  @action setDeliveryOrder = async (deliveryOrder: DeliveryOrder): Promise<void> => {
    try {
      const data = await customFetch(
        Routes.delivery_order_costs_api_v1_fulfillment_delivery_order_path(deliveryOrder),
        undefined,
        'GET'
      );

      const deliveryOrderCost: DeliveryOrderCostType = deserialise(data).data;

      this.deliveryOrderCost = this.createDeliveryOrderCost(
        deliveryOrder,
        deliveryOrderCost,
        deliveryOrderCost.destination_warehouse_cost
      );
    } catch (e) {
      // TODO: Handle error
    }
  };

  @action setPallets = (value: number): void => {
    this.deliveryOrderCost.setPalletQuantity(value);
  };

  @computed get pallets(): number {
    return this.deliveryOrderCost.palletQuantity;
  }

  private createDeliveryOrderCost = (
    deliveryOrder: DeliveryOrder,
    deliveryOrderCost: DeliveryOrderCostType,
    destinationWarehouseCost: DestinationWarehouseCost
  ): DeliveryOrderCost => {
    return new DeliveryOrderCost(
      deliveryOrder,
      deliveryOrderCost,
      destinationWarehouseCost
    );
  };
}

export default DeliveryOrderCostStore;
