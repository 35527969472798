import React from 'react';

interface TextWithStateProps {
  text: string;
  state?: 'ok' | 'error';
  dataCy?: string;
}

const TextWithState: React.FC<TextWithStateProps> = ({ state, text, dataCy }) => {
  const textClassName = ((state) => {
    switch (state) {
      case 'ok':
        return 'status--true';
      case 'error':
        return 'status--false';
      default:
        return '';
    }
  })(state);

  return (
    <div className={textClassName} data-cy={dataCy}>
      {text}
    </div>
  );
};
export default TextWithState;
