export function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  } else {
    const truncated = text;
    return `${truncated.slice(0, maxLength)} ...`;
  }
}

export function trimWhitespaces(value: string): string {
  return value.replace(/\s/g, '');
}
