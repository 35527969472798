import React from 'react';
import { observer } from 'mobx-react';

import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';
import DefectWrapper from './DefectWrapper';
import PercentageAppendComponent from './PercentageAppendComponent';

import ExcelsoPhysicalAnalysis from '../models/ExcelsoPhysicalAnalysis';
import PeabiesPhysicalAnalysis from '../models/PeabiesPhysicalAnalysis';

export interface FirstGroupComponentProps {
  physicalAnalysis: ExcelsoPhysicalAnalysis | PeabiesPhysicalAnalysis;
}

const FirstGroupComponent = ({ physicalAnalysis }: FirstGroupComponentProps) => (
  <>
    <div className='fieldset'>
      <Grid grid={16}>
        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.full_black')}
            value={physicalAnalysis.full_black.grains}
            onChange={(e) =>
              physicalAnalysis.full_black.setGrains(parseInt(e.target.value))
            }
            append='#'
            selectTextOnFocus={true}
            name='full_black_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.full_black.weight}
            onChange={(e) =>
              physicalAnalysis.full_black.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.full_black.weight
                )}
              />
            }
            name='full_black_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.partial_black')}
            value={physicalAnalysis.partial_black.grains}
            onChange={(e) =>
              physicalAnalysis.partial_black.setGrains(parseInt(e.target.value))
            }
            append='#'
            selectTextOnFocus={true}
            name='partial_black_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.partial_black.weight}
            onChange={(e) =>
              physicalAnalysis.partial_black.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.partial_black.weight
                )}
              />
            }
            name='partial_black_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.full_sour')}
            value={physicalAnalysis.full_sour.grains}
            onChange={(e) =>
              physicalAnalysis.full_sour.setGrains(parseInt(e.target.value))
            }
            append='#'
            selectTextOnFocus={true}
            name='full_sour_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.full_sour.weight}
            onChange={(e) =>
              physicalAnalysis.full_sour.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.full_sour.weight
                )}
              />
            }
            name='full_sour_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.partial_sour')}
            value={physicalAnalysis.partial_sour.grains}
            onChange={(e) =>
              physicalAnalysis.partial_sour.setGrains(parseInt(e.target.value))
            }
            append='#'
            selectTextOnFocus={true}
            name='partial_sour_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.partial_sour.weight}
            onChange={(e) =>
              physicalAnalysis.partial_sour.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.partial_sour.weight
                )}
              />
            }
            name='partial_sour_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.dried_cherry')}
            value={physicalAnalysis.dried_cherry.grains}
            onChange={(e) =>
              physicalAnalysis.dried_cherry.setGrains(parseInt(e.target.value))
            }
            append='#'
            selectTextOnFocus={true}
            name='dried_cherry_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.dried_cherry.weight}
            onChange={(e) =>
              physicalAnalysis.dried_cherry.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.dried_cherry.weight
                )}
              />
            }
            name='dried_cherry_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.fungus_sour')}
            value={physicalAnalysis.fungus_sour.grains}
            onChange={(e) =>
              physicalAnalysis.fungus_sour.setGrains(parseInt(e.target.value))
            }
            append='#'
            selectTextOnFocus={true}
            name='fungus_sour_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.fungus_sour.weight}
            onChange={(e) =>
              physicalAnalysis.fungus_sour.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.fungus_sour.weight
                )}
              />
            }
            name='fungus_sour_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.foreign_matter')}
            value={physicalAnalysis.foreign_matter.grains}
            onChange={(e) =>
              physicalAnalysis.foreign_matter.setGrains(parseInt(e.target.value))
            }
            append='#'
            selectTextOnFocus={true}
            name='foreign_matter_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.foreign_matter.weight}
            onChange={(e) =>
              physicalAnalysis.foreign_matter.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.foreign_matter.weight
                )}
              />
            }
            name='foreign_matter_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.severe_insect_damage')}
            value={physicalAnalysis.severe_insect_damage.grains}
            onChange={(e) =>
              physicalAnalysis.severe_insect_damage.setGrains(parseInt(e.target.value))
            }
            append='#'
            selectTextOnFocus={true}
            name='severe_insect_damage_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.severe_insect_damage.weight}
            onChange={(e) =>
              physicalAnalysis.severe_insect_damage.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.severe_insect_damage.weight
                )}
              />
            }
            name='severe_insect_damage_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>
      </Grid>

      <Grid grid={9}>
        <InputGroupComponent
          label={I18n.translate('qa_physical_analysis.group_1')}
          value={physicalAnalysis.group1FullGrains}
          readOnly={true}
          disabled={true}
          append='#'
          name='group_1_grains'
          type='number'
        />

        <InputGroupComponent
          label={I18n.translate('qa_physical_analysis.group_1_weight')}
          value={physicalAnalysis.group1FullWeight}
          readOnly={true}
          disabled={true}
          append={
            <PercentageAppendComponent
              percentage={physicalAnalysis.getWeightPercentageString(
                physicalAnalysis.group1FullWeight
              )}
            />
          }
          name='group_1_weight'
          type='number'
        />

        <InputGroupComponent
          label={I18n.translate('qa_physical_analysis.full_defects_group_1')}
          value={physicalAnalysis.group1FullDefects.toFixed(2)}
          disabled={true}
          name='full_defects_group_1'
          type='number'
        />
      </Grid>
    </div>
  </>
);

export default observer(FirstGroupComponent);
