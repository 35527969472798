import React, { useState, useEffect } from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { ContractSelectGroup, ContractTextInput } from '../ContractFields';
import { toMonthInputFormat, fromMonthInputFormat } from 'contracts/contractUtils';
import { changeDatePicker, isSafari } from 'shared/Browser';

export const Shipment = ({
  destinationWarehouseIdField,
  destinationWarehouseId,
  setDestinationWarehouseId,
  destinationWarehouseOptions,
  destinationWarehouseHidden,
  warehouseIdField,
  warehouseId,
  setWarehouseId,
  warehouseOptions,
  warehouseHidden,
  shipperPortIdField,
  shipperPortId,
  setShipperPortId,
  destinationPortIdField,
  destinationPortId,
  setDestinationPortId,
  shipmentDateField,
  shipmentDate,
  setShipmentDate,
  shipperPortOptions,
  deliveryDateHidden,
  deliveryDateField,
  deliveryDate,
  setDeliveryDate,
  cancelContractChecked,
  closeContractChecked,
}) => {
  const dateValidation = (dateField) => {
    return dateField && !dateField.readonly && toMonthInputFormat(new Date());
  };

  const [minDeliveryValue, setMinDeliveryValue] = useState(
    (!closeContractChecked &&
      !cancelContractChecked &&
      dateValidation(deliveryDateField)) ||
      undefined
  );
  useEffect(() => {
    setMinDeliveryValue(
      (!closeContractChecked &&
        !cancelContractChecked &&
        dateValidation(deliveryDateField)) ||
        undefined
    );
  }, [closeContractChecked, cancelContractChecked]);

  const changeMinDeliveryValue = (rawDate) => {
    const copyDate = new Date(rawDate);
    copyDate.setMonth(rawDate.getMonth() + 1);
    setMinDeliveryValue(isSafari() ? copyDate : toMonthInputFormat(copyDate));
  };

  const parseDate = (rawDate) => {
    return isSafari() ? rawDate : toMonthInputFormat(rawDate);
  };
  const onChangeDate = (monthDate, setDate) => {
    const parsedDate = isSafari() ? monthDate : fromMonthInputFormat(monthDate);
    setDate(parsedDate);
    return parsedDate;
  };

  return (
    <FieldSetWithTitle title={I18n.translate('contracts.form.shipment')}>
      <Grid>
        {!destinationWarehouseHidden && (
          <ContractSelectGroup
            field={destinationWarehouseIdField}
            value={destinationWarehouseId}
            onChange={setDestinationWarehouseId}
            overrideOptions={destinationWarehouseOptions}
          />
        )}
        {!warehouseHidden && (
          <ContractSelectGroup
            field={warehouseIdField}
            value={warehouseId}
            onChange={setWarehouseId}
            overrideOptions={warehouseOptions}
          />
        )}

        <ContractSelectGroup
          overrideOptions={shipperPortOptions}
          field={shipperPortIdField}
          value={shipperPortId}
          onChange={setShipperPortId}
        />
        <ContractSelectGroup
          field={destinationPortIdField}
          value={destinationPortId}
          onChange={setDestinationPortId}
        />

        <ContractTextInput
          field={shipmentDateField}
          type='month'
          min={
            (!closeContractChecked &&
              !cancelContractChecked &&
              dateValidation(shipmentDateField)) ||
            undefined
          }
          value={parseDate(shipmentDate)}
          onChangeArg={(e) => changeDatePicker(e)}
          onChange={(monthDate) => {
            const parsedDate = onChangeDate(monthDate, setShipmentDate);
            if (!deliveryDateHidden && !cancelContractChecked && !closeContractChecked)
              changeMinDeliveryValue(parsedDate);
          }}
        />
        {!deliveryDateHidden && (
          <ContractTextInput
            field={deliveryDateField}
            min={minDeliveryValue}
            type='month'
            value={parseDate(deliveryDate)}
            onChange={(monthDate) => onChangeDate(monthDate, setDeliveryDate)}
            onChangeArg={(e) => changeDatePicker(e)}
          />
        )}
      </Grid>
    </FieldSetWithTitle>
  );
};
