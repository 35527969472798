import React from 'react';
import STATUS from 'types/utils/status';

import type PhysicalAnalysisModel from 'productionOrders/models/PhysicalAnalysis';
import StatusText from 'shared/StatusText';
import { addTestingIdentifier } from 'utils/testing';

export interface PhysicalAnalysisProps {
  physicalAnalysis: PhysicalAnalysisModel;
  newUrl: string;
  editUrl: string;
}

function PhysicalAnalysis({ physicalAnalysis, newUrl, editUrl }: PhysicalAnalysisProps) {
  const { id, state, exceedsNorm, fullDefectsString } = physicalAnalysis;

  const status =
    state !== 'failed' ? (!exceedsNorm ? STATUS.OK : STATUS.FAILED) : STATUS.FAILED;

  return (
    <>
      {id ? (
        <a href={editUrl}>
          <StatusText status={status} reverseOrder={true}>
            {fullDefectsString}
          </StatusText>
        </a>
      ) : (
        <a
          href={newUrl}
          className='button button--ghost button--small'
          {...addTestingIdentifier('physical_analysis_button')}
        >
          {I18n.translate('buttons.analyse')}
        </a>
      )}
    </>
  );
}

export default PhysicalAnalysis;
