import React from 'react';
import ErrorsComponent from 'shared/Errors/components/Errors';
import { addTestingIdentifier } from 'utils/testing';
import { isSafari } from 'shared/Browser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Input, { InputProps } from '../elements/Input';
import BaseGroupComponent, { BaseGroupProps } from './BaseGroup';

export interface InputGroupProps extends BaseGroupProps, InputProps {
  errors?: string[];
}

const InputGroupComponent = React.forwardRef<HTMLInputElement, InputGroupProps>(
  (
    {
      label,
      append,
      prepend,
      className,
      info,
      infoname,
      condensed,
      inline,
      noMargin,
      changeIndicated,
      errors,
      required,
      ...rest
    },
    ref
  ) => {
    const inputComponent = () => {
      if (rest.type === 'month' && isSafari()) {
        const parsedMinDate =
          typeof rest.min === 'string' ? new Date(`${rest.min}-02`) : rest.min;

        return (
          <DatePicker
            showMonthYearPicker
            dateFormat='yyyy-MM'
            minDate={parsedMinDate}
            selected={rest.value}
            onChange={(date) => {
              rest.onChange && rest.onChange(date);
            }}
            {...rest}
          />
        );
      }

      return (
        <Input
          ref={ref}
          condensed={condensed}
          changeIndicated={changeIndicated}
          required={required}
          {...addTestingIdentifier(rest.dataCy || rest.name)}
          {...rest}
        />
      );
    };

    return (
      <BaseGroupComponent
        label={label}
        append={append}
        prepend={prepend}
        className={className}
        info={info}
        infoname={infoname}
        condensed={condensed}
        inline={inline}
        noMargin={noMargin}
        required={required}
        changeIndicated={changeIndicated}
        errors={errors && <ErrorsComponent errors={errors} />}
      >
        {inputComponent()}
      </BaseGroupComponent>
    );
  }
);

InputGroupComponent.displayName = 'InputGroupComponent';

export default InputGroupComponent;
