import React from 'react';
import { useFormContext } from 'react-hook-form';
import { addTestingIdentifier } from 'utils/testing';

interface WSRSampleWeightInputProps {
  name: string;
  description: string;
  dataCy?: string;
  readOnly?: boolean;
  required?: boolean;
}

const WSRSampleWeightInput = ({
  name,
  description,
  dataCy,
  readOnly = false,
  required = false,
}: WSRSampleWeightInputProps) => {
  const { register } = useFormContext();

  return (
    <div className='input-group__row input-group__row--append input-group__row--prepend'>
      <div className='input-group__prepend'>{description}</div>

      <input
        type='number'
        name={`${name}.value`}
        readOnly={readOnly}
        ref={register({ required, min: 1 })}
        {...addTestingIdentifier(dataCy)}
      />

      <div className='input-group__append'>gr</div>

      <input
        type='hidden'
        value='unit_g'
        readOnly={readOnly}
        name={`${name}.unit`}
        ref={register()}
      />
    </div>
  );
};

export default WSRSampleWeightInput;
