import PaginatedTable from 'components/shared/PaginatedTable';
import { observer } from 'mobx-react';
import React from 'react';
import generateRandomId from 'utils/generateRandomId';
import useStores from 'utils/useStores';
import ProductionOrder from 'millings/productionOrders/AsyncProductionOrder/types/ProductionOrder';
import FilterBar from './FilterBar';
import TableRow from './TableRow';
import ErrorsComponent from 'shared/Errors/components/Errors';
import styled from 'styled-components';

const Errors = styled(ErrorsComponent)`
  margin-bottom: 1em;
`;

function ProductionOrdersComponent() {
  const { asyncProductionOrdersStore } = useStores();

  const { paginatedTableStore } = asyncProductionOrdersStore;

  const renderTableHeader = () => {
    return (
      <tr>
        <th className='align-left'>{I18n.translate('samples.consolidate.type')}</th>

        <th className='table-border align-left'>
          {I18n.translate('samples.consolidate.source')}
        </th>

        <th className='selected-lots selected-lots__quality table-border'>
          {I18n.translate('attributes.quality')}
        </th>

        <th className='selected-lots selected-lots__weight table-border'>
          {I18n.translate('attributes.weight')}
        </th>

        <th className='selected-lots selected-lots__weight table-border'>
          {I18n.translate('samples.consolidate.source_weight')}
        </th>

        <th className='selected-lots selected-lots__certification table-border'>
          {I18n.translate('samples.consolidate.certification')}
        </th>

        <th className='selected-lots table-border'>
          {I18n.translate('samples.consolidate.process')}
        </th>

        <th className='selected-lots'>
          {I18n.translate('samples.consolidate.add_to_mix')}
        </th>
      </tr>
    );
  };

  const renderTableData = (dataRow: ProductionOrder) => {
    return (
      <TableRow
        key={generateRandomId('production_order_data')}
        productionOrder={dataRow}
      />
    );
  };

  const renderTopBar = () => {
    return <FilterBar />;
  };
  return (
    <>
      <Errors errors={asyncProductionOrdersStore.errors} />
      <PaginatedTable<ProductionOrder>
        renderTableData={renderTableData}
        renderTableHeader={renderTableHeader}
        renderTopBar={renderTopBar}
        paginatedTableStore={paginatedTableStore}
        hasSelectedData={false}
      />
    </>
  );
}

export default observer(ProductionOrdersComponent);
