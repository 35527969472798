import SensorialAnalysisType from 'customerSamples/types/SensorialAnalysisType';

class SensorialAnalysis {
  public id?: number;
  public finalScore?: number;
  public grade?: string;

  constructor(sensorialAnalysis?: SensorialAnalysisType) {
    if (sensorialAnalysis) {
      this.id = sensorialAnalysis.id;
      this.finalScore = sensorialAnalysis.final_score;
      this.grade = sensorialAnalysis.grade;
    }
  }
}

export default SensorialAnalysis;
