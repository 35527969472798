import React, { useState } from 'react';
import Routes from 'routes';

import { NewTestCard } from 'shared/LotInfoBox';
import { TestResult } from 'shared/TestGrid';
import { customFetch } from 'utils/fetch';
import MarkForCupping from 'shared/MarkForCupping';
import { ITest } from 'types/utils/IResult';
import ISample from 'samples/types/ISample';
import ISensorialAnalysisResult from 'samples/types/ISensorialAnalysisResult';
import useStores from 'utils/useStores';

export interface SensorialAnalysesProps {
  sample: ISample;
  sensorialAnalyses: ISensorialAnalysisResult[];
}

function SensorialAnalyses({ sensorialAnalyses, sample }: SensorialAnalysesProps) {
  const [consideredForCupping, setConsideredForCupping] = useState(
    sample.considered_for_cupping
  );

  const { analyseAndAdjustStore } = useStores();

  const onMarkForCuppingClickHandler = () => {
    if (consideredForCupping) {
      window.location.href = Routes.new_sensorial_analysis_path();
    } else {
      customFetch(Routes.mark_for_cupping_api_v1_sample_path(sample.id)).then(() => {
        setConsideredForCupping(true);
      });
    }
  };

  const needRoasting = sample.roastings.length === 0;

  return (
    <div>
      {sensorialAnalyses.map((sensorialAnalysis) => {
        const test = {
          created_at: sensorialAnalysis.created_at,
          created_by: sensorialAnalysis.created_by,
          values: Object.values<ITest>(sensorialAnalysis.values),
        };

        return <TestResult key={sensorialAnalysis.id} test={test} />;
      })}

      <NewTestCard>
        {!needRoasting && (
          <MarkForCupping
            onClick={onMarkForCuppingClickHandler}
            consideredForCupping={consideredForCupping}
            cuppingSessionEnabled={analyseAndAdjustStore.cuppingSessionEnabled}
          />
        )}
      </NewTestCard>
    </div>
  );
}

export default SensorialAnalyses;
