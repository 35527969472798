import React from 'react';
import classNames from 'classnames';
import Routes from 'routes';

export interface MarkForCuppingProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  consideredForCupping?: boolean;
  disabled?: boolean;
  cuppingSessionEnabled?: boolean;
}

function MarkForCupping({
  onClick,
  consideredForCupping = false,
  disabled = false,
  cuppingSessionEnabled = false,
}: MarkForCuppingProps) {
  if (cuppingSessionEnabled) {
    return (
      <a
        className={classNames('button', 'button--small', 'button--green')}
        href={Routes.new_cupping_table_path()}
      >
        {I18n.translate('samples.analyse_and_adjust.to_cupping')}
      </a>
    );
  }
  return (
    <button
      className={classNames('button', 'button--small', {
        'button--green': !consideredForCupping,
        'button--ghost': consideredForCupping,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {(consideredForCupping &&
        I18n.translate('samples.analyse_and_adjust.start_a_cupping')) ||
        I18n.translate('samples.analyse_and_adjust.mark_for_cupping')}
    </button>
  );
}

export default MarkForCupping;
