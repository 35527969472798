import React from 'react';
import { observer } from 'mobx-react';
import Opportunity from 'customerSamples/models/Opportunity';
import SampleRow from 'customerSamples/components/SampleRow';
import IcoRow from './IcoRow';
import PssInternalContract from 'customerSamples/models/PssInternalContract';
import SpotOpportunity from 'customerSamples/models/SpotOpportunity';

export interface SampleTableBodyProps {
  sampleEntity: Opportunity | SpotOpportunity | PssInternalContract;
}

function SampleTableBody({ sampleEntity }: SampleTableBodyProps): JSX.Element {
  if (sampleEntity instanceof Opportunity) {
    return (
      <>
        {sampleEntity.samples.map((sample, index) => (
          <SampleRow key={index} internalSample={sample} customerSample={sample} />
        ))}
        {sampleEntity.contract?.icos?.map((ico) => (
          <IcoRow key={ico.id} ico={ico} />
        ))}
      </>
    );
  } else if (sampleEntity instanceof SpotOpportunity) {
    return (
      <>
        {sampleEntity.samples.map((sample, index) => (
          <SampleRow key={index} internalSample={sample} customerSample={sample} />
        ))}
      </>
    );
  } else {
    return (
      <>
        {sampleEntity.icos.map((ico) => (
          <IcoRow key={ico.id} ico={ico} />
        ))}
      </>
    );
  }
}

export default observer(SampleTableBody);
