import styled from 'styled-components';
import COLORS from 'constants/colors';

interface TableRowHeaderProps {
  darkBackground?: boolean;
}

const TableRowHeader = styled.div<TableRowHeaderProps>`
  z-index: 8;
  position: sticky;
  left: 0;
  height: 100%;
  padding: 1.875rem 1.875rem 1.875rem 2.5rem;
  background: ${(props) =>
    props.darkBackground ? COLORS.MEDIUM_LIGHT_GRAY : COLORS.WHITE};
  font-size: 1.25rem;
  font-weight: 700;

  &:after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 100%;
    right: -1rem;
    bottom: 0;
    opacity: 0.1;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

export default TableRowHeader;
