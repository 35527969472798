import { observable, computed, action } from 'mobx';

import AvailabilityNoticeType from '../types/availabilityNotice';
import FinalizeableResource from './FinalizableResource';

class AvailabilityNotice extends FinalizeableResource<AvailabilityNoticeType> {
  @observable finalized: boolean;
  @observable date_of_availability?: string;
  @observable arrival_warehouse_id?: number;

  @action protected updateAttributes({
    id,
    finalized,
    date_of_availability,
    arrival_warehouse_id,
  }: AvailabilityNoticeType) {
    this.finalized = finalized;

    this.id = id;
    this.date_of_availability = date_of_availability;
    this.arrival_warehouse_id = arrival_warehouse_id;
  }

  @computed get isFinalizable() {
    return !['date_of_availability', 'arrival_warehouse_id'].find((attribute) =>
      [undefined, null, ''].includes(this[attribute])
    );
  }
}

export default AvailabilityNotice;
