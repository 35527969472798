import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Checkbox from 'shared/Checkbox';
import RadioGroup from '../models/RadioGroup';

interface BodyInputProps {
  sampleId: number | string;
  twoColumns?: boolean;
  useInOverlay?: boolean;
  completed?: boolean;
  descriptorBodies: RadioGroup<any>;
}

const BodyInput = ({
  sampleId,
  twoColumns,
  useInOverlay,
  completed = false,
  descriptorBodies,
}: BodyInputProps) => {
  const descriptorBodyKinds = descriptorBodies.radioKinds;

  const block = (
    <>
      {Object.keys(descriptorBodyKinds).map((key, index) => (
        <div key={index} className='check-group check-group--body'>
          <div
            className={classNames('check-group-list', {
              'check-group-list--two-columns': twoColumns,
            })}
          >
            {descriptorBodyKinds[key].map(({ id, name, _selected }) => (
              <Checkbox
                key={id}
                label={name}
                icon={'cross'}
                asRadioButton={true}
                round={true}
                iconColor={'BLUE'}
                id={`sample_${sampleId}_descriptor_body_${key}[${id}]`}
                name={`samples[${sampleId}][descriptor_body_id]`}
                value={`${id}`}
                onChange={() => descriptorBodies.selectRadio(id)}
                readOnly={completed}
                checked={descriptorBodies.selectedRadioId === id}
                // required={true}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
  if (useInOverlay) {
    return block;
  } else {
    return <div>{block}</div>;
  }
};

export default observer(BodyInput);
