import styled from 'styled-components';

import COLORS from 'constants/colors';

export interface TypeBadgeProps {
  type: 'lot' | 'set' | 'ico';
}

export const TypeBadge = styled.span<TypeBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.type === 'lot' ? COLORS.BLUE : COLORS.GRAY)};
  font-size: 0.875em;
  font-weight: 700;
  padding: 0.143em 0.43em;
  color: ${COLORS.WHITE};
  text-transform: uppercase;
  border-radius: 0.143em;
`;

export default TypeBadge;
