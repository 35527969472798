import React from 'react';

export interface OverlayRenderProps {
  closeOverlay: () => void;
}

export interface OverlayButtonProps<T = HTMLButtonElement> {
  button: React.ComponentType<React.HTMLAttributes<T>>;
  overlay: (props: OverlayRenderProps) => JSX.Element;
  onClick?: (event: React.MouseEvent<T>) => any;
}

function OverlayButton<T>({ overlay, button: Button, onClick }: OverlayButtonProps<T>) {
  const [showOverlay, setShowOverlay] = React.useState(false);

  const handleOnButtonClick = (event: React.MouseEvent<T>) => {
    setShowOverlay(true);

    onClick && onClick(event);
  };

  return (
    <>
      {showOverlay &&
        overlay({
          closeOverlay: () => {
            setShowOverlay(false);
          },
        })}
      <Button onClick={handleOnButtonClick} />
    </>
  );
}

export default OverlayButton;
