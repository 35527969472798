import { deserialise } from 'kitsu-core';
import { action, observable, computed } from 'mobx';

// import { AvailableCoffeLot } from 'types/model/lot';
import AvailableExcessOrOrphanItemModel from 'availableExcessOrOrphanCoffee/models/AvailableExcessOrOrphanItemModel';

import { OrphanStatus } from 'availableExcessOrOrphanCoffee/containers/AvailableExcessOrOrphanCoffeeContainer';
import SelectOptionArray from 'types/model/selectOption';

class AvailableExcessOrOrphanCoffeeStore {
  public items = observable.array<AvailableExcessOrOrphanItemModel>();
  public missingWeight: string;
  public millingOrderId: number;
  public orphanStatus: OrphanStatus;
  public qualities: SelectOptionArray;

  constructor(
    items: Array<any> = [],
    missingWeight: string,
    millingOrderId: number,
    orphanStatus: OrphanStatus,
    qualities: SelectOptionArray
  ) {
    let newItems = Array<AvailableExcessOrOrphanItemModel>();
    const deserialisedData = deserialise(items).data;

    if (deserialisedData) {
      newItems = deserialisedData.map(
        (item: any) => new AvailableExcessOrOrphanItemModel(item)
      );
    }

    this.missingWeight = missingWeight;
    this.millingOrderId = millingOrderId;
    this.orphanStatus = orphanStatus;
    this.qualities = qualities;
    this.items = observable.array(newItems);
  }

  @action public toggleLotSelection = (item: AvailableExcessOrOrphanItemModel) => {
    item.setSelected(!item.selected);
  };

  @computed get selectedItems(): Array<AvailableExcessOrOrphanItemModel> {
    return observable.array(this.items.filter((item) => item.selected));
  }

  @computed get selectedWeight(): string {
    return this.selectedItems
      .reduce((currentWeight, item) => currentWeight + parseFloat(item.quantity), 0.0)
      .toFixed(2);
  }

  @computed get openWeight(): string {
    return (parseFloat(this.missingWeight) - parseFloat(this.selectedWeight)).toFixed(2);
  }
}

export default AvailableExcessOrOrphanCoffeeStore;
