import styled from 'styled-components';

import COLORS from 'constants/colors';

export const LotInfoBoxWrapper = styled.div`
  padding: 0 0.625em 1em;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${COLORS.BORDERS};
  height: 3em;
  line-height: 3em;
  display: flex;
  align-items: center;
`;

export const HeaderInfo = styled.span`
  padding: 0 0.625em;
  font-size: 1.25em;
  font-weight: 600;

  span {
    color: ${COLORS.SUB_TEXT};
    padding: 0 0.25em;
  }
`;

export const SubHeaderInfo = styled(HeaderInfo)`
  font-size: 1em;
  font-weight: 400;
`;
