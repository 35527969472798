import classNames from 'classnames';
import React from 'react';

export interface TextProps {
  dataCy?: string;
  text: string | number;
  textClass?: string;
  title?: string;
  width?: string;
  overflow?: boolean;
  onClick?: () => void;
}

const Text = ({
  text,
  textClass,
  width: _width,
  onClick,
  title,
  overflow,
  ...props
}: TextProps) => {
  const textClassnames = classNames('text-with-sub__text', {
    [`${textClass}`]: textClass,
  });
  return (
    <p
      className={textClassnames}
      style={{ whiteSpace: overflow ? 'initial' : 'nowrap' }}
      onClick={onClick}
      title={title ? title : ''}
      {...props}
    >
      {text}
    </p>
  );
};

export default Text;
