import React from 'react';
import Text from 'shared/TextWithSub/components/Text';
import placeholder from 'utils/placeholder';
import { addTestingIdentifier } from 'utils/testing';
import WSRSelectionCheckbox from '../warehouse_sample_request/WSRSelectionCheckbox';
import { ISelectableSpotContract } from './WSRSpotContractsTable';

interface WSRSpotContractsTableRowProps {
  onSelect: () => any;
  onDeselect: () => any;
  spotContract: ISelectableSpotContract;
}

function WSRSpotContractsTableRow({
  spotContract,
  onSelect,
  onDeselect,
}: WSRSpotContractsTableRowProps) {
  return (
    <tr {...addTestingIdentifier(`wsr_ico`)}>
      <td className='table-border'>
        <Text
          width={'11em'}
          text={placeholder(spotContract.main_identifier)}
          textClass='text-with-sub__text'
        />
      </td>

      <td className='table-border'>
        <Text
          width={'11em'}
          text={placeholder(spotContract.customer.name)}
          textClass='text-with-sub__text'
        />
      </td>
      <td className='table-border'>
        <Text
          width={'11em'}
          text={placeholder(spotContract.units)}
          textClass='text-with-sub__text'
        />
      </td>

      <td className='selected-lots selected-lots__checkbox align-center'>
        <WSRSelectionCheckbox
          checked={spotContract.selected}
          onCheck={onSelect}
          onUncheck={onDeselect}
          disabled={spotContract.has_existing_spot_sample}
          dataCy='wsr_spot_contract_checkbox'
        />
      </td>
    </tr>
  );
}

export default WSRSpotContractsTableRow;
