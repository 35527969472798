import { computed, observable } from 'mobx';
import type * as API from '../types/api';

class IcoListing {
  @observable id?: number;
  @observable ico_id?: number;
  @observable lot_type_id?: string;
  @observable revision_number?: string;
  @observable announcement?: string;
  @observable allocation?: string;
  @observable sales_code?: string;
  @observable cargo_number?: string;
  @observable alternative_ico_id?: string;

  constructor({
    id,
    ico_id,
    lot_type_id,
    revision_number,
    announcement,
    allocation,
    sales_code,
    cargo_number,
    alternative_ico_id,
  }: API.IIcoListing) {
    this.id = id;
    this.ico_id = ico_id;
    this.lot_type_id = lot_type_id;
    this.revision_number = revision_number;
    this.announcement = announcement;
    this.allocation = allocation;
    this.sales_code = sales_code;
    this.cargo_number = cargo_number;
    this.alternative_ico_id = alternative_ico_id;
  }

  @computed get isFinalizable() {
    return ![
      'ico_id',
      'lot_type_id',
      'revision_number',
      'announcement',
      'allocation',
      'sales_code',
      'cargo_number',
    ].find((attribute) => [undefined, null, ''].includes(this[attribute]));
  }
}

export default IcoListing;
