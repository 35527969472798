import classNames from 'classnames';
import React, { ReactNode } from 'react';

export interface BaseGroupProps {
  label?: string;
  append?: JSX.Element | string;
  prepend?: any;
  info?: string;
  infoname?: string;
  condensed?: boolean;
  inline?: boolean;
  noMargin?: boolean;
  changeIndicated?: boolean;
  errors?: JSX.Element | string[];
  required?: boolean;
}

export interface ExtendBaseGroupProps extends BaseGroupProps {
  id?: string;
  className?: string;
  children: ReactNode;
  emptyLabel?: boolean;
}

const BaseGroupComponent = React.forwardRef<HTMLInputElement, ExtendBaseGroupProps>(
  (
    {
      id,
      label,
      append,
      prepend,
      info,
      infoname,
      className,
      children,
      emptyLabel,
      condensed,
      inline,
      noMargin,
      changeIndicated,
      errors,
      required,
      ...rest
    },
    ref
  ) => {
    const customClass = classNames('input-group', className, {
      'input--condensed': condensed,
      'input-group--inline': inline,
      'input-group--no-margin': noMargin,
      'input-group--without-label': emptyLabel,
      'input-group--change-indicated': changeIndicated === true,
      'input-group--no-change-indicated': changeIndicated === false,
    });

    return (
      <div className={customClass} {...rest}>
        {label && (
          <label htmlFor={id}>
            {label}
            {label && required && '*'}
          </label>
        )}

        <div className={append || prepend ? 'input-group__row' : ''}>
          {children}

          {prepend && <span className='input-group__prepend'>{append}</span>}

          {append && (
            <span
              className={classNames('input-group__append', {
                'input-group__append--double': info,
              })}
            >
              <span>{append}</span>

              {info && <span className='input-group__append-info'>{info}</span>}

              {info && infoname && (
                <input
                  ref={ref}
                  type='hidden'
                  name={infoname}
                  value={info.replace('%', '')}
                />
              )}
            </span>
          )}
        </div>
        {errors}
      </div>
    );
  }
);

BaseGroupComponent.displayName = 'BaseGroupComponent';

export default BaseGroupComponent;
