import AssignedLot from './AssignedLot';
import AssignedLotsType from '../types/assignedLot';
import React from 'react';
import styled from 'styled-components';

interface TraceabilityBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  collapsed: boolean;
  assignedLots: AssignedLotsType[];
}

const TraceabilityBody: React.FC<TraceabilityBodyProps> = ({
  assignedLots,
  className,
}) => (
  <div className={className}>
    {assignedLots.map((assignedLot) => (
      <AssignedLot key={assignedLot.id} assignedLot={assignedLot} />
    ))}
  </div>
);

export default styled(TraceabilityBody)`
  margin: ${(props) => (!props.collapsed ? '0.42em 0 2.5em' : 0)};
`;
