import ControlledSearch from 'components/elements/ControlledSearch';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { SampleDataContext } from 'samples/create/components/CreateSample';

const SampleOpportunitySelect = (): JSX.Element => {
  const { control } = useFormContext();
  const { opportunities } = useContext(SampleDataContext);
  return (
    <ControlledSearch
      label={I18n.translate('samples.create.opportunity')}
      dataCy='select_opportunity'
      prompt={I18n.translate('samples.create.select_opportunity')}
      options={opportunities}
      control={control}
      name='sample[opportunity_id]'
      required
    />
  );
};
export default SampleOpportunitySelect;
