import Icon from 'components/elements/Icon';
import React, { useMemo } from 'react';
import { TileList } from 'shared/Tile';
import styled from 'styled-components';
import { ContractTraceability } from 'traceability/types/ContractTraceability';
import {
  CenteredSecondaryDetail,
  DateDetail,
  DisclosureDetail,
  IdentifierDetail,
  PrimaryDetail,
  SecondaryDetail,
} from './Detail';
import Details from './Details';
import TypeBadge from './TypeBadge';

export const ContractTraceabilityDetails = styled(Details)`
  grid-template-columns: 2.5em 11em minmax(3em, 1fr);
  grid-column-gap: 0.9375em;
`;

export interface ContractTraceabilityHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onCollapsibleHandler: (event: React.MouseEvent) => any;
  collapsed: boolean;
  traceability: ContractTraceability;
}

function ContractTraceabilityHeader({
  traceability,
  traceability: {
    date,
    ico_identifier,
    alternative_ico_id,
    quality,
    grade,
    identifier,
    customer,
    origin,
  },
  onCollapsibleHandler,
  collapsed,
  ...props
}: ContractTraceabilityHeaderProps) {
  const format_secondary_ico = useMemo(() => {
    let string = ico_identifier;
    if (alternative_ico_id) {
      string += '\n' + alternative_ico_id;
    }
    return string;
  }, [ico_identifier, alternative_ico_id]);

  return (
    <TileList {...props}>
      <ContractTraceabilityDetails>
        <TypeBadge type='ico'>ICO</TypeBadge>

        <DateDetail>{date}</DateDetail>

        <PrimaryDetail data-cy='traceability_quality' align='center'>
          {quality}
        </PrimaryDetail>

        <DisclosureDetail align='center'>
          {
            <Icon
              onClick={onCollapsibleHandler}
              icon={collapsed ? 'caret-down' : 'caret-up'}
              className={'selected-lots__disclosure-icon'}
            />
          }
        </DisclosureDetail>

        <IdentifierDetail>{format_secondary_ico}</IdentifierDetail>

        <CenteredSecondaryDetail align='center'>{grade}</CenteredSecondaryDetail>
      </ContractTraceabilityDetails>

      <Details>
        <PrimaryDetail>
          {I18n.translate('traceability.contracts.contract_number', {
            identifier: identifier,
          })}
        </PrimaryDetail>

        <PrimaryDetail>
          {I18n.translate('traceability.contracts.customer_name', { name: customer })}
        </PrimaryDetail>
      </Details>

      {'export_identifier' in traceability ? (
        <Details>
          <PrimaryDetail>{origin}</PrimaryDetail>
          <SecondaryDetail>{traceability.export_identifier}</SecondaryDetail>
        </Details>
      ) : (
        <Details>{origin}</Details>
      )}
    </TileList>
  );
}

export default ContractTraceabilityHeader;
