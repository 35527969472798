import React from 'react';
import Checkbox from 'shared/Checkbox';
import { observer } from 'mobx-react';
import TableData from 'customerSamples/components/TableData';

export interface ReceivedCheckboxProps {
  received: boolean;
}

function ReceivedCheckbox({ received }: ReceivedCheckboxProps): JSX.Element {
  return (
    <TableData className='align-center'>
      <Checkbox icon='tick' checked={received} disabled={true} readOnly={true} />
    </TableData>
  );
}

export default observer(ReceivedCheckbox);
