import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import COLORS from 'constants/colors';

import InputGroup from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import Ico from '../../models/Ico';
import IcoListModel from '../../models/IcoList';
import AccordionFormBody from '../AccordionFormBody';
import SelectOptionArray from 'types/model/selectOption';
import useStores from 'utils/useStores';
import mapIcosWithIcoListing from '../../utils/mapIcosWithIcoListing';
import IcoWithIcoListing from '../../types/IcoWithIcoListing';
import { trimWhitespaces } from 'utils/string';

export interface IcoListProps {
  icos: Ico[];
  icoList: IcoListModel;
  lotTypes: SelectOptionArray;
}

const IcoListWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

const FieldSetWrapper = styled.div`
  overflow-y: auto;
`;

const IcoWrapper = styled.div`
  min-width: 8rem;
`;

const InputWrapper = styled.td`
  .input-group {
    min-width: 7rem;
  }
`;

const SelectWrapper = styled.td`
  min-width: 14rem;
`;

const RowWrapper = styled.tr`
  td {
    padding: 0.5rem 1rem !important;
  }

  td:first-child {
    padding-left: 2.5rem !important;
  }

  td:last-child {
    padding-right: 4rem !important;
  }

  th {
    padding: 0.5rem 1rem !important;
    white-space: nowrap;
  }

  th:first-child {
    padding-left: 2.5rem !important;
  }

  th:last-child {
    padding-right: 4rem !important;
  }
`;

function buildDefaultIcoListValues(icosWithIcoListing: IcoWithIcoListing[]) {
  const ico_listings_attributes = icosWithIcoListing.map(({ ico, icoListing }) => {
    return {
      ...icoListing,
      ico_attributes: {
        ...ico,
        alternative_ico_id: icoListing?.alternative_ico_id,
      },
    };
  });

  return {
    ico_list: {
      ico_listings_attributes,
    },
  };
}

function IcoList({ icos, icoList, lotTypes }: IcoListProps): JSX.Element {
  const {
    exportsStore: { selectedExport },
  } = useStores();

  const icosWithIcoListing = mapIcosWithIcoListing(icos, icoList.ico_listings);

  const defaultValues = buildDefaultIcoListValues(icosWithIcoListing);

  const { register, handleSubmit, formState, setValue, reset } = useForm({
    defaultValues,
  });

  const onFinalizeClick = async () => {
    await icoList.finalize();
  };

  const handleTrimWhitespaces = ({
    target,
  }: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = target;
    const trimmedValue = trimWhitespaces(value);

    setValue(name, trimmedValue);
  };

  // TODO: Typing of formData is  wrong as this uses rails nested attributes
  const onSaveClick = handleSubmit(async (formData: any): Promise<void> => {
    await icoList.save(formData);

    const newDefaultValues = buildDefaultIcoListValues(
      mapIcosWithIcoListing(icos, icoList.ico_listings)
    );

    reset(newDefaultValues);
  });

  return (
    <IcoListWrapper>
      <AccordionFormBody
        isFinalizable={false}
        isDirty={formState.isDirty}
        isSubmitting={formState.isSubmitting}
        finalized={icoList.finalized}
        onSubmitClick={onSaveClick}
        onFinalizeClick={onFinalizeClick}
        downloadUrl={undefined}
      >
        <FieldSetWrapper>
          <table>
            <thead>
              <RowWrapper>
                {/* TODO: MOVE TO CORRECT TRANSLATION */}
                <th className='align-left'>
                  {I18n.translate('exports.form.ico_list.list_headers.ico')}
                </th>

                <th className='align-left'>
                  {I18n.translate('exports.form.shipment.lot_type_label')}
                </th>

                <th className='align-left'>
                  {I18n.translate('exports.form.transit_guide.revision_number_label')}
                </th>

                <th className='align-left'>
                  {I18n.translate('exports.form.ico_list.list_headers.announcement')}
                </th>

                <th className='align-left'>
                  {I18n.translate('exports.form.ico_list.list_headers.allocation')}
                </th>

                <th className='align-left'>
                  {I18n.translate('exports.form.ico_list.list_headers.sales_code')}
                </th>

                <th className='align-left'>
                  {I18n.translate('exports.form.ico_list.list_headers.cargo_number')}
                </th>

                {selectedExport?.useSecondaryIcoIdentifier && (
                  <th className='align-left no-word'>
                    {I18n.translate(
                      'exports.form.ico_list.list_headers.alternative_ico_id'
                    )}
                  </th>
                )}
              </RowWrapper>
            </thead>

            <tbody>
              {icosWithIcoListing &&
                icosWithIcoListing.map(({ ico, icoListing }, index) => {
                  const param_name = `ico_list[ico_listings_attributes][${index}]`;

                  return (
                    <RowWrapper key={index}>
                      <td>
                        <IcoWrapper>{ico.identifier}</IcoWrapper>

                        <input
                          type='hidden'
                          name={`${param_name}[ico_id]`}
                          value={ico.id}
                          ref={register}
                        />

                        <input
                          type='hidden'
                          name={`${param_name}[ico_attributes][id]`}
                          value={ico.id}
                          ref={register}
                        />

                        <input
                          type='hidden'
                          name={`${param_name}[id]`}
                          value={icoListing?.id || ''}
                          ref={register}
                        />
                      </td>

                      <SelectWrapper>
                        <SelectGroup
                          placeholder={I18n.translate(
                            'exports.form.shipment.lot_type_placeholder'
                          )}
                          options={lotTypes}
                          noMargin={true}
                          id={`${param_name}[lot_type_id]`}
                          name={`${param_name}[lot_type_id]`}
                          ref={register}
                        />
                      </SelectWrapper>

                      <InputWrapper>
                        <InputGroup
                          noMargin={true}
                          id={`${param_name}[revision_number]`}
                          name={`${param_name}[revision_number]`}
                          ref={register}
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <InputGroup
                          noMargin={true}
                          id={`${param_name}[announcement]`}
                          name={`${param_name}[announcement]`}
                          ref={register}
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <InputGroup
                          noMargin={true}
                          id={`${param_name}[allocation]`}
                          name={`${param_name}[allocation]`}
                          ref={register}
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <InputGroup
                          noMargin={true}
                          id={`${param_name}[sales_code]`}
                          name={`${param_name}[sales_code]`}
                          ref={register}
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <InputGroup
                          noMargin={true}
                          id={`${param_name}[cargo_number]`}
                          name={`${param_name}[cargo_number]`}
                          ref={register}
                        />
                      </InputWrapper>

                      {selectedExport?.useSecondaryIcoIdentifier && (
                        <InputWrapper>
                          <InputGroup
                            noMargin={true}
                            id={`${param_name}[ico_attributes][alternative_ico_id]`}
                            name={`${param_name}[ico_attributes][alternative_ico_id]`}
                            ref={register}
                            onBlur={handleTrimWhitespaces}
                          />
                        </InputWrapper>
                      )}
                    </RowWrapper>
                  );
                })}
            </tbody>
          </table>
        </FieldSetWrapper>
      </AccordionFormBody>
    </IcoListWrapper>
  );
}

export default observer(IcoList);
