import React from 'react';
import Routes from 'routes';

import Checkbox from 'shared/Checkbox';

import { customFetch } from 'utils/fetch';

interface ReturnWithLoanComponentProps {
  id: number;
  checked: boolean;
  disabled: boolean;
}

const ReturnWithLoanComponent = ({
  id,
  checked,
  disabled,
}: ReturnWithLoanComponentProps) => {
  const handleChange = () => {
    customFetch(Routes.lot_authorize_return_path(id)).then(() => {
      window.location.reload();
    });
  };

  return (
    <Checkbox
      icon='tick'
      inline={true}
      checked={checked}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default ReturnWithLoanComponent;
