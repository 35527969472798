import React from 'react';
import styled from 'styled-components';
import generateRandomId from 'utils/generateRandomId';

export interface Pagy {
  scaffold_url: string;
  first_url: string;
  prev_url: string;
  page_url: string;
  next_url: string;
  last_url: string;
  count: number;
  page: any;
  items: any;
  vars: any;
  pages: any;
  last: any;
  from: string;
  to: number;
  prev: null;
  next: number;
  series: any;
}

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const PaginationElement = styled.span`
  padding: 0.25rem;
  min-width: 1.75rem;
  &:hover {
    text-decoration: underline;
  }
`;

export interface PagynationProps {
  pagy: Partial<Pagy>;
  searchParams?: {};
  url: (...args: any[]) => string;
  handleOnClick?: (event: any) => void;
}

function Pagynation({
  pagy,
  searchParams,
  url,
  handleOnClick,
}: PagynationProps): JSX.Element {
  const pageElements: JSX.Element[] = [];

  const getPages = (c, m) => {
    const current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range: number[] = [],
      rangeWithDots: (string | number)[] = [];
    let l;

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  let prevElement = (
    <PaginationElement
      key='prev'
      dangerouslySetInnerHTML={{ __html: I18n.translate('pagy.nav.prev') }}
    />
  );
  let nextElement = (
    <PaginationElement
      key='next'
      dangerouslySetInnerHTML={{ __html: I18n.translate('pagy.nav.next') }}
    />
  );

  if (pagy.prev) {
    prevElement = handleOnClick ? (
      <PaginationElement key='prev'>
        <a
          rel='prev'
          aria-label='prev'
          href={url({ ...searchParams, page: pagy.prev })}
          dangerouslySetInnerHTML={{ __html: I18n.translate('pagy.nav.prev') }}
          onClick={(event) => {
            event.preventDefault();
            handleOnClick(event);
          }}
        />
      </PaginationElement>
    ) : (
      <PaginationElement key='prev'>
        <a
          rel='prev'
          aria-label='prev'
          href={url({ ...searchParams, page: pagy.prev })}
          dangerouslySetInnerHTML={{ __html: I18n.translate('pagy.nav.prev') }}
        />
      </PaginationElement>
    );
  }

  pageElements.push(prevElement);

  const maxItems = 11;

  if (pagy.pages <= maxItems) {
    for (let i = 1; i <= pagy.pages; i++) {
      let pageElement = <PaginationElement key={i}>{i}</PaginationElement>;

      if (i !== pagy.page) {
        pageElement = handleOnClick ? (
          <PaginationElement key={i}>
            <a
              href={url({ ...searchParams, page: i })}
              onClick={(event) => {
                event.preventDefault();
                handleOnClick(event);
              }}
            >
              {i}
            </a>
          </PaginationElement>
        ) : (
          <PaginationElement key={i}>
            <a href={url({ ...searchParams, page: i })}>{i}</a>
          </PaginationElement>
        );
      }

      pageElements.push(pageElement);
    }
  } else {
    getPages(pagy.page, pagy.pages).forEach((pageIndex) => {
      let pageElement = (
        <PaginationElement key={pageIndex}>{pageIndex}</PaginationElement>
      );
      if (pageIndex === '...') {
        pageElement = (
          <PaginationElement key={generateRandomId('spacer')}>...</PaginationElement>
        );
      } else {
        if (pageIndex !== pagy.page) {
          pageElement = handleOnClick ? (
            <PaginationElement key={pageIndex}>
              <a
                href={url({ ...searchParams, page: pageIndex })}
                onClick={(event) => {
                  event.preventDefault();
                  handleOnClick(event);
                }}
              >
                {pageIndex}
              </a>
            </PaginationElement>
          ) : (
            <PaginationElement key={pageIndex}>
              <a href={url({ ...searchParams, page: pageIndex })}>{pageIndex}</a>
            </PaginationElement>
          );
        }
      }

      pageElements.push(pageElement);
    });
  }

  if (pagy.next) {
    nextElement = handleOnClick ? (
      <PaginationElement key='next'>
        <a
          rel='next'
          aria-label='next'
          href={url({ ...searchParams, page: pagy.next })}
          dangerouslySetInnerHTML={{ __html: I18n.translate('pagy.nav.next') }}
          onClick={(event) => {
            event.preventDefault();
            handleOnClick(event);
          }}
        />
      </PaginationElement>
    ) : (
      <PaginationElement key='next'>
        <a
          rel='next'
          aria-label='next'
          href={url({ ...searchParams, page: pagy.next })}
          dangerouslySetInnerHTML={{ __html: I18n.translate('pagy.nav.next') }}
        />
      </PaginationElement>
    );
  }

  pageElements.push(nextElement);

  return (
    <Nav aria-label='pager' role='navigation'>
      {pageElements}
    </Nav>
  );
}

export default Pagynation;
