import React from 'react';
import { observer } from 'mobx-react';

import type { AccordionHeaderProps } from 'shared/Accordion/components/AccordionWrapper';
import AccordionFormHeader from '../AccordionFormHeader';

export interface ResponsibilityLetterHeaderProps extends AccordionHeaderProps {
  completed: boolean;
}

const ResponsibilityLetterHeader = ({
  expanded,
  completed,
}: ResponsibilityLetterHeaderProps) => (
  <AccordionFormHeader
    expanded={expanded}
    headline={I18n.translate('exports.form.responsibility_letter.header')}
    completed={completed}
  />
);

export default observer(ResponsibilityLetterHeader);
