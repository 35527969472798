import classNames from 'classnames';
import React from 'react';

export interface ContentHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle?: string;
  prefix?: string;
  contentHeadlineClassName?: string;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({
  className,
  children,
  title,
  subtitle,
  prefix,
  contentHeadlineClassName,
  ...props
}) => {
  return (
    <div className={classNames('content-headline', className)} {...props}>
      <div className='content-headline-left'>
        <h2>
          {prefix && <span className='content-headline__prefix'>{prefix}</span>} {title}
        </h2>
        {subtitle && <span className='content-headline__subtitle'>{subtitle}</span>}
      </div>
      <div className={classNames('content-headline-right', contentHeadlineClassName)}>
        {children}
      </div>
    </div>
  );
};

export default ContentHeader;
