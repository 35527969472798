import React from 'react';
import useStores from 'utils/useStores';
import SampleTableRow from 'cuppingTable/components/SampleTableRow';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import FilterBar from 'components/elements/FilterBar';
import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import { useFilterContext } from 'utils/filterContext';
import debounce from 'lodash.debounce';

const StyledWrapper = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  border-bottom: 1px solid #c8c8c8;
`;

const SampleTable = (): JSX.Element => {
  const { cuppingTableStore } = useStores();

  const { filters, updateFilter } = useFilterContext();

  const filteredItems = cuppingTableStore.unselectedSamples.filter((item) =>
    Object.entries(filters).every(([key, { filter, value }]) => {
      return value ? filter(item[key], value) : true;
    })
  );

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => updateFilter(event.target.name, event.target.value);

  const debounceInputChange = debounce((e) => {
    handleOnChange(e);
  }, 500);

  const handleInputChange = (e) => {
    e.persist();
    debounceInputChange(e);
  };

  const sampleRows = filteredItems.map((sample) => (
    <SampleTableRow key={sample.id} sample={sample} />
  ));
  return (
    <StyledWrapper className='lots lots-index'>
      <h2 style={{ marginBottom: '20px' }}>Sample List</h2>
      <FilterBar>
        <InputGroupComponent
          name='full_identifier'
          type='text'
          noMargin={true}
          placeholder={I18n.translate('cupping_tables.search_by_ico_lot')}
          onChange={handleInputChange}
        />
        <SelectGroup
          name='sample_type'
          onChange={handleOnChange}
          noMargin={true}
          options={cuppingTableStore.sampleTypes}
          value={filters['sample_type'].value}
          placeholder={I18n.translate('cupping_tables.select_sample_type')}
        />
        {/* <SelectGroup
          name='quality'
          onChange={handleOnChange}
          noMargin={true}
          options={cuppingTableStore.qualities}
          value={filters['quality'].value}
          placeholder={I18n.translate('cupping_tables.select_quality')}
        />
        <SelectGroup
          name='grade'
          onChange={handleOnChange}
          noMargin={true}
          options={cuppingTableStore.grades}
          value={filters['grade'].value}
          placeholder={I18n.translate('cupping_tables.select_grade')}
        />
        <SelectGroup
          name='process_type'
          onChange={handleOnChange}
          noMargin={true}
          options={cuppingTableStore.processTypes}
          value={filters['process_type'].value}
          placeholder={I18n.translate('cupping_tables.select_process_type')}
        /> */}
      </FilterBar>
      <table>
        <thead>
          <tr>
            <th className='table-border'>
              {I18n.translate('cupping_tables.index.sample_id')}
            </th>

            <th className='table-border'>
              {I18n.translate('cupping_tables.index.sample_type')}
            </th>

            {/* <th className='table-border'>
              {I18n.translate('cupping_tables.index.grade')}
            </th> */}
            <th className='table-border'>
              {I18n.translate('cupping_tables.index.cupping_table_number')}
            </th>
            {/* 
            <th className='table-border'>
              {I18n.translate('cupping_tables.index.weight')}
            </th> */}

            {/* <th className='table-border'>
              {I18n.translate('cupping_tables.index.process_type')}
            </th> */}

            <th className=''>{I18n.translate('cupping_tables.index.select')} </th>
          </tr>
        </thead>

        <tbody>{sampleRows}</tbody>
      </table>
    </StyledWrapper>
  );
};

export default observer(SampleTable);
