import classnames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Checkbox from 'shared/Checkbox';
import CheckboxGroup from '../models/CheckboxGroup';

interface FlavourInputProps {
  sampleId: number | string;
  twoColumns?: boolean;
  threeColumns?: boolean;
  completed?: boolean;
  descriptorFlavours: CheckboxGroup<any>;
}

const FlavourInput = ({
  sampleId,
  twoColumns,
  threeColumns,
  completed = false,
  descriptorFlavours,
}: FlavourInputProps) => {
  const descriptorFlavourKinds = descriptorFlavours.checkboxKinds;
  return (
    <div>
      {Object.keys(descriptorFlavourKinds).map((key, index) => (
        <div key={index} className='check-group check-group--flavour'>
          <div
            className={classnames(
              'check-group-list',
              twoColumns && 'check-group-list--two-columns',
              threeColumns && 'check-group-list--three-columns'
            )}
          >
            {descriptorFlavourKinds[key].map((descriptorFlavour) => (
              <Checkbox
                key={descriptorFlavour.id}
                label={descriptorFlavour.name}
                icon={'tick'}
                id={`sample_${sampleId}_descriptor_flavour_id_${descriptorFlavour.id}`}
                name={`samples[${sampleId}][descriptor_flavour_ids][]`}
                value={`${descriptorFlavour.id}`}
                onChange={() =>
                  !descriptorFlavour.selected
                    ? descriptorFlavours.selectCheckbox(descriptorFlavour)
                    : descriptorFlavours.deselectCheckbox(descriptorFlavour)
                }
                readOnly={completed}
                checked={descriptorFlavour.selected}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default observer(FlavourInput);
