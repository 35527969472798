import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import useStores from 'utils/useStores';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';

import DeliveryOrderTable from './DeliveryOrderTable';
import DeliveryOrderFilter from './DeliveryOrderFilter';

const ReadyToDeliver: React.FC = () => {
  const { deliveryOrderStore } = useStores();
  const { selectedCustomerId, selectedWarehouseId } = deliveryOrderStore;

  useEffect(() => {
    if (selectedCustomerId && selectedWarehouseId) {
      customFetch(
        Routes.api_v1_fulfillment_ico_list_path({
          excelso_inventory: {
            destination_warehouse_id: selectedWarehouseId,
            customer_id: selectedCustomerId,
          },
        }),
        undefined,
        'GET'
      ).then(({ excelso_inventory }) => {
        deliveryOrderStore.setExcelsoInventory(excelso_inventory);
      });
    }
  }, [selectedCustomerId, selectedWarehouseId]);

  return (
    <>
      <DeliveryOrderFilter />

      {selectedCustomerId && selectedWarehouseId ? (
        <DeliveryOrderTable />
      ) : (
        <p>Please select a customer and warehouse!</p>
      )}
    </>
  );
};

export default observer(ReadyToDeliver);
