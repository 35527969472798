import { observer } from 'mobx-react';
import React from 'react';

import InputGroupComponent from 'components/groups/InputGroup';

import Customer from 'customers/models/Customer';
import { IField } from 'components/shared/form/IField';
import useField from '../hooks/useField';
import Icon from 'components/elements/Icon';

export interface CustomerEmailAddressInputProps {
  customer: Customer;
  fields: IField[];
}

const CustomerEmailAddressInput = ({
  customer,
  fields,
}: CustomerEmailAddressInputProps) => {
  const customerEmailAddressField = useField(fields, 'customer_email_addresses');

  const renderRemoveButton = (index: number): JSX.Element | undefined => {
    if (customerEmailAddressField?.enabled) {
      return (
        <button
          className='button button--small button--required'
          type='button'
          onClick={() => customer.removeCustomerEmailAddress(index)}
        >
          <Icon icon='cross' />
          {I18n.translate('buttons.remove')}
        </button>
      );
    }
  };

  return (
    <>
      {customer.customerEmailAddresses.map((emailAddress: string, index: number) => (
        <InputGroupComponent
          key={index}
          label={index < 1 ? I18n.translate('attributes.email_address') : ''}
          name='customer[customer_email_addresses][]'
          value={emailAddress}
          placeholder=' '
          onChange={(e) => customer.setCustomerEmailAddress(e.target.value, index)}
          onBlur={(e) => {
            if (!e.target.value) customer.removeCustomerEmailAddress(index);
          }}
          required={true}
          type='email'
          disabled={!customerEmailAddressField?.enabled}
          append={renderRemoveButton(index)}
        />
      ))}
      {!!customerEmailAddressField?.enabled && (
        <button
          className='button button--green button--small'
          type='button'
          onClick={() => customer.addNewCustomerEmailAddressInput()}
          disabled={customer.emptyLastEmailEntry}
        >
          + {I18n.translate('buttons.add')}
        </button>
      )}
    </>
  );
};

export default observer(CustomerEmailAddressInput);
