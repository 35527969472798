import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Routes from 'routes';

import useStores from 'utils/useStores';

import SelectOptionArray from 'types/model/selectOption';

import FieldSet from 'components/elements/FieldSet';
import Grid from 'components/elements/Grid';
import SpaceBetween from 'components/elements/SpaceBetween';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';

import FileUploaderComponent from 'shared/FileUploader/components/FileUploaderComponent';
import SubmitButtonsComponent from 'shared/SubmitButtons/components/SubmitButtons';
import LegQuestionnaire from './LegQuestionnaire';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

export type Transportable = {
  id: number;
  order: number;
};

export interface CreateTransportLegProps {
  lots: Array<Transportable>;
  lot_sets: Array<Transportable>;
  lost_coffees: Array<Transportable>;
  icos: Array<Transportable>;
  transport: any;
  personInspectionDispatching: SelectOptionArray;
  personsLoading: SelectOptionArray;
  nextDestinations: SelectOptionArray;
  nextDestinationId: number;
  withQuestionnaire: boolean;
  submitPath?: string;
  cancelPath?: string;
}

const CreateTransportLeg = ({
  lots,
  lot_sets,
  lost_coffees,
  icos,
  transport,
  personInspectionDispatching,
  personsLoading,
  nextDestinations,
  nextDestinationId,
  withQuestionnaire,
  submitPath,
  cancelPath,
}: CreateTransportLegProps) => {
  const { legStore, fileUploaderStore } = useStores();

  // Check for questionnaire
  useEffect(() => {
    legStore.setQuestionnaireNeeded(withQuestionnaire);
    if (personInspectionDispatching.length > 0) {
      // @ts-ignore: Wrongly typed Options

      legStore.setResponsibleForDispatchingId(personInspectionDispatching[0].id);

      // @ts-ignore: Wrongly typed Options
      legStore.setResponsibleForInspectionId(personInspectionDispatching[0].id);
    }

    window.onbeforeunload = () => '';
  }, []);

  useEffect(() => {
    if (legStore.readyForSubmit) {
      window.onbeforeunload = () => undefined;
    }
  }, [legStore.readyForSubmit]);

  // Create The Post Body
  const formData = new FormData();

  if (fileUploaderStore.files) {
    fileUploaderStore.files.forEach((image) => {
      formData.append('leg[files][]', image);
    });
  }

  Object.keys(legStore.formBody.leg).forEach((key) => {
    formData.append(`leg[${key}]`, JSON.stringify(legStore.formBody.leg[key]));
  });

  formData.append(
    'transport[next_destination_warehouse_id]',
    legStore.formBody.transport.next_destination_warehouse_id.toString()
  );

  if (lots) {
    lots.forEach((lot) => {
      formData.append('leg[lots_transport_legs_attributes][][lot_id]', lot.id.toString());
      formData.append(
        'leg[lots_transport_legs_attributes][][order]',
        lot.order.toString()
      );
    });
  }

  if (lot_sets) {
    lot_sets.forEach((set) => {
      formData.append(
        'leg[lot_sets_transport_legs_attributes][][lot_set_id]',
        set.id.toString()
      );
      formData.append(
        'leg[lot_sets_transport_legs_attributes][][order]',
        set.order.toString()
      );
    });
  }

  if (lost_coffees) {
    lost_coffees.forEach((lost_coffee) => {
      formData.append('leg[lost_coffee_ids][]', lost_coffee.id.toString());
      formData.append('leg[lost_coffees_attributes][][id]', lost_coffee.id.toString());
      formData.append(
        'leg[lost_coffees_attributes][][order]',
        lost_coffee.order.toString()
      );
    });
  }

  if (icos) {
    icos.forEach((ico) => {
      formData.append(
        'leg[decaf_transport_legs_icos_attributes][][ico_id]',
        ico.id.toString()
      );
      formData.append(
        'leg[decaf_transport_legs_icos_attributes][][order]',
        ico.order.toString()
      );
    });
  }

  return (
    <>
      <FieldSet>
        <AuthenticityToken />
        <Grid grid={9}>
          <InputGroupComponent
            type='date'
            label={I18n.translate('transports.create.dispatch_date')}
            value={legStore.dispatchDate}
            onChange={(e) => legStore.setDispatchDate(e.target.value)}
          />
          <SelectGroup
            label={I18n.translate('transports.create.next_destination')}
            placeholder={I18n.translate('transports.create.next_destination_placeholder')}
            options={nextDestinations}
            data-cy='select_next_destination'
            value={legStore.nextDestinationId}
            disabled={nextDestinationId !== undefined}
            onChange={(e) => legStore.setNextDestinationId(parseInt(e.target.value, 10))}
          />
        </Grid>
        <Grid grid={16}>
          <SelectGroup
            label={I18n.translate('transports.create.responsible_for_inspection')}
            placeholder={I18n.translate('transports.create.select_a_person_placeholder')}
            options={personInspectionDispatching}
            value={legStore.responsibleForInspectionId}
            data-cy='select_responsible_for_inspection'
            onChange={(e) =>
              legStore.setResponsibleForInspectionId(parseInt(e.target.value, 10))
            }
          />
          <SelectGroup
            label={I18n.translate('transports.create.responsible_for_dispatching')}
            placeholder={I18n.translate('transports.create.select_a_person_placeholder')}
            options={personInspectionDispatching}
            value={legStore.responsibleForDispatchingId}
            data-cy='select_responsible_for_dispatching'
            onChange={(e) =>
              legStore.setResponsibleForDispatchingId(parseInt(e.target.value, 10))
            }
          />
        </Grid>
        <Grid grid={16}>
          <SelectGroup
            className='transport-leg-loading'
            label={I18n.translate('transports.create.responsible_for_loading')}
            options={personsLoading}
            multiple={true}
            value={legStore.responsibleForLoading.map((loader) => loader.toString())}
            onChange={legStore.setResponsibleForLoading}
            data-cy='select_responsible_for_loading_user'
          />
          <TextAreaGroupComponent
            className='transport-leg-loading'
            label={I18n.translate('transports.create.responsible_for_loading_external')}
            value={legStore.responsibleForLoadingExternal}
            onChange={(e) => legStore.setResponsibleForLoadingExternal(e.target.value)}
            dataCy='responsible_for_loading_external'
          />
        </Grid>
        <Grid grid={16}>
          <TextAreaGroupComponent
            label={I18n.translate('transports.create.transport_tags')}
            value={legStore.transportTags}
            onChange={(e) => legStore.setTransportTags(e.target.value)}
            dataCy='transport_tags'
          />
          <TextAreaGroupComponent
            label={I18n.translate('transports.create.observations')}
            value={legStore.observations}
            onChange={(e) => legStore.setObservations(e.target.value)}
            dataCy='transport_observations'
          />
        </Grid>
        <FileUploaderComponent />
      </FieldSet>
      {withQuestionnaire && <LegQuestionnaire />}
      <FieldSet>
        <SpaceBetween>
          <SubmitButtonsComponent
            cancelPath={cancelPath ? cancelPath : Routes.transport_path(transport.id)}
            submitText={I18n.translate('buttons.create_and_print')}
            submitPath={
              submitPath ? submitPath : Routes.transport_transport_legs_path(transport.id)
            }
            submitEnabled={legStore.readyForSubmit && fileUploaderStore.files.length >= 1}
            data-cy='create_transport_leg'
            body={formData}
          />
        </SpaceBetween>
      </FieldSet>
    </>
  );
};
export default observer(CreateTransportLeg);
