import React from 'react';

import Checkbox from 'shared/Checkbox';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import StatusText from 'shared/StatusText';
import STATUS from 'types/utils/status';
import placeholder from 'utils/placeholder';

import type { InventoryItem as InventoryItemType } from '../stores/TransportStore';
import IdentifierTableData from 'shared/Table/components/IdentifierTableData';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandle from './DragHandle';

interface InventoryItemProps {
  onCheck: () => any;
  inventory: InventoryItemType;
  checked?: boolean;
  disabled?: boolean;
  showDragHandle?: boolean;
}

function InventoryItem({
  inventory: {
    localIdentifier,
    producer,
    certification,
    conventional,
    quality,
    bags,
    weightString,
    lostCoffee,
  },
  onCheck,
  checked = false,
  disabled,
  showDragHandle = false,
}: InventoryItemProps) {
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id: localIdentifier,
  });

  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    onCheck();
  };

  let certificationString = certification;

  if (conventional) {
    certificationString += ` - ${I18n.translate('attributes.conventional')}`;
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <IdentifierTableData>{localIdentifier}</IdentifierTableData>

      <td className='table-border selected-lots__producer'>
        <TextWithSub
          width={'11em'}
          text={placeholder(producer)}
          textClass='text-with-sub__text selected-lots__producer'
          sub={placeholder(certificationString)}
        />
      </td>

      <td className='selected-lots selected-lots__quality table-border align-center'>
        {quality}
      </td>

      <td className='selected-lots selected-lots__bags table-border align-center'>
        {bags}
      </td>

      <td className='selected-lots selected-lots__weight table-border align-center'>
        {weightString}
      </td>

      <td className='selected-lots selected-lots__whitespace table-border align-center'>
        {lostCoffee && (
          <StatusText withIcon reverseOrder status={STATUS.FAILED}>
            {I18n.translate('activerecord.attributes.lot.lost')}
          </StatusText>
        )}
      </td>

      <td className='selected-lots selected-lots__checkbox table-border align-center'>
        <label className='checkbox checkbox--no-label' onClick={handleOnClick}>
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={() => false}
            dataCy='checkbox_add_to_leg'
          />
        </label>
      </td>
      {showDragHandle && (
        <td className='selected-lots' {...listeners}>
          <DragHandle />
        </td>
      )}
    </tr>
  );
}

export default InventoryItem;
