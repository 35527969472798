import { deserialise } from 'kitsu-core';
import { action, computed, observable } from 'mobx';

import SelectOptionArray from 'types/model/selectOption';
import { JsonApi } from 'types/utils/jsonApi';
import type ISample from '../types/ISample';

interface Options {
  acceptingReasons: SelectOptionArray;
  roastingResults: SelectOptionArray;
}

class OverviewTableStore {
  public samples = observable.array<ISample>();
  public selectedSamples = observable.array<number>();
  public acceptingReasons: SelectOptionArray;
  public roastingResults: SelectOptionArray;

  constructor(samples: JsonApi<ISample>, { acceptingReasons, roastingResults }: Options) {
    const deserialziedSamples = deserialise(samples).data as ISample[];

    this.samples = observable.array(deserialziedSamples);
    this.acceptingReasons = acceptingReasons;
    this.roastingResults = roastingResults;
  }

  @computed get selectedSamplesCount(): number {
    return this.selectedSamples.length;
  }

  @action public toggleSelectedLot = (sampleId: number) => {
    if (this.selectedSamples.includes(sampleId)) {
      const index = this.selectedSamples.findIndex((item) => item === sampleId);
      if (index > -1) {
        this.selectedSamples.splice(index, 1);
      }
    } else {
      this.selectedSamples.push(sampleId);
    }
  };
}

export default OverviewTableStore;
