import RemoteControlledSearch from 'components/elements/RemoteControlledSearch';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Routes from 'routes';

const SampleDestinationWarehouseSelect = (): JSX.Element => {
  const { control } = useFormContext();

  return (
    <RemoteControlledSearch
      entityName={I18n.translate('activerecord.models.destination_warehouse')}
      sourcesPath={Routes.api_v1_samples_destination_warehouses_path}
      label={I18n.translate('activerecord.models.destination_warehouse')}
      dataCy='select_destination_warehouses'
      prompt={I18n.translate('samples.create.select_destination_warehouse')}
      control={control}
      name='warehouse_sample_request[destination_warehouse_id]'
      method='GET'
      required
    />
  );
};
export default SampleDestinationWarehouseSelect;
