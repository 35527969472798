import { Provider } from 'mobx-react';
import React from 'react';

import { equalityFilter, likeFilter } from 'utils/filter';
import FilterContextProvider from 'utils/filterContext';

import CreateSet from 'sets/components/CreateSet';
import SetStore, { LotsData } from 'sets/stores/SetStore';

interface CreateSetContainerProps {
  lots: LotsData;
}

const filterConfiguration = {
  municipality: {
    filter: equalityFilter,
    value: undefined,
  },
  certification: {
    filter: equalityFilter,
    value: undefined,
  },
  local_identifier: {
    filter: likeFilter,
    value: undefined,
  },
  process_type: {
    filter: equalityFilter,
    value: undefined,
  },
  producer: {
    filter: equalityFilter,
    value: undefined,
  },
  quality: {
    filter: equalityFilter,
    value: undefined,
  },
  variety: {
    filter: equalityFilter,
    value: undefined,
  },
};

const CreateSetContainer: React.FC<CreateSetContainerProps> = ({
  lots: { data, included },
  ...props
}) => {
  return (
    <Provider setStore={new SetStore({ data, included })}>
      <FilterContextProvider value={filterConfiguration}>
        <CreateSet included={included} {...props} />
      </FilterContextProvider>
    </Provider>
  );
};
export default CreateSetContainer;
