export const isInBound = (value: number, min: number, max: number) => {
  let isValid = true;

  if (min && max) {
    isValid = value >= min && value <= max;
  }

  return isValid;
};

export const belowUpperBound = (value: number, upperBound: number) => {
  let isValid = true;

  if (value >= 0 && upperBound >= 0) {
    isValid = value < upperBound;
  } else if (!value || !upperBound) {
    isValid = false;
  }

  return isValid;
};
