import React, { useState } from 'react';

import Accordion from './Accordion';
import AccordionItemWrapper from './AccordionItemWrapper';
import HeaderWrapper from './HeaderWrapper';
import AccordionBody from './AccordionBody';

export interface AccordionHeaderProps {
  expanded: boolean;
}

export interface AccordionBodyProps {
  expanded: boolean;
}

export interface AccordionItemType {
  header: React.FC<AccordionHeaderProps>;
  body: React.FC<AccordionBodyProps>;
  isSelectedCallback?: (collapsed: boolean) => void;
  expandable?: boolean;
}

interface AccordionWrapperProps {
  accordions: AccordionItemType[];
}

const AccordionWrapper: React.FC<AccordionWrapperProps> = ({ accordions }) => {
  const [activeChildIndex, setActiveChildIndex] = useState<undefined | number>(undefined);

  const setActiveIndex = (
    index: number,
    callback?: AccordionItemType['isSelectedCallback']
  ) => {
    const indexToSet =
      activeChildIndex !== undefined
        ? activeChildIndex === index
          ? undefined
          : index
        : index;

    callback && callback(indexToSet !== undefined);
    setActiveChildIndex(indexToSet);
  };

  if (accordions.length == 0) {
    return null;
  }

  return (
    <Accordion>
      {accordions.map(
        ({ header: Header, body, isSelectedCallback, expandable }, index) => {
          let onClickHandler = () => setActiveIndex(index, isSelectedCallback);
          if (expandable == undefined) {
            expandable = true;
          }

          if (!expandable) {
            onClickHandler = () => false;
          }

          return (
            <AccordionItemWrapper
              key={`${accordions.length}_${index}_${activeChildIndex}`}
              expandable={expandable}
            >
              <HeaderWrapper onClick={onClickHandler}>
                <Header expanded={activeChildIndex === index} />
              </HeaderWrapper>

              {expandable && (
                <AccordionBody expanded={activeChildIndex === index} component={body} />
              )}
            </AccordionItemWrapper>
          );
        }
      )}
    </Accordion>
  );
};

export default AccordionWrapper;
