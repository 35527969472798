import { action, observable } from 'mobx';

class OverlayStore {
  @observable public isValid = false;
  @observable public isFilled = false;
  @observable public overrideValidation = false;
  @observable public reasonId = null as null | number;

  constructor(values?) {
    if (values) {
      this.isValid = values.valid;
      this.isFilled = values.isFilled;
      this.overrideValidation = values.overrideValidation;
      this.reasonId = values.reasonId;
    }
  }

  @action public setValid = (valid: boolean) => (this.isValid = valid);

  @action public setReasonId = (id: number | null) => (this.reasonId = id);

  @action public setFilled = (status: boolean) => (this.isFilled = status);

  @action public setOverrideValidation = (status: boolean) => {
    this.overrideValidation = status;

    if (!status) {
      this.setReasonId(null);
    }
  };
}

export default OverlayStore;
