import { observable, computed, action } from 'mobx';

import PackingListType from '../types/packingList';
import FinalizeableResource from './FinalizableResource';
import Routes from 'routes';

class PackingList extends FinalizeableResource<PackingListType> {
  @observable finalized: boolean;
  @observable seal_number?: string;

  @action protected updateAttributes({ id, finalized, seal_number }: PackingListType) {
    this.finalized = finalized;

    this.id = id;
    this.seal_number = seal_number;
  }

  @computed get isFinalizable() {
    return this.dependenciesFinalized && this.hasValidAttributeValue('seal_number');
  }

  @computed get downloadUrl() {
    return this.id ? Routes.download_api_v1_packing_list_path(this.id) : undefined;
  }
}

export default PackingList;
