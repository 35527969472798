import React from 'react';
import styled from 'styled-components';

import COLORS from 'constants/colors';
import { observer } from 'mobx-react';

import Opportunity from 'customerSamples/models/Opportunity';
import SampleEntityHeader from 'customerSamples/components/OpportunityHeader';
import SampleTable from 'customerSamples/components/SampleTable';
import SpotOpportunity from 'customerSamples/models/SpotOpportunity';
import PssInternalContract from 'customerSamples/models/PssInternalContract';

export interface SampleEntityRowProps {
  sampleEntity: Opportunity | SpotOpportunity | PssInternalContract;
}

const Wrapper = styled.div`
  border: 1px solid ${COLORS.BORDERS};
  width: 100%;
  margin-bottom: 2.5rem;
  padding: 0.625rem;
`;

function SampleEntityRow({ sampleEntity }: SampleEntityRowProps): JSX.Element {
  return (
    <Wrapper>
      <SampleEntityHeader sampleEntity={sampleEntity} />
      <SampleTable sampleEntity={sampleEntity} />
    </Wrapper>
  );
}

export default observer(SampleEntityRow);
