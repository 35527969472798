import classnames from 'classnames';
import React, { useRef, useEffect } from 'react';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';

interface ScoreInputProps {
  sampleId: number;
  value?: number | null;
  completed?: boolean;
  small?: boolean;
  cuppingSessionEnabled?: boolean;
  onChange: (score: number) => void;
  onFetch: (state: boolean) => void;
  quality: string;
  setQuality: (quality: string) => void;
  markAsReject?: boolean;
}

let delayTimer: number;
const ScoreInput = ({
  sampleId,
  value,
  completed = false,
  small = false,
  cuppingSessionEnabled = false,
  onChange,
  onFetch,
  quality,
  setQuality,
  markAsReject,
}: ScoreInputProps) => {
  const inputRef = useRef(null);
  const setInitialCheckValue = () => {
    if (markAsReject === null) return;
    if (value && quality) {
      onFetch(markAsReject || false);
    }
  };
  useEffect(() => {
    setInitialCheckValue();
  }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let score = parseFloat(e.target.value);

    if (score > 100) {
      score = 100;
    } else if (score <= 0) {
      score = 1;
    }

    onChange(score);
    checkScore(score);
  };

  const checkScore = (score) => {
    if (score) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        customFetch(
          Routes.quality_sensorial_analyses_path({
            final_score: score,
            sample_id: sampleId,
          }),
          undefined,
          'GET'
        ).then((response) => {
          setQuality(response.quality);
          onFetch(response.mark_as_reject);
        });
      }, 500);
    }
  };

  if (small) {
    return (
      <span className='cupping-card__result'>
        <div className='cupping-card__score input-group input-group--inline input-group--condensed'>
          <label htmlFor={`samples[${sampleId}][final_score]`}>
            {I18n.translate('sensorial_analysis.final_score')}
          </label>
          <input
            type='number'
            inputMode='decimal'
            pattern='[0-9]+([.,][0-9][0-9]?)?'
            id={`samples[${sampleId}][final_score]`}
            required={!cuppingSessionEnabled}
            value={value || ''}
            name={`samples[${sampleId}][final_score]`}
            onChange={(e) => handleInputChange(e)}
            className='input--condensed'
            placeholder=' '
            data-cy='final_score'
          />
        </div>
        <span className='cupping-card__grade' data-cy='grade'>
          {quality}
        </span>
      </span>
    );
  }

  return (
    <div
      className={classnames(
        'sensorial-analyses__score-group',
        completed && 'sensorial-analyses__score-group--disabled'
      )}
    >
      <div className='input-group'>
        <label htmlFor={`samples[${sampleId}][final_score]`}>
          {I18n.translate('sensorial_analysis.final_score')}
        </label>
        <input
          type='number'
          inputMode='decimal'
          pattern='[0-9]+([.,][0-9][0-9]?)?'
          id={`samples[${sampleId}][final_score]`}
          ref={inputRef}
          value={value || ''}
          name={`samples[${sampleId}][final_score]`}
          onChange={(e) => handleInputChange(e)}
          readOnly={completed}
          data-cy='final_score'
        />
      </div>

      <div className='input-group'>
        <label htmlFor={`sample_${sampleId}_quality`}>
          {I18n.translate('sensorial_analysis.quality')}
        </label>
        <input
          type='text'
          id={`sample_${sampleId}_quality`}
          readOnly
          value={quality}
          data-cy='grade'
        />
      </div>
    </div>
  );
};

export default ScoreInput;
