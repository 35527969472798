import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Checkbox from 'shared/Checkbox';
import CheckboxGroup from '../models/CheckboxGroup';

interface DefectInputProps {
  sampleId: number | string;
  twoColumns?: boolean;
  threeColumns?: boolean;
  useInOverlay?: boolean;
  completed?: boolean;
  defects: CheckboxGroup<any>;
}

const DefectInput = ({
  sampleId,
  twoColumns,
  threeColumns,
  useInOverlay,
  completed = false,
  defects,
}: DefectInputProps) => {
  return (
    <div
      className={classNames('check-group', useInOverlay && 'check-group-list--overlay')}
    >
      <div
        className={classNames('check-group-list', {
          'check-group-list--overlay': useInOverlay,
          'check-group-list--three-columns': threeColumns,
          'check-group-list--two-columns': twoColumns,
        })}
      >
        {defects.checkboxes.map((defect) => (
          <Checkbox
            key={defect.id}
            label={defect.name}
            icon={'cross'}
            id={`sample_${sampleId}_defect_id_${defect.id}]`}
            name={`samples[${sampleId}][defect_ids][]`}
            value={`${defect.id}`}
            onChange={() =>
              !defect.selected
                ? defects.selectCheckbox(defect)
                : defects.deselectCheckbox(defect)
            }
            readOnly={completed}
            checked={defect.selected}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(DefectInput);
