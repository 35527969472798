import React from 'react';
import { observer } from 'mobx-react';
import Routes from 'routes';

import useStores from 'utils/useStores';

import styled from 'styled-components';

import BorderBox from 'components/elements/BorderBox';
import Checkbox from 'shared/Checkbox';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';
import debounce from 'lodash.debounce';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.4375em 0;

  & > * {
    margin: 0 0.75em;
  }
`;

const YearSelect = styled.select`
  width: auto;
  padding-right: 3em;
`;

const ContractSearch = styled.input`
  width: 15em;
  padding: 0px 10px;
  margin-right: auto;
`;

const TableHeadBox = () => {
  const { futuresStore } = useStores();
  const debounceContractSearch = debounce((e) => {
    futuresStore.execFilter({ mainIdentifier: e.target.value });
  }, 3000);

  const handleContractSearch = (e) => {
    e.persist();
    debounceContractSearch(e);
  };
  return (
    <BorderBox>
      <Content>
        <ContractSearch
          placeholder={I18n.translate('opportunities.index.find_by_contract_id')}
          defaultValue={futuresStore.filter.mainIdentifier}
          onChange={handleContractSearch}
        ></ContractSearch>
        <YearSelect
          value={futuresStore.filter.year}
          onChange={(e) =>
            futuresStore.execFilter({ year: parseInt(e.target.value, 10) })
          }
        >
          <option value=''>{I18n.translate('future_price_fixing.year')}</option>
          {futuresStore.years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </YearSelect>

        <Checkbox
          checked={!!futuresStore.filter.realised}
          label={I18n.translate('future_price_fixing.realised')}
          icon='tick'
          onChange={(checked) => futuresStore.execFilter({ realised: checked })}
        />

        <form action={Routes.bundles_path()} method='post'>
          <AuthenticityToken />
          <input
            type='hidden'
            name='futures_contract_date'
            value={futuresStore.currentFuturePosition}
          />

          {Array.from(futuresStore.selectedContracts).map((contractId) => (
            <input
              key={contractId}
              type='hidden'
              value={contractId}
              name='contract_ids[]'
            />
          ))}

          <button
            className='button button--green'
            disabled={futuresStore.selectedContracts.size === 0}
            type='submit'
          >
            {I18n.translate('future_price_fixing.bundle')}
          </button>
        </form>
      </Content>
    </BorderBox>
  );
};

export default observer(TableHeadBox);
