import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';

import { observer } from 'mobx-react';

import Ico from 'customerSamples/models/Ico';
import SampleRow from './SampleRow';

export interface IcoRowProps {
  ico: Ico;
}

const IcoHeaderRow = styled.td`
  background: ${COLORS.WHITE} !important;
  color: ${COLORS.BLACK};
  font-weight: bold;
`;

function IcoRow({ ico }: IcoRowProps): JSX.Element | null {
  return ico.samples && ico.samples.length > 0 ? (
    <>
      <tr>
        <IcoHeaderRow colSpan={8}>{`ICO-${ico.mainIdentifier}`}</IcoHeaderRow>
      </tr>
      {ico.samples?.map((sample) => (
        <SampleRow key={sample.id} internalSample={sample} customerSample={sample} />
      ))}
    </>
  ) : null;
}

export default observer(IcoRow);
