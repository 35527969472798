export enum SAMPLE_TYPE {
  SAMPLE_PURCHASE = 'sample_purchase',
  SAMPLE_LAB = 'sample_lab',
  SAMPLE_OFFER = 'sample_offer',
  SAMPLE_TYPE = 'sample_type',
  SAMPLE_EXTERNAL = 'sample_external',
  SAMPLE_RANDOM = 'sample_random',
  SAMPLE_MIX = 'sample_mix',
  SAMPLE_AUDIT = 'sample_audit',
  SAMPLE_PRE_PRODUCTION = 'sample_pre_production',
  SAMPLE_PRE_SHIPMENT = 'sample_pre_shipment',
  ARRIVAL = 'sample_arrival',
  SPOT = 'sample_spot',
}

interface ISample {
  id: number;
  sample_type: SAMPLE_TYPE;
  created_at: string;
  initial_weight: number;
  weight: number;
  grade: number;
  considered_for_cupping?: boolean;
  local_identifier: string;
  main_identifier: string;
  global_identifier: string;
  frontend_identifier: string;
  identifier_info: string;
}

export default ISample;
