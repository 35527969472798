export interface IEntity<T extends string> {
  model: T;
  id: string;
}

export function decodeEntityString(str: string) {
  const [model, id] = str.split('>>');

  return {
    model,
    id,
  };
}
export const encodeEntityString = (model: string, id: string) => [model, id].join('>>');
