export const formatExportDate = (dateString?: string) => {
  if (dateString) {
    const dateParts = dateString?.split('-').map((part) => parseInt(part, 10));

    return `${padZeros(dateParts[1])}/${padZeros(dateParts[2])}`;
  } else {
    return '--/--';
  }
};

const padZeros = function (s: string | number, size = 2) {
  s = s.toString();
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
};

export const calculateMonthName = (date: Date) => {
  const formatter = new Intl.DateTimeFormat('default', {
    month: 'short',
    timeZone: 'UTC',
  });
  return `${formatter.format(date)}-${date.getUTCFullYear().toString().slice(2)}`;
};
