import ControlledSearch from 'components/elements/ControlledSearch';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useSpotSampleIcos from 'samples/create/components/spot_sample/useSpotSampleIcos';
import WSRSpotSampleInputs from './spot_sample/WSRSpotSampleInputs';

import IcoInfos from './spot_sample/IcoInfos';

function CreateSpotSample(): JSX.Element {
  const { control, watch, register } = useFormContext();

  // data and logic for selecting icos from primary dropdown
  // api call for ICOs
  const { icos, icoOptions } = useSpotSampleIcos();

  const watchIcoId = watch('sample[ico_id]');

  const selectedIco = useMemo(
    () => icos.find((ico) => ico.id == watchIcoId),
    [watchIcoId]
  );

  return (
    <>
      <input
        type='hidden'
        value='sample_spot'
        name='spot_sample[sample_type]'
        ref={register}
      />
      <div className='l-item-stack-horizontal l-item-stack-horizontal--wide'>
        <ControlledSearch
          options={icoOptions}
          label={I18n.translate('activerecord.models.ico')}
          dataCy='select_ico_id'
          prompt={I18n.translate('samples.create.select_ico')}
          control={control}
          name='sample[ico_id]'
          required
        />
      </div>
      {selectedIco && (
        <>
          <IcoInfos ico={selectedIco} />
          <WSRSpotSampleInputs ico={selectedIco} />
        </>
      )}
    </>
  );
}
export default CreateSpotSample;
