import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Routes from 'routes';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import COLORS from 'constants/colors';

import Reprocess from 'productionOrders/models/Reprocess';
import Fraction from 'productionOrders/models/Fraction';
import useStores from 'utils/useStores';

import StatusText from 'shared/StatusText';
import STATUS from 'types/utils/status';
import HumidityAnalysis from 'shared/HumidityAnalysis';
import Input from 'components/elements/Input';

export interface ReprocessRowComponentProps {
  reprocess: Reprocess;
  fraction: Fraction;
}

const InputGroupWrapper = styled(InputGroupComponent)`
  max-width: 7.5rem;
  padding: 0.3125em 0.5em !important;
  height: 2.25rem !important;
  text-align: right;
`;

const SelectGroupWrapper = styled(SelectGroup)`
  max-width: 10rem;
  padding: 0.3125em 0.5em !important;
  height: 2.25rem !important;
  text-align: right;
`;

const OutWrapper = styled.div`
  position: relative;
  padding-left: 3rem;

  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    left: 0;
    top: 0;
    background: ${COLORS.BLACK};
  }

  &:after {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 2px;
    left: 2px;
    top: 50%;
    transform: translateY(50% - 2px);
    background: ${COLORS.BLACK};
  }
`;

export const ReprocessRowComponent = ({
  reprocess,
  fraction,
}: ReprocessRowComponentProps) => {
  const { fractionsStore } = useStores();

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => reprocess.setMillingMachineId(event.target.value);

  const {
    current_humidity_analysis,
    current_qa_physical_analysis,
    id,
    local_identifier,
  } = reprocess.sample;

  const renderHumidityAnalysis = () => {
    const handleOnPrimaryClick = (event, values) => {
      current_humidity_analysis.synchronize(values);
    };

    return (
      <HumidityAnalysis
        identifier={local_identifier}
        onPrimaryClick={handleOnPrimaryClick}
        humidityAnalysis={
          current_humidity_analysis.id ? current_humidity_analysis : undefined
        }
        lotFailed={false}
        validations={current_humidity_analysis.validations}
        text={current_humidity_analysis.text}
        status={current_humidity_analysis.status}
        icon={current_humidity_analysis.icon}
        reasons={fractionsStore.humidityAnalysisAcceptingReasons}
        disabled={!reprocess.hasWeight}
      />
    );
  };

  const renderPhysicalAnalysis = () => {
    const status =
      current_qa_physical_analysis.state !== 'failed'
        ? !current_qa_physical_analysis.exceedsNorm
          ? STATUS.OK
          : STATUS.FAILED
        : STATUS.FAILED;

    const classes = classNames('button button--small', {
      'button--ghost': reprocess.hasWeight,
      'button--disabled': !reprocess.hasWeight,
    });

    return (
      <>
        {current_qa_physical_analysis.id ? (
          <a
            href={Routes.edit_milling_order_sample_excelso_physical_analysis_path(
              fraction.milling_order_id,
              id,
              current_qa_physical_analysis.id || 0
            )}
          >
            <StatusText status={status} reverseOrder={true}>
              {current_qa_physical_analysis.fullDefectsString}
            </StatusText>
          </a>
        ) : (
          <a
            href={Routes.new_milling_order_sample_excelso_physical_analysis_path(
              fraction.milling_order_id,
              id
            )}
            className={classes}
          >
            {I18n.translate('fractions.analyse')}
          </a>
        )}
      </>
    );
  };

  return (
    <>
      <tr>
        <td></td>

        <td>
          <OutWrapper>{I18n.translate('fractions.out')}</OutWrapper>
        </td>

        <td>
          <InputGroupWrapper
            name='weight'
            append='kg'
            placeholder=''
            onChange={(e) =>
              reprocess.setWeight(e.target.value ? parseInt(e.target.value) : 0)
            }
            onBlur={reprocess.synchronize}
            value={reprocess.weight ? reprocess.weight : ''}
            noMargin={true}
            type='number'
            max={reprocess.initial}
            min={0}
            disabled={reprocess.isFinished}
          />
        </td>

        <td colSpan={1}>
          <SelectGroupWrapper
            name='milling_machine'
            onChange={handleOnChange}
            onBlur={reprocess.synchronize}
            noMargin={true}
            options={fractionsStore.machines}
            value={reprocess.millingMachineId ? reprocess.millingMachineId : ''}
            placeholder={I18n.translate('fractions.select_machine')}
          />
        </td>

        <td>
          <Input readOnly value={reprocess.totalLoss} />
        </td>

        <td colSpan={3} className='border-right-black'></td>

        <td className='align-center'>{renderHumidityAnalysis()}</td>

        <td className='align-center'>{renderPhysicalAnalysis()}</td>
      </tr>
    </>
  );
};

export default observer(ReprocessRowComponent);
