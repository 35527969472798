import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormFieldSet from '../FormFieldSet';
import Grid from 'components/elements/Grid';
import SelectGroup from 'components/groups/SelectGroup';
import ContainerType from 'exports/types/containerType';
import InputGroup from 'components/groups/InputGroup';
import transformStringToNumber from 'utils/transformStringToNumber';

export interface ContainerSelectionProps {
  disabled: boolean;
  containerOptions?: ContainerType[];
}

function ContainerSelection({
  disabled,
  containerOptions = [],
}: ContainerSelectionProps) {
  const { register, watch } = useFormContext();

  const containerSize = React.useMemo(
    () =>
      containerOptions.find(({ id }) => id === watch('container_type_id'))?.max_weight ||
      '',
    [watch('container_type_id')]
  );

  return (
    <FormFieldSet disabled={disabled}>
      <Grid grid={16}>
        <SelectGroup
          label={I18n.translate('exports.form.shipment.container_size_label')}
          placeholder={I18n.translate('exports.form.shipment.container_size_placeholder')}
          options={containerOptions}
          ref={register({ setValueAs: transformStringToNumber })}
          name='container_type_id'
        />

        <InputGroup
          id='container_max_weight'
          label={I18n.translate('exports.form.shipment.container_max_weight_label')}
          placeholder={I18n.translate(
            'exports.form.shipment.container_max_weight_placeholder'
          )}
          disabled={true}
          value={containerSize}
          append='kg'
        />
      </Grid>
    </FormFieldSet>
  );
}

export default ContainerSelection;
