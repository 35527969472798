import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import Grid from 'components/elements/Grid';
import InputGroup from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import COLORS from 'constants/colors';
import FormFieldSet from 'exports/components/FormFieldSet';
import ShipmentInstruction from 'exports/models/ShipmentInstruction';
import transformStringToNumber from 'utils/transformStringToNumber';
import useStores from 'utils/useStores';
import AccordionFormBody from '../AccordionFormBody';
import ContainerSelection from './ContainerSelection';
import type ShipmentInstructionType from '../../types/shipmentInstruction';

export interface ShipmentInstructionsProps {
  shipmentInstruction: ShipmentInstruction;
}

const ShipmentInstructionsWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

function ShipmentInstructions({
  shipmentInstruction,
}: ShipmentInstructionsProps): JSX.Element {
  const {
    exportsStore: {
      customsOfficers,
      consignees,
      insurers,
      shippingLines,
      shippingModes,
      freights,
      insurances,
      naturesOfOperation,
      paymentForms,
      blPrintInstructions,
      selectedExport,
    },
  } = useStores();

  const formMethods = useForm({
    defaultValues: shipmentInstruction,
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
    watch,
  } = formMethods;

  const secondInsurers = useMemo(
    () => insurers.filter(({ id }) => id !== watch('notification_id')),
    [watch('notification_id')]
  );

  useEffect(() => {
    selectedExport?.fetchShippingLineContract(watch('shipping_line_id'));
  }, [watch('shipping_line_id')]);

  const handleOnSubmitClick = handleSubmit(async (formData: ShipmentInstructionType) => {
    await shipmentInstruction.save(formData);

    reset(formData);
  });

  const handleOnFinalizeClick = handleSubmit(async () => {
    await shipmentInstruction.finalize();
  });

  return (
    <FormProvider {...formMethods}>
      <ShipmentInstructionsWrapper>
        <AccordionFormBody
          isFinalizable={shipmentInstruction.isValid && shipmentInstruction.isFinalizable}
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          finalized={shipmentInstruction.finalized}
          onSubmitClick={handleOnSubmitClick}
          onFinalizeClick={handleOnFinalizeClick}
          downloadUrl={shipmentInstruction.downloadUrl}
        >
          <FormFieldSet disabled={shipmentInstruction.finalized}>
            <Grid grid={16}>
              <SelectGroup
                label={I18n.translate('exports.form.shipment.customs_officer_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.customs_officer_placeholder'
                )}
                options={customsOfficers}
                ref={register({ setValueAs: transformStringToNumber })}
                name='customs_officer_id'
              />

              <SelectGroup
                label={I18n.translate('exports.form.shipment.consignee_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.consignee_placeholder'
                )}
                options={consignees}
                ref={register({ setValueAs: transformStringToNumber })}
                name='consignee_id'
              />

              <SelectGroup
                label={I18n.translate('exports.form.shipment.notification_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.notification_placeholder'
                )}
                options={insurers}
                ref={register({ setValueAs: transformStringToNumber })}
                name='notification_id'
              />
              <SelectGroup
                label={I18n.translate('exports.form.shipment.second_notification_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.notification_placeholder'
                )}
                options={secondInsurers}
                ref={register({ setValueAs: transformStringToNumber })}
                name='second_notification_id'
              />
            </Grid>
          </FormFieldSet>

          <FormFieldSet disabled={shipmentInstruction.finalized}>
            <Grid grid={16}>
              <SelectGroup
                label={I18n.translate('exports.form.shipment.shipping_line_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.shipping_line_placeholder'
                )}
                options={shippingLines}
                ref={register({ setValueAs: transformStringToNumber })}
                name='shipping_line_id'
              />

              <SelectGroup
                label={I18n.translate('exports.form.shipment.shipping_mode_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.shipping_mode_placeholder'
                )}
                options={shippingModes}
                ref={register({ setValueAs: transformStringToNumber })}
                name='shipping_mode_id'
              />
              <InputGroup
                id='service_contract'
                label={I18n.translate('exports.form.shipment.service_contract_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.service_contract_placeholder'
                )}
                value={selectedExport?.shippingLineContract?.contract_identifier || ''}
                disabled={true}
              />
              <input
                type='hidden'
                value={selectedExport?.shippingLineContract?.id || ''}
                name='shipping_line_contract_id'
                ref={register}
              />

              <InputGroup
                id='contract_expiry'
                label={I18n.translate('exports.form.shipment.contract_expiry_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.contract_expiry_placeholder'
                )}
                value={selectedExport?.shippingLineContract?.end_date || ''}
                disabled={true}
              />
            </Grid>
          </FormFieldSet>

          <FormFieldSet disabled={shipmentInstruction.finalized}>
            <Grid grid={16}>
              <InputGroup
                id='voyage_number'
                label={I18n.translate('exports.form.shipment.voyage_number_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.voyage_number_placeholder'
                )}
                ref={register}
                name='voyage_number'
              />

              <InputGroup
                id='vessel_name'
                label={I18n.translate('exports.form.shipment.vessel_name_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.vessel_name_placeholder'
                )}
                ref={register}
                name='vessel_name'
              />

              <InputGroup
                id='reservation_number'
                label={I18n.translate('exports.form.shipment.reservation_number_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.reservation_number_placeholder'
                )}
                ref={register}
                name='reservation_number'
              />
            </Grid>
          </FormFieldSet>

          <ContainerSelection
            disabled={shipmentInstruction.finalized}
            containerOptions={selectedExport?.availableContainerTypes}
          />

          <FormFieldSet disabled={shipmentInstruction.finalized}>
            <Grid grid={16}>
              <SelectGroup
                label={I18n.translate('exports.form.shipment.insurance_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.insurance_placeholder'
                )}
                options={insurances}
                ref={register}
                name='insurance_id'
              />

              <SelectGroup
                label={I18n.translate('exports.form.shipment.freight_label')}
                placeholder={I18n.translate('exports.form.shipment.freight_placeholder')}
                options={freights}
                ref={register({ setValueAs: transformStringToNumber })}
                name='freight_id'
              />

              <SelectGroup
                label={I18n.translate('exports.form.shipment.nature_operation_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.nature_operation_placeholder'
                )}
                options={naturesOfOperation}
                ref={register}
                name='nature_of_operation_id'
              />

              <SelectGroup
                label={I18n.translate('exports.form.shipment.payment_form_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.payment_form_placeholder'
                )}
                options={paymentForms}
                ref={register}
                name='payment_form_id'
              />
            </Grid>
          </FormFieldSet>

          <FormFieldSet disabled={shipmentInstruction.finalized}>
            <Grid grid={16}>
              <SelectGroup
                label={I18n.translate(
                  'exports.form.shipment.bl_print_instructions_label'
                )}
                placeholder={I18n.translate(
                  'exports.form.shipment.bl_print_instructions_placeholder'
                )}
                options={blPrintInstructions}
                ref={register}
                name='bill_of_lading_print_instruction'
              />
            </Grid>
          </FormFieldSet>

          <FormFieldSet disabled={shipmentInstruction.finalized}>
            <Grid grid={24}>
              <InputGroup
                id='observations'
                label={I18n.translate('exports.form.shipment.observations_label')}
                placeholder={I18n.translate(
                  'exports.form.shipment.observations_placeholder'
                )}
                ref={register}
                name='observations'
              />
            </Grid>
          </FormFieldSet>
        </AccordionFormBody>
      </ShipmentInstructionsWrapper>
    </FormProvider>
  );
}

export default observer(ShipmentInstructions);
