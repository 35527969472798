import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import Checkbox from './Checkbox';

import useStores from 'utils/useStores';
import SelectOptionArray from 'types/model/selectOption';
import SelectGroup from 'components/groups/SelectGroup';

export interface ReasonCheckboxProps {
  name: string;
  icon: string;
  label: string;
  reasons: SelectOptionArray;
  fieldName?: string;
  dataCy?: string;
}

function ReasonCheckbox({
  name,
  icon,
  label,
  reasons,
  dataCy,
  fieldName = 'accepting_reason_id',
}: ReasonCheckboxProps): JSX.Element {
  const { overlayStore } = useStores();

  useEffect(() => {
    if (!overlayStore.overrideValidation) {
      overlayStore.setReasonId(null);
    }
  }, [overlayStore.overrideValidation]);

  const handleCheckboxChange = () => {
    overlayStore.setOverrideValidation(!overlayStore.overrideValidation);

    if (overlayStore.overrideValidation && !overlayStore.reasonId) {
      overlayStore.setReasonId(reasons[0].id as number);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;

    overlayStore.setReasonId(parseInt(id, 10));
  };

  return (
    <div style={{ marginRight: 'auto' }}>
      <Checkbox
        dataCy={dataCy}
        name={name}
        icon={icon}
        checked={
          !overlayStore.isValid &&
          overlayStore.isFilled &&
          !overlayStore.overrideValidation
        }
        label={label}
        disabled={!overlayStore.isFilled || overlayStore.isValid}
        onChange={handleCheckboxChange}
      />
      {overlayStore.overrideValidation && (
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))' }}
        >
          <SelectGroup
            label={I18n.translate('sensorial_analysis.reason_for_proceeding')}
            options={reasons}
            onChange={handleSelectChange}
            value={overlayStore.reasonId?.toString()}
            name={fieldName}
            id={fieldName}
            noMargin
          />
        </div>
      )}
    </div>
  );
}

export default observer(ReasonCheckbox);
