import React from 'react';
import styled from 'styled-components';

import { TileList } from 'shared/Tile';

import type { LotTraceability, LotSetTraceability } from '../types/traceability';

import Detail, { IdentifierDetail } from './Detail';
import { TraceabilityDetails } from './Details';
import LotTileList from './LotTileList';
import ISample from 'samples/types/ISample';

const SampleTileList = styled(TileList)`
  margin: 0.42em 0;
`;

interface TraceabilityBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  traceability: LotTraceability | LotSetTraceability;
  collapsed: boolean;
  sample?: ISample;
}

function TraceabilityBody({
  traceability,
  traceability: { local_identifier },
  className,
  sample,
}: TraceabilityBodyProps): JSX.Element {
  return (
    <div className={className}>
      <SampleTileList>
        <TraceabilityDetails>
          <div></div>

          <IdentifierDetail>{sample?.local_identifier}</IdentifierDetail>

          <div></div>

          <Detail align='right'>{sample?.weight_string}</Detail>
        </TraceabilityDetails>

        <div></div>

        <div></div>
      </SampleTileList>

      {traceability.type === 'set' &&
        traceability.traceabilities.map((lot) => (
          <LotTileList
            key={lot.local_identifier}
            lot={lot}
            lotSetIdentifier={local_identifier}
          />
        ))}
    </div>
  );
}

export default styled(TraceabilityBody)`
  margin-bottom: ${(props) => (!props.collapsed ? '2.5em' : 0)};
`;
