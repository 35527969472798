import classnames from 'classnames';
import React from 'react';

interface ObservationInputProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  sampleId: number | string;
  completed?: boolean;
}

const ObservationInput = ({
  sampleId,
  completed = false,
  ...textareaProps
}: ObservationInputProps) => {
  return (
    <div
      className={classnames(
        'input-group',
        'sensorial-analyses__observation',
        completed && 'sensorial-analyses__observation--disabled'
      )}
    >
      <textarea
        data-cy='sensorial_analyses_observations'
        id={`samples[${sampleId}][observation]`}
        name={`samples[${sampleId}][observation]`}
        readOnly={completed}
        className={completed ? 'input-disabled' : ''}
        {...textareaProps}
      />
    </div>
  );
};

export default ObservationInput;
