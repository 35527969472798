import { Provider } from 'mobx-react';
import React from 'react';

import CustomerForm from 'customers/components/CustomerForm';
import CustomerType from 'types/model/customer';
import CustomerStore from 'customers/stores/CustomerStore';
import { ReasonGet } from 'types/model/reason';
import LeadType from 'types/model/lead';
import { IField } from 'components/shared/form/IField';
import SelectOptionArray from 'types/model/selectOption';
import SelectCompanyOptionArray from 'customers/types/SelectMarketTypeOption';
import { JsonApi } from 'types/utils/jsonApi';

export interface CustomerFormContainerProps {
  customer: JsonApi<CustomerType>;
  countries: JsonApi<ReasonGet[]>;
  paymentTerms: JsonApi<ReasonGet[]>;
  arbitrations: JsonApi<ReasonGet[]>;
  currencies: JsonApi<SelectOptionArray>;
  qualifications: JsonApi<ReasonGet[]>;
  unitsOfMeasurement: ReasonGet[];
  leadSources: JsonApi<ReasonGet[]>;
  customerOwners: JsonApi<ReasonGet[]>;
  leads: LeadType[];
  selectedLeadId: number;
  customerWithLeadPath: string;
  fields: IField[];
  view: boolean;
  cancelPath: string;
  saveButtonText: string;
  companySizes: JsonApi<SelectCompanyOptionArray>;
  marketTypes: JsonApi<SelectCompanyOptionArray>;
}

const CustomerFormContainer: React.FC<CustomerFormContainerProps> = ({
  customer,
  countries,
  paymentTerms,
  arbitrations,
  currencies,
  qualifications,
  unitsOfMeasurement,
  leadSources,
  customerOwners,
  leads,
  selectedLeadId,
  customerWithLeadPath,
  fields,
  view,
  cancelPath,
  saveButtonText,
  companySizes,
  marketTypes,
}) => (
  <Provider
    customerStore={
      new CustomerStore(
        customer,
        countries,
        paymentTerms,
        arbitrations,
        currencies,
        qualifications,
        unitsOfMeasurement,
        leadSources,
        customerOwners,
        leads,
        selectedLeadId,
        customerWithLeadPath,
        cancelPath,
        saveButtonText,
        companySizes,
        marketTypes
      )
    }
  >
    <CustomerForm view={view} fields={fields} />
  </Provider>
);

export default CustomerFormContainer;
