import React, { useEffect, useState } from 'react';

import Checkbox from 'shared/Checkbox';
import { LOTS_COFFEE_TYPE_CHANGE, LotCoffeeTypeDetail } from './CoffeeTypeSelect';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';

export interface PrepaymentProps {
  initialShow: boolean;
  hasPrepayment: boolean;
  hasWetCoffeeFinalPrice: string;
}

function Prepayment({
  initialShow,
  hasPrepayment,
  hasWetCoffeeFinalPrice,
}: PrepaymentProps): JSX.Element | null {
  const [showPayment, setShowPayment] = useState(initialShow);
  const [wetCoffeeFinalPrice, setValue] = useState(hasWetCoffeeFinalPrice || '');
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    window.ReactEventBus.on<LotCoffeeTypeDetail>(
      LOTS_COFFEE_TYPE_CHANGE,
      ({ detail }) => {
        setShowPayment(detail.coffeeType.isGreen);
      }
    );
  }, []);

  return showPayment ? (
    <FieldSetWithTitle title={I18n.translate('attributes.payment')}>
      <Grid>
        <input type='hidden' name='lot[has_prepayment]' value='' />

        <Checkbox
          checked={hasPrepayment}
          name='lot[has_prepayment]'
          id='lot_prepayment'
          data-cy='lot-prepayment'
          label={I18n.translate('attributes.prepayment')}
          value='has_prepayment'
        />

        <InputGroupComponent
          label={I18n.translate('attributes.price')}
          step={0.01}
          variant={VARIANT.DECIMAL}
          value={wetCoffeeFinalPrice}
          onChange={handleOnChange}
          name='lot[wet_coffee_final_price]'
          id='lot_wet_coffee_final_price'
          append={'US$'}
          min={0}
          required={true}
        />
      </Grid>
    </FieldSetWithTitle>
  ) : null;
}

export default Prepayment;
