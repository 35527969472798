import { customFetch, methodInterface } from 'utils/fetch';
import { deserialise } from 'kitsu-core';
import { JsonApi } from 'types/utils/jsonApi';
import Export from 'exports/models/Export';
import * as API from 'exports/types/api';

export interface FinalizableService<T> {
  create(payload: T): Promise<T>;
  update(id: number, payload: T): Promise<T>;
  finalize(id: number): Promise<Required<T>>;
}

export interface FinalizableResponse {
  export: JsonApi<API.IExport>;
  finalizableResource: JsonApi;
}

class FinalizeService<T extends {}> implements FinalizableService<T> {
  constructor(
    private export_: Export,
    private createRoute: (id: number) => string,
    private updateRoute: (id: number) => string,
    private finalizeRoute: (id: number) => string
  ) {}

  public async create(payload: T): Promise<T> {
    const fetchConfig = {
      path: this.createRoute(this.export_.id),
      method: 'POST' as keyof typeof methodInterface,
    };

    const response: FinalizableResponse = await customFetch(
      fetchConfig.path,
      payload,
      fetchConfig.method
    );

    this.updateExport(response.export);

    return deserialise(response.finalizableResource).data as T;
  }

  public async update(id: number, payload: T): Promise<T> {
    const fetchConfig = {
      path: this.updateRoute(id),
      method: 'PATCH' as keyof typeof methodInterface,
    };

    const response: FinalizableResponse = await customFetch(
      fetchConfig.path,
      payload,
      fetchConfig.method
    );

    this.updateExport(response.export);

    return deserialise(response.finalizableResource).data as T;
  }

  public async finalize(id: number): Promise<Required<T>> {
    const fetchConfig = {
      path: this.finalizeRoute(id),
      method: 'POST' as keyof typeof methodInterface,
    };

    const response: FinalizableResponse = await customFetch(
      fetchConfig.path,
      undefined,
      fetchConfig.method
    );

    this.updateExport(response.export);

    return deserialise(response.finalizableResource).data as Required<T>;
  }

  private updateExport(exportData: JsonApi<API.IExport>): void {
    const deserializedExport: API.IExport = deserialise(exportData).data;

    this.export_.updateAttributes(deserializedExport);
  }
}

export default FinalizeService;
