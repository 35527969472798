import styled from 'styled-components';

import { COLORS } from 'constants/colors';

export interface FieldSetProps extends React.InputHTMLAttributes<HTMLFieldSetElement> {
  width?: number | string;
  fetching?: boolean;
}

const FieldSet = styled.fieldset.attrs<FieldSetProps>((props) => ({
  disabled: props.fetching ? true : props.disabled || false,
  width: props.width ? props.width : '46em',
}))<FieldSetProps>`
  display: flex;
  flex-wrap: wrap;
  padding: 1.25em 0 1.5em;
  max-width: ${(props) =>
    props.width && !isNaN(props.width as number) ? `${props.width}em` : props.width};
  margin: 0 -0.875em;
  position: relative;
  border: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0.875em;
    right: 0.875em;
    height: 1px;
    background: ${COLORS.BORDERS};
  }

  > * {
    margin: 0 0.875em;
    width: 100%;
  }

  > div:first-child {
    flex: 0 0 12em;
    margin-bottom: 1em;

    legend {
      font-size: 1em;
      font-weight: 700;
      margin-bottom: 0.2778em;
      padding: 0;
    }
  }

  > div:last-child {
    flex-grow: 1;
    flex-basis: 12em;
  }

  ${(props) =>
    props.fetching
      ? `
    &:after {
      content: '';
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="rgba(177,42,49,1)"><path d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z" opacity=".2"/><path d="M26.013 10.047l1.654-2.866a14.855 14.855 0 00-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z"><animateTransform attributeName="transform" attributeType="xml" dur="0.5s" from="0 20 20" repeatCount="indefinite" to="360 20 20" type="rotate"/></path></svg>');
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3em;
      height: 3em;
    }`
      : ''}
`;

export default FieldSet;
