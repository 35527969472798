import { deserialise } from 'kitsu-core';
import { Provider } from 'mobx-react';
import React from 'react';

import OverlayStore from 'stores/OverlayStore';
import Grade from 'types/model/grade';
import { SAMPLE_TYPE } from 'types/model/ISample';

import AnalyseAndAdjust from 'samples/analyse_and_adjust/components/AnalyseAndAdjust';
import AnalyseAndAdjustStore from 'samples/analyse_and_adjust/stores/AnalyseAndAdjustStore';
import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';
import SelectOptionArray from 'types/model/selectOption';
import { JsonApi } from 'types/utils/jsonApi';
import { Pagy } from 'shared/Pagynation';
import ISample from 'samples/types/ISample';

interface AnalyseAndAdjustContainerProps {
  pagy: Pagy;
  samples: JsonApi<ISample>;
  sampleTypes: SelectOptionArray;
  currentSampleType: SAMPLE_TYPE;
  currentLocalIdentifier: string;
  currentMark: string;
  currentOrigin: string;
  currentQuality: string;
  marks: SelectOptionArray;
  qualities: SelectOptionArray;
  origins: SelectOptionArray;
  humidityAcceptingReasons: SelectOptionArray;
  roastingResults: SelectOptionArray;
  grades: JsonApi<Grade>;
  cuppingSessionEnabled: boolean;
}

function AnalyseAndAdjustContainer({
  pagy,
  samples,
  marks,
  qualities,
  origins,
  sampleTypes,
  currentSampleType,
  currentLocalIdentifier,
  currentMark,
  currentOrigin,
  currentQuality,
  humidityAcceptingReasons,
  roastingResults,
  grades,
  cuppingSessionEnabled,
}: AnalyseAndAdjustContainerProps) {
  const deserializedSamples = deserialise(samples).data as ISample[];
  return (
    <AbilityContext.Provider value={Ability}>
      <Provider
        analyseAndAdjustStore={
          new AnalyseAndAdjustStore({
            humidityAcceptingReasons,
            grades,
            sampleTypes,
            qualities,
            marks,
            origins,
            roastingResults,
            cuppingSessionEnabled,
          })
        }
        overlayStore={new OverlayStore()}
      >
        <AnalyseAndAdjust
          pagy={pagy}
          samples={deserializedSamples}
          currentSampleType={currentSampleType}
          currentLocalIdentifier={currentLocalIdentifier}
          currentMark={currentMark}
          currentOrigin={currentOrigin}
          currentQuality={currentQuality}
        />
      </Provider>
    </AbilityContext.Provider>
  );
}

export default AnalyseAndAdjustContainer;
