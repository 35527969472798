import { deserialise } from 'kitsu-core';
import React from 'react';

import Traceabilities from '../components/Traceabilities';
import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';
import SelectOptionArray from 'types/model/selectOption';
import { Tabs } from 'traceability/components/TraceabilityTabBar';
import { Pagy } from 'shared/Pagynation';
import { FilterOptions } from 'traceability/components/SearchBar';
import { JsonApi } from 'types/utils/jsonApi';
import type {
  GeneralContractTraceability,
  InteralContractTraceability,
} from 'traceability/types/ContractTraceability';

export interface ContractsTraceabilityContainerProps {
  filters?: FilterOptions;
  specialMarks: SelectOptionArray;
  traceabilities: JsonApi<GeneralContractTraceability | InteralContractTraceability>[];
  tabs: Tabs;
  pagy: Pagy;
}

function ContractsTraceabilityContainer({
  traceabilities,
  filters,
  specialMarks,
  tabs,
  pagy,
}: ContractsTraceabilityContainerProps) {
  const deserializedTraceabilities:
    | GeneralContractTraceability[]
    | InteralContractTraceability[] = [];

  traceabilities.map((traceability) => {
    deserializedTraceabilities.push(deserialise(traceability).data);
  });

  return (
    <AbilityContext.Provider value={Ability}>
      <Traceabilities
        traceabilities={deserializedTraceabilities}
        filters={filters}
        specialMarks={specialMarks}
        tabs={tabs}
        pagy={pagy}
      />
    </AbilityContext.Provider>
  );
}

export default ContractsTraceabilityContainer;
