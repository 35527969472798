import { Provider } from 'mobx-react';
import React from 'react';

import CuppingSessionStore from '../stores/CuppingSessionStore';

import { DefectGet } from 'types/model/defect';
import { DescriptorBodyGet } from 'types/model/descriptorBody';
import { DescriptorFlavourGet } from 'types/model/descriptorFlavour';
import { DescriptorOverallGet } from 'types/model/descriptorOverall';
import { DescriptorRoastGet } from 'types/model/descriptorRoast';
import { AlternativeGet } from 'types/model/alternative';

import CuppingSession from '../components/CuppingSession';
import FullscreenCuppingSession from '../components/FullscreenCuppingSession';
import SelectOptionArray from 'types/model/selectOption';
import ISample from 'cuppingSession/types/ISample';

interface CuppingSessionContainerProps {
  samples: ISample[];
  descriptorFlavours: DescriptorFlavourGet[];
  descriptorOveralls: DescriptorOverallGet[];
  descriptorBodies: DescriptorBodyGet[];
  descriptorRoasts: DescriptorRoastGet[];
  defects: DefectGet[];
  alternatives: AlternativeGet[];
  reasons: SelectOptionArray;
  cuppingSessionEnabled: boolean;
  cuppingTableId: string;
  fullscreenView?: boolean;
}

function CuppingSessionContainer({
  samples,
  defects,
  descriptorBodies,
  descriptorFlavours,
  descriptorOveralls,
  descriptorRoasts,
  alternatives,
  reasons,
  cuppingSessionEnabled,
  cuppingTableId,
  fullscreenView = false,
}: CuppingSessionContainerProps): JSX.Element {
  return (
    <Provider
      cuppingSessionStore={
        new CuppingSessionStore(samples, cuppingSessionEnabled, cuppingTableId, {
          defects,
          descriptorBodies,
          descriptorFlavours,
          descriptorOveralls,
          descriptorRoasts,
          reasons,
          alternatives,
        })
      }
    >
      {fullscreenView ? <FullscreenCuppingSession /> : <CuppingSession />}
    </Provider>
  );
}

export default CuppingSessionContainer;
