import React from 'react';
import Routes from 'routes';

import { DeliveryOrder } from '../../models/DeliveryOrder';
import { customFetch } from 'utils/fetch';

interface AcceptDeliveryOrderProps {
  deliveryOrder: DeliveryOrder;
}

function AcceptDeliveryOrder({ deliveryOrder }: AcceptDeliveryOrderProps) {
  const handleOnReleaseClick = () => {
    const url = Routes.api_business_v1_fulfillment_delivery_order_accept_path(
      deliveryOrder.identifier
    );

    customFetch(url, undefined, 'PATCH').then((_response) => window.location.reload());
  };

  return ['sent', 'in_progress'].includes(deliveryOrder.state) ? (
    <button className='button button--small button--green' onClick={handleOnReleaseClick}>
      Accept
    </button>
  ) : null;
}

export default AcceptDeliveryOrder;
