import Sample from 'productionOrders/models/Sample';
import ISample from 'millingOrders/types/ISample';
import SampleBuilder from 'utils/builders/SampleBuilder';

export function createSampleFactory(
  sample: ISample,
  {
    createHumidityAnalysis,
    createPhysicalAnalysis,
    createRoasting,
    createSensorialAnalysis,
  }: SampleBuilder
): Sample {
  const humidityAnalysis = createHumidityAnalysis();
  const physicalAnalysis = createPhysicalAnalysis();
  const roasting = createRoasting();
  const sensorialAnalysis = createSensorialAnalysis();

  return new Sample(
    sample,
    humidityAnalysis,
    physicalAnalysis,
    roasting,
    sensorialAnalysis
  );
}
