import { DeliveryOrder } from '../../models/DeliveryOrder';
import { observer } from 'mobx-react';
import React from 'react';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

import Routes from 'routes';
import Icon from 'components/elements/Icon';
import useStores from 'utils/useStores';
import { customFetch } from 'utils/fetch';
import isEnvironment from 'utils/isEnvironment';
import AcceptDeliveryOrder from './AcceptDeliveryOrder';
import ButtonGroup from './ButtonGroup';
import PointOfRelease from './PointOfRelease';

interface DeliveryOrderRowProps {
  deliveryOrder: DeliveryOrder;
}

function DeliveryOrderRow({ deliveryOrder }: DeliveryOrderRowProps) {
  const { deliveryOrderIndexStore } = useStores();
  const { sent, released, hasAddress, state, acceptedDate, totalUnits, downloadUrl } =
    deliveryOrder;

  const handleOnNotesClick = (): void => {
    deliveryOrderIndexStore.openNotesOverlay(deliveryOrder);
  };

  const openSendOverlay = async (): Promise<void> => {
    await deliveryOrderIndexStore.openSendOverlay(deliveryOrder);
  };

  const processDeliveryOrder = () => {
    const url = Routes.in_progress_api_v1_fulfillment_delivery_order_path(
      deliveryOrder.id
    );
    customFetch(url, undefined, 'PATCH').then((_response) => window.location.reload());
  };

  return (
    <tr>
      <td className='delivery-orders-index--edit align-center'>
        {deliveryOrder.editable ? (
          <a
            href={Routes.edit_fulfillment_delivery_order_path(deliveryOrder)}
            data-cy='edit_delivery_order'
          >
            <Icon icon='pen' />
          </a>
        ) : (
          <a
            href={Routes.fulfillment_delivery_order_path(deliveryOrder)}
            data-cy='show_delivery_order'
          >
            <Icon icon='info' />
          </a>
        )}
      </td>

      <td className='align-left table-border'>
        <TextWithSub text={deliveryOrder.identifier} sub={deliveryOrder.customer.name} />
      </td>

      <td className='align-left table-border'>
        {deliveryOrder.destinationWarehouse.name}
      </td>

      <td className='delivery-orders-index--status align-center table-border'>
        <div className='l-item-stack-horizontal'>
          {deliveryOrder.state == 'new' ? (
            <button
              onClick={openSendOverlay}
              className='button button--small button--green'
            >
              {I18n.translate('fulfillment.delivery_orders.index.send_to_accounting')}
            </button>
          ) : (
            <span>
              {I18n.translate(
                `activerecord.attributes.fulfillment/delivery_order.aasm_state.${state}`
              )}
            </span>
          )}

          {isEnvironment('development') && (
            <AcceptDeliveryOrder deliveryOrder={deliveryOrder} />
          )}
        </div>
      </td>

      <td className='align-center table-border'>
        <TextWithSub text={totalUnits} sub={acceptedDate} />
      </td>

      <td className='align-center table-border'>
        <PointOfRelease deliveryOrder={deliveryOrder} />
      </td>

      <td className='align-center table-border'>
        <button
          className='button button--small'
          onClick={handleOnNotesClick}
          disabled={!sent}
        >
          {I18n.translate('fulfillment.delivery_orders.index.notes')}
        </button>
      </td>

      <td className='align-center'>
        {sent &&
          (hasAddress ? (
            <ButtonGroup>
              <a
                href={Routes.preview_fulfillment_delivery_order_path(deliveryOrder)}
                className='button button--small button--green'
              >
                <Icon icon='list' />
              </a>

              <button
                onClick={processDeliveryOrder}
                className='button button--small button'
              >
                {I18n.translate('fulfillment.delivery_orders.index.send_to_customer')}
              </button>
            </ButtonGroup>
          ) : (
            <span>
              {I18n.translate('fulfillment.delivery_orders.index.missing_address')}
            </span>
          ))}

        {released && downloadUrl && (
          <ButtonGroup>
            <a href={downloadUrl} className='button button--small button--green'>
              <Icon icon='list' />
            </a>
          </ButtonGroup>
        )}
      </td>
    </tr>
  );
}

export default observer(DeliveryOrderRow);
