import { observer } from 'mobx-react';
import React from 'react';
import Pagynation from 'shared/Pagynation';
import useStores from 'utils/useStores';
import parseUSVString from 'utils/parseUSVString';

interface PaginatedTableProps {
  handlePaginationClick: (event: MouseEvent) => void;
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({
  handlePaginationClick,
  children,
}) => {
  const { paginatedTableStore } = useStores();
  const { pagy } = paginatedTableStore;

  const searchParams = parseUSVString(window.location?.search);

  return (
    <>
      <table>{children}</table>
      {pagy && (
        <Pagynation
          pagy={pagy}
          url={paginatedTableStore.dataUrl}
          searchParams={searchParams}
          handleOnClick={handlePaginationClick}
        />
      )}
    </>
  );
};

export default observer(PaginatedTable);
