import React from 'react';
import { Provider } from 'mobx-react';

import DecafOrder from 'decaf/types/DecafOrder';
import QualityAssuranceStore from 'decaf/stores/QualityAssuranceStore';
import { JsonApi } from 'types/utils/jsonApi';
import Ico from 'types/model/ico';
import type Validation from 'types/utils/validations';
import DecafOrdersTable from 'decaf/components/DecafOrdersTable';
import OverlayStore from 'stores/OverlayStore';
import SelectOptionArray from 'types/model/selectOption';

export interface QualityAssuranceContainerProps {
  decafOrders: JsonApi<DecafOrder, Ico>;
  humidityAcceptingReasons: SelectOptionArray;
  roastingResults: SelectOptionArray;
  roastingSampleWeight: number;
  roastingValidations: { pppt: Validation };
}

export default function QualityAssuranceContainer({
  decafOrders,
  humidityAcceptingReasons,
  roastingResults,
  roastingSampleWeight,
  roastingValidations,
}: QualityAssuranceContainerProps) {
  return (
    <Provider
      qualityAssuranceStore={
        new QualityAssuranceStore(
          decafOrders,
          humidityAcceptingReasons,
          roastingResults,
          roastingSampleWeight,
          roastingValidations
        )
      }
      overlayStore={new OverlayStore()}
    >
      <DecafOrdersTable />
    </Provider>
  );
}
