import InternalContractCustomerSampleType from 'customerSamples/types/InternalContractCustomerSampleType';
import Sample from 'customerSamples/models/Sample';
import { computed } from 'mobx';

class InternalContractSample extends Sample {
  public received: boolean;

  constructor(sample: InternalContractCustomerSampleType) {
    super(sample);

    this.received = sample.received;
  }

  @computed get showCustomerSampleValueButton(): boolean {
    return !!this.currentSensorialAnalysis;
  }
}

export default InternalContractSample;
