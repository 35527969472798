import React from 'react';
import styled from 'styled-components';

import Icon from 'components/elements/Icon';
import COLORS from 'constants/colors';
import STATUS from 'types/utils/status';

export interface StatusTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  status: STATUS;
  reverseOrder?: boolean;
  withIcon?: boolean;
}

const STATUS_MAP = {
  [STATUS.OK]: {
    color: COLORS.GREEN,
    component: <Icon icon='passed' />,
  },
  [STATUS.FAILED]: {
    color: COLORS.RED,
    component: <Icon icon='failed' />,
  },
};

const StatusText: React.FC<StatusTextProps> = ({
  status,
  children,
  reverseOrder,
  withIcon = true,
  ...props
}) => {
  return (
    <span {...props}>
      {reverseOrder ? (
        <>
          {children} {withIcon && STATUS_MAP[status]?.component}
        </>
      ) : (
        <>
          {withIcon && STATUS_MAP[status]?.component} {children}
        </>
      )}
    </span>
  );
};

export default styled(StatusText)`
  color: ${({ status }) => (STATUS_MAP[status] ? STATUS_MAP[status].color : 'inherited')};
`;
