import React from 'react';
import ReactDOM from 'react-dom';

const OverlayPortal: React.FC = ({ children }) => {
  const portalDomNode = document.getElementById('overlay') as HTMLDivElement;

  return ReactDOM.createPortal(children, portalDomNode);
};

export default OverlayPortal;
