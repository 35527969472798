import classNames from 'classnames';
import React from 'react';
import { truncateText } from 'utils/string';

import Text, { TextProps } from './Text';

interface TextWithSubProps extends TextProps {
  sub: string | number;
  subClass?: string;
  width?: string;
  maxTextLength?: number;
  overflow?: boolean;
}

const TextWithSub: React.FC<TextWithSubProps> = ({
  text,
  textClass,
  sub,
  subClass,
  dataCy,
  onClick,
  overflow,
  width,
  maxTextLength,
}) => {
  const subClassnames = classNames('text-with-sub__sub', {
    [`${subClass}`]: subClass,
  });

  const truncatedText = maxTextLength
    ? truncateText(text.toString(), maxTextLength)
    : text;
  const truncatedSub = maxTextLength ? truncateText(sub.toString(), maxTextLength) : sub;

  return (
    <div className='text-with-sub' data-cy={dataCy}>
      <Text
        text={truncatedText}
        textClass={textClass}
        onClick={onClick}
        width={width}
        title={text.toString()}
        overflow={overflow}
      />

      <small className={subClassnames} style={{ width: '100%' }} title={sub.toString()}>
        {truncatedSub}
      </small>
    </div>
  );
};
export default TextWithSub;
