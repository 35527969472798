import React from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { ContractSelectGroup } from '../ContractFields';
import Checkbox from 'shared/Checkbox';

export const ContractClosure = ({
  contractClosureReasonIdField,
  contractClosureReasonId,
  setContractClosureReasonId,
  contractCancellationReasonIdField,
  contractCancellationReasonId,
  setContractCancellationReasonId,
  cancelContractChecked,
  setCancelContractChecked,
  closeContractChecked,
  setCloseContractChecked,
}) => {
  return (
    <FieldSetWithTitle title={I18n.translate('contracts.form.close_or_cancel')}>
      <div className='l-item-stack'>
        {contractClosureReasonIdField?.visible && (
          <>
            <Checkbox
              icon='tick'
              inline={true}
              checked={closeContractChecked}
              disabled={
                contractClosureReasonIdField?.disabled ||
                contractClosureReasonIdField?.readonly
              }
              label={I18n.translate('contracts.form.close_contract')}
              onChange={setCloseContractChecked}
            />
            {closeContractChecked && (
              <Grid grid={16}>
                <ContractSelectGroup
                  field={contractClosureReasonIdField}
                  value={contractClosureReasonId}
                  onChange={setContractClosureReasonId}
                />
              </Grid>
            )}
          </>
        )}
        {contractCancellationReasonIdField?.visible && (
          <>
            <Checkbox
              icon='tick'
              inline={true}
              checked={cancelContractChecked}
              disabled={
                contractCancellationReasonIdField?.disabled ||
                contractCancellationReasonIdField?.readonly
              }
              label={I18n.translate('contracts.form.cancel_contract')}
              onChange={setCancelContractChecked}
            />
            {cancelContractChecked && (
              <Grid grid={16}>
                <ContractSelectGroup
                  field={contractCancellationReasonIdField}
                  value={contractCancellationReasonId}
                  onChange={setContractCancellationReasonId}
                />
              </Grid>
            )}
          </>
        )}
      </div>
    </FieldSetWithTitle>
  );
};
