import Grid from 'components/elements/Grid';
import Search, { Option } from 'components/elements/Search';
import BaseGroupComponent from 'components/groups/BaseGroup';
// import SelectGroup from 'components/groups/SelectGroup';
import TextareaGroupComponent from 'components/groups/TextareaGroup';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';
import Checkbox from 'shared/Checkbox';
import {
  OverlayBaseForm,
  OverlayFooter,
  OverlayTitle,
  OverlayWrapper,
} from 'shared/Overlay';
import useStores from 'utils/useStores';
import LotOrSetRow from './LotOrSetRow';

export interface IcoSelectOverlayProps {
  onCloseClick: (event?: React.MouseEvent) => void;
}

function IcoSelectOverlay({ onCloseClick }: IcoSelectOverlayProps) {
  const { consolidateSamplesStore } = useStores();
  const { paginatedTableStore, selectedSampleTypeId, setSubmitting, submitting } =
    consolidateSamplesStore;
  const [selectedIcoId, setSelectedIcoId] = useState('');
  const [reserveCoffee, setReserveCoffee] = useState(false);
  const [canReserveCoffee, setCanReserveCoffee] = useState(false);

  useEffect(() => {
    setCanReserveCoffee(false);
    setReserveCoffee(false);

    if (selectedIcoId !== '') {
      const selectedIco = consolidateSamplesStore.icos.find(({ id }) => {
        return id === parseInt(selectedIcoId, 10);
      });

      if (selectedIco) {
        setCanReserveCoffee(selectedIco.can_reserve_coffee);
      }
    }
  }, [selectedIcoId]);

  const handleOnIcoChange = (option: Option) => {
    setSelectedIcoId(option.value.toString());
  };

  const handleOnSubmit = (_event: React.FormEvent) => {
    setSubmitting(true);
  };

  return (
    <OverlayWrapper>
      <OverlayBaseForm>
        <form
          action={paginatedTableStore.actionUrl ? paginatedTableStore.actionUrl() : ''}
          method='POST'
          onSubmit={handleOnSubmit}
        >
          <AuthenticityToken />
          <input type='hidden' name='sample_type' value={selectedSampleTypeId} />
          {consolidateSamplesStore.selectedSamples.map(
            ({ attributes: { id, sampleSize } }) => (
              <input key={id} type='hidden' name={`samples[${id}]`} value={sampleSize} />
            )
          )}
          <OverlayTitle>
            {I18n.translate('samples.consolidate.pre_shipment_sample_overlay_title')}
          </OverlayTitle>

          <Grid grid={12}>
            <div>
              <BaseGroupComponent
                label={I18n.translate('samples.consolidate.ico')}
                data-cy='ico_select'
              >
                <Search
                  name='ico_id'
                  placeholder={I18n.translate('samples.consolidate.ico_placeholder')}
                  options={consolidateSamplesStore.searchableIcos}
                  value={parseInt(selectedIcoId, 10)}
                  onChange={handleOnIcoChange}
                  menuPosition='fixed'
                />
              </BaseGroupComponent>

              <Checkbox
                label={I18n.translate('samples.consolidate.reserve_coffee')}
                checked={reserveCoffee}
                disabled={!canReserveCoffee}
                onChange={() => setReserveCoffee(!reserveCoffee)}
                data-cy='reserve_coffee_checkbox'
              />
            </div>

            <TextareaGroupComponent
              placeholder={I18n.translate(
                'customer_samples_dashboard.observations_placeholder'
              )}
              name='observation'
              id='observation'
              label={I18n.translate('attributes.observations')}
              data-cy='observations_textarea'
            />
          </Grid>

          <Grid grid={24}>
            {reserveCoffee && (
              <table className='table table--border table--striped'>
                <thead>
                  <tr>
                    <th>{I18n.translate('samples.consolidate.lot_or_set_header')}</th>
                    <th className='align-center'>
                      {I18n.translate('samples.consolidate.lot_or_set_size_header')}
                    </th>
                    <th className='align-right'>
                      {I18n.translate(
                        'samples.consolidate.lot_or_set_reserved_size_header'
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {consolidateSamplesStore.selectedLots?.map((lot) => (
                    <LotOrSetRow
                      key={`lot${lot.id}`}
                      id={lot.id}
                      identifier={lot?.local_identifier}
                      type='lot'
                      availableAmount={lot.weight_minus_reserved_weight.weight_kg}
                    />
                  ))}

                  {consolidateSamplesStore.selectedLotSets?.map((lotSet) => (
                    <LotOrSetRow
                      key={`set${lotSet.id}`}
                      id={lotSet.id}
                      identifier={lotSet?.local_identifier}
                      type='lot_set'
                      availableAmount={lotSet.weight_minus_reserved_weight.weight_kg}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </Grid>

          <OverlayFooter className='l-item-stack-horizontal' distributeButtons={true}>
            <button onClick={onCloseClick} className='button button--gray'>
              {I18n.translate('buttons.close')}
            </button>

            <button
              type='submit'
              disabled={
                !consolidateSamplesStore.isFormValid || !selectedIcoId || submitting
              }
              className='button'
              data-cy='ico_select_overlay_submit'
            >
              {I18n.translate('samples.consolidate.create_sample')}
            </button>
          </OverlayFooter>
        </form>
      </OverlayBaseForm>
    </OverlayWrapper>
  );
}

export default observer(IcoSelectOverlay);
