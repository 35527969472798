import { deserialise } from 'kitsu-core';
import { useEffect, useState } from 'react';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';
import Ico from 'samples/types/Ico';

const fetchArrivalSampleIcos = (destinationWarehouseId: string) => {
  return customFetch(
    Routes.api_v1_samples_icos_arrival_sample_path({
      destination_warehouse_id: destinationWarehouseId,
    }),
    undefined,
    'GET'
  );
};

const useArrivalSampleIcos = (destinationWarehouseId: string) => {
  const [icos, setIcos] = useState<Ico[]>([]);

  useEffect(() => {
    if (!destinationWarehouseId || destinationWarehouseId == '') {
      setIcos([]);
      return;
    }
    fetchArrivalSampleIcos(destinationWarehouseId).then((data) => {
      const icos = deserialise(data.icos).data;
      setIcos(icos);
    });
  }, [destinationWarehouseId]);

  return {
    icos,
  };
};
export default useArrivalSampleIcos;
