import { action, computed, observable } from 'mobx';

import COLORS from 'constants/colors';

import { LotGet } from 'types/model/lot';
import { LotSetGet } from 'types/model/lot_set';
import UnloadableItem from './UnloadableItem';
import Storage from 'transports/model/Storage';

class UnloadItem extends UnloadableItem {
  public lot: LotGet | LotSetGet;

  @observable public unloadedWeight: number | undefined;
  @observable public unloadedUnits: number | undefined;
  @observable public reasonId: number | undefined;

  public storage: Storage;

  public identifier: string;
  public expectedUnits: number;
  public expectedWeight: number;

  public type: LotGet['type'] | LotSetGet['type'];

  constructor(lot: LotGet | LotSetGet, storage: Storage) {
    super();

    this.type = lot.type;
    this.lot = lot;
    this.identifier = lot.local_identifier;
    this.expectedUnits = this.lot.available_bags;
    this.unloadedUnits = 0;
    this.expectedWeight = this.lot.weight;
    this.unloadedWeight = 0;

    this.storage = storage;
  }

  @action public setUnloadedUnits = (value?: number) => {
    if (value && this.expectedUnits < value) {
      this.unloadedUnits = this.expectedUnits;
    } else {
      this.unloadedUnits = value;
    }
  };

  @action public setUnloadedWeight = (value?: number) => {
    if (value && this.expectedWeight < value) {
      this.unloadedWeight = this.expectedWeight;
    } else {
      this.unloadedWeight = value;
    }
  };

  @action public setReasonId = (value: number | undefined) => {
    this.reasonId = value;
  };

  @computed get isUnloaded(): boolean {
    return !!this.unloadedUnits && !!this.unloadedWeight && this.storage.isValid;
  }

  @computed get bagIcon(): { name: string; color: string } {
    if (this.unloadedUnits === this.expectedUnits) {
      return { name: 'check', color: COLORS.GREEN };
    } else if (this.unloadedUnits && this.unloadedUnits !== this.expectedUnits) {
      return { name: 'failed', color: COLORS.RED };
    }

    return { name: '', color: '' };
  }

  @computed get weightIcon(): { name: string; color: string } {
    if (this.unloadedWeight === this.expectedWeight) {
      return { name: 'check', color: COLORS.GREEN };
    } else if (this.unloadedWeight && this.unloadedWeight !== this.expectedWeight) {
      return { name: 'failed', color: COLORS.RED };
    }

    return { name: '', color: '' };
  }
}

export default UnloadItem;
