import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import SampleTransportModel from 'samples/receive_samples/models/SampleTransport';
import styled from 'styled-components';
import ReceiveSampleRow from './ReceiveSampleRow';
import SampleModel from 'samples/receive_samples/models/SampleModel';

const ActionCol = styled.th`
  width: 10em;
`;

interface ReceiveSampleTransportTableProps {
  transport: SampleTransportModel;
}

function ReceiveSampleTransportTable({
  transport,
}: ReceiveSampleTransportTableProps): JSX.Element {
  return (
    <table
      className={classNames('table', 'sets-table', {
        'sets-table--collapsed': true,
      })}
    >
      <thead>
        <tr>
          <th className='align-left'>ID</th>
          <th className='table-border'>
            {I18n.translate('activerecord.models.producer')} /{' '}
            {I18n.translate('activerecord.models.customer')} |{' '}
            {I18n.translate('activerecord.models.certificate')}
          </th>
          <th className='table-border align-center'>
            {I18n.translate('samples.receive.type')} | {I18n.translate('attributes.mark')}
          </th>
          <th className='table-border align-center'>
            {I18n.translate('samples.receive.quality')}
          </th>
          <th className='table-border align-center'>
            {I18n.translate('samples.receive.weight')}
          </th>
          <th className='table-border align-center'>
            {I18n.translate('attributes.bin')} |{' '}
            {I18n.translate('samples.receive.request_id')}
          </th>
          <ActionCol className='transports--unload-action'>
            {I18n.translate('samples.receive.status')}
          </ActionCol>
        </tr>
      </thead>
      <tbody>
        {transport.samples.map((sample: SampleModel) => (
          <ReceiveSampleRow
            sample={sample}
            key={sample.attributes.id}
            transport={transport}
          />
        ))}
      </tbody>
    </table>
  );
}

export default observer(ReceiveSampleTransportTable);
