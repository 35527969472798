import { observer } from 'mobx-react';
import React from 'react';

import Customer from 'customers/models/Customer';
import { CustomerLead } from 'customerLeads/model/CustomerLead';
export interface CustomerDetailsProps {
  data: Customer | CustomerLead;
  view: boolean;
  cancelPath: string;
  saveButtonText: string;
}

const CustomerDetails = ({
  data,
  view,
  cancelPath,
  saveButtonText,
}: CustomerDetailsProps) => (
  <div className='fieldset'>
    <div className='l-distribute-l-r'>
      <a href={cancelPath} className='button button--gray'>
        {I18n.translate('buttons.cancel')}
      </a>
      {!view && (
        <button
          type='submit'
          disabled={data instanceof Customer ? !data.isValid : false}
          className='button'
        >
          {saveButtonText}
        </button>
      )}
    </div>
  </div>
);

export default observer(CustomerDetails);
