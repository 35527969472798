import { action, observable } from 'mobx';
import Routes from 'routes';

import ITransportSample from 'samples/types/ITransportSample';
import SelectableTransportSampleModel from '../models/SelectableTransportSampleModel';

class SelectedTranportSamplesStore {
  @observable public selectedSamples = observable.array<SelectableTransportSampleModel>();
  public actionUrl: () => string;

  constructor() {
    this.actionUrl = Routes.new_sample_transport_path;
  }

  @action public toggleSampleSelection = (sample: SelectableTransportSampleModel) => {
    if (sample.selected) {
      this.removeSample(sample);
    } else {
      this.addSample(sample);
    }
  };

  @action private addSample = (sample: SelectableTransportSampleModel) => {
    this.selectedSamples.push(sample);
    sample.setSelected(true);
  };

  @action private removeSample = (sample: SelectableTransportSampleModel) => {
    this.selectedSamples.remove(sample);
    sample.setSelected(false);
  };

  @action public findSample = (
    sample: ITransportSample
  ): SelectableTransportSampleModel | undefined => {
    return this.selectedSamples.find(
      (selectedSample) => selectedSample.attributes.id === sample.id
    );
  };

  @action public isSelected = (id: string | number): boolean => {
    const index = this.selectedSamples.findIndex((selectedSample) => {
      return selectedSample.attributes.id.toString() === id.toString();
    });
    return index > 0;
  };
}

export default SelectedTranportSamplesStore;
