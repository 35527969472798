import { observer } from 'mobx-react';
import React from 'react';
import Routes from 'routes';

import useStores from 'utils/useStores';

import HumidityAnalysis from 'shared/HumidityAnalysis';
import Roasting from 'shared/Roasting';
import PhysicalAnalysis from 'decaf/components/PhysicalAnalysis';
import { addTestingIdentifier } from 'utils/testing';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

interface SampleQaRowProps {
  weight: number;
}

const SampleQaRow = ({ weight }: SampleQaRowProps) => {
  const { millingOrderQaStore } = useStores();
  const {
    roastingSampleWeight,
    millingOutput: {
      outputType,
      sample: {
        id,
        milling_order_id,
        current_roasting,
        current_humidity_analysis,
        current_qa_physical_analysis,
        current_sensorial_analysis: initialSensorialAnalysis,
        local_identifier,
      },
    },
    cuppingSessionEnabled,
  } = millingOrderQaStore;

  let current_sensorial_analysis = initialSensorialAnalysis;
  if (cuppingSessionEnabled && !current_sensorial_analysis?.finalized) {
    current_sensorial_analysis = undefined;
  }

  const handleOnPrimaryClick = (_, values) => {
    current_humidity_analysis.synchronize(values);
  };

  const handleOnRoastingPrimaryClick = (_event: React.MouseEvent, values: {}) => {
    current_roasting.synchronize(values);
  };

  const legacyCupping = () => {
    if (current_sensorial_analysis) {
      return (
        <div
          className={`status status--no-pointer status--${current_sensorial_analysis.status}`}
        >
          <span>{current_sensorial_analysis.text}</span>
          <svg className='icon'>
            <use xlinkHref={`#${current_sensorial_analysis.icon}`} />
          </svg>
        </div>
      );
    } else {
      return (
        <form action={Routes.vertical_session_sensorial_analyses_path()} method='post'>
          <AuthenticityToken />

          <input type='hidden' name={`samples[${id}]`} value={id} />
          <button
            type='submit'
            className='button button--small button--green'
            disabled={!current_roasting.id}
            {...addTestingIdentifier('to_cupping_button')}
          >
            {I18n.translate('fractions.to_cupping')}
          </button>
        </form>
      );
    }
  };

  const cuppingTable = () => {
    if (current_sensorial_analysis && current_sensorial_analysis.finalized) {
      return (
        <div
          className={`status status--no-pointer status--${current_sensorial_analysis.status}`}
        >
          <span>{current_sensorial_analysis.text}</span>
          <svg className='icon'>
            <use xlinkHref={`#${current_sensorial_analysis.icon}`} />
          </svg>
        </div>
      );
    } else if (initialSensorialAnalysis) {
      return <span>{initialSensorialAnalysis.cuppingTableIdentifier}</span>;
    } else {
      return (
        <a
          href={Routes.new_cupping_table_path()}
          className='button button--small button--green'
        >
          {I18n.translate('fractions.to_cupping')}
        </a>
      );
    }
  };

  const renderSensorial = () => {
    if (!current_roasting.id) return;

    if (cuppingSessionEnabled) {
      return cuppingTable();
    } else {
      return legacyCupping();
    }
  };

  return (
    <>
      <td className='table-border'>
        <p>
          {I18n.translate(`milling_orders.finalise_milling.types.${outputType}`)}
          <br />({local_identifier})
        </p>
      </td>

      <td className='align-center table-border'>
        <HumidityAnalysis
          identifier={local_identifier}
          onPrimaryClick={handleOnPrimaryClick}
          humidityAnalysis={current_humidity_analysis}
          lotFailed={false}
          validations={current_humidity_analysis.validations}
          text={current_humidity_analysis.text}
          status={current_humidity_analysis.status}
          icon={current_humidity_analysis.icon}
          reasons={millingOrderQaStore.humidityAnalysisAcceptingReasons}
        />
      </td>

      <td className='align-center table-border'>
        {(outputType === 'excelso' || outputType === 'excess_excelso') && (
          <PhysicalAnalysis
            physicalAnalysis={current_qa_physical_analysis}
            newUrl={Routes.new_milling_order_sample_excelso_physical_analysis_path(
              milling_order_id,
              id
            )}
            editUrl={Routes.edit_milling_order_sample_excelso_physical_analysis_path(
              milling_order_id,
              id,
              current_qa_physical_analysis.id || 0
            )}
          />
        )}

        {(outputType === 'peebies' || outputType === 'peaberries') && (
          <PhysicalAnalysis
            physicalAnalysis={current_qa_physical_analysis}
            newUrl={Routes.new_milling_order_sample_peabies_physical_analysis_path(
              milling_order_id,
              id
            )}
            editUrl={Routes.edit_milling_order_sample_peabies_physical_analysis_path(
              milling_order_id,
              id,
              current_qa_physical_analysis.id || 0
            )}
          />
        )}
      </td>

      <td className='align-center table-border'>
        <Roasting
          identifier={local_identifier}
          results={millingOrderQaStore.roastingResults}
          roasting={current_roasting}
          roastingSampleWeight={roastingSampleWeight}
          text={I18n.translate(
            `widgets.lot_status_table.${current_roasting.passed ? 'done' : 'failed'}`
          )}
          status={current_roasting.passed}
          icon={current_roasting.passed ? 'passed' : 'failed'}
          onPrimaryClick={handleOnRoastingPrimaryClick}
          showReroast={false}
          showEnterRoasting={!current_roasting.id}
          validations={millingOrderQaStore.roastingValidations}
        />
      </td>

      <td className='align-center table-border'>{renderSensorial()}</td>

      <td className='align-center'>{weight.toFixed(2)} kg</td>
    </>
  );
};

export default observer(SampleQaRow);
