import React from 'react';
import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import Icon from 'components/elements/Icon';
import Ico from 'exports/models/Ico';

export interface IcoPopupProps {
  ico: Ico;
}

const PopupWrapper = styled.div`
  z-index: 12;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 0.25rem));
  padding: 0.25rem 1rem;
  border-radius: 4px;
  background: ${COLORS.BLUE};
`;

const PopupRow = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 0.25rem 0;
  font-size: 0.875rem;

  &:last-child {
    border-top: 1px solid ${COLORS.WHITE};
  }
`;

const RowItem = styled.div`
  align-items: center;
  display: inline-flex;
  white-space: nowrap;
  margin-right: 1rem;

  &:last-child {
    text-align: right;
    margin-right: 0rem;
  }
`;

function IcoPopup({ ico }: IcoPopupProps) {
  return (
    <PopupWrapper>
      <PopupRow>
        <RowItem>
          <b>{ico.contract_identifier}</b>
        </RowItem>
        <RowItem>
          <Icon icon='bag' color='#ffffff' />
          {ico.units} x {ico.packaging_type.capacity}kg
        </RowItem>
      </PopupRow>

      <PopupRow>
        <RowItem>{ico.mark_name}</RowItem>
        <RowItem>{ico.unit_price} USD/lb</RowItem>
      </PopupRow>
    </PopupWrapper>
  );
}

export default IcoPopup;
