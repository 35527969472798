import { action, observable } from 'mobx';

class Selectable {
  @observable public selected: boolean;

  constructor(selected = false) {
    this.selected = selected;
  }

  @action public setSelected = (selected: boolean) => {
    this.selected = selected;
  };
}

export default Selectable;
