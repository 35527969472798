import React from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';

import AvailableCoffee from 'opportunities/components/AvailableCoffee';
import { AvailableCoffeeType } from 'opportunities/components/AvailableCoffee';
import { IField } from 'components/shared/form/IField';
import InputGroupComponent from 'components/groups/InputGroup';
import { OpportunityTextInput } from '../OpportunityFields';

interface OpportunitySpotCoffeeProps {
  availableCoffee: AvailableCoffeeType[];
  onSelect: (AvailableCoffeeType) => void;
  disabled?: boolean;
  selectedSpotIco?: AvailableCoffeeType;
  estimatedUnitsField?: IField;
  units?: number;
}

export const OpportunitySpotCoffee = ({
  availableCoffee,
  onSelect,
  selectedSpotIco,
  estimatedUnitsField,
  units,
  disabled = false,
}: OpportunitySpotCoffeeProps) => {
  return (
    <FieldSetWithTitle
      title={I18n.translate('opportunities.form.coffee')}
      disabled={disabled}
    >
      <Grid>
        {selectedSpotIco && (
          <>
            <input type='hidden' value={selectedSpotIco.id} name='opportunity[ico_id]' />
            <InputGroupComponent
              value={selectedSpotIco.identifier.value}
              disabled={true}
              label={I18n.translate(`opportunities.form.ico_identifier`)}
              readOnly={true}
              data-cy={'icoNumber'}
            />
            <InputGroupComponent
              value={selectedSpotIco.certificate || ''}
              disabled={true}
              label={I18n.translate(`opportunities.form.certificate`)}
              readOnly={true}
              data-cy={'certificate'}
            />
            <InputGroupComponent
              value={selectedSpotIco.origin_country || ''}
              disabled={true}
              label={I18n.translate(`opportunities.form.origin_countries`)}
              readOnly={true}
              data-cy={'origin'}
            />
            <InputGroupComponent
              value={selectedSpotIco.mark || ''}
              disabled={true}
              label={I18n.translate(`opportunities.form.mark`)}
              readOnly={true}
              data-cy={'mark'}
            />
            <InputGroupComponent
              value={selectedSpotIco.quality || ''}
              disabled={true}
              label={I18n.translate(`opportunities.form.quality`)}
              readOnly={true}
              data-cy={'quality'}
            />

            <OpportunityTextInput
              field={estimatedUnitsField}
              type='number'
              min={1}
              max={units}
              value={estimatedUnitsField?.value}
              step='1'
              append='#'
              info={`/${units}`}
            />
            <InputGroupComponent
              value={selectedSpotIco.packagingType}
              disabled={true}
              label={I18n.translate(`opportunities.form.capacity_and_packaging`)}
              readOnly={true}
              data-cy={'packaging_type'}
            />
          </>
        )}
        {!disabled && (
          <AvailableCoffee
            buttonTitle={
              selectedSpotIco
                ? I18n.translate('available_coffee_overlay.change_ico')
                : I18n.translate('available_coffee_overlay.see_available_coffee')
            }
            availableCoffee={availableCoffee}
            onSelect={onSelect}
          />
        )}
      </Grid>
    </FieldSetWithTitle>
  );
};
