import Validation from 'types/utils/validations';

class HumidityValidations {
  public humidity_green: Validation;
  public water_activity: Validation;
  public humidity_dry_parchment: Validation;

  constructor({
    humidity_green,
    water_activity,
    humidity_dry_parchment,
  }: HumidityValidations) {
    this.humidity_green = humidity_green;
    this.water_activity = water_activity;
    this.humidity_dry_parchment = humidity_dry_parchment;
  }
}

export default HumidityValidations;
