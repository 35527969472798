import IPhysicalAnalysis from 'types/model/IPhysicalAnalysis';
import { action, computed, observable } from 'mobx';

const valuePercentage = (value: string, green: string): string => {
  const procent = ((parseFloat(value) * 100) / parseFloat(green)).toFixed(2);

  if (green && procent && procent !== 'NaN' && value) {
    return procent;
  }

  return '';
};

class PhysicalAnalysisStore {
  private exportSize: number;
  public sumTolerance: number;

  @observable public values = [];
  @observable public isValid = true;
  @observable public sampleWeight = '300'; // @TODO: wire this
  @observable public green: string;
  @observable public sieve0: string;
  @observable public sieve12: string;
  @observable public sieve14: string;
  @observable public sieve15: string;
  @observable public group1: string;
  @observable public group2: string;
  @observable public broca: string;
  @observable public paloteo: string;
  @observable public ripio: string;
  @observable public guayabas: string;
  @observable public caracol: string;
  @observable public density: string;
  @observable public observations: string;
  @observable public rejectReason: string;
  @observable public acceptingReasonId;

  constructor(values: IPhysicalAnalysis, exportSize: number, sumTolerance: number) {
    this.sampleWeight = '300'; // @TODO: wire this
    this.exportSize = exportSize;
    this.sumTolerance = sumTolerance;
    this.green = values.green || '';
    this.sieve0 = values.sieve_0 || '';
    this.sieve12 = values.sieve_12 || '';
    this.sieve14 = values.sieve_14 || '';
    this.sieve15 = values.sieve_15 || '';
    this.group1 = values.group_1 || '';
    this.group2 = values.group_2 || '';
    this.broca = values.broca || '';
    this.paloteo = values.paloteo || '';
    this.ripio = values.ripio || '';
    this.guayabas = values.guayabas || '';
    this.caracol = values.caracol || '';
    this.density = values.density || '';
    this.observations = values.observations || '';
    this.rejectReason = values.reject_reason || '';
    this.acceptingReasonId = values.accepting_reason_id;
  }

  // #################
  // Setter for values
  // #################
  @action public setIsValid = (status: boolean) => (this.isValid = status);

  @action public setGreen = (value: string) => (this.green = value);

  @action public setSieve0 = (value: string) => (this.sieve0 = value);

  @action public setSieve12 = (value: string) => (this.sieve12 = value);

  @action public setSieve14 = (value: string) => (this.sieve14 = value);

  @action public setSieve15 = (value: string) => (this.sieve15 = value);

  @action public setGroup1 = (value: string) => (this.group1 = value);

  @action public setGroup2 = (value: string) => (this.group2 = value);

  @action public setBroca = (value: string) => (this.broca = value);

  @action public setPaloteo = (value: string) => (this.paloteo = value);

  @action public setRipio = (value: string) => (this.ripio = value);

  @action public setGuayabas = (value: string) => (this.guayabas = value);

  @action public setCaracol = (value: string) => (this.caracol = value);

  @action public setObservations = (value: string) => (this.observations = value);

  @action public setDensity = (value: string) => (this.density = value);

  // #################
  // Calculated Values
  // #################
  @computed get mermaPercentage(): string {
    if (this.green) {
      const sampleWeight = parseFloat(this.sampleWeight);
      return (((sampleWeight - parseFloat(this.green)) / sampleWeight) * 100).toFixed(2);
    }
    return '';
  }

  @computed get sieve0Percentage(): string {
    return valuePercentage(this.sieve0, this.green);
  }

  @computed get sieve12Percentage(): string {
    return valuePercentage(this.sieve12, this.green);
  }

  @computed get sieve14Percentage(): string {
    return valuePercentage(this.sieve14, this.green);
  }

  @computed get sieve15Percentage(): string {
    return valuePercentage(this.sieve15, this.green);
  }

  @computed get sieveSum(): string {
    const sum = [
      this.group1,
      this.group2,
      this.broca,
      this.paloteo,
      this.caracol,
      this.guayabas,
      this.ripio,
      this.sieve0,
      this.sieve12,
      this.sieve14,
      this.sieve15,
    ].reduce((totalValue, currentValue) => {
      const addValue = parseFloat(currentValue) || 0;
      return totalValue + addValue;
    }, 0);

    return sum.toFixed(2);
  }

  @computed get factor14(): string {
    if (this.sieve14 && this.sieve15) {
      return (
        (this.exportSize * parseFloat(this.sampleWeight)) /
        (parseFloat(this.sieve14) + parseFloat(this.sieve15))
      ).toFixed(2);
    }
    return '';
  }

  @computed get factor15(): string {
    if (this.sieve15) {
      return (
        (this.exportSize * parseFloat(this.sampleWeight)) /
        parseFloat(this.sieve15)
      ).toFixed(2);
    }
    return '';
  }

  @computed get yield14(): string {
    if (this.factor14) {
      return (this.exportSize / parseFloat(this.factor14)).toFixed(2);
    }
    return '';
  }

  @computed get yield15(): string {
    if (this.factor15) {
      return (this.exportSize / parseFloat(this.factor15)).toFixed(2);
    }
    return '';
  }

  @computed get group1Percentage(): string {
    return valuePercentage(this.group1, this.green);
  }
  @computed get group2Percentage(): string {
    return valuePercentage(this.group2, this.green);
  }
  @computed get brocaPercentage(): string {
    return valuePercentage(this.broca, this.green);
  }
  @computed get paloteoPercentage(): string {
    return valuePercentage(this.paloteo, this.green);
  }
  @computed get ripioPercentage(): string {
    return valuePercentage(this.ripio, this.green);
  }
  @computed get guayabasPercentage(): string {
    return valuePercentage(this.guayabas, this.green);
  }
  @computed get caracolPercentage(): string {
    return valuePercentage(this.caracol, this.green);
  }
}
export default PhysicalAnalysisStore;
