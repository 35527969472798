import { Provider } from 'mobx-react';
import React from 'react';

import { equalityFilter, likeFilter } from 'utils/filter';
import FilterContextProvider from 'utils/filterContext';
import Ability from 'utils/permissions/ability';

import SelectOptionArray from 'types/model/selectOption';

import SubproductsSelection from 'subproducts/candidates/components/SubproductsSelection';
import SubproductsStore, { SubproductsData } from 'subproducts/stores/SubproductsStore';
import { AbilityContext } from 'components/shared/Can';

interface SubproductsSelectionContainerProps {
  subproducts: SubproductsData;
  productionOrderId?: number;
  selectedSubproducts?: Array<{ local_identifier: string; quantity: number }>;
  qualities: SelectOptionArray;
}

const filterConfiguration = {
  local_identifier: {
    filter: likeFilter,
    value: undefined,
  },
  quality: {
    filter: equalityFilter,
    value: undefined,
  },
};

const SubproductsSelectionContainer: React.FC<SubproductsSelectionContainerProps> = ({
  subproducts,
  productionOrderId,
  selectedSubproducts,
  qualities,
}) => {
  return (
    <AbilityContext.Provider value={Ability}>
      <Provider
        subproductsStore={
          new SubproductsStore(
            subproducts,
            productionOrderId,
            selectedSubproducts,
            qualities
          )
        }
      >
        <FilterContextProvider value={filterConfiguration}>
          <SubproductsSelection />
        </FilterContextProvider>
      </Provider>
    </AbilityContext.Provider>
  );
};

export default SubproductsSelectionContainer;
