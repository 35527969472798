import React from 'react';
import { observer } from 'mobx-react';

import PriceFixing from './PriceFixing';
import BundleAction from './BundleAction';
import useStores from 'utils/useStores';
import styled from 'styled-components';
import { formatFutureFixingDate } from 'contracts/contractUtils';
import Checkbox from 'shared/Checkbox';
import ToolTip from 'shared/ToolTip/components/ToolTip';
import EditPriceFixing from './EditPriceFixing';

const ShipperSellerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & > * {
    flex-basis: 100%;
    padding: 0.35em;
  }
`;

const FuturesTable = () => {
  const { futuresStore } = useStores();

  const contracts = futuresStore.listContracts;
  const bundles = futuresStore.listBundles;

  return (
    <>
      <table className='table--full-border'>
        <thead>
          <tr>
            <th></th>
            <th className='table-col-left'>{I18n.translate('future_price_fixing.id')}</th>
            <th>{I18n.translate('future_price_fixing.bundle')}</th>
            <th>{I18n.translate('future_price_fixing.origin_country')}</th>
            <th>{I18n.translate('future_price_fixing.futures_position')}</th>
            <th>
              <ShipperSellerFlex>
                <div>{I18n.translate('future_price_fixing.shipper')}</div>
                <div>{I18n.translate('future_price_fixing.seller')}</div>
              </ShipperSellerFlex>
            </th>
            <th>{I18n.translate('future_price_fixing.unrealised')}</th>
            <th>{I18n.translate('future_price_fixing.realised')}</th>
            <th>{I18n.translate('future_price_fixing.bundle')}</th>
          </tr>
        </thead>
        <tbody>
          {contracts.map(
            ({
              id,
              mainId,
              futuresPosition,
              frontendDate,
              originCountry,
              units,
              volume,
              capacity,
            }) => {
              return (
                <tr key={mainId}>
                  <td className='align-center'></td>
                  <td className='lot--id' style={{ overflow: 'visible' }}>
                    <div className='l-auto-fill-flex'>
                      <ToolTip
                        title={I18n.translate('future_price_fixing.contract_units')}
                        infos={[
                          {
                            label: mainId,
                            value: `${volume}kg / ${capacity} = ${units} ${I18n.translate(
                              'future_price_fixing.units'
                            )}`,
                          },
                        ]}
                      />
                      <div>{mainId}</div>
                    </div>
                  </td>
                  <td className='align-center'>-</td>
                  <td className='align-center'>{originCountry}</td>
                  <td className='align-center'>{frontendDate}</td>
                  <td className='align-center'></td>
                  <td className='align-center'>-</td>
                  <td className='align-center'>-</td>
                  <td className='align-center'>
                    <Checkbox
                      checked={futuresStore.selectedContracts.has(id)}
                      onChange={() => futuresStore.toggleSelectContract(id)}
                      disabled={
                        futuresStore.currentFuturePosition !== '' &&
                        futuresStore.currentFuturePosition !== futuresPosition
                      }
                    />
                  </td>
                </tr>
              );
            }
          )}
          {bundles.map((bundle) => {
            const {
              id: bundleId,
              contracts,
              futuresPosition,
              frontendDate,
              unrealised,
              realised,
              changedContracts,
              originCountries,
            } = bundle;

            const date = new Date(futuresPosition);
            const bundleIdent = `BUN-${bundleId}`;

            return (
              <tr key={bundleIdent}>
                <td className='align-center'>
                  {bundle.shipperFixing || bundle.sellerFixing ? (
                    <EditPriceFixing
                      future={formatFutureFixingDate(date)}
                      bundle={bundle}
                    />
                  ) : null}
                </td>
                <td className='lot--id' style={{ overflow: 'visible' }}>
                  <div className='l-auto-fill-flex'>
                    <div>
                      <ToolTip
                        title={I18n.translate('future_price_fixing.contract_units')}
                        infos={contracts.map(({ mainId, units, volume, capacity }) => ({
                          label: mainId,
                          value: `${volume}kg / ${capacity} = ${units} ${I18n.translate(
                            'future_price_fixing.units'
                          )}`,
                        }))}
                      />
                    </div>
                    <div>
                      {contracts.map(({ mainId }) => (
                        <p key={mainId}>{mainId}</p>
                      ))}
                    </div>
                  </div>
                </td>
                <td className='lot--id align-center'>{bundleIdent}</td>
                <td className='align-center'>{originCountries.join(', ')}</td>
                <td className='align-center'>{frontendDate}</td>
                <td className='align-center'>
                  <PriceFixing future={formatFutureFixingDate(date)} bundle={bundle} />
                  {changedContracts.length > 0 && (
                    <p className='error'>
                      {I18n.translate('future_price_fixing.contracts_changed_warning')}:{' '}
                      <b>{changedContracts.join(', ')}</b>
                    </p>
                  )}
                </td>
                <td className='align-center'>{unrealised || '-'}</td>
                <td className='align-center'>{realised || '-'}</td>
                <td className='align-center'>
                  <BundleAction bundle={bundle} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default observer(FuturesTable);
