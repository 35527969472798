import React from 'react';
import { JsonApiResource } from 'types/utils/jsonApi';
import { Provider } from 'mobx-react';
import QualitiesPricesStore from 'fixedPriceContracts/stores/QualitiesPricesStore';
import type QualitiesPrice from 'fixedPriceContracts/types/QualitiesPrice';
import QualitiesPrices from 'fixedPriceContracts/components/QualitiesPrices';
import Quality from 'types/model/quality';

export interface QualitiesPricesContainerProps
  extends React.HTMLAttributes<HTMLTableElement> {
  qualities: JsonApiResource<Quality>[];
  qualitiesPrices: JsonApiResource<QualitiesPrice>[];
}

const QualitiesPricesContainer = ({
  qualities,
  qualitiesPrices,
}: QualitiesPricesContainerProps) => {
  return (
    <Provider qualitiesPricesStore={new QualitiesPricesStore(qualities, qualitiesPrices)}>
      <QualitiesPrices />
    </Provider>
  );
};

export default QualitiesPricesContainer;
