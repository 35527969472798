import React from 'react';
import ToolTipBody from 'shared/ToolTip/components/ToolTipBody';

interface SampleToolTipProps {
  samples: Array<any>;
}

const SampleToolTip: React.FC<SampleToolTipProps> = ({ samples }) => {
  const component = () => (
    <table>
      <tbody>
        {samples.map((sample, index) => (
          <tr key={index}>
            <td className='table-border align-center'>
              <p>{sample.sample_type}</p>
              <p>{sample.created_at}</p>
            </td>
            <td className='table-border align-center'>
              <p>{sample.local_identifier}</p>
              <p>{sample.frontend_identifier}</p>
            </td>
            <td className='table-border align-center'>
              <p>{sample.grade}</p>
              <p>{sample.quality}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return <ToolTipBody component={component} top={true} />;
};

export default SampleToolTip;
