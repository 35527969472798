import { observer } from 'mobx-react';
import React from 'react';

import { COLORS } from 'constants/colors';

import SelectOptionArray from 'types/model/selectOption';

import { Icons, ReasonCheckbox } from 'shared/Checkbox';

import OverlayBaseForm from './OverlayBaseForm';
import OverlayFooter from './OverlayFooter';
import OverlayHeader from './OverlayHeader';
import OverlayPortal from './OverlayPortal';
import OverlayTitle from './OverlayTitle';
import OverlayWrapper from './OverlayWrapper';
import HeaderSubtitle from './HeaderSubtitle';

import SelectGroup from 'components/groups/SelectGroup';
import OverlayTitlePrefix from './OverlayTitlePrefix';

export interface OverlayProps {
  id?: string;
  title: string;
  titlePrefix?: string;
  children?: React.ReactNode;
  center?: boolean;
  count?: number;
  distributeButtons?: boolean;
  disablePrevNextButtons?: boolean;
  hideTitleBorder?: boolean;
  maxCount?: number;
  noPadding?: boolean;
  autoWidth?: boolean;
  onNextClick?: (event: React.MouseEvent) => void;
  onPrevClick?: (event: React.MouseEvent) => void;
  onPrimaryClick?: (event: React.MouseEvent) => void;
  onSecondaryClick?: (event: React.MouseEvent) => void;
  primaryDisabled?: boolean;
  primaryText?: string;
  reasons?: SelectOptionArray;
  options?: SelectOptionArray;
  optionLabel?: string;
  optionPlaceholder?: string;
  handleReasonChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  secondaryText?: string;
  subtitle?: string;
  headerSubtitle?: string;
  selectedValue?: number;
}

const Overlay = ({
  title,
  titlePrefix,
  center,
  children,
  count,
  disablePrevNextButtons,
  distributeButtons,
  hideTitleBorder,
  maxCount,
  primaryDisabled,
  primaryText,
  noPadding,
  autoWidth,
  onNextClick,
  onPrevClick,
  onPrimaryClick,
  onSecondaryClick,
  reasons,
  secondaryText,
  subtitle,
  options,
  optionLabel,
  optionPlaceholder,
  handleReasonChange,
  headerSubtitle,
  selectedValue,
}: OverlayProps) => (
  <OverlayPortal>
    <OverlayWrapper>
      <OverlayBaseForm noPadding={noPadding} autoWidth={autoWidth}>
        {subtitle && (
          <OverlayHeader>
            {!disablePrevNextButtons && (
              <button
                data-cy='overlay_prev_button'
                type='button'
                className='button button--small header__button header__button--first'
                onClick={onPrevClick}
              >
                <svg className='icon' fill={COLORS.WHITE}>
                  <use href='#arrow-left' />
                </svg>
              </button>
            )}

            <span className='header__number'>
              <span className='header__number--big'>{count}</span>/{maxCount}
            </span>

            <span className='header__id'>{subtitle}</span>
            {!disablePrevNextButtons && (
              <button
                data-cy='overlay_next_button'
                type='button'
                className='button button--small header__button header__button--last'
                onClick={onNextClick}
              >
                <svg className='icon' fill={COLORS.WHITE}>
                  <use href='#arrow-right' />
                </svg>
              </button>
            )}
          </OverlayHeader>
        )}

        {title && (
          <OverlayTitle center={center} hideBorder={hideTitleBorder}>
            {titlePrefix && <OverlayTitlePrefix>{titlePrefix}</OverlayTitlePrefix>}
            {title} {headerSubtitle && <HeaderSubtitle>{headerSubtitle}</HeaderSubtitle>}
          </OverlayTitle>
        )}

        {children}

        <OverlayFooter
          className='l-item-stack-horizontal'
          withReason={!!reasons || !!options}
          distributeButtons={distributeButtons}
        >
          {reasons && (
            <ReasonCheckbox
              reasons={reasons}
              name={title}
              icon={Icons.cross}
              label='rejected'
            />
          )}
          {options && (
            <SelectGroup
              onChange={handleReasonChange}
              label={optionLabel}
              placeholder={optionPlaceholder}
              className={options.length === 0 ? 'overlay-select-group-unused' : ''}
              options={options}
              value={selectedValue}
            />
          )}

          <div className='overlay-buttons'>
            {secondaryText && onSecondaryClick && (
              <button
                data-cy='overlay_secondary_button'
                type='button'
                onClick={onSecondaryClick}
                className='button button--gray'
              >
                {secondaryText}
              </button>
            )}

            {primaryText && onPrimaryClick && (
              <button
                type='button'
                data-cy='overlay_primary_button'
                disabled={primaryDisabled}
                onClick={onPrimaryClick}
                className='button'
              >
                {primaryText}
              </button>
            )}
          </div>
        </OverlayFooter>
      </OverlayBaseForm>
    </OverlayWrapper>
  </OverlayPortal>
);

export default observer(Overlay);
