import BaseCustomerSampleType from 'customerSamples/types/BaseCustomerSampleType';

import SensorialAnalysis from 'customerSamples/models/SensorialAnalysis';
import CustomerSampleValue from 'customerSamples/models/CustomerSampleValue';

import { computed, observable } from 'mobx';

class Sample {
  public id: number;
  public sampleType: string;
  public mainIdentifier: string;
  public localIdentifier: string;
  public globalIdentifier: string;
  public currentSensorialAnalysis?: SensorialAnalysis;
  @observable public customerSampleValue?: CustomerSampleValue;

  constructor(sample: BaseCustomerSampleType) {
    this.id = sample.id;
    this.sampleType = sample.sample_type;
    this.localIdentifier = sample.local_identifier;
    this.globalIdentifier = sample.global_identifier;
    this.mainIdentifier = sample.main_identifier;

    this.customerSampleValue = new CustomerSampleValue(
      sample.customer_sample_value,
      this.id
    );

    this.currentSensorialAnalysis = sample.current_sensorial_analysis
      ? new SensorialAnalysis(sample.current_sensorial_analysis)
      : undefined;
  }

  @computed get showCustomerSampleValueButton(): boolean {
    return this.customerSampleValue?.outToCustomer || false;
  }
}

export default Sample;
