import React from 'react';

import SpaceBetween from 'components/elements/SpaceBetween';
import * as LotInfoBox from 'shared/LotInfoBox';
import TestGrid, { TestGridHeader, TestResult } from 'shared/TestGrid';

import {
  OverlayBaseForm,
  OverlayFooter,
  OverlayPortal,
  OverlayWrapper,
} from 'shared/Overlay';
import { OverlayProps } from 'shared/Overlay/components/Overlay';
import { LotSetTraceability, LotTraceability } from '../types/traceability';

export type SetPurchaseInformationOverlayProps = PartialPick<
  OverlayProps,
  'id' | 'title' | 'primaryText' | 'children'
> & {
  traceability: LotTraceability | LotSetTraceability;
};

function SetPurchaseInformationOverlay({
  traceability: { local_identifier },
  traceability,
  onPrimaryClick,
}: SetPurchaseInformationOverlayProps) {
  return (
    <OverlayPortal>
      <OverlayWrapper>
        <OverlayBaseForm>
          <LotInfoBox.Header>
            <SpaceBetween>
              <span>
                <LotInfoBox.HeaderInfo>
                  {I18n.translate('traceability.overlay_headline', {
                    identifier: local_identifier,
                  })}
                </LotInfoBox.HeaderInfo>
              </span>
            </SpaceBetween>
          </LotInfoBox.Header>

          <TestGrid>
            <div>
              <TestGridHeader>{I18n.translate('other.general')}</TestGridHeader>
              <TestGridHeader>
                {I18n.translate('lots.index.physical_anal')}
              </TestGridHeader>
            </div>

            <div>
              {traceability.type === 'set' && traceability.general && (
                <div>
                  <TestResult test={traceability.general} />
                </div>
              )}

              {traceability.type === 'lot' && traceability.physical_analysis && (
                <div>
                  <TestResult test={traceability.physical_analysis} />
                </div>
              )}
            </div>
          </TestGrid>

          <OverlayFooter>
            <button type='button' onClick={onPrimaryClick} className='button'>
              {I18n.translate('buttons.close')}
            </button>
          </OverlayFooter>
        </OverlayBaseForm>
      </OverlayWrapper>
    </OverlayPortal>
  );
}

export default SetPurchaseInformationOverlay;
