import { Provider } from 'mobx-react';
import React from 'react';
import type Sample from 'cuppingTable/type/Sample';
import CuppingTable from 'cuppingTable/components/CuppingTable';
import CuppingTableStore from 'cuppingTable/store/CuppingTableStore';
import SelectOptionArray from 'types/model/selectOption';
import FilterContextProvider from 'utils/filterContext';
import { equalityFilter, likeFilter } from 'utils/filter';
import CuppingCustomer from 'cuppingTable/type/CuppingCustomer';
interface CuppingTableContainerProps {
  cupping_customers?: CuppingCustomer[];
  samples: Sample[];
  user_options: SelectOptionArray;
  customer_options: SelectOptionArray;
  sample_types: SelectOptionArray;
  qualities: SelectOptionArray;
  grades: SelectOptionArray;
  process_types: SelectOptionArray;
  cupping_customer_options: Object;
  cupping_table_id?: number;
}

const filterConfiguration = {
  full_identifier: {
    filter: likeFilter,
    value: undefined,
  },
  sample_type: {
    filter: equalityFilter,
    value: undefined,
  },
  quality: {
    filter: equalityFilter,
    value: undefined,
  },
  grade: {
    filter: equalityFilter,
    value: undefined,
  },
  process_type: {
    filter: equalityFilter,
    value: undefined,
  },
};

const CuppingTableContainer = ({
  cupping_customers,
  samples,
  user_options,
  customer_options,
  sample_types,
  qualities,
  grades,
  process_types,
  cupping_customer_options,
  cupping_table_id,
}: CuppingTableContainerProps) => {
  return (
    <Provider
      cuppingTableStore={
        new CuppingTableStore(
          samples,
          user_options,
          customer_options,
          sample_types,
          qualities,
          grades,
          process_types,
          cupping_customer_options,
          cupping_customers,
          cupping_table_id
        )
      }
    >
      <FilterContextProvider value={filterConfiguration}>
        <CuppingTable />
      </FilterContextProvider>
    </Provider>
  );
};

export default CuppingTableContainer;
