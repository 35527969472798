import React, { useState } from 'react';
import styled from 'styled-components';

import COLORS from 'constants/colors';
import MenuItemType from 'types/model/menuItem';

import MenuItem, { MenuItemDivider, MenuItemTitle } from '../elements/MenuItem';
import MenuLinkComponent from './MenuLinkComponent';
import SubNavigationBlock from './SubNavigationBlock';

export interface MenuItemComponentProps {
  menuItem: MenuItemType;
}

interface MenuTitleLinkProps {
  active?: boolean;
}

const MenuTitleLink = styled.a<MenuTitleLinkProps>`
  color: ${({ active }) => (active ? COLORS.WHITE : COLORS.MENU_TEXT_COLOR)};
`;

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({ menuItem }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(menuItem.active);

  const testId = 'menu_' + menuItem.title.replace(' ', '_').toLowerCase();

  const toggleMenu = () => {
    if (menuItem.subNavigation) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const handleOnItemClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const subNavigation =
    menuItem.subNavigation &&
    menuItem.subNavigation.map((subMenuItem, index: number) => {
      if (subMenuItem.type === 'divider') {
        return <MenuItemDivider key={`divider-${index}`} />;
      } else {
        return (
          <MenuLinkComponent
            data-cy={
              testId + '_sub_' + subMenuItem.title.replace(/ /g, '_').toLowerCase()
            }
            menuItem={subMenuItem}
            key={subMenuItem.title}
            onClick={handleOnItemClick}
          />
        );
      }
    });

  const menuItemTitle = (
    <MenuItemTitle onClick={toggleMenu}>
      {menuItem.title}
      {menuItem.button && (
        <a
          className='button button--green button--small'
          data-cy={`${testId}_button`}
          href={menuItem.button_path}
          onClick={handleOnItemClick}
        >
          {menuItem.button}
        </a>
      )}
    </MenuItemTitle>
  );

  return (
    <MenuItem data-cy={testId} active={isMenuOpen}>
      {menuItem.path ? (
        <MenuTitleLink active={menuItem.active} href={menuItem.path}>
          {menuItemTitle}
        </MenuTitleLink>
      ) : (
        menuItemTitle
      )}

      {subNavigation && (
        <SubNavigationBlock active={isMenuOpen}>{subNavigation}</SubNavigationBlock>
      )}
    </MenuItem>
  );
};

export default MenuItemComponent;
