import { Provider } from 'mobx-react';
import React from 'react';

import FileUploaderStore from 'shared/FileUploader/stores/FileUploaderStore';

import LegStore from '../stores/LegStore';

import CreateTransportLeg, {
  Transportable,
} from 'transports/components/CreateTransportLeg';
import SelectOptionArray from 'types/model/selectOption';

interface CreateTransportLegContainerProps {
  lots: Array<Transportable>;
  lot_sets: Array<Transportable>;
  lost_coffees: Array<Transportable>;
  icos: Array<Transportable>;
  transport: any;
  personInspectionDispatching: SelectOptionArray;
  personsLoading: SelectOptionArray;
  nextDestinations: SelectOptionArray;
  nextDestinationId: number;
  withQuestionnaire: boolean;
  submitPath?: string;
  cancelPath?: string;
}

const CreateTransportLegContainer = ({
  nextDestinationId,
  ...props
}: CreateTransportLegContainerProps) => (
  <Provider
    legStore={new LegStore(nextDestinationId)}
    fileUploaderStore={new FileUploaderStore()}
  >
    <CreateTransportLeg {...props} nextDestinationId={nextDestinationId} />
  </Provider>
);

export default CreateTransportLegContainer;
