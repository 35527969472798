import { deserialise } from 'kitsu-core';

import Grade, { DeserializedGrade } from 'types/model/grade';
import { JsonApi } from 'types/utils/jsonApi';
import SelectOptionArray from 'types/model/selectOption';
import { observable, action } from 'mobx';

interface Options {
  humidityAcceptingReasons: SelectOptionArray;
  grades: JsonApi<Grade>;
  sampleTypes: SelectOptionArray;
  qualities: SelectOptionArray;
  marks: SelectOptionArray;
  origins: SelectOptionArray;
  roastingResults: SelectOptionArray;
  cuppingSessionEnabled: boolean;
}

class AnalyseAndAdjustStore {
  public humidityAcceptingReasons: SelectOptionArray;
  public grades: DeserializedGrade[];
  public sampleTypes: SelectOptionArray;
  public qualities: SelectOptionArray;
  public marks: SelectOptionArray;
  public origins: SelectOptionArray;
  public roastingResults: SelectOptionArray;
  public cuppingSessionEnabled: boolean;
  @observable public enableFlash: boolean;
  constructor({
    humidityAcceptingReasons,
    grades,
    sampleTypes,
    qualities,
    marks,
    origins,
    roastingResults,
    cuppingSessionEnabled,
  }: Options) {
    this.humidityAcceptingReasons = humidityAcceptingReasons;
    this.grades = deserialise(grades).data as DeserializedGrade[];
    this.sampleTypes = sampleTypes;
    this.qualities = qualities;
    this.marks = marks;
    this.origins = origins;
    this.roastingResults = roastingResults;
    this.cuppingSessionEnabled = cuppingSessionEnabled;
  }

  @action public setEnableFlash = (enableFlash: boolean) => {
    this.enableFlash = enableFlash;
  };
}

export default AnalyseAndAdjustStore;
