import React from 'react';
import styled from 'styled-components';

import MenuItem from 'types/model/menuItem';
import MenuItemComponent from './MenuItemComponent';

export const NavigationWrapper = styled.ul``;

export interface NavigationComponentProps extends React.HTMLAttributes<HTMLUListElement> {
  menuItems: MenuItem[];
}

const NavigationComponent: React.FC<NavigationComponentProps> = ({ menuItems }) => {
  let menuItemComponents = Array<JSX.Element>();
  if (menuItems) {
    menuItemComponents = menuItems.map((menuItem) => {
      return <MenuItemComponent menuItem={menuItem} key={menuItem.title} />;
    });
  }

  return <NavigationWrapper>{menuItemComponents}</NavigationWrapper>;
};

export default NavigationComponent;
