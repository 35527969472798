import React, { createRef, useState, useEffect } from 'react';
import Routes from 'routes';

import { customFetch } from 'utils/fetch';
import Overlay from 'shared/Overlay';
import SelectGroup from 'components/groups/SelectGroup';
import Grid from 'components/elements/Grid';

import { toISO8601 } from 'utils/dateFormat';
import { toInputFieldTime } from 'utils/timeFormat';

import BaseGroupComponent from 'components/groups/BaseGroup';
import Input from 'components/elements/Input';
import MissingCoffeeComponent from './MissingCoffeeComponent';
import { addTestingIdentifier } from 'utils/testing';

export interface StartMillingOrderOverlayProps {
  id: string;
  identifier: string;
  withCheck?: boolean;
}

const StartMillingOrderOverlay = ({
  id,
  identifier,
  withCheck = true,
}: StartMillingOrderOverlayProps) => {
  const timeInput = createRef<HTMLInputElement>();
  const dateInput = createRef<HTMLInputElement>();

  const currentDate = new Date();
  const fmtDate = toISO8601(currentDate);
  const fmtTime = toInputFieldTime(currentDate);

  const [showOverlay, setOverlay] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [missingCoffee, setMissingCoffee] = useState([]);

  const [productionLine, setProductionLine] = useState('');
  const [date, setDate] = useState(fmtDate);
  const [time, setTime] = useState(fmtTime);

  const minTime = toInputFieldTime(new Date(Date.now() - 1000 * 60 * 5));

  const handleStartClick = () => {
    setOverlay(true);

    if (withCheck) {
      setLoading(true);
      customFetch(
        Routes.coffee_location_check_api_v1_milling_order_path(id),
        undefined,
        'GET'
      ).then((response) => {
        if (response.missing_count > 0) {
          setMissingCoffee(response.coffee_status.filter((coffee) => !coffee.at_mill));
        }

        setLoading(false);
      });
    }
  };

  const handleOnPrimaryClick = () => {
    setOverlay(false);
    customFetch(Routes.start_api_v1_milling_order_path(id), {
      production_line: productionLine,
      start_date: `${date}T${time}`,
    }).then(() => {
      window.location.reload();
    });
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  useEffect(() => {
    setFormValid(
      time !== '' &&
        timeInput.current?.validationMessage.length === 0 &&
        date !== '' &&
        dateInput.current?.validationMessage.length === 0 &&
        productionLine !== ''
    );
  }, [productionLine, date, time]);

  return (
    <>
      {showOverlay && (
        <Overlay
          title={I18n.translate('milling_orders.start_milling_overlay.headline')}
          titlePrefix={identifier}
          primaryDisabled={!formValid}
          primaryText={I18n.translate('buttons.start')}
          onPrimaryClick={handleOnPrimaryClick}
          secondaryText={I18n.translate('buttons.cancel')}
          onSecondaryClick={handleOnSecondaryClick}
        >
          {loading ? (
            <div>Checking for coffee...</div>
          ) : missingCoffee.length > 0 ? (
            <MissingCoffeeComponent missingCoffee={missingCoffee} />
          ) : (
            <Grid grid={10}>
              <SelectGroup
                label={I18n.translate(
                  'milling_orders.start_milling_overlay.production_line_label'
                )}
                placeholder={I18n.translate(
                  'milling_orders.start_milling_overlay.select_a_production_line'
                )}
                value={productionLine}
                onChange={(e) => setProductionLine(e.target.value)}
                options={[{ id: '1', name: '1' }]}
                {...addTestingIdentifier('production_line_select')}
              />
              <BaseGroupComponent
                label={I18n.translate(
                  'milling_orders.start_milling_overlay.start_date_label'
                )}
              >
                <Input
                  ref={dateInput}
                  type='date'
                  min={fmtDate}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  {...addTestingIdentifier('start_date_input')}
                />
              </BaseGroupComponent>
              <BaseGroupComponent
                label={I18n.translate(
                  'milling_orders.start_milling_overlay.start_time_label'
                )}
              >
                <Input
                  ref={timeInput}
                  type='time'
                  min={minTime}
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  {...addTestingIdentifier('start_time_input')}
                />
              </BaseGroupComponent>
            </Grid>
          )}
        </Overlay>
      )}

      <button
        onClick={handleStartClick}
        type='button'
        className='button button--small button--green'
        {...addTestingIdentifier('start_milling_button')}
      >
        {I18n.translate('buttons.start')}
      </button>
    </>
  );
};

export default StartMillingOrderOverlay;
