import { SelectOption } from 'samples/receive_samples/types/SelectOption';

class ReceptionOption {
  public id?: string | number;
  public name: string;
  public type: string;

  constructor({ id, name, type }: SelectOption) {
    this.id = id;
    this.name = name;
    this.type = type;
  }

  get optionIdentifier() {
    if (this.id !== undefined) {
      return `${this.type}_${this.id}`;
    } else {
      return this.type;
    }
  }
}

export default ReceptionOption;
