import { action, observable, computed } from 'mobx';
import { toISO8601 } from 'utils/dateFormat';
import DefaultDateRangeType from 'types/model/defaultDateRange';

export class DateRange {
  @observable public startMonth: number;
  @observable public startYear: number;
  @observable public endMonth: number;
  @observable public endYear: number;

  constructor(dateRange: DefaultDateRangeType) {
    this.startMonth = dateRange.start_month;
    this.startYear = dateRange.start_year;
    this.endMonth = dateRange.end_month;
    this.endYear = dateRange.end_year;
  }

  @action setStartMonth(month: string | number) {
    this.startMonth = Number(month);
  }

  @action setStartYear(year: string | number) {
    this.startYear = Number(year);
  }

  @action setEndMonth(month: string | number) {
    this.endMonth = Number(month);
  }

  @action setEndYear(year: string | number) {
    this.endYear = Number(year);
  }

  @computed get startRange() {
    // Javascript Months start at 0
    return toISO8601(new Date(this.startYear, this.startMonth - 1));
  }

  @computed get endRange() {
    // Javascript Months start at 0
    return toISO8601(new Date(this.endYear, this.endMonth - 1));
  }
}

export default DateRange;
