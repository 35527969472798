import { observer } from 'mobx-react';
import React from 'react';
import Routes from 'routes';

import { customFetch } from 'utils/fetch';
import useStores from 'utils/useStores';

import { TestResult } from 'shared/TestGrid';

import { HumidityAnalysisOverlay } from 'shared/HumidityAnalysis';

import { NewTestCard } from 'shared/LotInfoBox';
import type IHumidityAnalysisResult from 'samples/types/IHumidityAnalysisResult';
import type ISample from '../../types/ISample';

export interface HumidityAnalysesProps extends React.HTMLAttributes<HTMLDivElement> {
  sample: ISample;
  humidityAnalyses: IHumidityAnalysisResult[];
  enableNewButton: boolean;
}

function HumidityAnalyses({
  sample,
  humidityAnalyses,
  enableNewButton,
}: HumidityAnalysesProps): JSX.Element {
  const { analyseAndAdjustStore } = useStores();
  const [showOverlay, setShowOverlay] = React.useState(false);

  const handleOnPrimaryClick = async (
    _event: React.MouseEvent,
    values: {}
  ): Promise<void> => {
    return customFetch(
      Routes.api_v1_sample_humidity_analyses_path(sample.id),
      values
    ).then(() => {
      setShowOverlay(false);
      window.location.reload();
    });
  };

  const handleOnSecondaryClick = (_event: React.MouseEvent) => {
    setShowOverlay(false);
  };

  const handleOnButtonClick = (_event: React.MouseEvent) => {
    if (!enableNewButton) {
      analyseAndAdjustStore.setEnableFlash(true);

      return;
    }

    return setShowOverlay(true);
  };

  return (
    <div>
      {humidityAnalyses.map((humidityAnalysis) => (
        <TestResult key={humidityAnalysis.id} test={humidityAnalysis} />
      ))}

      {showOverlay && (
        <HumidityAnalysisOverlay
          identifier={sample.local_identifier}
          validations={sample.humidity_analysis_validations}
          reasons={analyseAndAdjustStore.humidityAcceptingReasons}
          onPrimaryClick={handleOnPrimaryClick}
          onSecondaryClick={handleOnSecondaryClick}
        />
      )}

      <NewTestCard onClick={handleOnButtonClick}>
        <button
          className={`button button--small button--${enableNewButton ? 'green' : 'gray'}`}
        >
          + {I18n.translate('buttons.new')}
        </button>
      </NewTestCard>
    </div>
  );
}

export default observer(HumidityAnalyses);
