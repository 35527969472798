import React, { useState } from 'react';
import styled from 'styled-components';

import COLORS from 'constants/colors';

import InputGroup from 'components/groups/InputGroup';
import FormButton from './FormButton';

const RowWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${COLORS.BORDERS};
  padding-bottom: 1rem;
`;

const InputText = styled.div`
  padding-right: 1.875rem;
  font-size: 1rem;
  font-weight: bold;
`;

const Wrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const Input = styled.div`
  .input-group {
    margin-bottom: 0;
  }

  input {
    width: 11rem;
    text-align: center;
    font-size: 1rem;
  }
`;

export interface ShipmentExportDataProps {
  billOfLading?: string;
  billOfLadingDisabled?: boolean;
  onSaveBillOfLanding: (shipmentInstructionId: any, billOfLandingExport) => void;
  export_: any;
}

const ShipmentExportData = ({
  onSaveBillOfLanding,
  export_,
}: ShipmentExportDataProps) => {
  const [billOfLadingExport, setBillOfLadingExport] = useState(
    export_.shipmentSettings.shipmentInstruction.bill_of_lading
  );

  const onBillOfLandingChangeHandler = (e) => {
    export_.bl_can_be_edited = !(
      export_.shipmentSettings.shipmentInstruction.bill_of_lading == e.target.value
    );

    setBillOfLadingExport(e.target.value);
    setButtonDisabled(false);
  };

  const onClickButton = (export_) => {
    if (billOfLadingExport !== undefined) {
      onSaveBillOfLanding(
        export_.shipmentSettings.shipmentInstruction.id,
        billOfLadingExport
      );
      setButtonDisabled(true);
    }
  };

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  return (
    <>
      <RowWrapper>
        <Wrapper>
          <InputText>{I18n.translate('exports.form.isf.bill_of_lading_label')}</InputText>

          <Input>
            <InputGroup
              id='bill_of_lading'
              defaultValue={billOfLadingExport}
              type='text'
              onChange={onBillOfLandingChangeHandler}
            />
          </Input>
        </Wrapper>
        <FormButton>
          <button
            disabled={!export_.bl_can_be_edited || isButtonDisabled}
            type='button'
            className='button'
            onClick={() => onClickButton(export_)}
          >
            {I18n.translate('buttons.save')}
          </button>
        </FormButton>
      </RowWrapper>
    </>
  );
};

export default ShipmentExportData;
