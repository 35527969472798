import React from 'react';
import { LotTraceability } from 'traceability/types/traceability';
import { LOT_STATE } from 'types/model/lot';

export interface WarehouseInfoProps {
  state?: LotTraceability['state'];
  warehouseName: string;
}

function WarehouseInfo({ state, warehouseName }: WarehouseInfoProps) {
  const infoText = state === LOT_STATE.IN_TRANSIT ? 'in_transit' : 'sent_to';

  return (
    <div>
      <span>{I18n.translate(`traceability.${infoText}`)}</span>
      <strong>{warehouseName}</strong>
    </div>
  );
}

export default WarehouseInfo;
