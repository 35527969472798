import React from 'react';
import { observer } from 'mobx-react';

import InputGroupComponent from 'components/groups/InputGroup';
import Checkbox from 'shared/Checkbox';
import { displayWithCurrency, exchangedValue } from 'utils/currency';
import useStores from 'utils/useStores';

function PalletizingCost(): JSX.Element | null {
  const { deliveryOrderIndexStore } = useStores();
  const { deliveryOrderCostStore } = deliveryOrderIndexStore;
  const { deliveryOrderCost, pallets } = deliveryOrderCostStore;
  const {
    palletizingCosts,
    destinationWarehouseCost: { sameCurrency, exchangeRate },
  } = deliveryOrderCost;

  const handleOnQuantityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const quantity = parseInt(event.currentTarget.value);

    if (!Number.isNaN(quantity)) {
      deliveryOrderCost.setPalletQuantity(quantity);
    }
  };

  return palletizingCosts > 0 ? (
    <tr>
      <td>
        <Checkbox
          condensed={true}
          label='Palletizing'
          checked={deliveryOrderCost.palletizingChecked}
          onChange={deliveryOrderCost.togglePalletizingChecked}
        />
      </td>

      <td>
        <InputGroupComponent
          type='number'
          append='#'
          disabled={!deliveryOrderCost.palletizingChecked}
          onChange={handleOnQuantityChange}
          min={0}
          value={pallets}
          inline
        />
      </td>

      <td>{displayWithCurrency(palletizingCosts.toFixed(2), exchangeRate.from)}</td>

      {!sameCurrency && (
        <td>
          {displayWithCurrency(
            exchangedValue(palletizingCosts, exchangeRate.rate),
            exchangeRate.to
          )}
        </td>
      )}
    </tr>
  ) : null;
}

export default observer(PalletizingCost);
