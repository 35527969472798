import ContractType from 'customerSamples/types/ContractType';
import Ico from 'customerSamples/models/Ico';

export class Contract {
  public id: number;
  public mainIdentifier?: string;
  public icos?: Ico[];

  constructor(contract: ContractType) {
    this.id = contract.id;
    this.mainIdentifier = contract.main_identifier;

    const icos = contract.icos?.map((ico) => new Ico(ico));
    this.icos = icos;
  }
}

export default Contract;
