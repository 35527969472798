import { action, observable } from 'mobx';

import type { AvailableCoffeItem as AvailableCoffeeItemType } from 'types/model/lot';

class AvailableCoffeItem {
  public attributes: AvailableCoffeeItemType;

  @observable public quantity: string;
  @observable public selected: boolean;
  @observable public reserved: boolean;

  constructor(item: AvailableCoffeeItemType) {
    this.attributes = item;
    this.selected = false;
    this.reserved = false;
    this.quantity = item.available_weight.toString();
  }

  @action public setQuantity = (value: string) => {
    this.quantity = value;
  };

  @action public setSelected = (selected: boolean) => {
    this.selected = selected;
  };

  @action public setReserved = (reserved: boolean) => {
    this.reserved = reserved;
  };
}

export default AvailableCoffeItem;
