import React from 'react';

import type DeliveryOrder from 'traceability/types/DeliveryOrder';

import { TileList } from 'shared/Tile';
import Details from './Details';
import styled from 'styled-components';
import Detail from './Detail';
import { ContractTraceabilityDetails } from './ContractTraceabilityHeader';

export interface DeliveryOrderItemProps {
  deliveryOrder: DeliveryOrder;
}

const DeliveryOrderDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

function DeliveryOrderItem({ deliveryOrder }: DeliveryOrderItemProps) {
  return (
    <div>
      <TileList>
        <ContractTraceabilityDetails />

        <DeliveryOrderDetails>
          <Detail>{deliveryOrder.date}</Detail>
          <Detail>{deliveryOrder.identifier}</Detail>
          <Detail>
            {I18n.translate('traceability.contracts.units', {
              units: deliveryOrder.units,
            })}
          </Detail>
        </DeliveryOrderDetails>

        <Details>
          {I18n.translate(
            `activerecord.attributes.fulfillment/delivery_order.aasm_state.${deliveryOrder.state}`
          )}
        </Details>
      </TileList>
    </div>
  );
}

export default DeliveryOrderItem;
