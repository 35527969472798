import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { COLORS } from 'constants/colors';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
import Export from 'exports/models/Export';
import SelectGroup from 'components/groups/SelectGroup';
import { AbilityContext } from 'components/shared/Can';

const Wrapper = styled.div`
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  width: 100%;
  padding: 1rem;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHT_GRAY};
`;

const Block = styled.div`
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  width: 30%;
`;

const Headline = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${COLORS.BLACK};
`;

const Text = styled.p`
  width: 100%;
  padding-bottom: 0.625rem;
  color: ${COLORS.BLACK_70};

  &:last-child {
    padding-bottom: 0;
  }
`;

export interface ContainerInformationProps {
  exportModel: Export;
}

function ContainerInformation({ exportModel }: ContainerInformationProps): JSX.Element {
  const ability = useContext(AbilityContext);
  const { exportsStore } = useStores();
  const { ports, moveExport } = exportsStore;
  const {
    mainIdentifier,
    shippingPortId,
    originOffice,
    incoterm,
    destinationPortId,
    updateShippingPort,
    editableDestinationPort,
  } = exportModel;

  const selectableOriginPorts = useMemo(() => {
    const currentPort = ports.find(({ id }) => shippingPortId === id);

    return ports.filter(
      (port) => port.port_type === 'origin' && port.country_id === currentPort?.country_id
    );
  }, [shippingPortId, ports]);

  const selectableDestinationPorts = useMemo(
    () => ports.filter(({ port_type }) => port_type === 'destination'),
    [ports]
  );

  const handleShippingPortChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const nextShippingPortId = parseInt(event.target.value);

    if (nextShippingPortId) {
      updateShippingPort(nextShippingPortId);
    }
  };

  const handleDestinationPortChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ): Promise<void> => {
    const nextDestinationPortId = parseInt(event.target.value);

    if (nextDestinationPortId) {
      moveExport(exportModel, nextDestinationPortId);
    }
  };

  const disableDestinationPortEdit =
    ability.cannot('set_destination_port', 'API::V1::Export') || !editableDestinationPort;

  return (
    <Wrapper>
      <Block>
        <Headline>{I18n.translate('exports.export_information.header')}</Headline>
      </Block>

      <Block>
        <Headline>{I18n.translate('exports.export_information.reference')}</Headline>
        <Text>{mainIdentifier}</Text>

        <SelectGroup
          onChange={handleShippingPortChange}
          label={I18n.translate('exports.export_information.origin_port')}
          options={selectableOriginPorts}
          value={shippingPortId}
          disabled={!exportModel.canRemoveIcos}
          condensed
        />

        <Headline>{I18n.translate('exports.export_information.origin_office')}</Headline>
        <Text>{originOffice}</Text>
      </Block>

      <Block>
        <Headline>{I18n.translate('exports.export_information.incoterm')}</Headline>
        <Text>{incoterm}</Text>

        <SelectGroup
          onChange={handleDestinationPortChange}
          label={I18n.translate('exports.export_information.destination_port')}
          options={selectableDestinationPorts}
          value={destinationPortId}
          disabled={disableDestinationPortEdit}
          condensed
        />
      </Block>
    </Wrapper>
  );
}

export default observer(ContainerInformation);
