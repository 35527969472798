import debounce from 'lodash.debounce';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';
import styled from 'styled-components';
import useStores from 'utils/useStores';

const FormWrapper = styled.div`
  flex-basis: 61.8%;
`;

const TypeWrapper = styled.div`
  width: 40%;
`;

const FilterForm: React.FC = () => {
  const { transportSamplesStore } = useStores();
  const { paginatedTableStore, searchString } = transportSamplesStore;
  const { dataUrl } = paginatedTableStore;

  const handleOnSubmit = (search) => {
    const urlParams = {
      search: search,
    };
    const params = paginatedTableStore.urlStringToPaginateParams(
      window.location.href.split('?')[1]
    );
    paginatedTableStore.loadPage(params['page'], urlParams);
  };

  const filterValueChanged = useMemo(() => debounce(handleOnSubmit, 500), []);

  useMemo(() => {
    filterValueChanged(searchString);
  }, [searchString]);

  const handleSearchChange = (e) => {
    transportSamplesStore.setSearchString(e.currentTarget.value);
  };

  return (
    <FormWrapper>
      <form
        action={dataUrl()}
        acceptCharset='UTF-8'
        method='GET'
        onSubmit={(event) => event.preventDefault()}
      >
        <div className='l-item-stack-horizontal l-item-stack-horizontal--wide l-item-stack-horizontal--space-between'>
          <AuthenticityToken />
          <TypeWrapper>
            <input
              type='text'
              placeholder={I18n.translate('samples.send.filter')}
              onChange={handleSearchChange}
              name='search'
            />
          </TypeWrapper>
        </div>
      </form>
    </FormWrapper>
  );
};

export default observer(FilterForm);
