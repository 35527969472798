import { Provider } from 'mobx-react';
import React from 'react';

import OverlayStore from '../stores/OverlayStore';
import PhysicalAnalysisStore from '../stores/PhysicalAnalysisStore';

import PhysicalAnalysisComponent, {
  Validation,
} from '../components/forms/lot/PhysicalAnalysisComponent';
import { ReasonGet } from 'types/model/reason';
import IPhysicalAnalysis from 'types/model/IPhysicalAnalysis';

export interface PhysicalAnalysisContainerProps {
  physicalAnalysis: IPhysicalAnalysis;
  exportSize: number;
  sumTolerance: number;
  reasons: ReasonGet[];
  validations: {
    mermaPercentage: Validation;
    sieve0Percentage: Validation;
    sieve12Percentage: Validation;
    sieve14Percentage: Validation;
    sieve15Percentage: Validation;
    factor14: Validation;
    factor15: Validation;
    group1Percentage: Validation;
    group2Percentage: Validation;
    brocaPercentage: Validation;
    paloteoPercentage: Validation;
    ripioPercentage: Validation;
    guayabasPercentage: Validation;
    caracolPercentage: Validation;
  };
}

const PhysicalAnalysisContainer = ({
  physicalAnalysis,
  exportSize,
  sumTolerance,
  ...props
}: PhysicalAnalysisContainerProps) => {
  return (
    <Provider
      overlayStore={new OverlayStore()}
      physicalAnalysisStore={
        new PhysicalAnalysisStore(physicalAnalysis, exportSize, sumTolerance)
      }
    >
      <PhysicalAnalysisComponent {...props} />
    </Provider>
  );
};

export default PhysicalAnalysisContainer;
