import { IField } from 'components/shared/form/IField';

export const getField = (fields: IField[], name: string): IField | undefined =>
  fields.find((field) => field.name == name);

export const getFields = (fields: IField[], names: string[]): IField[] =>
  fields.filter((field) => names.includes(field.name));

export const hasSegment = (fields: IField[], groupName: string): boolean =>
  fields.find((field) => field.group == groupName) != undefined;
