import { action, IObservableArray, observable } from 'mobx';

import type IIcoList from '../types/icoList';

import FinalizeableResource from './FinalizableResource';
import IcoListing from './IcoListing';

class IcoList extends FinalizeableResource<IIcoList> {
  @observable finalized: boolean;
  @observable ico_listings: IObservableArray<IcoListing>;

  @action public updateAttributes({ id, finalized, ico_listings }: IIcoList) {
    this.finalized = finalized;
    this.id = id;

    if (ico_listings) {
      const icoListings = ico_listings?.map((ico_listing) => new IcoListing(ico_listing));

      this.ico_listings = observable.array(icoListings);
    }
  }

  get isFinalizable() {
    return false;
  }
}

export default IcoList;
