import React from 'react';
import Checkbox, { CheckboxProps } from 'shared/Checkbox';

interface WSRSelectionCheckboxProps extends CheckboxProps {
  onCheck: () => any;
  onUncheck: () => any;
  checked: boolean;
  disabled?: boolean;
}

function WSRSelectionCheckbox({
  onCheck,
  onUncheck,
  checked,
  disabled = false,
  ...rest
}: WSRSelectionCheckboxProps) {
  return (
    <Checkbox
      checked={checked}
      onChange={(checked) => (checked ? onCheck() : onUncheck())}
      disabled={disabled}
      {...rest}
    />
  );
}

export default WSRSelectionCheckbox;
