import { action, computed, observable, reaction } from 'mobx';

import { DefectGet } from 'types/model/defect';
import { DescriptorBodyGet } from 'types/model/descriptorBody';
import { DescriptorFlavourGet } from 'types/model/descriptorFlavour';
import { DescriptorOverallGet } from 'types/model/descriptorOverall';
import { DescriptorRoastGet } from 'types/model/descriptorRoast';
import { AlternativeGet } from 'types/model/alternative';
import CheckboxGroup from '../models/CheckboxGroup';
import RadioGroup from '../models/RadioGroup';

import Conclusion from './Conclusion';
import ISample from 'cuppingSession/types/ISample';

export interface Characteristics {
  defects: CheckboxGroup<DefectGet>;
  descriptorFlavours: CheckboxGroup<DescriptorFlavourGet>;
  descriptorBodies: RadioGroup<DescriptorBodyGet>;
  descriptorOveralls: RadioGroup<DescriptorOverallGet>;
  descriptorRoasts: RadioGroup<DescriptorRoastGet>;
  alternatives: CheckboxGroup<AlternativeGet>;
  conclusion: Conclusion;
}

class SensorialAnalysis {
  public sample: ISample;
  public defects: CheckboxGroup<DefectGet>;
  public descriptorFlavours: CheckboxGroup<DescriptorFlavourGet>;
  public descriptorBodies: RadioGroup<DescriptorBodyGet>;
  public descriptorOveralls: RadioGroup<DescriptorOverallGet>;
  public descriptorRoasts: RadioGroup<DescriptorRoastGet>;
  public alternatives: CheckboxGroup<AlternativeGet>;
  public conclusion: Conclusion;
  public cuppingTableNumber: string;
  @observable public observation: string;
  @observable public defectsSelected: boolean;
  @observable public reasonSelected: boolean;
  @observable public flavourSelected: boolean;
  @observable public overallSelected: boolean;
  @observable public bodySelected: boolean;
  @observable public score: number | null;
  @observable public badScore: boolean;
  @observable public quality: string;
  @observable public markAsReject: boolean;

  constructor(
    sample: ISample,
    {
      defects,
      alternatives,
      descriptorFlavours,
      descriptorOveralls,
      descriptorBodies,
      descriptorRoasts,
      conclusion,
    }: Characteristics
  ) {
    const {
      data: {
        attributes: {
          rejected,
          accepted,
          accepting_reason_id,
          observation,
          quality,
          mark_as_reject,
          selected_body,
          selected_overall,
          descriptor_roast_id,
          selected_defects,
          selected_flavours,
          cupping_table_number,
          values: {
            final_score: { value: finalScore },
          },
        },
      },
    } = sample.current_sensorial_analyses;
    descriptorBodies.selectRadio(selected_body);
    descriptorOveralls.selectRadio(selected_overall);
    descriptorRoasts.selectRadio(descriptor_roast_id);
    this.sample = sample;
    this.defects = defects;
    this.descriptorBodies = descriptorBodies;
    this.descriptorFlavours = descriptorFlavours;
    this.descriptorOveralls = descriptorOveralls;
    this.descriptorRoasts = descriptorRoasts;
    this.alternatives = alternatives;
    this.conclusion = conclusion;
    this.observation = observation || '';
    this.quality = quality;
    this.markAsReject = mark_as_reject;
    this.defectsSelected = !!selected_defects.length;
    this.reasonSelected = !!accepting_reason_id;
    this.flavourSelected = !!selected_flavours.length;
    this.overallSelected = !!selected_overall;
    this.bodySelected = !!selected_body;
    this.score = finalScore || null;
    this.badScore = mark_as_reject;
    this.conclusion.accepted = accepted && !rejected;
    this.conclusion.rejected = rejected || false;
    this.conclusion.reasonId = accepting_reason_id;
    this.cuppingTableNumber = cupping_table_number;

    reaction(() => this.defects.checkboxCount, this.handleDefectsSelection);
    reaction(() => this.conclusion.reasonId, this.handleReasonSelection);
    reaction(() => this.conclusion.accepted, this.handleAcceptedSelection);
    reaction(() => this.conclusion.rejected, this.handleRejectedSelection);
    reaction(
      () => this.descriptorFlavours.selectedCheckboxes,
      this.handleFlavourSelection
    );
    reaction(() => this.descriptorOveralls.selectedRadio, this.handleOverallSelection);
    reaction(() => this.descriptorBodies.selectedRadio, this.handleBodySelection);
    // reaction(() => this.descriptorRoasts.selectedRadio, this.handleRoastSelection);
    reaction(
      () => this.conclusion.accepted,
      () => this.completed
    );
  }

  private handleDefectsSelection = (count: number) => {
    this.setDefectsSelected(count > 0);

    this.conclusion.setRejected(count > 0);
    this.conclusion.setAccepted(false);
  };

  private handleAcceptedSelection = (accepted: boolean) => {
    // uncheck and defectsSelected
    if (!accepted && this.defectsSelected) {
      this.conclusion.setRejected(true);
    }
  };

  private handleRejectedSelection = (rejected: boolean) => {
    if (!this.conclusion.accepted && !rejected && this.defectsSelected) {
      this.conclusion.setRejected(true);
    }
  };

  private handleReasonSelection = (reasonId: string) => {
    if (reasonId === '') {
      this.conclusion.setRejected(true);
      this.conclusion.setAccepted(false);
    }

    this.setReasonSelected(reasonId !== '');
  };

  private handleFlavourSelection = () => {
    this.flavourSelected = this.descriptorFlavours.selectedCheckboxes.length >= 3;
  };

  private handleOverallSelection = () => {
    this.overallSelected = true;
  };

  private handleBodySelection = () => {
    this.bodySelected = true;
  };

  @action public setQuality = (quality: string) => {
    this.quality = quality;
  };

  @action public setObservation = (observation: string) => {
    this.observation = observation;
  };

  @action public setDefectsSelected = (defectsSelected: boolean) => {
    this.defectsSelected = defectsSelected;
  };

  @action public setReasonSelected = (reasonSelected: boolean) => {
    this.reasonSelected = reasonSelected;
  };

  @action public setBodySelected = (bodySelected: boolean) => {
    this.bodySelected = bodySelected;
  };

  @action public setScore = (score: number) => {
    this.score = score;
  };

  @action public setBadScore = (state: boolean) => {
    this.badScore = state;
  };

  @action public setRejectGrade = (reject: boolean) => {
    this.setBadScore(reject);
    this.conclusion.setRejected(reject);

    if (this.baseCompleted) {
      this.conclusion.setAccepted(!reject);
    }
  };

  @computed get baseCompleted(): boolean {
    return this.flavourSelected && this.overallSelected && this.bodySelected;
  }

  @computed get completed(): boolean {
    let completed =
      this.baseCompleted && !!this.score && this.score > 1 && this.score < 100;

    if (this.defectsSelected) {
      completed =
        completed &&
        (this.conclusion.rejected ||
          (this.conclusion.accepted && this.conclusion.reasonIsSet));
    } else {
      completed = completed && (this.conclusion.accepted || this.conclusion.rejected);
    }

    return completed;
  }
}

export default SensorialAnalysis;
