import { observer } from 'mobx-react';
import React from 'react';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import Grid from 'components/elements/Grid';

import Customer from 'customers/models/Customer';
import { ReasonGet } from 'types/model/reason';
import { VARIANT } from 'constants/inputTypes';
import { IField } from 'components/shared/form/IField';
import SelectOptionArray from 'types/model/selectOption';
import useField from 'customers/hooks/useField';

export interface ContractTermsProps {
  customer: Customer;
  paymentTerms: ReasonGet[];
  arbitrations: ReasonGet[];
  currencies: SelectOptionArray;
  qualifications: ReasonGet[];
  unitsOfMeasurement: ReasonGet[];
  fields: IField[];
}

const ContractTerms = ({
  customer,
  paymentTerms,
  arbitrations,
  currencies,
  qualifications,
  unitsOfMeasurement,
  fields,
}: ContractTermsProps) => {
  const customerArbitrationField = useField(fields, 'customer_arbitration');
  const customerCurrencyField = useField(fields, 'customer_currency');
  const customerUnitOfMeasureField = useField(fields, 'customer_unit_of_measure');
  const customerPenaltyField = useField(fields, 'customer_penalty');
  const customerQualificationField = useField(fields, 'customer_qualification');

  return (
    <FieldSetWithTitle title={I18n.translate('customers.form.contract_terms')}>
      <Grid grid={12}>
        <SelectGroup
          name='customer[payment_term_id]'
          label={I18n.translate('attributes.payment_terms')}
          options={paymentTerms}
          value={paymentTerms[0]?.id}
          disabled={true}
        />

        {/* Show Select for Payment Terms, but disable, POST params via hidden field */}
        <input
          type='hidden'
          name='customer[payment_term_id]'
          value={paymentTerms[0]?.id}
        />

        <SelectGroup
          name='customer[arbitration_id]'
          label={I18n.translate('attributes.arbitration')}
          options={arbitrations}
          placeholder={I18n.translate('customers.form.select_arbitration')}
          onChange={(e) => customer.setArbitrationId(e.target.value)}
          required={true}
          value={customer.arbitrationId ? customer.arbitrationId : ''}
          disabled={!customerArbitrationField?.enabled}
        />

        <SelectGroup
          name='customer[currency]'
          label={I18n.translate('attributes.currency')}
          options={currencies}
          placeholder={I18n.translate('customers.form.select_currency')}
          onChange={(e) => customer.setCurrency(e.target.value)}
          required={true}
          value={customer.currency ? customer.currency : ''}
          disabled={!customerCurrencyField?.enabled}
        />

        <SelectGroup
          name='customer[unit_of_measure]'
          label={I18n.translate('attributes.unit_of_measure')}
          options={unitsOfMeasurement}
          placeholder={I18n.translate('customers.form.select_unit_of_measure')}
          onChange={(e) => customer.setUnitOfMeasure(e.target.value)}
          required={true}
          value={customer.unitOfMeasure ? customer.unitOfMeasure : ''}
          disabled={!customerUnitOfMeasureField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.penalty')}
          name='customer[penalty]'
          value={customer.penalty ? customer.penalty : ''}
          placeholder=''
          onChange={(e) => customer.setPenalty(e.target.value)}
          variant={VARIANT.DECIMAL}
          step={0.01}
          disabled={!customerPenaltyField?.enabled}
        />

        <SelectGroup
          name='customer[qualification_id]'
          label={I18n.translate('attributes.qualification')}
          options={qualifications}
          placeholder={I18n.translate('customers.form.select_qualification')}
          onChange={(e) => customer.setQualificationId(e.target.value)}
          required={true}
          value={customer.qualificationId ? customer.qualificationId : ''}
          disabled={!customerQualificationField?.enabled}
        />
      </Grid>
    </FieldSetWithTitle>
  );
};

export default observer(ContractTerms);
