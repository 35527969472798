import Input from 'components/elements/Input';
import { VARIANT } from 'constants/inputTypes';
import React from 'react';
import styled from 'styled-components';
import { FilterOption } from './SearchBar';

export const Divider = styled.span`
  margin: 0 0.75em;
`;

type DateRangeProps = {
  startDate?: FilterOption<string>;
  endDate?: FilterOption<string>;
  className?: string;
};

function DateRange({ startDate, endDate, className }: DateRangeProps) {
  if (startDate && !endDate) {
    return (
      <Input
        name={startDate.name}
        type='date'
        variant={VARIANT.TIME}
        defaultValue={startDate.value}
      />
    );
  }

  if (!startDate && endDate) {
    return (
      <Input
        name={endDate.name}
        type='date'
        variant={VARIANT.TIME}
        defaultValue={endDate.value}
      />
    );
  }

  if (startDate && endDate) {
    return (
      <span className={className}>
        {startDate && (
          <Input
            name={startDate.name}
            type='date'
            variant={VARIANT.TIME}
            defaultValue={startDate.value}
          />
        )}

        <Divider>-</Divider>

        {endDate && (
          <Input
            name={endDate.name}
            type='date'
            variant={VARIANT.TIME}
            defaultValue={endDate.value}
          />
        )}
      </span>
    );
  }

  return null;
}

export default styled(DateRange)`
  display: flex;
  align-items: center;
`;
