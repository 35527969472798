import RemoteControlledSearch from 'components/elements/RemoteControlledSearch';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Routes from 'routes';
import BaseFields from 'samples/create/components/BaseFields';

const CreateMillSample = (): JSX.Element => {
  const { control } = useFormContext();
  return (
    <>
      <RemoteControlledSearch
        entityName={I18n.translate('activerecord.models.ico')}
        sourcesPath={Routes.api_v1_samples_icos_mill_sample_path}
        label={I18n.translate('activerecord.models.ico')}
        dataCy='select_ico'
        prompt={I18n.translate('samples.create.select_ico')}
        control={control}
        name='sample[ico_id]'
        method='GET'
        required
      />
      <BaseFields showCoffeeTypeSelection={false} />
    </>
  );
};
export default CreateMillSample;
