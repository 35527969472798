import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import useStores from 'utils/useStores';
import Routes from 'routes';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

const FormWrapper = styled.div`
  flex-basis: 38.2%;
`;

function ActionForm() {
  const { transportSamplesStore } = useStores();
  const { paginatedTableStore } = transportSamplesStore;
  const { actionUrl } = paginatedTableStore;

  return (
    <FormWrapper>
      <form
        action={actionUrl !== undefined ? actionUrl() : ''}
        acceptCharset='UTF-8'
        method='POST'
      >
        <AuthenticityToken />

        {transportSamplesStore.selectedSamples.map((inventory) => (
          <input
            key={inventory.attributes.id}
            type='hidden'
            name={`sample_transport[samples][]`}
            value={inventory.attributes.id}
          />
        ))}
        <input
          type='hidden'
          name='total_weight'
          value={transportSamplesStore.totalWeight}
        />
        <div className='selected-lots__actions'>
          <span className='bold'>
            {I18n.translate('samples.send.samples', {
              count: transportSamplesStore.totalSamples,
            })}
          </span>
          <span className='bold'>{`${transportSamplesStore.totalWeight} g`}</span>
          <div className='l-item-stack-horizontal'>
            <a
              href={Routes.analyse_and_adjust_samples_path()}
              className='button button--second'
            >
              {I18n.translate('buttons.cancel')}
            </a>
            <button
              disabled={!transportSamplesStore.isFormValid}
              type='submit'
              className='button'
            >
              {I18n.translate('buttons.continue')}
            </button>
          </div>
        </div>
      </form>
    </FormWrapper>
  );
}

export default observer(ActionForm);
