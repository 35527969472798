import React from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
import {
  OpportunityCheckbox,
  OpportunitySelectGroup,
  OpportunityTextInput,
} from '../OpportunityFields';

const OpportunityContract = ({
  contractTypeField,
  contractType,
  setContractType,
  priceTypeField,
  priceTypeOptions,
  priceType,
  setPriceType,
  probabilityField,
  fixedPriceField,
  differentialField,
  incoterm,
  incotermField,
  setIncoterm,
  sampleNeededField,
  sampleNeeded,
  setSampleNeeded,
}) => {
  const { opportunityStore } = useStores();

  return (
    <FieldSetWithTitle title={I18n.translate('contracts.form.price')}>
      <Grid>
        <OpportunitySelectGroup
          field={contractTypeField}
          value={contractType}
          onChange={setContractType}
        />
        <OpportunitySelectGroup
          field={priceTypeField}
          overrideOptions={priceTypeOptions}
          value={priceType}
          onChange={setPriceType}
        />
        <OpportunitySelectGroup
          field={probabilityField}
          value={probabilityField?.value}
        />
        {priceType == 'fixed' && (
          <>
            <OpportunityTextInput
              field={fixedPriceField}
              type='number'
              step='0.0001'
              append={opportunityStore.selectedCustomer?.price_unit_currency || ''}
            />
            <input type='hidden' name='opportunity[differential]' value='' />
          </>
        )}
        {priceType == 'differential' && (
          <>
            <OpportunityTextInput
              field={differentialField}
              type='number'
              step='0.0001'
              append='USD/lb'
            />
            <input type='hidden' name='opportunity[fixed_price]' value='' />
          </>
        )}
        <OpportunitySelectGroup
          field={incotermField}
          value={incoterm}
          onChange={setIncoterm}
        />
        {contractType && contractType === 'fwd' && (
          <OpportunityCheckbox
            field={sampleNeededField}
            checked={sampleNeeded}
            onChange={setSampleNeeded}
          />
        )}
      </Grid>
    </FieldSetWithTitle>
  );
};

export default observer(OpportunityContract);
