import React from 'react';

import SelectOptionArray from 'types/model/selectOption';

interface SpecialMarkSelectProps extends React.HTMLAttributes<HTMLSelectElement> {
  defaultValue?: string;
  specialMarks: SelectOptionArray;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function SpecialMarkSelect({ specialMarks, onChange, ...rest }: SpecialMarkSelectProps) {
  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(event);
  };

  return (
    <select className='select--small' onChange={onChangeHandler} {...rest}>
      <option value=''>{I18n.translate('traceability.select_a_special_mark')}</option>

      {specialMarks.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </select>
  );
}

export default SpecialMarkSelect;
