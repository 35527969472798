import Icon from 'components/elements/Icon';
import Can from 'components/shared/Can';
import { observer } from 'mobx-react';
import React from 'react';
import Routes from 'routes';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import ToolTip from 'shared/ToolTip/components/ToolTip';
import styled from 'styled-components';
import placeholder from 'utils/placeholder';
import ProductionOrder from '../types/ProductionOrder';
import ActionsColumn from './ActionsColumn';

const IdWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalWrapper = styled.td`
  width: 11rem;
  max-width: 11rem;
`;

const MarkWrapper = styled.td`
  width: 11rem;
  max-width: 11rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const EditWrapper = styled.div`
  height: 100%;
  width: 2rem;
`;

interface ProdcutionOrderTableProps {
  productionOrder: ProductionOrder;
}

const TableRow: React.FC<ProdcutionOrderTableProps> = ({ productionOrder }) => {
  const {
    contract: { approval_requested },
  } = productionOrder;
  return (
    <tr>
      <td>
        <IdWrapper>
          <EditWrapper>
            {productionOrder.editable && (
              <Can I='edit' a='ProductionOrder'>
                <a
                  href={Routes.edit_production_order_path(productionOrder.id)}
                  data-cy='edit_production_order_button'
                >
                  <Icon icon='pen' color='#006F94' />
                </a>
              </Can>
            )}
          </EditWrapper>

          <div>
            <TextWithSub
              text={productionOrder.main_identifier}
              sub={I18n.l('time.formats.long', productionOrder.created_at)}
            />
          </div>
        </IdWrapper>
      </td>

      <td>
        <TextWithSub
          text={placeholder(productionOrder.ico?.identifier)}
          sub={productionOrder.contract.main_identifier}
        />
      </td>

      <td className='align-center'>
        <TextWithSub
          text={placeholder(productionOrder.shipment_date)}
          sub={placeholder(productionOrder.destination_port)}
        />
      </td>

      <MarkWrapper>
        <TextWithSub
          text={placeholder(productionOrder.customer_name)}
          sub={
            approval_requested
              ? I18n.translate('production_orders.info_table.pending_mark')
              : placeholder(productionOrder.mark)
          }
        />
      </MarkWrapper>

      <TotalWrapper className='align-right'>
        <div className='l-distribute-l-r l-distribute-l-r--centered'>
          <ToolTip
            title={I18n.translate('milling.production_orders.tooltip.headline')}
            infos={[
              {
                label: I18n.translate(
                  'milling.production_orders.tooltip.contract_weight'
                ),
                value: productionOrder.total,
              },
              {
                label: I18n.translate(
                  'milling.production_orders.tooltip.parchment_weight'
                ),
                value: `${productionOrder.parchment_weight}kg`,
              },
            ]}
          />
          <TextWithSub
            text={`${productionOrder.estimated_excelso_weight} kg`}
            sub={productionOrder.estimated_excelso_bags}
          />
        </div>
      </TotalWrapper>

      <td className='align-center'>
        <TextWithSub
          text={placeholder(productionOrder.quality)}
          sub={placeholder(productionOrder.grade)}
        />
      </td>

      <td className='align-center'>
        {I18n.translate(
          `activerecord.attributes.production_order.aasm_state.${productionOrder.state}`
        )}
      </td>

      <td className='align-center'>
        <ActionsColumn productionOrder={productionOrder} />
      </td>
    </tr>
  );
};

export default observer(TableRow);
