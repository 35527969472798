import { deserialise } from 'kitsu-core';

import { JsonApi } from 'types/utils/jsonApi';
import type MillingOutputType from 'millingOrders/types/MillingOutput';
import MillingOutput from 'millingOrders/models/MillingOutput';
import type SelectOptionArray from 'types/model/selectOption';
import { Validations } from 'shared/Roasting/components/RoastingOverlay';

class MillingOrderQaStore {
  public millingOutput: MillingOutput;
  public roastingValidations: Validations;
  public roastingResults: SelectOptionArray;
  public humidityAnalysisAcceptingReasons: SelectOptionArray;
  public roastingSampleWeight: number;
  public cuppingSessionEnabled: boolean;

  constructor(
    millingOutput: JsonApi<MillingOutputType>,
    humidityAnalysisAcceptingReasons: SelectOptionArray,
    roastingValidations: Validations,
    roastingResults: SelectOptionArray,
    roastingSampleWeight: number,
    cuppingSessionEnabled: boolean
  ) {
    const deserializedMillingOutput = deserialise(millingOutput)
      .data as MillingOutputType;
    this.millingOutput = new MillingOutput(deserializedMillingOutput);
    this.humidityAnalysisAcceptingReasons = humidityAnalysisAcceptingReasons;
    this.roastingValidations = roastingValidations;
    this.roastingResults = deserialise(roastingResults).data;
    this.roastingSampleWeight = roastingSampleWeight;
    this.cuppingSessionEnabled = cuppingSessionEnabled;
  }
}

export default MillingOrderQaStore;
