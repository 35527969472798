import classNames from 'classnames';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import React from 'react';
import Routes from 'routes';

import useStores from 'utils/useStores';

import InputGroupComponent from 'components/groups/InputGroup';
import Fraction from '../models/Fraction';
import Icon from 'components/elements/Icon';
import Roasting from 'shared/Roasting';
import HumidityAnalysis from 'shared/HumidityAnalysis';
import { VARIANT } from 'constants/inputTypes';
import PhysicalAnalysis from 'decaf/components/PhysicalAnalysis';

export interface FractionsTableRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  fraction: Fraction;
  isMainFraction?: boolean;
  expanded?: boolean;
  toggleExpanded?: () => void;
  cuppingSessionEnabled?: boolean;
}

const ToggleData = styled.td`
  width: 1.5rem;
`;

const ToggleWrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 1.5rem;
  cursor: pointer;
`;

const WeightWrapper = styled.td`
  min-width: 8.25rem;
`;

const IconWrapper = styled(Icon)`
  margin-left: 1rem;
  padding-bottom: 0.125rem;
`;

const RoastingWrapper = styled.div`
  .status {
    width: 4.5rem;
  }
`;

const IdentifierWrapper = styled.td`
  min-width: 8rem;
`;

const InputGroupWrapper = styled(InputGroupComponent)`
  max-width: 11rem;
  padding: 0.3125em 0.5em !important;
  height: 3.25rem !important;
  text-align: right;
  input {
    width: auto;
  }
`;

const FractionsTableRow = ({
  fraction,
  isMainFraction = false,
  expanded = false,
  toggleExpanded,
  cuppingSessionEnabled,
  ...props
}: FractionsTableRowProps) => {
  const {
    sample: {
      current_roasting,
      current_humidity_analysis,
      current_qa_physical_analysis,
      local_identifier,
      current_sensorial_analysis: initialSensorialAnalysis,
    },
  } = fraction;

  let current_sensorial_analysis = initialSensorialAnalysis;

  const { fractionsStore } = useStores();
  const cuppingTableIdentifier = () => {
    if (
      !initialSensorialAnalysis ||
      initialSensorialAnalysis.finalized ||
      !initialSensorialAnalysis.cuppingTableIdentifier
    ) {
      return false;
    }
    return initialSensorialAnalysis.cuppingTableIdentifier;
  };
  if (cuppingSessionEnabled && !current_sensorial_analysis?.finalized) {
    current_sensorial_analysis = undefined;
  }

  const onSplitClick = async () => {
    await fraction.createSubfraction();

    if (
      !expanded &&
      fraction.subfractions.length > 0 &&
      typeof toggleExpanded === 'function'
    ) {
      toggleExpanded();
    }
  };

  const onBlur = async () => {
    await fraction.updateFraction();
  };

  const setRejectedWeight = (weight: string) => {
    const newWeight = weight ? parseFloat(weight) : 0;
    fraction.setRejectedWeight(newWeight);
  };

  const handleOnRoastingPrimaryClick = (event: React.MouseEvent, values: {}) => {
    fraction.sample.current_roasting.synchronize(values);
  };

  const renderRoasting = () => (
    <Roasting
      identifier={local_identifier}
      results={fractionsStore.roastingResults}
      roasting={current_roasting}
      roastingSampleWeight={current_roasting.initial_weight}
      text={I18n.translate(
        `widgets.lot_status_table.${current_roasting.passed ? 'done' : 'failed'}`
      )}
      status={current_roasting.passed}
      icon={current_roasting.passed ? 'passed' : 'failed'}
      onPrimaryClick={handleOnRoastingPrimaryClick}
      showReroast={false}
      showEnterRoasting={!current_roasting.id}
      validations={fractionsStore.roastingValidations}
    />
  );

  const sensorialAnalysisCondition = () => {
    if (cuppingSessionEnabled)
      return current_sensorial_analysis && current_sensorial_analysis.finalized;
    return current_sensorial_analysis;
  };

  const renderSensorialAnalysis = () => {
    const buttonClasses = classNames('button', 'button--small', {
      'button--green': current_roasting.id && fraction.weight > 0,
      'button--disabled': !current_roasting.id || fraction.weight <= 0,
      'button--ghost-cancel':
        current_roasting.id &&
        fraction.weight > 0 &&
        fractionsStore.selectedSamples.includes(fraction.sample.id),
    });
    if (sensorialAnalysisCondition()) {
      return (
        <div
          className={`status status--no-pointer status--${current_sensorial_analysis?.status}`}
        >
          <span>{current_sensorial_analysis?.text}</span>
          <svg className='icon'>
            <use xlinkHref={`#${current_sensorial_analysis?.icon}`} />
          </svg>
        </div>
      );
    } else if (cuppingTableIdentifier()) {
      return (
        <p>
          <span> in progress </span>
          <br />
          <span>{`${cuppingTableIdentifier()}`}</span>
        </p>
      );
    } else if (!cuppingSessionEnabled) {
      return (
        <>
          {fractionsStore.selectedSamples.includes(fraction.sample.id) && (
            <input
              type='hidden'
              name={`samples[${fraction.sample.id}]`}
              value={fraction.sample.id}
            />
          )}
          <button type='button' className={buttonClasses} onClick={handleButtonClick}>
            {(fractionsStore.selectedSamples.includes(fraction.sample.id) &&
              I18n.translate('fractions.cancel_cupping')) ||
              I18n.translate('fractions.to_cupping')}
          </button>
        </>
      );
    }
  };

  const handleOnChangeWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseFloat(event.target.value);

    fraction.setWeight(parsedValue);
  };

  const handleOnPrimaryClick = (event, values) => {
    current_humidity_analysis.synchronize(values);
  };

  const handleButtonClick = () => {
    fractionsStore.toggleSelectedLot(fraction.sample.id);
  };

  const renderHumidityAnalysis = () => {
    return (
      <HumidityAnalysis
        identifier={fraction.sample.local_identifier}
        onPrimaryClick={handleOnPrimaryClick}
        humidityAnalysis={
          current_humidity_analysis.id ? current_humidity_analysis : undefined
        }
        lotFailed={false}
        validations={current_humidity_analysis.validations}
        text={current_humidity_analysis.text}
        status={current_humidity_analysis.status}
        icon={current_humidity_analysis.icon}
        reasons={fractionsStore.humidityAnalysisAcceptingReasons}
      />
    );
  };

  const renderPhysicalAnalysis = () => {
    return (
      <PhysicalAnalysis
        physicalAnalysis={current_qa_physical_analysis}
        newUrl={Routes.new_milling_order_sample_excelso_physical_analysis_path(
          fraction.milling_order_id,
          fraction.sample.id
        )}
        editUrl={Routes.edit_milling_order_sample_excelso_physical_analysis_path(
          fraction.milling_order_id,
          fraction.sample.id,
          current_qa_physical_analysis.id || 0
        )}
      />
    );
  };

  const checkFractionStatus = (fraction) => {
    if (fraction.isMain) {
      return fractionsStore.remainingFractionWeight(fraction);
    }
  };

  return (
    <tr key={fraction.node_identifier} {...props}>
      <ToggleData>
        {isMainFraction && (
          <ToggleWrapper onClick={toggleExpanded}>
            <Icon icon={`caret-${expanded ? 'up' : 'down'}`} color='#006F94' />
          </ToggleWrapper>
        )}
      </ToggleData>

      <IdentifierWrapper className='align-left'>
        <b>{`${fraction.node_identifier}-${fraction.sample.local_identifier}`}</b>
        {fraction.hasUnfinishedReprocesses && (
          <IconWrapper icon='ring-filled' color='#EFD449' />
        )}
      </IdentifierWrapper>

      <WeightWrapper className='align-left'>
        <InputGroupWrapper
          name='excelso_weight'
          append='kg'
          placeholder=''
          onChange={handleOnChangeWeight}
          onBlur={onBlur}
          noMargin={true}
          value={fraction.weight}
          variant={VARIANT.DECIMAL}
          readOnly={fraction.excelsoInputDisabled}
          disabled={fraction.excelsoInputDisabled}
          step={0.01}
          // TODO: max's tag validation commented in order to test ATLAS-1162, we have to check ATLAS-727 validation functionality and fix it.
          max={checkFractionStatus(fraction)}
          // max={
          //   fraction.isMain
          //     ? fractionsStore.remainingFractionWeight(fraction)
          //     : fraction.maxGreen
          // }
        />
      </WeightWrapper>

      <WeightWrapper className='align-center'>
        {fraction.showRejectedInput && (
          <InputGroupWrapper
            name='rejected_weight'
            append='kg'
            placeholder=''
            onChange={(e) => setRejectedWeight(e.target.value)}
            onBlur={onBlur}
            value={fraction.remainingInputWeight}
            noMargin={true}
            variant={VARIANT.DECIMAL}
            readOnly={fraction.rejectedInputDisabled}
            disabled={fraction.rejectedInputDisabled}
            max={fraction.weight}
          />
        )}
      </WeightWrapper>

      <td></td>

      <td className='align-center'>
        <RoastingWrapper>{renderRoasting()}</RoastingWrapper>
      </td>
      <td className='align-center border-right-none'>{renderSensorialAnalysis()}</td>

      <td className='align-center border-right-black'>
        {current_sensorial_analysis && fraction.showSplitButton && (
          <button
            onClick={onSplitClick}
            disabled={!fraction.isSplittable}
            className='button button--ghost button--small'
            type='button'
          >
            {I18n.translate('fractions.split')}
          </button>
        )}
      </td>
      <td className='align-center'>{fraction.isMain && renderHumidityAnalysis()}</td>
      <td className='align-center'>{fraction.isMain && renderPhysicalAnalysis()}</td>
    </tr>
  );
};

export default observer(FractionsTableRow);
