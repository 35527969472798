import { Provider } from 'mobx-react';
import React from 'react';

import { ReasonGet } from 'types/model/reason';
import { MachinePhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';
import SpecialtyGradingSpecificationType from 'types/model/specialtyGradingSpecification';
import NormType from 'types/model/norm';
import OverlayStore from 'stores/OverlayStore';
import MachinePhysicalAnalysisStore from 'qaPhysicalAnalyses/stores/MachinePhysicalAnalysisStore';
import MachinePhysicalAnalysisForm from 'qaPhysicalAnalyses/components/MachinePhysicalAnalysisForm';
import ISample from 'millingOrders/types/ISample';
import { JsonApi } from 'types/utils/jsonApi';

export interface PhysicalAnalysisContainerProps {
  serialized_sample: JsonApi<ISample>;
  physical_analysis: MachinePhysicalAnalysisType;
  norm: NormType;
  reasons: ReasonGet[];
  specialty_grading_specifications: SpecialtyGradingSpecificationType[];
  cancel_url: string;
  percentage_green_in_pasilla: number;
}

function PhysicalAnalysisContainer({
  serialized_sample,
  norm,
  reasons,
  physical_analysis,
  specialty_grading_specifications,
  cancel_url = '',
  percentage_green_in_pasilla,
}: PhysicalAnalysisContainerProps): JSX.Element {
  return (
    <Provider
      machinePhysicalAnalysisStore={
        new MachinePhysicalAnalysisStore(
          serialized_sample,
          norm,
          physical_analysis,
          specialty_grading_specifications,
          reasons,
          cancel_url,
          percentage_green_in_pasilla
        )
      }
      overlayStore={new OverlayStore()}
    >
      <MachinePhysicalAnalysisForm />
    </Provider>
  );
}

export default PhysicalAnalysisContainer;
