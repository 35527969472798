import React, { useState } from 'react';

import Checkbox from './Checkbox';

import SelectGroup from 'components/groups/SelectGroup';
import SelectOptionArray from 'types/model/selectOption';

export interface StandaloneReasonCheckboxProps {
  name: string;
  icon: string;
  label: string;
  reasons: SelectOptionArray;
  initialReasonId: string;
  fieldName?: string;
  dataCy?: string;
}

function StandaloneReasonCheckbox({
  name,
  icon,
  label,
  reasons,
  initialReasonId,
  dataCy,
  fieldName = 'accepting_reason_id',
}: StandaloneReasonCheckboxProps): JSX.Element {
  const [isChecked, setIsChecked] = useState(!!initialReasonId);
  const [reasonId, setReasonId] = useState(initialReasonId);

  return (
    <div style={{ marginRight: 'auto' }}>
      <Checkbox
        dataCy={dataCy}
        name={name}
        icon={icon}
        checked={isChecked}
        label={label}
        onChange={setIsChecked}
      />
      {isChecked && (
        <SelectGroup
          name={fieldName}
          options={reasons}
          onChange={(e) => setReasonId(e.target.value)}
          value={reasonId}
          id={fieldName}
        />
      )}
    </div>
  );
}

export default StandaloneReasonCheckbox;
