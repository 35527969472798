import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import Routes from 'routes';

import { VARIANT } from 'constants/inputTypes';
import STATUS from 'types/utils/status';
import useStores from 'utils/useStores';

import InputGroupComponent from 'components/groups/InputGroup';
import Checkbox from 'shared/Checkbox';
import StatusText from 'shared/StatusText';

import Tile, { TileDisclosure, TileHeader } from 'shared/Tile';

import LotItem from './LotItem';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

const SelectedLots: React.FC<{}> = () => {
  const { setStore } = useStores();

  const [collapsed, setCollapsed] = React.useState(true);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (!setStore.selectedLots.length) {
      setCollapsed(true);
    }
  }, [setStore.selectedLots.length]);

  const handleDisclosureClick = React.useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => setCollapsed(!collapsed),
    [collapsed]
  );

  const totalWeightInBound =
    setStore.totalWeight === 0 ||
    (setStore.totalWeight >= 600 && setStore.totalWeight <= 3000);

  return (
    <form
      action={Routes.lot_sets_path()}
      method='post'
      onSubmit={() => setSubmitted(true)}
    >
      <AuthenticityToken />
      <Tile collapsibleIndicator={!!setStore.selectedLots.length && collapsed}>
        <>
          <TileHeader className='selected-lots__header'>
            <TileDisclosure
              disabled={!setStore.selectedLots.length}
              handleCollapsible={handleDisclosureClick}
              collapsed={collapsed}
            />
            <span className='selected-lots__total-amount'>
              {I18n.translate('lot_sets.index.total_amount')}
            </span>

            <StatusText
              className='selected-lots__total-weight'
              status={!totalWeightInBound ? STATUS.FAILED : STATUS.OK}
            >
              {`${setStore.totalWeight.toFixed(2)} kg`}
            </StatusText>

            <span className='selected-lots__placeholder'>-</span>

            <Checkbox
              label={I18n.translate('lot_sets.index.sample_size')}
              icon='tick'
              name='set[sample]'
              condensed={false}
              checked={setStore.sampleSizeChecked}
              value={String(setStore.sampleSizeChecked)}
              onChange={() => setStore.setSampleSizeChecked(!setStore.sampleSizeChecked)}
            />

            <span className='selected-lots__placeholder'>=</span>

            <div className='selected-lots__actions'>
              <InputGroupComponent
                name='set[number_calibrated_bags]'
                className='selected-lots__bags-input'
                variant={VARIANT.INTEGER}
                data-cy='input_number_of_bags'
                min={setStore.selectedTotalBags ? 1 : 0}
                append={I18n.translate('lot_sets.index.bags', {
                  count: setStore.selectedTotalBags,
                })}
                value={setStore.selectedTotalBags}
                onChange={(e) => setStore.setBags(e.target.value)}
              />

              <button
                disabled={!setStore.isFormValid || submitted}
                className='button'
                data-cy='button_create_and_print'
              >
                {I18n.translate('lot_sets.index.create_set_and_print')}
              </button>
            </div>
          </TileHeader>

          {!collapsed && !!setStore.selectedLots.length && (
            <table
              className={classNames('table', 'table--inverted-stripes', 'sets-table', {
                'sets-table--collapsed': collapsed,
              })}
            >
              <tbody>
                {setStore.selectedLots.map((selectedLot) => (
                  <LotItem
                    key={selectedLot.attributes.id}
                    lot={selectedLot}
                    checked={selectedLot.selected}
                    onCheck={() => setStore.deselectLot(selectedLot)}
                  />
                ))}
              </tbody>
            </table>
          )}
        </>
      </Tile>
      {setStore.selectedLots.map((lot) => (
        <input
          key={lot.attributes.id}
          type='hidden'
          name={`set[lots][${lot.attributes.id}]`}
          value='true'
        />
      ))}
    </form>
  );
};

export default observer(SelectedLots);
