import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  OverlayBaseForm,
  OverlayFooter,
  OverlayTitle,
  OverlayWrapper,
} from 'shared/Overlay';
import AvailableExcessOrOrphanCoffeeRowComponent from './AvailableExcessOrOrphanCoffeeRowComponent';
import useStores from 'utils/useStores';
import { addTestingIdentifier } from 'utils/testing';
import { useFilterContext } from 'utils/filterContext';
import FilterBar from 'components/elements/FilterBar';
import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';

const AvailableExcessOrOrphanCoffeeOverlayComponent: React.FC = () => {
  const { availableExcessOrOrphanCoffeeStore } = useStores();
  const [showOverlay, setShowOverlay] = useState(false);
  const { filters, updateFilter } = useFilterContext();

  const handleButtonClick = () => {
    setShowOverlay(true);
  };

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => updateFilter(event.target.name, event.target.value);

  const filteredItems = React.useMemo(
    () =>
      availableExcessOrOrphanCoffeeStore.items.filter(
        (item) =>
          Object.entries(filters).every(([key, { filter, value }]) => {
            return value ? filter(item.attributes[key], value) : true;
          }) && parseFloat(item.quantity) > 0
      ),
    [filters]
  );

  const renderRows = () => {
    return filteredItems.map((item) => (
      <AvailableExcessOrOrphanCoffeeRowComponent
        key={item.attributes.identifier}
        item={item}
        openWeight={parseFloat(availableExcessOrOrphanCoffeeStore.openWeight)}
      />
    ));
  };

  return (
    <>
      {showOverlay && (
        <OverlayWrapper>
          <OverlayBaseForm>
            <OverlayTitle>
              {I18n.translate('milling_orders.show.overlay.available_coffee')}
            </OverlayTitle>

            <FilterBar>
              <InputGroupComponent
                name='identifier'
                type='text'
                noMargin={true}
                placeholder={I18n.translate(
                  'milling_orders.show.overlay.search_placeholder'
                )}
                onChange={handleOnChange}
              />

              {availableExcessOrOrphanCoffeeStore.qualities.length > 0 && (
                <SelectGroup
                  name='quality'
                  onChange={handleOnChange}
                  noMargin={true}
                  options={availableExcessOrOrphanCoffeeStore.qualities}
                  value={filters['quality'].value}
                  placeholder={I18n.translate(
                    'milling_orders.show.overlay.select_a_quality'
                  )}
                />
              )}
            </FilterBar>

            <table className='table--full-border'>
              <thead>
                <tr>
                  <th className='align-left'>
                    {I18n.translate('milling_orders.show.overlay.id_mark')}
                  </th>

                  <th className='align-left'>
                    {I18n.translate('milling_orders.show.overlay.location')}
                  </th>

                  <th>{I18n.translate('milling_orders.show.overlay.quality')}</th>

                  <th>{I18n.translate('milling_orders.show.overlay.available')}</th>

                  <th>{I18n.translate('milling_orders.show.overlay.quantity_to_use')}</th>
                </tr>
              </thead>

              <tbody>{renderRows()}</tbody>
            </table>

            <OverlayFooter className='l-item-stack-horizontal' distributeButtons={true}>
              <button
                onClick={() => setShowOverlay(false)}
                className='button button--gray'
                {...addTestingIdentifier('close_overlay_button')}
              >
                {I18n.translate('buttons.close')}
              </button>
              <button
                onClick={() => setShowOverlay(false)}
                className='button'
                {...addTestingIdentifier('save_overlay_button')}
              >
                {I18n.translate('buttons.save')}
              </button>
            </OverlayFooter>
          </OverlayBaseForm>
        </OverlayWrapper>
      )}
      <button
        className='button button--green'
        onClick={handleButtonClick}
        type='button'
        {...addTestingIdentifier('available_coffee_button')}
      >
        {I18n.translate('buttons.available_coffee')}
      </button>
    </>
  );
};

export default observer(AvailableExcessOrOrphanCoffeeOverlayComponent);
