import styled from 'styled-components';
import COLORS from 'constants/colors';

export interface HeaderItemProps {
  active?: boolean;
  withArrows?: boolean;
}

export default styled.div<HeaderItemProps>`
  z-index: ${(props) => (props.active ? 2 : 0)};
  display: inline-flex;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  position: relative;
  border: 1px solid ${(props) => (props.active ? COLORS.BLUE : COLORS.GRAY)};
  border-bottom: 1px solid ${(props) => (props.active ? 'transparent' : COLORS.BLUE)};
  border-radius: 5px 5px 0 0;
  margin-right: ${(props) => (props.withArrows ? 0 : '1rem')};
  padding: 0.25rem 0.5rem;
  background: ${(props) => (props.active ? COLORS.MEDIUM_LIGHT_GRAY : COLORS.LIGHT_GRAY)};
  cursor: pointer;

  &:last-child {
    margin-left: auto;
    margin-right: 0;
  }
`;
