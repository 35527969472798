import React from 'react';
import { Provider } from 'mobx-react';

import Ability from 'utils/permissions/ability';
import ProductionLogStore from 'millings/productionLog/stores/ProductionLogStore';

import LogTable from 'millings/productionLog/components/LogTable';
import { AbilityContext } from 'components/shared/Can';

const Container = (props) => (
  <AbilityContext.Provider value={Ability}>
    <Provider
      productionLogStore={
        new ProductionLogStore(
          props.millingOrderId,
          props.productionLogs,
          props.identifier,
          props.fractions,
          props.currentOperator,
          props.machines
        )
      }
    >
      <LogTable cancelPath={props.cancelPath} />
    </Provider>
  </AbilityContext.Provider>
);

export default Container;
