import { action, observable } from 'mobx';
import { SelectOption } from 'types/model/selectOption';

interface selectedCustomer extends SelectOption {
  price_unit_currency?: string;
}
class OpportunityStore {
  @observable public selectedCustomer?: selectedCustomer;

  constructor(fields) {
    const customerField = fields.find((field) => field.name == 'customer_id');
    if (customerField.value) {
      this.selectedCustomer = customerField.options.find(
        (option) => option.id == customerField.value
      );
    }
  }
  @action public setSelectedCustomer(customer) {
    this.selectedCustomer = customer;
  }
}

export default OpportunityStore;
