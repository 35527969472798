import styled from 'styled-components';

const OverlayFooter = styled.div<{ withReason?: boolean; distributeButtons?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.withReason ? 'space-between' : 'flex-end')};
  align-items: flex-end;
  margin-top: 0.625em;
  padding: ${(props) => (props.distributeButtons ? '0.625em' : '0')};

  .input-group {
    margin-bottom: 0;
    opacity: 1;
    transition: opacity 0.2s ease-out 0.5s;
  }

  .overlay-select-group-unused {
    opacity: 0.5;
    pointer-events: none;
  }

  button:first-child:not(:only-child) {
    margin-right: 1em;
  }
`;

export default OverlayFooter;
