import React from 'react';
import Icon from 'components/elements/Icon';
import useStores from 'utils/useStores';
import ButtonGroup from './ButtonGroup';
import DeliveryOrder from '../../models/DeliveryOrder';
import { observer } from 'mobx-react';

export interface PointOfReleaseProps {
  deliveryOrder: DeliveryOrder;
}

function PointOfRelease({ deliveryOrder }: PointOfReleaseProps) {
  const { deliveryOrderIndexStore } = useStores();
  const { sent } = deliveryOrder;

  const handleOnAddressClick = (): void => {
    deliveryOrderIndexStore.openAddressOverlay(deliveryOrder);
  };

  const handleOnShippingOptionClick = (): void => {
    deliveryOrderIndexStore.openShippingOptionsOverlay(deliveryOrder);
  };

  return (
    <ButtonGroup>
      <button
        className='button button--small'
        onClick={handleOnAddressClick}
        disabled={!sent}
      >
        {I18n.translate('fulfillment.delivery_orders.index.set_address')}
      </button>

      <button
        className='button button--small button--medium-blue'
        onClick={handleOnShippingOptionClick}
        disabled={!sent}
        data-cy='shipping_options_button'
        title='Shipping Options'
      >
        <Icon
          icon='export'
          color='#fff'
          className='delivery-orders__shipment-options-icon'
        />
      </button>
    </ButtonGroup>
  );
}

export default observer(PointOfRelease);
