import PaginatedTableStore from 'components/shared/PaginatedTable/stores/PaginatedTableStore';
import {
  PaginatedErrorResponse,
  PaginatedSuccessResponse,
} from 'components/shared/PaginatedTable/utils/paginatedTableCustomFetch';
import { action, observable } from 'mobx';
import Routes from 'routes';
import SelectOptionArray from 'types/model/selectOption';
import ProductionOrder from 'millings/productionOrders/AsyncProductionOrder/types/ProductionOrder';

class AsyncProductionOrdersStore {
  // Preloaded Options data
  public states: SelectOptionArray;
  public years: SelectOptionArray;

  // Filter fields
  @observable public customerIdString = '';
  @observable public selectedState = '';
  @observable public selectedYear = '';

  @observable public paginatedTableStore: PaginatedTableStore<ProductionOrder>;

  @observable public errors: Array<string> = [];

  constructor(states: SelectOptionArray, years: SelectOptionArray) {
    this.states = states;
    this.years = years;

    this.paginatedTableStore = new PaginatedTableStore(
      Routes.api_v1_production_orders_path,
      this.successHandler,
      this.errorHandler
    );
  }

  @action public successHandler = (
    response: PaginatedSuccessResponse<ProductionOrder>
  ): void => {
    this.paginatedTableStore.updatePagy(response.pagy);
    this.paginatedTableStore.setLoadedRows(response.data);
  };

  @action public errorHandler = (response: PaginatedErrorResponse): void => {
    this.errors = response.errors;
  };

  @action public setCustomerIdString = (newValue: string): void => {
    this.customerIdString = newValue;
  };

  @action public setSelectedState = (newValue: string): void => {
    this.selectedState = newValue;
  };

  @action public setSelectedYear = (newValue: string): void => {
    this.selectedYear = newValue;
  };
}

export default AsyncProductionOrdersStore;
