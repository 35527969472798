import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import COLORS from 'constants/colors';

import useStores from 'utils/useStores';

import ContentHeader from 'components/elements/ContentHeader';

const TinyWeightInfo = styled.span`
  font-size: 0.5em;
  font-weight: 100;
  color: ${COLORS.GRAY};
  padding-left: 0.25em;
`;

const HeadlineInfo = styled.div`
  width: 12em;
  display: flex;
  justify-content: flex-end;
`;

const AssignCoffeeHeadline = () => {
  const { availableCoffeeStore } = useStores();
  const { productionOrderType } = availableCoffeeStore;

  if (!availableCoffeeStore.withTable) {
    return null;
  }

  const enoughWeight =
    availableCoffeeStore.estimatedGreen >= availableCoffeeStore.wantedWeight;

  const weightHeadlineClasses = classNames('content-headline__status align-right', {
    'content-headline__status--green': enoughWeight,
    'content-headline__status--error': !enoughWeight,
  });

  return (
    <ContentHeader
      prefix={availableCoffeeStore.icoIdentifier}
      title={I18n.translate('headlines.select_available_coffee')}
    >
      <HeadlineInfo className='content-headline__info'>
        <ul>
          <li>
            <div className={weightHeadlineClasses}>
              {availableCoffeeStore.estimatedGreen}
              <TinyWeightInfo>/ {availableCoffeeStore.wantedWeight}</TinyWeightInfo>
            </div>

            <div className='content-headline__status content-headline__status--legend align-right'>
              {productionOrderType === 'vanilla' &&
                availableCoffeeStore.estimatedGreenTitle}
              {productionOrderType === 'caracol' &&
                I18n.translate('available_coffee.estimate')}
            </div>
          </li>
          {productionOrderType === 'vanilla' && (
            <li>
              <div className={weightHeadlineClasses}>
                {availableCoffeeStore.alternativeEstimatedGreen}
                <TinyWeightInfo>/ {availableCoffeeStore.wantedWeight}</TinyWeightInfo>
              </div>

              <div className='content-headline__status content-headline__status--legend align-right'>
                {availableCoffeeStore.alternativeEstimatedGreenTitle}
              </div>
            </li>
          )}
        </ul>
      </HeadlineInfo>
    </ContentHeader>
  );
};

export default observer(AssignCoffeeHeadline);
