import { observable, action, computed } from 'mobx';
import { deserialise } from 'kitsu-core';

import Sample from './Sample';
import Reprocess from './Reprocess';
import FractionType from 'types/model/fraction';

import Routes from 'routes';
import { customFetch } from 'utils/fetch';
import FractionsStore from 'productionOrders/stores/FractionsStore';
import { createSampleFactory } from 'utils/factories/createSampleFactory';
import SampleBuilder from 'utils/builders/SampleBuilder';

class Fraction {
  public milling_order_id: number;
  public id: number;
  public node_identifier: string;
  public depth: number;
  public sample: Sample;
  public parent?: Fraction;
  public root?: Fraction;
  public fractionsStore?: FractionsStore;
  @observable public subfractions: Fraction[];
  @observable public weight: number;
  @observable public rejected: number;
  @observable public remaining?: number;
  @observable public reprocesses: Reprocess[];

  constructor(
    fraction: FractionType,
    parent?: Fraction,
    root?: Fraction,
    fractionsStore?: FractionsStore
  ) {
    this.id = fraction.id;
    this.node_identifier = fraction.node_identifier;
    this.depth = fraction.depth;
    this.weight = parseFloat(fraction.weight);
    this.rejected = fraction.rejected;
    this.remaining = fraction.remaining;
    this.parent = parent;
    this.root = root;
    this.milling_order_id = fraction.milling_order_id;
    this.fractionsStore = fractionsStore;

    const sampleBuilder = new SampleBuilder(fraction.sample);

    this.sample = createSampleFactory(fraction.sample, sampleBuilder);

    // If there is no Parent, it's a main fraction, thus the root is 'this'
    const rootFraction = this.parent ? this.root : this;
    const subfractions = fraction.subfractions?.map(
      (subfraction) => new Fraction(subfraction, this, rootFraction)
    );
    this.subfractions = observable.array(subfractions);

    const reprocesses = fraction.fraction_reprocesses?.map(
      (reprocess) => new Reprocess(reprocess, this)
    );
    this.reprocesses = observable.array(reprocesses);
  }

  @action createSubfraction = async () => {
    const route = Routes.api_v1_milling_order_fractions_path(this.milling_order_id);

    const response = await customFetch(
      route,
      {
        parent_id: this.id,
      },
      'POST'
    );

    if (response.success) {
      const deserialisedFraction = deserialise(response.fraction).data;
      const fractionModel = new Fraction(deserialisedFraction, this, this.root);
      this.subfractions.push(fractionModel);

      this.finishLastReprocess();
    } else {
      window.location.reload();
    }
  };

  @action finishLastReprocess = () => {
    if (this.lastReprocess && !this.lastReprocess.finished) {
      this.lastReprocess.setFinished();
      this.lastReprocess.synchronize();
    }
  };

  @action updateFraction = async () => {
    if (!this.canSync) {
      return;
    }

    const route = Routes.api_v1_milling_order_fraction_path(
      this.milling_order_id,
      this.id
    );

    const response = await customFetch(
      route,
      {
        weight: this.weight,
        rejected: this.rejected,
      },
      'PATCH'
    );

    if (!response.success) {
      window.location.reload();
    }
  };

  @action setWeight(weight: number) {
    this.weight = weight;
  }

  @action setRejectedWeight(rejectedWeight: number) {
    if (rejectedWeight <= this.weight) {
      this.rejected = rejectedWeight;
    }
  }

  @action createReprocess = async () => {
    const route = Routes.api_v1_fraction_fraction_reprocesses_path(this.id);

    const response = await customFetch(
      route,
      {
        fraction_id: this.id,
        initial: this.totalGreen,
      },
      'POST'
    );

    if (response.success) {
      const reprocess = new Reprocess(
        deserialise(response.fraction_reprocess).data,
        this
      );

      this.reprocesses.push(reprocess);
    }
  };

  @computed public get isMain(): boolean {
    return this.parent === undefined;
  }

  @computed public get canSync(): boolean {
    return (
      !this.exceedsParentMaxWeight &&
      !this.exceedsRejectedMaxWeight &&
      (this.weight !== 0 || this.rejected !== 0)
    );
  }

  @computed public get showRejectedInput(): boolean {
    return (
      !!this.sample.current_sensorial_analysis &&
      !!this.sample.current_sensorial_analysis.analysisFailed
    );
  }

  @computed public get showSplitButton(): boolean {
    return (
      !!this.sample.current_sensorial_analysis &&
      !!this.sample.current_sensorial_analysis.analysisFailed
    );
  }

  @computed public get excelsoInputDisabled() {
    if (this.isMain) {
      return (
        !this.isLast ||
        (this.isLast && (!!this.subfractions.length || !!this.reprocesses.length))
      );
    } else {
      return !!this.subfractions.length;
    }
  }

  @computed public get rejectedInputDisabled() {
    return !!this.subfractions.length;
  }

  @computed get isLast() {
    if (this.fractionsStore?.fractions) {
      return (
        this.fractionsStore.fractions[this.fractionsStore.fractions.length - 1] === this
      );
    } else {
      return false;
    }
  }

  @computed public get remainingWeight(): number {
    let deductionWeight = this.totalRejected;

    if (this.lastReprocess && this.lastReprocess.analysisStarted) {
      deductionWeight += this.lastReprocess.weight;
    }

    return Math.max(this.weight - deductionWeight, 0);
  }

  @computed public get hasUnfinishedReprocesses() {
    return (
      this.reprocesses && this.reprocesses.some((reprocess) => !reprocess.inputValuesSet)
    );
  }
  @computed public get isSplittable() {
    return (
      this.minRemainingWeight > 0 &&
      ((this.lastReprocess && this.lastReprocess.hasWeight) || !this.lastReprocess)
    );
  }

  @computed private get adjacentFractions() {
    let adjacentFractions: Fraction[] = [];

    if (this.parent && this.root) {
      adjacentFractions = this.parent.subfractions.filter(
        (subfraction) => subfraction !== this
      );
    }

    return adjacentFractions;
  }

  @computed public get minRemainingWeight(): number {
    return this.weight - this.rejected;
  }

  @computed public get maxGreen(): number {
    let maxGreen = 0;

    if (this.parent && this.root) {
      const subtractedWeight = this.adjacentFractions.reduce(
        (total, subfraction) => total - subfraction.weight,
        Math.max(this.parent.minRemainingWeight, 0)
      );

      maxGreen = Math.max(Math.min(subtractedWeight, this.parent.maxGreen), 0);
    }

    return maxGreen;
  }

  @computed public get maxSettableWeightForFraction(): number {
    // Parent Green or last output max weight

    if (this.parent && this.root) {
      // subfractions

      if (this.lastReprocess) {
        return this.lastReprocess.weight;
      } else {
        const subtractedWeight = this.adjacentFractions.reduce(
          (total, subfraction) => total - subfraction.weight,
          Math.max(this.parent.weight - this.parent.rejected, 0)
        );

        return Math.max(
          Math.min(subtractedWeight, this.parent.maxSettableWeightForFraction),
          0
        );
      }
    } else {
      // main fractions
      return this.minRemainingWeight;
    }
  }

  @computed public get exceedsParentMaxWeight(): boolean {
    if (this.isMain && this.fractionsStore) {
      return this.weight > this.fractionsStore.remainingFractionWeight(this);
    } else {
      return this.weight > this.maxSettableWeightForFraction;
    }
  }

  @computed public get exceedsRejectedMaxWeight(): boolean {
    return this.rejected > this.weight;
  }

  @computed public get remainingInputWeight(): number | '' {
    return !this.rejected ? '' : this.rejected;
  }

  @computed get lastReprocess(): Reprocess | undefined {
    let reprocesses = this.reprocesses;

    if (this.parent && this.root) {
      reprocesses = this.root.reprocesses;
    }

    return reprocesses[reprocesses.length - 1];
  }

  @computed get isReprocessable(): boolean {
    let isReprocessable =
      this.sample.current_qa_physical_analysis.failed && this.totalGreen > 0;

    if (this.lastReprocess) {
      isReprocessable = this.lastReprocess.isReprocessable;
    }

    return isReprocessable;
  }

  @computed get totalRejected(): number {
    return this.subfractions.reduce(
      (totalRejected, subfraction) => totalRejected + subfraction.totalRejected,
      this.rejected
    );
  }

  @computed get totalLoss(): number {
    return this.reprocesses.reduce(
      (totalLoss, reprocess) => totalLoss + reprocess.totalLoss,
      0
    );
  }

  @computed get totalGreen(): number {
    return this.weight - this.totalRejected - this.totalLoss;
  }
}

export default Fraction;
