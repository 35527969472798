import React from 'react';

export interface MissingCoffeePropsComponent {
  missingCoffee: Array<{
    identifier: string;
    at_mill: boolean;
    current_location: string;
  }>;
}

const MissingCoffeeComponent: React.FC<MissingCoffeePropsComponent> = ({
  missingCoffee,
}) => {
  return (
    <>
      {I18n.translate('milling_orders.missing_coffee_table.info')}
      <table>
        <thead>
          <tr>
            <th className='table-border'>ID</th>
            <th>{I18n.translate('milling_orders.missing_coffee_table.warehouse')}</th>
          </tr>
        </thead>
        <tbody>
          {missingCoffee.map((missingCoffee) => (
            <tr key={missingCoffee.identifier}>
              <td className='table-border'>{missingCoffee.identifier}</td>
              <td>{missingCoffee.current_location}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default MissingCoffeeComponent;
