import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import React from 'react';
import Overlay, { OverlayFooter, OverlayTitle } from 'shared/Overlay';

interface ITrackingFieldOverlayProps {
  register: any;
  onClose: () => void;
  onSubmit: () => void;
}

const TrackingFieldOverlay = ({
  register,
  onClose,
  onSubmit,
}: ITrackingFieldOverlayProps): JSX.Element => {
  return (
    <Overlay title={''}>
      <form onSubmit={onSubmit}>
        <OverlayTitle>
          {I18n.translate('samples.index.tracking_overlay_headline')}
        </OverlayTitle>
        <TextAreaGroupComponent {...register('sample[tracking_note]')} />

        <OverlayFooter className='l-item-stack-horizontal'>
          <div className='overlay-buttons'>
            <button
              data-cy='overlay_secondary_button'
              type='button'
              onClick={() => onClose()}
              className='button button--gray'
            >
              {I18n.translate('buttons.close')}
            </button>
            <button type='submit' data-cy='overlay_primary_button' className='button'>
              {I18n.translate('buttons.save')}
            </button>
          </div>
        </OverlayFooter>
      </form>
    </Overlay>
  );
};
export default TrackingFieldOverlay;
