import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { VARIANT } from 'constants/inputTypes';

interface VariantType {
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  pattern?: string;
  maxLength?: number;
}

const variants: {
  [key in VARIANT]: VariantType;
} = {
  DECIMAL: {
    inputMode: 'decimal',
    pattern: '[0-9]+([.,][0-9][0-9]?)?',
  },
  INTEGER: {
    inputMode: 'numeric',
    pattern: '[0-9]+',
  },
  TIME: {
    inputMode: 'numeric',
    maxLength: 5,
    pattern: '^([0-5]?[0-9]):[0-5][0-9]$',
  },
};

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  variant?: VARIANT;
  condensed?: boolean;
  selectTextOnFocus?: boolean;
  validator?: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
  changeIndicated?: boolean;
  dataCy?: string;
  externalValid?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      disabled,
      onChange,
      onBlur,
      validator,
      variant,
      type,
      condensed,
      changeIndicated,
      selectTextOnFocus,
      dataCy,
      externalValid = true,
      ...inputProps
    }: InputProps,
    ref
  ) => {
    const [valid, setValid] = useState(true);

    let inputVariantAttributes: VariantType = {};

    if (variant) {
      inputVariantAttributes = variants[variant];
    }

    useEffect(() => {
      if (disabled && validator && value) {
        setValid(
          validator(
            new Event('change', {
              bubbles: true,
            }) as unknown as React.ChangeEvent<HTMLInputElement>
          )
        );
      }
    }, [value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }

      if (validator) {
        setValid(validator(e));
      }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (selectTextOnFocus) {
        event.target.select();
      }
    };

    let inputType = type || 'text';

    if (variant === VARIANT.INTEGER || variant === VARIANT.DECIMAL) {
      inputType = 'number';
    }

    return (
      <input
        type={inputType}
        className={classNames({
          'input--disabled': inputProps.readOnly,
          'input--invalid': !valid || !externalValid,
          'input--condensed': condensed,
          'input--change-indicated': changeIndicated,
        })}
        disabled={disabled}
        ref={ref}
        onBlur={onBlur}
        value={value}
        onChange={(e) => handleInputChange(e)}
        onFocus={handleFocus}
        tabIndex={inputProps.readOnly ? -1 : undefined}
        data-cy={dataCy}
        {...inputProps}
        {...inputVariantAttributes}
      />
    );
  }
);

Input.displayName = 'Input';

export default Input;
