export enum COLORS {
  BLUE = '#006F94',
  RED = '#B12A31',
  LIGHTER_GRAY = '#f0f0f0',
  MEDIUM_LIGHT_GRAY = '#F6F5F4',
  LIGHT_GRAY = '#EFEEEC',
  GRAY = '#A4A9AD',
  MID_GRAY = '#dbd9d6',
  WHITE = '#FFF',
  BLACK = '#111820',
  BLACK_70 = '#57585C',
  BACKGROUND = '#F0F0F0',
  MENU_ITEM_BG_COLOR = '#004961',
  MENU_TEXT_COLOR = '#AFD7E4',
  BORDERS = '#C8C8C8',
  SUB_TEXT = '#75787b',
  TABLE_BORDERS = '#DBD9D6',
  GREEN = '#6ea340',
  YELLOW = '#EFD449',
}

export default COLORS;
