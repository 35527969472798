import React, { useState, useRef } from 'react';
import CollapsibleBody from './CollapsibleBody';
import BodyWrapper from './BodyWrapper';

interface AccordionBodyProps {
  expanded: boolean;
  component: React.FC<{ expanded: boolean }>;
}

const AccordionBody: React.FC<AccordionBodyProps> = ({ expanded, component: Body }) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [maxHeight] = useState('auto');

  // Disabled for now, as smooth collapsing is not working right now anyways
  // useEffect(() => {
  //   calculateHeight();
  // }, []);

  // const calculateHeight = () => {
  //   let calculatedHeight = 'auto';

  //   if (bodyRef.current !== null) {
  //     calculatedHeight = `${bodyRef.current?.getBoundingClientRect().height}px`;
  //   }

  //   maxHeight !== calculatedHeight && setMaxHeight(calculatedHeight);
  // };

  return (
    <CollapsibleBody expanded={expanded} maxHeight={maxHeight}>
      <BodyWrapper ref={bodyRef}>
        <Body expanded={expanded} />
      </BodyWrapper>
    </CollapsibleBody>
  );
};

export default AccordionBody;
