import ControlledSearch, {
  ControlledSearchProps,
} from 'components/elements/ControlledSearch';
import { Option } from 'components/elements/Search';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { customFetch } from 'utils/fetch';

interface IRemoteControlledSearchProps extends Omit<ControlledSearchProps, 'options'> {
  sourcesPath: () => string;
  sourcesData?: any;
  entityName: string;
  disabled?: boolean;
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE';
}

const RemoteControlledSearch = ({
  sourcesPath,
  sourcesData,
  entityName = 'Entity',
  disabled,
  method = 'POST',
  ...rest
}: IRemoteControlledSearchProps): JSX.Element => {
  const { setValue } = useFormContext();
  const [fetching, setFetching] = useState(false);
  const [sources, setSources] = useState<Array<Option>>([]);

  const path = sourcesPath();
  const stringifiedData = JSON.stringify(sourcesData);
  useEffect(() => {
    if (sources.length > 0) {
      setSources(Array<Option>());
    }
    if (path) {
      setFetching(true);

      customFetch(path, sourcesData, method).then((response) => {
        setValue(rest.name, null);
        setSources(response.sources);
        setFetching(false);
      });
    }
  }, [path, stringifiedData]);

  if (!fetching && sources.length == 0 && !disabled) {
    return <span>No {entityName} available</span>;
  }

  const key = path + stringifiedData;

  return <ControlledSearch key={key} options={sources} {...rest} />;
};
export default RemoteControlledSearch;
