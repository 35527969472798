import { truncate } from '@sentry/utils';
import Icon from 'components/elements/Icon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Routes from 'routes';
import TrackingFieldOverlay from 'samples/index/TrackingFieldOverlay';
import LoadingSpinner from 'shared/FileUploader/components/LoadingSpinner';
import useFetch, { ApiResponse } from 'utils/hooks/useFetch';

interface ITrackingFieldProps {
  sampleId: string;
  value: string;
}

interface IResponseData extends ApiResponse {
  status: 'error' | 'success';
  sample: {
    tracking_note: string;
  };
}

function TrackingField({ value, sampleId }: ITrackingFieldProps): JSX.Element {
  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
      'sample[tracking_note]': value,
    },
  });
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [trackingNote, setTrackingNote] = useState(value);

  const { loading, fetchData, error, response } = useFetch<IResponseData>(
    Routes.update_tracking_note_api_v1_sample_path({ id: sampleId })
  );

  const onSubmit = async (data) => {
    setOverlayVisible(false);
    await fetchData(data);
  };

  useEffect(() => {
    if (response?.sample?.tracking_note) {
      setValue('sample[tracking_note]', response.sample.tracking_note);
      setTrackingNote(response?.sample?.tracking_note);
    }
  }, [fetchData]);

  return (
    <div className='flex'>
      <span className='px-2'>
        {truncate((!!trackingNote && trackingNote) || '-', 15)}
      </span>
      {!loading && (
        <button
          className='button button--transparent button--icon'
          type='button'
          onClick={() => setOverlayVisible(true)}
        >
          <Icon icon={'pen'} />
        </button>
      )}
      {loading && <LoadingSpinner color='#7eacc0' />}
      {error && (
        <span title={error}>
          <Icon color='#b94a48' icon='cross' />
        </span>
      )}

      {overlayVisible && (
        <TrackingFieldOverlay
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          onClose={() => setOverlayVisible(false)}
        />
      )}
    </div>
  );
}

export default TrackingField;
