import React, { useState, useEffect } from 'react';

import Checkbox from './Checkbox';

import SelectGroup from 'components/groups/SelectGroup';
import SelectOptionArray from 'types/model/selectOption';

export interface StandaloneAcceptingReasonCheckboxProps {
  name: string;
  icon: string;
  label: string;
  reasons: SelectOptionArray;
  isRejected?: boolean;
  showReasons?: boolean;
  initialReasonId?: string | number;
  fieldName?: string;
  dataCy?: string;
}

function StandaloneAcceptingReasonCheckbox({
  name,
  icon,
  label,
  reasons,
  isRejected,
  showReasons,
  initialReasonId,
  dataCy,
  fieldName = 'accepting_reason_id',
}: StandaloneAcceptingReasonCheckboxProps) {
  const [isChecked, setIsChecked] = useState(isRejected);
  const [reasonId, setReasonId] = useState(
    initialReasonId ? initialReasonId : reasons[0]?.id
  );

  useEffect(() => {
    setIsChecked(isRejected);
  }, [showReasons]);

  // Clear AcceptingReasonId to not be set
  useEffect(() => {
    showReasons && !isChecked
      ? setReasonId(initialReasonId ? initialReasonId : reasons[0]?.id)
      : setReasonId('');
  }, [isChecked, showReasons, isRejected]);

  return (
    <div style={{ marginRight: 'auto' }}>
      <Checkbox
        dataCy={dataCy}
        name={name}
        icon={icon}
        checked={isChecked}
        label={label}
        value={isChecked ? 'true' : ''}
        onChange={setIsChecked}
      />
      {showReasons && !isChecked && (
        <SelectGroup
          name={fieldName}
          options={reasons}
          onChange={(e) => setReasonId(e.target.value)}
          value={reasonId}
          id={fieldName}
        />
      )}
    </div>
  );
}

export default StandaloneAcceptingReasonCheckbox;
