import React, { useState } from 'react';
import { observer } from 'mobx-react';
import FractionsContainer from './FractionsContainer';
import type { FractionsContainerProps } from './FractionsContainer';
import ConfirmFinalisedFractionComponent from 'millingOrders/components/ConfirmFinalisedFractionComponent';
import SelectGroup from 'components/groups/SelectGroup';
import type { IField } from 'components/shared/form/IField';
import Routes from 'routes';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';
import styled from 'styled-components';

const FractionForm = styled.form`
  display: flex;
  align-items: flex-end;
  min-width: 350px;
  justify-content: space-between;
`;

const ActionWrapper = styled.div`
  align-items: flex-end;
`;
export interface FractionsMillingContainerProps {
  disabled: boolean;
  fraction_versions: FractionsContainerProps[];
  cancel_path: string;
  can_finalised: boolean;
  milling_order_id: number;
  can_edited: boolean;
  fraction_change_reason: IField;
  cuppingSessionEnabled: boolean;
}

function FractionsMillingContainer({
  disabled,
  fraction_versions,
  cancel_path,
  can_finalised,
  milling_order_id,
  can_edited,
  fraction_change_reason,
  cuppingSessionEnabled,
}: FractionsMillingContainerProps): JSX.Element {
  const [DisabledState, setDisabledState] = useState(disabled);

  const handleChange = () => {
    setDisabledState(false);
  };

  const changeFractionReason = (can_finalised) => {
    return (
      <>
        {can_edited && !DisabledState && (
          <FractionForm
            id='change_reason'
            action={Routes.change_reason_milling_order_path(milling_order_id)}
            acceptCharset='UTF-8'
            method='post'
          >
            <AuthenticityToken />
            <SelectGroup
              label={I18n.translate('milling_orders.form.fraction_change_reason')}
              noMargin={true}
              name={`milling_order[${fraction_change_reason.name}]`}
              options={fraction_change_reason.options || []}
              value={fraction_change_reason.value}
              placeholder={I18n.translate('milling_orders.select_fraction_change_reason')}
            />

            <button
              type='submit'
              className='button button--secondary'
              form='change_reason'
            >
              {I18n.translate('buttons.save')}
            </button>
          </FractionForm>
        )}
        {can_finalised && <ConfirmFinalisedFractionComponent id={milling_order_id} />}
      </>
    );
  };

  return (
    <div>
      <fieldset disabled={DisabledState}>
        {fraction_versions.map((fraction_version) => {
          return (
            <FractionsContainer
              key={fraction_version.id}
              estimatedWeight={fraction_version.estimatedWeight}
              maxWeight={fraction_version.maxWeight}
              fractions={fraction_version.fractions}
              roastingResults={fraction_version.roastingResults}
              roastingValidations={fraction_version.roastingValidations}
              humidityAnalysisAcceptingReasons={
                fraction_version.humidityAnalysisAcceptingReasons
              }
              machines={fraction_version.machines}
              run={fraction_version.run}
              createdAt={fraction_version.createdAt}
              cuppingSessionEnabled={cuppingSessionEnabled}
            />
          );
        })}
      </fieldset>
      <div className='content'>
        <div className='l-auto-fill-flex'>
          <ActionWrapper className='l-distribute-l-r'>
            <div>
              <a href={cancel_path} className='button button--gray'>
                {I18n.translate('buttons.cancel')}
              </a>
            </div>

            {can_edited && DisabledState && (
              <button
                type='button'
                className='button button--secondary'
                onClick={handleChange}
              >
                {I18n.translate('buttons.edit')}
              </button>
            )}

            {changeFractionReason(can_finalised)}
          </ActionWrapper>
        </div>
      </div>
    </div>
  );
}

export default observer(FractionsMillingContainer);
