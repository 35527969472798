import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';
import CompanyDetails from './CompanyDetails';
import MainContactInfo from './MainContactInfo';
import ContractTerms from './ContractTerms';
import CustomerDetails from './CustomerDetails';
import CustomerLeadSelect from './CustomerLeadSelect';
import SubmitRow from './SubmitRow';
import { IField } from 'components/shared/form/IField';

export interface CustomerFormProps {
  view: boolean;
  fields: IField[];
}

const CustomerForm = ({ view, fields }: CustomerFormProps) => {
  const { customerStore } = useStores();

  const {
    customer,
    countries,
    paymentTerms,
    arbitrations,
    currencies,
    qualifications,
    leadSources,
    unitsOfMeasurement,
    customerOwners,
    leads,
    selectedLeadId,
    customerWithLeadPath,
    cancelPath,
    saveButtonText,
  } = customerStore;

  return (
    <>
      <CustomerLeadSelect
        leads={leads}
        selectedLeadId={selectedLeadId}
        path={customerWithLeadPath}
        fields={fields}
      />

      <CompanyDetails customer={customer} countries={countries} fields={fields} />

      <MainContactInfo data={customer} type={'customer'} fields={fields} />

      <ContractTerms
        customer={customer}
        paymentTerms={paymentTerms}
        arbitrations={arbitrations}
        currencies={currencies}
        qualifications={qualifications}
        unitsOfMeasurement={unitsOfMeasurement}
        fields={fields}
      />

      <CustomerDetails
        customer={customer}
        leadSources={leadSources}
        customerOwners={customerOwners}
        fields={fields}
      />

      <SubmitRow
        data={customer}
        cancelPath={cancelPath}
        saveButtonText={saveButtonText}
        view={view}
      />
    </>
  );
};

export default observer(CustomerForm);
