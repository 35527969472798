import React from 'react';
import { observer } from 'mobx-react';

import type { AccordionHeaderProps } from 'shared/Accordion/components/AccordionWrapper';
import AccordionFormHeader from '../AccordionFormHeader';

export interface ImporterSecurityFillingHeaderProps extends AccordionHeaderProps {
  completed: boolean;
}

const ImporterSecurityFillingHeader = ({
  expanded,
  completed,
}: ImporterSecurityFillingHeaderProps) => (
  <AccordionFormHeader
    expanded={expanded}
    headline={I18n.translate('exports.form.isf.header')}
    completed={completed}
  />
);

export default observer(ImporterSecurityFillingHeader);
