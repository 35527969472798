import { computed } from 'mobx';
import STATUS from 'types/utils/status';
import CustomerSampleReasonType from 'types/model/ICustomerSampleReason';

export class CustomerSampleReason {
  public id: number;
  public name: string;
  public isSuccessful: boolean;

  constructor(status: CustomerSampleReasonType) {
    this.id = status.id;
    this.name = status.name;
    this.isSuccessful = status.is_successful;
  }

  @computed get status() {
    return this.isSuccessful ? STATUS.OK : STATUS.FAILED;
  }
}

export default CustomerSampleReason;
