import React from 'react';
import Overlay from 'shared/Overlay';
import Grid from 'components/elements/Grid';
import useStores from 'utils/useStores';
import { useForm } from 'react-hook-form';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import TextareaGroupComponent from 'components/groups/TextareaGroup';
import { VARIANT } from 'constants/inputTypes';
import { CustomerSampleValueFormData } from 'customerSamples/models/CustomerSampleValue';

export interface CustomerSampleValueOverlayProps {
  onCloseClick: (event?: React.MouseEvent) => void;
}

function CustomerSampleValueOverlay({ onCloseClick }): JSX.Element {
  const { customerSamplesDashboardStore } = useStores();
  const customerSampleValue =
    customerSamplesDashboardStore.selectedSample?.customerSampleValue;

  // Default Values that are not shown in Form need additional hidden input
  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      humidity_analysis_percentage: customerSampleValue?.humidityAnalysisPercentage,
      water_analysis_percentage: customerSampleValue?.waterAnalysisPercentage,
      sensorial_score: customerSampleValue?.sensorialScore,
      customer_sample_reason_id: customerSampleValue?.customerSampleReasonId,
      observations: customerSampleValue?.observations,
      id: customerSampleValue?.id,
      sample_id: customerSampleValue?.sampleId,
    },
  });

  const handleOnSubmitClick = handleSubmit(
    async (data: CustomerSampleValueFormData): Promise<void> => {
      await customerSampleValue?.save(data);

      onCloseClick();
      // May be obsolete - component unmounts anyways
      reset(data);
    }
  );

  return (
    <Overlay
      primaryText={I18n.translate('buttons.save')}
      onPrimaryClick={handleOnSubmitClick}
      secondaryText={I18n.translate('buttons.close')}
      onSecondaryClick={onCloseClick}
      title={I18n.translate('customer_samples_dashboard.analysis_values')}
    >
      <Grid grid={12}>
        <input type='hidden' ref={register} name='sample_id' />
        <input type='hidden' ref={register} name='id' />

        <InputGroupComponent
          label={I18n.translate(
            'customer_samples_dashboard.humidity_analysis_percentage_label'
          )}
          name='humidity_analysis_percentage'
          variant={VARIANT.DECIMAL}
          append='%'
          disabled={!!customerSampleValue?.customerSampleReasonId}
          readOnly={!!customerSampleValue?.customerSampleReasonId}
          ref={register}
          min={0}
          max={100}
        />

        <InputGroupComponent
          label={I18n.translate(
            'customer_samples_dashboard.water_analysis_percentage_label'
          )}
          type='number'
          name='water_analysis_percentage'
          variant={VARIANT.DECIMAL}
          disabled={!!customerSampleValue?.customerSampleReasonId}
          readOnly={!!customerSampleValue?.customerSampleReasonId}
          ref={register}
          min={0}
          max={1}
          step={0.01}
        />

        <InputGroupComponent
          label={I18n.translate('customer_samples_dashboard.sensorial_score_label')}
          type='number'
          name='sensorial_score'
          disabled={!!customerSampleValue?.customerSampleReasonId}
          readOnly={!!customerSampleValue?.customerSampleReasonId}
          ref={register}
          min={0}
          max={100}
        />

        <SelectGroup
          label={I18n.translate('customer_samples_dashboard.result_label')}
          placeholder={I18n.translate('customer_samples_dashboard.result_placeholder')}
          options={customerSamplesDashboardStore.customerSampleReasons}
          disabled={!!customerSampleValue?.customerSampleReasonId}
          ref={register}
          name='customer_sample_reason_id'
        />

        <TextareaGroupComponent
          placeholder={I18n.translate(
            'customer_samples_dashboard.observations_placeholder'
          )}
          name='observations'
          id='observations'
          label={I18n.translate('attributes.observations')}
          disabled={!!customerSampleValue?.customerSampleReasonId}
          readOnly={!!customerSampleValue?.customerSampleReasonId}
          ref={register}
        />
      </Grid>
    </Overlay>
  );
}

export default CustomerSampleValueOverlay;
