import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from '../../components/elements/ContentHeader';
import chunk from '../../utils/chunk';
import styled from 'styled-components';
import COLORS from '../../constants/colors';

const RecentFuturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Courier Prime';
  overflow-x: scroll;
`;

const RecentFuture = styled.div`
  display: block;
  padding: 0 0.8755em;

  &:not(:last-child) {
    border-right: solid ${COLORS.BORDERS} 1px;
  }
`;

const RecentPair = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RecentPairKey = styled.div`
  margin-right: 0.5em;
  width: max-content;
`;

const RecentPairValue = styled.div`
  font-weight: 700;
`;

export interface Future {
  name: string;
  price: number;
}

export interface FuturesHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  futures: Array<Future>;
  unrealised: string;
  realised: string;
}

const FuturesHeader: React.FC<FuturesHeaderProps> = ({
  futures,
  unrealised,
  realised,
}: FuturesHeaderProps) => {
  // break up futures into chunks of two and take the first 3 pairs
  const shownFutures = chunk(futures, 2).slice(0, 5);

  return (
    <ContentHeader
      title={I18n.translate('future_price_fixing.price_fixing')}
      contentHeadlineClassName='content-headline__fitcontent'
    >
      <RecentFuturesContainer>
        {shownFutures.map(([first, second]) => (
          <RecentFuture key={`${first.name}-${first.price}`}>
            <RecentPair>
              <RecentPairKey>{first.name}</RecentPairKey>

              <RecentPairValue>{first.price}</RecentPairValue>
            </RecentPair>

            {second && (
              <RecentPair>
                <RecentPairKey>{second.name}</RecentPairKey>

                <RecentPairValue>{second.price}</RecentPairValue>
              </RecentPair>
            )}
          </RecentFuture>
        ))}
        <RecentFuture>
          <RecentPair>
            <RecentPairKey>
              {I18n.translate('future_price_fixing.unrealised')}
            </RecentPairKey>

            <RecentPairValue>{unrealised}</RecentPairValue>
          </RecentPair>
          <RecentPair>
            <RecentPairKey>
              {I18n.translate('future_price_fixing.realised')}
            </RecentPairKey>

            <RecentPairValue>{realised}</RecentPairValue>
          </RecentPair>
        </RecentFuture>
      </RecentFuturesContainer>
    </ContentHeader>
  );
};

export default observer(FuturesHeader);
