import React from 'react';

import FilterContext from './filterContext';
import { FilterState } from './types';

export interface FilterContextProviderProps {
  value: FilterState;
}

const FilterContextProvider: React.FC<FilterContextProviderProps> = ({
  children,
  value,
}) => {
  const [filters, setFilter] = React.useState(value);

  const updateFilter = (key: string, newValue: string) => {
    setFilter({
      ...filters,
      [key]: {
        ...filters[key],
        value: newValue,
      },
    });
  };

  return (
    <FilterContext.Provider value={{ filters, updateFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
