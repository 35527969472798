import styled from 'styled-components';

import { COLORS } from 'constants/colors';
import OverlayHeader from './OverlayHeader';
import OverlayFooter from './OverlayFooter';

const ScrollableOverlayBaseForm = styled.div<{ large?: boolean; noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  min-width: 36.125rem;
  max-width: 61rem;
  padding: ${(props) => (props.noPadding ? '0' : '1.25em')};
  background-color: ${COLORS.WHITE};
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5), 0 20px 40px -20px rgba(0, 0, 0, 0.67);
  text-align: left;

  ${OverlayHeader}, ${OverlayFooter} {
    flex: none;
  }
`;

export default ScrollableOverlayBaseForm;
