import { deserialise } from 'kitsu-core';
import { observable } from 'mobx';
import { JsonApiResource } from 'types/utils/jsonApi';
import type QualitiesPriceType from 'fixedPriceContracts/types/QualitiesPrice';
import QualitiesPrice from '../models/QualitiesPrice';
import type Quality from 'types/model/quality';

class QualitiesPricesStore {
  @observable checkableQualitiesPrices: QualitiesPrice[];

  constructor(
    qualities: JsonApiResource<Quality>[],
    qualtitiesPrices: JsonApiResource<QualitiesPriceType>[]
  ) {
    const deserializedQualityPrices = deserialise(qualtitiesPrices)
      .data as QualitiesPriceType[];

    const deserializedQualities = deserialise(qualities).data as Quality[];

    this.checkableQualitiesPrices = deserializedQualities.map((quality) => {
      const qualitiesPrice = deserializedQualityPrices.find(
        (qualitiesPrice) => qualitiesPrice.quality.id === quality.id
      );

      if (qualitiesPrice) {
        const { id, cup_premium, quality } = qualitiesPrice;

        const attributes = {
          id,
          cup_premium: cup_premium,
          quality,
          checked: true,
        };

        return new QualitiesPrice(attributes);
      } else {
        const attributes = {
          id: undefined,
          cup_premium: undefined,
          quality,
          checked: false,
        };

        return new QualitiesPrice(attributes);
      }
    });
  }
}

export default QualitiesPricesStore;
