import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledSearch from 'components/elements/ControlledSearch';
import { SampleDataContext } from 'samples/create/components/CreateSample';

const SampleLotSelect = (): JSX.Element => {
  const { control } = useFormContext();
  const { lots_and_sets } = useContext(SampleDataContext);
  return (
    <>
      <ControlledSearch
        label={I18n.translate('samples.create.select_lot')}
        dataCy='select_lot'
        prompt={I18n.translate('samples.create.select_lot')}
        options={lots_and_sets}
        control={control}
        name='sample[lot_or_lot_set]'
        required
      />
    </>
  );
};
export default SampleLotSelect;
