import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import Checkbox from 'shared/Checkbox';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import Grid from 'components/elements/Grid';

import Customer from 'customers/models/Customer';
import { ReasonGet } from 'types/model/reason';
import { IField } from 'components/shared/form/IField';
import CustomerEmailAddressInput from 'customers/components/CustomerEmailAddressInput';
import useField from 'customers/hooks/useField';

export interface CompanyDetailsProps {
  customer: Customer;
  countries: ReasonGet[];
  fields: IField[];
}

const CompanyDetails = ({ customer, countries, fields }: CompanyDetailsProps) => {
  const customerNameField = useField(fields, 'customer_name');
  const customerFiscalIdField = useField(fields, 'customer_fiscal_id');
  const customerAddressField = useField(fields, 'customer_address');
  const customerBillingAddressField = useField(fields, 'customer_billing_address');
  const customerCityField = useField(fields, 'customer_city');
  const customerBillingCityField = useField(fields, 'customer_billing_city');
  const customerStateField = useField(fields, 'customer_state');
  const customerBillingStateField = useField(fields, 'customer_billing_state');
  const customerPostcodeField = useField(fields, 'customer_postcode');
  const customerBillingPostcodeField = useField(fields, 'customer_billing_postcode');
  const customerPhoneNumberField = useField(fields, 'customer_phone_number');
  const customerWebsiteField = useField(fields, 'customer_website');
  const customerCountryField = useField(fields, 'customer_country');

  const [addressEqualsBillingAddress, setAddressEqualsBillingAddress] = useState(
    customer?.addressEqualsBillingAddress
  );

  useEffect(() => {
    if (customerBillingAddressField?.enabled) {
      customer.setBillingAddress(customer.address);
      customer.setBillingCity(customer.city);
      customer.setBillingState(customer.state);
      customer.setBillingPostcode(customer.postcode);
    }
  }, [addressEqualsBillingAddress]);

  return (
    <FieldSetWithTitle title={I18n.translate('customers.form.company_details')}>
      <Grid grid={24}>
        {customerBillingAddressField?.enabled && (
          <Checkbox
            label={I18n.translate('customers.form.address_equals_billing')}
            name={'customer[address_equals_billing_address]'}
            icon='tick'
            checked={addressEqualsBillingAddress}
            value={addressEqualsBillingAddress?.toString()}
            onChange={(checked) => setAddressEqualsBillingAddress(checked)}
          />
        )}
      </Grid>
      <Grid grid={12}>
        <InputGroupComponent
          label={I18n.translate('attributes.company')}
          name='customer[name]'
          value={customer.name ? customer.name : ''}
          placeholder=' '
          onChange={(e) => customer.setName(e.target.value)}
          required={true}
          disabled={!customerNameField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.fiscal_id_number')}
          name='customer[fiscal_id]'
          value={customer.fiscalId ? customer.fiscalId : ''}
          placeholder=' '
          onChange={(e) => customer.setFiscalId(e.target.value)}
          required={true}
          disabled={!customerFiscalIdField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.shipping_address')}
          name='customer[address]'
          value={customer.address ? customer.address : ''}
          placeholder=' '
          onChange={(e) =>
            customer.setAddress(e.target.value, addressEqualsBillingAddress)
          }
          required={true}
          disabled={!customerAddressField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.billing_address')}
          name='customer[billing_address]'
          value={customer.billingAddress ? customer.billingAddress : ''}
          placeholder=' '
          onChange={(e) => customer.setBillingAddress(e.target.value)}
          required={true}
          readOnly={addressEqualsBillingAddress}
          disabled={!customerBillingAddressField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.city')}
          name='customer[city]'
          value={customer.city ? customer.city : ''}
          placeholder=' '
          onChange={(e) => customer.setCity(e.target.value, addressEqualsBillingAddress)}
          required={true}
          disabled={!customerCityField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.billing_city')}
          name='customer[billing_city]'
          value={customer.billingCity ? customer.billingCity : ''}
          placeholder=' '
          onChange={(e) => customer.setBillingCity(e.target.value)}
          required={true}
          readOnly={addressEqualsBillingAddress}
          disabled={!customerBillingCityField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.state')}
          name='customer[state]'
          value={customer.state ? customer.state : ''}
          placeholder=' '
          onChange={(e) => customer.setState(e.target.value, addressEqualsBillingAddress)}
          disabled={!customerStateField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.billing_state')}
          name='customer[billing_state]'
          value={customer.billingState ? customer.billingState : ''}
          placeholder=' '
          onChange={(e) => customer.setBillingState(e.target.value)}
          readOnly={addressEqualsBillingAddress}
          disabled={!customerBillingStateField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.postcode')}
          name='customer[postcode]'
          value={customer.postcode ? customer.postcode : ''}
          placeholder=' '
          onChange={(e) =>
            customer.setPostcode(e.target.value, addressEqualsBillingAddress)
          }
          required={true}
          disabled={!customerPostcodeField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.billing_postcode')}
          name='customer[billing_postcode]'
          value={customer.billingPostcode ? customer.billingPostcode : ''}
          placeholder=' '
          onChange={(e) => customer.setBillingPostcode(e.target.value)}
          required={true}
          readOnly={addressEqualsBillingAddress}
          disabled={!customerBillingPostcodeField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.phone_number')}
          name='customer[customer_phone_number]'
          value={customer.customerPhoneNumber ? customer.customerPhoneNumber : ''}
          placeholder=' '
          onChange={(e) => customer.setCustomerPhoneNumber(e.target.value)}
          required={true}
          disabled={!customerPhoneNumberField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.website')}
          name='customer[website]'
          value={customer.website ? customer.website : ''}
          placeholder=' '
          onChange={(e) => customer.setWebsite(e.target.value)}
          disabled={!customerWebsiteField?.enabled}
        />

        <SelectGroup
          name='customer[country_id]'
          label={I18n.translate('attributes.country')}
          onChange={(e) => customer.setCountryId(e.target.value)}
          options={countries}
          placeholder={I18n.translate('customers.form.select_country')}
          required={true}
          value={customer.countryId ? customer.countryId : ''}
          disabled={!customerCountryField?.enabled}
        />
      </Grid>
      <CustomerEmailAddressInput fields={fields} customer={customer} />
    </FieldSetWithTitle>
  );
};

export default observer(CompanyDetails);
