import React, { useState } from 'react';

import { observer } from 'mobx-react';

import styled from 'styled-components';
import COLORS from 'constants/colors';
import Overlay from 'shared/Overlay';
import InputGroup from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';
import { toISO8601 } from 'utils/dateFormat';

import type { Bundle, PriceFixingData } from '../stores/FutureStore';

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2em;
`;

const Column = styled.div`
  width: 100%;
  padding: 0 1.25em;

  &:not(:last-child) {
    border-right: 1px solid ${COLORS.BORDERS};
  }
`;

const Header = styled.h2`
  margin-bottom: 0.5em;
`;

interface FuturesOverlayProps {
  close: () => void;
  onShipperFix: (bundleId: number, data: PriceFixingData) => void;
  onSellerFix: (bundleId: number, data: PriceFixingData) => void;
  bundle: Bundle;
  future: string;
  editFixed: boolean;
}

const FuturesOverlay = ({
  close,
  onShipperFix,
  onSellerFix,
  bundle,
  future,
  editFixed,
}: FuturesOverlayProps) => (
  <Overlay
    title={`BUN-${bundle.id}`}
    headerSubtitle={future}
    secondaryText={I18n.translate('buttons.close')}
    disablePrevNextButtons={true}
    onSecondaryClick={close}
  >
    <ColumnsContainer>
      <PriceFixingColumn
        fixing={bundle.shipperFixing}
        onFix={(data) => onShipperFix(bundle.id, data)}
        header={I18n.translate('future_price_fixing.shipper')}
        editFixed={editFixed}
      />
      <PriceFixingColumn
        fixing={bundle.sellerFixing}
        onFix={(data) => onSellerFix(bundle.id, data)}
        header={I18n.translate('future_price_fixing.seller')}
        editFixed={editFixed}
      />
    </ColumnsContainer>
  </Overlay>
);

const handleInputChange =
  (setter: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setter(e.target.value);
  };

const PriceFixingColumn = ({
  fixing,
  onFix,
  header,
  editFixed,
}: {
  fixing?: PriceFixingData;
  onFix: (data: PriceFixingData) => void;
  header: string;
  editFixed: boolean;
}) => {
  const [date, setDate] = useState(new Date());
  const [price, setPrice] = useState<number | undefined>(fixing && fixing.price);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const rawDate = fixing ? new Date(fixing.date) : new Date(date);
  const fmtDate = toISO8601(rawDate);

  const isReadOnly = (fixing: PriceFixingData | undefined, editFixed: boolean) => {
    return !!fixing && !editFixed;
  };

  return (
    <Column>
      <Header>{header}</Header>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onFix({ date, price: price as number });
          setButtonDisabled(true);
        }}
      >
        <InputGroup
          type='date'
          label={I18n.translate('future_price_fixing.fixing_date')}
          readOnly
          onChange={handleInputChange((data) => setDate(new Date(data)))}
          value={fmtDate}
          required
        />
        <InputGroup
          type='number'
          step='0.0001'
          variant={VARIANT.DECIMAL}
          readOnly={isReadOnly(fixing, editFixed)}
          onChange={handleInputChange((data) => setPrice(parseFloat(data)))}
          value={price}
          label={I18n.translate('future_price_fixing.fixing_price')}
          placeholder='0.0000'
          required
        />
        <button
          type='submit'
          className='button button--primary'
          disabled={isButtonDisabled || isReadOnly(fixing, editFixed)}
        >
          {I18n.translate('future_price_fixing.fix')}
        </button>
      </form>
    </Column>
  );
};

export default observer(FuturesOverlay);
