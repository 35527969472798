import React, { useState } from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { OpportunitySelectGroup, OpportunityTextInput } from '../OpportunityFields';
import { toMonthInputFormat } from 'contracts/contractUtils';
import { changeDatePicker } from 'shared/Browser';

export const OpportunityDelivery = ({
  estimatedDeliveryDateField,
  destinationPortIdField,
  destinationPortHidden,
}) => {
  const [deliveryDate, setDeliveryDate] = useState(estimatedDeliveryDateField?.value);
  return (
    <FieldSetWithTitle title={I18n.translate('opportunities.form.delivery')}>
      <Grid>
        <OpportunityTextInput
          field={estimatedDeliveryDateField}
          type='month'
          onChangeArg={(e) => changeDatePicker(e)}
          min={toMonthInputFormat(new Date())}
          value={deliveryDate}
          onChange={setDeliveryDate}
        />
        {!destinationPortHidden && (
          <OpportunitySelectGroup
            field={destinationPortIdField}
            value={destinationPortIdField?.value}
          />
        )}
      </Grid>
    </FieldSetWithTitle>
  );
};
