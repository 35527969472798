import React from 'react';

import Validation from 'types/utils/validations';

import HumidityAnalysisOverlay from './HumidityAnalysisOverlay';
import HumidityAnalysisOverlayButton from './HumidityAnalysisOverlayButton';

import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';
import type HumidityAnalysisModel from 'productionOrders/models/HumidityAnalysis';
import { addTestingIdentifier } from 'utils/testing';
import SelectOptionArray from 'types/model/selectOption';
import IHumidityAnalysis from 'types/model/IHumidityAnalysis';

export interface HumidityAnalysisProps {
  identifier: string;
  validations: {
    humidity_green: Validation;
    water_activity: Validation;
    humidity_dry_parchment: Validation;
  };
  text?: string;
  status?: boolean;
  icon?: string;
  humidityAnalysis?: IHumidityAnalysis | HumidityAnalysisModel;
  lotFailed?: boolean;
  reasons: SelectOptionArray;
  disabled?: boolean;
  showConfirmation?: boolean;
  onPrimaryClick?: (event: React.MouseEvent, values: {}) => void;
  onSecondaryClick?: (event: React.MouseEvent) => void;
}

const HumidityAnalysis = ({
  text,
  icon,
  status,
  humidityAnalysis,
  lotFailed,
  onPrimaryClick,
  onSecondaryClick,
  disabled,
  ...props
}: HumidityAnalysisProps) => {
  const [showOverlay, setShowOverlay] = React.useState(false);

  const handleOnPrimaryClick = (event: React.MouseEvent, values: {}) => {
    setShowOverlay(false);

    if (onPrimaryClick) {
      onPrimaryClick(event, values);
    }
  };

  const handleOnSecondaryClick = (event: React.MouseEvent) => {
    setShowOverlay(false);

    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
  };

  const handleOnButtonClick = () => {
    setShowOverlay(true);
  };

  return (
    <>
      {showOverlay && (
        <HumidityAnalysisOverlay
          humidityAnalysis={humidityAnalysis}
          onPrimaryClick={handleOnPrimaryClick}
          onSecondaryClick={handleOnSecondaryClick}
          {...props}
        />
      )}
      <AbilityContext.Provider value={Ability}>
        <HumidityAnalysisOverlayButton
          humidityAnalysis={humidityAnalysis}
          lotFailed={lotFailed}
          text={text}
          icon={icon}
          status={status}
          onClick={handleOnButtonClick}
          disabled={disabled}
          {...addTestingIdentifier('humidityAnalysis_button')}
        />
      </AbilityContext.Provider>
    </>
  );
};

export default HumidityAnalysis;
