import React from 'react';
import { observer } from 'mobx-react';

import type { AccordionHeaderProps } from 'shared/Accordion/components/AccordionWrapper';
import AccordionFormHeader from '../AccordionFormHeader';

export interface IcoImportHeaderProps extends AccordionHeaderProps {
  completed: boolean;
}

const IcoImportHeader = ({ expanded, completed }: IcoImportHeaderProps) => (
  <AccordionFormHeader
    expanded={expanded}
    headline={I18n.translate('exports.form.import_ico.header')}
    completed={completed}
  />
);

export default observer(IcoImportHeader);
