import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

export interface SignaturePadProps {
  form_id: string;
  param_name: string;
  current_signature_url: string;
  delete_label: string;
  read_only: boolean;
}

const SignaturePad = ({
  param_name,
  current_signature_url,
  form_id,
  delete_label,
}: SignaturePadProps) => {
  const padRef = useRef<SignatureCanvas>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [currentImage, setCurrentImage] = useState('');
  const [showPad, setShowPad] = useState(!current_signature_url);

  // Hook into onFormSubmit
  useEffect(() => {
    const form = document.getElementById(form_id);
    if (!form) {
      throw new Error('SignaturePad: Form not found');
    } else {
      form.onsubmit = () => {
        const currentBase64Image =
          padRef.current && padRef.current.toDataURL('image/png');
        setCurrentImage(currentBase64Image || '');
      };
    }
  }, []);

  const wrapperClasses = classnames('signature_pad__wrapper', {
    'signature_pad__wrapper--disabled': current_signature_url,
  });

  return (
    <div className={wrapperClasses}>
      {((!showPad || current_signature_url) && (
        <div className='signature_pad__image_wrapper'>
          <img
            src={current_signature_url}
            alt='current signature'
            className='signature_pad__image'
          />
          <button
            onClick={() => setShowPad(true)}
            className='signature_pad__remove_image_button'
          >
            {delete_label}
          </button>
        </div>
      )) || (
        <div className='signature_pad__canvas_wrapper'>
          <SignatureCanvas
            penColor='green'
            ref={padRef}
            canvasProps={{ className: 'signature_pad__canvas' }}
          />
          <button
            type='button'
            onClick={() => padRef.current && padRef.current.clear()}
            className='button button--small button--gray signature_pad__clear_button'
          >
            {delete_label}
          </button>
          <input type='hidden' ref={inputRef} name={param_name} value={currentImage} />
        </div>
      )}
    </div>
  );
};

export default SignaturePad;
