import React from 'react';
import FieldSet, { FieldSetProps } from 'components/elements/FieldSet';

interface FieldSetWithTitleProps extends FieldSetProps {
  title: string;
  subtitle?: string;
}

const FieldSetWithTitle = ({
  title,
  subtitle,
  children,
  ...rest
}: FieldSetWithTitleProps) => {
  return (
    <FieldSet {...rest}>
      <div>
        {title && <legend>{title}</legend>}
        {subtitle && <p className='small'>{subtitle}</p>}
      </div>

      <div>{children}</div>
    </FieldSet>
  );
};

export default FieldSetWithTitle;
