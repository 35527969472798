import React from 'react';
import { deserialise } from 'kitsu-core';

import SelectOptionArray from 'types/model/selectOption';
import { JsonApiResource } from 'types/utils/jsonApi';
import ThirdPartyImportTable from 'exports/components/thirdPartyImport/ThirdPartyImportTable';
import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';
import ThirdPartyImport from 'exports/types/thirdPartyImport';

export interface ThirdPartyImportContainerProps {
  thirdPartyImportsData: JsonApiResource<ThirdPartyImport>[];
  destinationWarehousesData: SelectOptionArray;
}

const ThirdPartyImportContainer = ({
  thirdPartyImportsData,
  destinationWarehousesData,
}: ThirdPartyImportContainerProps) => {
  const thirdPartyImports = deserialise(thirdPartyImportsData).data as ThirdPartyImport[];
  const destinationWarehouses = deserialise(destinationWarehousesData)
    .data as SelectOptionArray;

  return (
    <AbilityContext.Provider value={Ability}>
      <ThirdPartyImportTable
        thirdPartyImports={thirdPartyImports}
        destinationWarehouses={destinationWarehouses}
      />
    </AbilityContext.Provider>
  );
};

export default ThirdPartyImportContainer;
