import React, { useMemo } from 'react';
import Routes from 'routes';
import { observer } from 'mobx-react';
import Sample from 'customerSamples/models/Sample';
import InternalSampleIdentifier from 'customerSamples/components/InternalSampleIdentifier';
import CustomerSampleAnalysis from 'customerSamples/components/CustomerSampleAnalysis';
import InternalSampleAnalysis from 'customerSamples/components/InternalSampleAnalysis';
import SendToCustomerCheckbox from 'customerSamples/components/SendToCustomerCheckbox';
import CustomerSampleValuesStatus from 'customerSamples/components/CustomerSampleValuesStatus';
import ReceivedCheckbox from './ReceivedCheckbox';
import InternalContractSample from 'customerSamples/models/InternalContractSample';

export interface SampleRowProps {
  internalSample: Sample;
  customerSample: Sample;
}

interface IConfig {
  identifier: string;
  showSendToCustomerCheckbox: boolean;
}

const DEFAULT_CONFIG = (sample: Sample): IConfig => ({
  identifier: sample.mainIdentifier,
  showSendToCustomerCheckbox: true,
});

const TYPE_CONFIG_ADJUSTMENTS = {
  SAMPLE_ARRIVAL: (sample: Sample): IConfig => ({
    identifier: sample.localIdentifier,
    showSendToCustomerCheckbox: sample.customerSampleValue?.sendToCustomer || false,
  }),
  SAMPLE_SPOT: (sample: Sample): IConfig => ({
    identifier: sample.localIdentifier,
    showSendToCustomerCheckbox: true,
  }),
} as const;

const fetchConfig = (sample: Sample): IConfig => {
  const custom_config = TYPE_CONFIG_ADJUSTMENTS[sample.sampleType.toUpperCase()];
  return custom_config ? custom_config(sample) : DEFAULT_CONFIG(sample);
};

function SampleRow({ internalSample, customerSample }: SampleRowProps): JSX.Element {
  const customerSampleConfig = useMemo(
    () => fetchConfig(customerSample),
    [customerSample]
  );

  const internalSampleConfig = useMemo(
    () => fetchConfig(internalSample),
    [internalSample]
  );

  const internalSampleAnalyseAndAdjustPath = useMemo(
    () =>
      `${Routes.analyse_and_adjust_samples_path()}?filter[local_identifier]=${
        internalSampleConfig.identifier
      }&filter[sample_type]=${internalSample.sampleType}`,
    [internalSampleConfig.identifier, internalSample.sampleType]
  );

  if (internalSample.mainIdentifier.includes('-SAM-')) {
    return <></>;
  }

  return (
    <tr data-cy='sample-row'>
      <InternalSampleIdentifier
        internalSample={internalSample}
        internalSampleIdentifier={internalSampleConfig.identifier}
      />

      <CustomerSampleAnalysis
        customerSampleValue={customerSample.customerSampleValue}
        customerSampleIdentifier={customerSampleConfig.identifier}
      />

      <InternalSampleAnalysis
        currentSensorialAnalysis={internalSample.currentSensorialAnalysis}
        analyseAndAdjustPath={internalSampleAnalyseAndAdjustPath}
        sendToCustomer={customerSample.customerSampleValue?.sendToCustomer}
      />

      {internalSample === customerSample &&
      internalSample instanceof InternalContractSample ? (
        <ReceivedCheckbox received={internalSample.received} />
      ) : (
        <SendToCustomerCheckbox
          customerSample={customerSample}
          internalSample={internalSample}
          showCheckbox={customerSampleConfig.showSendToCustomerCheckbox}
        />
      )}

      <CustomerSampleValuesStatus customerSample={customerSample} />
    </tr>
  );
}

export default observer(SampleRow);
