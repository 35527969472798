import React from 'react';

import Icon from 'components/elements/Icon';

interface TileDisclosureProps {
  disabled?: boolean;
  collapsed?: boolean;
  handleCollapsible?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const TileDisclosure: React.FC<TileDisclosureProps> = ({
  disabled,
  collapsed,
  handleCollapsible,
}) => (
  <button
    type='button'
    className='selected-lots__disclosure'
    disabled={disabled}
    onClick={handleCollapsible}
  >
    <Icon
      icon={collapsed ? 'caret-down' : 'caret-up'}
      className={'selected-lots__disclosure-icon'}
    />
  </button>
);

export default TileDisclosure;
