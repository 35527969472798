import { action, computed, observable } from 'mobx';
import { deserialise } from 'kitsu-core';

import { LotGet } from 'types/model/lot';
import SelectableItem from 'types/model/selectableItem';
import { LotSetGet } from 'types/model/lot_set';
import LostCoffee from 'types/model/lostCoffee';
import { JsonApi } from 'types/utils/jsonApi';

import mapTransportableAttributes from 'transports/utils/mapTransportableAttributes';
import type Ico from 'decaf/types/Ico';
import { arrayMove } from '@dnd-kit/sortable';

export interface InventoryItem {
  localIdentifier: string;
  producer?: string;
  certification?: string;
  conventional?: boolean;
  quality?: string;
  bags: number;
  weightString: string;
  lostCoffee?: boolean;
}

export interface Transportable extends InventoryItem {
  id: string | number;
  type: string;
  weight: number;
}

export type SelectableTransportable = SelectableItem<Transportable>;

export interface Transportables {
  lots: JsonApi<LotGet>;
  lot_sets: JsonApi<LotSetGet>;
  lost_coffees: JsonApi<LostCoffee>;
  icos: JsonApi<Ico>;
}

class TransportStore {
  public transportables = observable.array<SelectableTransportable>();
  public sortable: boolean;
  @observable public sampleSizeChecked = false;
  @observable public bags = 0;

  constructor(transportables: Transportables, sortable = true) {
    const selectableTransportables: SelectableTransportable[] =
      this.deserializeTransportables(transportables).map((transportable) => {
        return {
          selected: false,
          type: transportable.type,
          // TODO Frontend workaround until transportables are implemented
          attributes: mapTransportableAttributes(transportable),
        };
      });

    this.sortable = sortable;
    this.transportables = observable.array(selectableTransportables);
  }

  private deserializeTransportables = (transportables: Transportables) => {
    return Object.keys(transportables).flatMap((key) => {
      const transportable = transportables[key] as ValueOf<Transportables>;

      return deserialise(transportable).data;
    });
  };

  private findTransportableIndex = (identifier: string): number => {
    return this.transportables.findIndex(
      (transportable) => transportable.attributes.localIdentifier === identifier
    );
  };

  @action public selectInventory = (transportable: SelectableTransportable) => {
    transportable.selected = true;
  };

  @action public deselectInventory = (transportable: SelectableTransportable) => {
    transportable.selected = false;
  };

  @action public setBags = (value: string) => {
    this.bags = parseInt(value, 10);
  };

  @action public orderInventory(from: string, to: string): void {
    const oldIndex = this.findTransportableIndex(from);
    const newIndex = this.findTransportableIndex(to);

    const sortedTransportables = arrayMove(
      this.transportables.toJS(),
      oldIndex,
      newIndex
    );

    this.transportables.replace(sortedTransportables);
  }

  @computed get selectedInventory(): SelectableTransportable[] {
    return this.transportables.filter((lot) => lot.selected);
  }

  @computed get selectedTotalBags(): number {
    const selectedInventoryBags = this.selectedInventory.reduce(
      (totalBags, { attributes: { bags } }) => {
        return totalBags + bags;
      },
      0
    );

    return this.bags || selectedInventoryBags;
  }

  @computed get totalWeight(): number {
    return this.selectedInventory.reduce((totalWeight, { attributes }) => {
      return Math.round((totalWeight + attributes.weight) * 100) / 100;
    }, 0);
  }

  @computed get isFormValid(): boolean {
    return this.selectedInventory.length > 0;
  }
}

export default TransportStore;
