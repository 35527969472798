import React, { useState } from 'react';
import Icon from 'components/elements/Icon';
import COLORS from 'constants/colors';
import styled from 'styled-components';
import useClickOutside from 'use-click-outside';

interface ToolTipBodyProps {
  component: () => JSX.Element;
  icon?: string;
  classNames?: Array<string>;
  asGrid?: boolean;
  top?: boolean;
}

interface ToolTipComponentProps {
  classNames?: Array<string>;
}

const ToolTipComponent = styled.div<ToolTipComponentProps>`
  display: inline-block;
  position: relative;
  text-align: initial;
  font-family: 'Nunito', sans-serif;
  ${(props) => props.classNames?.join(' ')}
  > svg {
    cursor: pointer;
  }
`;

interface ToolTipOverlayProps {
  asGrid?: boolean;
  top?: boolean;
}

const ToolTipOverlay = styled.div<ToolTipOverlayProps>`
  position: absolute;
  right: 0.75em;
  ${(props) => (props.top ? 'top: 2em;' : 'bottom: 2em;')}
  min-width: ${(props) => (props.asGrid ? '30.625em' : '21.25em')};
  background: ${COLORS.WHITE};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  padding: 1em 1.25em;
  z-index: 100;
  > span {
    display: block;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 0.475em;
    padding-bottom: 0.275em;
    border-bottom: 1px solid ${COLORS.BORDERS};
  }
  ul > li > div > span {
    &:nth-child(${(props) => (props.asGrid ? 1 : 2)}) {
      font-weight: 800;
      display: ${(props) => (props.asGrid ? 'block' : 'intial')};
    }
  }
  ul {
    display: ${(props) => (props.asGrid ? 'grid' : 'initial')};
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.625em;
  }
`;

const ToolTipBody: React.FC<ToolTipBodyProps> = ({
  component,
  classNames,
  icon = 'info',
  asGrid,
  top,
  ...others
}) => {
  const ref = React.createRef<HTMLDivElement>();
  const [showToolTip, setShowToolTip] = useState(false);

  const onClose = () => {
    if (showToolTip) {
      setShowToolTip(false);
    }
  };

  useClickOutside(ref, onClose);

  return (
    <ToolTipComponent ref={ref} classNames={classNames} {...others}>
      {showToolTip && (
        <ToolTipOverlay asGrid={asGrid} top={top}>
          {component()}
        </ToolTipOverlay>
      )}
      <Icon
        icon={icon}
        color={COLORS.BLUE}
        onClick={() => setShowToolTip(!showToolTip)}
      />
    </ToolTipComponent>
  );
};

export default ToolTipBody;
