import { observer } from 'mobx-react';
import React from 'react';

import InputGroupComponent from 'components/groups/InputGroup';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import Grid from 'components/elements/Grid';

import { IField } from 'components/shared/form/IField';
import useField from 'customers/hooks/useField';
import Customer from 'customers/models/Customer';
import { CustomerLead } from 'customerLeads/model/CustomerLead';

export interface MainContactInfoProps {
  data: Customer | CustomerLead;
  type: 'customer' | 'main' | 'secondary';
  fields: IField[];
}

const MainContactInfo = ({ data, type, fields }: MainContactInfoProps) => {
  const kind = type === 'customer' ? 'customer' : 'lead';

  const firstNameField = useField(fields, `${kind}_contact_first_name`);
  const middleNameField = useField(fields, `${kind}_contact_middle_name`);
  const surnameField = useField(fields, `${kind}_contact_surname`);
  const titleField = useField(fields, `${kind}_contact_title`);
  const customerSecondSurnameField = useField(fields, 'customer_contact_second_surname');
  const mobileNumberField = useField(fields, `${kind}_contact_mobile_number`);
  const phoneNumberField = useField(fields, `${kind}_contact_phone_number`);
  const emailAddressField = useField(fields, `${kind}_contact_email_address`);
  const customerPersonalIdNumberField = useField(
    fields,
    'customer_contact_personal_id_number'
  );
  const companyNameField = useField(fields, 'lead_contact_company_name');

  const getNamespace = (name) => {
    if (kind == 'customer') {
      return `customer[main_contact_info_attributes][${name}]`;
    }
    return `customer_lead[${type}_contact_info_attributes][${name}]`;
  };

  const getValue = (value) => {
    if (type == 'customer') {
      return data[value] || '';
    } else if (type == 'main' && data instanceof CustomerLead) {
      return data.mainContactInfo ? data['mainContactInfo'][value] : undefined;
    } else if (data instanceof CustomerLead) {
      return data.secondaryContactInfo ? data['secondaryContactInfo'][value] : undefined;
    }
  };

  const setInfo = (info, e) => {
    if (data instanceof Customer) {
      data[`set${info}`](e.target.value);
    } else if (data instanceof CustomerLead) {
      if (type == 'main') {
        data.mainContactInfo[`set${info}`](e.target.value);
      } else if (type == 'secondary') {
        data.secondaryContactInfo
          ? data.secondaryContactInfo[`set${info}`](e.target.value)
          : undefined;
      }
    }
  };

  return (
    <FieldSetWithTitle
      title={
        type != 'secondary'
          ? I18n.translate('customers.form.main_contact_infos')
          : I18n.translate('leads.form.secondary_contact_info')
      }
    >
      <Grid grid={12}>
        {type === 'customer' && (
          <InputGroupComponent
            type='hidden'
            name='customer[main_contact_info_attributes][id]'
            value={getValue('mainContactInfoId')}
          />
        )}
        <InputGroupComponent
          label={I18n.translate('attributes.first_name')}
          name={getNamespace('first_name')}
          value={getValue('firstName')}
          placeholder=''
          required={true}
          readOnly={firstNameField?.readonly}
          onChange={(e) => setInfo('FirstName', e)}
          disabled={!firstNameField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.middle_name')}
          name={getNamespace('middle_name')}
          value={getValue('middleName')}
          placeholder=''
          onChange={(e) => setInfo('MiddleName', e)}
          disabled={!middleNameField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.surname')}
          name={getNamespace('surname')}
          value={getValue('surname')}
          placeholder=''
          required={true}
          readOnly={surnameField?.readonly}
          onChange={(e) => setInfo('Surname', e)}
          disabled={!surnameField?.enabled}
        />

        {type === 'customer' && (
          <InputGroupComponent
            label={I18n.translate('attributes.second_surname')}
            name={'customer[main_contact_info_attributes][second_surname]'}
            value={getValue('secondSurname')}
            placeholder=''
            onChange={(e) => setInfo('SecondSurname', e)}
            disabled={!customerSecondSurnameField?.enabled}
          />
        )}

        {kind === 'lead' && (
          <InputGroupComponent
            label={I18n.translate('leads.form.contact_title')}
            name={getNamespace('contact_title')}
            value={getValue('contactTitle')}
            placeholder=''
            onChange={(e) => setInfo('ContactTitle', e)}
            required={true}
            disabled={!titleField?.enabled}
          />
        )}

        <InputGroupComponent
          label={I18n.translate('attributes.mobile_number')}
          name={getNamespace('mobile_number')}
          value={getValue('mobileNumber')}
          required={kind == 'lead' ? true : false}
          placeholder=''
          onChange={(e) => setInfo('MobileNumber', e)}
          disabled={!mobileNumberField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.phone_number')}
          name={getNamespace('phone_number')}
          value={getValue('phoneNumber')}
          required={kind == 'lead' ? true : false}
          placeholder=''
          onChange={(e) => setInfo('PhoneNumber', e)}
          disabled={!phoneNumberField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.email_address')}
          name={getNamespace('email_address')}
          value={getValue('emailAddress')}
          placeholder=''
          onChange={(e) => setInfo('EmailAddress', e)}
          required={true}
          readOnly={emailAddressField?.readonly}
          type='email'
          pattern='[^\s@]+@[^\s@]+\.[^\s@]+'
          disabled={!emailAddressField?.enabled}
        />

        {type === 'customer' && (
          <InputGroupComponent
            label={I18n.translate('attributes.personal_id_number')}
            name='customer[contact_personal_id_number]'
            value={getValue('contactPersonalIdNumber')}
            placeholder=''
            onChange={(e) => setInfo('ContactPersonalIdNumber', e)}
            disabled={!customerPersonalIdNumberField?.enabled}
          />
        )}

        {data instanceof CustomerLead && type == 'main' && (
          <InputGroupComponent
            label={I18n.translate('attributes.company_name')}
            name='customer_lead[company_name]'
            value={data.companyName}
            required={true}
            placeholder=''
            onChange={(e) => data.setCompanyName(e.target.value)}
            disabled={!companyNameField?.enabled}
          />
        )}
      </Grid>
    </FieldSetWithTitle>
  );
};

export default observer(MainContactInfo);
