import { action, observable, computed } from 'mobx';
import type Sample from 'cuppingTable/type/Sample';
import type CuppingCustomer from 'cuppingTable/type/CuppingCustomer';
import SelectOptionArray from 'types/model/selectOption';

class CuppingTableStore {
  @observable samples: Sample[];
  @observable cuppingCustomers: CuppingCustomer[];
  @observable selectedSamples: Sample[];

  public userOptions: SelectOptionArray;
  public customerOptions: SelectOptionArray;
  public sampleTypes: SelectOptionArray;
  public qualities: SelectOptionArray;
  public grades: SelectOptionArray;
  public processTypes: SelectOptionArray;
  public cuppingCustomersOptions: Object;
  public cuppingTableId?: number;

  constructor(
    samples: Sample[],
    user_options: SelectOptionArray,
    customer_options: SelectOptionArray,
    sample_types: SelectOptionArray,
    qualities: SelectOptionArray,
    grades: SelectOptionArray,
    process_types: SelectOptionArray,
    cupping_customers_options: Object,
    cuppingCustomers?: CuppingCustomer[],
    cupping_table_id?: number
  ) {
    this.cuppingCustomers = cuppingCustomers || [{ identifier: 1 }];
    this.samples = samples;
    this.selectedSamples = samples.filter((sample) => sample.selected);
    this.userOptions = user_options;
    this.customerOptions = customer_options;
    this.sampleTypes = sample_types;
    this.qualities = qualities;
    this.grades = grades;
    this.processTypes = process_types;
    this.cuppingCustomersOptions = cupping_customers_options;
    this.cuppingTableId = cupping_table_id;
  }

  @computed get allCuppingCustomers() {
    return this.cuppingCustomers;
  }

  @action public addCuppingCustomer() {
    const existingIdentifiers = this.cuppingCustomers.map(
      (customer) => customer.identifier
    );
    let newIdentifier = 1;
    while (existingIdentifiers.includes(newIdentifier)) {
      newIdentifier++;
    }
    this.cuppingCustomers.push({ identifier: newIdentifier });
  }

  @action public removeCuppingCustomer(cuppingCustomer: CuppingCustomer) {
    if (cuppingCustomer.id) {
      cuppingCustomer.destroyable = true;
      return;
    }
    this.cuppingCustomers = this.cuppingCustomers.filter(
      (customer) => customer.identifier !== cuppingCustomer.identifier
    );
  }

  @action public toggleSample(sampleId: number) {
    const selectedSample = this.samples.filter((sample) => sample.id === sampleId)[0];
    selectedSample.selected = !selectedSample.selected;

    if (selectedSample.selected) {
      this.selectedSamples.push(selectedSample);
    } else {
      this.selectedSamples = this.selectedSamples.filter(
        (sample) => sample.id !== sampleId
      );
    }

    if (!selectedSample.selected && selectedSample.cupping_table_sample_id) {
      selectedSample.destroyable = true;
    } else {
      selectedSample.destroyable = false;
    }
  }

  @action public setSelectedCustomer(
    cuppingCustomer: CuppingCustomer,
    customerId: string
  ) {
    cuppingCustomer.customerId = customerId;
  }

  @action public setSelectedTaster(cuppingCustomer: CuppingCustomer, tasterId: string) {
    cuppingCustomer.tasterId = tasterId;
  }

  @action public setSelectedUser(cuppingCustomer: CuppingCustomer, userId: string) {
    cuppingCustomer.userId = userId;
  }

  @action public setSelectedCustomerType(cuppingCustomer: CuppingCustomer, type: string) {
    cuppingCustomer.type = type;
  }

  @computed public get unselectedSamples(): Sample[] {
    return this.samples.filter((sample) => !sample.selected);
  }

  @computed public get destroyableSamples(): Sample[] {
    return this.samples.filter((sample) => sample.destroyable);
  }
}

export default CuppingTableStore;
