const SEPARATOR = '_';
function parameterizeString(identifier) {
  const replaceLeadingAndEndingSep = `$${SEPARATOR}+|${SEPARATOR}$`;
  const replaceLeadingAndEndingSepReg = new RegExp(replaceLeadingAndEndingSep, 'g');
  return identifier
    .replace(/[^a-z0-9\-_]+/g, SEPARATOR)
    .replace(replaceLeadingAndEndingSepReg, '');
}
export function addTestingIdentifier(id = '') {
  if (id && ['development', 'test'].includes(process.env.RAILS_ENV || '')) {
    return {
      'data-cy': parameterizeString(id),
    };
  }
  return {};
}
