import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import BaseGroupComponent from 'components/groups/BaseGroup';
import Checkbox from 'shared/Checkbox';

import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import Input from 'components/elements/Input';
import { VARIANT } from 'constants/inputTypes';

import SelectableSubproductModel from 'subproducts/models/SelectableSubproduct';
import Can from 'components/shared/Can';

export const IdCol = styled.td`
  width: 8em;
  max-width: 8em;
`;

export const IcoCol = styled.td`
  width: 14em;
  max-width: 14em;
`;

export const CoffeeTypeCol = styled.td`
  text-align: center;
  width: 12.25em;
  max-width: 12.25em;
`;

export const InputCol = styled.td`
  text-align: center;
  width: 20%;

  .input-group--inline,
  .input-group__row {
    width: 100%;
  }
`;

export const WeightCol = styled.td`
  width: 12em;
  max-width: 12em;
  text-align: right;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input[type='text'] {
    width: 5em;
  }
`;

interface SubproductProps {
  subproduct: SelectableSubproductModel;
}

const SubproductItem = ({ subproduct }: SubproductProps) => {
  const [realSize, setSubproductSize] = React.useState(0);

  useEffect(() => {
    setSubproductSize(subproduct.attributes.total_weight);
  }, [subproduct.attributes.available_weight]);

  return (
    <tr>
      <IdCol className='align-left lot--id table-border'>
        {subproduct.attributes.identifier}
      </IdCol>

      <IcoCol className='table-border'>{subproduct.attributes.ico_identifier}</IcoCol>

      <td className='align-center table-border'>
        <TextWithSub
          text={subproduct.attributes.grade}
          sub={subproduct.attributes.quality}
        />
      </td>

      <CoffeeTypeCol className='table-border'>
        {subproduct.attributes.coffee_type}
      </CoffeeTypeCol>

      <WeightCol className='table-border'>
        {subproduct.attributes.available_weight} kg /{' '}
        {subproduct.attributes.initial_weight} kg
      </WeightCol>

      <InputCol>
        <InputWrapper>
          <BaseGroupComponent append='kg' inline={true}>
            <Input
              variant={VARIANT.DECIMAL}
              condensed={true}
              value={subproduct.quantity}
              readOnly={!subproduct.selected}
              step={0.01}
              min={1}
              max={subproduct.attributes.available_weight}
              validator={(e) =>
                parseFloat(e.currentTarget.value) <=
                subproduct.attributes.available_weight
              }
              onChange={(e) => subproduct.setQuantity(e.currentTarget.value)}
            />
          </BaseGroupComponent>

          <Can I='create' a='Subproducts::ProductionOrder'>
            <Checkbox
              checked={subproduct.selected}
              disabled={!realSize}
              onChange={() => subproduct.setSelected(!subproduct.selected)}
              dataCy='select_subproduct_checkbox'
            />
          </Can>
        </InputWrapper>
      </InputCol>
    </tr>
  );
};

export default observer(SubproductItem);
