import { computed } from 'mobx';
import type DestinationWarehoustCostType from '../types/DestinationWarehouseCost';
import type { ExchangeRate } from '../types/DestinationWarehouseCost';

export class DestinationWarehouseCost {
  public readonly exchangeRate: ExchangeRate;
  public readonly ipccHeatTreatedFeeCost: number | null;
  public readonly loadCostsEditable: boolean;
  public readonly nonHeatTreatedFeeCost: number | null;
  public readonly palletizingMinimumCost: number;
  public readonly palletizingPerUnitCost: number | null;
  public readonly rebaggingPerUnitCost: number | null;
  public readonly rushFeeCost: number | null;
  public readonly samplingFeeCost: number | null;

  public constructor(destinationWarehouseCost: DestinationWarehoustCostType) {
    this.exchangeRate = destinationWarehouseCost.exchange_rate;
    this.ipccHeatTreatedFeeCost =
      parseFloat(destinationWarehouseCost.ipcc_heat_treated_fee_cost) || null;
    this.loadCostsEditable = destinationWarehouseCost.loadout_costs_editable;
    this.nonHeatTreatedFeeCost =
      parseFloat(destinationWarehouseCost.non_heat_treated_fee_cost) || null;
    this.palletizingMinimumCost =
      parseFloat(destinationWarehouseCost.palletizing_minimum_cost || '') || 0.0;
    this.palletizingPerUnitCost =
      parseFloat(destinationWarehouseCost.palletizing_per_unit_cost) || null;
    this.rebaggingPerUnitCost =
      parseFloat(destinationWarehouseCost.rebagging_per_unit_cost) || null;
    this.rushFeeCost = parseFloat(destinationWarehouseCost.rush_fee_cost) || null;
    this.samplingFeeCost = parseFloat(destinationWarehouseCost.sampling_fee_cost) || null;
  }

  @computed get sameCurrency(): boolean {
    return this.exchangeRate.to.symbol === this.exchangeRate.from.symbol;
  }
}

export default DestinationWarehouseCost;
