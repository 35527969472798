import { computed, observable } from 'mobx';
import IcoImport from './IcoImport';
import type { IIco } from '../types/api';

class IcoImportList {
  @observable icoImports: IcoImport[];

  constructor(icos: IIco[]) {
    this.icoImports = icos.map(
      ({ id, identifier, weight, ico_import }) =>
        new IcoImport({ ...ico_import, identifier, id, weight })
    );
  }

  @computed get isFinalized() {
    return this.icoImports.every((icoImport) => icoImport.isClosed);
  }
}

export default IcoImportList;
