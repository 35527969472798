import classnames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Checkbox from 'shared/Checkbox';
import RadioGroup from '../models/RadioGroup';

interface OverallInputProps {
  sampleId: number;
  fourColumns?: boolean;
  useInOverlay?: boolean;
  completed?: boolean;
  descriptorOveralls: RadioGroup<any>;
}

const OverallInput = ({
  sampleId,
  fourColumns,
  useInOverlay,
  completed = false,
  descriptorOveralls,
}: OverallInputProps) => {
  return (
    <div
      className={classnames(
        'check-group-list',
        'check-group-list__dense',
        fourColumns && 'check-group-list__dense--four-columns',
        useInOverlay && 'check-group-list--overlay'
      )}
    >
      {descriptorOveralls.radios.map(({ id, name }) => (
        <Checkbox
          key={id}
          label={name}
          icon={'tick'}
          id={`sample_${sampleId}_descriptor_overall_id_${id}`}
          name={`samples[${sampleId}][descriptor_overall_id]`}
          value={`${id}`}
          square={true}
          asRadioButton={true}
          onChange={() => descriptorOveralls.selectRadio(id)}
          checked={descriptorOveralls.selectedRadioId === id}
          readOnly={completed}
          // required={true}
        />
      ))}
    </div>
  );
};

export default observer(OverallInput);
