import React from 'react';
import { Provider } from 'mobx-react';

import FuturesOverview from '../components/FuturesOverview';

import FuturesStore from '../stores/FutureStore';
import type { Future, Contract, Bundle, Filter } from '../stores/FutureStore';

const FuturesContainer = ({
  futures,
  contracts,
  bundles,
  years,
  filter,
  totalUnrealised,
  totalRealised,
}: {
  futures: Array<Future>;
  contracts: Array<Contract>;
  bundles: Array<Bundle>;
  years: Array<number>;
  filter: Filter;
  totalUnrealised: string;
  totalRealised: string;
}) => (
  <Provider
    futuresStore={
      new FuturesStore(
        futures,
        contracts,
        bundles,
        years,
        filter,
        totalUnrealised,
        totalRealised
      )
    }
  >
    <FuturesOverview />
  </Provider>
);

export default FuturesContainer;
