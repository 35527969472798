import React from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { ContractSelectGroup, ContractTextInput } from '../ContractFields';

export const ContractType = ({
  contractTypeField,
  incotermField,
  incoterm,
  setIncoterm,
  monthlyCarryField,
  monthlyCarry,
  setMonthlyCarry,
  maxCarryField,
  maxCarry,
  setMaxCarry,
  freeDaysField,
  freeDays,
  setFreeDays,
}) => {
  const greaterThanEqualZero = (event) =>
    event.target && parseFloat(event.target.value) >= 0.0;
  let maxCarryLabel = I18n.translate(`contracts.form.max_carry`);
  if (contractTypeField) {
    maxCarryLabel =
      contractTypeField?.value == 'FWD'
        ? I18n.translate(`contracts.form.max_carry_days`)
        : I18n.translate(`contracts.form.max_carry_months`);
  }
  return (
    <FieldSetWithTitle title={I18n.translate('contracts.form.contract_type')}>
      <Grid>
        <ContractTextInput
          field={contractTypeField}
          value={I18n.translate(
            `contracts.types.${contractTypeField?.value.toLowerCase()}`
          )}
        />
        <ContractSelectGroup
          field={incotermField}
          value={incoterm}
          onChange={(val) => setIncoterm(val)}
        />
        {(!incotermField || ['EXW', 'DLD', 'FCA', 'DAP', 'DDP'].includes(incoterm)) && (
          <>
            <ContractTextInput
              field={monthlyCarryField}
              value={monthlyCarry}
              onChange={setMonthlyCarry}
              append='%'
              step='0.01'
              type='number'
              validator={greaterThanEqualZero}
            />
            <ContractTextInput
              field={maxCarryField}
              value={maxCarry}
              onChange={setMaxCarry}
              label={maxCarryLabel}
              type='number'
            />
            <ContractTextInput
              field={freeDaysField}
              value={freeDays}
              onChange={setFreeDays}
              type='number'
            />
          </>
        )}
      </Grid>
    </FieldSetWithTitle>
  );
};
