import React from 'react';
import { observer } from 'mobx-react';

import ScrollableOverlayBaseForm from 'shared/Overlay/components/ScrollableOverlayBaseForm';
import OverlayFooter from 'shared/Overlay/components/OverlayFooter';
import OverlayPortal from 'shared/Overlay/components/OverlayPortal';
import OverlayTitle from 'shared/Overlay/components/OverlayTitle';
import OverlayWrapper from 'shared/Overlay/components/OverlayWrapper';
import ScrollableOverlayBody from 'shared/Overlay/components/ScrollableOverlayBody';

import Checkbox from 'shared/Checkbox';

import useStores from 'utils/useStores';

const HarbourSelectOverlay = () => {
  const { exportsStore } = useStores();

  return (
    <>
      {exportsStore.showHarbourOverlay && (
        <OverlayPortal>
          <OverlayWrapper>
            <ScrollableOverlayBaseForm>
              <OverlayTitle>
                {I18n.translate('exports.select_visible_harbours')}
              </OverlayTitle>

              <ScrollableOverlayBody>
                <div className='check-group-list check-group-list--three-columns check-group-list--three-columns--with-gap'>
                  {exportsStore.destinationPorts.map(({ name }) => (
                    <Checkbox
                      key={name}
                      label={name}
                      icon={'tick'}
                      labelMultiline={true}
                      iconColor={'GREEN'}
                      id={`export_harbours_${name}`}
                      name={`export_harbours[destinationPort]`}
                      value={name}
                      onChange={() => exportsStore.toggleExcludedDestinationPort(name)}
                      checked={!exportsStore.excludedDestinationPortNames.includes(name)}
                    />
                  ))}
                </div>
              </ScrollableOverlayBody>

              <OverlayFooter className='l-item-stack-horizontal'>
                <div className='overlay-buttons'>
                  <button
                    data-cy='overlay_secondary_button'
                    type='button'
                    onClick={() => exportsStore.setShowHarbourOverlay(false)}
                    className='button button--gray'
                  >
                    {I18n.translate('buttons.close')}
                  </button>
                  <button
                    type='button'
                    data-cy='overlay_primary_button'
                    onClick={() => exportsStore.toggleExcludedDestinationPorts()}
                    className='button'
                  >
                    {I18n.translate('buttons.toggle_all')}
                  </button>
                </div>
              </OverlayFooter>
            </ScrollableOverlayBaseForm>
          </OverlayWrapper>
        </OverlayPortal>
      )}
    </>
  );
};

export default observer(HarbourSelectOverlay);
