import React from 'react';
import { observer } from 'mobx-react';
import { deserialise } from 'kitsu-core';

import Icon from 'components/elements/Icon';
import InputGroup from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import COLORS from 'constants/colors';
import styled from 'styled-components';
import SelectOptionArray from 'types/model/selectOption';
import IcoImport from 'exports/models/IcoImport';

export interface IcoPopupProps {
  icoImport: IcoImport;
  isThirdPartyImport: boolean;
  destinationWarehouses: SelectOptionArray;
  destinationWarehousesData?: SelectOptionArray;
}

const InputGroupWrapper = styled(InputGroup)`
  height: auto !important;
`;

const SelectGroupWrapper = styled.div`
  .input-group {
    min-width: 10rem;
    margin-bottom: 0;
  }

  select {
    height: auto;
  }
`;

const IcoNameWrapper = styled.td`
  min-width: 10rem;
`;

const DestinationWrapper = styled.td`
  min-width: 12rem;
`;

const ButtonWrapper = styled.div`
  min-width: 13rem;
`;

const BinWrapper = styled.div`
  min-width: 7rem;
`;

const WeightWrapper = styled.p`
  font-size: 1rem;
  color: ${COLORS.GRAY};
`;

const IcoImportRow = ({
  icoImport,
  destinationWarehousesData,
  destinationWarehouses,
  isThirdPartyImport,
}: IcoPopupProps) => {
  if (destinationWarehousesData) {
    destinationWarehouses = deserialise(destinationWarehousesData).data;
  }

  if (!destinationWarehouses) {
    throw new Error(
      'Provide either destinationWarehouses or serialized destinationWarehouseData'
    );
  }

  const handleOnCloseClick = (_event: React.MouseEvent<HTMLButtonElement>) =>
    icoImport.createOrUpdateIcoImport();

  const handleOnDestinationWarehouseChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);

    icoImport.setDestinationWarehouse(id);
  };

  const handleOnArrivalDateChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    icoImport.setArrivalDate(event.target.value);

  const handleOnBinChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    icoImport.setBin(event.target.value);

  return (
    <tr key={icoImport.id}>
      <IcoNameWrapper>
        {icoImport.identifier}
        <WeightWrapper>{icoImport.weight}</WeightWrapper>
      </IcoNameWrapper>

      {isThirdPartyImport && (
        <DestinationWrapper>
          {icoImport.originCountryCode}
          <WeightWrapper>
            {icoImport.destinationPortName} | {icoImport.destinationCountryCode}
          </WeightWrapper>
        </DestinationWrapper>
      )}

      <td className='input-table-data'>
        <SelectGroupWrapper>
          <SelectGroup
            placeholder={I18n.translate('exports.transports_popup.select_warehouse')}
            options={destinationWarehouses}
            name='destination_warehouse_id'
            value={icoImport.destinationWarehouseId || ''}
            onChange={handleOnDestinationWarehouseChange}
            disabled={icoImport.isClosed}
          />
        </SelectGroupWrapper>
      </td>

      <td className='input-table-data'>
        <InputGroupWrapper
          name='arrival_date'
          noMargin={true}
          type='date'
          value={icoImport.arrivalDate}
          onChange={handleOnArrivalDateChange}
          disabled={icoImport.isClosed}
        />
      </td>

      <td className='input-table-data'>
        <BinWrapper>
          <InputGroupWrapper
            name='bin'
            noMargin={true}
            placeholder='00000000'
            value={icoImport.bin}
            onChange={handleOnBinChange}
            disabled={icoImport.isClosed}
          />
        </BinWrapper>
      </td>

      <td>
        <ButtonWrapper>
          {icoImport.isClosed && <Icon icon='check' color='#6EA340' />}
          {!icoImport.isClosed && (
            <button
              type='button'
              onClick={handleOnCloseClick}
              disabled={!icoImport.isCloseable}
              className='button button--with-blue-border exports-card__popup-button'
            >
              {I18n.translate('buttons.close')}
            </button>
          )}
        </ButtonWrapper>
      </td>
    </tr>
  );
};
export default observer(IcoImportRow);
