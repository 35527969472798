import BorderBox from 'components/elements/BorderBox';
import ContentHeader from 'components/elements/ContentHeader';
import React from 'react';
import Routes from 'routes';
import Pagynation, { Pagy } from 'shared/Pagynation';
import { SAMPLE_TYPE } from 'types/model/ISample';
import ISample from '../../types/ISample';
import SearchBar from './SearchBar';
import parseUSVString from 'utils/parseUSVString';
import LotInfoBox from './LotInfoBox';
import Flash from 'components/elements/Flash';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';

export interface AnalyseAndAdjustProps extends React.HTMLAttributes<HTMLDivElement> {
  pagy: Pagy;
  samples: ISample[];
  currentSampleType: SAMPLE_TYPE;
  currentLocalIdentifier: string;
  currentMark: string;
  currentOrigin: string;
  currentQuality: string;
}

function AnalyseAndAdjust({
  pagy,
  samples,
  currentSampleType,
  currentLocalIdentifier,
  currentMark,
  currentOrigin,
  currentQuality,
}: AnalyseAndAdjustProps) {
  const searchParams = parseUSVString(window.location?.search);
  const { analyseAndAdjustStore } = useStores();
  const disableFlash = () => {
    analyseAndAdjustStore.setEnableFlash(false);
  };
  return (
    <div className='content'>
      {analyseAndAdjustStore.enableFlash && (
        <Flash
          icon='cross'
          color='#b94a48'
          title={I18n.translate('shared.analyse_and_adjust.send_sample_to_lab')}
          action={disableFlash}
        />
      )}
      <ContentHeader title={I18n.translate('headlines.analyse_and_adjust')} />

      <SearchBar
        currentSampleType={currentSampleType}
        currentLocalIdentifier={currentLocalIdentifier}
        currentMark={currentMark}
        currentOrigin={currentOrigin}
        currentQuality={currentQuality}
      />

      {samples.map((sample) => {
        return (
          <BorderBox key={sample.id}>
            <LotInfoBox sample={sample} />
          </BorderBox>
        );
      })}

      <Pagynation
        pagy={pagy}
        url={Routes.analyse_and_adjust_samples_path}
        searchParams={searchParams}
      />
    </div>
  );
}

export default observer(AnalyseAndAdjust);
