import { action, computed, observable } from 'mobx';

import { SelectableLot, Lot } from '../types/Lot';
import Certificate from 'types/model/certificate';
import Grade from 'types/model/grade';
import ProcessType from 'types/model/processType';
import Quality from 'types/model/quality';
import Variety from 'types/model/variety';
import Sample from 'types/model/ISample';
import { deserialise } from 'kitsu-core';
import { JsonApiResource } from 'types/utils/jsonApi';

export interface Requirements {
  [key: string]: Requirement;
}

export interface Requirement {
  excludes?: Array<{
    rule: string;
    value: any;
  }>;
}

export interface LotsData {
  data: Array<JsonApiResource<Lot>>;
  included: Array<
    JsonApiResource<Sample | Certificate | Grade | ProcessType | Quality | Variety>
  >;
}

class SetStore {
  public lots = observable.array<SelectableLot>();
  @observable public sampleSizeChecked = false;
  @observable public bags = 0;

  constructor(lots: LotsData) {
    const deserialisedLots: Lot[] = deserialise(lots).data;

    const selectableLots = deserialisedLots.map((deserialisedLot) => ({
      selected: false,
      attributes: deserialisedLot,
    }));

    this.lots = observable.array(selectableLots);
  }

  @action public selectLot = (lot: SelectableLot) => {
    lot.selected = true;
  };

  @action public deselectLot = (lot: SelectableLot) => {
    lot.selected = false;
    this.bags = 0; // will be a reaction in the future
  };

  @action public setSampleSizeChecked = (value: boolean) => {
    this.sampleSizeChecked = value;
  };

  @action public setBags = (value: string) => {
    this.bags = parseInt(value, 10);
  };

  @computed get selectedLots(): Array<SelectableLot> {
    return this.lots.filter((lot) => lot.selected);
  }

  @computed get selectedRequirements(): {} {
    const requirements: Requirements = {
      quality: {},
      certification: {},
      no_certificate_or_conventional: {},
      eudr: {},
    };

    const requirementsCatalogue = {};

    Object.entries(requirements).forEach(([requirement]) => {
      const lot = this.selectedLots[0];
      const lotRequirement = lot?.attributes[requirement];

      if (lotRequirement !== undefined) {
        requirementsCatalogue[requirement] = lotRequirement;
      }

      // Ignore the certificate if it is empty or the producer agreed_to_no_premium_price on lot
      if (
        requirementsCatalogue['certification'] &&
        lot.attributes.no_certificate_or_conventional
      ) {
        delete requirementsCatalogue['certification'];
      }
    });

    return requirementsCatalogue;
  }

  @computed get selectedTotalBags(): number {
    const selectedLotsBags = this.selectedLots.reduce(
      (totalBags, { attributes }) => totalBags + attributes.number_calibrated_bags,
      0
    );

    return this.bags || selectedLotsBags;
  }

  @computed get totalWeight(): number {
    return this.selectedLots.reduce(
      (totalWeight, { attributes }) => totalWeight + attributes.weight,
      0
    );
  }

  @computed get isBagsValid(): boolean {
    return this.selectedTotalBags >= 1;
  }

  @computed get isFormValid(): boolean {
    return this.selectedLots.length > 1 && this.sampleSizeChecked && this.isBagsValid;
  }
}

export default SetStore;
