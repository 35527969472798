import React, { useContext } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import COLORS from 'constants/colors';

import Icon from 'components/elements/Icon';
import Export from 'exports/models/Export';
import ExportIcon from './ExportIcon';
import useStores from 'utils/useStores';
import { AbilityContext } from 'components/shared/Can';
import { formatExportDate } from 'exports/exportUtils';

export interface CardHeaderProps {
  exportModel: Export;
  onListClick: () => void;
  onStartShippingClick: () => void;
  onEndShippingClick: () => void;
}

const HeaderTitleInfo = styled.span``;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid ${COLORS.BLACK};
  padding-bottom: 0.125rem;
`;
const HeaderTitle = styled.h3`
  font-weight: bold;
  font-size: 1.25rem;
  font-style: uppercase;
`;

const Header = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${COLORS.BORDERS};
`;

function CardHeader({
  exportModel,
  onListClick,
  onStartShippingClick,
  onEndShippingClick,
}: CardHeaderProps): JSX.Element {
  const ability = useContext(AbilityContext);
  const { exportsStore } = useStores();
  const shippingPort = exportsStore.ports.find(
    (port) => port.id == exportModel.shippingPortId
  );

  return (
    <>
      <HeaderWrapper>
        <HeaderTitle>{exportModel.mainIdentifier}</HeaderTitle>
        <HeaderTitleInfo>
          {shippingPort?.name}, {exportModel.incoterm}
        </HeaderTitleInfo>
      </HeaderWrapper>
      <Header>
        <button
          type='button'
          onClick={onListClick}
          className='button button--with-blue-border exports-card__header-button'
          disabled={!ability.can('edit', 'Exports::Export')}
        >
          <Icon icon='list' color='#111820' className='exports-card__ico-icon' />
          <ExportIcon
            type={exportModel.shipmentSettingsStateIcon}
            className='exports-card__ico-icon'
          />
        </button>

        <Icon
          icon='arrow-right-alt'
          color='#006f94'
          className='exports-card__ico-icon exports-card__ico-icon--small'
        />

        <button
          type='button'
          onClick={onStartShippingClick}
          className='button button--with-blue-border exports-card__header-button'
          disabled={!ability.can('edit', 'Exports::Export')}
        >
          <Icon icon='export' color='#111820' className='exports-card__ico-icon' />
          <ExportIcon
            type={exportModel.startShipmentStateIcon}
            className='exports-card__ico-icon'
          />
          {formatExportDate(exportModel.exportDate)}
        </button>

        {exportModel.willBeImported && (
          <>
            <Icon
              icon='arrow-right-alt'
              color='#006f94'
              className='exports-card__ico-icon exports-card__ico-icon--small'
            />

            <button
              type='button'
              onClick={onEndShippingClick}
              className='button button--with-blue-border exports-card__header-button'
              disabled={!ability.can('edit', 'Exports::Export')}
            >
              <Icon icon='import' color='#111820' className='exports-card__ico-icon' />
              <ExportIcon
                type={exportModel.endShipmentStateIcon}
                className='exports-card__ico-icon'
              />
              {formatExportDate(exportModel.importDate)}
            </button>
          </>
        )}
      </Header>
    </>
  );
}

export default observer(CardHeader);
