import React from 'react';
import QualitiesPrice from './QualitiesPrice';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';

const QualitiesPrices = () => {
  const { qualitiesPricesStore } = useStores();

  return (
    <table data-cy='qualities_prices'>
      <thead>
        <tr>
          <td></td>
          <td>{I18n.translate('attributes.quality')}</td>
          <td>{I18n.translate('attributes.cup_premium')}</td>
        </tr>
      </thead>

      <tbody>
        {qualitiesPricesStore.checkableQualitiesPrices.map((qualitiesPrice, index) => (
          <QualitiesPrice key={index} qualitiesPrice={qualitiesPrice} />
        ))}
      </tbody>
    </table>
  );
};

export default observer(QualitiesPrices);
