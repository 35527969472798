import { observer } from 'mobx-react';
import React from 'react';
import SampleTransportModel from 'samples/receive_samples/models/SampleTransport';
import useStores from 'utils/useStores';
import ReceiveSampleTransportRow from './ReceiveSampleTransportRow';

function ReceiveSampleTransports(): JSX.Element {
  const { receiveSampleTransportsStores } = useStores();
  return (
    <>
      {receiveSampleTransportsStores.transports.map((transport: SampleTransportModel) => (
        <ReceiveSampleTransportRow key={transport.attributes.id} transport={transport} />
      ))}
    </>
  );
}

export default observer(ReceiveSampleTransports);
