import React from 'react';
import Overlay from 'shared/Overlay';
import { OverlayProps } from 'shared/Overlay/components/Overlay';

export interface ConfirmationOverlayProps extends Partial<OverlayProps> {
  title: string;
  confirmText: string;
  cancelText: string;
  onConfirmClick: (event: React.MouseEvent) => any;
  onCancelClick: (event: React.MouseEvent) => any;
}

const ConfirmationOverlay: React.FC<ConfirmationOverlayProps> = ({
  confirmText,
  cancelText,
  onConfirmClick,
  onCancelClick,
  ...props
}) => {
  return (
    <Overlay
      primaryText={confirmText}
      onPrimaryClick={onConfirmClick}
      secondaryText={cancelText}
      onSecondaryClick={onCancelClick}
      {...props}
    />
  );
};

export default ConfirmationOverlay;
