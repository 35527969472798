import React from 'react';

import ConfirmationOverlay from 'shared/ConfirmationOverlay';

export interface LotRemoveButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  lotIdentifier: string;
  lotSetIdentifier: string;
  onConfirm: (event: React.MouseEvent) => any;
}

const LotRemoveButton: React.FC<LotRemoveButtonProps> = ({
  onConfirm,
  lotIdentifier,
  lotSetIdentifier,
}) => {
  const [showOverlay, setOverlay] = React.useState(false);

  const handleOnRemoveClick = () => {
    setOverlay(true);
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  return (
    <>
      {showOverlay && (
        <ConfirmationOverlay
          title={I18n.translate('traceability.remove.confirm.title', {
            lot: lotIdentifier,
            lot_set: lotSetIdentifier,
          })}
          confirmText={I18n.translate('traceability.remove.confirm.primary')}
          onConfirmClick={onConfirm}
          cancelText={I18n.translate('traceability.remove.confirm.secondary')}
          onCancelClick={handleOnSecondaryClick}
        />
      )}

      <button
        onClick={handleOnRemoveClick}
        type='button'
        className='button button--small button--ghost'
      >
        {I18n.translate('traceability.remove.button')}
      </button>
    </>
  );
};

export default LotRemoveButton;
