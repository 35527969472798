export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function changeDatePicker(data) {
  if (!isSafari()) {
    return null;
  }
  return data;
}
