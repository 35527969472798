import React from 'react';
import { observer } from 'mobx-react';

import styled from 'styled-components';

import useStores from 'utils/useStores';
import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';

import Tile, { TileHeader } from 'shared/Tile';

const Actions = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SearchWrapper = styled.div`
  flex-direction: row;
  display: inline-flex;
  min-width: 13.25rem;
  margin-right: 1.25rem;

  .input-group {
    width: 100%;
  }
`;

const SelectWrapper = styled.div`
  flex-direction: row;
  display: inline-flex;
  max-width: 100%;
  margin-right: 1.25rem;

  .input-group {
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const DateWrapper = styled.div`
  flex-direction: row;
  display: inline-flex;
  max-width: 100%;

  .input-group {
    width: 100%;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Bullet = styled.div`
  align-items: center;
  display: inline-flex;
  padding: 0 0.5rem;
  font-size: 1rem;
`;

function SearchBar(): JSX.Element {
  const { customerSamplesDashboardStore: store } = useStores();

  return (
    <Tile>
      <TileHeader className='selected-lots__header'>
        <Actions>
          <SearchWrapper>
            <InputGroupComponent
              value={store.search || ''}
              placeholder={I18n.translate(
                'customer_samples_dashboard.search_placeholder'
              )}
              onChange={(e) => store.setSearch(e.target.value)}
            />
          </SearchWrapper>

          <SelectWrapper>
            <SelectGroup
              options={store.types}
              placeholder={I18n.translate('customer_samples_dashboard.type_placeholder')}
              onChange={(e) => store.setType(e.target.value)}
              required={true}
              value={store.type ? store.type : ''}
            />
          </SelectWrapper>

          <SelectWrapper>
            <SelectGroup
              options={store.states}
              placeholder={I18n.translate(
                'customer_samples_dashboard.status_placeholder'
              )}
              onChange={(e) => store.setStatus(e.target.value)}
              required={true}
              value={store.state ? store.state : ''}
            />
          </SelectWrapper>

          <SelectWrapper>
            <SelectGroup
              options={store.originCountries}
              placeholder={I18n.translate(
                'customer_samples_dashboard.origin_placeholder'
              )}
              onChange={(e) => store.setOriginCountryId(e.target.value)}
              required={true}
              value={store.originCountryId ? store.originCountryId : ''}
            />
          </SelectWrapper>

          <DateWrapper>
            <SelectGroup
              options={store.months}
              onChange={(e) => store.dateRange.setStartMonth(e.target.value)}
              value={store.dateRange?.startMonth ? store.dateRange?.startMonth : ''}
            />

            <SelectGroup
              options={store.years}
              onChange={(e) => store.dateRange.setStartYear(e.target.value)}
              value={store.dateRange?.startYear ? store.dateRange?.startYear : ''}
            />
          </DateWrapper>

          <Bullet>-</Bullet>

          <DateWrapper>
            <SelectGroup
              options={store.months}
              onChange={(e) => store.dateRange.setEndMonth(e.target.value)}
              value={store.dateRange?.endMonth ? store.dateRange?.endMonth : ''}
            />

            <SelectGroup
              options={store.years}
              onChange={(e) => store.dateRange.setEndYear(e.target.value)}
              value={store.dateRange?.endYear ? store.dateRange?.endYear : ''}
            />
          </DateWrapper>
        </Actions>
      </TileHeader>
    </Tile>
  );
}

export default observer(SearchBar);
