import { deserialise } from 'kitsu-core';
import { observable } from 'mobx';

import WetLotModel from 'wetCoffee/models/WetLot';
import CoffeeType from 'types/model/coffee_type';

class WetReceptionStore {
  @observable public lots = Array<WetLotModel>();
  public coffeeTypes = Array<CoffeeType>();

  constructor(lots: any, coffeeTypes: Array<CoffeeType>) {
    const wetLots = deserialise(lots).data.map((lot) => new WetLotModel(lot));

    this.lots = observable.array(wetLots);
    this.coffeeTypes = coffeeTypes;
  }
}

export default WetReceptionStore;
