import { deserialise } from 'kitsu-core';

import { ReasonGet } from 'types/model/reason';
import { PeabiesPhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';
import SpecialtyGradingSpecificationType from 'types/model/specialtyGradingSpecification';
import PeabiesPhysicalAnalysis from '../models/PeabiesPhysicalAnalysis';
import SpecialtyGradingSpecification from '../models/SpecialtyGradingSpecification';
import Norm from '../models/Norm';
import Sample from 'productionOrders/models/Sample';
import { createSampleFactory } from 'utils/factories/createSampleFactory';
import SampleBuilder from 'utils/builders/SampleBuilder';
import { JsonApi } from 'types/utils/jsonApi';
import ContractType from '../types/Contract';
import Validation from 'types/utils/validations';
import ISample from 'millingOrders/types/ISample';

class PeabiesPhysicalAnalysisStore {
  public sample: Sample;
  public norm: Norm;
  public specialtyGradingSpecifications: SpecialtyGradingSpecification[];
  public peabiesPhysicalAnalysis: PeabiesPhysicalAnalysis;
  public reasons: ReasonGet[];
  public cancelUrl: string;

  constructor(
    sample: JsonApi<ISample>,
    contract: JsonApi<ContractType>,
    physicalAnalysis: JsonApi<PeabiesPhysicalAnalysisType>,
    specialtyGradingSpecifications: SpecialtyGradingSpecificationType[],
    reasons: ReasonGet[],
    cancelUrl: string,
    validations: {
      flat_bean_peaberry_percentage: Validation;
    }
  ) {
    const deserializedSample = deserialise(sample).data as ISample;
    const sampleBuilder = new SampleBuilder(deserializedSample);
    const { norm, preparation_type } = deserialise(contract).data as ContractType;

    this.sample = createSampleFactory(deserializedSample, sampleBuilder);
    this.norm = new Norm(norm);
    this.reasons = reasons;
    this.cancelUrl = cancelUrl;

    this.specialtyGradingSpecifications = deserialise(
      specialtyGradingSpecifications
    ).data.map((grading) => new SpecialtyGradingSpecification(grading));

    const deserialisedPhysicalAnalysis = deserialise(physicalAnalysis)
      .data as PeabiesPhysicalAnalysisType;

    this.peabiesPhysicalAnalysis = new PeabiesPhysicalAnalysis(
      this.sample.id,
      deserialisedPhysicalAnalysis,
      this.specialtyGradingSpecifications,
      this.norm,
      preparation_type,
      validations
    );
  }
}

export default PeabiesPhysicalAnalysisStore;
