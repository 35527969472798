import { action, observable } from 'mobx';

interface AvailableExcessOrOrphanModel {
  available_weight: number;
  grade: string;
  ico_identifier: string;
  alternative_ico_identifier: string;
  id: number;
  identifier: string;
  location: { area?: string; level?: string; bin?: string; position?: string };
  mark?: string;
  quality: string;
}

class AvailableExcessOrOrphanItemModel {
  public attributes: AvailableExcessOrOrphanModel;

  @observable public quantity: string;
  @observable public selected: boolean;

  constructor(item: AvailableExcessOrOrphanModel) {
    this.attributes = item;
    this.selected = false;
    this.quantity = item.available_weight.toString();
    this.quantity = parseFloat(this.quantity).toFixed(2).toString();
  }

  @action public setQuantity = (value: string) => {
    this.quantity = value;
  };

  @action public setSelected = (selected: boolean) => {
    this.selected = selected;
  };
}

export default AvailableExcessOrOrphanItemModel;
