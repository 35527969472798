import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';
import { observer } from 'mobx-react';
import Opportunity from 'customerSamples/models/Opportunity';
import Can from 'components/shared/Can';
import SpotOpportunity from 'customerSamples/models/SpotOpportunity';
import SampleTableBody from 'customerSamples/components/SampleTableBody';
import PssInternalContract from 'customerSamples/models/PssInternalContract';

export interface SampleTableProps {
  sampleEntity: Opportunity | SpotOpportunity | PssInternalContract;
}

const TableHeader = styled.th`
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: ${COLORS.BLACK_70};
`;

const SensorialHeader = styled(TableHeader)`
  width: 10rem;
`;

function SampleTable({ sampleEntity }: SampleTableProps): JSX.Element {
  return (
    <table className='table-filled'>
      <thead>
        <tr>
          <TableHeader></TableHeader>
          <TableHeader></TableHeader>
          <TableHeader className='align-center'>
            {I18n.translate(
              'customer_samples_dashboard.humidity_analysis_percentage_label'
            )}
          </TableHeader>

          <TableHeader className='align-center'>
            {I18n.translate('customer_samples_dashboard.water_analysis_percentage_label')}
          </TableHeader>

          <TableHeader className='align-center border-right'>
            {I18n.translate('customer_samples_dashboard.sensorial_score_label')}
          </TableHeader>

          <SensorialHeader className='align-center'>
            {I18n.translate('customer_samples_dashboard.sensorial_internal_label')}
          </SensorialHeader>

          <Can I='set_send_to_customer' a='CustomerSampleValue'>
            <SensorialHeader className='align-center'>
              {sampleEntity instanceof PssInternalContract
                ? I18n.translate('customer_samples_dashboard.received')
                : I18n.translate('customer_samples_dashboard.send_to_customer')}
            </SensorialHeader>
          </Can>

          <SensorialHeader></SensorialHeader>
        </tr>
      </thead>

      <tbody>
        <SampleTableBody sampleEntity={sampleEntity} />
      </tbody>
    </table>
  );
}

export default observer(SampleTable);
