import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Input from 'components/elements/Input';
import InputGroupComponent from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';

import {
  CompletedTypes,
  MillingTypeResult,
} from 'subproducts/millingOrders/components/FinalizeMillingOrderOverlay';
import { addTestingIdentifier } from 'utils/testing';

const TypeColumn = styled.td`
  width: 12.1875em;
`;
const LocationColumn = styled.td`
  width: 6.25em;
`;
const PositionColumn = styled.td`
  width: 10em;
`;
export const QuantityColumn = styled.td`
  width: 16em;
  padding-right: 6.25em !important;
`;

interface FinalizeMillingRowProps {
  type: keyof CompletedTypes;
  values: MillingTypeResult;
  maxWeight: number;
  withLocation: boolean;
  readOnly: boolean;
  onComplete: (type: keyof CompletedTypes, values: MillingTypeResult | null) => void;
}

const FinalizeMillingRowComponent: React.FC<FinalizeMillingRowProps> = ({
  type,
  values,
  withLocation,
  readOnly,
  onComplete,
  maxWeight,
}) => {
  const [area, setArea] = useState(values?.area || '');
  const [bin, setBin] = useState(values?.bin || '');
  const [level, setLevel] = useState(values?.level || '');
  const [position, setPosition] = useState(values?.position || '');
  const [quantity, setQuantity] = useState(values?.quantity || '');

  const validateQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = parseFloat(e.target.value);

    return quantity > 0.0 && quantity <= maxWeight;
  };

  useEffect(() => {
    onComplete(type, {
      area,
      bin,
      level,
      position,
      quantity,
    });
  }, [area, bin, level, position, quantity]);

  return (
    <tr key={type}>
      <TypeColumn className='table-border'>
        {I18n.translate(`milling_orders.finalise_milling.types.${type}`)}
      </TypeColumn>
      <LocationColumn className='table-border'>
        {withLocation && (
          <Input
            variant={VARIANT.INTEGER}
            placeholder=' '
            required={true}
            value={area}
            onChange={(e) => setArea(e.target.value)}
            {...addTestingIdentifier(`area_input`)}
          />
        )}
      </LocationColumn>
      <LocationColumn className='table-border'>
        {withLocation && (
          <Input
            variant={VARIANT.INTEGER}
            placeholder=' '
            required={true}
            value={bin}
            onChange={(e) => setBin(e.target.value)}
            {...addTestingIdentifier(`bin_input`)}
          />
        )}
      </LocationColumn>
      <LocationColumn className='table-border'>
        {withLocation && (
          <Input
            variant={VARIANT.INTEGER}
            placeholder=' '
            required={true}
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            {...addTestingIdentifier(`level_input`)}
          />
        )}
      </LocationColumn>
      <PositionColumn className='table-border'>
        {withLocation && (
          <Input
            required={true}
            placeholder=' '
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            {...addTestingIdentifier(`position_input`)}
          />
        )}
      </PositionColumn>
      <QuantityColumn>
        <InputGroupComponent
          variant={VARIANT.DECIMAL}
          placeholder=' '
          readOnly={readOnly}
          noMargin={true}
          append='kg'
          step={0.01}
          required={!readOnly}
          value={quantity}
          validator={validateQuantity}
          onChange={(e) => setQuantity(e.target.value)}
          {...addTestingIdentifier(`quantity_input`)}
        />
      </QuantityColumn>
    </tr>
  );
};

export default FinalizeMillingRowComponent;
