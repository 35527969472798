import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { COLORS } from 'constants/colors';

export enum Icons {
  tick = 'tick',
  cross = 'cross',
}

export interface CheckboxProps {
  label?: string;
  labelMultiline?: boolean;
  icon?: string;
  iconColor?: keyof typeof COLORS;
  name?: string;
  value?: string;
  checked?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  id?: string;
  square?: boolean;
  round?: boolean;
  asRadioButton?: boolean;
  required?: boolean;
  inline?: boolean;
  dataCy?: string;
  onChange?: (state: boolean) => void;
  onClick?: () => void;
  hoverText?: string;
}

const renderIcon = (icon: string, iconColor?: keyof typeof COLORS) => {
  switch (icon) {
    case Icons.tick:
      return (
        <>
          <path
            className='tick'
            d='M4 10l5 5 9-9'
            fill='none'
            strokeLinecap='round'
            strokeWidth='4'
            stroke={iconColor ? COLORS[iconColor] : COLORS.GREEN}
          />
        </>
      );

    case Icons.cross:
    default:
      return (
        <>
          <path
            className='cross'
            d='M2 2l21 15'
            strokeLinecap='round'
            strokeWidth='4'
            stroke={iconColor ? COLORS[iconColor] : COLORS.RED}
          />
          <path
            className='cross'
            d='M20 0L3 18'
            strokeLinecap='round'
            strokeWidth='4'
            stroke={iconColor ? COLORS[iconColor] : COLORS.RED}
          />
        </>
      );
  }
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      labelMultiline,
      icon = Icons.tick,
      iconColor,
      name,
      value,
      checked = false,
      condensed,
      disabled,
      inline,
      readOnly,
      id,
      square,
      round,
      asRadioButton,
      onChange,
      onClick,
      dataCy,
      required = false,
      hoverText,
    },
    ref
  ) => {
    const checkBoxRef = useRef<HTMLLabelElement>(null);
    const [isChecked, setIsChecked] = useState(checked);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
      if (onChange) {
        setIsChecked(checked);
      }
    }, [checked]);

    useEffect(() => {
      checkBoxRef?.current?.parentElement?.setAttribute('checked', isChecked.toString());
    }, [isChecked]);

    const className = classNames('checkbox', {
      'checkbox--condensed': condensed,
      'checkbox--disabled': disabled,
      'checkbox--inline': inline,
      'checkbox--no-label': !label,
      'checkbox--read-only': readOnly,
      'checkbox--round': round,
      'checkbox--square': square,
      'checkbox--label-multiline': labelMultiline,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        onChange(e.target.checked);
      } else {
        setIsChecked(!isChecked);
      }
    };

    const handleClick = (): void => {
      if (onClick) {
        onClick();
      }
    };

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <>
        {' '}
        <label
          className={className}
          htmlFor={id ? id : name}
          ref={checkBoxRef}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input
            type={asRadioButton ? 'radio' : 'checkbox'}
            id={id ? id : name}
            name={name}
            value={value || ''}
            checked={isChecked}
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleChange}
            required={required}
            ref={ref}
            data-cy={
              dataCy ||
              'checkbox_' +
                label
                  ?.replace(/ /g, '_')
                  .replace('?', '')
                  .replace('/', '_')
                  .replace(',', '_')
                  .toLowerCase()
            }
          />

          {!square && (
            <svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
              {round ? (
                <circle cx='11' cy='11' r='10' fill={COLORS.WHITE} stroke={COLORS.BLUE} />
              ) : (
                <rect
                  fill={COLORS.WHITE}
                  height='21'
                  rx='3'
                  stroke={COLORS.BLUE}
                  width='21'
                  x='.5'
                  y='.5'
                />
              )}

              {renderIcon(icon, iconColor && iconColor)}
            </svg>
          )}

          {label && <span>{label}</span>}
        </label>
        {isHovered && hoverText && <span>{hoverText}</span>}
      </>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
