import { observable, computed, action } from 'mobx';

import ShippingAdviceType from '../types/shippingAdvice';
import FinalizeableResource from './FinalizableResource';
import Routes from 'routes';

class ShippingAdvice extends FinalizeableResource<ShippingAdviceType> {
  @observable finalized: boolean;
  @observable container_number?: string;
  @observable etd?: string;
  @observable eta?: string;

  @action protected updateAttributes({
    id,
    finalized,
    container_number,
    etd,
    eta,
  }: ShippingAdviceType) {
    this.finalized = finalized;

    this.id = id;
    this.container_number = container_number;
    this.etd = etd;
    this.eta = eta;
  }

  @computed get isFinalizable() {
    return (
      this.dependenciesFinalized &&
      this.hasValidAttributeValue('container_number', 'etd', 'eta')
    );
  }

  @computed get downloadUrl() {
    return this.id ? Routes.download_api_v1_shipping_advice_path(this.id) : undefined;
  }
}

export default ShippingAdvice;
