import React, { useCallback, useRef } from 'react';
import StateManagedSelect, {
  components,
  GroupBase,
  OptionProps,
  Props,
  SingleValueProps,
} from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';

export type SingleOption = {
  icon: string;
  label: string;
  selected: boolean;
  type: string;
  value: number;
};

type CustomSelectType = Select<SingleOption, false, GroupBase<SingleOption>>;

interface GroupedSearchProps extends Props<SingleOption, false> {
  name?: string;
  selectOption?: SingleOption;
  placeholder: string;
}

const GroupedSearch = ({
  name,
  selectOption,
  options,
  onChange,
  placeholder,
}: GroupedSearchProps) => {
  const selectRef = useRef<CustomSelectType>(null);

  const customStyles = {
    control: (provided, _state) => ({
      ...provided,
      border: 'none',
      height: '100%',
    }),

    input: (provided, _state) => ({
      ...provided,
      boxShadow: '0',
      maxHeight: '100%',
    }),

    valueContainer: (provided, _state) => ({
      ...provided,
    }),

    singleValue: (provided, _state) => ({
      ...provided,
      maxWidth: 'calc(100% - 2.5em)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),

    option: (provided, _) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      scrollMarginTop: '2em',
    }),

    menu: (provided, _state) => ({
      ...provided,
      left: 0,
    }),
  };

  const { Option, SingleValue, Input } = components;

  const CustomSelectOption = (props: OptionProps<SingleOption, false>) => (
    <Option {...props} isSelected={props.data.selected}>
      {props.data.icon && (
        <span style={{ paddingRight: '0.75em' }}>{props.data.icon}</span>
      )}
      {props.data.label}
    </Option>
  );
  const CustomInput = (props: any) => (
    <Input {...props} inputClassName='react-select__input' />
  );

  const CustomSelectValue = ({ ...props }: SingleValueProps<SingleOption, false>) => (
    <SingleValue {...props}>
      {props.data.icon && (
        <span style={{ paddingRight: '0.75em' }}>{props.data.icon}</span>
      )}
      {props.data.label}
    </SingleValue>
  );

  const onMenuOpen = useCallback(() => {
    if (selectRef && selectRef.current) {
      const select = selectRef?.current;
      const option = select.state.selectValue?.[0];
      if (option) {
        requestAnimationFrame(() => {
          if (select.menuListRef?.parentElement) {
            select?.menuListRef?.parentElement
              .querySelectorAll("[id*='react-select-2-option']")
              .forEach((item: Element) => {
                if ((item as HTMLOptionElement).innerText.includes(option.label)) {
                  item.scrollIntoView();
                }
              });
          }
        });
      }
    }
  }, [selectRef.current]);

  return (
    <StateManagedSelect
      ref={selectRef}
      onMenuOpen={onMenuOpen}
      name={name}
      styles={customStyles}
      className='select react-select'
      defaultValue={selectOption}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      components={{
        Option: CustomSelectOption,
        SingleValue: CustomSelectValue,
        Input: CustomInput,
      }}
    />
  );
};

export default GroupedSearch;
