import Icon, { IconProps } from 'components/elements/Icon';
import React from 'react';

export type IconType =
  | 'CHECKMARK'
  | 'YELLOW_RING'
  | 'ORANGE_RING'
  | 'GREY_CLOCK'
  | 'RED_CROSS';
interface ExportIconProps extends Omit<IconProps, 'icon' | 'color'> {
  type: IconType;
}

function ExportIcon({ type, ...rest }: ExportIconProps) {
  switch (type) {
    case 'CHECKMARK':
      return <Icon icon={'check'} color='#6ea340' {...rest} />;
    case 'YELLOW_RING':
      return <Icon icon={'ring'} color='#EFD449' {...rest} />;
    case 'ORANGE_RING':
      return <Icon icon={'ring-filled'} color='#ec991e' {...rest} />;
    case 'GREY_CLOCK':
      return <Icon icon={'hourglass'} color='#a4a9ad' {...rest} />;
    case 'RED_CROSS':
      return <Icon icon={'cross'} color='#B12A31' {...rest} />;
  }
  return <Icon icon={'hourglass'} />;
  // throw new Error("UNKNOWN ICON");
}

export default ExportIcon;
