import React from 'react';

import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

import type UnloadableLostCoffeeType from 'transports/model/UnloadableLostCoffee';
import { observer } from 'mobx-react';

export interface UnloadableLostCoffeeProps {
  transportable: UnloadableLostCoffeeType;
}

function UnloadableLostCoffee({
  transportable: {
    localIdentifier,
    quality,
    grade,
    bags,
    weight,
    isUnloaded,
    setUnloaded,
    storage: { area, bin, level, position },
  },
}: UnloadableLostCoffeeProps) {
  const handleOnClick = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setUnloaded(!isUnloaded);
  };

  return (
    <tr key={localIdentifier}>
      <td className='table-border align-left lot--id'>{localIdentifier}</td>
      <td className='table-border align-center'>
        <TextWithSub text={quality} sub={grade} />
      </td>
      <td className='table-border align-center'>{bags}</td>
      <td className='table-border align-center'>{weight} kg</td>
      <td className='table-border transport-unload--tags' />
      <td className='table-border transport-unload--additions align-center'>
        <div className='l-distribute-l-r'>
          <TextWithSub text='Area' sub={area} />
          <TextWithSub text='Bin' sub={bin} />
          <TextWithSub text='Level' sub={level} />
          <TextWithSub text='Position' sub={position} />
        </div>
      </td>
      <td>
        <button
          type='button'
          data-cy='overlay_primary_button'
          onClick={handleOnClick}
          className='button button--block button--small'
        >
          {I18n.translate(`unload_lot.${isUnloaded ? 'undo' : 'unload'}`)}
        </button>
      </td>
    </tr>
  );
}

export default observer(UnloadableLostCoffee);
