import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import BaseGroupComponent from 'components/groups/BaseGroup';
import Checkbox from 'shared/Checkbox';

import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import Input from 'components/elements/Input';
import { VARIANT } from 'constants/inputTypes';

import AvailableExcessOrOrphanItemModel from 'availableExcessOrOrphanCoffee/models/AvailableExcessOrOrphanItemModel';
import placeholder from 'utils/placeholder';

export const LotCol = styled.td`
  min-width: 13em;
  max-width: 13em;
`;

const LocationColumn = styled.td`
  min-width: 15em;
`;

const InputCol = styled.td`
  width: 11em;

  .input-group--inline,
  .input-group__row {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input[type='text'] {
    width: 5em;
  }
`;

const calculateMaxAttributeValue = (item, openWeight) => {
  let availableWeightAsNumber = item.attributes.available_weight;

  if (typeof item.attributes.available_weight === 'string') {
    availableWeightAsNumber = parseFloat(item.attributes.available_weight).toFixed(2);
  }

  return Math.min(availableWeightAsNumber, openWeight + parseFloat(item.quantity));
};

interface AvailableExcessOrOrphanItem {
  item: AvailableExcessOrOrphanItemModel;
  openWeight: number;
}

const AvailableExcessOrOrphanCoffeeRowComponent = ({
  item,
  openWeight,
}: AvailableExcessOrOrphanItem) => {
  return (
    <tr>
      <LotCol className='align-left'>
        <TextWithSub
          width='11em'
          text={item.attributes.identifier}
          textClass='lot--id'
          sub={placeholder(item.attributes.mark)}
        />

        {item.attributes.alternative_ico_identifier ? (
          <>
            <small className='text-with-sub' style={{ width: '100%', marginTop: '6px' }}>
              {item.attributes.ico_identifier} /
            </small>
            <small className='text-with-sub' style={{ width: '100%' }}>
              {item.attributes.alternative_ico_identifier}
            </small>
          </>
        ) : (
          <small className='text-with-sub' style={{ width: '100%', marginTop: '6px' }}>
            {item.attributes.ico_identifier}
          </small>
        )}
      </LotCol>

      <LocationColumn className='align-center'>
        <div className='l-distribute-l-r'>
          <TextWithSub
            text={I18n.translate('milling_orders.finalise_milling.area')}
            sub={placeholder(item.attributes.location.area)}
          />
          <TextWithSub
            text={I18n.translate('milling_orders.finalise_milling.bin')}
            sub={placeholder(item.attributes.location.bin)}
          />
          <TextWithSub
            text={I18n.translate('milling_orders.finalise_milling.level')}
            sub={placeholder(item.attributes.location.level)}
          />
          <TextWithSub
            text={I18n.translate('milling_orders.finalise_milling.position')}
            sub={placeholder(item.attributes.location.position)}
          />
        </div>{' '}
      </LocationColumn>

      <td className='align-center'>
        <TextWithSub text={item.attributes.quality} sub={item.attributes.grade} />
      </td>

      <td className='align-right'>{item.quantity} kg</td>

      <InputCol className='align-center'>
        <InputWrapper>
          <BaseGroupComponent append='kg' inline={true}>
            <Input
              variant={VARIANT.DECIMAL}
              condensed={true}
              value={item.quantity}
              readOnly={!item.selected}
              step={0.01}
              max={calculateMaxAttributeValue(item, openWeight)}
              validator={(e) =>
                parseFloat(e.currentTarget.value) <= item.attributes.available_weight
              }
              onChange={(e) => item.setQuantity(e.currentTarget.value)}
            />
          </BaseGroupComponent>
          <Checkbox
            checked={item.selected}
            onChange={() => item.setSelected(!item.selected)}
            dataCy='use_excess_coffee_checkbox'
          />
        </InputWrapper>
      </InputCol>
    </tr>
  );
};

export default observer(AvailableExcessOrOrphanCoffeeRowComponent);
