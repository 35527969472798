import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import InputGroupComponent from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';
import Checkbox from 'shared/Checkbox';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import useStores from 'utils/useStores';
import ConsolidateSampleSelectable from '../models/ConsolidateSampleSelectable';

const TypeCol = styled.td`
  width: 15%;
`;

const UnitCol = styled.td`
  width: 8%;
`;

const CertificationCol = styled.td`
  width: 17%;
`;

const ProcessCol = styled.td`
  width: 18%;
`;

const InputCol = styled.td`
  width: 20%;

  .input-group--inline,
  .input-group__row {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input[type='text'] {
    width: 5em;
  }
`;

interface PaginatedSampleRowProps {
  sample: ConsolidateSampleSelectable;
  readOnly?: boolean;
}

const PaginatedSampleRow = ({
  sample,
  sample: {
    selected,
    attributes: {
      sampleSize,
      base_weight,
      sample_type,
      created_at,
      identifier,
      parent_frontend_identifier,
      grade,
      quality,
      source_current_weight_string,
      source_initial_weight_string,
      process_type,
      certification,
    },
  },
  readOnly,
}: PaginatedSampleRowProps) => {
  const { consolidateSamplesStore } = useStores();

  const [sampleSizeValue, setSampleSizeValue] = useState(sampleSize);

  useEffect(() => {
    setSampleSizeValue(sampleSize);
  }, [sampleSize]);

  const handleSampleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      e.currentTarget.value.includes('.') || e.currentTarget.value.includes(',')
        ? ''
        : e.currentTarget.value;
    consolidateSamplesStore.setSampleSize(sample, value);
  };

  const leftOverWeight = parseInt(base_weight.weight_g, 10);
  return (
    <tr data-cy={`sample_row_${sample_type}`}>
      <TypeCol>
        <TextWithSub
          text={I18n.translate(
            `activerecord.attributes.sample.sample_type.${sample_type}`
          )}
          sub={I18n.l('time.formats.short', created_at.toString())}
        />
      </TypeCol>

      <td className='table-border padding-right lot--id'>
        <TextWithSub
          text={identifier}
          textClass='lot--id'
          sub={parent_frontend_identifier ? parent_frontend_identifier : '-'}
          subClass='lot--id'
        />
      </td>

      <UnitCol className='table-border align-center'>
        <TextWithSub text={quality || '-'} sub={grade?.grade || '-'} />
      </UnitCol>

      <UnitCol className='table-border align-center'>
        <TextWithSub
          text={leftOverWeight + 'g'}
          sub={parseInt(base_weight.initial_weight, 10) + 'g'}
        />
      </UnitCol>

      <UnitCol className='table-border align-center'>
        <TextWithSub
          text={source_current_weight_string}
          sub={source_initial_weight_string}
        />
      </UnitCol>

      <CertificationCol className='table-border align-center'>
        {certification}
      </CertificationCol>

      <ProcessCol className='table-border align-center'>{process_type}</ProcessCol>

      <InputCol className='align-center'>
        <InputWrapper>
          <InputGroupComponent
            append='gr'
            inline={true}
            variant={VARIANT.INTEGER}
            disabled={!selected}
            condensed={true}
            value={sampleSizeValue || ''}
            readOnly={readOnly}
            min={1}
            max={leftOverWeight}
            onChange={handleSampleSizeChange}
            externalValid={!selected || (!!sampleSize && sampleSize !== '')}
            dataCy='add_to_mix_weight'
          />

          <Checkbox
            checked={selected}
            onChange={() => consolidateSamplesStore.toggleSampleSelection(sample)}
            dataCy='add_to_mix_checkbox'
          />
        </InputWrapper>
      </InputCol>
    </tr>
  );
};

export default observer(PaginatedSampleRow);
