import SelectGroup from 'components/groups/SelectGroup';
import React, { useEffect, useState } from 'react';
import { ControlledTextInput } from './ControlledTextInput';
import { IFieldInput } from './IFieldInput';

export const ControlledSelectGroup = ({
  field,
  value,
  onChange,
  namespace,
  overrideOptions,
  overrideName,
  multiple,
  ...rest
}: IFieldInput) => {
  if (field == undefined || !field.visible || field.options == undefined) {
    return null;
  }
  const options = overrideOptions || field.options;
  const isControlled = !!onChange;
  const [internalValue, setInternalValue] = useState(value || field.value);

  if (!value && !!field.value) {
    value = field.value;
  }

  // Select first option if only one option left
  useEffect(() => {
    if (!value && options.length == 1) {
      const newValue = options[0].value || options[0].id.toString();
      setInternalValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  }, [options]);
  const fieldNameWithoutId = field.name.replace('_id', '');

  if (field.readonly && !multiple) {
    const textValue = field.options.find((options) => options.id == value);
    return (
      <ControlledTextInput
        namespace={namespace}
        field={field}
        value={(textValue && textValue.name) || ''}
        onChange={onChange}
      />
    );
  }
  // Poor mans I18n Pluralization
  const namespace_plural =
    namespace.slice(-1) == 'y' ? namespace.slice(0, -1) + 'ies' : namespace + 's';

  const onChangeMiddleware = (e) => {
    if (!isControlled) {
      setInternalValue(e.target.value);
      return;
    }
    if (multiple == true && onChange) {
      onChange(Array.from(e.target.selectedOptions).map((option: any) => option.value));
    } else if (onChange) {
      onChange(e.target.value);
    }
  };
  let label = I18n.translate(`${namespace_plural}.form.${fieldNameWithoutId}`);
  if (field?.required) {
    label = label + '*';
  }
  return (
    <SelectGroup
      label={label}
      placeholder={I18n.translate(
        `${namespace_plural}.form.select_${fieldNameWithoutId}`
      )}
      name={overrideName || `${namespace}[${field.name}]`}
      options={options}
      disabled={!field?.enabled}
      onChange={(e) => onChangeMiddleware(e)}
      errors={field?.errors}
      data-cy={field.name}
      value={isControlled ? value : internalValue}
      id={`${namespace}_${field.name}`}
      multiple={multiple}
      {...rest}
    />
  );
};
