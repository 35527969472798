import React from 'react';
import Routes from 'routes';

import DecafOrder from 'decaf/models/DecafOrder';
import HumidityAnalysis from 'shared/HumidityAnalysis';
import useStores from 'utils/useStores';
import PhysicalAnalysis from './PhysicalAnalysis';
import Roasting from 'shared/Roasting';
import SensorialAnalysis from './SensorialAnalysis';
import CloseDecafOrderButton from './CloseDecafOrderButton';
import { customFetch } from 'utils/fetch';

export interface DecafOrderRowProps {
  decafOrder: DecafOrder;
}

function DecafOrderRow({ decafOrder }: DecafOrderRowProps) {
  const { qualityAssuranceStore } = useStores();
  const { ico, sample, id } = decafOrder;
  const {
    current_humidity_analysis,
    current_qa_physical_analysis,
    current_roasting,
    current_sensorial_analysis,
  } = sample;

  const handleOnHumidityAnalysisPrimaryClick = (_, values) => {
    current_humidity_analysis.synchronize(values);
  };

  const handleOnRoastingPrimaryClick = (event: React.MouseEvent, values: {}) => {
    current_roasting.synchronize(values);
  };

  const handleOnCloseDecafOrderClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ): Promise<unknown> =>
    customFetch(Routes.close_api_v1_decaf_decaf_order_path(id)).then(() => {
      window.location.href = Routes.decaf_quality_assurance_index_path();
    });

  return (
    <tr>
      <td className='table-border'>{ico.main_identifier}</td>

      <td className='align-center table-border'>
        <HumidityAnalysis
          identifier={ico.main_identifier}
          onPrimaryClick={handleOnHumidityAnalysisPrimaryClick}
          humidityAnalysis={current_humidity_analysis}
          lotFailed={false}
          validations={current_humidity_analysis.validations}
          text={current_humidity_analysis.text}
          status={current_humidity_analysis.status}
          icon={current_humidity_analysis.icon}
          reasons={qualityAssuranceStore.humidityAcceptingReasons}
          showConfirmation={true}
        />
      </td>

      <td className='align-center table-border'>
        <PhysicalAnalysis
          physicalAnalysis={current_qa_physical_analysis}
          newUrl={Routes.new_decaf_decaf_order_physical_analysis_path(id)}
          editUrl={Routes.edit_decaf_decaf_order_physical_analysis_path(id)}
        />
      </td>

      <td className='align-center table-border'>
        <Roasting
          identifier={ico.main_identifier}
          results={qualityAssuranceStore.roastingResults}
          roasting={current_roasting}
          roastingSampleWeight={qualityAssuranceStore.roastingSampleWeight || 110}
          text={I18n.translate(
            `widgets.lot_status_table.${current_roasting.passed ? 'done' : 'failed'}`
          )}
          status={current_roasting.passed}
          icon={current_roasting.passed ? 'passed' : 'failed'}
          onPrimaryClick={handleOnRoastingPrimaryClick}
          showReroast={false}
          showEnterRoasting={!current_roasting.id}
          validations={qualityAssuranceStore.roastingValidations}
        />
      </td>

      <td className='align-center table-border'>
        <SensorialAnalysis
          sample={sample}
          sensorialAnalysis={current_sensorial_analysis}
        />
      </td>

      <td className='align-center table-border'>{`${ico.weight.value} ${ico.weight.unit}`}</td>

      <td className='align-center'>
        {(sample.failed || sample.passed) && (
          <CloseDecafOrderButton
            passed={sample.passed}
            onClick={handleOnCloseDecafOrderClick}
          />
        )}
      </td>
    </tr>
  );
}

export default DecafOrderRow;
