import React from 'react';

import SpotContract from 'traceability/types/SpotContract';
import SpotContractItem from './SpotContractItem';

interface SpotContractListProps {
  spotContracts: SpotContract[];
}

function SpotContractList({ spotContracts, ...props }: SpotContractListProps) {
  return (
    <div {...props}>
      {spotContracts.map((spotContract) => (
        <SpotContractItem key={spotContract.identifier} spotContract={spotContract} />
      ))}
    </div>
  );
}

export default SpotContractList;
