import React from 'react';

import DeliveryOrderTable from 'fulfillment/components/index/DeliveryOrderTable';
import { Provider } from 'mobx-react';
import DeliveryOrderIndexStore from 'fulfillment/stores/DeliveryOrderIndexStore';
import { JsonApi } from 'types/utils/jsonApi';
import { DeliveryOrder } from 'fulfillment/types/types';

interface DeliveryOrderIndexContainerProps {
  deliveryOrders: JsonApi<DeliveryOrder>;
}

function DeliveryOrderIndexContainer({
  deliveryOrders,
}: DeliveryOrderIndexContainerProps) {
  return (
    <Provider deliveryOrderIndexStore={new DeliveryOrderIndexStore(deliveryOrders)}>
      <DeliveryOrderTable />
    </Provider>
  );
}

export default DeliveryOrderIndexContainer;
