import styled from 'styled-components';

import COLORS from 'constants/colors';

export const TestGridHeader = styled.div`
  height: 3em;
  text-align: center;
  align-items: baseline;
  line-height: 3.5em;
  color: ${COLORS.SUB_TEXT};
`;

export default TestGridHeader;
