import React from 'react';
import { observer } from 'mobx-react';

import useStores from 'utils/useStores';

import LogEntry from 'millings/productionLog/models/LogEntry';

interface ProductionMachineProps {
  entry: LogEntry;
}

const ProductionLogMachine = ({
  entry: {
    setMachine,
    attributes: { machine, start_date },
  },
}: ProductionMachineProps) => {
  const { productionLogStore } = useStores();

  if (machine && start_date) {
    return <span>{I18n.translate(`milling.machines.${machine}`)}</span>;
  }

  return (
    <select
      value={machine || ''}
      className='input--condensed'
      onChange={(e) => setMachine(e.target.value)}
    >
      <option>{I18n.translate('milling.machines.placeholder')}</option>
      {productionLogStore.machines.map((machine) => (
        <option key={machine} value={machine}>
          {I18n.translate(`milling.machines.${machine}`)}
        </option>
      ))}
    </select>
  );
};

export default observer(ProductionLogMachine);
