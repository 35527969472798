import React, { useState, useEffect } from 'react';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';

import Overlay from 'shared/Overlay';
import FinalizeMillingRowComponent from './FinalizeMillingRowComponent';
import { addTestingIdentifier } from 'utils/testing';

export interface FinalizeMillingOrderOverlayProps {
  id: string;
  totalWeight: number;
  weightTollerance: number;
  identifier: string;
}

type Result = {
  location: boolean;
  readOnly: boolean;
};

const RESULT_TYPES: {
  [key: string]: Result;
} = {
  caracol: { location: true, readOnly: false },
  machine_pasilla: { location: true, readOnly: false },
  electronic_pasilla: { location: true, readOnly: false },
  ripio: { location: false, readOnly: false },
  cisco: { location: false, readOnly: false },
  excelso: { location: true, readOnly: true },
  excess_excelso: { location: true, readOnly: true },
  fraction_rejections: { location: false, readOnly: true },
  reprocessing_loss: { location: false, readOnly: true },
};

export interface CompletedTypes {
  caracol: null | MillingTypeResult;
  machine_pasilla: null | MillingTypeResult;
  electronic_pasilla: null | MillingTypeResult;
  ripio: null | MillingTypeResult;
  cisco: null | MillingTypeResult;
  excelso: null | MillingTypeResult;
  excess_excelso: null | MillingTypeResult;
  fraction_rejections: null | MillingTypeResult;
  reprocessing_loss: null | MillingTypeResult;
}

export interface MillingTypeResult {
  area?: string;
  bin?: string;
  level?: string;
  position?: string;
  quantity: string;
}

const FinalizeMillingOrderOverlay = ({
  id,
  identifier,
  totalWeight,
  weightTollerance = 0,
}: FinalizeMillingOrderOverlayProps) => {
  const [showOverlay, setOverlay] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [completedRows, setCompletedRows] = useState(0);
  const [completedWeight, setCompletedWeight] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (showOverlay) {
      customFetch(
        Routes.milling_outputs_info_api_v1_milling_order_path(id),
        undefined,
        'GET'
      ).then((result) => {
        result.milling_outputs.forEach((milling_output) => {
          setCompleted((prevComplete) => {
            return {
              ...prevComplete,
              [milling_output.type]: milling_output.values,
            };
          });
        });
        setLoading(false);
      });
    }
  }, [showOverlay]);

  const [completed, setCompleted] = useState<CompletedTypes>({
    caracol: null,
    machine_pasilla: null,
    electronic_pasilla: null,
    ripio: null,
    cisco: null,
    excelso: null,
    excess_excelso: null,
    fraction_rejections: null,
    reprocessing_loss: null,
  });

  const handleFinalizeClick = () => {
    setOverlay(true);
  };

  const handleOnPrimaryClick = () => {
    setSubmitted(true);
    customFetch(Routes.finalise_api_v1_milling_order_path(id), {
      milling_outputs: completed,
    }).then(() => {
      window.location.reload();
    });
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  const handleOnComplete = (
    type: keyof CompletedTypes,
    values: MillingTypeResult | null
  ) => {
    setCompleted((prevComplete) => {
      return {
        ...prevComplete,
        [type]: values,
      };
    });
  };

  // @TODO: there must be a better way to check top completed values
  useEffect(() => {
    if (showOverlay) {
      let totalWeight = 0;
      let totalRows = 0;
      Object.keys(completed).forEach((item) => {
        totalWeight += parseFloat(completed[item]?.quantity || 0);
        completed[item] !== null ? totalRows++ : false;
      });
      setCompletedWeight(totalWeight);
      setCompletedRows(totalRows);
    }
  }, [completed]);

  const submitButtonDisabled =
    completedRows < Object.keys(completed).length ||
    Math.round(Math.abs(totalWeight - completedWeight) * 100) / 100 > weightTollerance ||
    submitted;

  return (
    <>
      {showOverlay && (
        <Overlay
          title={I18n.translate('milling_orders.finalise_milling.title')}
          titlePrefix={identifier}
          primaryDisabled={submitButtonDisabled}
          primaryText={I18n.translate('milling_orders.finalise_milling.title')}
          onPrimaryClick={handleOnPrimaryClick}
          secondaryText={I18n.translate('buttons.cancel')}
          onSecondaryClick={handleOnSecondaryClick}
        >
          {loading ? (
            <div>loading...</div>
          ) : (
            <table className='table table--inverted-stripes'>
              <thead>
                <tr>
                  <th className='table-border'>
                    {I18n.translate('milling_orders.finalise_milling.type')}
                  </th>
                  <th className='table-border'>
                    {I18n.translate('milling_orders.finalise_milling.area')}
                  </th>
                  <th className='table-border'>
                    {I18n.translate('milling_orders.finalise_milling.bin')}
                  </th>
                  <th className='table-border'>
                    {I18n.translate('milling_orders.finalise_milling.level')}
                  </th>
                  <th className='table-border'>
                    {I18n.translate('milling_orders.finalise_milling.position')}
                  </th>
                  <th>
                    {I18n.translate('milling_orders.finalise_milling.total_produced')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(RESULT_TYPES).map(([type, { location, readOnly }]) => (
                  <FinalizeMillingRowComponent
                    key={type}
                    type={type as keyof CompletedTypes}
                    values={completed[type] || { quantity: '0' }}
                    withLocation={location}
                    readOnly={readOnly}
                    onComplete={handleOnComplete}
                  />
                ))}

                <tr>
                  <td colSpan={5} className='table-border'>
                    {I18n.translate('milling_orders.finalise_milling.check_weight')}
                  </td>
                  <td>
                    {completedWeight.toFixed(2)} / {totalWeight} kg
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Overlay>
      )}

      <button
        onClick={handleFinalizeClick}
        type='button'
        className='button button--small button--ghost'
        {...addTestingIdentifier('finalize_milling_button')}
      >
        {I18n.translate('buttons.finalize')}
      </button>
    </>
  );
};

export default FinalizeMillingOrderOverlay;
