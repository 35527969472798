import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import AccordionWrapper, {
  AccordionHeaderProps,
  AccordionItemType,
} from 'shared/Accordion/components/AccordionWrapper';
import ShipmentSettingsModel from '../../models/ShipmentSettings';

import ShipmentInstructionsHeader from '../shipmentInstructions/ShipmentInstructionsHeader';
import ShipmentInstructions from '../shipmentInstructions/ShipmentInstructions';

import TransitGuideHeader from '../transitGuide/TransitGuideHeader';
import TransitGuide from '../transitGuide/TransitGuide';

import ResponsibilityLetterHeader from '../responsibilityLetter/ResponsibilityLetterHeader';
import ResponsibilityLetter from '../responsibilityLetter/ResponsibilityLetter';

import ImporterSecurityFillingHeader from '../importerSecurityFilling/importerSecurityFillingHeader';
import ImporterSecurityFilling from '../importerSecurityFilling/importerSecurityFilling';

import useStores from 'utils/useStores';
import Export from 'exports/models/Export';
import TransportHeader from '../transport/TransportHeader';
import Transport from '../transport/Transport';
import IcoList from '../icoList/IcoList';
import IcoListHeader from '../icoList/IcoListHeader';
import ExportDocumentType from 'exports/types/ExportDocumentType';
import filterRequiredOnly from 'exports/utils/filterRequiredOnly';
import ShipmentExportData from '../ShipmentExportData';

export interface ShipmentSettingsProps {
  export_: Export;
  shipmentSettings: ShipmentSettingsModel;
}

function ShipmentSettings({
  export_,
  shipmentSettings: {
    shipmentInstruction,
    transitGuide,
    responsibilityLetter,
    importerSecurityFilling,
    transport,
    icoList,
  },
}: ShipmentSettingsProps) {
  const {
    exportsStore: {
      customsOfficers,
      consignees,
      transportCompanies,
      shippingModes,
      shippingLines,
      ports,
      lotTypes,
    },
  } = useStores();
  const { requiredDocuments, shippingPortId, icos } = export_;

  const documents: ExportDocumentType = new Map([
    [
      'shipment_instruction',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <ShipmentInstructionsHeader
            completed={shipmentInstruction?.finalized || false}
            expanded={expanded}
          />
        )),
        body: observer((_props: AccordionHeaderProps) => (
          <ShipmentInstructions shipmentInstruction={shipmentInstruction} />
        )),
        expandable: true,
      },
    ],
    [
      'transit_guide',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <TransitGuideHeader
            completed={transitGuide?.finalized || false}
            expanded={expanded}
          />
        )),
        body: observer((_props: AccordionHeaderProps) => {
          const customsOfficer = customsOfficers.find(
            ({ id }) => id === shipmentInstruction.customs_officer_id
          );

          const consignee = consignees.find(
            ({ id }) => id === shipmentInstruction.consignee_id
          );

          const shippingPort = ports.find(({ id }) => id === shippingPortId);

          return (
            <TransitGuide
              transitGuide={transitGuide}
              // @ts-ignore: Wrongly typed Options
              customsOfficer={customsOfficer}
              // @ts-ignore: Wrongly typed Options
              consignee={consignee}
              originCountryId={shippingPort?.country_id}
            />
          );
        }),
        expandable: shipmentInstruction.isValid,
      },
    ],
    [
      'responsibility_letter',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <ResponsibilityLetterHeader
            completed={responsibilityLetter?.finalized || false}
            expanded={expanded}
          />
        )),
        body: observer((_props: AccordionHeaderProps) => {
          const transportCompany = transportCompanies.find(
            ({ id }) => id === transitGuide.transport_company_id
          );

          return (
            <ResponsibilityLetter
              responsibilityLetter={responsibilityLetter}
              vesselName={shipmentInstruction.vessel_name}
              voyageNumber={shipmentInstruction.voyage_number}
              transportCompany={transportCompany?.name || ''}
            />
          );
        }),
        expandable: transitGuide.finalized,
      },
    ],
    [
      'importer_security_filling',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <ImporterSecurityFillingHeader
            completed={importerSecurityFilling.finalized}
            expanded={expanded}
          />
        )),
        body: observer((_props: AccordionHeaderProps) => {
          const containerMovement =
            shippingModes.find(({ id }) => id === shipmentInstruction.shipping_mode_id)
              ?.name || '';

          const carrierName =
            shippingLines.find(({ id }) => id === shipmentInstruction.shipping_line_id)
              ?.name || '';

          const billOfLading = shipmentInstruction.bill_of_lading || '';

          return (
            <ImporterSecurityFilling
              importerSecurityFilling={importerSecurityFilling}
              containerMovement={containerMovement}
              carrierName={carrierName}
              billOfLading={billOfLading}
              destinationPortId={export_.destinationPortId}
            />
          );
        }),
        expandable: shipmentInstruction.finalized,
      },
    ],
  ]);

  // @ts-ignore: Wrongly typed Options
  const shipmentSettingsAccordions: AccordionItemType[] = useMemo(
    () => filterRequiredOnly(documents, requiredDocuments),
    [
      shipmentInstruction.isValid,
      shipmentInstruction.isFinalizable,
      shipmentInstruction.finalized,
      transitGuide.finalized,
      responsibilityLetter.finalized,
      importerSecurityFilling.finalized,
    ]
  );

  const transportAccordion: ExportDocumentType = new Map([
    [
      'transport',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <TransportHeader completed={transport?.finalized} expanded={expanded} />
        )),
        body: observer((_props: AccordionHeaderProps) => <Transport />),
        expandable: export_.canPrepareTransport,
      },
    ],
  ]);

  // @ts-ignore: Wrongly typed Options
  const transportAccordions: AccordionItemType[] = useMemo(
    () => filterRequiredOnly(transportAccordion, requiredDocuments),
    [
      shipmentInstruction.finalized,
      transitGuide.finalized,
      responsibilityLetter.finalized,
      importerSecurityFilling.finalized,
    ]
  );

  const icoListAccordion: ExportDocumentType = new Map([
    [
      'ico_list',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <IcoListHeader completed={icoList.finalized} expanded={expanded} />
        )),
        body: observer((_props: AccordionHeaderProps) => (
          <IcoList icoList={icoList} icos={icos} lotTypes={lotTypes} />
        )),
        expandable: true,
      },
    ],
  ]);

  // @ts-ignore: Wrongly typed Options
  const thirdSectionAccordions: AccordionItemType[] = useMemo(
    () => filterRequiredOnly(icoListAccordion, requiredDocuments),
    [icoList.finalized]
  );

  return (
    <>
      <ShipmentExportData
        export_={export_}
        billOfLadingDisabled={!export_.blCanBeEdited}
        onSaveBillOfLanding={export_.updateExportBillOfLading}
      />
      <AccordionWrapper accordions={shipmentSettingsAccordions} />
      <AccordionWrapper accordions={transportAccordions} />
      <AccordionWrapper accordions={thirdSectionAccordions} />
    </>
  );
}

export default observer(ShipmentSettings);
