import { deserialise } from 'kitsu-core';
import { action, computed, observable } from 'mobx';

import ProductionLog from 'types/model/production_log';

import LogEntry from 'millings/productionLog/models/LogEntry';

interface Filter {
  closed?: boolean;
  inProgress?: boolean;
}

export default class ProductionLogStore {
  private millingOrderId: number;
  public productionLogs = observable.array<LogEntry>();
  public newEntries = observable.array<LogEntry>();
  public identifier: string;
  public fractions: Array<number>[];
  @observable public saving = false;
  @observable public filter: Filter = { inProgress: true };

  constructor(
    millingOrderId: number,
    productionLogs: ProductionLog[],
    identifier: string,
    fractions: Array<number>[],
    public currentOperator: string,
    public machines: string[]
  ) {
    const modelEntries = deserialise(productionLogs).data.map(
      (e) => new LogEntry(this.millingOrderId, e)
    );
    this.productionLogs = observable.array(modelEntries);

    this.millingOrderId = millingOrderId;
    this.identifier = identifier;
    this.fractions = fractions;
  }

  @computed get currentEntries(): LogEntry[] {
    return this.productionLogs.filter(
      (entry) =>
        (entry.attributes.open && this.filter.inProgress) ||
        (!entry.attributes.open && this.filter.closed)
    );
  }

  @action public createNewEntry = () => {
    this.newEntries.push(
      new LogEntry(this.millingOrderId, {
        editable: true,
        open: true,
        operator_name: this.currentOperator,
      })
    );
  };

  @action public toggleFilter = (filter: string) => {
    this.filter[filter] = !this.filter[filter];
  };
}
