import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface TileComponentProps {
  disabled?: boolean;
  noMargin?: boolean;
}

const TileComponent = styled.div<TileComponentProps>`
  margin-bottom: ${(props) => (props.noMargin ? '0' : '2em')};
  position: relative;

  opacity: ${(props) => (props.disabled ? '0.5' : 1)};
`;

const CollapsibleIndicator = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #dbd9d6;
  background-color: #fff;
  min-height: 3.75em;
  flex-direction: column;
`;

const CollapsibleIndicatorOne = styled(CollapsibleIndicator)`
  position: absolute;
  bottom: -0.75em;
  left: 0.625em;
  right: 0.625em;
`;

const CollapsibleIndicatorTwo = styled(CollapsibleIndicator)`
  position: absolute;
  bottom: -0.375em;
  left: 0.3125em;
  right: 0.3125em;
`;

const CollapsibleIndicatorComponent = () => (
  <>
    <CollapsibleIndicatorOne />
    <CollapsibleIndicatorTwo />
  </>
);

interface TileProps {
  collapsibleIndicator?: boolean;
  children: ReactNode;
  disabled?: boolean;
  noMargin?: boolean;
}

const Tile: React.FC<TileProps> = ({
  collapsibleIndicator,
  children,
  disabled,
  noMargin,
}) => {
  return (
    <TileComponent disabled={disabled} noMargin={noMargin}>
      {collapsibleIndicator && <CollapsibleIndicatorComponent />}
      <CollapsibleIndicator>{children}</CollapsibleIndicator>
    </TileComponent>
  );
};

export default Tile;
