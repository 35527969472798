import IcoType from 'customerSamples/types/IcoType';
import Sample from 'customerSamples/models/Sample';
import InternalContractSample from './InternalContractSample';
import InternalContractIcoType from 'customerSamples/types/InternalContractIcoType';
import CustomerSampleType from 'customerSamples/types/CustomerSampleType';
import InternalContractCustomerSampleType from 'customerSamples/types/InternalContractCustomerSampleType';

export class Ico {
  public samples?: Array<InternalContractSample | Sample>;
  public id: number;
  public mainIdentifier: string;

  constructor(ico: IcoType | InternalContractIcoType) {
    this.id = ico.id;
    this.mainIdentifier = ico.main_identifier;

    const samples = ico.samples?.map(
      (sample: CustomerSampleType | InternalContractCustomerSampleType) => {
        if (sample.type === 'internal_contract_sample') {
          return new InternalContractSample(sample);
        } else {
          return new Sample(sample);
        }
      }
    );
    this.samples = samples;
  }
}

export default Ico;
