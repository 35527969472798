import React from 'react';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import placeholder from 'utils/placeholder';
import { addTestingIdentifier } from 'utils/testing';
import WSRSelectionCheckbox from '../warehouse_sample_request/WSRSelectionCheckbox';
import { ISelectableArrivalSampleIco } from './WSRIcosTable';

interface WSRIcosTableRowProps {
  onSelect: () => any;
  onDeselect: () => any;
  ico: ISelectableArrivalSampleIco;
}

function WSRIcosTableRow({ ico, onSelect, onDeselect }: WSRIcosTableRowProps) {
  return (
    <tr {...addTestingIdentifier(`wsr_ico`)}>
      <td className='table-border'>
        <TextWithSub
          width={'11em'}
          text={placeholder(ico.label)}
          textClass='text-with-sub__text'
          sub={placeholder(ico.contract?.main_identifier)}
        />
      </td>

      <td className='table-border'>
        <TextWithSub
          width={'11em'}
          text={placeholder(ico.quality?.code)}
          textClass='text-with-sub__text'
          maxTextLength={25}
          sub={placeholder(ico.mark?.name)}
        />
      </td>
      <td className='table-border'>
        <TextWithSub
          width={'11em'}
          text={placeholder(ico.contract?.customer?.name)}
          textClass='text-with-sub__text'
          maxTextLength={25}
          sub={placeholder(ico.bin)}
        />
      </td>
      <td className='table-border'>
        <TextWithSub
          width={'11em'}
          text={placeholder(ico.packaging_type?.name)}
          textClass='text-with-sub__text'
          maxTextLength={25}
          sub={placeholder(ico.units)}
        />
      </td>

      <td className='selected-lots selected-lots__checkbox align-center'>
        <WSRSelectionCheckbox
          checked={ico.selected}
          onCheck={onSelect}
          onUncheck={onDeselect}
          dataCy='wsr_ico_checkbox'
        />
      </td>
    </tr>
  );
}

export default WSRIcosTableRow;
