import React from 'react';
import Routes from 'routes';

import AdjustGrade from './AdjustGrade';

import SpaceBetween from 'components/elements/SpaceBetween';

import TestGrid, { TestGridHeader } from 'shared/TestGrid';

import AdjustWeight from 'samples/analyse_and_adjust/components/AdjustWeight';
import HumidityAnalyses from 'samples/analyse_and_adjust/components/HumidityAnalyses';
import PhysicalAnalyses from 'samples/analyse_and_adjust/components/PhysicalAnalyses';
import Roastings from 'samples/analyse_and_adjust/components/Roastings';
import SensorialAnalyses from 'samples/analyse_and_adjust/components/SensorialAnalyses';
import Can from 'components/shared/Can';
import { addTestingIdentifier } from 'utils/testing';

import ISample from '../../types/ISample';
import { Header, HeaderInfo, LotInfoBoxWrapper } from 'shared/LotInfoBox';

export interface LotInfoBoxProps {
  sample: ISample;
}

function LotInfoBox({ sample }: LotInfoBoxProps): JSX.Element {
  const {
    can_be_analysed,
    humidity_analyses,
    physical_analyses,
    roastings,
    sensorial_analyses,
    lot,
    lot_set,
    id,
  } = sample;

  const renderAdjustGradeButton = (): JSX.Element | void => {
    if (
      lot &&
      lot.grade?.toString() !== '-' &&
      sample.sample_type === 'sample_lab' &&
      sensorial_analyses.length > 0
    ) {
      return (
        <Can I='regrade' a='API::V1::Lot'>
          {lot.original_grade && (
            <button className='button button--small' disabled>
              {I18n.translate('buttons.already_regraded')}
            </button>
          )}

          {!lot.original_grade && sample.grade !== lot.grade && (
            <AdjustGrade
              identifier={sample.parent_frontend_identifier}
              sampleId={sample.id}
              currentGrade={sample.grade}
            />
          )}
        </Can>
      );
    }
  };

  return (
    <LotInfoBoxWrapper>
      <Header>
        <SpaceBetween>
          <div>
            <HeaderInfo {...addTestingIdentifier('sample_identifier')}>
              {`${I18n.translate(
                `activerecord.attributes.sample.sample_type.${sample.sample_type}`
              )} ${sample.frontend_identifier}`}

              {(lot || lot_set) && (
                <>
                  <span>{I18n.translate('other.from')}</span>
                  {sample.parent_frontend_identifier}
                </>
              )}

              {sample.opportunity_main_identifier && (
                <>
                  ,
                  <span>
                    {I18n.translate(
                      'samples.analyse_and_adjust.opportunity_main_identifier'
                    )}
                    :
                  </span>{' '}
                  {sample.opportunity_main_identifier}
                </>
              )}
            </HeaderInfo>

            <a
              href={Routes.label_sample_path(sample.id)}
              target='_blank'
              rel='noreferrer'
              className='button button--small button--ghost'
              {...addTestingIdentifier('print_sample_label')}
            >
              {I18n.translate('buttons.reprint_label')}
            </a>
          </div>

          <div className='l-item-stack-horizontal'>
            {renderAdjustGradeButton()}

            <AdjustWeight
              identifier={sample.local_identifier}
              sampleId={id}
              currentWeight={sample.weight}
            />

            <HeaderInfo {...addTestingIdentifier('weight_info')}>
              {sample.weight}gr / {sample.initial_weight}gr
            </HeaderInfo>
          </div>
        </SpaceBetween>
      </Header>

      <TestGrid>
        <div>
          <TestGridHeader>{I18n.translate('lots.index.humidity')}</TestGridHeader>

          <TestGridHeader>{I18n.translate('lots.index.physical_anal')}</TestGridHeader>

          <TestGridHeader>{I18n.translate('lots.index.roasting')}</TestGridHeader>

          <TestGridHeader>
            {I18n.translate('lot_sets.index.sensorial_analysis')}
          </TestGridHeader>
        </div>

        <div>
          <HumidityAnalyses
            sample={sample}
            humidityAnalyses={humidity_analyses}
            enableNewButton={can_be_analysed}
          />

          <PhysicalAnalyses
            sample={sample}
            physicalAnalyses={physical_analyses}
            enableNewButton={can_be_analysed}
          />

          <Roastings
            sample={sample}
            roastings={roastings}
            enableNewButton={can_be_analysed}
          />

          <SensorialAnalyses sample={sample} sensorialAnalyses={sensorial_analyses} />
        </div>
      </TestGrid>
    </LotInfoBoxWrapper>
  );
}

export default LotInfoBox;
