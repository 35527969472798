import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Tile from 'shared/Tile/components/Tile';
import TileDisclosure from 'shared/Tile/components/TileDisclosure';
import TileHeader from 'shared/Tile/components/TileHeader';
import AvailableExcessOrOrphanCoffeeOverlayComponent from './AvailableExcessOrOrphanCoffeeOverlayComponent';
import useStores from 'utils/useStores';
import AvailableExcessOrOrphanCoffeeRowComponent from './AvailableExcessOrOrphanCoffeeRowComponent';
import RequestOrphanIcoComponent from './RequestOrphanIcoComponent';
import Can from 'components/shared/Can';
import { equalityFilter, likeFilter } from 'utils/filter';
import FilterContextProvider from 'utils/filterContext';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  width: 100%;
`;

const MissingWeight = styled.div`
  white-space: nowrap;
`;

const filterConfiguration = {
  identifier: {
    filter: likeFilter,
    value: undefined,
  },
  quality: {
    filter: equalityFilter,
    value: undefined,
  },
};

const AvailableExcessOrOrphanComponent: React.FC = () => {
  const { availableExcessOrOrphanCoffeeStore } = useStores();

  const [collapsed, setCollapsed] = React.useState(false);

  const handleDisclosureClick = React.useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => setCollapsed(!collapsed),
    [collapsed]
  );

  return (
    <>
      <Tile collapsibleIndicator={collapsed}>
        <>
          <TileHeader className='selected-lots__header'>
            <TileDisclosure
              disabled={availableExcessOrOrphanCoffeeStore.selectedItems.length === 0}
              handleCollapsible={handleDisclosureClick}
              collapsed={collapsed}
            />
            <MissingWeight>
              {I18n.translate('milling_orders.show.missing_weight')}
              {availableExcessOrOrphanCoffeeStore.openWeight}
              &nbsp;/&nbsp;
              {parseFloat(availableExcessOrOrphanCoffeeStore.missingWeight).toFixed(2)}
              kg
            </MissingWeight>
            <Actions>
              <Can I='request' a='OrphanIco'>
                <RequestOrphanIcoComponent
                  millingOrderId={availableExcessOrOrphanCoffeeStore.millingOrderId}
                  missingWeight={availableExcessOrOrphanCoffeeStore.missingWeight}
                  orphanStatus={availableExcessOrOrphanCoffeeStore.orphanStatus}
                />
              </Can>

              <FilterContextProvider value={filterConfiguration}>
                <AvailableExcessOrOrphanCoffeeOverlayComponent />
              </FilterContextProvider>
            </Actions>
          </TileHeader>

          {!collapsed && !!availableExcessOrOrphanCoffeeStore.selectedItems.length && (
            <table
              className={classNames(
                'table',
                'table--full-border',
                'table--inverted-stripes',
                'sets-table',
                {
                  'sets-table--collapsed': collapsed,
                }
              )}
            >
              <tbody>
                {availableExcessOrOrphanCoffeeStore.selectedItems.map((item) => (
                  <AvailableExcessOrOrphanCoffeeRowComponent
                    key={item.attributes.identifier}
                    item={item}
                    openWeight={parseFloat(availableExcessOrOrphanCoffeeStore.openWeight)}
                  />
                ))}
              </tbody>
            </table>
          )}
          {availableExcessOrOrphanCoffeeStore.selectedItems.map((item) => (
            <input
              key={item.attributes.id}
              type='hidden'
              name={`milling_output[${item.attributes.id}]`}
              value={item.quantity}
            />
          ))}
        </>
      </Tile>
    </>
  );
};

export default observer(AvailableExcessOrOrphanComponent);
