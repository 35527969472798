import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import Routes from 'routes';

import useStores from 'utils/useStores';

import COLORS from 'constants/colors';

import Tile from 'shared/Tile/components/Tile';
import TileDisclosure from 'shared/Tile/components/TileDisclosure';
import TileHeader from 'shared/Tile/components/TileHeader';

import CoffeeItemRow from './CoffeeItemRow';
import AvailableCoffeItem from 'availableCoffee/models/AvailableCoffeItem';
import AvailableMillingOutput from 'availableCoffee/models/AvailableMillingOutput';
import MillingOutputRow from './MillingOutputRow';

interface SelectedCoffeeProps {
  withButton?: boolean;
}

const Actions = styled.div<SelectedCoffeeProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.withButton
      ? '8em max-content max-content 10em 1em 12em max-content'
      : '8em 10em 10em 10em 1em 15em'};
  grid-column-gap: 1.25em;
  align-items: center;
  width: 100%;

  span:nth-child(2),
  span:nth-child(3) {
    border-right: 1px solid ${COLORS.BORDERS};
    padding: 0 1em;
    text-align: right;
  }
`;

const SelectedCoffee = ({ withButton }: SelectedCoffeeProps) => {
  const { availableCoffeeStore } = useStores();
  const { productionOrderType } = availableCoffeeStore;

  const [collapsed, setCollapsed] = React.useState(true);

  const handleDisclosureClick = React.useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => setCollapsed(!collapsed),
    [collapsed]
  );

  const handleButtonClick = () => {
    window.location.href = Routes.available_coffee_path({
      production_order: availableCoffeeStore.productionOrderId,
    });
  };

  return (
    <>
      <Tile
        collapsibleIndicator={!!availableCoffeeStore.selectedSources.length && collapsed}
      >
        <>
          <TileHeader className='selected-lots__header'>
            <TileDisclosure
              disabled={!availableCoffeeStore.selectedSources.length}
              handleCollapsible={handleDisclosureClick}
              collapsed={collapsed}
            />
            <Actions withButton={withButton}>
              <span>{I18n.translate('available_coffee.candidates')}</span>

              <span>{`${availableCoffeeStore.totalWeight} kg`}</span>

              <span>
                {productionOrderType === 'vanilla' &&
                  `${availableCoffeeStore.totalBags} ${availableCoffeeStore.bagsTitle}`}
                <br></br>
                {productionOrderType === 'vanilla' &&
                  `${availableCoffeeStore.alternativeTotalBags} ${availableCoffeeStore.alternativeBagsTitle}`}
              </span>

              <span>
                {productionOrderType === 'vanilla' &&
                  `${availableCoffeeStore.averageFactor} ${availableCoffeeStore.averageFactorInfo}`}
                <br></br>
                {productionOrderType === 'vanilla' &&
                  `${availableCoffeeStore.secondaryAverageFactor} ${availableCoffeeStore.secondaryAverageFactorInfo}`}
              </span>

              <span>≈</span>

              <span>
                {productionOrderType === 'vanilla' &&
                  `${availableCoffeeStore.estimatedGreen} ${availableCoffeeStore.estimatedGreenTitle}`}
                {productionOrderType === 'caracol' &&
                  `${availableCoffeeStore.estimatedGreen} ${I18n.translate(
                    'available_coffee.estimate'
                  )}`}

                <br></br>
                {productionOrderType === 'vanilla' &&
                  `${availableCoffeeStore.alternativeEstimatedGreen} ${availableCoffeeStore.alternativeEstimatedGreenTitle}`}
              </span>

              {withButton && (
                <div className='button button--green' onClick={handleButtonClick}>
                  {I18n.translate('available_coffee.available_button')}
                </div>
              )}
            </Actions>
          </TileHeader>

          {!collapsed && !!availableCoffeeStore.selectedSources.length && (
            <table
              className={classNames(
                'table',
                'table--full-border',
                'table--inverted-stripes',
                'sets-table',
                {
                  'sets-table--collapsed': collapsed,
                }
              )}
            >
              <tbody>
                {availableCoffeeStore.selectedSources.map((item) => {
                  if (item instanceof AvailableCoffeItem) {
                    return (
                      <CoffeeItemRow key={item.attributes.local_identifier} item={item} />
                    );
                  } else if (item instanceof AvailableMillingOutput) {
                    return (
                      <MillingOutputRow
                        key={item.attributes.local_identifier}
                        item={item}
                      />
                    );
                  }
                })}
              </tbody>
            </table>
          )}
        </>
      </Tile>
    </>
  );
};

export default observer(SelectedCoffee);
