import React from 'react';

import type QualitiesPriceModel from '../models/QualitiesPrice';
import Checkbox from 'shared/Checkbox';
import { observer } from 'mobx-react';
import InputGroupComponent from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';
import styled from 'styled-components';

export interface QualitiesPriceProps extends React.HTMLAttributes<HTMLTableRowElement> {
  qualitiesPrice: QualitiesPriceModel;
}

const TableData = styled.td`
  &:first-child,
  &:last-child {
    padding: 0;
  }
`;

function QualitiesPrice({ qualitiesPrice }: QualitiesPriceProps) {
  const { quality, cupPremium, checked, id } = qualitiesPrice;

  const handleOnCheck = (state: boolean) => qualitiesPrice.setChecked(state);

  const handleOnCupPremiumChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    qualitiesPrice.setCupPremium(event.target.value);

  const attributeString = `fixed_price_contract[fixed_price_contracts_qualities_attributes][]`;

  return (
    <tr>
      <TableData>
        <Checkbox checked={checked} onChange={handleOnCheck} dataCy='checkbox' />
      </TableData>

      <TableData>{quality.code}</TableData>

      <TableData>
        <InputGroupComponent
          value={cupPremium || ''}
          onChange={handleOnCupPremiumChange}
          variant={VARIANT.DECIMAL}
          step={0.01}
          max={9999.99}
        />

        {((id && checked) || (id && !checked)) && (
          <input type='hidden' name={`${attributeString}[id]`} defaultValue={id} />
        )}

        {id && !checked && (
          <input type='hidden' name={`${attributeString}[_destroy]`} defaultValue='1' />
        )}

        {checked && (
          <input
            type='hidden'
            name={`${attributeString}[quality_id]`}
            value={quality.id}
          />
        )}

        {checked && (
          <input
            type='hidden'
            name={`${attributeString}[cup_premium]`}
            value={cupPremium || ''}
          />
        )}
      </TableData>
    </tr>
  );
}

export default observer(QualitiesPrice);
