import { Provider } from 'mobx-react';
import React from 'react';

import { equalityFilter, likeFilter } from 'utils/filter';
import FilterContextProvider from 'utils/filterContext';
import Ability from 'utils/permissions/ability';

import ProductionOrdersStore from 'subproducts/stores/SubproductProductionOrdersStore';
import ProductionOrdersComponent from 'subproducts/productionOrders/components/ProductionOrders';
import SelectOptionArray from 'types/model/selectOption';
import { AbilityContext } from 'components/shared/Can';

interface ProductionOrdersContainerProps {
  productionOrders: Array<any>;
  years: SelectOptionArray;
  states: SelectOptionArray;
}

const filterConfiguration = {
  identifier: {
    filter: likeFilter,
    value: undefined,
  },
  state: {
    filter: equalityFilter,
    value: undefined,
  },
  year: {
    filter: equalityFilter,
    value: undefined,
  },
};

const ProductionOrdersContainer: React.FC<ProductionOrdersContainerProps> = ({
  productionOrders,
  ...props
}) => (
  <AbilityContext.Provider value={Ability}>
    <Provider productionOrdersStore={new ProductionOrdersStore(productionOrders)}>
      <FilterContextProvider value={filterConfiguration}>
        <ProductionOrdersComponent {...props} />
      </FilterContextProvider>
    </Provider>
  </AbilityContext.Provider>
);

export default ProductionOrdersContainer;
