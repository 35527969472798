import React from 'react';
import { customFetch } from 'utils/fetch';
import COLORS from 'constants/colors';

import Routes from 'routes';
import ConfirmationOverlay from 'shared/ConfirmationOverlay';
import Icon from 'components/elements/Icon';

export interface DeclinePriceButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  id: string;
  lot_id: string;
  identifier: string;
}

const DeclinePriceButton: React.FC<DeclinePriceButtonProps> = ({
  id,
  lot_id,
  identifier,
}) => {
  const [showOverlay, setOverlay] = React.useState(false);

  const handleOnCancelClick = () => {
    setOverlay(true);
  };

  const handleOnPrimaryClick = () => {
    customFetch(Routes.api_v1_lot_final_price_path(lot_id, id), {}, 'DELETE').then(() => {
      window.location.reload();
    });
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  return (
    <>
      {showOverlay && (
        <ConfirmationOverlay
          title={I18n.translate('accept_price.index.decline_price.title', {
            identifier,
          })}
          confirmText={I18n.translate('accept_price.index.decline_price.primary')}
          onConfirmClick={handleOnPrimaryClick}
          cancelText={I18n.translate('accept_price.index.decline_price.secondary')}
          onCancelClick={handleOnSecondaryClick}
        />
      )}

      <button
        onClick={handleOnCancelClick}
        type='button'
        className='button button--small button--ghost'
        data-cy='decline_price'
      >
        <Icon icon='cross' color={COLORS.RED} />
      </button>
    </>
  );
};

export default DeclinePriceButton;
