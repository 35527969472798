import Export from 'exports/models/Export';
import ExportDocumentType from 'exports/types/ExportDocumentType';
import filterRequiredOnly from 'exports/utils/filterRequiredOnly';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import AccordionWrapper, {
  AccordionHeaderProps,
  AccordionItemType,
} from 'shared/Accordion/components/AccordionWrapper';
import ShipmentSettingsModel from '../../models/ShipmentSettings';
import StartShipmentModel from '../../models/StartShipment';
import PackingDeclaration from '../packingDeclaration/PackingDeclaration';
import PackingDeclarationHeader from '../packingDeclaration/PackingDeclarationHeader';
import PackingList from '../packingList/PackingList';
import PackingListHeader from '../packingList/PackingListHeader';
import ShipmentDate from '../ShipmentDate';
import ShippingAdvice from '../shippingAdvice/ShippingAdvice';
import ShippingAdviceHeader from '../shippingAdvice/ShippingAdviceHeader';
export interface StartShipmentProps {
  export_: Export;
  startShipment: StartShipmentModel;
  shipmentSettings: ShipmentSettingsModel;
}

const StartShipment = ({
  export_,
  shipmentSettings: { shipmentInstruction },
  startShipment: { shippingAdvice, packingList, packingDeclaration },
}: StartShipmentProps): JSX.Element => {
  const { requiredDocuments } = export_;

  const documents: ExportDocumentType = new Map([
    [
      'shipping_advice',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <ShippingAdviceHeader
            completed={shippingAdvice?.finalized || false}
            expanded={expanded}
          />
        )),
        body: observer((_props: AccordionHeaderProps) => (
          <ShippingAdvice
            shippingAdvice={shippingAdvice}
            exportIncoterm={export_.incoterm}
            packingListSealNumber={packingList.seal_number}
          />
        )),
      },
    ],
    [
      'packing_list',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <PackingListHeader
            completed={packingList?.finalized || false}
            expanded={expanded}
          />
        )),
        body: observer((_props: AccordionHeaderProps) => (
          <PackingList
            packingList={packingList}
            shippingAdviceContainerNumber={shippingAdvice.container_number}
          />
        )),
      },
    ],
    [
      'packing_declaration',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <PackingDeclarationHeader
            completed={packingDeclaration.finalized}
            expanded={expanded}
          />
        )),
        body: observer((_props: AccordionHeaderProps) => {
          const vesselName = shipmentInstruction.vessel_name || '';
          const voyageNumber = shipmentInstruction.voyage_number || '';
          const billOfLading = shipmentInstruction.bill_of_lading || '';

          return (
            <PackingDeclaration
              packingDeclaration={packingDeclaration}
              vesselName={vesselName}
              voyageNumber={voyageNumber}
              billOfLading={billOfLading}
            />
          );
        }),
      },
    ],
  ]);

  // @ts-ignore: Wrongly typed Options
  const firstSectionAccordions: AccordionItemType[] = useMemo(
    () => filterRequiredOnly(documents, requiredDocuments),
    [shipmentInstruction.finalized]
  );

  return (
    <>
      <ShipmentDate
        text={I18n.translate('exports.enter_export_date')}
        value={export_.exportDate}
        disabled={!export_.canBeExported}
        onSave={(date) => export_.updateExportDate(date)}
      />
      <AccordionWrapper accordions={firstSectionAccordions} />
    </>
  );
};

export default observer(StartShipment);
