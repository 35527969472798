import { deserialise } from 'kitsu-core';
import { action, computed, observable } from 'mobx';
import Mark, { MarkMillingType } from 'types/model/Mark';
import SelectOptionArray from 'types/model/selectOption';
import { JsonApiResource } from 'types/utils/jsonApi';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';
import ProductionOrder from '../types/ProductionOrder';

const ProductionOrderTypeToMillingType: {
  [key: string]: MarkMillingType[];
} = {
  vanilla: ['vanilla', 'decaf'],
  caracol: ['peebies', 'peaberries'],
  subproducts: [],
};

class ProductionOrderStore {
  private marks: Mark[];

  @observable private selectedProductionOrder?: ProductionOrder;

  public constructor(marks: JsonApiResource<Mark>) {
    this.marks = deserialise(marks).data;
  }

  @action selectProductionOrder = (productionOrder: ProductionOrder): void => {
    this.selectedProductionOrder = productionOrder;
  };

  public saveMark = async (id: string): Promise<void> => {
    const mark_id = parseInt(id);

    if (this.selectedProductionOrder?.id) {
      return await customFetch(
        Routes.add_mark_api_v1_contract_path(this.selectedProductionOrder.id),
        { mark_id },
        'POST'
      );
    }
  };

  @computed get filterMarks(): Mark[] {
    const millingTypes = this.selectedProductionOrder?.production_order_type
      ? ProductionOrderTypeToMillingType[
          this.selectedProductionOrder?.production_order_type
        ]
      : [];

    return this.marks.filter(({ milling_type }) => millingTypes.includes(milling_type));
  }

  @computed get markOptions(): SelectOptionArray {
    return this.filterMarks.map(({ id, name }) => ({
      id,
      name,
    }));
  }
}

export default ProductionOrderStore;
