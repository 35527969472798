import FieldSet from 'components/elements/FieldSet';
import styled from 'styled-components';

export interface FormFieldSetProps {
  noBorder?: boolean;
}

export default styled(FieldSet)<FormFieldSetProps>`
  margin-bottom: 0;
  padding-bottom: 0;

  &:before {
    display: ${(props) => (props.noBorder ? 'none' : 'block')};
  }
`;
