import React from 'react';
import styled from 'styled-components';

import COLORS from 'constants/colors';

import Overlay from 'shared/Overlay';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

import LotLocation from 'types/model/lot_location';

import Icon from 'components/elements/Icon';
import placeholder from 'utils/placeholder';

const LocationColumn = styled.th`
  min-width: 15em;
`;

interface CoffeeLocation {
  id: string;
  location: LotLocation;
  available_weight: number;
  weight_to_produce: number;
  certificate?: string;
  tags: string;
}

export interface LocationComponentProps {
  lite: boolean;
  identifier: string;
  locations: Array<CoffeeLocation>;
}

const StartMillingOrderOverlay: React.FC<LocationComponentProps> = ({
  lite = false,
  identifier,
  locations,
}) => {
  const [showOverlay, setOverlay] = React.useState(false);

  const locationColumnClass = lite ? 'align-center' : 'table-border align-center';

  const renderLocations = () => {
    return locations.map((location: CoffeeLocation) => (
      <tr key={location.id}>
        <td className='table-border'>{location.id}</td>
        <LocationColumn as='td' className={locationColumnClass}>
          <div className='l-distribute-l-r'>
            <TextWithSub
              text={I18n.translate('milling_orders.finalise_milling.area')}
              sub={placeholder(location.location.area)}
            />
            <TextWithSub
              text={I18n.translate('milling_orders.finalise_milling.bin')}
              sub={placeholder(location.location.bin)}
            />
            <TextWithSub
              text={I18n.translate('milling_orders.finalise_milling.level')}
              sub={placeholder(location.location.level)}
            />
            <TextWithSub
              text={I18n.translate('milling_orders.finalise_milling.position')}
              sub={placeholder(location.location.position)}
            />
          </div>
        </LocationColumn>
        {!lite && (
          <>
            <td className='table-border align-center'>{location.available_weight} kg</td>
            <td className='table-border align-center'>{location.weight_to_produce} kg</td>
            <td className='table-border align-center'>{location.tags}</td>
            <td className='align-center'>{location.certificate}</td>
          </>
        )}
      </tr>
    ));
  };

  return (
    <>
      {showOverlay && (
        <Overlay
          title={I18n.translate('milling_orders.locations')}
          titlePrefix={identifier}
          secondaryText={I18n.translate('buttons.close')}
          onSecondaryClick={() => setOverlay(false)}
        >
          <table>
            <thead>
              <tr>
                <th className='table-border'>ID</th>
                <LocationColumn className={locationColumnClass}>
                  {I18n.translate('milling_orders.location_overlay.location')}
                </LocationColumn>
                {!lite && (
                  <>
                    <th className='table-border align-center'>
                      {I18n.translate('milling_orders.location_overlay.available')}
                    </th>
                    <th className='table-border align-center'>
                      {I18n.translate(
                        'milling_orders.location_overlay.quantity_to_produce'
                      )}
                    </th>
                    <th className='table-border align-center'>
                      {I18n.translate('milling_orders.location_overlay.tags')}
                    </th>
                    <th className='align-center'>
                      {I18n.translate('activerecord.models.certificate')}
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>{renderLocations()}</tbody>
          </table>
        </Overlay>
      )}

      <span onClick={() => setOverlay(true)}>
        <Icon icon='location' color={COLORS.BLUE} />
      </span>
    </>
  );
};

export default StartMillingOrderOverlay;
