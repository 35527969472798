import Grid from 'components/elements/Grid';
import InputGroup from 'components/groups/InputGroup';
import COLORS from 'constants/colors';
import FormFieldSet from 'exports/components/FormFieldSet';
import { observer } from 'mobx-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import type ShippingAdviceModel from '../../models/ShippingAdvice';
import type ShippingAdviceType from '../../types/shippingAdvice';

import AccordionFormBody from '../AccordionFormBody';

export interface ShippingAdviceProps {
  shippingAdvice: ShippingAdviceModel;
  exportIncoterm: String;
  packingListSealNumber?: string;
}

const ShippingAdviceWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

const ShippingAdvice = ({
  shippingAdvice,
  exportIncoterm,
  packingListSealNumber,
}: ShippingAdviceProps) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: shippingAdvice,
  });

  const onSaveClick = handleSubmit(async (formData: ShippingAdviceType) => {
    await shippingAdvice.save(formData);

    reset(formData);
  });

  const handleOnFinalizeClick = handleSubmit(async () => {
    await shippingAdvice.finalize();
  });

  return (
    <ShippingAdviceWrapper>
      <AccordionFormBody
        isFinalizable={shippingAdvice.isFinalizable}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        finalized={shippingAdvice.finalized}
        onSubmitClick={onSaveClick}
        onFinalizeClick={handleOnFinalizeClick}
        downloadUrl={
          exportIncoterm == 'CIF' || exportIncoterm == 'FOB'
            ? shippingAdvice.downloadUrl
            : ''
        }
      >
        <FormFieldSet disabled={shippingAdvice.finalized}>
          <Grid grid={16}>
            <InputGroup
              id='etd'
              name='etd'
              ref={register}
              type='date'
              label={I18n.translate('exports.form.shipping_advice.etd_label')}
              placeholder={I18n.translate('exports.form.shipping_advice.etd_placeholder')}
            />

            <InputGroup
              id='eta'
              name='eta'
              ref={register}
              type='date'
              label={I18n.translate('exports.form.shipping_advice.eta_label')}
              placeholder={I18n.translate('exports.form.shipping_advice.eta_placeholder')}
            />

            <InputGroup
              id='container_number'
              name='container_number'
              ref={register}
              label={I18n.translate(
                'exports.form.shipping_advice.container_number_label'
              )}
              placeholder={I18n.translate(
                'exports.form.shipping_advice.container_number_placeholder'
              )}
            />

            <InputGroup
              id='seal_number'
              name='seal_number'
              label={I18n.translate('exports.form.packing_list.seal_number_label')}
              disabled={true}
              value={packingListSealNumber}
            />
          </Grid>
        </FormFieldSet>
      </AccordionFormBody>
    </ShippingAdviceWrapper>
  );
};

export default observer(ShippingAdvice);
