import { Option } from 'components/elements/Search';
import { deserialise } from 'kitsu-core';
import { useEffect, useMemo, useState } from 'react';
import Routes from 'routes';
import Ico from 'samples/types/Ico';
import { customFetch } from 'utils/fetch';
import type ISample from 'types/model/ISample';

export type IPreShipmentSample = Pick<ISample, 'main_identifier'>;

export type ISpotSampleIco = Ico & {
  current_pre_shipment_sample?: IPreShipmentSample;
};

const fetchSpotSampleIcos = () => {
  return customFetch(Routes.api_v1_samples_icos_spot_sample_path(), undefined, 'GET');
};

const useSpotSampleIcos = () => {
  const [icos, setIcos] = useState<ISpotSampleIco[]>([]);

  useEffect(() => {
    fetchSpotSampleIcos().then((data) => {
      const icos = deserialise(data.sources).data;

      setIcos(icos);
    });
  }, []);

  const icoOptions: Option[] = useMemo(
    () =>
      icos.map((ico: ISpotSampleIco) => ({
        value: ico.id,
        label: ico.label,
      })),
    [icos]
  );

  return {
    icos,
    icoOptions,
  };
};

export default useSpotSampleIcos;
