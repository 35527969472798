import React from 'react';
import { observer } from 'mobx-react';
import Routes from 'routes';

import useStores from 'utils/useStores';
import SubmitButtonsComponent from 'shared/SubmitButtons/components/SubmitButtons';

import SpaceBetween from 'components/elements/SpaceBetween';

function ActionBar() {
  const { availableCoffeeStore } = useStores();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const productionOrderId = urlParams.get('production_order');

  const submitPath = productionOrderId
    ? Routes.production_order_assign_coffee_path(productionOrderId)
    : '';

  const cancelPath = productionOrderId
    ? Routes.edit_production_order_path(productionOrderId)
    : '';

  return (
    <SpaceBetween>
      <a href={cancelPath} className='button button--gray'>
        {I18n.translate('buttons.cancel')}
      </a>

      <SubmitButtonsComponent
        body={availableCoffeeStore.submitBody}
        submitPath={submitPath}
        submitText={I18n.translate('buttons.save')}
        submitEnabled={availableCoffeeStore.totalWeight > 0}
        dataCy='save_available_coffee'
      />
    </SpaceBetween>
  );
}

export default observer(ActionBar);
