import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import InputGroupComponent from 'components/groups/InputGroup';
import Overlay from 'shared/Overlay';

import Validation from 'types/utils/validations';

import { VARIANT } from 'constants/inputTypes';
import { isInBound } from 'utils/validators';
import type HumidityAnalysisModel from 'productionOrders/models/HumidityAnalysis';

import useStores from 'utils/useStores';
import SelectOptionArray from 'types/model/selectOption';
import IHumidityAnalysis from 'types/model/IHumidityAnalysis';

export interface HumidityAnalysisOverlayProps {
  identifier: string;
  validations: {
    humidity_green: Validation;
    water_activity: Validation;
    humidity_dry_parchment: Validation;
  };
  overlayStore?: any;
  humidityAnalysis?: IHumidityAnalysis | HumidityAnalysisModel;
  reasons: SelectOptionArray;
  showConfirmation?: boolean;
  onPrimaryClick?: (event: React.MouseEvent, values: {}) => any;
  onSecondaryClick?: (event: React.MouseEvent) => any;
}

function HumidityAnalysisOverlay({
  identifier,
  validations: { humidity_green, water_activity, humidity_dry_parchment },
  humidityAnalysis,
  reasons,
  showConfirmation = false,
  onPrimaryClick,
  onSecondaryClick,
}: HumidityAnalysisOverlayProps) {
  const { overlayStore } = useStores();
  const [humidityGreen, setHumidityGreen] = useState('');
  const [humidityDryParchment, setHumidityDryParchment] = useState('');
  const [waterActivity, setWaterActivity] = useState('');

  useEffect(() => {
    if (humidityAnalysis) {
      setHumidityGreen(humidityAnalysis.humidity_green?.toString() || '');
      setHumidityDryParchment(humidityAnalysis.humidity_dry_parchment?.toString() || '');
      setWaterActivity(humidityAnalysis.water_activity?.toString() || '');

      if (humidityAnalysis.accepting_reason_id) {
        overlayStore.setReasonId(humidityAnalysis.accepting_reason_id);
        overlayStore.setValid(false);
        overlayStore.setOverrideValidation(true);
      }
    }
  }, []);

  useEffect(() => {
    const humidityGreenNumber = parseFloat(humidityGreen);
    const waterActivityNumber = parseFloat(waterActivity);
    const humidityDryParchmentNumber = parseFloat(humidityDryParchment);

    let isFilled = humidityGreen?.length > 0 && waterActivity?.length > 0;

    let isValid =
      isInBound(humidityGreenNumber, humidity_green.min, humidity_green.max) &&
      isInBound(waterActivityNumber, water_activity.min, water_activity.max);

    if (humidity_dry_parchment.min) {
      isFilled = isFilled && humidityDryParchment?.length > 0;
      isValid =
        isValid &&
        isInBound(
          humidityDryParchmentNumber,
          humidity_dry_parchment.min,
          humidity_dry_parchment.max
        );
    }

    overlayStore.setFilled(isFilled);
    overlayStore.setValid(isValid);

    if (overlayStore.overrideValidation) {
      overlayStore.setOverrideValidation(
        !(overlayStore.isFilled && overlayStore.isValid)
      );
    }
  }, [waterActivity, humidityGreen, humidityDryParchment]);

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const humidityGreenValidator = (e: React.ChangeEvent<HTMLInputElement>) =>
    isInBound(parseFloat(e.target.value), humidity_green.min, humidity_green.max);

  const humidityDryParchmentValidator = (e: React.ChangeEvent<HTMLInputElement>) =>
    isInBound(
      parseFloat(e.target.value),
      humidity_dry_parchment.min,
      humidity_dry_parchment.max
    );

  const waterActivityValidator = (e: React.ChangeEvent<HTMLInputElement>) =>
    isInBound(parseFloat(e.target.value), water_activity.min, water_activity.max);

  const handleOnPrimaryClick = (event: React.MouseEvent) => {
    const currentValues = {
      accepting_reason_id: overlayStore.reasonId,
      humidity_green: humidityGreen,
      humidity_analysis_id: humidityAnalysis ? humidityAnalysis.id : null,
      humidity_dry_parchment: humidityDryParchment,
      water_activity: waterActivity,
    };

    if (onPrimaryClick) {
      if (!overlayStore.isValid && showConfirmation) {
        if (confirm(I18n.translate('humidity_analysis_overlay.confirmation_dialogue'))) {
          onPrimaryClick(event, currentValues);
        }
      } else {
        onPrimaryClick(event, currentValues);
      }
    }
  };

  const handleOnSecondaryClick = (event) => {
    setHumidityGreen('');
    setWaterActivity('');
    setHumidityDryParchment('');
    overlayStore.setValid(false);
    overlayStore.setFilled(false);
    overlayStore.setOverrideValidation(false);
    overlayStore.setReasonId(null);

    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
  };

  return (
    <Overlay
      id={identifier}
      title={`${identifier} Humidity`}
      primaryDisabled={!overlayStore.isFilled}
      primaryText={I18n.translate('buttons.save')}
      onPrimaryClick={handleOnPrimaryClick}
      secondaryText={I18n.translate('buttons.close')}
      onSecondaryClick={handleOnSecondaryClick}
      reasons={reasons}
    >
      <div className='l-item-stack-horizontal'>
        <InputGroupComponent
          data-cy={'humidity_green'}
          label={I18n.translate('attributes.humidity_green')}
          variant={VARIANT.DECIMAL}
          append='%'
          value={humidityGreen}
          validator={humidityGreenValidator}
          onChange={(e) => handleInputChange(e, setHumidityGreen)}
          step='0.01'
        />

        <InputGroupComponent
          data-cy={'water_activity'}
          label={I18n.translate('attributes.water_activity')}
          variant={VARIANT.DECIMAL}
          value={waterActivity}
          validator={waterActivityValidator}
          onChange={(e) => handleInputChange(e, setWaterActivity)}
          step='0.01'
        />

        <InputGroupComponent
          data-cy={'humidity_parchment_short'}
          label={I18n.translate('attributes.humidity_parchment_short')}
          variant={VARIANT.DECIMAL}
          append='%'
          value={humidityDryParchment}
          validator={humidityDryParchmentValidator}
          onChange={(e) => handleInputChange(e, setHumidityDryParchment)}
          step='0.01'
        />
      </div>
    </Overlay>
  );
}

export default observer(HumidityAnalysisOverlay);
