import styled from 'styled-components';

import COLORS from 'constants/colors';

const TileList = styled.div`
  display: grid;
  grid-template-columns: minmax(25em, 1fr) minmax(20em, 1fr) 17em;
  background: ${COLORS.WHITE};
  border-width: 0 1px 1px 1px;
  border-style: none solid solid solid;
  border-color: ${COLORS.TABLE_BORDERS};

  & > * {
    padding: 0.6875em 1.25em;
    border-right: 1px solid ${COLORS.TABLE_BORDERS};
  }

  & > *:first-child {
    padding-left: 0.625em;
  }

  & > *:last-child {
    padding-right: 0.625em;
    border-right: none;
  }
`;

export default TileList;
