import classNames from 'classnames';
import React from 'react';

import { SelectableLot } from '../types/Lot';

import Checkbox from 'shared/Checkbox';
import Text from 'shared/TextWithSub/components/Text';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

interface LotItemProps {
  lot: SelectableLot;
  onCheck: () => any;
  checked?: boolean;
  disabled?: boolean;
}

const LotItem = ({
  lot: { attributes },
  onCheck,
  checked = false,
  disabled,
}: LotItemProps) => {
  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    onCheck();
  };

  return (
    <tr
      className={classNames({
        'sets-table__row--disabled': disabled,
      })}
    >
      <td className='lot--id'>
        {attributes.lab_sample_identifier ? (
          <TextWithSub
            text={attributes.local_identifier}
            sub={attributes.lab_sample_identifier}
          />
        ) : (
          <Text text={attributes.local_identifier} />
        )}
      </td>

      <td className='sets-table__column--sensorial table-border'>
        <div className='l-item-stack-horizontal'></div>
      </td>

      <td className='table-border align-center'>
        <TextWithSub text={attributes.grade} sub={attributes.quality} />
      </td>

      <td className='table-border align-center'>{attributes.final_score}</td>

      <td className='sets-table__column--weight table-border align-center'>
        {attributes.weight_string}
      </td>

      <td className='sets-table__column--producer table-border'>
        <TextWithSub
          text={attributes.producer}
          sub={`${attributes.certification}${attributes.eudr ? ' | EUDR' : ''}`}
          width='14em'
        />
      </td>

      <td className='align-center'>
        <label className='checkbox checkbox--no-label' onClick={handleOnClick}>
          <Checkbox
            checked={checked}
            disabled={disabled}
            dataCy='checkbox_add_to_set'
            onChange={() => false}
          />
        </label>
      </td>
    </tr>
  );
};

export default LotItem;
