import { deserialise } from 'kitsu-core';
import { observable } from 'mobx';

import ProductionOrder from 'types/model/productionOrder';

class SubproductProductionOrdersStore {
  public productionOrders = observable.array<ProductionOrder>();

  constructor(productionOrders: Array<ProductionOrder> = []) {
    this.productionOrders = observable.array(deserialise(productionOrders).data);
  }
}

export default SubproductProductionOrdersStore;
