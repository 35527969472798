import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';
import { observer } from 'mobx-react';

import Grid from 'components/elements/Grid';
import SelectGroup from 'components/groups/SelectGroup';
import InputGroup from 'components/groups/InputGroup';
import FormFieldSet from 'exports/components/FormFieldSet';
import PackingDeclarationModel from '../../models/PackingDeclaration';
import { useForm } from 'react-hook-form';
import useStores from 'utils/useStores';
import AccordionFormBody from '../AccordionFormBody';

export interface PackingDeclarationProps {
  packingDeclaration: PackingDeclarationModel;
  vesselName: string;
  voyageNumber: string;
  billOfLading: string;
}

const PackingDeclarationWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

const PackingDeclaration = ({
  packingDeclaration,
  vesselName,
  voyageNumber,
  billOfLading,
}: PackingDeclarationProps) => {
  const {
    exportsStore: { timberBambooPackaging, treatmentCertification },
  } = useStores();

  const { register, reset, handleSubmit, formState, watch } = useForm({
    defaultValues: packingDeclaration,
  });

  const onFinalizeClick = async () => {
    await packingDeclaration.finalize();
  };

  const onSaveClick = handleSubmit(async (formData: any) => {
    await packingDeclaration.save(formData);

    reset(formData);
  });

  const certificationRequiredPackagings = ['timber', 'bamboo'];

  const treatmentCertificationVisible = React.useMemo(() => {
    const packaging = watch('timber_bamboo_packaging');
    return packaging && certificationRequiredPackagings.includes(packaging);
  }, [watch('timber_bamboo_packaging')]);

  const unacceptableMaterials = [
    {
      id: 1,
      name: 'YES',
      value: 'true',
    },
    {
      id: 2,
      name: 'NO',
      value: 'false',
    },
  ];

  return (
    <PackingDeclarationWrapper>
      <AccordionFormBody
        isFinalizable={packingDeclaration.isFinalizable}
        isDirty={formState.isDirty}
        isSubmitting={formState.isSubmitting}
        finalized={packingDeclaration.finalized}
        onSubmitClick={onSaveClick}
        onFinalizeClick={onFinalizeClick}
        downloadUrl={packingDeclaration.downloadUrl}
      >
        <FormFieldSet noBorder={true}>
          <Grid grid={16}>
            <InputGroup
              id='vessel_name'
              label={I18n.translate('exports.form.packing_declaration.vessel_name_label')}
              placeholder={I18n.translate(
                'exports.form.packing_declaration.vessel_name_placeholder'
              )}
              disabled={true}
              value={vesselName}
            />

            <InputGroup
              id='voyage_number'
              label={I18n.translate(
                'exports.form.packing_declaration.voyage_number_label'
              )}
              placeholder={I18n.translate(
                'exports.form.packing_declaration.voyage_number_placeholder'
              )}
              disabled={true}
              value={voyageNumber}
            />

            <InputGroup
              id='bill_of_lading'
              label={I18n.translate(
                'exports.form.packing_declaration.bill_of_lading_label'
              )}
              placeholder={I18n.translate(
                'exports.form.packing_declaration.bill_of_lading_placeholder'
              )}
              disabled={true}
              value={billOfLading}
            />
          </Grid>
        </FormFieldSet>

        <FormFieldSet disabled={packingDeclaration.finalized}>
          <Grid grid={16}>
            <SelectGroup
              label={I18n.translate(
                'exports.form.packing_declaration.unacceptable_packing_materials_label'
              )}
              options={unacceptableMaterials}
              ref={register}
              name='unacceptable_packing_materials'
            />

            <SelectGroup
              label={I18n.translate(
                'exports.form.packing_declaration.timber_bamboo_packaging_label'
              )}
              options={timberBambooPackaging}
              name='timber_bamboo_packaging'
              ref={register}
            />

            {treatmentCertificationVisible && (
              <SelectGroup
                label={I18n.translate(
                  'exports.form.packing_declaration.treatment_certification'
                )}
                options={treatmentCertification}
                name='treatment_certification'
                ref={register}
              />
            )}
          </Grid>
        </FormFieldSet>
      </AccordionFormBody>
    </PackingDeclarationWrapper>
  );
};

export default observer(PackingDeclaration);
