import React, { useState } from 'react';
import SelectGroup from 'components/groups/SelectGroup';
import CoffeeType from 'types/model/coffee_type';

export interface CoffeeTypeProps {
  defaultValue: string;
  coffeeTypes: Array<CoffeeType>;
}

export const LOTS_COFFEE_TYPE_CHANGE = 'lots/coffeeType/change';

export type LotCoffeeTypeDetail = {
  coffeeType: {
    isGreen: boolean;
  };
};

const CoffeeTypeSelect: React.FC<CoffeeTypeProps> = ({ coffeeTypes, defaultValue }) => {
  const [coffeeTypeId, setCoffeeTypeId] = useState(defaultValue);

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCoffeeTypeId = event.target.value;

    const coffeeType = coffeeTypes.find(
      (coffeeType) => coffeeType.id === parseInt(selectedCoffeeTypeId, 10)
    );

    setCoffeeTypeId(selectedCoffeeTypeId);

    window.ReactEventBus.dispatch(
      new CustomEvent<LotCoffeeTypeDetail>(LOTS_COFFEE_TYPE_CHANGE, {
        detail: {
          coffeeType: {
            isGreen: coffeeType?.wet_reception || false,
          },
        },
      })
    );
  };

  return (
    <SelectGroup
      name='lot[coffee_type_id]'
      id='lot_coffee_type_id'
      label={I18n.translate('attributes.type')}
      options={coffeeTypes}
      value={coffeeTypeId}
      onChange={onChangeHandler}
    />
  );
};

export default CoffeeTypeSelect;
