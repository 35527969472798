import React from 'react';
import Routes from 'routes';
import styled from 'styled-components';

import Tile, { TileHeader } from 'shared/Tile';
import { SAMPLE_TYPE } from 'types/model/ISample';
import Select from 'components/elements/Select';
import useStores from 'utils/useStores';

const Actions = styled.div`
  display: grid;
  grid-template-columns: auto minmax(8.125em, 100%);
  grid-column-gap: 1.25em;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Filter = styled.div`
  display: grid;
  grid-template-columns: 15em 7em 10em 10em 10em;
  grid-column-gap: 1em;
  align-items: center;
`;

export interface SearchBarProps {
  currentSampleType: SAMPLE_TYPE;
  currentLocalIdentifier: string;
  currentMark: string;
  currentQuality: string;
  currentOrigin: string;
}

function SearchBar({
  currentSampleType,
  currentLocalIdentifier,
  currentMark,
  currentQuality,
  currentOrigin,
}: SearchBarProps): JSX.Element {
  const { analyseAndAdjustStore } = useStores();
  const { sampleTypes, qualities, marks, origins } = analyseAndAdjustStore;

  return (
    <Tile>
      <TileHeader className='selected-lots__header'>
        <form method='GET' action={Routes.analyse_and_adjust_samples_path()}>
          <Actions>
            <Filter>
              <input
                type='text'
                className='input--condensed'
                placeholder={I18n.translate('samples.consolidate.filter')}
                name='filter[local_identifier]'
                defaultValue={currentLocalIdentifier}
              />

              <Select
                name='filter[sample_type]'
                defaultValue={currentSampleType}
                className='input--condensed'
                placeholder={I18n.translate('samples.analyse_and_adjust.select_type')}
                options={sampleTypes}
              />

              <Select
                name='filter[origin]'
                defaultValue={currentOrigin}
                className='input--condensed'
                placeholder={I18n.translate('samples.analyse_and_adjust.select_origin')}
                options={origins}
              />

              <Select
                name='filter[quality]'
                defaultValue={currentQuality}
                className='input--condensed'
                placeholder={I18n.translate('samples.analyse_and_adjust.select_quality')}
                options={qualities}
              />

              <Select
                name='filter[mark]'
                defaultValue={currentMark}
                className='input--condensed'
                placeholder={I18n.translate('samples.analyse_and_adjust.select_mark')}
                options={marks}
              />
            </Filter>

            <button className='button button--condensed'>
              {I18n.translate('samples.analyse_and_adjust.find_sample')}
            </button>
          </Actions>
        </form>
      </TileHeader>
    </Tile>
  );
}

export default SearchBar;
