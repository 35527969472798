import AvailabilityNoticeType from 'exports/types/availabilityNotice';
import EndShipmentType from 'exports/types/endShipment';
import { computed, observable } from 'mobx';
import Routes from 'routes';
import FinalizeService from '../services/FinalizeService';
import AvailabilityNotice from './AvailabilityNotice';
import Export from './Export';
import IcoImportList from './IcoImportList';

class EndShipment {
  @observable public availabilityNotice: AvailabilityNotice;
  @observable public icoImportList: IcoImportList;

  constructor({ availabilityNotice, icos }: EndShipmentType, export_: Export) {
    const availabilityNoticeService = new FinalizeService<AvailabilityNoticeType>(
      export_,
      Routes.api_v1_export_availability_notices_path,
      Routes.api_v1_availability_notice_path,
      Routes.finalize_api_v1_availability_notice_path
    );

    this.availabilityNotice = new AvailabilityNotice(
      { finalized: false, ...availabilityNotice },
      availabilityNoticeService
    );

    this.icoImportList = new IcoImportList(icos);
  }

  @computed get allDocumentsFinalized() {
    return this.availabilityNotice.finalized;
  }
}

export default EndShipment;
