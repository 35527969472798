import ContentHeader from 'components/elements/ContentHeader';
import PaginatedTable from 'components/shared/PaginatedTable';
import { observer } from 'mobx-react';
import React from 'react';
import ITransportSample from 'samples/types/ITransportSample';
import useStores from 'utils/useStores';
import SelectableTransportSampleModel from '../models/SelectableTransportSampleModel';
import ActionForm from './ActionForm';
import FilterForm from './FilterForm';
import InventoryItem from './InventoryItem';
import ErrorsComponent from 'shared/Errors/components/Errors';
import styled from 'styled-components';

const Errors = styled(ErrorsComponent)`
  margin-bottom: 1em;
`;

const TransportLegIndex: React.FC = () => {
  const { transportSamplesStore } = useStores();

  const renderTableData = (inventory: ITransportSample) => {
    const inventoryModel = dataRowMapper(inventory);
    return (
      <InventoryItem
        key={inventory.id}
        inventory={inventoryModel}
        checked={inventoryModel.selected}
        onCheck={() => transportSamplesStore.toggleSampleSelection(inventoryModel)}
      ></InventoryItem>
    );
  };

  const dataRowMapper = (dataRow: ITransportSample): SelectableTransportSampleModel => {
    const sample = transportSamplesStore.findSample(dataRow);

    return sample ? sample : new SelectableTransportSampleModel(dataRow, false);
  };

  const renderTableHeader = () => {
    return (
      <tr>
        <th className='align-left'>ID</th>

        <th className='table-border align-left'>
          {I18n.translate('samples.send.producer_certification')}
        </th>

        <th className='selected-lots selected-lots__quality table-border'>
          {I18n.translate('samples.send.type')}
        </th>

        <th className='selected-lots selected-lots__quality table-border'>
          {I18n.translate('samples.send.quality')}
        </th>

        <th className='selected-lots selected-lots__weight table-border'>
          {I18n.translate('samples.send.weight')}
        </th>

        <th className='selected-lots selected-lots__weight table-border'>
          {I18n.translate('activerecord.models.mark')}
        </th>

        <th className='selected-lots selected-lots__checkbox'>
          {I18n.translate('samples.send.add')}
        </th>
      </tr>
    );
  };

  const renderSelectedSamples = () => {
    return transportSamplesStore.selectedSamples.length > 0 ? (
      <table>
        <tbody>
          {transportSamplesStore.selectedSamples.map((sample) => {
            return (
              <InventoryItem
                key={sample.attributes.id}
                inventory={sample}
                checked={sample.selected}
                onCheck={() => transportSamplesStore.toggleSampleSelection(sample)}
              />
            );
          })}
        </tbody>
      </table>
    ) : (
      <></>
    );
  };

  const renderTopBar = () => {
    return (
      <>
        <FilterForm />
        <ActionForm />
      </>
    );
  };

  return (
    <div className='content'>
      <ContentHeader
        title={I18n.translate('headlines.send_sample_transport')}
      ></ContentHeader>

      <Errors errors={transportSamplesStore.errors} />

      <PaginatedTable<ITransportSample>
        renderTableData={renderTableData}
        renderTableHeader={renderTableHeader}
        renderSelectedData={renderSelectedSamples}
        renderTopBar={renderTopBar}
        paginatedTableStore={transportSamplesStore.paginatedTableStore}
        hasSelectedData={transportSamplesStore.selectedSamples.length > 0}
      />
    </div>
  );
};

export default observer(TransportLegIndex);
