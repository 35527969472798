import React, { useState } from 'react';
import Sample from 'cuppingTable/type/Sample';
import Checkbox from 'shared/Checkbox';
import styled from 'styled-components';
import InputGroupComponent from 'components/groups/InputGroup';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
interface OverviewTableRowProps {
  sample: Sample;
}

const StyledCheckBoxWrapper = styled.div`
  position: relative;
`;

const StyledCuppingTableNumber = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const StyledCuppingTableNumberChild = styled.div`
  max-width: 100px;
`;

const SampleTableRow = ({ sample }: OverviewTableRowProps): JSX.Element => {
  const { cuppingTableStore } = useStores();
  const [table_number, setTableNumber] = useState(sample.table_number);
  const [observations, setObservations] = useState(sample.observations);
  return (
    <tr>
      <td className='table-border align-center'>
        <p>{sample.identifier_info}</p>
        <p>{sample.frontend_identifier}</p>
      </td>
      <td className='table-border align-center'>{sample.sample_type}</td>
      {/* <td className='table-border align-center'>{`${sample.grade} ${sample.quality}`}</td> */}
      {sample.selected && (
        <td className='table-border'>
          <TextAreaGroupComponent
            id={`cupping_table[cupping_table_samples_attributes][observations][${sample.id.toString()}]`}
            name={`cupping_table[cupping_table_samples_attributes][${sample.id}][observations]`}
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
          />
        </td>
      )}
      <td className='table-border align-center'>
        <StyledCuppingTableNumber>
          <StyledCuppingTableNumberChild>
            <InputGroupComponent
              disabled={!sample.selected}
              id={`cupping_table[cupping_table_samples_attributes][cupping_table_number][${sample.id.toString()}]`}
              name={
                sample.selected
                  ? `cupping_table[cupping_table_samples_attributes][${sample.id}][cupping_table_number]`
                  : undefined
              }
              value={table_number}
              onChange={(e) => setTableNumber(parseInt(e.target.value, 10))}
              required={sample.selected}
              type='number'
              min='1'
              step='1'
            />
          </StyledCuppingTableNumberChild>
        </StyledCuppingTableNumber>
      </td>
      {/* <td className='table-border align-center'>{sample.weight}</td>
      <td className='table-border align-center'>{sample.process_type}</td> */}
      <td className='align-center'>
        {sample.cupping_table_sample_id && (
          <InputGroupComponent
            disabled={!sample.selected}
            id={`cupping_table[cupping_table_samples_attributes][id][${sample.cupping_table_sample_id.toString()}]`}
            name={`cupping_table[cupping_table_samples_attributes][${sample.id}][id]`}
            value={sample.cupping_table_sample_id}
            className='hidden--important'
          />
        )}

        <StyledCheckBoxWrapper>
          <Checkbox
            name={
              sample.selected
                ? `cupping_table[cupping_table_samples_attributes][${sample.id}][sample_id]`
                : ''
            }
            dataCy={`select_sample_checkbox`}
            id={`cupping_table[cupping_table_samples_attributes][][${sample.id.toString()}]`}
            value={sample.id.toString()}
            onChange={() => cuppingTableStore.toggleSample(sample.id)}
            checked={sample.selected}
            disabled={
              !!sample.cupping_table_sample_id &&
              sample.selected &&
              !sample.can_be_deleted
            }
            required={cuppingTableStore.selectedSamples.length < 1}
          />
        </StyledCheckBoxWrapper>
      </td>
    </tr>
  );
};

export default observer(SampleTableRow);
