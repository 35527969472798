import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import AvailabilityNotice from '../../components/availabilityNotice/AvailabilityNotice';
import AvailabilityNoticeHeader from '../../components/availabilityNotice/AvailabilityNoticeHeader';
import ImportIcosContainer from '../../containers/ImportIcosContainer';
import Export from '../../models/Export';
import AccordionWrapper, {
  AccordionHeaderProps,
  AccordionItemType,
} from 'shared/Accordion/components/AccordionWrapper';
import useStores from 'utils/useStores';
import EndShipmentModel from '../../models/EndShipment';
import IcoImportHeader from '../icoImport/IcoImportHeader';
import ShipmentDate from '../ShipmentDate';
import filterRequiredOnly from '../../utils/filterRequiredOnly';
import ExportDocumentType from '../../types/ExportDocumentType';

export interface EndShipmentProps {
  export_: Export;
  endShipment: EndShipmentModel;
}

const EndShipment = ({
  export_,
  endShipment: { availabilityNotice, icoImportList },
}: EndShipmentProps) => {
  const {
    exportsStore: { destinationWarehouses },
  } = useStores();
  const documents: ExportDocumentType = new Map([
    [
      'availability_notice',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <AvailabilityNoticeHeader
            completed={availabilityNotice?.finalized || false}
            expanded={expanded}
          />
        )),
        body: observer((_props: AccordionHeaderProps) => (
          <AvailabilityNotice
            destinationWarehouses={destinationWarehouses}
            availabilityNotice={availabilityNotice}
          />
        )),
        expandable: true,
      },
    ],
    [
      'ico_import',
      {
        header: observer(({ expanded }: AccordionHeaderProps) => (
          <IcoImportHeader completed={icoImportList.isFinalized} expanded={expanded} />
        )),
        body: observer((_props: AccordionHeaderProps) => (
          <ImportIcosContainer
            destinationWarehouses={destinationWarehouses}
            icoImports={icoImportList.icoImports}
            isThirdPartyImport={false}
          />
        )),
        expandable: ['exported', 'closed'].includes(export_.state),
      },
    ],
  ]);

  // @ts-ignore: Wrongly typed Options
  const firstSectionAccordions: AccordionItemType[] = useMemo(
    () => filterRequiredOnly(documents, export_.requiredDocuments),
    export_.requiredDocuments
  );

  return (
    <>
      <ShipmentDate
        text={I18n.translate('exports.enter_destination_port_import_date')}
        value={export_.importDate}
        disabled={!(availabilityNotice.finalized && icoImportList.isFinalized)}
        onSave={(date) => export_.updateImportDate(date)}
      />
      <AccordionWrapper accordions={firstSectionAccordions} />
    </>
  );
};
export default observer(EndShipment);
