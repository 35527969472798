import React from 'react';
import styled from 'styled-components';

export interface CollapsibleBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  expanded: boolean;
  maxHeight?: string;
}

// If maxHeight is passed - transitions, if not, it just jumps to required height
export default styled.div<CollapsibleBodyProps>`
  width: 100%;
  max-height: ${(props) =>
    props.expanded ? (props.maxHeight ? props.maxHeight : 'auto') : 0};
  overflow: hidden;
  transition: max-height 0.25s ease;
`;
