import Grid from 'components/elements/Grid';
import SelectGroup from 'components/groups/SelectGroup';
import TextareaGroupComponent from 'components/groups/TextareaGroup';
import React, { useState } from 'react';
import {
  OverlayBaseForm,
  OverlayFooter,
  OverlayTitle,
  OverlayWrapper,
} from 'shared/Overlay';
import useStores from 'utils/useStores';

import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

export interface OpportunitySelectOverlayProps {
  onCloseClick: (event?: React.MouseEvent) => void;
}

function OpportunitySelectOverlay({ onCloseClick }: OpportunitySelectOverlayProps) {
  const { consolidateSamplesStore } = useStores();
  const { setSubmitting, submitting } = consolidateSamplesStore;
  const { paginatedTableStore, selectedMarkId, selectedSampleTypeId } =
    consolidateSamplesStore;
  const [selectedOpportunityId, setSelectedOpportunityId] = useState('');

  const handleOnSubmit = (_event: React.FormEvent) => {
    setSubmitting(true);
  };

  return (
    <OverlayWrapper>
      <OverlayBaseForm>
        <form
          action={paginatedTableStore.actionUrl ? paginatedTableStore.actionUrl() : ''}
          method='POST'
          onSubmit={handleOnSubmit}
        >
          <AuthenticityToken />

          <input type='hidden' name='sample_type' value={selectedSampleTypeId} />

          {consolidateSamplesStore.selectedSamples.map(
            ({ attributes: { id, sampleSize } }) => (
              <input key={id} type='hidden' name={`samples[${id}]`} value={sampleSize} />
            )
          )}

          <OverlayTitle>
            {I18n.translate('samples.consolidate.offer_sample_overlay_title')}
          </OverlayTitle>

          <Grid grid={12}>
            <SelectGroup
              label={I18n.translate('samples.consolidate.opportunity')}
              placeholder={I18n.translate('samples.consolidate.opportunity_placeholder')}
              options={consolidateSamplesStore.opportunities}
              value={selectedOpportunityId}
              onChange={(e) => setSelectedOpportunityId(e.target.value)}
              name='opportunity_id'
              data-cy='opportunity_select'
            />

            <TextareaGroupComponent
              placeholder={I18n.translate(
                'customer_samples_dashboard.observations_placeholder'
              )}
              name='observation'
              id='observation'
              label={I18n.translate('attributes.observations')}
              data-cy='observation_textarea'
            />
          </Grid>

          <OverlayFooter className='l-item-stack-horizontal' distributeButtons={true}>
            <button onClick={onCloseClick} className='button button--gray'>
              {I18n.translate('buttons.close')}
            </button>
            <button
              type='submit'
              disabled={
                !consolidateSamplesStore.isFormValid ||
                !selectedOpportunityId ||
                submitting
              }
              className='button'
              data-cy='create_offer_sample_submit'
            >
              {I18n.translate('samples.consolidate.create_sample')}
            </button>
          </OverlayFooter>

          <input
            type='hidden'
            name='opportunity_id'
            value={selectedOpportunityId ? selectedOpportunityId : ''}
          />
          <input
            type='hidden'
            name='mark_id'
            value={selectedMarkId ? selectedMarkId : ''}
          />
        </form>
      </OverlayBaseForm>
    </OverlayWrapper>
  );
}

export default OpportunitySelectOverlay;
