import type DecafOrderType from '../types/DecafOrder';
import Sample from 'productionOrders/models/Sample';
import Ico from 'decaf/types/Ico';

import { createSampleFactory } from 'utils/factories/createSampleFactory';
import DecafSampleBuidler from '../builders/DecafSampleBuilder';

class DecafOrder {
  public id: number;
  public sample: Sample;
  public ico: Ico;

  public constructor({ id, ico }: DecafOrderType) {
    this.id = id;
    this.ico = ico;

    const sampleBuilder = new DecafSampleBuidler(ico.sample, id);

    this.sample = createSampleFactory(ico.sample, sampleBuilder);
  }
}

export default DecafOrder;
