import styled from 'styled-components';

const IconWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  margin-left: 0.5rem;

  .icon {
    padding-bottom: 0.2rem;
  }
`;

export default IconWrapper;
