import { createContext } from 'react';

import { FilterState } from './types';

export interface FilterContextInterface {
  filters: FilterState;
  updateFilter: (key: string, value: string) => void;
}

const FilterContext = createContext<FilterContextInterface>({
  filters: {},
  updateFilter: () => undefined,
});

export default FilterContext;
