import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { TableHeaderData } from 'shared/ScrollableTable';

import DestinationPort from '../models/DestinationPort';

export interface TableDestinationPortProps {
  destinationPort: DestinationPort;
}

const ExpandButtonWrapper = styled.div`
  margin-top: 0.325rem;
  font-size: 1rem;
`;

const TableDestinationPort = ({ destinationPort }: TableDestinationPortProps) => (
  <TableHeaderData>
    {destinationPort.name}
    <ExpandButtonWrapper>
      <button
        onClick={() => destinationPort.toggleExportsCollapsed()}
        className='button button--ghost button--small'
      >
        {destinationPort.allExportsCollapsed
          ? I18n.translate('buttons.expand_all')
          : I18n.translate('buttons.collapse_all')}
      </button>
    </ExpandButtonWrapper>
  </TableHeaderData>
);

export default observer(TableDestinationPort);
