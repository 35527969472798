import React from 'react';
import Ico from 'samples/types/Ico';
import WSRIcosTableRow from 'samples/create/components/arrival_sample/WSRIcosTableRow';

export interface ISelectableArrivalSampleIco extends Ico {
  selected: boolean;
}

interface IWSRIcosTableProps {
  icos: ISelectableArrivalSampleIco[];
  onSelect: (index: number, ico: ISelectableArrivalSampleIco) => void;
  onDeselect: (ico: ISelectableArrivalSampleIco) => void;
}

const WSRIcosTable = ({
  icos,
  onSelect,
  onDeselect,
}: IWSRIcosTableProps): JSX.Element => {
  return (
    <table data-cy='WSRIcosTable'>
      <thead>
        <tr>
          <th className='table-border align-left'>
            {I18n.translate('activerecord.models.ico')} |{' '}
            {I18n.translate('activerecord.models.contract')}
          </th>

          <th className='table-border'>
            {I18n.translate('activerecord.models.quality')} |{' '}
            {I18n.translate('activerecord.models.mark')}
          </th>

          <th className='table-border'>
            {I18n.translate('activerecord.models.customer')} |{' '}
            {I18n.translate('attributes.bin')}
          </th>

          <th className='table-border'>
            {I18n.translate('activerecord.models.packaging_type')} |{' '}
            {I18n.translate('attributes.quantity')}
          </th>

          <th className=''>{I18n.translate('buttons.request_samples')}</th>
        </tr>
      </thead>

      <tbody>
        {icos.map((ico, index) => {
          return (
            <WSRIcosTableRow
              ico={ico}
              key={ico.id}
              onSelect={() => onSelect(index, ico)}
              onDeselect={() => onDeselect(ico)}
            />
          );
        })}
      </tbody>
    </table>
  );
};
export default WSRIcosTable;
