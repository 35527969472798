import React, { useState, useMemo } from 'react';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';

import Overlay from 'shared/Overlay';
import FinalizeMillingRowComponent, {
  QuantityColumn,
} from './FinalizeMillingRowComponent';
import InputGroupComponent from 'components/groups/InputGroup';
import { addTestingIdentifier } from 'utils/testing';

export interface FinalizeMillingOrderOverlayProps {
  id: string;
  totalWeight: number;
  identifier: string;
  resultsConfiguration: ResultsConfiguration;
}

type ResultConfig = {
  location: boolean;
  readOnly: boolean;
};

type ResultsConfiguration = {
  [key: string]: ResultConfig;
};

export type CompletedTypes = {
  [key: string]: null | MillingTypeResult;
};

export interface MillingTypeResult {
  area?: string;
  bin?: string;
  level?: string;
  position?: string;
  quantity: string;
}

const FinalizeMillingOrderOverlay = ({
  id,
  identifier,
  totalWeight,
  resultsConfiguration,
}: FinalizeMillingOrderOverlayProps) => {
  const [showOverlay, setOverlay] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const initialComplete = Object.fromEntries(
    Object.keys(resultsConfiguration).map((key) => [key, null])
  );

  const [completed, setCompleted] = useState<CompletedTypes>(initialComplete);

  const handleFinalizeClick = () => {
    setOverlay(true);
  };

  const handleOnPrimaryClick = () => {
    setSubmitted(true);
    customFetch(Routes.finalise_api_v1_milling_order_path(id), {
      milling_outputs: completed,
    }).then(() => {
      window.location.reload();
    });
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  const handleOnComplete = (
    type: keyof CompletedTypes,
    values: MillingTypeResult | null
  ) => {
    setCompleted((prevCompleted) => {
      return {
        ...prevCompleted,
        [type]: values,
      };
    });
  };

  const completedWeight = useMemo(
    () =>
      Object.entries(completed).reduce((memo, [_type, attributes]) => {
        return (memo += attributes?.quantity ? parseFloat(attributes?.quantity) : 0);
      }, 0),
    [completed]
  );

  const locationFilled = useMemo(
    () =>
      Object.entries(completed).every(([key, item]) => {
        const itemConfiguration = resultsConfiguration[key];

        if (itemConfiguration.location && item) {
          const { area, bin, level, position } = item;

          return area && bin && level && position;
        } else {
          return true;
        }
      }),
    [completed]
  );

  const conformantPasillaWeight = Math.max(totalWeight - completedWeight, 0);

  const submitButtonDisabled =
    !(completedWeight > 0.0) ||
    completedWeight > totalWeight ||
    !locationFilled ||
    submitted;
  submitted;

  return (
    <>
      {showOverlay && (
        <Overlay
          title={I18n.translate('milling_orders.finalise_milling.title')}
          titlePrefix={identifier}
          primaryDisabled={submitButtonDisabled}
          primaryText={I18n.translate('milling_orders.finalise_milling.title')}
          onPrimaryClick={handleOnPrimaryClick}
          secondaryText={I18n.translate('buttons.cancel')}
          onSecondaryClick={handleOnSecondaryClick}
        >
          <table className='table table--inverted-stripes'>
            <thead>
              <tr>
                <th className='table-border'>
                  {I18n.translate('milling_orders.finalise_milling.type')}
                </th>
                <th className='table-border'>
                  {I18n.translate('milling_orders.finalise_milling.area')}
                </th>
                <th className='table-border'>
                  {I18n.translate('milling_orders.finalise_milling.bin')}
                </th>
                <th className='table-border'>
                  {I18n.translate('milling_orders.finalise_milling.level')}
                </th>
                <th className='table-border'>
                  {I18n.translate('milling_orders.finalise_milling.position')}
                </th>
                <th>
                  {I18n.translate('milling_orders.finalise_milling.total_produced')}
                </th>
              </tr>
            </thead>

            <tbody>
              {Object.entries(resultsConfiguration).map(
                ([type, { location, readOnly }]) => (
                  <FinalizeMillingRowComponent
                    key={type}
                    type={type as keyof CompletedTypes}
                    values={completed[type] || { quantity: '0' }}
                    withLocation={location}
                    readOnly={readOnly}
                    onComplete={handleOnComplete}
                    maxWeight={totalWeight}
                  />
                )
              )}
              <tr>
                <td colSpan={5} className='table-border'>
                  {I18n.translate(
                    'milling_orders.finalise_milling.types.conformant_pasilla'
                  )}
                </td>
                <QuantityColumn>
                  <InputGroupComponent
                    readOnly={true}
                    noMargin={true}
                    append='kg'
                    value={conformantPasillaWeight}
                    dataCy='conformant_pasilla_input'
                  />
                </QuantityColumn>
              </tr>
            </tbody>
          </table>
        </Overlay>
      )}

      <button
        onClick={handleFinalizeClick}
        type='button'
        className='button button--small button--ghost'
        {...addTestingIdentifier('finalize_milling_button')}
      >
        {I18n.translate('buttons.finalize')}
      </button>
    </>
  );
};

export default FinalizeMillingOrderOverlay;
