import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import useStores from 'utils/useStores';

import SelectOptionArray from 'types/model/selectOption';

import Tile, { TileHeader } from 'shared/Tile';

import SpaceBetween from 'components/elements/SpaceBetween';
import BaseGroupComponent from 'components/groups/BaseGroup';
import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';

import UnloadLegCard from './UnloadLegCard';

export interface UnloadTransportProps {
  persons: SelectOptionArray;
}

const LegWrapper = styled.div<{ enabled: boolean }>`
  opacity: ${(props) => (props.enabled ? 1 : 0.25)};
  pointer-events: ${(props) => (props.enabled ? 'auto' : 'none')};
`;

const TransportFields = styled.div`
  display: flex;

  > div {
    margin-right: 1em;
  }

  > div:first-child {
    width: 11em;
  }
`;

const UnloadTransport = ({ persons }: UnloadTransportProps) => {
  const { unloadStore } = useStores();

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (persons.length > 0) {
      // @ts-ignore: Wrongly typed Options
      unloadStore.setReceivedById(persons[0].id);
    }
  }, []);

  const renderLegs = () => {
    return unloadStore.unloadLegs.map((leg) => {
      return <UnloadLegCard key={leg.id} leg={leg} />;
    });
  };

  const renderSaveBaseDataButton = () => {
    if (!unloadStore.baseDataSaved) {
      return (
        <BaseGroupComponent emptyLabel={true}>
          <button
            className='button'
            onClick={() => unloadStore.setBaseDataSaved(true)}
            disabled={!unloadStore.baseDataIsFilled}
          >
            {I18n.translate('buttons.save')}
          </button>
        </BaseGroupComponent>
      );
    }
  };

  const handleCloseTransport = async () => {
    try {
      setSubmitted(true);
      await unloadStore.submit();
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitted(false);
    }
  };

  const renderCompleteTransportInputs = () => {
    if (unloadStore.baseDataSaved) {
      return (
        <>
          <InputGroupComponent
            readOnly={!unloadStore.unloaded}
            label={I18n.translate('transports.receive.truck_net_weight')}
            data-cy='truck_net_weight'
            type='number'
            append='kg'
            value={unloadStore.truckNetWeight || ''}
            onChange={(e) => unloadStore.setTruckNetWeight(parseInt(e.target.value, 10))}
          />
          <BaseGroupComponent emptyLabel={true}>
            <button
              className='button'
              onClick={handleCloseTransport}
              disabled={!unloadStore.truckNetWeight || submitted}
            >
              {I18n.translate('transport.receive.close_transport')}
            </button>
          </BaseGroupComponent>
        </>
      );
    }
  };

  return (
    <>
      <Tile>
        <TileHeader>
          <SpaceBetween>
            <TransportFields>
              <InputGroupComponent
                readOnly={unloadStore.baseDataSaved}
                label={I18n.translate('transports.receive.truck_gross_weight')}
                type='number'
                append='kg'
                data-cy='truck_gross_weight'
                value={unloadStore.truckGrossWeight || ''}
                onChange={(e) =>
                  unloadStore.setTruckGrossWeight(parseInt(e.target.value, 10))
                }
              />

              {persons.length === 1 ? (
                <InputGroupComponent
                  readOnly
                  label={I18n.translate('transports.receive.responsible_for_receiving')}
                  data-cy='responsible_for_receiving'
                  value={persons[0].name}
                />
              ) : (
                <SelectGroup
                  disabled={unloadStore.baseDataSaved}
                  label={I18n.translate('transports.receive.responsible_for_receiving')}
                  data-cy='responsible_for_receiving'
                  placeholder={I18n.translate(
                    'transports.create.select_a_person_placeholder'
                  )}
                  options={persons}
                  value={unloadStore.receivedById}
                  onChange={(e) =>
                    unloadStore.setReceivedById(parseInt(e.target.value, 10))
                  }
                />
              )}

              {renderSaveBaseDataButton()}
            </TransportFields>
            <TransportFields>{renderCompleteTransportInputs()}</TransportFields>
          </SpaceBetween>
        </TileHeader>
      </Tile>
      <LegWrapper enabled={unloadStore.baseDataSaved}>{renderLegs()}</LegWrapper>
    </>
  );
};

export default observer(UnloadTransport);
