import { inject, observer } from 'mobx-react';
import React from 'react';

import WetReceptionStore from 'wetCoffee/stores/WetReceptionStore';

import BaseWetInfo from 'wetCoffee/components/BaseWetInfo';
import Location from 'wetCoffee/components/Location';
import Drying from 'wetCoffee/components/Drying';
import Proceed from 'wetCoffee/components/Proceed';
import WetLotModel from 'wetCoffee/models/WetLot';

interface WetTableRowProps {
  wetReceptionStore?: WetReceptionStore;
}

const WetTableRowsComponent: React.FC<WetTableRowProps> = ({ wetReceptionStore }) => {
  if (!wetReceptionStore) return null;

  const { lots, coffeeTypes } = wetReceptionStore;

  return (
    <>
      {lots.map((lot: WetLotModel) => (
        <tr key={lot.attributes.id}>
          <BaseWetInfo lot={lot.attributes} />
          <td className='table-border'>
            <Location lot={lot} />
          </td>
          <td>
            <Drying lot={lot} />
          </td>
          <td>
            <Proceed
              id={lot.attributes.id}
              daysSinceReceiving={lot.attributes.days_in_drying}
              canProceed={lot.canProceed}
              coffeeTypes={coffeeTypes}
            />
          </td>
        </tr>
      ))}
    </>
  );
};

export default inject('wetReceptionStore')(observer(WetTableRowsComponent));
