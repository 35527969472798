import AssignedLotsType from '../types/assignedLot';
import Details from './Details';
import React from 'react';
import SpaceBetween from 'components/elements/SpaceBetween';
import styled from 'styled-components';
import { IdentifierDetail } from './Detail';
import { TileList } from 'shared/Tile';

export interface AssignedLotsProps {
  assignedLot: AssignedLotsType;
}

export const PrimaryDetails = styled(Details)`
  grid-template-columns: 2.5em 8.125em minmax(4em, 1fr) 6em;
  grid-column-gap: 0.9375em;
`;

export const OriginDetails = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

// eslint-disable-next-line no-empty-pattern
function AssignedCoffee({ assignedLot }: AssignedLotsProps) {
  return (
    <TileList>
      <PrimaryDetails>
        <div></div>
        <IdentifierDetail>{assignedLot.identifier}</IdentifierDetail>
        <div></div>
      </PrimaryDetails>

      <OriginDetails>{assignedLot.type}</OriginDetails>

      <SpaceBetween>
        {' '}
        {assignedLot.weight} {assignedLot.unit_of_measure}
      </SpaceBetween>
    </TileList>
  );
}

export default AssignedCoffee;
