import React from 'react';
import SelectOptionArray from 'types/model/selectOption';

import TraceabilityBody from './TraceabilityBody';
import TraceabilityHeader from './TraceabilityHeader';
import { LotTraceability, LotSetTraceability } from 'traceability/types/traceability';

export interface TraceabilityItemProps extends React.HTMLAttributes<HTMLDivElement> {
  traceability: LotTraceability | LotSetTraceability;
  specialMarks: SelectOptionArray;
}

function TraceabilityItem({ traceability, specialMarks }: TraceabilityItemProps) {
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <div>
      <TraceabilityHeader
        traceability={traceability}
        collapsed={collapsed}
        onCollapsibleHandler={(_event: React.MouseEvent) => setCollapsed(!collapsed)}
        specialMarks={specialMarks}
      />

      {!collapsed && (
        <TraceabilityBody
          traceability={traceability}
          collapsed={collapsed}
          sample={traceability.sample}
        />
      )}
    </div>
  );
}

export default TraceabilityItem;
