import Routes from 'routes';

import SampleBuilder from 'utils/builders/SampleBuilder';
import HumidityAnalysis from 'productionOrders/models/HumidityAnalysis';
import HumidityValidations from 'productionOrders/models/HumidityValidations';
import ISample from 'millingOrders/types/ISample';

class DecafSampleBuidler extends SampleBuilder {
  public constructor(protected sample: ISample, private decafOrderId: number) {
    super(sample);
  }

  public createHumidityAnalysis = (): HumidityAnalysis => {
    const { id, humidity_analysis_validations, current_humidity_analysis } = this.sample;

    const humidityValidations = new HumidityValidations(humidity_analysis_validations);

    return new HumidityAnalysis(
      id,
      current_humidity_analysis,
      humidityValidations,
      Routes.api_v1_decaf_decaf_order_humidity_analyses_path(this.decafOrderId)
    );
  };
}

export default DecafSampleBuidler;
