import { Provider } from 'mobx-react';
import React from 'react';

import AsyncProductionOrdersStore from 'millings/productionOrders/AsyncProductionOrder/stores/AsyncProductionOrdersStore';
import ProductionOrdersComponent from 'millings/productionOrders/AsyncProductionOrder/components/ProductionOrders';
import SelectOptionArray from 'types/model/selectOption';
import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';
import ProductionOrderStore from '../stores/ProductionOrderStore';
import { JsonApiResource } from 'types/utils/jsonApi';
import Mark from 'types/model/Mark';

interface ProductionOrdersContainerProps {
  years: SelectOptionArray;
  states: SelectOptionArray;
  marks: JsonApiResource<Mark>;
}

function ProductionOrdersContainer({
  years,
  states,
  marks,
}: ProductionOrdersContainerProps) {
  return (
    <AbilityContext.Provider value={Ability}>
      <Provider
        asyncProductionOrdersStore={new AsyncProductionOrdersStore(states, years)}
        productionOrderStore={new ProductionOrderStore(marks)}
      >
        <ProductionOrdersComponent />
      </Provider>
    </AbilityContext.Provider>
  );
}

export default ProductionOrdersContainer;
