import { observable } from 'mobx';

import Sample from './Sample';
import SampleRowEntity from './SampleRowEntity';
import SpotOpportunityType from 'customerSamples/types/SpotOpportunityType';

export class SpotOpportunity extends SampleRowEntity {
  @observable public samples: Sample[];

  constructor(spotOpportunity: SpotOpportunityType) {
    super(spotOpportunity);

    const deserializedSamples: Sample[] = spotOpportunity.samples.map(
      (sample) => new Sample(sample)
    );
    this.samples = observable.array(deserializedSamples);
  }
}

export default SpotOpportunity;
