import React, { useState } from 'react';

import MainContactInfo from '../customers/components/MainContactInfo';
import { IField } from 'components/shared/form/IField';
import Checkbox from 'shared/Checkbox/components/Checkbox';
import SubmitRow from 'customers/components/SubmitRow';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { CustomerLead } from './model/CustomerLead';
import { deserialise } from 'kitsu-core';
import CustomerLeadType from 'types/model/customerLead';
import { JsonApi } from 'types/utils/jsonApi';
import Routes from 'routes';
import Icon from 'components/elements/Icon';
export interface LeadFormProps {
  lead: JsonApi<CustomerLeadType>;
  fields: IField[];
  saveButtonText: string;
  view: boolean;
}

const LeadForm = ({ lead, fields, saveButtonText, view }: LeadFormProps) => {
  const customerLead = new CustomerLead(deserialise(lead).data);
  const mainContactInfoId = customerLead.mainContactInfo?.id;
  const secondaryContactInfoId = customerLead.secondaryContactInfo?.id;
  const [createSecondary, setCreateSecondary] = useState(
    secondaryContactInfoId ? true : false
  );

  const removeContactInfo = (id: number, type: string) => {
    const removeInfoText = () => {
      if (type === 'main') {
        return 'Main Contact Info';
      } else {
        return 'Secondary Contact Info';
      }
    };

    const removeText = `${I18n.translate('buttons.remove')} ${removeInfoText()}`;
    return (
      <FieldSetWithTitle title={''}>
        <a
          data-confirm='Are you sure?'
          rel='nofollow'
          data-method='delete'
          href={Routes.contact_info_path(id)}
          className='button button--small button--required'
        >
          <Icon icon='cross' />
          {removeText}
        </a>
      </FieldSetWithTitle>
    );
  };

  return (
    <>
      <MainContactInfo data={customerLead} type='main' fields={fields} />
      {mainContactInfoId &&
        secondaryContactInfoId &&
        removeContactInfo(mainContactInfoId, 'main')}

      <FieldSetWithTitle title={''}>
        <Checkbox
          label={I18n.translate('leads.form.secondary_contact_question')}
          name={'customer[address_equals_billing_address]'}
          icon='tick'
          checked={createSecondary}
          onChange={() => setCreateSecondary(!createSecondary)}
        />
      </FieldSetWithTitle>

      {createSecondary && (
        <>
          <MainContactInfo data={customerLead} type={'secondary'} fields={fields} />
          {secondaryContactInfoId &&
            removeContactInfo(secondaryContactInfoId, 'secondary')}
        </>
      )}

      <SubmitRow
        data={customerLead}
        view={view}
        cancelPath={'/'}
        saveButtonText={saveButtonText}
      />
    </>
  );
};

export default LeadForm;
