import React from 'react';
import Ico from 'samples/types/Ico';
import WSRIcosTable from 'samples/create/components/arrival_sample/WSRIcosTable';
import WSRSelectionTable from '../warehouse_sample_request/WSRSelectionTable';

export interface WSRArrivalSampleSelectionTableProps {
  icos: Ico[];
}

const WSRArrivalSampleSelectionTable = ({
  icos,
}: WSRArrivalSampleSelectionTableProps): JSX.Element => {
  return (
    <WSRSelectionTable<Ico>
      resources={icos}
      resourceFormName='warehouse_sample_request_icos'
      resourceTitle='ICOs'
      resourceIdName='ico_id'
    >
      {(insert, deselect, selectedIcos) => (
        <WSRIcosTable onSelect={insert} onDeselect={deselect} icos={selectedIcos} />
      )}
    </WSRSelectionTable>
  );
};
export default WSRArrivalSampleSelectionTable;
