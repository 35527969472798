import ContentHeader from 'components/elements/ContentHeader';
import FieldSet from 'components/elements/FieldSet';
import { MultiOptions, Option } from 'components/elements/Search';
import SpaceBetween from 'components/elements/SpaceBetween';
import SelectGroup from 'components/groups/SelectGroup';
import React, { createContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Routes from 'routes';
import CreateArrivalSample from 'samples/create/components/CreateArrivalSample';
import CreateAuditSample from 'samples/create/components/CreateAuditSample';
import CreateExternalSample from 'samples/create/components/CreateExternalSample';
import CreateMillSample from 'samples/create/components/CreateMillSample';
import CreateOfferSample from 'samples/create/components/CreateOfferSample';
import CreateSpotSample from 'samples/create/components/CreateSpotSample';
import ProducerToggleButton from 'samples/create/components/ProducerToggleButton';
import Errors from 'shared/Errors/components/Errors';
import styled from 'styled-components';
import SelectOptionArray from 'types/model/selectOption';
import Weight from 'types/model/weight';
import { customFetch } from 'utils/fetch';

const InputWrapper = styled.div`
  > div:not('.l-item-stack-horizontal') {
    margin-bottom: 1em;
  }

  .l-item-stack-horizontal > div {
    width: 50%;
  }
`;

interface CreateSampleProps {
  types: SelectOptionArray;
  producers: Option[];
  lots_and_sets: MultiOptions[];
  coffee_types: SelectOptionArray;
  opportunities: Option[];
  marks: Option[];
}

const SAMPLE_TYPES = {
  PURCHASE: '0',
  WET_RECEPTION: '5',
  LAB: '10',
  OFFER: '20',
  TYPE: '30',
  EXTERNAL: '40',
  RANDOM: '50',
  MIX: '60',
  AUDIT: '70',
  PRE_PRODUCTION: '80',
  PRE_SHIPMENT: '120',
  FRACTION: '140',
  FRACTION_REPROCESS: '160',
  MILLING_OUTPUT: '180',
  DECAF: '200',
  ARRIVAL: '220',
  MILL: '240',
  SPOT: '260',
} as const;

const SampleTypeConfiguration: Record<
  string,
  {
    mainComponent: JSX.Element;
    headerComponent?: JSX.Element;
    endpointFn: () => string;
    width?: string;
    submitText?: string;
  }
> = {
  OFFER: {
    mainComponent: <CreateOfferSample />,
    endpointFn: Routes.samples_path,
  },
  EXTERNAL: {
    mainComponent: <CreateExternalSample />,
    headerComponent: <ProducerToggleButton />,
    endpointFn: Routes.samples_path,
  },
  // Uses the exact same mechanism like the auditsample. Duplicate if it diverges
  LAB: {
    mainComponent: <CreateAuditSample />,
    endpointFn: Routes.samples_path,
  },
  AUDIT: {
    mainComponent: <CreateAuditSample />,
    endpointFn: Routes.samples_path,
  },
  ARRIVAL: {
    mainComponent: <CreateArrivalSample />,
    endpointFn: Routes.api_v1_samples_create_arrival_sample_path,
    width: '100%',
    submitText: I18n.translate('buttons.create'),
  },
  MILL: {
    mainComponent: <CreateMillSample />,
    endpointFn: Routes.api_v1_samples_create_mill_sample_path,
  },
  SPOT: {
    mainComponent: <CreateSpotSample />,
    endpointFn: Routes.api_v1_samples_create_spot_sample_path,
    width: '100%',
    submitText: I18n.translate('buttons.create'),
  },
};

export type SampleFormValues = {
  sample: {
    base_weight: Weight;
    coffee_type_id: string;
    observation: string;
    producer_id: number;
    sample_type: number;
  };
};

// Before refactoring all the endpoints, use context
export const SampleDataContext = createContext<
  Pick<
    CreateSampleProps,
    'producers' | 'lots_and_sets' | 'coffee_types' | 'opportunities' | 'marks'
  >
>({
  producers: [],
  lots_and_sets: [],
  coffee_types: [],
  opportunities: [],
  marks: [],
});

function CreateSample({
  types,
  producers,
  lots_and_sets,
  coffee_types,
  opportunities,
  marks,
}: CreateSampleProps) {
  const [fetching, setFetching] = useState(false);
  const [type, setType] = useState<any>();
  const [errors, setErrors] = useState(Array<string>());

  const methods = useForm<SampleFormValues>({ mode: 'onChange' });
  const {
    formState: { isSubmitting, isValid },
  } = methods;

  const config:
    | typeof SampleTypeConfiguration[keyof typeof SampleTypeConfiguration]
    | undefined = useMemo(() => {
    const sampleType =
      type &&
      (Object.keys(SAMPLE_TYPES).find(
        (key) => SAMPLE_TYPES[key] === type
      ) as keyof typeof SAMPLE_TYPES);
    return sampleType && SampleTypeConfiguration[sampleType];
  }, [type]);

  const components = useMemo(() => {
    return {
      MainComponent: config?.['mainComponent'] || null,
      HeaderComponent: config?.['headerComponent'] || null,
    };
  }, [config]);

  const onSubmit = async (data: SampleFormValues): Promise<void> => {
    setFetching(true);

    return customFetch(config!.endpointFn(), data)
      .then((response) => {
        if (response.success || (!response.success && response.path)) {
          window.location = response.path;
        } else {
          setFetching(false);
          setErrors(response.errors);
        }
      })
      .catch((reason) => {
        setFetching(false);
        alert(`something went wrong, please reload the page and try again: ${reason}`);
      });
  };

  return (
    <div className='content'>
      <ContentHeader title={I18n.translate('headlines.create_sample')} />

      <Errors errors={errors} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FieldSet width={config?.width || 34} fetching={fetching}>
            <InputWrapper>
              <div className='l-item-stack-horizontal l-item-stack-horizontal--wide'>
                <SelectGroup
                  label={I18n.translate('samples.create.sample_type')}
                  placeholder={I18n.translate('samples.create.sample_type_placeholder')}
                  onChange={(e) => setType(e.target.value as any)}
                  value={type}
                  data-cy='select_sample_type'
                  options={types}
                  name='sample[sample_type]'
                  ref={methods.register}
                />

                {/* Allow ProducerComponent to render Toggle Button here */}
                {components.HeaderComponent}
              </div>
              <SampleDataContext.Provider
                value={{
                  producers: producers,
                  lots_and_sets: lots_and_sets,
                  coffee_types: coffee_types,
                  opportunities: opportunities,
                  marks: marks,
                }}
              >
                {components.MainComponent}
              </SampleDataContext.Provider>
            </InputWrapper>
          </FieldSet>

          <FieldSet width={config?.width || 34}>
            <SpaceBetween>
              <a
                className='button button--gray'
                href={Routes.analyse_and_adjust_samples_path()}
              >
                {I18n.translate('buttons.cancel')}
              </a>

              <button
                disabled={fetching || !config || isSubmitting || !isValid}
                data-cy='samples_create_and_print_button'
                type='submit'
                className='button'
              >
                {config?.submitText || I18n.translate('buttons.create_and_print')}
              </button>
            </SpaceBetween>
          </FieldSet>
        </form>
      </FormProvider>
    </div>
  );
}

export default CreateSample;
