// import { observable } from 'mobx';
import { deserialise } from 'kitsu-core';

import { ReasonGet } from 'types/model/reason';
import { ExcelsoPhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';
import NormType from 'types/model/norm';
import SpecialtyGradingSpecificationType from 'types/model/specialtyGradingSpecification';

import ExcelsoPhysicalAnalysis from '../models/ExcelsoPhysicalAnalysis';
import SpecialtyGradingSpecification from '../models/SpecialtyGradingSpecification';
import Norm from '../models/Norm';
import Sample from 'productionOrders/models/Sample';
import { createSampleFactory } from 'utils/factories/createSampleFactory';
import { JsonApi } from 'types/utils/jsonApi';
import SampleBuilder from 'utils/builders/SampleBuilder';
import ISample from 'millingOrders/types/ISample';

class ExcelsoPhysicalAnalysisStore {
  public sample: Sample;
  public norm: Norm;
  public specialtyGradingSpecifications: SpecialtyGradingSpecification[];
  public excelsoPhysicalAnalysis: ExcelsoPhysicalAnalysis;
  public reasons: ReasonGet[];
  public cancelUrl: string;

  constructor(
    sample: JsonApi<ISample>,
    norm: JsonApi<NormType>,
    physicalAnalysis: ExcelsoPhysicalAnalysisType,
    specialtyGradingSpecifications: SpecialtyGradingSpecificationType[],
    reasons: ReasonGet[],
    cancelUrl: string
  ) {
    const deserializedSample = deserialise(sample).data as ISample;
    const deserializedNorm = deserialise(norm).data as NormType;
    const sampleBuilder = new SampleBuilder(deserializedSample);

    this.sample = createSampleFactory(deserializedSample, sampleBuilder);
    this.norm = new Norm(deserializedNorm);
    this.reasons = reasons;
    this.cancelUrl = cancelUrl;

    this.specialtyGradingSpecifications = deserialise(
      specialtyGradingSpecifications
    ).data.map((grading) => new SpecialtyGradingSpecification(grading));

    const deserialisedPhysicalAnalysis = deserialise(physicalAnalysis).data;

    this.excelsoPhysicalAnalysis = new ExcelsoPhysicalAnalysis(
      this.sample.id,
      deserialisedPhysicalAnalysis,
      this.specialtyGradingSpecifications,
      this.norm
    );
  }
}

export default ExcelsoPhysicalAnalysisStore;
