import { IField } from 'components/shared/form/IField';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getField } from './formHelpers';

type StatefulPriceField = PriceField & {
  set: React.Dispatch<React.SetStateAction<string>>;
};

type PriceField = {
  field: IField;
  value: string;
};

type PriceContext = {
  fixedPriceType?: PriceField;
  importMarkup?: StatefulPriceField;
  otherMarkups?: StatefulPriceField;
  fobPrice?: StatefulPriceField;
  spotIcoCost?: StatefulPriceField;
  fixedPrice?: StatefulPriceField;
  differentialPrice?: StatefulPriceField;
  opportunityPrice?: StatefulPriceField;
  internalContractPrice?: StatefulPriceField;
};

export const PriceContext = createContext<PriceContext>({});

export const usePriceContext = () => useContext(PriceContext);

function createPrice(field: IField): PriceField {
  return {
    field,
    value: field.value ?? '0.0',
  };
}

function createStatefulPrice(field: IField): StatefulPriceField {
  const { value } = createPrice(field);

  const [price, setPrice] = useState(value);

  return {
    field,
    value: price,
    set: setPrice,
  };
}

export const usePrice = (fields: IField[]): PriceContext => {
  let fixedPriceType: undefined | PriceField;
  let importMarkup: undefined | StatefulPriceField;
  let otherMarkups: undefined | StatefulPriceField;
  let fobPrice: undefined | StatefulPriceField;
  let spotIcoCost: undefined | StatefulPriceField;
  let fixedPrice: undefined | StatefulPriceField;
  let differentialPrice: undefined | StatefulPriceField;
  let opportunityPrice: undefined | StatefulPriceField;
  let internalContractPrice: undefined | StatefulPriceField;

  const fixedPriceTypeField = getField(fields, 'price_type');
  const importMarkupField = getField(fields, 'import_markup');
  const otherMarkupsField = getField(fields, 'other_markups');
  const fobPriceField = getField(fields, 'fob_price');
  const spotIcoCostField = getField(fields, 'spot_ico_cost');
  const fixedPriceField = getField(fields, 'fixed_price');
  const differentialField = getField(fields, 'differential');
  const opportunityPriceField = getField(fields, 'opportunity_price');
  const internalContractPriceField = getField(fields, 'internal_contract_price');

  if (fixedPriceTypeField) {
    fixedPriceType = createPrice(fixedPriceTypeField);
  }

  if (importMarkupField) {
    importMarkup = createStatefulPrice(importMarkupField);
  }

  if (otherMarkupsField) {
    otherMarkups = createStatefulPrice(otherMarkupsField);
  }

  if (fobPriceField) {
    fobPrice = createStatefulPrice(fobPriceField);
  }

  if (spotIcoCostField) {
    spotIcoCost = createStatefulPrice(spotIcoCostField);
  }

  if (fixedPriceField) {
    fixedPrice = createStatefulPrice(fixedPriceField);
  }

  if (differentialField) {
    differentialPrice = createStatefulPrice(differentialField);
  }

  if (opportunityPriceField) {
    opportunityPrice = createStatefulPrice(opportunityPriceField);
  }

  if (internalContractPriceField) {
    internalContractPrice = createStatefulPrice(internalContractPriceField);
  }

  useEffect(() => {
    const finalPrice =
      ((importMarkup && parseFloat(importMarkup.value)) || 0) +
      ((otherMarkups && parseFloat(otherMarkups.value)) || 0) +
      ((fobPrice && parseFloat(fobPrice.value)) || 0);

    const formattedFinalPrice = finalPrice.toFixed(2).toString();

    if (fixedPriceType?.value === 'fixed') {
      fixedPrice?.set(formattedFinalPrice);
    } else {
      differentialPrice?.set(formattedFinalPrice);
    }
  }, [importMarkup, otherMarkups, fobPrice]);

  return {
    fixedPriceType,
    importMarkup,
    otherMarkups,
    fobPrice,
    spotIcoCost,
    fixedPrice,
    differentialPrice,
    opportunityPrice,
    internalContractPrice,
  };
};

export const PriceProvider = ({
  value,
  children,
}: {
  value: PriceContext;
  children: React.ReactElement;
}) => {
  return <PriceContext.Provider value={value}>{children}</PriceContext.Provider>;
};
