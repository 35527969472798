import { action, computed, observable } from 'mobx';
import { deserialise } from 'kitsu-core';

import Routes from 'routes';
import { customFetch } from 'utils/fetch';

import CustomerSampleValueType from 'types/model/ICustomerSampleValue';
import CustomerSampleReason from 'customerSamples/models/CustomerSampleReason';

export interface CustomerSampleValueFormData {
  humidity_analysis_percentage?: number;
  water_analysis_percentage?: number;
  sensorial_score?: number;
  customer_sample_reason_id?: number;
  observations?: string;
  id?: number;
  sample_id?: number;
}

export interface SendToCustomerChangeRequestData {
  send_to_customer: boolean;
  sample_id: number;
  id?: number;
}

export class CustomerSampleValue {
  public sampleId: number;
  public id?: number;
  @observable public aasmState?: string;
  @observable public humidityAnalysisPercentage?: number;
  @observable public waterAnalysisPercentage?: number;
  @observable public sensorialScore?: number;
  @observable public observations?: string;
  @observable public sendToCustomer?: boolean;
  @observable public outToCustomer: boolean;
  @observable public customerSampleReasonId?: number;
  @observable public customerSampleReason?: CustomerSampleReason;

  constructor(customerSample: CustomerSampleValueType, sampleId: number) {
    this.sampleId = sampleId;

    if (customerSample) {
      this.id = customerSample.id;
      this.sampleId = customerSample.sample_id;
      this.humidityAnalysisPercentage = customerSample.humidity_analysis_percentage;
      this.waterAnalysisPercentage = customerSample.water_analysis_percentage;
      this.sensorialScore = customerSample.sensorial_score;
      this.customerSampleReasonId = customerSample.customer_sample_reason_id;
      this.aasmState = customerSample.aasm_state;
      this.sendToCustomer = customerSample.send_to_customer;
      this.outToCustomer = customerSample.out_to_customer;
      this.observations = customerSample.observations;
      this.customerSampleReason = customerSample.customer_sample_reason
        ? new CustomerSampleReason(customerSample.customer_sample_reason)
        : undefined;
    }
  }

  @action async save(
    data: CustomerSampleValueFormData | SendToCustomerChangeRequestData
  ): Promise<void> {
    const method = this.id ? 'PATCH' : 'POST';
    const route = this.id
      ? Routes.api_v1_customer_samples_customer_sample_value_path(this.id)
      : Routes.api_v1_customer_samples_customer_sample_values_path();

    const response = await customFetch(
      route,
      {
        customer_sample_values: data,
      },
      method
    );

    if (response.success) {
      const values: CustomerSampleValueType = deserialise(
        response.customer_sample_value
      ).data;

      this.id = values.id;
      this.sampleId = values.sample_id;
      this.humidityAnalysisPercentage = values.humidity_analysis_percentage;
      this.waterAnalysisPercentage = values.water_analysis_percentage;
      this.sensorialScore = values.sensorial_score;
      this.customerSampleReasonId = values.customer_sample_reason_id;
      this.aasmState = values.aasm_state;
      this.sendToCustomer = values.send_to_customer;
      this.outToCustomer = values.out_to_customer;
      this.observations = values.observations;
      this.customerSampleReason = values.customer_sample_reason
        ? new CustomerSampleReason(values.customer_sample_reason)
        : undefined;
    } else {
      // Show Flash Message
      window.location.reload();
    }
  }

  @computed get showEnterResults(): boolean {
    return (
      !!this.sensorialScore ||
      !!this.humidityAnalysisPercentage ||
      !!this.waterAnalysisPercentage ||
      !!this.customerSampleReasonId
    );
  }

  @computed get isLocked(): boolean {
    return (
      !!this.sensorialScore &&
      !!this.humidityAnalysisPercentage &&
      !!this.waterAnalysisPercentage &&
      !!this.customerSampleReasonId
    );
  }
}

export default CustomerSampleValue;
