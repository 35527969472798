import React from 'react';
import Routes from 'routes';

import type { Bundle } from '../stores/FutureStore';

export interface BundleActionProps {
  bundle: Bundle;
}

const BundleAction = ({ bundle }: BundleActionProps) => {
  if (bundle.shipperFixing || bundle.sellerFixing) {
    return null;
  } else {
    return (
      <a
        href={Routes.bundle_path(bundle.id)}
        rel='nofollow'
        data-method='delete'
        className='button button--small button--ghost'
      >
        {I18n.translate('future_price_fixing.unbundle')}
      </a>
    );
  }
};

export default BundleAction;
