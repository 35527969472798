import { Provider } from 'mobx-react';
import React from 'react';
import Routes from 'routes';

import Validation from 'types/utils/validations';
import OverlayStore from 'stores/OverlayStore';
import HumidityAnalysis from 'shared/HumidityAnalysis';

import { customFetch } from 'utils/fetch';
import SelectOptionArray from 'types/model/selectOption';

interface HumidityAnalysisOverlayContainerProps {
  identifier: string;
  validations: {
    humidity_green: Validation;
    water_activity: Validation;
    humidity_dry_parchment: Validation;
  };
  lotFailed: boolean;
  reasons: SelectOptionArray;
  humidityAnalysis?: any;
  lotId: string;
  text: string;
  icon: string;
  status: boolean;
}

function HumidityAnalysisOverlayContainer({
  humidityAnalysis,
  lotId,
  ...props
}: HumidityAnalysisOverlayContainerProps): JSX.Element {
  let storeParams = {};

  if (humidityAnalysis && humidityAnalysis.accepting_reason_id) {
    storeParams = {
      isFilled: true,
      overrideValidation: true,
      reason_id: humidityAnalysis.accepting_reason_id,
      valid: false,
    };
  }

  const handleOnPrimaryClick = (_event: React.MouseEvent, values: {}) =>
    customFetch(
      Routes.lot_humidity_analysis_path(lotId),
      values,
      humidityAnalysis ? 'PATCH' : 'POST'
    ).then(() => {
      window.location.reload();
    });

  return (
    <Provider overlayStore={new OverlayStore(storeParams)}>
      <HumidityAnalysis
        onPrimaryClick={handleOnPrimaryClick}
        humidityAnalysis={humidityAnalysis}
        {...props}
      />
    </Provider>
  );
}

export default HumidityAnalysisOverlayContainer;
