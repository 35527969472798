import { action, observable, computed } from 'mobx';
import { LotGet } from 'types/model/lot';

export class LotCalibrationModel {
  constructor(lot: LotGet) {
    this.lot = lot;
  }

  @observable public showOverlay = false;
  @observable public showErrorOverlay = false;
  @observable public bagType = -1;
  @observable public amountOfBags: number | null = null;
  @observable public createSample = false;
  @observable public startNumber: number | null = null;
  @observable public endNumber: number | null = null;
  @observable public color = '';
  @observable public errorMessage = null;
  public lot: LotGet;

  public validateTagNumbers = () => {
    if (
      this.bagType == -1 ||
      this.amountOfBags == null ||
      this.startNumber == null ||
      this.endNumber == null
    ) {
      return true;
    }
    return this.endNumber - this.startNumber === this.amountOfBags - 1;
  };

  @computed public get allFilled() {
    return this.startNumber !== null && this.endNumber !== null && this.color !== '';
  }

  @computed public get isPremiumGrade() {
    return this.lot.grade >= 900;
  }

  @computed public get submitEnabled() {
    let isEnabled = false;

    if (this.bagType && this.amountOfBags != null) {
      const areBagsConfigured = this.amountOfBags > 0 && this.bagType > 0;

      let isTagAssigned = !this.isPremiumGrade;

      if (this.isPremiumGrade) {
        isTagAssigned = !!this.startNumber && !!this.endNumber && !!this.color;
      }

      isEnabled = areBagsConfigured && isTagAssigned && this.validateTagNumbers();
    }

    return isEnabled;
  }

  @computed public get certification() {
    let certificate = this.lot.certification;

    if (this.lot.agreed_to_no_premium_price) {
      certificate = I18n.translate('attributes.conventional');
    }

    return certificate;
  }

  @action public resetTag = () => {
    this.startNumber = null;
    this.endNumber = null;
    this.color = '';
    return true;
  };

  @action public setShowOverlay = (state: boolean) => (this.showOverlay = state);

  @action public setShowErrorOverlay = (state: boolean) =>
    (this.showErrorOverlay = state);

  @action public setBagType = (value: number) => (this.bagType = value);

  @action public setAmountOfBags = (value: number) => (this.amountOfBags = value);

  @action public setCreateSample = (state: boolean) => (this.createSample = state);

  @action public setColor = (value: string) => (this.color = value);

  @action public setStartNumber = (value: number) => {
    this.startNumber = value;
    this.endNumber = value + (this.amountOfBags || 0) - 1;
  };
}

export default LotCalibrationModel;
