import styled from 'styled-components';

import { COLORS } from 'constants/colors';

const OverlayTitlePrefix = styled.span`
  color: ${COLORS.GRAY};
  padding-right: 0.5em;
`;

export default OverlayTitlePrefix;
