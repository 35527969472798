import React from 'react';
import { observer } from 'mobx-react';

import useStores from 'utils/useStores';

import AssignCoffeeHeadline from 'availableCoffee/components/AssignCoffeeHeadline';
import SelectedCoffee from 'availableCoffee/components/SelectedCoffee';
import AvailableCoffeTable from 'availableCoffee/components/AvailableCoffeTable';
import ActionBar from 'availableCoffee/components/ActionBar';

function AssignCoffee() {
  const { availableCoffeeStore } = useStores();

  const Wrapper = ({ children }) =>
    availableCoffeeStore.withTable ? (
      <div className='content'>{children}</div>
    ) : (
      <>{children}</>
    );

  return (
    <Wrapper>
      <AssignCoffeeHeadline />

      <SelectedCoffee withButton={!availableCoffeeStore.withTable} />

      {availableCoffeeStore.withTable && (
        <div className='l-item-stack l-item-stack--spaced'>
          <AvailableCoffeTable />

          <ActionBar />
        </div>
      )}
    </Wrapper>
  );
}

export default observer(AssignCoffee);
