import styled from 'styled-components';

const DefectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 0.875rem;

  .input-group {
    max-width: 50%;
  }

  .input-group:first-child {
    margin-right: 5px;
  }
`;

export default DefectWrapper;
