import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';

import SubproductItem, {
  IdCol,
  WeightCol,
  IcoCol,
  CoffeeTypeCol,
  InputCol,
} from './SubproductItem';

const SubproductsTable: React.FC = () => {
  const { subproductsStore } = useStores();

  const renderSubproductsRows = () => {
    return subproductsStore.subproducts.map((subproduct) => (
      <SubproductItem key={subproduct.attributes.identifier} subproduct={subproduct} />
    ));
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <IdCol as='th' className='align-left table-border'>
              ID
            </IdCol>

            <IcoCol as='th' className='align-left table-border'>
              {I18n.translate('subproducts.index.origin_ico')}
            </IcoCol>

            <th className='align-center table-border'>
              {I18n.translate('subproducts.index.quality')}
            </th>

            <CoffeeTypeCol as='th' className='table-border'>
              {I18n.translate('subproducts.index.coffee_type')}
            </CoffeeTypeCol>

            <WeightCol as='th' className='table-border'>
              {I18n.translate('subproducts.index.available_total_weight')}
            </WeightCol>

            <InputCol as='th'>
              {I18n.translate('subproducts.index.quantity_to_use')}
            </InputCol>
          </tr>
        </thead>

        <tbody>{renderSubproductsRows()}</tbody>
      </table>
    </>
  );
};

export default observer(SubproductsTable);
