import PaginatedTable from 'components/shared/PaginatedTable';
import { observer } from 'mobx-react';
import React from 'react';
import IConsolidateSample from 'samples/types/IConsolidateSample';
import ErrorsComponent from 'shared/Errors/components/Errors';
import styled from 'styled-components';
import useStores from 'utils/useStores';
import ConsolidateSampleSelectable from '../models/ConsolidateSampleSelectable';
import ActionForm from './ActionForm';
import FilterForm from './FilterForm';
import IcoSelectOverlay from './IcoSelectOverlay';
import OpportunitySelectOverlay from './OpportunitySelectOverlay';
import PaginatedSampleRow from './PaginatedSampleRow';

const ActionBar = styled.div`
  display: flex;
`;

const Errors = styled(ErrorsComponent)`
  margin-bottom: 1em;
`;

function ConsolidateSamples() {
  const { consolidateSamplesStore } = useStores();

  const renderTableHeader = () => {
    return (
      <tr>
        <th className='align-left'>{I18n.translate('samples.consolidate.type')}</th>

        <th className='table-border align-left'>
          {I18n.translate('samples.consolidate.source')}
        </th>

        <th className='selected-lots selected-lots__quality table-border'>
          {I18n.translate('attributes.quality')}
        </th>

        <th className='selected-lots selected-lots__weight table-border'>
          {I18n.translate('attributes.weight')}
        </th>

        <th className='selected-lots selected-lots__weight table-border'>
          {I18n.translate('samples.consolidate.source_weight')}
        </th>

        <th className='selected-lots selected-lots__certification table-border'>
          {I18n.translate('samples.consolidate.certification')}
        </th>

        <th className='selected-lots table-border'>
          {I18n.translate('samples.consolidate.process')}
        </th>

        <th className='selected-lots'>
          {I18n.translate('samples.consolidate.add_to_mix')}
        </th>
      </tr>
    );
  };

  const renderTableData = (sample: IConsolidateSample) => {
    return <PaginatedSampleRow key={sample.id} sample={dataRowMapper(sample)} />;
  };

  const dataRowMapper = (dataRow: IConsolidateSample): ConsolidateSampleSelectable => {
    const sample = consolidateSamplesStore.findSample(dataRow);

    return sample ? sample : new ConsolidateSampleSelectable(dataRow, false);
  };

  const renderSelectedSamples = () => {
    return consolidateSamplesStore.selectedSamples.length > 0 ? (
      <table>
        <tbody>
          {consolidateSamplesStore.selectedSamples.map((sample) => {
            return (
              <PaginatedSampleRow
                key={sample.attributes.id}
                sample={sample}
                readOnly={true}
              />
            );
          })}
        </tbody>
      </table>
    ) : (
      <></>
    );
  };

  const renderTopBar = () => {
    return (
      <ActionBar>
        <FilterForm />
        <ActionForm />
      </ActionBar>
    );
  };

  return (
    <>
      <Errors errors={consolidateSamplesStore.errors} />
      <PaginatedTable<IConsolidateSample>
        renderTableData={renderTableData}
        renderTableHeader={renderTableHeader}
        renderSelectedData={renderSelectedSamples}
        renderTopBar={renderTopBar}
        paginatedTableStore={consolidateSamplesStore.paginatedTableStore}
        hasSelectedData={consolidateSamplesStore.selectedSamples.length > 0}
      />
      {consolidateSamplesStore.showOpportunityOverlay && (
        <OpportunitySelectOverlay
          onCloseClick={() => consolidateSamplesStore.setShowOpportunityOverlay(false)}
        />
      )}

      {consolidateSamplesStore.showIcoOverlay && (
        <IcoSelectOverlay
          onCloseClick={() => consolidateSamplesStore.setShowIcoOverlay(false)}
        />
      )}
    </>
  );
}

export default observer(ConsolidateSamples);
