import React from 'react';
import { TileList } from 'shared/Tile';
import styled from 'styled-components';
import SpotContract from 'traceability/types/SpotContract';
import DeliveryOrderList from './DeliveryOrderList';
import Detail from './Detail';
import { ContractDetails } from './ContractDetails';

interface SpotContractItemProps {
  spotContract: SpotContract;
  className?: string;
}

function SpotContractItem({
  className,
  spotContract: { identifier, units, customer, state, delivery_orders },
}: SpotContractItemProps) {
  return (
    <div className={className}>
      <TileList>
        <ContractDetails>
          <span>{identifier}</span>
          <span>{I18n.translate('traceability.contracts.units', { units })}</span>
        </ContractDetails>

        <Detail>{customer}</Detail>

        <Detail>
          {I18n.translate(`activerecord.attributes.contract.aasm_state.${state}`)}
        </Detail>
      </TileList>

      <DeliveryOrderList deliveryOrders={delivery_orders} />
    </div>
  );
}

export default styled(SpotContractItem)`
  margin-top: 0.42em;
`;
