import { Provider } from 'mobx-react';
import React from 'react';

import WetReceptionStore from '../stores/WetReceptionStore';
import { WetLot } from 'types/model/lot';
import WetTableRows from 'wetCoffee/components/WetTableRows';
import CoffeeType from 'types/model/coffee_type';

interface WetReceptionStoreProps {
  lots: Array<WetLot>;
  coffeeTypes: Array<CoffeeType>;
}

const TableRowsContainer: React.FC<WetReceptionStoreProps> = ({ lots, coffeeTypes }) => (
  <Provider wetReceptionStore={new WetReceptionStore(lots, coffeeTypes)}>
    <WetTableRows />
  </Provider>
);

export default TableRowsContainer;
