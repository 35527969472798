import { observer } from 'mobx-react';
import styled from 'styled-components';
import React, { useState } from 'react';
import InputGroupComponent from 'components/groups/InputGroup';

export interface LotOrSetRowProps {
  identifier: string;
  type: string;
  id: number;
  availableAmount: string;
}

const InputWrapper = styled.div`
  .input-group {
    margin-bottom: 0;
  }
`;

const LotOrSetRow: React.FC<LotOrSetRowProps> = ({
  id,
  identifier,
  type,
  availableAmount,
}) => {
  const [reservedSize, setReservedSize] = useState<number | undefined>(undefined);

  const reserveSize = (value: string | undefined) => {
    const amount = Number(value);
    if (Number(availableAmount) >= amount) {
      setReservedSize(amount);
    }
  };

  return (
    <tr data-cy={`${type}_row`}>
      <td>{identifier}</td>
      <td className='align-center'>{`${availableAmount} kg`}</td>
      <td className='align-right'>
        <InputWrapper>
          <InputGroupComponent
            name={`reserve_coffee[${type}][${id}]`}
            value={reservedSize ? reservedSize : ''}
            append='kg'
            onChange={(e) => reserveSize(e.target.value)}
            data-cy='lot_or_set_row_weight_input'
          />
        </InputWrapper>
      </td>
    </tr>
  );
};

export default observer(LotOrSetRow);
