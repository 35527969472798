import React, { useState, useEffect } from 'react';

import Grid from 'components/elements/Grid';

import AvailableCoffee, {
  AvailableCoffeeType,
} from 'opportunities/components/AvailableCoffee';

import { ContractTextInput } from './ContractFields';
import { IField } from 'components/shared/form/IField';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import InputGroupComponent from 'components/groups/InputGroup';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';
import Response from 'types/utils/Response';
import { SpotInventory } from 'fulfillment/types/SpotInventory';

interface SpotCoffeeDetailsProps {
  fields: IField[];
  availableCoffee: Array<AvailableCoffeeType>;
  spotIco: AvailableCoffeeType;
  opportunityEstimatedKg: string;
  editMode?: boolean;
}

type GetSpotInventoryResponse = Response<{
  response: SpotInventory;
}>;

const SpotCoffeeDetails = ({
  fields,
  availableCoffee,
  spotIco,
  opportunityEstimatedKg,
  editMode = true,
}: SpotCoffeeDetailsProps) => {
  const unitsField = fields.find((field) => field.name == 'units');
  const [units, setUnits] = useState(unitsField?.value || '0');

  const [selectedSpotIco, setSelectedSpotIco] = useState<undefined | AvailableCoffeeType>(
    spotIco
  );

  const [actualUnits, setActualUnits] = useState(0);

  const spotIcoField = fields.find((field) => field.name == 'spot_ico_id');

  const subtitle = opportunityEstimatedKg
    ? I18n.translate('contracts.form.estimated_kg_hint', {
        weight: opportunityEstimatedKg,
      })
    : '';

  if (unitsField?.enabled) {
    useEffect(() => {
      if (selectedSpotIco) {
        customFetch<GetSpotInventoryResponse>(
          Routes.api_v1_fulfillment_get_spot_inventory_path({
            spot_inventory: {
              ico_identifier: selectedSpotIco?.identifier.value,
            },
          }),
          undefined,
          'GET'
        ).then((response) => {
          if (response.status === 'success') {
            let { units: actualUnits } = response.response;

            if (editMode) {
              actualUnits += parseInt(units);
            }

            setActualUnits(actualUnits);
          } else {
            setActualUnits(0);
          }
        });
      }
    }, [selectedSpotIco]);
  }

  const unitInfoText = unitsField?.enabled ? `/${actualUnits}` : '-';

  // TODO When a production order exists, packaging type and units cannot be edited anymore
  // We still have to send the proper contract values when field is disabled, current fallback sends the name instead of id
  const hiddenFields = fields
    .filter((field) => {
      return ['packaging_type_id', 'units'].includes(field.name) && !field.enabled;
    })
    .map((hiddenField, index) => (
      <input
        key={index}
        type='hidden'
        name={`contract[${hiddenField.name}]`}
        value={hiddenField.value}
      />
    ));

  return (
    <FieldSetWithTitle
      title={I18n.translate('contracts.form.coffee_details')}
      subtitle={subtitle}
    >
      <Grid grid={12}>
        {hiddenFields}
        {availableCoffee && (
          <>
            {selectedSpotIco && (
              <>
                <input
                  type='hidden'
                  value={selectedSpotIco.id}
                  name='opportunity[ico_id]'
                />
                <InputGroupComponent
                  value={selectedSpotIco.identifier.value || ''}
                  disabled={true}
                  label={I18n.translate(`opportunities.form.ico_identifier`)}
                  readOnly={true}
                  data-cy={'icoNumber'}
                />
                <InputGroupComponent
                  value={selectedSpotIco.certificate || ''}
                  disabled={true}
                  label={I18n.translate(`opportunities.form.certificate`)}
                  readOnly={true}
                  data-cy={'certificate'}
                />
                <InputGroupComponent
                  value={selectedSpotIco.origin_country || ''}
                  disabled={true}
                  label={I18n.translate(`opportunities.form.origin_countries`)}
                  readOnly={true}
                  data-cy={'origin'}
                />
                <InputGroupComponent
                  value={selectedSpotIco.mark || ''}
                  disabled={true}
                  label={I18n.translate(`opportunities.form.mark`)}
                  readOnly={true}
                  data-cy={'mark'}
                />
                <InputGroupComponent
                  value={selectedSpotIco.quality || ''}
                  disabled={true}
                  label={I18n.translate(`opportunities.form.quality`)}
                  readOnly={true}
                  data-cy={'quality'}
                />

                <ContractTextInput
                  type='number'
                  min={1}
                  max={actualUnits}
                  step='1'
                  append='#'
                  info={unitInfoText}
                  field={unitsField}
                  value={units}
                  onChange={setUnits}
                  validator={(event) =>
                    event.currentTarget && event.currentTarget.value > 0
                  }
                />
                <InputGroupComponent
                  value={selectedSpotIco.packagingType}
                  disabled={true}
                  label={I18n.translate(`opportunities.form.capacity_and_packaging`)}
                  readOnly={true}
                  data-cy={'packaging_type'}
                />
                <InputGroupComponent
                  value={selectedSpotIco.preparation}
                  disabled={true}
                  label={I18n.translate(`contracts.form.preparation`)}
                  readOnly={true}
                  data-cy={'preparation'}
                />
              </>
            )}
            {spotIcoField?.enabled && (
              <AvailableCoffee
                buttonTitle={
                  selectedSpotIco
                    ? I18n.translate('available_coffee_overlay.change_ico')
                    : I18n.translate('available_coffee_overlay.available_ico')
                }
                availableCoffee={availableCoffee}
                onSelect={setSelectedSpotIco}
                data-cy='available_ico'
              />
            )}
          </>
        )}
      </Grid>
    </FieldSetWithTitle>
  );
};

export default SpotCoffeeDetails;
