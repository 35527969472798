import React from 'react';

import Icon from 'components/elements/Icon';

interface RegradeArrowProps {
  grade: number;
  originalGrade: number;
}

function RegradeArrow({ grade, originalGrade }: RegradeArrowProps) {
  const direction = grade > originalGrade ? 'up' : 'down';
  const title = originalGrade + ' ➡ ' + grade;

  return <Icon title={title} icon={`big-arrow-${direction}`} />;
}

export default RegradeArrow;
