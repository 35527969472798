import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';

import LogEntry from 'millings/productionLog/models/LogEntry';

interface ProductionFractionProps {
  entry: LogEntry;
}

const ProductionLogFraction = ({
  entry: {
    setFraction,
    attributes: { fraction, start_date },
  },
}: ProductionFractionProps) => {
  const { productionLogStore } = useStores();

  if (fraction && start_date) {
    return <span>{fraction}</span>;
  }

  return (
    <select
      className='input--condensed'
      onChange={(e) => setFraction(e.target.value)}
      value={fraction || ''}
    >
      <option>{I18n.translate('milling.machines.placeholder')}</option>
      {productionLogStore.fractions.map((fraction, index) => (
        // @ts-ignore: Wrongly typed Options
        <option key={index} value={fraction}>
          {fraction}
        </option>
      ))}
    </select>
  );
};

export default observer(ProductionLogFraction);
