import React, { useState, useEffect } from 'react';

import SelectOptionArray from 'types/model/selectOption';

import Grid from 'components/elements/Grid';

import { ContractSelectGroup, ContractTextInput } from './ContractFields';
import { IField } from 'components/shared/form/IField';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';

interface CoffeeDetailsProps {
  fields: IField[];
  opportunityEstimatedKg?: string;
  packagingTypeOptions: SelectOptionArray;
  markOptions: SelectOptionArray;
  onMarkChange: (value: string) => void;
  markIdField?: IField;
  markId: string;
  qualityIdField?: IField;
  qualityId: string;
  onQualityChange: (value: string) => void;
  preparationIdField?: IField;
  preparationId: string;
  setPreparationId: (value: string) => void;
}

const CoffeeDetails = ({
  preparationIdField,
  preparationId,
  setPreparationId,
  opportunityEstimatedKg,
  fields,
  packagingTypeOptions,
  markOptions,
  onMarkChange,
  markIdField,
  markId,
  qualityIdField,
  qualityId,
  onQualityChange,
}: CoffeeDetailsProps) => {
  const [selectedPackagingTypeCapacity, setSelectedPackagingTypeCapacity] = useState('0');
  const packagingTypeIdField = fields.find((field) => field.name == 'packaging_type_id');
  const [packagingTypeId, setPackagingTypeId] = useState(
    packagingTypeIdField?.value || '0'
  );
  const unitsField = fields.find((field) => field.name == 'units');
  const [units, setUnits] = useState(unitsField?.value || '0');
  const weight = parseInt(units, 10) * parseFloat(selectedPackagingTypeCapacity);
  const [calculatedWeight, setCalculatedWeight] = useState(weight);

  useEffect(() => {
    const capacity = packagingTypeIdField?.options?.find(
      (option) => option.id == packagingTypeId
      // @ts-ignore: Wrongly typed Options
    )?.capacity;

    setSelectedPackagingTypeCapacity(capacity);
  }, [packagingTypeId]);

  useEffect(() => {
    const parsedUnits = parseInt(units, 10);
    const parsedSelectedPackagingTypeCapacity = parseFloat(selectedPackagingTypeCapacity);
    setCalculatedWeight(parsedSelectedPackagingTypeCapacity * parsedUnits);
  }, [units, selectedPackagingTypeCapacity]);

  const handlePackagingTypeIdChange = (selectedPackagingTypeId: number) => {
    const capacity = packagingTypeIdField?.options?.find(
      (option) => option.id == selectedPackagingTypeId
      // @ts-ignore: Wrongly typed Options
    )?.capacity;

    if (opportunityEstimatedKg && units) {
      const estimatedKg = parseInt(opportunityEstimatedKg, 10);
      const packagingCapacity = parseFloat(capacity);
      const newUnits = Math.ceil(estimatedKg / packagingCapacity);

      setUnits(newUnits.toString());
      setCalculatedWeight(newUnits * capacity);
    }
    setPackagingTypeId(selectedPackagingTypeId.toString());
    setSelectedPackagingTypeCapacity(capacity);
  };

  const normField = fields.find((field) => field.name == 'norm_id');
  const certificateIdField = fields.find((field) => field.name == 'certificate_id');
  const processTypeIdField = fields.find((field) => field.name == 'process_type_id');

  const subtitle = opportunityEstimatedKg
    ? I18n.translate('contracts.form.estimated_kg_hint', {
        weight: opportunityEstimatedKg,
      })
    : '';

  // TODO When a production order exists, packaging type and units cannot be edited anymore
  // We still have to send the proper contract values when field is disabled, current fallback sends the name instead of id
  const hiddenFields = fields
    .filter((field) => {
      return ['packaging_type_id', 'units'].includes(field.name) && !field.enabled;
    })
    .map((hiddenField, index) => (
      <input
        key={index}
        type='hidden'
        name={`contract[${hiddenField.name}]`}
        value={hiddenField.value}
      />
    ));

  return (
    <FieldSetWithTitle
      title={I18n.translate('contracts.form.coffee_details')}
      subtitle={subtitle}
    >
      <Grid grid={12}>
        <ContractSelectGroup
          field={packagingTypeIdField}
          value={packagingTypeId}
          onChange={handlePackagingTypeIdChange}
          overrideOptions={packagingTypeOptions}
        />
        <ContractTextInput
          field={unitsField}
          value={units}
          onChange={setUnits}
          append='#'
          info={calculatedWeight ? `${calculatedWeight} KG` : '-'}
          type='number'
          min={1}
        />
        <ContractSelectGroup
          field={qualityIdField}
          onChange={onQualityChange}
          value={qualityId}
        />
        <ContractSelectGroup
          field={markIdField}
          overrideOptions={markOptions}
          onChange={onMarkChange}
          value={markId}
        />
        <ContractSelectGroup field={normField} />
        <ContractSelectGroup field={certificateIdField} />
        <ContractSelectGroup field={processTypeIdField} />

        <ContractSelectGroup
          field={preparationIdField}
          value={preparationId}
          onChange={setPreparationId}
        />
        {hiddenFields}
      </Grid>
    </FieldSetWithTitle>
  );
};

export default CoffeeDetails;
