import { action, computed, observable } from 'mobx';
import { IObservableArray } from 'mobx/lib/internal';

import Routes from 'routes';

import type { ISampleTransport } from 'samples/receive_samples/types/ISampleTransport';
import { customFetch } from 'utils/fetch';
import SampleModel from 'samples/receive_samples/models/SampleModel';
import ReceptionOption from './ReceptionOption';

type SampleTransportAttributes = Omit<ISampleTransport, 'samples'>;

class SampleTransportModel {
  public attributes: SampleTransportAttributes;
  public samples: IObservableArray<SampleModel>;
  public unloadedSamples = observable.array<SampleModel>();
  @observable public closed = false;
  @observable public submitted = false;
  @observable public errors: Array<string> = [];

  constructor(transport: ISampleTransport) {
    const { samples, ...attributes } = transport;
    this.attributes = attributes;

    this.samples = observable.array<SampleModel>(
      samples.map((sample) => new SampleModel(sample))
    );
  }

  @action public receiveSample = (
    sample: SampleModel,
    receptionOpion: ReceptionOption
  ) => {
    this.unloadedSamples.remove(sample);
    sample.addReceptionOption(receptionOpion);
    this.unloadedSamples.push(sample);
  };

  @action public removeUnloadedSample = (sample: SampleModel) => {
    this.unloadedSamples.remove(sample);
    sample.removeReceptionOption();
  };

  @action public closeTransport = () => {
    if (this.unloaded) {
      this.submitted = true;
      customFetch(Routes.sample_transport_close_transport_path(this.attributes.id), {
        samples: this.unloadedSamples.map((sample) => ({
          sample_id: sample.attributes.id,
          option: sample.receptionOption,
        })),
      }).then((result) => {
        if (result.success) {
          this.closed = true;
          this.errors = [];
        } else {
          this.errors = result.errors;
          this.submitted = false;
        }
      });
    }
  };

  @computed get unloaded(): boolean {
    return this.unloadedSamples.length === this.samples.length;
  }
}

export default SampleTransportModel;
