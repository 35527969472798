import React from 'react';
import { InputGroupProps } from 'components/groups/InputGroup';
import { IFieldInput } from './IFieldInput';
import Checkbox, { CheckboxProps } from 'shared/Checkbox';

interface ControlledSelectionCheckboxProps extends CheckboxProps {
  onCheck: () => any;
  onUncheck: () => any;
  checked: boolean;
  disabled?: boolean;
}

export const ControlledCheckbox = ({
  field,
  namespace,
  checked,
  onChange,
  disabled = false,
  overrideName,
}: IFieldInput & InputGroupProps & ControlledSelectionCheckboxProps) => {
  if (field == undefined || !field.visible) {
    return null;
  }

  const fieldNameWithoutId = field.name.replace('_id', '');

  const namespace_plural =
    namespace.slice(-1) == 'y' ? namespace.slice(0, -1) + 'ies' : namespace + 's';

  const label = I18n.translate(`${namespace_plural}.form.${fieldNameWithoutId}`);

  return (
    <>
      {/* Hidden input with value "false" so we ensure the parameter is sent at the endpoint */}
      <input
        type='hidden'
        name={overrideName || `${namespace}[${field.name}]`}
        value='false'
      />

      {/* The actual checkbox */}
      <Checkbox
        name={overrideName || `${namespace}[${field.name}]`}
        icon='tick'
        label={label}
        onChange={(e) => onChange && onChange(e)}
        value={`${checked}`}
        checked={checked}
        disabled={disabled}
      />
    </>
  );
};
