import React from 'react';
import styled from 'styled-components';

import { TabNav, TabNavItem, TavNavFiller } from './TabBar';

export type Tabs = {
  [key: string]: {
    url: string;
    active: boolean;
    title: string;
  };
};

export interface TraceabilityTabBarProps {
  tabs: Tabs;
  className?: string;
}

function TraceabilityTabBar({ tabs, className }: TraceabilityTabBarProps) {
  return (
    <TabNav className={className}>
      {Object.entries(tabs).map(([key, { title, url, active }]) => {
        const elementProps: {
          isActive: boolean;
          as?: 'a';
          href?: string;
        } = {
          isActive: active,
        };

        if (!active) {
          elementProps.as = 'a';
          elementProps.href = url;
        }

        return (
          <TabNavItem key={key} {...elementProps}>
            {title}
          </TabNavItem>
        );
      })}

      <TavNavFiller />
    </TabNav>
  );
}

export default styled(TraceabilityTabBar)`
  margin-bottom: 4rem;
`;
