import React, { useContext } from 'react';

import { AbilityContext } from 'components/shared/Can';

export interface HumidityAnalysisOverlayButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  humidityAnalysis?: any;
  lotFailed?: boolean;
  text?: string;
  icon?: string;
  status?: boolean;
  disabled?: boolean;
}

function HumidityAnalysisOverlayButton({
  humidityAnalysis,
  lotFailed,
  text,
  icon,
  status,
  onClick,
  disabled,
}: HumidityAnalysisOverlayButtonProps): JSX.Element {
  const ability = useContext(AbilityContext);

  const handleOnClick = (event: React.MouseEvent) => {
    if (onClick) {
      if (ability.can('create', 'HumidityAnalysis')) {
        onClick(event as any);
      }
    }
  };

  const renderButton = () => {
    if (humidityAnalysis && humidityAnalysis.id) {
      if (lotFailed) {
        return (
          <div
            className='text-with-sub text-with-sub--link text-with-sub--icon'
            onClick={handleOnClick}
          >
            <svg className='icon'>
              <use xlinkHref='#pen'></use>
            </svg>

            <div className='text-with-sub__text' style={{ width: '100%' }}>
              {I18n.translate('rejected.humidity')}
            </div>
            <small className='text-with-sub__sub'>{humidityAnalysis.reject_reason}</small>
          </div>
        );
      } else {
        return (
          <div onClick={handleOnClick} className={`status status--${status}`}>
            <span>{text}</span>
            <svg className='icon'>
              <use xlinkHref={`#${icon}`} />
            </svg>
          </div>
        );
      }
    } else {
      return (
        <button
          type='button'
          onClick={handleOnClick}
          className='button button--small button--ghost'
          data-cy='analyse_humidity'
          disabled={!ability.can('create', 'HumidityAnalysis') || disabled}
        >
          {I18n.translate('buttons.analyse')}
        </button>
      );
    }
  };

  return renderButton();
}

export default HumidityAnalysisOverlayButton;
