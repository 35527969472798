import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import useStores from 'utils/useStores';

import LogEntryRow, {
  OperatorColumn,
  ObservationColumn,
  LogTimeColumn,
} from './LogEntryRow';

import Checkbox from 'shared/Checkbox';

import ContentHeader from 'components/elements/ContentHeader';
import SpaceBetween from 'components/elements/SpaceBetween';
import FilterBar from 'components/elements/FilterBar';
import Can from 'components/shared/Can';

const MachineColumn = styled.th`
  width: 10.625em;
  max-width: 10.625em;
`;

const DateColumn = styled.th`
  width: 4.6875em;
  max-width: 4.6875em;
`;

const FractionColumn = styled.th`
  width: 7em;
  max-width: 7em;
`;

const CloseButtonColumn = styled.th`
  width: 5.3125em;
  max-width: 5.3125em;
`;

const LogTable = ({ cancelPath }) => {
  const { productionLogStore } = useStores();

  const { identifier } = productionLogStore;

  useEffect(() => {
    if (productionLogStore.currentEntries.length === 0) {
      productionLogStore.createNewEntry();
    }
  }, []);

  return (
    <div>
      <ContentHeader
        title={I18n.translate('milling.production_log.title')}
        prefix={identifier}
      />

      <FilterBar columns='repeat(2, min-content)'>
        <Checkbox
          label={I18n.translate('milling.production_log.show_in_progress')}
          checked={productionLogStore.filter.inProgress}
          onChange={() => productionLogStore.toggleFilter('inProgress')}
        />
        <Checkbox
          label={I18n.translate('milling.production_log.show_closed')}
          checked={productionLogStore.filter.closed}
          onChange={() => productionLogStore.toggleFilter('closed')}
        />
      </FilterBar>

      <div className='l-item-stack l-item-stack--spaced'>
        <table>
          <thead>
            <tr>
              <OperatorColumn as='th' className='table-border'>
                {I18n.translate('milling.production_log.operator')}
              </OperatorColumn>
              <MachineColumn className='table-border'>
                {I18n.translate('milling.production_log.machine')}
              </MachineColumn>
              <DateColumn>{I18n.translate('milling.production_log.date')}</DateColumn>
              <LogTimeColumn as='th' className='table-border'>
                {I18n.translate('milling.production_log.start')}
                {' - '}
                {I18n.translate('milling.production_log.end')}
              </LogTimeColumn>
              <FractionColumn className='table-border'>
                {I18n.translate('milling.production_log.fraction')}
              </FractionColumn>
              <ObservationColumn as='th' className='align-left'>
                {I18n.translate('milling.production_log.observations')}
              </ObservationColumn>
              <CloseButtonColumn />
            </tr>
          </thead>
          <tbody>
            {productionLogStore.currentEntries.map((entry) => (
              <LogEntryRow key={entry.attributes.id} entry={entry} />
            ))}
            {productionLogStore.newEntries.map((entry) => (
              <LogEntryRow key={`new-${entry.attributes.id}`} entry={entry} />
            ))}
          </tbody>
        </table>

        <SpaceBetween>
          <a href={cancelPath} className='button button--gray'>
            {I18n.translate('buttons.cancel')}
          </a>

          <Can I='create' a='ProductionLog'>
            <button
              className='button button--green'
              onClick={() => productionLogStore.createNewEntry()}
            >
              + {I18n.translate('milling.production_log.add_new_log')}
            </button>
          </Can>
        </SpaceBetween>
      </div>
    </div>
  );
};

export default observer(LogTable);
