import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';

import { CalibrationLotModel } from 'calibration/stores/CalibrationStore';
import CalibrationTablePackagingRowComponent from './CalibrationTablePackagingRowComponent';

const CalibrationTablePackagingComponent: React.FC = () => {
  const { calibrationStore } = useStores();

  return (
    <>
      {calibrationStore.calibrationLots.map((calibrationLot: CalibrationLotModel) => {
        const { local_identifier } = calibrationLot.lot;

        return (
          <CalibrationTablePackagingRowComponent
            key={local_identifier}
            calibrationLot={calibrationLot}
          />
        );
      })}
    </>
  );
};

export default observer(CalibrationTablePackagingComponent);
