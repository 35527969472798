import { deserialise } from 'kitsu-core';

import CustomerType from 'types/model/customer';
import { ReasonGet } from 'types/model/reason';

import Customer from 'customers/models/Customer';
import LeadType from 'types/model/lead';
import SelectOptionArray from 'types/model/selectOption';
import { JsonApi } from 'types/utils/jsonApi';
import SelectCompanyOptionArray from 'customers/types/SelectMarketTypeOption';

class CustomerStore {
  public customer: Customer;
  public countries: ReasonGet[];
  public paymentTerms: ReasonGet[];
  public arbitrations: ReasonGet[];
  public currencies: SelectOptionArray;
  public qualifications: ReasonGet[];
  public unitsOfMeasurement: ReasonGet[];
  public leadSources: ReasonGet[];
  public customerOwners: ReasonGet[];
  public leads: LeadType[];
  public selectedLeadId: number;
  public customerWithLeadPath: string;
  public cancelPath: string;
  public saveButtonText: string;
  public companySizes: SelectCompanyOptionArray;
  public marketTypes: SelectCompanyOptionArray;

  constructor(
    customer: JsonApi<CustomerType>,
    countries: JsonApi<ReasonGet[]>,
    paymentTerms: JsonApi<ReasonGet[]>,
    arbitrations: JsonApi<ReasonGet[]>,
    currencies: JsonApi<SelectOptionArray>,
    qualifications: JsonApi<ReasonGet[]>,
    unitsOfMeasurement: ReasonGet[],
    leadSources: JsonApi<ReasonGet[]>,
    customerOwners: JsonApi<ReasonGet[]>,
    leads: LeadType[],
    selectedLeadId: number,
    customerWithLeadPath: string,
    cancelPath: string,
    saveButtonText: string,
    companySizes: JsonApi<SelectCompanyOptionArray>,
    marketTypes: JsonApi<SelectCompanyOptionArray>
  ) {
    this.customer = new Customer(deserialise(customer).data);
    this.countries = deserialise(countries).data;
    this.paymentTerms = deserialise(paymentTerms).data;
    this.arbitrations = deserialise(arbitrations).data;
    this.currencies = deserialise(currencies).data;
    this.qualifications = deserialise(qualifications).data;
    this.unitsOfMeasurement = unitsOfMeasurement;
    this.leadSources = deserialise(leadSources).data;
    this.customerOwners = deserialise(customerOwners).data;
    this.leads = leads;
    this.selectedLeadId = selectedLeadId;
    this.customerWithLeadPath = customerWithLeadPath;
    this.cancelPath = cancelPath;
    this.saveButtonText = saveButtonText;
    this.companySizes = deserialise(companySizes).data;
    this.marketTypes = deserialise(marketTypes).data;
  }
}

export default CustomerStore;
