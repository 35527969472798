import React, { useEffect, useState } from 'react';
import Routes from 'routes';

import InputWithSuggestions from 'components/elements/InputWithSuggestions';
import InputGroup from 'components/groups/InputGroup';

const idPattern = /(.+)\s*-\s*.+/;

interface DriverOption {
  id: number;
  personal_id_number: string;
  name: string;
}

interface DriverSelectionProps {
  driverId: number | null | undefined;
  driverCollection: Array<DriverOption>;
}

interface DriverSelectionState {
  driverId?: number;
  license?: string;
  phoneNumber?: string;
}

const DriverSelectionComponent = ({
  driverId,
  driverCollection,
}: DriverSelectionProps) => {
  const [currentDriver, setCurrentDriver] = useState({
    id: 0,
    name: '',
    personal_id_number: '',
  } as DriverOption);

  const [form, setState] = useState({
    driverId,
    license: undefined,
    phoneNumber: undefined,
  } as DriverSelectionState);

  useEffect(() => {
    if (currentDriver.id) {
      updateFields();
    }
  }, [currentDriver.id, form.driverId]);

  const deriveDriverIdWithName = (): string => {
    return currentDriver
      ? `${currentDriver.personal_id_number} - ${currentDriver.name}`
      : '';
  };

  const updateFields = () => {
    if (currentDriver) {
      fetch(Routes.api_v1_driver_path(currentDriver.id))
        .then((res) => (res.ok ? res : Promise.reject(res)))
        .then((res) => res.json())
        .then((body) =>
          setState({
            ...form,
            driverId: form.driverId,
            license: body.license,
            phoneNumber: body.phone_number,
          })
        )
        .catch(() =>
          setState({
            ...form,
            driverId: undefined,
            license: undefined,
            phoneNumber: undefined,
          })
        );
    }
  };

  const handleDriverSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const matches = event.target.value.match(idPattern);

    if (matches) {
      // We just want the personal id part of the input
      const [, personalId] = matches;

      // We check whether the id part of the input has matched
      if (personalId) {
        // We look for the producer with the given id (kinda inefficient)
        const newDriver = driverCollection.find(
          (d) => d.personal_id_number === personalId.trim()
        );

        if (newDriver) {
          setCurrentDriver(newDriver);
        }
      }
    }
  };

  const { license, phoneNumber } = form;

  const driverIdWithName = currentDriver ? deriveDriverIdWithName() : '';
  return (
    <>
      <input type='hidden' value={currentDriver.id || ''} name='transport[driver_id]' />

      <div className='l-auto-fill-flex'>
        <InputWithSuggestions
          listId='driveList'
          id='driver_name'
          defaultValue={driverIdWithName}
          autoComplete='off'
          onChange={(e) => handleDriverSelection(e)}
        >
          {driverCollection.map((driver) => (
            <option
              key={driver.id}
              value={`${driver.personal_id_number} - ${driver.name}`}
            />
          ))}
        </InputWithSuggestions>
      </div>
      <div className='l-auto-fill-grid'>
        <InputGroup
          id='license_id'
          label={I18n.translate('transports.form.license_number')}
          value={license || ''}
          readOnly
        />

        <InputGroup
          id='phone_number'
          label={I18n.translate('attributes.phone_number')}
          value={phoneNumber || ''}
          readOnly
        />
      </div>
    </>
  );
};

export default DriverSelectionComponent;
