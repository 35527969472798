import DeliveryOrder from '../../models/DeliveryOrder';
import { observer } from 'mobx-react';
import React from 'react';
import { OverlayFooter, OverlayTitle, OverlayWrapper } from 'shared/Overlay';
import OverlayBaseForm from 'shared/Overlay/components/OverlayBaseForm';
import styled from 'styled-components';
import { customFetch } from 'utils/fetch';
import useStores from 'utils/useStores';
import DestinationWarehouseCosts from './DestinationWarehouseCosts';
import Routes from 'routes';

const FormButton = styled.button``;
const SubmitButton = styled.input``;

const Overlay = styled(OverlayBaseForm)``;

function SendOverlay() {
  const { deliveryOrderIndexStore } = useStores();
  const { deliveryOrderCostStore, activeDeliveryOrder } = deliveryOrderIndexStore;
  const { deliveryOrderCost } = deliveryOrderCostStore;

  const sendDeliveryOrder = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const payload = {
      delivery_order_costs: deliveryOrderCost.urlParams,
    };

    customFetch(
      Routes.send_to_accounting_api_v1_fulfillment_delivery_order_path(
        (activeDeliveryOrder as DeliveryOrder).id
      ),
      payload,
      'PATCH'
    ).then((_response) => {
      window.location.reload();
    });
  };

  return (
    <form onSubmit={sendDeliveryOrder}>
      <OverlayWrapper>
        <Overlay>
          <OverlayTitle>
            {I18n.translate('fulfillment.delivery_orders.warehousing_costs')}
          </OverlayTitle>

          <DestinationWarehouseCosts />

          <OverlayFooter>
            <FormButton
              className='button button--small'
              onClick={deliveryOrderIndexStore.closeOverlay}
            >
              Cancel
            </FormButton>
            <SubmitButton
              className='button button--small'
              disabled={!deliveryOrderCost.deliveryOrderCostsValid}
              type='submit'
              value='Save'
            />
          </OverlayFooter>
        </Overlay>
      </OverlayWrapper>
    </form>
  );
}

export default observer(SendOverlay);
