import React, { useState } from 'react';
import classNames from 'classnames';

export interface CloseDecafOrderButtonProps {
  passed: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<unknown>;
}

const CloseDecafOrderButton = ({ onClick, passed }: CloseDecafOrderButtonProps) => {
  const [submitted, setSubmitted] = useState(false);

  const classes = classNames('button', 'button--small', {
    'button--green': passed,
    'button--required': !passed,
  });

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSubmitted(true);

    onClick(event).catch(() => {
      setSubmitted(false);
    });
  };

  let text = I18n.translate('decaf.quality_assurance.approve_decaf_order');

  if (!passed) {
    text = I18n.translate('decaf.quality_assurance.reject_decaf_order');
  }

  return (
    <button
      type='button'
      className={classes}
      onClick={handleOnClick}
      disabled={submitted}
    >
      {text}
    </button>
  );
};

export default CloseDecafOrderButton;
