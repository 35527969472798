import React from 'react';
import Routes from 'routes';

import { customFetch } from 'utils/fetch';

import RequestOrphanIcoOverlay from './RequestOrphanIcoOverlay';
import { OrphanStatus } from 'availableExcessOrOrphanCoffee/containers/AvailableExcessOrOrphanCoffeeContainer';
import { addTestingIdentifier } from 'utils/testing';

export interface RequestOrphanIcoComponentProps {
  millingOrderId: number;
  missingWeight: string;
  orphanStatus: OrphanStatus;
}

const RequestOrphanIcoComponent: React.FC<RequestOrphanIcoComponentProps> = ({
  millingOrderId,
  missingWeight,
  orphanStatus,
}) => {
  const [showOverlay, setShowOverlay] = React.useState(false);

  const handleOnPrimaryClick = (event, weight: number) => {
    customFetch(Routes.request_orphan_ico_api_v1_milling_order_path(millingOrderId), {
      weight,
    }).then(() => {
      setShowOverlay(false);
      window.location.href = Routes.milling_orders_path();
    });
  };

  const handleOnSecondaryClick = (_event: React.MouseEvent) => {
    setShowOverlay(false);
  };

  const handleOnButtonClick = (_event: React.MouseEvent) => {
    setShowOverlay(true);
  };

  const renderOrphanStatus = () => {
    if (orphanStatus === null) {
      return (
        <button
          type='button'
          className='button button--green'
          onClick={handleOnButtonClick}
          {...addTestingIdentifier('request_orphan_ico_button')}
        >
          {I18n.translate('buttons.request_orphan_ico')}
        </button>
      );
    } else {
      return (
        <div>
          {I18n.translate(
            `milling_orders.show.orphan_status.${orphanStatus?.toLowerCase()}`
          )}
        </div>
      );
    }
  };

  return (
    <>
      {showOverlay && (
        <RequestOrphanIcoOverlay
          title={I18n.translate('milling_orders.show.request_orphan')}
          onPrimaryClick={handleOnPrimaryClick}
          onSecondaryClick={handleOnSecondaryClick}
          missingWeight={missingWeight}
        />
      )}

      {renderOrphanStatus()}
    </>
  );
};

export default RequestOrphanIcoComponent;
