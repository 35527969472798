import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Routes from 'routes';

import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

import Checkbox from 'shared/Checkbox';
import { customFetch } from 'utils/fetch';
import LotTag from './LotTagComponent';

import { CalibrationLotModel } from 'calibration/stores/CalibrationStore';
import useStores from 'utils/useStores';
import Icon from 'components/elements/Icon';
import COLORS from 'constants/colors';

interface CalibrationTablePackagingProps {
  calibrationLot: CalibrationLotModel;
}

const CalibrationTablePackagingRowComponent = ({
  calibrationLot: {
    model,
    lot: {
      id,
      local_identifier,
      weight,
      weight_string,
      grade,
      calibration_lab_sample_size,
      quality,
    },
  },
}: CalibrationTablePackagingProps) => {
  const { calibrationStore } = useStores();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const bag = calibrationStore.bags.find((b) => b.id === model.bagType);
    let newAmount = 0;
    if (bag) {
      newAmount = Math.ceil(weight / bag.capacity);
    }
    model.setAmountOfBags(newAmount);
  }, [model.bagType]);

  const handleSubmit = () => {
    const body = {
      calibration_lab_sample_size,
      create_sample: model.createSample,
      number_calibrated_bags: model.amountOfBags,
      packaging_type_id: model.bagType,
      lot_tag: {
        tag_color_id: model.color,
        end_number: model.endNumber,
        start_number: model.startNumber,
      },
    };

    if (!submitted) {
      setSubmitted(true);
      customFetch(Routes.calibration_calibrate_path(id), body).then(() => {
        window.location.reload();
      });
    }
  };

  const renderOptions = () => {
    return calibrationStore.bags.map((bag) => (
      <option key={bag.id} value={bag.id}>
        {bag.name}
      </option>
    ));
  };

  const renderSampleCheckbox = () => {
    if (calibration_lab_sample_size) {
      return (
        <Checkbox
          label={`${calibration_lab_sample_size}g`}
          icon='tick'
          name={`create_sample_${id}`}
          checked={model.createSample}
          onChange={(checked) => model.setCreateSample(checked)}
        />
      );
    }
  };

  return (
    <tr key={local_identifier}>
      <td className='table-border'>
        <TextWithSub
          text={local_identifier}
          textClass='lot--id'
          sub={model.certification}
        />
      </td>
      <td className='align-right'>
        <span className='nowrap' data-cy='calibration_total_weight'>
          {weight_string}
        </span>
      </td>
      <td className='table-math-sign'>/</td>
      <td>
        <select
          className='input--condensed'
          data-cy='calibration_select_packaging_type'
          onChange={(e) => {
            model.setBagType(parseInt(e.target.value, 10));
          }}
        >
          <option>{I18n.translate('calibration.index.select_packaging_type')}</option>
          {renderOptions()}
        </select>
      </td>
      <td className='table-math-sign'>=</td>
      <td className='table-border lots-calibration__bags'>
        <input
          type='text'
          className='input--condensed'
          data-cy='calibration_amount_of_bags'
          value={model.amountOfBags || 0}
          onChange={(e) =>
            model.setAmountOfBags(e.target.value ? parseInt(e.target.value, 10) : 0)
          }
        />
      </td>
      <td className='table-border align-center lots-calibration__grade'>
        <LotTag
          model={model}
          quality={quality}
          grade={grade}
          disabled={!model.amountOfBags}
        />
      </td>
      <td className='lots-calibration__sample'>{renderSampleCheckbox()}</td>
      <td className='align-right'>
        {model.validateTagNumbers() && (
          <button
            className='button button--green button--small'
            disabled={!model.submitEnabled || submitted}
            onClick={handleSubmit}
            data-cy='calibration_submit'
          >
            {I18n.translate('shared.status.done')}
          </button>
        )}
        {!model.validateTagNumbers() && (
          <Icon
            onClick={() => model.setShowErrorOverlay(true)}
            color={COLORS.RED}
            icon='failed'
          />
        )}
      </td>
    </tr>
  );
};

export default observer(CalibrationTablePackagingRowComponent);
