import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';

import { SelectOption } from 'types/model/selectOption';

import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';

import Grid from 'components/elements/Grid';

import CustomerOrLeadComponent from './CustomerOrLeadComponent';
import { AvailableCoffeeType } from 'opportunities/components/AvailableCoffee';
import { OpportunityCoffee } from './segments/OpportunityCoffee';
import { OpportunitySpotCoffee } from './segments/OpportunitySpotCoffee';
import { OpportunityLost } from './segments/OpportunityLost';
import { OpportunityTextAreaInput } from './OpportunityFields';
import OpportunityContract from './segments/OpportunityContract';
import { OpportunityDelivery } from './segments/OpportunityDelivery';
import { IField } from 'components/shared/form/IField';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';

export interface OpportunityFormProps {
  fields: IField[];
  availableCoffee: AvailableCoffeeType[];
  selectedIco: AvailableCoffeeType;
  currentOfficeCountryId: number;
  updateable?: boolean;
}

const OpportunityFormComponent: React.FC<OpportunityFormProps> = ({
  fields,
  availableCoffee,
  selectedIco,
  updateable,
}) => {
  const getField = (name) => fields.find((field) => field.name == name);
  const contractTypeField = useMemo(() => getField('contract_type'), []);
  const [contractType, setContractType] = useState(contractTypeField?.value || '');
  const priceTypeField = useMemo(() => getField('price_type'), []);
  const [priceType, setPriceType] = useState(priceTypeField?.value || '');
  const fixedPriceField = useMemo(() => getField('fixed_price'), []);
  const differentialField = useMemo(() => getField('differential'), []);
  const probabilityField = useMemo(() => getField('probability'), []);
  const qualityIdField = useMemo(() => getField('quality_id'), []);
  const certificateIdField = useMemo(() => getField('certificate_id'), []);
  const estimatedKgField = useMemo(() => getField('estimated_kg'), []);
  const estimatedDeliveryDateField = useMemo(
    () => getField('estimated_delivery_date'),
    []
  );
  const observationsField = useMemo(() => getField('observations'), []);
  const [observations, setObservations] = useState(observationsField?.value || '');
  const originCountryIdsField = useMemo(() => getField('origin_countries'), []);
  const [originCountryIds, setOriginCountryIds] = useState(
    originCountryIdsField?.value || ''
  );
  const destinationPortIdField = useMemo(() => getField('destination_port_id'), []);
  const markIdField = useMemo(() => getField('mark_id'), []);
  const lostOpportunityReasonIdField = useMemo(
    () => getField('lost_opportunity_reason_id'),
    []
  );

  const [isSpotSelectionDisabled, setSpotSelectionDisabled] = useState(false);

  const customerIdField = useMemo(() => getField('customer_id'), []);
  const customerLeadIdField = useMemo(() => getField('customer_lead_id'), []);
  const opportunitySourceIdField = useMemo(() => getField('opportunity_source_id'), []);

  const thirdPartyShipperField = useMemo(() => getField('third_party_shipper_id'), []);
  const estimatedUnitsField = useMemo(() => getField('estimated_units'), []);
  const priceTypeFilteredOptions = ['spot', 'local'].includes(contractType)
    ? priceTypeField?.options?.filter((opt: SelectOption) => opt.name == 'Fixed')
    : priceTypeField?.options;

  const isSpot = contractType == 'spot';
  const isLocal = contractType == 'local';

  const [actualUnits, setActualUnits] = useState(0);

  const [selectedSpotIco, setSelectedSpotIco] = useState<AvailableCoffeeType | undefined>(
    selectedIco
  );

  const incotermField = useMemo(() => getField('incoterm'), []);
  const [incoterm, setIncoterm] = useState(incotermField?.value || '');

  const sampleNeededField = useMemo(() => getField('sample_needed'), []);
  const [sampleNeeded, setSampleNeeded] = useState(sampleNeededField?.value || false);

  const onLostOpportunityChangeHandler = (value: boolean) => {
    setSpotSelectionDisabled(value);
  };

  useEffect(() => {
    if (selectedSpotIco) {
      customFetch(
        Routes.api_v1_fulfillment_get_spot_inventory_path({
          spot_inventory: {
            ico_identifier: selectedSpotIco?.identifier.value,
          },
        }),
        undefined,
        'GET'
      ).then((response) => {
        if (response.status === 'success') {
          const { units: actualUnits } = response.response;

          setActualUnits(actualUnits);
        } else {
          setActualUnits(0);
        }
      });
    }
  }, [selectedSpotIco]);

  return (
    <>
      <OpportunityContract
        contractTypeField={contractTypeField}
        contractType={contractType}
        setContractType={setContractType}
        priceTypeField={priceTypeField}
        priceType={priceType}
        setPriceType={setPriceType}
        priceTypeOptions={priceTypeFilteredOptions}
        probabilityField={probabilityField}
        fixedPriceField={fixedPriceField}
        differentialField={differentialField}
        incotermField={incotermField}
        incoterm={incoterm}
        setIncoterm={setIncoterm}
        sampleNeededField={sampleNeededField}
        sampleNeeded={sampleNeeded}
        setSampleNeeded={setSampleNeeded}
      />
      <FieldSetWithTitle title={I18n.translate('opportunities.form.customer')}>
        <Grid>
          <CustomerOrLeadComponent
            customers={customerIdField?.options}
            customerLeads={customerLeadIdField?.options}
            readOnly={customerLeadIdField?.readonly || false}
            selectedCustomerId={customerIdField?.value || undefined}
            selectedCustomerLeadId={customerLeadIdField?.value || undefined}
            opportunitySourceIdField={opportunitySourceIdField}
            errors={customerIdField?.errors}
          />
        </Grid>
      </FieldSetWithTitle>

      {isSpot ? (
        <OpportunitySpotCoffee
          availableCoffee={availableCoffee}
          selectedSpotIco={selectedSpotIco}
          onSelect={setSelectedSpotIco}
          estimatedUnitsField={estimatedUnitsField}
          units={actualUnits}
          disabled={isSpotSelectionDisabled}
        />
      ) : (
        <OpportunityCoffee
          originCountryIdsField={originCountryIdsField}
          originCountryIds={originCountryIds}
          setOriginCountryIds={setOriginCountryIds}
          markIdField={markIdField}
          qualityIdField={qualityIdField}
          certificateIdField={certificateIdField}
          estimatedKgField={estimatedKgField}
          contractType={contractType}
          thirdPartyShipperField={thirdPartyShipperField}
        />
      )}

      <OpportunityDelivery
        estimatedDeliveryDateField={estimatedDeliveryDateField}
        destinationPortIdField={destinationPortIdField}
        destinationPortHidden={isSpot || isLocal}
      />

      <FieldSetWithTitle title={''}>
        <OpportunityTextAreaInput
          field={observationsField}
          value={observations}
          onChange={(e) => setObservations(e.target.value)}
        />
      </FieldSetWithTitle>

      <OpportunityLost
        onChange={onLostOpportunityChangeHandler}
        lostOpportunityReasonIdField={lostOpportunityReasonIdField}
      />

      <FieldSetWithTitle title=''>
        <div className='l-distribute-l-r'>
          <a
            className='button button--gray'
            data-cy='opportunities_form_cancel'
            href={Routes.opportunities_path()}
          >
            {I18n.translate('buttons.cancel')}
          </a>
          {!(updateable == false) && (
            <input
              type='submit'
              className='button'
              data-cy='opportunities_form_submit'
              value={I18n.translate('buttons.create')}
            />
          )}
        </div>
      </FieldSetWithTitle>
    </>
  );
};

export default observer(OpportunityFormComponent);
