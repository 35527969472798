import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Sample from 'customerSamples/models/Sample';
import CustomerSampleValuesDetails from 'customerSamples/components/CustomerSampleValuesDetails';
import CustomerSampleValuesButton from 'customerSamples/components/CustomerSampleValuesButton';

export interface CustomerSampleValuesStatusProps {
  customerSample: Sample;
}

const StatusWrapper = styled.td`
  max-width: 8.25rem;
`;

function CustomerSampleValuesStatus({
  customerSample,
}: CustomerSampleValuesStatusProps): JSX.Element {
  return (
    <StatusWrapper className='align-right'>
      {customerSample.customerSampleValue?.showEnterResults ? (
        <CustomerSampleValuesDetails customerSample={customerSample} />
      ) : (
        customerSample.showCustomerSampleValueButton && (
          <CustomerSampleValuesButton
            className='button button--small'
            customerSample={customerSample}
          >
            {I18n.translate('customer_samples_dashboard.enter_results')}
          </CustomerSampleValuesButton>
        )
      )}
    </StatusWrapper>
  );
}

export default observer(CustomerSampleValuesStatus);
