import React, { useEffect } from 'react';
import Routes from 'routes';
import styled from 'styled-components';

import COLORS from 'constants/colors';
import { VARIANT } from 'constants/inputTypes';

import { customFetch } from 'utils/fetch';

import Overlay from 'shared/Overlay';

import Grid from 'components/elements/Grid';
import SpaceBetween from 'components/elements/SpaceBetween';
import InputGroupComponent from 'components/groups/InputGroup';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import WetLotModel from 'wetCoffee/models/WetLot';

const DryingRow = styled.div`
  padding: 0.1875em 0.5em;

  &:nth-child(odd) {
    background-color: ${COLORS.LIGHT_GRAY};
  }
`;

const DryingInputs = styled.div`
  border-top: 1px solid ${COLORS.BORDERS};
  margin: 1em 0;
  padding-top: 0.5em;
`;

interface WetCoffeeHumidityProps {
  lot: WetLotModel;
}

const WetCoffeeHumidity = ({
  lot,
  lot: {
    attributes: { id, latest_drying, dryings },
  },
}: WetCoffeeHumidityProps) => {
  const dateObject = new Date();
  const [showOverlay, setOverlay] = React.useState(false);
  const [date, setDate] = React.useState(
    I18n.l('time.formats.input', dateObject.toDateString())
  );
  const [time, setTime] = React.useState(
    `${dateObject.getHours()}:${dateObject.getMinutes().toString().padStart(2, '0')}`
  );
  const [humidity, setHumidity] = React.useState('');

  const [latestHumidity, setLatestHumidity] = React.useState('--');
  const [latestDate, setLatestDate] = React.useState('');

  const handleUpdateClick = () => {
    setOverlay(true);
  };

  const handleOnPrimaryClick = () => {
    customFetch(Routes.wet_reception_update_lot_drying_path(id), {
      lot: {
        date,
        humidity,
        time,
      },
    }).then((response) => {
      if (response.success) {
        setLatestDate(I18n.l('time.formats.long', date));
        setLatestHumidity(`${humidity}%`);
        lot.setLatestHumidity(parseFloat(humidity));
        setHumidity('');
        dryings.push(response.drying);
      } else {
        alert('Something went wrong! Values reseted');
      }
      setOverlay(false);
    });
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  useEffect(() => {
    if (latest_drying && !showOverlay && latestHumidity === '--') {
      setLatestDate(I18n.l('time.formats.long', latest_drying.took_at));
      setLatestHumidity(`${latest_drying.humidity}%`);
    }
  }, [showOverlay]);

  return (
    <>
      {showOverlay && (
        <Overlay
          title={I18n.translate('wet_reception.index.humidity_update')}
          primaryText={I18n.translate('buttons.create')}
          onPrimaryClick={handleOnPrimaryClick}
          secondaryText={I18n.translate('buttons.cancel')}
          onSecondaryClick={handleOnSecondaryClick}
        >
          {dryings.map((dry) => (
            <DryingRow key={dry.id}>
              <SpaceBetween>
                <span>
                  {I18n.l(
                    'time.formats.date_time_split',
                    new Date(dry.took_at).toString()
                  )}
                </span>
                <span>{dry.humidity}%</span>
              </SpaceBetween>
            </DryingRow>
          ))}
          <DryingInputs>
            <Grid grid={9}>
              <InputGroupComponent
                title={I18n.translate('wet_reception.index.date')}
                defaultValue={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <InputGroupComponent
                title={I18n.translate('wet_reception.index.time')}
                defaultValue={time}
                onChange={(e) => setTime(e.target.value)}
              />
              <InputGroupComponent
                title={I18n.translate('wet_reception.index.humidity')}
                defaultValue={humidity}
                variant={VARIANT.DECIMAL}
                append='%'
                onChange={(e) => setHumidity(e.target.value)}
              />
            </Grid>
          </DryingInputs>
        </Overlay>
      )}
      <SpaceBetween alignCenter={true}>
        <TextWithSub text={latestHumidity} sub={latestDate} />
        <button
          className='button button--small button--ghost'
          onClick={handleUpdateClick}
        >
          {I18n.translate('buttons.update')}
        </button>
      </SpaceBetween>
    </>
  );
};

export default WetCoffeeHumidity;
