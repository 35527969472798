import React, { ChangeEvent, useMemo, useState } from 'react';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Routes from 'routes';

import { TransportationMode, Incoterm } from 'fulfillment/types/types';

import FormButton from 'exports/components/FormButton';
import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroupComponent from 'components/groups/SelectGroup';
import OverlayBaseForm from 'shared/Overlay/components/OverlayBaseForm';
import { OverlayFooter, OverlayTitle, OverlayWrapper } from 'shared/Overlay';
import { customFetch } from 'utils/fetch';
import DeliveryOrder from '../../models/DeliveryOrder';

const Overlay = styled(OverlayBaseForm)``;

function ShippingOptionsOverlay() {
  const { deliveryOrderIndexStore } = useStores();
  const deliveryOrder = deliveryOrderIndexStore.activeDeliveryOrder as DeliveryOrder;
  const deliveryOrderShippingOption = deliveryOrder.deliveryOrderShippingOption;
  const [submitted, setSubmitted] = useState(false);

  const defaultValues =
    deliveryOrderShippingOption !== undefined
      ? {
          id: deliveryOrderShippingOption.id,
          transportation_mode: deliveryOrderShippingOption.transportation_mode,
          load_date: new Date(deliveryOrderShippingOption.load_date),
          remarks: deliveryOrderShippingOption.remarks,
          incoterm: deliveryOrderShippingOption.incoterm,
        }
      : {
          id: null,
          transportation_mode: '',
          load_date: new Date(Date.now()),
          remarks: '',
          incoterm: '',
        };

  const dispatched = deliveryOrderShippingOption?.dispatched || false;

  const [shippingOptions, setShippingOptions] = useState(defaultValues);

  const setShippingOptionsField = (value: string | Date | null, name: string): void => {
    const fieldname = name.substring(name.lastIndexOf('[') + 1, name.lastIndexOf(']'));
    setShippingOptions({
      ...shippingOptions,
      [fieldname]: value,
    });
  };

  const setShippingOptionsTextField = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { value, name } = event.currentTarget;
    setShippingOptionsField(value, name);
  };

  const setShippingOptionsDateField = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = event.currentTarget;

    setShippingOptionsField(value ? new Date(value) : null, name);
  };

  const saveShippingOptions = (): void => {
    if (!disabledSave) {
      setSubmitted(true);

      const payload = {
        delivery_order_shipping_option_attributes: {
          ...shippingOptions,
          load_date: shippingOptions.load_date.toISOString().substring(0, 10),
        },
      };

      customFetch(
        Routes.shipping_options_api_v1_fulfillment_delivery_order_path(deliveryOrder.id),
        payload,
        'POST'
      ).then(() => window.location.reload());
    }
  };

  const disabledSave = useMemo(
    (): boolean =>
      submitted ||
      dispatched ||
      !shippingOptions.transportation_mode ||
      !shippingOptions.incoterm ||
      !shippingOptions.load_date,
    [
      shippingOptions.transportation_mode,
      shippingOptions.incoterm,
      shippingOptions.load_date,
      submitted,
    ]
  );

  return (
    <OverlayWrapper>
      <Overlay>
        <OverlayTitle>
          {I18n.translate('fulfillment.delivery_shipping_options.shipping_options')}
        </OverlayTitle>

        <div className='addressFields'>
          <SelectGroupComponent
            onChange={setShippingOptionsTextField}
            placeholder='Transportation Mode'
            name='delivery_order_shipping_options[transportation_mode]'
            value={shippingOptions.transportation_mode}
            disabled={dispatched}
            options={Object.values(TransportationMode).map((transportationMode) => {
              return {
                id: 'transportation-mode-' + transportationMode,
                name: transportationMode,
                value: transportationMode,
              };
            })}
            data-cy='shipping_options_transportation-mode_input'
          />

          <SelectGroupComponent
            onChange={setShippingOptionsTextField}
            placeholder='Incoterm'
            name='delivery_order_shipping_options[incoterm]'
            value={shippingOptions.incoterm}
            disabled={dispatched}
            options={Object.values(Incoterm).map((incoterm) => {
              return {
                id: 'incoterm-' + incoterm,
                name: incoterm,
                value: incoterm,
              };
            })}
            data-cy='shipping_options_transportation-mode_input'
          />

          <InputGroupComponent
            type='date'
            name='delivery_order_shipping_options[load_date]'
            onChange={setShippingOptionsDateField}
            value={
              shippingOptions.load_date
                ? shippingOptions.load_date.toISOString().substring(0, 10)
                : ''
            }
            disabled={dispatched}
            label='Load Date'
            data-cy='shipping_options_date_input'
          />

          <InputGroupComponent
            name='delivery_order_shipping_options[remarks]'
            onChange={setShippingOptionsTextField}
            value={shippingOptions.remarks}
            disabled={dispatched}
            placeholder='Remarks...'
            data-cy='shipping_options_remarks_input'
            maxLength={60}
          />
        </div>

        <OverlayFooter>
          <FormButton
            className='button button--small'
            onClick={deliveryOrderIndexStore.closeOverlay}
            data-cy='shipping_options_cancel_button'
          >
            Cancel
          </FormButton>

          <FormButton
            className={`button button--small${disabledSave ? ' button--disabled' : ''}`}
            onClick={saveShippingOptions}
            data-cy='shipping_options_save_button'
          >
            Save
          </FormButton>
        </OverlayFooter>
      </Overlay>
    </OverlayWrapper>
  );
}

export default observer(ShippingOptionsOverlay);
