import { observable, action } from 'mobx';
import type QualitiesPriceType from '../types/QualitiesPrice';
import Quality from 'types/model/quality';

type CheckableQualitiesPrice = QualitiesPriceType & {
  checked: boolean;
};

class QualitiesPrice {
  @observable checked: boolean;
  @observable cupPremium?: string;

  id?: number;
  quality: Quality;

  public constructor({ id, checked, cup_premium, quality }: CheckableQualitiesPrice) {
    this.id = id;
    this.checked = checked;
    this.cupPremium = cup_premium;
    this.quality = quality;
  }

  @action setChecked = (value: boolean) => {
    this.checked = value;
  };

  @action setCupPremium = (value: string) => {
    this.cupPremium = value;
  };
}

export default QualitiesPrice;
