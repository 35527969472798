import styled from 'styled-components';
import { COLORS } from 'constants/colors';

export default styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -15px;
  right: -15px;
  border-radius: 50%;
  border: 3px solid ${COLORS.BLUE};
  background: ${COLORS.WHITE};
  font-size: 1rem;
  cursor: pointer;
`;
