import { action, computed, observable } from 'mobx';

import ReprocessType from 'types/model/reprocess';
import Sample from './Sample';

import Routes from 'routes';
import { customFetch } from 'utils/fetch';
import Fraction from './Fraction';
import SampleBuilder from 'utils/builders/SampleBuilder';
import { createSampleFactory } from 'utils/factories/createSampleFactory';

class Reprocess {
  public id: number;
  public fractionId: number;
  public sample: Sample;
  public fraction: Fraction;
  public initial: number;
  @observable public finished: boolean;
  @observable public weight: number;
  @observable public loss: number;
  @observable public millingMachineId?: string;

  constructor(reprocess: ReprocessType, fraction: Fraction) {
    this.id = reprocess.id;
    this.fractionId = reprocess.fraction_id;
    this.fraction = fraction;
    this.millingMachineId = reprocess.milling_machine_id;
    this.weight = reprocess.weight || 0;
    this.loss = reprocess.loss || 0;
    this.initial = reprocess.initial || 0;
    this.finished = reprocess.finished || false;

    const sampleBuilder = new SampleBuilder(reprocess.sample);

    this.sample = createSampleFactory(reprocess.sample, sampleBuilder);
  }

  @action setWeight(weight: number) {
    if (weight <= this.maxReprocessWeight && weight >= 0) {
      this.weight = weight ? weight : 0;
    }
  }

  @action setFinished() {
    this.finished = true;
  }

  @action setMillingMachineId(id: string) {
    this.millingMachineId = id;
  }

  @action synchronize = async () => {
    const route = Routes.api_v1_fraction_fraction_reprocess_path(
      this.fractionId,
      this.id
    );

    const response = await customFetch(
      route,
      {
        weight: this.weight,
        loss: this.totalLoss,
        finished: this.finished,
        milling_machine_id: this.millingMachineId,
      },
      'PATCH'
    );

    if (response.success) {
      // TODO
    } else {
      window.location.reload();
    }
  };

  @computed get totalLoss() {
    return this.initial - this.weight;
  }

  @computed get analysisStarted(): boolean {
    return (
      !!this.sample.current_humidity_analysis?.id ||
      !!this.sample.current_qa_physical_analysis?.id
    );
  }

  @computed get hasWeight(): boolean {
    return this.weight > 0;
  }

  @computed get isFinished(): boolean {
    return this.analysisStarted || this.finished;
  }

  @computed get maxReprocessWeight() {
    let lossWeight = 0;

    const currentReprocessIndex = this.fraction.reprocesses.indexOf(this);

    for (let i = 0; i < currentReprocessIndex; i++) {
      const reprocess = this.fraction.reprocesses[i];

      lossWeight += reprocess.totalLoss;
    }

    return this.fraction.weight - this.fraction.totalRejected - lossWeight;
  }

  @computed get inputValuesSet() {
    return !!this.weight && !!this.millingMachineId;
  }

  @computed get isReprocessable() {
    return (
      this.inputValuesSet &&
      !!this.sample.current_qa_physical_analysis.id &&
      this.sample.current_qa_physical_analysis.state === 'failed'
    );
  }
}

export default Reprocess;
