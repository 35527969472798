import CustomerType from 'customerSamples/types/CustomerType';

export class Customer {
  public id: number;
  public name?: string;

  constructor(customer: CustomerType) {
    this.id = customer.id;
    this.name = customer.name;
  }
}

export default Customer;
