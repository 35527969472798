import { useCallback, useEffect } from 'react';

export const useDebouncedEffect = (effect, delay, deps) => {
  // eslint-disable-next-line
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};

export default useDebouncedEffect;
