import { deserialise } from 'kitsu-core';
import { useEffect, useState } from 'react';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';
import { Contract } from 'samples/types/Ico';

export type SpotContract = Contract & {
  units: number;
  has_existing_spot_sample: boolean;
};

const fetchSpotSampleSpotContracts = (icoId: number) => {
  return customFetch(
    Routes.api_v1_samples_spot_contracts_path({ ico_id: icoId }),
    undefined,
    'GET'
  );
};

const useSpotContracts = (icoId: number) => {
  const [spotContracts, setSpotContracts] = useState<SpotContract[]>([]);

  useEffect(() => {
    fetchSpotSampleSpotContracts(icoId).then((data) => {
      const spotContracts = deserialise(data.sources).data;

      setSpotContracts(spotContracts);
    });
  }, [icoId]);

  return {
    spotContracts,
  };
};

export default useSpotContracts;
