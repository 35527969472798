import { observer } from 'mobx-react';
import React from 'react';
import Routes from 'routes';
import styled from 'styled-components';

import { useFilterContext } from 'utils/filterContext';
import useStores from 'utils/useStores';

import SelectGroup from 'components/groups/SelectGroup';
import Icon from 'components/elements/Icon';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import InputGroupComponent from 'components/groups/InputGroup';
import FilterBar from 'components/elements/FilterBar';
import SelectOptionArray from 'types/model/selectOption';
import Can from 'components/shared/Can';
import placeholder from 'utils/placeholder';
import { addTestingIdentifier } from 'utils/testing';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

const IdCol = styled.td`
  min-width: 12em;
  max-width: 12em;
  width: 12em;
`;

const IconCol = styled.td`
  height: 100%;
  width: 1.5em;
`;

const TotalCol = styled.td`
  width: 7.85em;
`;

const SmallCol = styled.td`
  width: 6.8125em;
`;

interface ProductionOrdersComponentProps {
  years: SelectOptionArray;
  states: SelectOptionArray;
}

const ProductionOrdersComponent: React.FC<ProductionOrdersComponentProps> = ({
  years,
  states,
}) => {
  const { filters, updateFilter } = useFilterContext();
  const { productionOrdersStore } = useStores();

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => updateFilter(event.target.name, event.target.value);

  const filteredOrders = React.useMemo(
    () =>
      productionOrdersStore.productionOrders.filter((productionOrder) =>
        Object.entries(filters).every(([_, { filter, value }]) => {
          if (value) {
            return (
              filter(productionOrder.main_identifier, value) ||
              filter(productionOrder.customer_name, value) ||
              filter(productionOrder.year?.toString(), value) ||
              filter(productionOrder.state, value)
            );
          } else {
            return true;
          }
        })
      ),
    [filters]
  );

  return (
    <>
      <FilterBar columns='15em 12em 10.5em'>
        <InputGroupComponent
          name='identifier'
          type='text'
          placeholder={I18n.translate('milling.production_orders.search_placeholder')}
          onChange={handleOnChange}
          value={filters['identifier'].value}
        />
        <SelectGroup
          name='state'
          onChange={handleOnChange}
          options={states}
          placeholder={I18n.translate('milling.production_orders.select_a_state')}
          value={filters['state'].value}
        />

        <SelectGroup
          name='year'
          onChange={handleOnChange}
          options={years}
          placeholder={I18n.translate('milling.production_orders.select_a_year')}
          value={filters['year'].value}
        />
      </FilterBar>

      <table>
        <thead>
          <tr>
            <IconCol as='th' />

            <IdCol as='th' className='align-left table-border'>
              ID
            </IdCol>

            <TotalCol as='th' className='table-border'>
              {I18n.translate('subproducts.production_orders.total')}
            </TotalCol>

            <SmallCol as='th' className='table-border'>
              {I18n.translate('milling.production_orders.quality')}
            </SmallCol>

            <SmallCol as='th' className='table-border'>
              {I18n.translate('milling.production_orders.status')}
            </SmallCol>

            <th></th>
          </tr>
        </thead>

        <tbody>
          {filteredOrders &&
            filteredOrders.map((productionOrder) => (
              <tr key={productionOrder.id}>
                <IconCol>
                  {productionOrder.editable && (
                    <a
                      href={Routes.edit_subproducts_production_order_path(
                        productionOrder.id
                      )}
                    >
                      <Icon icon='pen' color='#006F94' />
                    </a>
                  )}
                </IconCol>

                <IdCol className='table-border'>
                  <TextWithSub
                    text={productionOrder.main_identifier}
                    sub={I18n.l('time.formats.long', productionOrder.created_at)}
                  />
                </IdCol>

                <TotalCol className='align-right table-border'>
                  {productionOrder.parchment_weight} kg
                </TotalCol>

                <SmallCol className='align-center table-border'>
                  <TextWithSub
                    text={placeholder(productionOrder.quality)}
                    sub={placeholder(productionOrder.grade)}
                  />
                </SmallCol>

                <SmallCol className='align-center table-border'>
                  {productionOrder.milling_order_id ? (
                    <TextWithSub
                      text={I18n.translate(
                        `activerecord.attributes.production_order.aasm_state.${productionOrder.state}`
                      )}
                      sub={productionOrder.milling_order_id}
                    />
                  ) : (
                    <form
                      action={Routes.subproducts_milling_orders_path()}
                      acceptCharset='UTF-8'
                      method='post'
                    >
                      <AuthenticityToken />
                      <input
                        type='hidden'
                        name='production_order_id'
                        value={productionOrder.id}
                      />
                      <Can I='create' a='Subproducts::MillingOrder'>
                        <button
                          type='submit'
                          className='button button--ghost button--small'
                          {...addTestingIdentifier('send_production_order')}
                        >
                          {I18n.translate('buttons.send')}
                        </button>
                      </Can>
                    </form>
                  )}
                </SmallCol>
                <td></td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default observer(ProductionOrdersComponent);
