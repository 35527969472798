import React from 'react';
import ReceptionOption from '../models/ReceptionOption';

interface ReceptionOptionsProps {
  receptionOptions: ReceptionOption[];
  groupLabel: string;
}

function ReceptionOptions({
  receptionOptions,
  groupLabel,
}: ReceptionOptionsProps): JSX.Element {
  return (
    <optgroup label={groupLabel}>
      {receptionOptions.map(({ optionIdentifier, name }) => (
        <option key={optionIdentifier} value={optionIdentifier}>
          {name}
        </option>
      ))}
    </optgroup>
  );
}

export default ReceptionOptions;
