import { Provider } from 'mobx-react';
import React from 'react';

import { equalityFilter } from 'utils/filter';

import DeliveryOrderStore from 'fulfillment/stores/DeliveryOrderStore';
import { DeliveryOrder } from 'fulfillment/types/types';
import FilterContextProvider from 'utils/filterContext';
import ReadyToDeliver from 'fulfillment/components/form/ReadyToDeliver';
import { deserialise } from 'kitsu-core';

interface DeliveryOrdersContainerProps {
  customers: Array<{ id: string; value: string }>;
  destinationWarehouses: Array<{ id: string; value: string }>;
  usedIcoIds: string[];
  usedSpotContractIds: string[];
  deliveryOrderData: any;
}

const DeliveryOrderContainer = ({
  customers,
  destinationWarehouses,
  usedIcoIds,
  usedSpotContractIds,
  deliveryOrderData,
}: DeliveryOrdersContainerProps) => {
  const deliveryOrder: DeliveryOrder | undefined =
    deliveryOrderData === undefined ? undefined : deserialise(deliveryOrderData).data;
  const defaultFilterConfiguration = {
    customer: {
      filter: equalityFilter,
      value: deliveryOrder !== undefined ? String(deliveryOrder.customer.id) : '',
    },
    warehouse: {
      filter: equalityFilter,
      value:
        deliveryOrder !== undefined ? String(deliveryOrder.destination_warehouse.id) : '',
    },
  };

  return (
    <Provider
      deliveryOrderStore={
        new DeliveryOrderStore(
          destinationWarehouses,
          customers,
          usedIcoIds,
          usedSpotContractIds,
          deliveryOrder
        )
      }
    >
      <FilterContextProvider value={defaultFilterConfiguration}>
        <ReadyToDeliver />
      </FilterContextProvider>
    </Provider>
  );
};

export default DeliveryOrderContainer;
