import styled from 'styled-components';

import COLORS from 'constants/colors';

export const TabNav = styled.nav`
  display: flex;
`;

export const TavNavFiller = styled.span`
  flex-grow: 1;
  border-bottom: 1px solid ${COLORS.GRAY};
`;

export interface TabNavItemProps {
  readonly isActive: boolean;
}

export const TabNavItem = styled.span<TabNavItemProps>`
  border-color: ${COLORS.GRAY};
  border-width: 1px;
  border-bottom: 1px solid ${COLORS.GRAY};
  padding: 0.5em 1.5em;
  font-weight: 800;

  ${(props) =>
    props.isActive &&
    `
  color: $black;
  border: 1px solid ${COLORS.GRAY};
  padding: 0.5em 0.8em;
  border-bottom-color: transparent;`}
`;
