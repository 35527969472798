import React from 'react';
import { usePriceContext } from 'contracts/utils/priceContext';
import { ContractTextInput } from './ContractFields';

function Markups() {
  const { importMarkup, otherMarkups } = usePriceContext();

  return (
    <>
      {importMarkup && (
        <ContractTextInput
          field={importMarkup.field}
          value={importMarkup.value}
          type='number'
          step='0.0001'
          min='0.0000'
          onChange={importMarkup.set}
        />
      )}

      {otherMarkups && (
        <ContractTextInput
          field={otherMarkups.field}
          value={otherMarkups.value}
          type='number'
          step='0.0001'
          min='0.0000'
          onChange={otherMarkups.set}
        />
      )}
    </>
  );
}

export default Markups;
