import React from 'react';
import Ico from 'components/elements/Icon';

interface FlashProps {
  icon: string;
  color: string;
  title: string;
  action: () => void;
}

function Flash({ icon, color, title, action }: FlashProps) {
  const handleOnButtonClick = () => {
    action();
  };
  return (
    <ul className='alert js-flash-message' data-cy='alert-list'>
      <li
        onClick={handleOnButtonClick}
        className='alert-message alert-error'
        data-cy='alert_list_item'
      >
        <div>
          <Ico icon={icon} color={color} />
          <span>{title}</span>
        </div>
        <Ico icon={'cross'} color={color} />
      </li>
    </ul>
  );
}

export default Flash;
