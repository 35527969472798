import { observer } from 'mobx-react';
import React from 'react';

import LogEntry from '../models/LogEntry';

interface LogTimeFormProps {
  entry: LogEntry;
}

const LogTimeForm = ({
  entry: {
    setStartDate,
    setEndDate,
    attributes: { start_time, end_time },
  },
}: LogTimeFormProps) => {
  const handleTimeClick = (type: 'start' | 'end') => {
    const setDateMethod = type === 'start' ? setStartDate : setEndDate;

    const dateTime = new Date();
    const formatedDate = `${dateTime.getFullYear()}-${
      dateTime.getMonth() + 1
    }-${dateTime.getDate()}T${dateTime.getHours()}:${dateTime.getMinutes()}`;

    setDateMethod(formatedDate);
  };

  return (
    <>
      {start_time || (
        <button
          className='button button--small button--ghost'
          onClick={() => handleTimeClick('start')}
        >
          {I18n.translate('milling.production_log.start')}
        </button>
      )}
      {' - '}
      {end_time || (
        <button
          className='button button--small button--ghost'
          disabled={!start_time}
          onClick={() => handleTimeClick('end')}
        >
          {I18n.translate('milling.production_log.end')}
        </button>
      )}
    </>
  );
};

export default observer(LogTimeForm);
