import React from 'react';

import { AccordionHeaderProps } from 'shared/Accordion/components/AccordionWrapper';
import AccordionFormHeader from '../AccordionFormHeader';

export interface TransitGuideHeaderProps extends AccordionHeaderProps {
  completed: boolean;
}

const TransitGuideHeader: React.FC<TransitGuideHeaderProps> = ({
  expanded,
  completed,
}) => (
  <AccordionFormHeader
    expanded={expanded}
    headline={I18n.translate('exports.form.transit_guide.header')}
    completed={completed}
  />
);

export default TransitGuideHeader;
