import React from 'react';
import Icon from 'components/elements/Icon';

import { observer } from 'mobx-react';

import TableData from 'customerSamples/components/TableData';
import IconWrapper from 'customerSamples/components/IconWrapper';
import FlexContentWrapper from 'customerSamples/components/FlexContentWrapper';
import SensorialAnalysis from 'customerSamples/models/SensorialAnalysis';

export interface InternalSampleAnalysisProps {
  currentSensorialAnalysis?: SensorialAnalysis;
  analyseAndAdjustPath: string;
  sendToCustomer?: boolean;
}

function InternalSampleAnalysis({
  currentSensorialAnalysis,
  analyseAndAdjustPath,
  sendToCustomer = false,
}: InternalSampleAnalysisProps): JSX.Element {
  return (
    <TableData className='align-center'>
      {currentSensorialAnalysis ? (
        <FlexContentWrapper>
          <span>
            {currentSensorialAnalysis.finalScore} | {currentSensorialAnalysis.grade}
          </span>
          <IconWrapper>
            <a href={analyseAndAdjustPath}>
              <Icon icon='info' color='#006F94' />
            </a>
          </IconWrapper>
        </FlexContentWrapper>
      ) : !sendToCustomer ? (
        <a href={analyseAndAdjustPath} className='button button--small button--ghost'>
          {I18n.translate('customer_samples_dashboard.test_internally')}
        </a>
      ) : (
        '-'
      )}
    </TableData>
  );
}

export default observer(InternalSampleAnalysis);
