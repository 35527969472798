import { Provider } from 'mobx-react';
import React from 'react';
import Routes from 'routes';

import Roasting from 'shared/Roasting';
import OverlayStore from 'stores/OverlayStore';
import { customFetch } from 'utils/fetch';
import type IRoasting from 'types/model/IRoasting';
import SelectOptionArray from 'types/model/selectOption';

export interface RoastingContainerProps {
  lotId: string;
  identifier: string;
  results: SelectOptionArray;
  roasting: IRoasting;
  roastingSampleWeight: number;
  status: boolean;
  text: string;
  icon: string;
}

function RoastingOverlayContainer({
  lotId,
  roastingSampleWeight,
  ...props
}: RoastingContainerProps): JSX.Element {
  const storeParams = { isFilled: !!props.roasting };

  const handleOnPrimaryClick = (_event: React.MouseEvent, values: any) => {
    const method = props.roasting && !values.reroast ? 'PATCH' : 'POST';

    customFetch(Routes.lot_roasting_path(lotId), values, method).then(() => {
      window.location.reload();
    });
  };

  return (
    <Provider overlayStore={new OverlayStore(storeParams)}>
      <Roasting
        onPrimaryClick={handleOnPrimaryClick}
        roastingSampleWeight={roastingSampleWeight}
        {...props}
      />
    </Provider>
  );
}

export default RoastingOverlayContainer;
