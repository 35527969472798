import React, { useMemo } from 'react';
import Checkbox from 'shared/Checkbox';
import { observer } from 'mobx-react';
import Sample from 'customerSamples/models/Sample';
import Can from 'components/shared/Can';
import TableData from 'customerSamples/components/TableData';

export interface SendToCustomerCheckboxProps {
  internalSample: Sample;
  customerSample: Sample;
  showCheckbox: boolean;
}

function SendToCustomerCheckbox({
  internalSample,
  customerSample,
  showCheckbox,
}: SendToCustomerCheckboxProps): JSX.Element {
  const onSendToCustomerChange = (checked: boolean): void => {
    customerSample.customerSampleValue?.save({
      send_to_customer: checked,
      sample_id: customerSample.id,
      id: customerSample.customerSampleValue?.id,
    });
  };

  const checkboxDisabled = useMemo(
    () =>
      Boolean(
        !internalSample.currentSensorialAnalysis ||
          (internalSample.currentSensorialAnalysis &&
            customerSample.customerSampleValue?.customerSampleReasonId) ||
          customerSample.customerSampleValue?.outToCustomer
      ),
    [
      internalSample.currentSensorialAnalysis,
      customerSample.customerSampleValue?.customerSampleReasonId,
      customerSample.customerSampleValue?.outToCustomer,
    ]
  );

  return (
    <Can I='set_send_to_customer' a='CustomerSampleValue'>
      <TableData className='align-center'>
        {showCheckbox && (
          <Checkbox
            icon='tick'
            name={`send_to_customer${customerSample.id}`}
            checked={customerSample.customerSampleValue?.sendToCustomer}
            disabled={checkboxDisabled}
            readOnly={checkboxDisabled}
            onChange={(checked) => onSendToCustomerChange(checked)}
          />
        )}
      </TableData>
    </Can>
  );
}

export default observer(SendToCustomerCheckbox);
