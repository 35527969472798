import React, { useState } from 'react';
import Icon from 'components/elements/Icon';

const ShowHideTable: React.FC = () => {
  const [showTable, setShowTable] = useState(false);

  const toggleTable = (event) => {
    setShowTable(!showTable);
    event.target.closest('tr').nextElementSibling.classList.toggle('hidden');
  };

  return (
    <a onClick={toggleTable}>
      {showTable ? (
        <Icon icon='caret-up' color='#006F94' />
      ) : (
        <Icon icon='caret-down' color='#006F94' />
      )}
    </a>
  );
};

export default ShowHideTable;
