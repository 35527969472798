import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStores from 'utils/useStores';
import useDebouncedEffect from 'utils/useDebouncedEffect';
import SampleEntityRow from 'customerSamples/components/SampleEntityRow';
import SearchBar from 'customerSamples/components/SearchBar';
import CustomerSampleValueOverlay from 'customerSamples/components/CustomerSampleValuesOverlay';

function CustomerSamplesDashboardComponent(): JSX.Element {
  const { customerSamplesDashboardStore } = useStores();
  const [isFetching, setIsFetching] = useState(true);

  const fetchData = async (): Promise<void> => {
    await customerSamplesDashboardStore.fetchOpportunities();
    setIsFetching(false);
  };

  useEffect(() => {
    setIsFetching(true);
    fetchData();
  }, []);

  useDebouncedEffect(
    () => {
      setIsFetching(true);
      fetchData();
    },
    250,
    [
      customerSamplesDashboardStore.search,
      customerSamplesDashboardStore.type,
      customerSamplesDashboardStore.state,
      customerSamplesDashboardStore.originCountryId,
      customerSamplesDashboardStore.dateRange.startMonth,
      customerSamplesDashboardStore.dateRange.startYear,
      customerSamplesDashboardStore.dateRange.endMonth,
      customerSamplesDashboardStore.dateRange.endYear,
    ]
  );

  return (
    <div>
      <SearchBar />

      {isFetching ? (
        I18n.translate('customer_samples_dashboard.fetching_data')
      ) : (
        <>
          {customerSamplesDashboardStore.sampleRowEntities.map((sampleRowEntity) => (
            <SampleEntityRow key={sampleRowEntity.id} sampleEntity={sampleRowEntity} />
          ))}

          {!customerSamplesDashboardStore.sampleRowEntities.length && (
            <span>{I18n.translate('customer_samples_dashboard.no_results')}</span>
          )}
        </>
      )}

      {customerSamplesDashboardStore.selectedSample && (
        <CustomerSampleValueOverlay
          onCloseClick={() => customerSamplesDashboardStore.setSelectedSample(undefined)}
        />
      )}
    </div>
  );
}

export default observer(CustomerSamplesDashboardComponent);
