import styled from 'styled-components';
import COLORS from 'constants/colors';

export interface AccordionItemWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  expandable: boolean;
}

export default styled.div<AccordionItemWrapperProps>`
  width: 100%;
  background: ${COLORS.WHITE};
  padding: 0.25rem 1rem;
  border: 1px solid ${COLORS.LIGHT_GRAY};
  margin-bottom: 0.625rem;
  opacity: ${(props) => (props.expandable ? 1 : 0.5)};

  &:last-child {
    margin-bottom: 0;
  }
`;
