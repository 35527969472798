import Roasting from './Roasting';
import SensorialAnalysis from './SensorialAnalysis';
import HumidityAnalysis from './HumidityAnalysis';
import PhysicalAnalysis from './PhysicalAnalysis';

import { action, observable, computed } from 'mobx';
import ISample from 'millingOrders/types/ISample';

// Attributes are snail_case to work with existing components
class Sample {
  public milling_order_id: number;
  public id: number;
  public main_identifier: string;
  // public coffee_type_id?: number;
  public sample_type: string;
  // public depleted: boolean;
  // public weight_string: string;
  // public weight_string_g: string;
  public initial_weight: number;
  public local_identifier: string;
  public global_identifier: string;
  public grade: number;
  public considered_for_cupping?: boolean;
  @observable current_humidity_analysis: HumidityAnalysis;
  @observable current_qa_physical_analysis: PhysicalAnalysis;
  @observable current_roasting: Roasting;
  @observable current_sensorial_analysis?: SensorialAnalysis;
  @observable weight: number;

  constructor(
    sample: ISample,
    humidityAnalysis: HumidityAnalysis,
    physicalAnalysis: PhysicalAnalysis,
    roasting: Roasting,
    sensorialAnalysis?: SensorialAnalysis
  ) {
    this.id = sample.id;
    // this.coffee_type_id = sample.coffee_type_id;
    this.sample_type = sample.sample_type;
    this.local_identifier = sample.local_identifier;
    this.global_identifier = sample.global_identifier;
    this.main_identifier = sample.main_identifier;
    // this.depleted = sample.depleted;
    // this.weight_string = sample.weight_string;
    // this.weight_string_g = sample.weight_string_g;
    this.initial_weight = sample.initial_weight;
    this.weight = sample.weight;
    this.grade = sample.grade;
    this.considered_for_cupping = sample.considered_for_cupping;
    this.milling_order_id = sample.milling_order_id;

    this.current_humidity_analysis = humidityAnalysis;
    this.current_qa_physical_analysis = physicalAnalysis;
    this.current_roasting = roasting;
    this.current_sensorial_analysis = sensorialAnalysis;
  }

  @action setCurrentRoasting(roasting: Roasting) {
    this.current_roasting = roasting;
  }

  @action setCurrentSensorialAnalysis(sensorialAnalysis: SensorialAnalysis | undefined) {
    this.current_sensorial_analysis = sensorialAnalysis;
  }

  @action setCurrentHumidityAnalysis(humidityAnalysis: HumidityAnalysis) {
    this.current_humidity_analysis = humidityAnalysis;
  }

  @action setCurrentPhysicalAnalysis(physicalAnalysis: PhysicalAnalysis) {
    this.current_qa_physical_analysis = physicalAnalysis;
  }

  @computed get failed(): boolean {
    return (
      this.current_humidity_analysis.failed ||
      this.current_qa_physical_analysis.failed ||
      this.current_roasting.failed ||
      (!!this.current_sensorial_analysis && this.current_sensorial_analysis.failed)
    );
  }

  @computed get passed(): boolean {
    return (
      this.current_humidity_analysis.passed &&
      this.current_qa_physical_analysis.passed &&
      this.current_roasting.passed &&
      (this.current_sensorial_analysis?.passed || false)
    );
  }
}

export default Sample;
