import { action, computed, observable } from 'mobx';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';

export interface Future {
  name: string;
  price: number;
}

export interface PriceFixingData {
  date: Date;
  price: number;
}

export interface Bundle {
  id: number;
  futuresPosition: string;
  frontendDate: string;
  contracts: Pick<Contract, 'mainId' | 'volume' | 'units' | 'capacity'>[];
  shipperFixing?: PriceFixingData;
  sellerFixing?: PriceFixingData;
  unrealised?: string;
  realised?: string;
  exported: boolean;
  changedContracts: string[];
  originCountries: string[];
}

export interface Contract {
  id: number;
  mainId: string;
  volume: number;
  units: number;
  capacity: number;
  futuresPosition: string;
  frontendDate: string;
  originCountry: string;
}

export interface ModalProps {
  bundle: Bundle;
  future: string;
  editFixed: boolean;
}

export interface Filter {
  mainIdentifier?: string;
  year?: number;
  realised?: boolean;
}

class FutureStore {
  public contracts = observable.map<number, Contract>();
  public bundles = observable.map<number, Bundle>();

  public selectedContracts = observable.set<number>();
  @observable public priceFixingModal?: ModalProps;

  @observable public currentFuturePosition: string;

  @observable public filter: Filter = {
    realised: false,
    mainIdentifier: '',
  };

  @observable public totalUnrealised: string;
  @observable public totalRealised: string;

  public futures = Array<Future>();
  public years = Array<number>();

  constructor(
    futures: Array<Future>,
    contracts: Array<Contract>,
    bundles: Array<Bundle>,
    years: Array<number>,
    filter: Partial<Filter>,
    totalUnrealised: string,
    totalRealised: string
  ) {
    this.futures = futures;
    this.years = years;
    this.totalUnrealised = totalUnrealised;
    this.totalRealised = totalRealised;

    this.currentFuturePosition = '';

    // Creating a map of contracts to have an index
    const mappedContracts = contracts.reduce(
      (acc, item) => acc.set(item.id, item),
      new Map()
    );

    this.contracts = observable.map(mappedContracts);

    // Creating a map of bundles to have an index
    const mappedBundles = bundles.reduce(
      (acc, item) => acc.set(item.id, item),
      new Map()
    );

    this.bundles = observable.map(mappedBundles);

    this.applyFilter(filter);
    if (filter['main_identifier']) {
      this.filter.mainIdentifier = filter['main_identifier'];
    }
  }
  // Computed properties

  @computed get listContracts(): Array<Contract> {
    return Array.from(this.contracts.values());
  }

  @computed get listBundles(): Array<Bundle> {
    return Array.from(this.bundles.values());
  }

  @computed get identifier(): string {
    return this.filter.mainIdentifier || '';
  }

  // Actions

  @action private applyFilter = (filter: Partial<Filter>) => {
    if (filter.realised !== undefined) {
      this.filter.realised = filter.realised;
    }

    if (filter.year !== undefined) {
      this.filter.year = filter.year;
    }
  };

  @action public execFilter = (filter: Partial<Filter>) => {
    this.applyFilter(filter);

    if (
      filter.mainIdentifier !== '' &&
      (filter.mainIdentifier || this.filter.mainIdentifier)
    ) {
      this.filter['main_identifier'] =
        filter?.mainIdentifier?.trim() || this.filter.mainIdentifier?.trim();
    } else {
      this.filter['main_identifier'] = '';
    }

    this.filter.mainIdentifier = '';
    const urlParams = new URLSearchParams(window.location.search);

    for (const key in this.filter) {
      if (Object.prototype.hasOwnProperty.call(this.filter, key)) {
        if (this.filter[key]) {
          urlParams.set(key, this.filter[key]);
        } else {
          urlParams.delete(key);
        }
      }
    }

    window.location.search = urlParams.toString();
  };

  @action public toggleOverlay = (props?: ModalProps) => {
    this.priceFixingModal = props;
  };

  @action public toggleSelectContract = (id: number) => {
    const contract = this.contracts.get(id);

    if (!contract) {
      return;
    }

    this.currentFuturePosition = contract.futuresPosition;

    if (this.selectedContracts.has(id)) {
      this.selectedContracts.delete(id);
    } else {
      this.selectedContracts.add(id);
    }

    if (this.selectedContracts.size === 0) {
      this.currentFuturePosition = '';
    }
  };

  @action public fixOnBundle = (
    bundleId: number,
    fixingType: 'shipperFixing' | 'sellerFixing',
    data: PriceFixingData
  ) => {
    const bundle = this.bundles.get(bundleId);

    if (!bundle) {
      return;
    }

    bundle[fixingType] = data;

    let body;

    if (fixingType === 'sellerFixing') {
      body = {
        seller_fixing_price: data.price,
        seller_fixing_date: data.date,
      };
    } else if (fixingType === 'shipperFixing') {
      body = {
        shipper_fixing_price: data.price,
        shipper_fixing_date: data.date,
      };
    }

    // update bundle and get new unrealised/realised values
    customFetch(Routes.api_v1_bundle_path(bundle.id), body, 'PATCH').then((res) => {
      bundle.unrealised = res.unrealised;
      bundle.realised = res.realised;

      if (res.success) {
        this.updateBundle(bundleId, bundle);
      } else {
        window.location.reload();
      }
    });
  };

  @action public updateBundle = (bundleId: number, bundle: Bundle) => {
    this.bundles.set(bundleId, bundle);
  };
}

export default FutureStore;
