export function parseTimeInput(value: string): string {
  const containsTimeFormat = value.includes(':');
  try {
    if (containsTimeFormat) {
      return rectifyTime(value);
    } else {
      return integerToTime(value);
    }
  } catch {
    return '';
  }
}

export function integerToTime(value: string): string {
  if (value && !value.includes(':')) {
    const inputNum = parseInt(value, 10);
    const minutes = String(Math.trunc(inputNum / 60)).padStart(2, '0');
    const seconds = String(inputNum % 60).padStart(2, '0');

    const paddedTime = [minutes, seconds].join(':');

    return paddedTime;
  } else {
    throw new Error('Value is not an integer');
  }
}

export function rectifyTime(value: string): string {
  if (value && value.includes(':')) {
    return secondsToTime(timeToSeconds(padTime(value)));
  } else {
    throw new Error('Not a valid time');
  }
}

export function padTime(value: string): string {
  const parts = value.split(':');
  const paddedTime = parts.map((part) => part.padStart(2, '0')).join(':');

  return paddedTime;
}

export function secondsToTime(s: number): string {
  return ((s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s).padStart(5, '0');
}

// Possible values:
// SS
// MM:SS
// HH:MM:SS
export function timeToSeconds(time: string, iteration = 0): number {
  const delimiter = time.lastIndexOf(':');
  const multiplier = Math.pow(60, iteration);

  if (!~delimiter) {
    return parseInt(time, 10) * multiplier;
  } else {
    const lastTimePortion = time.slice(delimiter + 1, time.length);
    const restTimePortion = time.slice(0, delimiter);

    return (
      parseInt(lastTimePortion) * multiplier +
      timeToSeconds(restTimePortion, iteration + 1)
    );
  }
}
