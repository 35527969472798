import React, { useState } from 'react';
import Select, { Props } from 'react-select';

export interface Option {
  value: number;
  label: string;
  meta?: any;
}

export interface MultiOptions {
  label: string;
  value?: number; // this is not really a thing. make ts happy
  options: Array<Option>;
}

interface SearchProps extends Props {
  options: (Option | MultiOptions)[];
  onClear?: () => void;
  onChange?: (value) => void;
}

export const customSelectStyles = {
  control: (provided, _state) => ({
    ...provided,
    border: 'none',
    height: '100%',
  }),

  input: (provided, _state) => ({
    ...provided,
    boxShadow: '0',
    maxHeight: '100%',
  }),

  valueContainer: (provided, _state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '0 0.5em',
  }),

  menu: (provided, _state) => ({
    ...provided,
    left: 0,
    width: '28em',
  }),

  option: (provided, _state) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

function Search({
  value,
  name,
  options,
  onChange,
  onClear,
  isDisabled = false,
  isClearable = true,
  ...rest
}: SearchProps): JSX.Element {
  const [selectedOption, setSelectedOption] = useState(
    value ? options.find((opt) => opt.value === value) : null
  );

  const handleOnChange = (selected: Option) => {
    setSelectedOption(selected);

    if (onClear && selected === null) {
      return onClear();
    }

    if (onChange && selected) {
      onChange(selected);
    }
  };

  return (
    <Select
      name={name}
      styles={customSelectStyles}
      className='select react-select'
      classNamePrefix='react-select'
      isClearable={isClearable}
      isDisabled={isDisabled}
      value={selectedOption}
      onChange={(selected: any) => handleOnChange(selected as Option)}
      options={options}
      placeholder={prompt}
      {...rest}
    />
  );
}

export default Search;
