import React from 'react';
import Routes from 'routes';
import BaseFields from 'samples/create/components/BaseFields';
import SampleMarkSelect from 'samples/create/components/SampleMarkSelect';
import SampleOpportunitySelect from 'samples/create/components/SampleOpportunitySelect';
import SampleSourceSelect from 'samples/create/components/SampleSourceSelect';

const CreateOfferSample = (): JSX.Element => (
  <>
    <SampleOpportunitySelect />
    <SampleMarkSelect />
    <SampleSourceSelect
      sourcesPath={Routes.offer_sample_sources_api_v1_samples_path}
      method='GET'
    />
    <BaseFields showCoffeeTypeSelection={false} />
  </>
);
export default CreateOfferSample;
