import IFinalizeableResource from 'exports/types/IFinalizeableResource';
import { computed, observable } from 'mobx';
import type { Transport as ITransport, TransportState } from '../types/transport';
class Transport implements IFinalizeableResource {
  @observable state: TransportState;

  public constructor(transport: ITransport) {
    this.state = transport.state;
  }

  @computed get finalized(): boolean {
    return this.state === 'completed';
  }
}

export default Transport;
