import { MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';

import AnalyseAndAdjustStore from 'samples/analyse_and_adjust/stores/AnalyseAndAdjustStore';
import AvailableCoffeeStore from 'availableCoffee/stores/AvailableCoffeeStore';
import AvailableExcessOrOrphanCoffeeStore from 'availableExcessOrOrphanCoffee/stores/AvailableExcessOrOrphanCoffeeStore';
import CalibrationStore from 'calibration/stores/CalibrationStore';
import ConsolidateSamplesStore from 'samples/consolidate/stores/ConsolidateSamplesStore';
import CuppingSessionStore from 'cuppingSession/stores/CuppingSessionStore';
import CuppingTableStore from 'cuppingTable/store/CuppingTableStore';
import CustomerSamplesDashboardStore from 'customerSamples/stores/CustomerSamplesDashboardStore';
import CustomerStore from 'customers/stores/CustomerStore';
import DeliveryOrderIndexStore from 'fulfillment/stores/DeliveryOrderIndexStore';
import DeliveryOrderStore from 'fulfillment/stores/DeliveryOrderStore';
import ExcelsoPhysicalAnalysisStore from 'qaPhysicalAnalyses/stores/ExcelsoPhysicalAnalysisStore';
import ExportsStore from 'exports/stores/ExportsStore';
import FileUploaderStore from 'shared/FileUploader/stores/FileUploaderStore';
import FractionsStore from 'productionOrders/stores/FractionsStore';
import FutureStore from 'futures/stores/FutureStore';
import LegStore from 'transports/stores/LegStore';
import MachinePhysicalAnalysisStore from 'qaPhysicalAnalyses/stores/MachinePhysicalAnalysisStore';
import MillingOrderQaStore from 'millingOrders/stores/MillingOrderQaStore';
import OpportunityStore from 'opportunities/stores/opportunityStore';
import OverlayStore from 'stores/OverlayStore';
import OverviewTableStore from 'cuppingSession/stores/OverviewTableStore';
import PaginatedTableStore from 'components/shared/PaginatedTable/stores/PaginatedTableStore';
import PeabiesPhysicalAnalysisStore from 'qaPhysicalAnalyses/stores/PeabiesPhysicalAnalysisStore';
import ProductionLogStore from 'millings/productionLog/stores/ProductionLogStore';
import ProductionOrdersStore from 'subproducts/stores/SubproductProductionOrdersStore';
import AsyncProductionOrdersStore from 'millings/productionOrders/AsyncProductionOrder/stores/AsyncProductionOrdersStore';
import QualitiesPricesStore from 'fixedPriceContracts/stores/QualitiesPricesStore';
import QualityAssuranceStore from 'decaf/stores/QualityAssuranceStore';
import ReceiveSampleTranportsStore from 'samples/receive_samples/stores/ReceiveSampleTransportsStore';
import TransportSamplesStore from 'samples/send_samples_transport/stores/TransportSamplesStore';
import SetStore from 'sets/stores/SetStore';
import SubproductsStore from 'subproducts/stores/SubproductsStore';
import TransportStore from 'transports/stores/TransportStore';
import UnloadStore from 'transports/stores/UnloadStore';
import PhysicalAnalysisStore from 'stores/PhysicalAnalysisStore';
import WetPhysicalAnalysisStore from 'wetPhysicalAnalysis/stores/WetPhysicalAnalysisStore';
export interface Stores {
  analyseAndAdjustStore: AnalyseAndAdjustStore;
  asyncProductionOrdersStore: AsyncProductionOrdersStore;
  availableCoffeeStore: AvailableCoffeeStore;
  availableExcessOrOrphanCoffeeStore: AvailableExcessOrOrphanCoffeeStore;
  calibrationStore: CalibrationStore;
  consolidateSamplesStore: ConsolidateSamplesStore;
  cuppingSessionStore: CuppingSessionStore;
  cuppingTableStore: CuppingTableStore;
  customerSamplesDashboardStore: CustomerSamplesDashboardStore;
  customerStore: CustomerStore;
  deliveryOrderIndexStore: DeliveryOrderIndexStore;
  deliveryOrderStore: DeliveryOrderStore;
  excelsoPhysicalAnalysisStore: ExcelsoPhysicalAnalysisStore;
  exportsStore: ExportsStore;
  fileUploaderStore: FileUploaderStore;
  fractionsStore: FractionsStore;
  futuresStore: FutureStore;
  legStore: LegStore;
  machinePhysicalAnalysisStore: MachinePhysicalAnalysisStore;
  millingOrderQaStore: MillingOrderQaStore;
  opportunityStore: OpportunityStore;
  overlayStore: OverlayStore;
  overviewTableStore: OverviewTableStore;
  paginatedTableStore: PaginatedTableStore<any>;
  peabiesPhysicalAnalysisStore: PeabiesPhysicalAnalysisStore;
  physicalAnalysisStore: PhysicalAnalysisStore;
  productionLogStore: ProductionLogStore;
  productionOrdersStore: ProductionOrdersStore;
  qualitiesPricesStore: QualitiesPricesStore;
  qualityAssuranceStore: QualityAssuranceStore;
  receiveSampleTransportsStores: ReceiveSampleTranportsStore;
  setStore: SetStore;
  subproductsStore: SubproductsStore;
  transportSamplesStore: TransportSamplesStore;
  transportStore: TransportStore;
  unloadStore: UnloadStore;
  wetPhysicalAnalysisStore: WetPhysicalAnalysisStore;
}

export function useStores<T = Stores>(): T {
  return useContext(MobXProviderContext) as T;
}

export default useStores;
