import { observer } from 'mobx-react';
import React from 'react';

import { useFilterContext } from 'utils/filterContext';
import useStores from 'utils/useStores';

import InventoryTable from './InventoryTable';
import SelectedInventory from './SelectedInventory';

function TransportLegIndex() {
  const { transportStore } = useStores();
  const { filters } = useFilterContext();

  const filteredInventory = React.useMemo(
    () =>
      transportStore.transportables.filter((item) =>
        Object.entries(filters).every(([_, { filter, value }]) => {
          if (value) {
            return (
              filter(item.attributes.localIdentifier, value) ||
              filter(item.attributes.producer, value) ||
              filter(item.attributes.certification, value)
            );
          } else {
            return true;
          }
        })
      ),
    [filters]
  );

  return (
    <>
      <SelectedInventory />

      <InventoryTable inventoryItems={filteredInventory} />
    </>
  );
}

export default observer(TransportLegIndex);
