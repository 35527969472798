import Icon from 'components/elements/Icon';
import SpaceBetween from 'components/elements/SpaceBetween';
import COLORS from 'constants/colors';
import { observer } from 'mobx-react';
import React from 'react';
import SampleTransportModel from 'samples/receive_samples/models/SampleTransport';
import { TileDisclosure, TileHeader } from 'shared/Tile';
import styled from 'styled-components';
import { addTestingIdentifier } from 'utils/testing';

const UnloadHeadline = styled.div`
  h2 {
    display: inline-block;
    margin-right: 0.3125em;
  }
`;

interface ReceiveSampleTransportHeaderProps {
  collapsed: boolean;
  handleCollapsible: (boolean) => void;
  transport: SampleTransportModel;
}

function ReceiveSampleTransportHeader({
  collapsed,
  handleCollapsible,
  transport,
}: ReceiveSampleTransportHeaderProps): JSX.Element {
  const { created_at, origin, courier, tracking_id, hand_delivered } =
    transport.attributes;

  const handleCloseTransport = () => {
    transport.closeTransport();
  };

  return (
    <TileHeader>
      <TileDisclosure
        collapsed={collapsed}
        handleCollapsible={() => handleCollapsible(!collapsed)}
      />
      <SpaceBetween alignCenter={true}>
        <UnloadHeadline>
          <h2>
            {I18n.l('time.formats.long', created_at)} {origin?.name}
          </h2>
          {courier && `${courier.name} - ${tracking_id}`}
          {hand_delivered && I18n.translate('samples.create_transport.hand_delivered')}
        </UnloadHeadline>
        {transport.closed && <Icon icon='check' color={COLORS.GREEN} />}
        {!transport.closed && (
          <button
            className='button'
            disabled={!transport.unloaded || transport.submitted}
            onClick={handleCloseTransport}
            {...addTestingIdentifier('close_transport_button')}
          >
            {I18n.translate('samples.receive.close_sample_transport')}
          </button>
        )}
      </SpaceBetween>
    </TileHeader>
  );
}

export default observer(ReceiveSampleTransportHeader);
