import React from 'react';
import styled from 'styled-components';

import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

import LogEntry from 'millings/productionLog/models/LogEntry';

import LogTimeForm from 'millings/productionLog/components/LogTimeForm';
import ProductionLogObservations from 'millings/productionLog/components/ProductionLogObservations';
import ProductionLogFraction from 'millings/productionLog/components/ProductionLogFraction';
import ProductionLogMachine from 'millings/productionLog/components/ProductionLogMachine';
import { observer } from 'mobx-react';

export const OperatorColumn = styled.td`
  width: 14em;
  min-width: 14em;
  max-width: 14em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ObservationColumn = styled.td`
  width: 16em;
  max-width: 16em;
  padding: 0.4375em 0;
  textarea {
    min-height: auto;
    height: 4.5em;
  }
`;

export const LogTimeColumn = styled.td`
  width: 10em;
  max-width: 10em;
  text-align: center;
`;

interface LogEntryRow {
  entry: LogEntry;
}

const LogEntryRow = ({
  entry,
  entry: {
    attributes: { start_date, meridiem, end_date, open, operator_name },
  },
}: LogEntryRow) => {
  let startDate = '-';
  let merdiemValue = '-';

  if (start_date && meridiem) {
    startDate = I18n.l('time.formats.day_month', start_date);
    merdiemValue = meridiem;
  }

  return (
    <tr>
      <OperatorColumn className='table-border'>{operator_name}</OperatorColumn>
      <td className='table-border'>
        <ProductionLogMachine entry={entry} />
      </td>
      <td className='align-center'>
        <TextWithSub text={startDate} sub={merdiemValue} />
      </td>
      <LogTimeColumn className='table-border'>
        <LogTimeForm entry={entry} />
      </LogTimeColumn>
      <td className='table-border align-center'>
        <ProductionLogFraction entry={entry} />
      </td>
      <ObservationColumn>
        <ProductionLogObservations entry={entry} />
      </ObservationColumn>
      <td>
        {start_date && end_date && open && (
          <button
            onClick={() => entry.close()}
            className='button button--small button--ghost'
          >
            {I18n.translate('buttons.close')}
          </button>
        )}
      </td>
    </tr>
  );
};

export default observer(LogEntryRow);
