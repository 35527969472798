import classNames from 'classnames';
import BaseGroupComponent from 'components/groups/BaseGroup';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import SampleTransportModel from 'samples/receive_samples/models/SampleTransport';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import placeholder from 'utils/placeholder';
import { addTestingIdentifier } from 'utils/testing';
import SampleModel from 'samples/receive_samples/models/SampleModel';
import useStores from 'utils/useStores';
import ReceptionOptions from 'samples/receive_samples/components/ReceptionOptions';
import ReceptionIcon from 'samples/receive_samples/components/ReceptionIcon';

interface ReceiveSampleRowProps {
  sample: SampleModel;
  transport: SampleTransportModel;
}

function ReceiveSampleRow({ sample, transport }: ReceiveSampleRowProps): JSX.Element {
  const { receiveSampleTransportsStores } = useStores();
  const { receptionOptions } = receiveSampleTransportsStores;

  const valid = useMemo(() => {
    switch (sample.receptionOption?.type) {
      case undefined:
        return undefined;
      case 'loss':
      case 'damage':
      case 'wrong':
        return false;
      case 'success':
      default:
        return true;
    }
  }, [sample.receptionOption?.type]);

  const handleOnReceptionOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const optionIdentifier = e.target.value;

    if (optionIdentifier === '') {
      transport.removeUnloadedSample(sample);
    } else {
      const receptionOption =
        receiveSampleTransportsStores.findReceptionOption(optionIdentifier);
      receptionOption && transport.receiveSample(sample, receptionOption);
    }
  };

  const weightClassname = classNames('table-border', 'align-center', {
    strikethrough: valid === false,
  });

  return (
    <tr>
      <td className='align-left lot--id'>
        <TextWithSub
          text={sample.attributes.identifier}
          sub={sample.attributes.parent_identifier || ''}
        />
      </td>

      <td className='table-border align-left'>
        <TextWithSub
          maxTextLength={15}
          text={placeholder(sample.attributes.customer || sample.attributes.producer)}
          sub={sample.attributes.certification}
        />
      </td>

      <td className='table-border align-center'>
        <TextWithSub
          text={I18n.translate(
            `activerecord.attributes.sample.sample_type.${sample.attributes.sample_type}`
          )}
          sub={sample.attributes.mark}
        />
      </td>

      <td className='table-border align-center'>
        <TextWithSub text={sample.attributes.quality} sub={sample.attributes.grade} />
      </td>

      <td className={weightClassname}>{sample.attributes.weight}</td>

      <td className='table-border align-center'>
        <TextWithSub
          text={sample.attributes.bin}
          sub={placeholder(sample.attributes.sample_request_identifier)}
        />
      </td>

      <td className='table-border flex'>
        <BaseGroupComponent noMargin={true} condensed={true}>
          <select
            disabled={transport.closed}
            className='input--condensed'
            onChange={handleOnReceptionOptionChange}
            {...addTestingIdentifier('receive_sample_status')}
            value={sample.receptionOption?.optionIdentifier || ''}
          >
            <option value=''>{I18n.translate('samples.receive.select_a_status')}</option>

            <ReceptionOptions
              receptionOptions={receptionOptions.received}
              groupLabel={I18n.translate('samples.receive.reception_ok')}
            />

            <ReceptionOptions
              receptionOptions={receptionOptions.damage}
              groupLabel={I18n.translate('samples.receive.reception_damage')}
            />

            <ReceptionOptions
              receptionOptions={receptionOptions.loss}
              groupLabel={I18n.translate('samples.receive.loss_of_coffee')}
            />

            <ReceptionOptions
              receptionOptions={receptionOptions.wrong}
              groupLabel={I18n.translate('samples.receive.wrong_data')}
            />
          </select>
        </BaseGroupComponent>

        <ReceptionIcon valid={valid} />
      </td>
    </tr>
  );
}

export default observer(ReceiveSampleRow);
