import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import { VARIANT } from 'constants/inputTypes';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { SampleDataContext } from 'samples/create/components/CreateSample';

interface IBaseFieldsProps {
  showCoffeeTypeSelection: boolean;
}

const BaseFields = ({ showCoffeeTypeSelection }: IBaseFieldsProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { coffee_types } = useContext(SampleDataContext);

  return (
    <>
      <div className='l-item-stack-horizontal l-item-stack-horizontal--wide'>
        <InputGroupComponent
          data-cy='samples_create_sample_weight'
          label={I18n.translate('samples.create.sample_weight')}
          append='gr'
          required={true}
          variant={VARIANT.DECIMAL}
          errors={errors?.sample_weight?.message}
          ref={register}
          name='sample[base_weight][value]'
          defaultValue={'1500'}
        />
        <input
          type='hidden'
          name='sample[base_weight][unit]'
          ref={register}
          value='unit_g'
        />
        {showCoffeeTypeSelection && (
          <SelectGroup
            data-cy='samples_create_coffee_type'
            label={I18n.translate('samples.create.coffee_type')}
            placeholder={I18n.translate('samples.create.select_coffee_type_placeholder')}
            options={coffee_types}
            ref={register}
            required={true}
            name='sample[coffee_type_id]'
          />
        )}
      </div>
      <TextAreaGroupComponent
        data-cy='samples_create_observations'
        label={I18n.translate('samples.create.observations')}
        ref={register}
        name='sample[observation]'
      />
    </>
  );
};
export default BaseFields;
