import React from 'react';

interface ErrorProps {
  errors: Array<string>;
  [key: string]: any;
}

function ErrorsComponent({ errors = [], ...rest }: ErrorProps) {
  if (errors.length === 0) {
    return null;
  }

  return (
    <ul data-cy='error-container' {...rest}>
      {errors.map((error) => (
        <li key={error}>
          <b className='error'>{error}</b>
        </li>
      ))}
    </ul>
  );
}

export default ErrorsComponent;
