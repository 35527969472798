import React from 'react';

import type { IcoTraceability as IcoTraceabilityType } from '../types/traceability';

import IcoTraceabilityHeader from './IcoTraceabilityHeader';
import IcoTraceabilityBody from './IcoTraceabilityBody';

export interface TraceabilityItemProps extends React.HTMLAttributes<HTMLDivElement> {
  traceability: IcoTraceabilityType;
}

const IcoTraceability = ({ traceability }: TraceabilityItemProps) => {
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <div>
      <IcoTraceabilityHeader
        traceability={traceability}
        collapsed={collapsed}
        onCollapsibleHandler={(_event: React.MouseEvent) => setCollapsed(!collapsed)}
      />

      {!collapsed && (
        <IcoTraceabilityBody
          assignedCoffees={traceability.assigned_coffees}
          collapsed={collapsed}
        />
      )}
    </div>
  );
};

export default IcoTraceability;
