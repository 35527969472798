import { computed, observable } from 'mobx';
import Routes from 'routes';

import type IShipmentSettings from '../types/IShipmentSettings';
import ShipmentInstruction from './ShipmentInstruction';
import ShipmentInstructionType from '../types/shipmentInstruction';
import TransitGuide from './TransitGuide';
import TransitGuideType from '../types/transitGuide';
import FinalizeService from '../services/FinalizeService';
import ResponsibilityLetter from './ResponsibilityLetter';
import ResponsibilityLetterType from '../types/responsibilityLetter';
import ImporterSecurityFillingType from 'exports/types/importerSecurityFilling';
import ImporterSecurityFilling from './ImporterSecurityFilling';
import IcoList from 'exports/models/IcoList';
import IcoListType from 'exports/types/icoList';
import Export from './Export';
import Transport from 'exports/models/Transport';

class ShipmentSettings {
  @observable public shipmentInstruction: ShipmentInstruction;
  @observable public transitGuide: TransitGuide;
  @observable public responsibilityLetter: ResponsibilityLetter;
  @observable public importerSecurityFilling: ImporterSecurityFilling;
  @observable public icoList: IcoList;
  @observable public transport?: Transport;

  constructor(
    {
      shipmentInstruction,
      transitGuide,
      responsibilityLetter,
      importerSecurityFilling,
      transport,
      icoList,
    }: IShipmentSettings,
    export_: Export
  ) {
    const shipmentInstructionService = new FinalizeService<ShipmentInstructionType>(
      export_,
      Routes.api_v1_export_shipment_instructions_path,
      Routes.api_v1_shipment_instruction_path,
      Routes.finalize_api_v1_shipment_instruction_path
    );

    this.shipmentInstruction = new ShipmentInstruction(
      { finalized: false, ...shipmentInstruction },
      shipmentInstructionService
    );

    const transitGuideService = new FinalizeService<TransitGuideType>(
      export_,
      Routes.api_v1_export_transit_guides_path,
      Routes.api_v1_transit_guide_path,
      Routes.finalize_api_v1_transit_guide_path
    );

    this.transitGuide = new TransitGuide(
      { finalized: false, ...transitGuide },
      transitGuideService
    );

    const responsibilityLetterService = new FinalizeService<ResponsibilityLetterType>(
      export_,
      Routes.api_v1_export_responsibility_letters_path,
      Routes.api_v1_responsibility_letter_path,
      Routes.finalize_api_v1_responsibility_letter_path
    );

    this.responsibilityLetter = new ResponsibilityLetter(
      { finalized: false, ...responsibilityLetter },
      responsibilityLetterService
    );

    const importerSecurityFillingService =
      new FinalizeService<ImporterSecurityFillingType>(
        export_,
        Routes.api_v1_export_importer_security_fillings_path,
        Routes.api_v1_importer_security_filling_path,
        Routes.finalize_api_v1_importer_security_filling_path
      );

    this.importerSecurityFilling = new ImporterSecurityFilling(
      { finalized: false, ...importerSecurityFilling },
      importerSecurityFillingService
    );

    if (transport) {
      this.transport = new Transport(transport);
    }

    const icoListService = new FinalizeService<IcoListType>(
      export_,
      Routes.api_v1_export_ico_lists_path,
      Routes.api_v1_ico_list_path,
      Routes.finalize_api_v1_ico_list_path
    );

    this.icoList = new IcoList({ finalized: false, ...icoList }, icoListService);
  }

  @computed get allDocumentsFinalized() {
    return (
      this.shipmentInstruction.finalized &&
      this.transitGuide.finalized &&
      this.responsibilityLetter.finalized &&
      this.importerSecurityFilling.finalized
    );
  }
}

export default ShipmentSettings;
