import Routes from 'routes';
import { customFetch } from 'utils/fetch';

export type ContractPriceParams = {
  markId: string;
  qualityId: string;
  currency: string;
  unitOfMeasurement: string;
  customerId?: string;
};

export type ContractPriceResponse = {
  contract_price: {
    id: null | number;
    unit_of_measurement: string;
    price_currency: string;
    quality_id: number;
    mark_id: number;
    destination_offce_id: number;
    fixed_price: string;
    differential: string;
  };
};

export async function fetchContractPrice({
  markId,
  qualityId,
  customerId,
  unitOfMeasurement,
  currency,
}: ContractPriceParams): Promise<ContractPriceResponse> {
  const body = {
    mark_id: markId,
    quality_id: qualityId,
    customer_id: customerId,
    unit_of_measurement: unitOfMeasurement,
    price_currency: currency,
  };

  return customFetch<ContractPriceResponse>(
    Routes.api_v1_contract_prices_path(body),
    undefined,
    'GET'
  );
}
