import styled from 'styled-components';

const InputColumn = styled.td`
  .input-group--inline,
  .input-group__row {
    width: 8em;
  }
`;

export default InputColumn;
