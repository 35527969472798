import { observable, computed, action } from 'mobx';

import ShipmentInstructionType from '../types/shipmentInstruction';
import FinalizeableResource from './FinalizableResource';
import Routes from 'routes';

class ShipmentInstruction extends FinalizeableResource<ShipmentInstructionType> {
  public static readonly VALID_DOCUMENTS = [
    'customs_officer_id',
    'consignee_id',
    'notification_id',
    'second_notification_id',

    'shipping_line_id',
    'shipping_mode_id',

    'voyage_number',
    'vessel_name',
    'reservation_number',

    'container_type_id',

    'insurance_id',
    'freight_id',
    'nature_of_operation_id',
    'payment_form_id',

    'bill_of_lading_print_instruction',
  ];

  public static readonly REQUIRED_DOCUMENTS = [...this.VALID_DOCUMENTS];

  @observable public finalized: boolean;

  @observable public customs_officer_id?: number;
  @observable public consignee_id?: number;
  @observable public notification_id?: number;
  @observable public second_notification_id?: number;

  @observable public shipping_line_id?: number;
  @observable public shipping_line_contract_id?: number;
  @observable public shipping_mode_id?: number;

  @observable public voyage_number?: string;
  @observable public vessel_name?: string;
  @observable public reservation_number?: string;

  @observable public container_type_id?: number;

  @observable public insurance_id?: number;
  @observable public freight_id?: number;
  @observable public nature_of_operation_id?: number;
  @observable public payment_form_id?: number;

  @observable public bill_of_lading?: string;
  @observable public bill_of_lading_print_instruction?: string;

  @observable public observations?: string;

  @action protected updateAttributes({
    id,
    finalized,
    customs_officer_id,
    consignee_id,
    notification_id,
    second_notification_id,
    shipping_line_id,
    shipping_mode_id,
    voyage_number,
    vessel_name,
    reservation_number,
    container_type_id,
    insurance_id,
    freight_id,
    nature_of_operation_id,
    payment_form_id,
    bill_of_lading,
    bill_of_lading_print_instruction,
    observations,
  }: ShipmentInstructionType) {
    this.id = id;
    this.finalized = finalized;

    this.customs_officer_id = customs_officer_id;

    this.consignee_id = consignee_id;
    this.notification_id = notification_id;
    this.second_notification_id = second_notification_id;

    this.shipping_line_id = shipping_line_id;
    this.shipping_mode_id = shipping_mode_id;

    this.voyage_number = voyage_number;
    this.vessel_name = vessel_name;
    this.reservation_number = reservation_number;

    this.container_type_id = container_type_id;

    this.insurance_id = insurance_id;
    this.freight_id = freight_id;
    this.nature_of_operation_id = nature_of_operation_id;
    this.payment_form_id = payment_form_id;

    this.bill_of_lading = bill_of_lading;
    this.bill_of_lading_print_instruction = bill_of_lading_print_instruction;
    this.observations = observations;
  }

  @computed get isFinalizable() {
    return this.hasValidAttributeValue(...ShipmentInstruction.REQUIRED_DOCUMENTS);
  }

  @computed get isValid(): boolean {
    return this.hasValidAttributeValue(...ShipmentInstruction.VALID_DOCUMENTS);
  }

  @computed get downloadUrl(): string | undefined {
    return this.id
      ? Routes.download_api_v1_shipment_instruction_path(this.id)
      : undefined;
  }
}

export default ShipmentInstruction;
