import classnames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import Checkbox from 'shared/Checkbox';
import CheckboxGroup from '../models/CheckboxGroup';

interface AlternativeInputProps {
  sampleId: number | string;
  twoColumns?: boolean;
  threeColumns?: boolean;
  useInOverlay?: boolean;
  completed?: boolean;
  alternatives: CheckboxGroup<any>;
}

const AlternativeInput = ({
  sampleId,
  twoColumns,
  threeColumns,
  useInOverlay,
  completed = false,
  alternatives,
}: AlternativeInputProps) => {
  return (
    <div
      className={classnames('check-group', useInOverlay && 'check-group-list--overlay')}
    >
      <div
        className={classnames(
          'check-group-list',
          twoColumns && 'check-group-list--two-columns',
          threeColumns && 'check-group-list--three-columns',
          useInOverlay && 'check-group-list--overlay'
        )}
      >
        {alternatives.checkboxes.map((alternative) => (
          <Checkbox
            key={alternative.id}
            label={alternative.name}
            icon={'cross'}
            id={`sample_${sampleId}_alternative_id_${alternative.id}]`}
            name={`samples[${sampleId}][alternative_ids][]`}
            value={`${alternative.id}`}
            onChange={() =>
              !alternative.selected
                ? alternatives.selectCheckbox(alternative)
                : alternatives.deselectCheckbox(alternative)
            }
            readOnly={completed}
            checked={alternative.selected}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(AlternativeInput);
