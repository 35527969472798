import React from 'react';

import FilterBar from 'components/elements/FilterBar';
import { VARIANT } from 'constants/inputTypes';
import Input from 'components/elements/Input';
import { FilterOption, FilterOptions, SelectFilterOption } from './SearchBar';
import Select from 'components/elements/Select';

export interface ContractSearchBarFilterOptions extends FilterOptions {
  identifier?: FilterOption<string>;
  contractType?: SelectFilterOption;
  contractStatus?: SelectFilterOption;
  shipmentDate?: FilterOption<string>;
}

export interface ContractSearchBarProps {
  filters: ContractSearchBarFilterOptions;
}

function ContractSearchBar({ filters = {} }: ContractSearchBarProps) {
  return (
    <FilterBar as='form' method='GET' columns='minmax(10em, 1fr) 9.75em 9.75em 14em 7em'>
      {filters.identifier && (
        <Input
          name={filters.identifier.name}
          type='text'
          defaultValue={filters.identifier.value}
        />
      )}

      {filters.contractType && (
        <Select
          value={filters.contractType.value}
          name={filters.contractType.name}
          options={filters.contractType.options}
          placeholder={filters.contractType.placeholder}
        />
      )}

      {filters.contractStatus && (
        <Select
          value={filters.contractStatus.value}
          name={filters.contractStatus.name}
          options={filters.contractStatus.options}
          placeholder={filters.contractStatus.placeholder}
        />
      )}

      {filters.shipmentDate && (
        <Input
          name={filters.shipmentDate.name}
          type='month'
          variant={VARIANT.TIME}
          defaultValue={filters.shipmentDate.value}
        />
      )}

      <button className='button' type='submit'>
        {I18n.translate('buttons.search')}
      </button>
    </FilterBar>
  );
}

export default ContractSearchBar;
