export const nextFutureFixingDate = (date: Date, includeCurrentMonth = true) => {
  const calculatedDate = new Date(date);
  const possible_months = [3, 5, 7, 9, 12]; // MAY, JUL, SEP, DEC
  if (!includeCurrentMonth) {
    // MUST BE ATLEAST 1 MONTH IN FUTURE
    calculatedDate.setMonth(calculatedDate.getMonth() + 1);
  }
  while (!possible_months.includes(calculatedDate.getMonth() + 1)) {
    calculatedDate.setMonth(calculatedDate.getMonth() + 1);
  }
  return calculatedDate;
};

export const formatFutureFixingDate = (date: Date) => {
  // Did not want to include moment or similar just for this
  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  return `${monthNames[date.getMonth()]}-${date.getFullYear()}`;
};

export const toMonthInputFormat = (date: Date) => {
  if (!date) return '';

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthString = month.toString().padStart(2, '0');

  return `${year}-${monthString}`;
};
export const fromMonthInputFormat = (monthYearString: string) => {
  const monthDate = monthYearString.split('-');
  const date = new Date(parseInt(monthDate[0]), parseInt(monthDate[1]) - 1, 1, 0, 0, 0);

  return date;
};
