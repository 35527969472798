import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Ring = styled.div<any>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: ${(props) => props.color};

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const RingInside = styled.div<any>`
  box-sizing: border-box;
  display: block;
  position: absolute;

  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin: 2px;
  border: ${(props) => props.size / 8}px solid ${(props) => props.color};
  border-radius: 50%;
  animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(props) => props.color} transparent transparent transparent;
`;

interface LoadingSpinnerProps {
  color?: string;
  backgroundColor?: string;
  size?: number;
}

function LoadingSpinner({
  color = '#fff',
  backgroundColor = 'transparent',
  size = 16,
}: LoadingSpinnerProps): JSX.Element {
  return (
    <Ring color={backgroundColor} size={size}>
      <RingInside color={color} size={size} />
      <RingInside color={color} size={size} />
      <RingInside color={color} size={size} />
    </Ring>
  );
}

export default LoadingSpinner;
