import { AbilityContext } from 'components/shared/Can';
import ThirdPartyImport from 'exports/types/thirdPartyImport';
import React, { useContext } from 'react';
import styled from 'styled-components';
import SelectOptionArray from 'types/model/selectOption';
import ThirdPartyImportRow from './ThirdPartyImportRow';

const ImportTable = styled.table`
  td {
    padding: 0.5rem 1rem !important;
  }

  th {
    padding: 0.5rem 1rem !important;
    box-shadow: none;
  }

  .input-table-data {
    padding: 0.5rem 0.325rem !important;
  }
`;

interface ThirdPartyImportTableProps {
  thirdPartyImports: ThirdPartyImport[];
  destinationWarehouses: SelectOptionArray;
}
const ThirdPartyImportTable = ({
  thirdPartyImports,
  destinationWarehouses,
}: ThirdPartyImportTableProps) => {
  const ability = useContext(AbilityContext);
  const readonly = !ability.can('update', 'Exports::ThirdPartyImport');

  return (
    <ImportTable className='table'>
      <thead>
        <tr>
          <th className='align-left'>
            {I18n.translate('exports.third_party_imports.mc_amount')}
          </th>

          <th className='align-left'>
            {I18n.translate('exports.third_party_imports.origin_dest')}
          </th>

          <th className='align-left'>
            {I18n.translate('exports.third_party_imports.warehouse')}
          </th>

          <th className='align-left'>
            {I18n.translate('exports.third_party_imports.arrival')}
          </th>

          <th className='align-left'>
            {I18n.translate('exports.third_party_imports.bin')}
          </th>

          <th className='align-left'>
            {I18n.translate('exports.third_party_imports.third_party_ico')}
          </th>

          <th className='align-left'></th>
          <th className='align-left'></th>
        </tr>
      </thead>

      <tbody>
        {thirdPartyImports &&
          thirdPartyImports.map((thirdPartyImport: ThirdPartyImport) => (
            <ThirdPartyImportRow
              key={thirdPartyImport.id}
              thirdPartyImport={thirdPartyImport}
              destinationWarehouses={destinationWarehouses}
              readonly={readonly}
            />
          ))}
      </tbody>
    </ImportTable>
  );
};

export default ThirdPartyImportTable;
