import React from 'react';
import { observer } from 'mobx-react';

import type { AccordionHeaderProps } from 'shared/Accordion/components/AccordionWrapper';
import {
  HeadlineWrapper,
  IconWrapper,
  Headline,
  HeaderWrapper,
} from '../AccordionFormHeader';
import Icon from 'components/elements/Icon';

export interface IcoListHeaderProps extends AccordionHeaderProps {
  completed: boolean;
}

const IcoListHeader = ({ expanded }: IcoListHeaderProps) => (
  <HeaderWrapper>
    <HeadlineWrapper>
      <IconWrapper>
        {expanded ? (
          <Icon icon='caret-up' color='#111820' />
        ) : (
          <Icon icon='caret-down' color='#006F94' />
        )}
      </IconWrapper>
      <Headline>{I18n.translate('exports.form.ico_list.header')}</Headline>
    </HeadlineWrapper>
  </HeaderWrapper>
);

export default observer(IcoListHeader);
