import React from 'react';
import { useFormContext } from 'react-hook-form';
import useSpotContracts from './useSpotContracts';
import { ISpotSampleIco } from './useSpotSampleIcos';
import WSRSpotSampleSelectionTable from './WSRSpotSampleSelectionTable';

export interface WSRSpotSampleInputsProps {
  ico: ISpotSampleIco;
}

const WSRSpotSampleInputs = ({ ico }: WSRSpotSampleInputsProps): JSX.Element => {
  const { register } = useFormContext();

  const { spotContracts } = useSpotContracts(ico.id);

  return (
    <>
      <input
        type='hidden'
        value='sample_spot'
        name='warehouse_sample_request[sample_type]'
        ref={register}
      />

      <input
        type='hidden'
        value={ico.id}
        name='warehouse_sample_request[ico_id]'
        ref={register}
      />

      <WSRSpotSampleSelectionTable
        spotContracts={spotContracts}
        preShipmentSample={ico.current_pre_shipment_sample}
      />
    </>
  );
};
export default WSRSpotSampleInputs;
