import { action, computed, observable } from 'mobx';
import IWetPhysicalAnalysis from 'wetPhysicalAnalysis/types/IWetPhysicalAnalysis';

const valuePercentage = (value, base) => {
  const procent = ((value * 100) / base).toFixed(2);
  if (base && procent && procent !== 'NaN' && value) {
    return procent;
  }
  return '';
};

class WetPhysicalAnalysisStore {
  public acceptingReasonId: null | number;
  @observable public values = [];
  @observable public isValid = true;
  @observable public sampleWeight = '300'; // @TODO: wire this
  @observable public humidity: string;
  @observable public hoursOfFermentation: string;
  @observable public smellId: number | null;
  @observable public brocaAmount: string;
  @observable public brocaWeight: string;
  @observable public cherryAmount: string;
  @observable public cherryWeight: string;
  @observable public fermentAmount: string;
  @observable public fermentWeight: string;
  @observable public pulpAmount: string;
  @observable public pulpWeight: string;
  @observable public fungusAmount: string;
  @observable public fungusWeight: string;
  @observable public stainedAmount: string;
  @observable public stainedWeight: string;
  @observable public blackAmount: string;
  @observable public blackWeight: string;
  @observable public moldAmount: string;
  @observable public moldWeight: string;
  @observable public brokenAmount: string;
  @observable public brokenWeight: string;
  @observable public bittenAmount: string;
  @observable public bittenWeight: string;
  @observable public vanoAmount: string;
  @observable public vanoWeight: string;
  @observable public greenAmount: string;
  @observable public greenWeight: string;
  @observable public observations: string;
  @observable public rejectReason: string;
  @observable public estimatedGreen: string;

  constructor(values: IWetPhysicalAnalysis) {
    this.sampleWeight = '300';
    this.humidity = values.humidity || '';
    this.hoursOfFermentation = values.hours_of_fermentation || '';
    this.smellId = values.smell_id;
    this.brocaAmount = values.broca_amount || '';
    this.brocaWeight = values.broca_weight || '';
    this.cherryAmount = values.cherry_amount || '';
    this.cherryWeight = values.cherry_weight || '';
    this.fermentAmount = values.ferment_amount || '';
    this.fermentWeight = values.ferment_weight || '';
    this.pulpAmount = values.pulp_amount || '';
    this.pulpWeight = values.pulp_weight || '';
    this.fungusAmount = values.fungus_amount || '';
    this.fungusWeight = values.fungus_weight || '';
    this.stainedAmount = values.stained_amount || '';
    this.stainedWeight = values.stained_weight || '';
    this.blackAmount = values.black_amount || '';
    this.blackWeight = values.black_weight || '';
    this.moldAmount = values.mold_amount || '';
    this.moldWeight = values.mold_weight || '';
    this.brokenAmount = values.broken_amount || '';
    this.brokenWeight = values.broken_weight || '';
    this.bittenAmount = values.bitten_amount || '';
    this.bittenWeight = values.bitten_weight || '';
    this.vanoAmount = values.vano_amount || '';
    this.vanoWeight = values.vano_weight || '';
    this.greenAmount = values.green_amount || '';
    this.greenWeight = values.green_weight || '';
    this.observations = values.observations || '';
    this.rejectReason = values.reject_reason || '';
    this.acceptingReasonId = values.accepting_reason_id;
  }

  // #################
  // Setter for values
  // #################
  @action public setIsValid = (status: boolean) => (this.isValid = status);
  @action public setHumidity = (value: string) => (this.humidity = value);
  @action public setHoursOfFermentation = (value: string) =>
    (this.hoursOfFermentation = value);
  @action public setSmellId = (value: string) => (this.smellId = parseInt(value));
  @action public setBrocaAmount = (value: string) => (this.brocaAmount = value);
  @action public setBrocaWeight = (value: string) => (this.brocaWeight = value);
  @action public setCherryAmount = (value: string) => (this.cherryAmount = value);
  @action public setCherryWeight = (value: string) => (this.cherryWeight = value);
  @action public setFermentAmount = (value: string) => (this.fermentAmount = value);
  @action public setFermentWeight = (value: string) => (this.fermentWeight = value);
  @action public setPulpAmount = (value: string) => (this.pulpAmount = value);
  @action public setPulpWeight = (value: string) => (this.pulpWeight = value);
  @action public setFungusAmount = (value: string) => (this.fungusAmount = value);
  @action public setFungusWeight = (value: string) => (this.fungusWeight = value);
  @action public setStainedAmount = (value: string) => (this.stainedAmount = value);
  @action public setStainedWeight = (value: string) => (this.stainedWeight = value);
  @action public setBlackAmount = (value: string) => (this.blackAmount = value);
  @action public setBlackWeight = (value: string) => (this.blackWeight = value);
  @action public setMoldAmount = (value: string) => (this.moldAmount = value);
  @action public setMoldWeight = (value: string) => (this.moldWeight = value);
  @action public setBrokenAmount = (value: string) => (this.brokenAmount = value);
  @action public setBrokenWeight = (value: string) => (this.brokenWeight = value);
  @action public setBittenAmount = (value: string) => (this.bittenAmount = value);
  @action public setBittenWeight = (value: string) => (this.bittenWeight = value);
  @action public setVanoAmount = (value: string) => (this.vanoAmount = value);
  @action public setVanoWeight = (value: string) => (this.vanoWeight = value);
  @action public setGreenAmount = (value: string) => (this.greenAmount = value);
  @action public setGreenWeight = (value: string) => (this.greenWeight = value);
  @action public setObservations = (value: string) => (this.observations = value);
  @action public setRejectReason = (value: string) => (this.rejectReason = value);

  // #################
  // Calculated Values
  // #################
  @computed get brocaPercentage(): string {
    return valuePercentage(this.brocaWeight, this.sampleWeight);
  }
  @computed get cherryPercentage(): string {
    return valuePercentage(this.cherryWeight, this.sampleWeight);
  }
  @computed get fermentPercentage(): string {
    return valuePercentage(this.fermentWeight, this.sampleWeight);
  }
  @computed get pulpPercentage(): string {
    return valuePercentage(this.pulpWeight, this.sampleWeight);
  }
  @computed get fungusPercentage(): string {
    return valuePercentage(this.fungusWeight, this.sampleWeight);
  }
  @computed get stainedPercentage(): string {
    return valuePercentage(this.stainedWeight, this.sampleWeight);
  }
  @computed get blackPercentage(): string {
    return valuePercentage(this.blackWeight, this.sampleWeight);
  }
  @computed get moldPercentage(): string {
    return valuePercentage(this.moldWeight, this.sampleWeight);
  }

  @computed get pasilla1Amount(): string {
    return [
      this.brocaAmount,
      this.cherryAmount,
      this.fermentAmount,
      this.pulpAmount,
      this.fungusAmount,
      this.stainedAmount,
      this.blackAmount,
      this.moldAmount,
    ]
      .reduce((a, b) => {
        const left = a ? parseInt(a.toString(), 10) : 0;
        const right = b ? parseInt(b.toString(), 10) : 0;
        return left + right;
      }, 0)
      .toString();
  }

  @computed get pasilla1Weight(): string {
    return [
      this.brocaWeight,
      this.cherryWeight,
      this.fermentWeight,
      this.pulpWeight,
      this.fungusWeight,
      this.stainedWeight,
      this.blackWeight,
      this.moldWeight,
    ]
      .reduce((a, b) => {
        const left = a || 0;
        const right = parseFloat(b) || 0;
        return left + right;
      }, 0)
      .toString();
  }

  @computed get pasilla1Percentage(): string {
    return valuePercentage(this.pasilla1Weight, this.sampleWeight);
  }

  @computed get brokenPercentage(): string {
    return valuePercentage(this.brokenWeight, this.sampleWeight);
  }
  @computed get bittenPercentage(): string {
    return valuePercentage(this.bittenWeight, this.sampleWeight);
  }
  @computed get vanoPercentage(): string {
    return valuePercentage(this.vanoWeight, this.sampleWeight);
  }
  @computed get greenPercentage(): string {
    return valuePercentage(this.greenWeight, this.sampleWeight);
  }

  @computed get pasilla2Amount(): string {
    return [this.brokenAmount, this.bittenAmount, this.vanoAmount, this.greenAmount]
      .reduce((a, b) => {
        const left = a ? parseInt(a.toString(), 10) : 0;
        const right = b ? parseInt(b.toString(), 10) : 0;
        return left + right;
      }, 0)
      .toString();
  }

  @computed get pasilla2Weight(): string {
    return [this.brokenWeight, this.bittenWeight, this.vanoWeight, this.greenWeight]
      .reduce((a, b) => {
        const left = a || 0;
        const right = parseFloat(b) || 0;
        return left + right;
      }, 0)
      .toString();
  }

  @computed get pasilla2Percentage(): string {
    return valuePercentage(this.pasilla2Weight, this.sampleWeight);
  }
}
export default WetPhysicalAnalysisStore;
