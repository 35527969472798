import { Provider } from 'mobx-react';
import React from 'react';

import SelectOptionArray from 'types/model/selectOption';
import CustomerSamplesDashboardStore from 'customerSamples/stores/CustomerSamplesDashboardStore';
import CustomerSamplesDashboardComponent from 'customerSamples/components/CustomerSamplesDashboardComponent';
import DefaultDateRange from 'types/model/defaultDateRange';
import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';

interface CustomerSamplesDashboardContainerProps {
  defaultDateRange: DefaultDateRange;
  types: SelectOptionArray;
  states: SelectOptionArray;
  originCountries: SelectOptionArray;
  months: SelectOptionArray;
  years: SelectOptionArray;
  grades: SelectOptionArray;
  customerSampleReasons: SelectOptionArray;
  search?: string;
  type?: string;
}

function CustomerSamplesDashboardContainer({
  defaultDateRange,
  types,
  originCountries,
  months,
  years,
  grades,
  states,
  customerSampleReasons,
  search = '',
  type = '',
}: CustomerSamplesDashboardContainerProps): JSX.Element {
  return (
    <AbilityContext.Provider value={Ability}>
      <Provider
        customerSamplesDashboardStore={
          new CustomerSamplesDashboardStore(
            defaultDateRange,
            types,
            originCountries,
            months,
            years,
            grades,
            states,
            customerSampleReasons,
            search,
            type
          )
        }
      >
        <CustomerSamplesDashboardComponent />
      </Provider>
    </AbilityContext.Provider>
  );
}

export default CustomerSamplesDashboardContainer;
