import { action, computed, observable } from 'mobx';
import ICheckbox from 'cuppingSession/types/ICheckbox';

export interface Kind<T> {
  [key: string]: Array<T>;
}

interface SelectableCheckboxes {
  selected: boolean;
}

class CheckboxGroup<T extends any> {
  public checkboxes = observable.array<any>();

  constructor(checkboxes: Array<ICheckbox>, selected?: Array<number>) {
    const selectableCheckboxes = checkboxes.map((checkbox) => ({
      ...(checkbox as Object),
      selected: selected?.includes(checkbox.id) || false,
    }));

    this.checkboxes = observable.array(selectableCheckboxes);
  }

  @action public selectCheckbox = (checkbox: SelectableCheckboxes) => {
    checkbox.selected = true;
  };

  @action public deselectCheckbox = (checkbox: SelectableCheckboxes) => {
    checkbox.selected = false;
  };

  @computed get checkboxKinds(): Kind<T> {
    return this.checkboxes.reduce((kinds, value) => {
      kinds[value.kind] = [...(kinds[value.kind] || []), value];
      return kinds;
    }, {});
  }

  @computed get selectedCheckboxes(): Array<any> {
    return this.checkboxes.filter((checkbox) => checkbox.selected);
  }

  @computed get checkboxCount(): number {
    return this.selectedCheckboxes.length;
  }
}

export default CheckboxGroup;
