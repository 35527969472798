import { action, computed, observable } from 'mobx';

import { MachinePhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';

class MachinePhysicalAnalysis {
  public sampleId: number;
  public type: string;
  public id?: number;
  public totalPasilla: number;
  private percentageGreenInPasilla: number;
  @observable public sampleWeight: number;
  @observable public uv: number;
  @observable public sieve14: number;
  @observable public sieve15: number;
  @observable public conformantPasilla: number;
  @observable public nonConformantPasilla: number;
  @observable public nonConformantPasillaPercentage: number | string;
  @observable public observations?: string;
  @observable public acceptingReasonId?: number;

  constructor(
    sample_id: number,
    physicalAnalysis: MachinePhysicalAnalysisType,
    percentageGreenInPasilla: number
  ) {
    this.sampleId = sample_id;
    this.sampleWeight = physicalAnalysis.sample_weight;
    this.id = physicalAnalysis.id;
    this.type = physicalAnalysis.type;
    this.observations = physicalAnalysis.observations;
    this.uv = physicalAnalysis.uv;
    this.sieve14 = physicalAnalysis.sieve_14;
    this.sieve15 = physicalAnalysis.sieve_15;
    this.totalPasilla = physicalAnalysis.total_pasilla;
    this.conformantPasilla = physicalAnalysis.conformant_pasilla;
    this.nonConformantPasilla = physicalAnalysis.non_conformant_pasilla;
    this.nonConformantPasillaPercentage =
      physicalAnalysis.non_conformant_pasilla_percentage;
    this.acceptingReasonId = physicalAnalysis.accepting_reason_id;
    this.percentageGreenInPasilla = percentageGreenInPasilla;
  }

  @action getWeightPercentage = (weight: number) => {
    return weight > 0 ? `${((weight * 100) / this.sampleWeight).toFixed(2)}%` : '---';
  };

  @action setSampleWeight = (sampleWeight: number) => {
    this.sampleWeight = sampleWeight;
  };

  @action setSieve14 = (sieve14: number) => {
    this.sieve14 = sieve14 ? sieve14 : 0;
  };

  @action setSieve15 = (sieve15: number) => {
    this.sieve15 = sieve15 ? sieve15 : 0;
  };

  @action setUv = (uv: number) => {
    this.uv = uv ? uv : uv;
  };

  @action setNonConformantPasillaPercentage = (
    nonConformantPasillaPercentage: number
  ) => {
    this.nonConformantPasillaPercentage = nonConformantPasillaPercentage || '';
  };

  @action setObservations = (observations: string) => {
    this.observations = observations;
  };

  @computed get formValid(): boolean {
    return this.nonConformantPasillaPercentage >= 0;
  }

  @computed get calculatedNonConformantPasilla() {
    if (this.nonConformantPasillaPercentage >= this.percentageGreenInPasilla) {
      return this.totalPasilla;
    }
    return 0;
  }

  @computed get calculatedConformantPasilla() {
    if (this.nonConformantPasillaPercentage < this.percentageGreenInPasilla) {
      return this.totalPasilla;
    }
    return 0;
  }
}

export default MachinePhysicalAnalysis;
