import React from 'react';
import useStores from 'utils/useStores';

import { TableCorner, TableColumnHeader, TableHeaderData } from 'shared/ScrollableTable';
import { calculateMonthName } from 'exports/exportUtils';

const TableHeader = () => {
  const { exportsStore } = useStores();
  return (
    <>
      {exportsStore.dates.map((date, index) => (
        <React.Fragment key={`${date}_${index}`}>
          {index === 0 && <TableCorner />}
          <TableColumnHeader>
            <TableHeaderData>{calculateMonthName(date)}</TableHeaderData>
          </TableColumnHeader>
        </React.Fragment>
      ))}
    </>
  );
};

export default TableHeader;
