import classNames from 'classnames';
import SelectGroup from 'components/groups/SelectGroup';
import { observer } from 'mobx-react';
import React from 'react';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';
import styled from 'styled-components';
import useStores from 'utils/useStores';

const TypeWrapper = styled.div`
  margin-right: 1rem;
  width: 100%;
`;

const FormWrapper = styled.div`
  flex-basis: 38.2%;
`;

function ActionForm() {
  const { consolidateSamplesStore } = useStores();
  const {
    marks,
    paginatedTableStore,
    sampleTypes,
    selectedMarkId,
    selectedSampleTypeId,
  } = consolidateSamplesStore;
  const { actionUrl } = paginatedTableStore;

  const onOfferSampleCreate = () => {
    consolidateSamplesStore.isFormValid &&
      consolidateSamplesStore.setShowOpportunityOverlay(true);
  };

  const onPreShipmentSampleCreate = () => {
    consolidateSamplesStore.isFormValid &&
      consolidateSamplesStore.setShowIcoOverlay(true);
  };

  const renderCreateButton = () => {
    if (consolidateSamplesStore.isOfferSample) {
      return (
        <a
          data-cy='create_consolidation_offer_sample'
          className={classNames('button', {
            'button--gray': !consolidateSamplesStore.isFormValid,
          })}
          onClick={onOfferSampleCreate}
        >
          {I18n.translate('samples.consolidate.create_sample')}
        </a>
      );
    } else if (consolidateSamplesStore.isPreShipmentSample) {
      return (
        <a
          data-cy='create_consolidation_preshipment_sample'
          className={classNames('button', {
            'button--gray': !consolidateSamplesStore.isFormValid,
          })}
          onClick={onPreShipmentSampleCreate}
        >
          {I18n.translate('samples.consolidate.create_sample')}
        </a>
      );
    } else {
      return (
        <button
          disabled={!consolidateSamplesStore.isFormValid}
          className='button'
          type='submit'
          data-cy='create_consolidation_sample'
        >
          {I18n.translate('samples.consolidate.create_sample')}
        </button>
      );
    }
  };

  return (
    <FormWrapper>
      <form
        action={actionUrl !== undefined ? actionUrl() : ''}
        acceptCharset='UTF-8'
        method='POST'
      >
        <AuthenticityToken />
        {consolidateSamplesStore.selectedSamples.map(
          ({ attributes: { id, sampleSize } }) => (
            <input
              key={id}
              type='hidden'
              name={`samples[${id}]`}
              value={sampleSize || ''}
            />
          )
        )}

        <input
          type='hidden'
          name='sample_type'
          value={consolidateSamplesStore.selectedSampleTypeId}
        />
        <input
          type='hidden'
          name='mark_id'
          value={
            consolidateSamplesStore.selectedMarkId
              ? consolidateSamplesStore.selectedMarkId
              : ''
          }
        />

        <div className='l-item-stack-horizontal l-item-stack-horizontal--wide l-item-stack-horizontal--space-between'>
          <TypeWrapper>
            <SelectGroup
              name='sample_type'
              data-cy='sample_type_select'
              onChange={(e) =>
                consolidateSamplesStore.setSelectedSampleTypeId(
                  parseInt(e.target.value, 10)
                )
              }
              value={selectedSampleTypeId}
              options={sampleTypes}
            />
          </TypeWrapper>

          <TypeWrapper>
            <SelectGroup
              name='mark'
              data-cy='select_mark'
              onChange={(e) =>
                consolidateSamplesStore.setSelectedMarkId(parseInt(e.target.value, 10))
              }
              value={selectedMarkId}
              placeholder={I18n.translate('samples.consolidate.select_mark')}
              options={marks}
              disabled={!(marks && marks.length > 0)}
            />
          </TypeWrapper>

          {renderCreateButton()}
        </div>
      </form>
    </FormWrapper>
  );
}

export default observer(ActionForm);
