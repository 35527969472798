import React from 'react';

import { useFilterContext } from 'utils/filterContext';
import FilterBar from 'components/elements/FilterBar';

interface LotFilterProps {
  options: {
    [key: string]: Array<string>;
  };
}

const LotFilter: React.FC<LotFilterProps> = ({
  options: { certificates, processTypes, qualities, varieties, municipalities },
}) => {
  const { updateFilter } = useFilterContext();

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => updateFilter(event.target.name, event.target.value);

  return (
    <FilterBar columns='1fr 1.15fr 1fr 0.85fr 1fr 1fr'>
      <input
        type='text'
        placeholder={I18n.translate('lot_sets.index.enter_lot_id')}
        onChange={handleOnChange}
        name='local_identifier'
      />

      <select onChange={handleOnChange} name='municipality' defaultValue=''>
        <option value=''>{I18n.translate('lot_sets.index.select_a_municipality')}</option>
        {municipalities.map((municipality) => (
          <option key={municipality}>{municipality}</option>
        ))}
      </select>

      <select onChange={handleOnChange} name='certification' defaultValue=''>
        <option value=''>
          {I18n.translate('lot_sets.index.select_a_certification')}
        </option>
        {certificates.map((certificate) => (
          <option key={certificate}>{certificate}</option>
        ))}
      </select>

      <select onChange={handleOnChange} name='quality' defaultValue=''>
        <option value=''>{I18n.translate('lot_sets.index.select_a_quality')}</option>
        {qualities.map((quality) => (
          <option key={quality}>{quality}</option>
        ))}
      </select>

      <select onChange={handleOnChange} name='variety' defaultValue=''>
        <option value=''>{I18n.translate('lot_sets.index.select_a_variety')}</option>
        {varieties.map((variety) => (
          <option key={variety}>{variety}</option>
        ))}
      </select>

      <select onChange={handleOnChange} name='process_type' defaultValue=''>
        <option value=''>{I18n.translate('lot_sets.index.select_a_process_type')}</option>
        {processTypes.map((processType) => (
          <option key={processType}>{processType}</option>
        ))}
      </select>
    </FilterBar>
  );
};

export default LotFilter;
