import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';

import Badge from 'components/shared/Badge';

import Overlay from 'shared/Overlay';

interface CuppingSessionOverlayProps {
  cuppingCardIndex: number;
  lotLocalIdentifier: string;
  overlayIndex: number;
  title: string;
  subtitle: string;
  widgetAmount: number;
  buttonTitle?: string;
  children?: React.ReactNode;
  completed?: boolean;
  filled?: boolean;
  selectedBadgeKind?: string;
  selectedTitle?: string;
  showRequired?: boolean;
}

const CuppingSessionOverlay = ({
  cuppingCardIndex,
  lotLocalIdentifier,
  overlayIndex,
  title,
  subtitle,
  widgetAmount,
  buttonTitle,
  children,
  completed,
  filled,
  selectedBadgeKind,
  selectedTitle = I18n.translate('sensorial_analysis.present'),
  showRequired,
}: CuppingSessionOverlayProps) => {
  const { cuppingSessionStore } = useStores();

  const handlePrevClick = () => {
    cuppingSessionStore.selectPrevOverlay(cuppingCardIndex);
  };

  const handleNextClick = () => {
    cuppingSessionStore.selectNextOverlay(cuppingCardIndex);
  };

  return (
    <>
      {cuppingSessionStore.cuppingCardIndex === cuppingCardIndex &&
        cuppingSessionStore.overlayIndex === overlayIndex && (
          <Overlay
            id={lotLocalIdentifier}
            title={title}
            subtitle={subtitle}
            count={cuppingCardIndex}
            maxCount={widgetAmount}
            primaryText={I18n.translate('buttons.save')}
            secondaryText={I18n.translate('buttons.cancel')}
            onPrimaryClick={() => cuppingSessionStore.deselectOverlay()}
            onSecondaryClick={() => cuppingSessionStore.deselectOverlay()}
            onPrevClick={() => handlePrevClick()}
            onNextClick={() => handleNextClick()}
            disablePrevNextButtons={cuppingSessionStore.oneAnalysisLeft}
            noPadding={true}
            hideTitleBorder={true}
            distributeButtons={true}
            center={true}
          >
            {children}
          </Overlay>
        )}
      {!filled ? (
        <button
          type='button'
          onClick={() =>
            !completed &&
            cuppingSessionStore.selectOverlay(cuppingCardIndex, overlayIndex)
          }
          className={classNames('button button--small button--ghost', {
            'button--disabled cupping-card__button--disabled': completed,
            'button--required': showRequired,
          })}
        >
          {buttonTitle}
        </button>
      ) : (
        <Badge
          onClick={() =>
            !completed &&
            cuppingSessionStore.selectOverlay(cuppingCardIndex, overlayIndex)
          }
          className={classNames(
            'badge',
            completed ? 'badge--disabled' : 'badge--clickable',
            !!selectedBadgeKind && `badge__${selectedBadgeKind}`
          )}
        >
          {selectedTitle}
        </Badge>
      )}
    </>
  );
};

export default observer(CuppingSessionOverlay);
