import styled from 'styled-components';

interface SubnavigationBlockProps {
  active?: boolean;
}

export default styled.ul<SubnavigationBlockProps>`
  display: none;
  padding: 1em 0;
`;
