import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import useStores from 'utils/useStores';

import COLORS from 'constants/colors';

import Tile from 'shared/Tile/components/Tile';
import TileHeader from 'shared/Tile/components/TileHeader';
import SelectGroup from 'components/groups/SelectGroup';
import { TileDisclosure } from 'shared/Tile';

const Actions = styled.div`
  display: grid;
  grid-template-columns: auto 19.875em;
  grid-column-gap: 1.25em;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  input[type='text'] {
    width: 16em;
  }

  div:nth-child(2) {
    border-left: 1px solid ${COLORS.BORDERS};
    padding-left: 1em;
  }
`;

const Filter = styled.div`
  display: grid;
  grid-template-columns: 1fr 10em 5em;
  grid-column-gap: 1em;
  align-items: center;
`;

const DeliveryOrderFilter: React.FC<{}> = () => {
  const { deliveryOrderStore } = useStores();

  const [collapsed, setCollapsed] = React.useState(true);
  const {
    destinationWarehouses,
    customers,
    selectedCustomerId,
    selectedWarehouseId,
    deliveryOrder,
  } = deliveryOrderStore;

  // React.useEffect(() => {
  //   if (!deliveryOrderStore.icos.length) {
  //     setCollapsed(true);
  //   }
  // }, [deliveryOrderStore.icos.length]);

  const handleDisclosureClick = React.useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => setCollapsed(!collapsed),
    [collapsed]
  );

  const handleOnCustomerChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => deliveryOrderStore.setSelectedCustomerId(event.target.value);

  const handleOnWarehouseChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => deliveryOrderStore.setSelectedWarehouseId(event.target.value);

  const buttonText =
    deliveryOrder !== undefined
      ? I18n.translate('fulfillment.delivery_orders.update_do')
      : I18n.translate('fulfillment.delivery_orders.create_do');
  const editMode = deliveryOrder !== undefined;
  const editable = editMode ? deliveryOrder?.editable : true;
  const hasItems =
    (deliveryOrder !== undefined && !!deliveryOrder?.delivery_order_items) || true;

  return (
    <>
      <Tile collapsibleIndicator={collapsed}>
        <>
          <TileHeader className='delivery-orders__header'>
            <TileDisclosure
              disabled={hasItems}
              handleCollapsible={handleDisclosureClick}
              collapsed={collapsed}
            />
            <Actions>
              <Filter>
                <SelectGroup
                  onChange={handleOnCustomerChange}
                  name='customer'
                  value={selectedCustomerId}
                  placeholder={I18n.translate(
                    'fulfillment.delivery_orders.select_client'
                  )}
                  disabled={!editable || editMode}
                  options={customers.map((customer) => {
                    return { id: customer.id, name: customer.value };
                  })}
                />

                <SelectGroup
                  onChange={handleOnWarehouseChange}
                  name='warehouse'
                  value={selectedWarehouseId}
                  placeholder={I18n.translate(
                    'fulfillment.delivery_orders.select_warehouse'
                  )}
                  disabled={!editable || editMode}
                  options={destinationWarehouses.map((warehouse) => {
                    return { id: warehouse.id, name: warehouse.value };
                  })}
                />
              </Filter>

              <div className='l-item-stack-horizontal l-item-stack-horizontal--wide'>
                <input
                  disabled={!deliveryOrderStore.isFormValid || !editable}
                  className='button'
                  type='submit'
                  value={buttonText}
                />
              </div>
            </Actions>
          </TileHeader>
        </>
      </Tile>
    </>
  );
};

export default observer(DeliveryOrderFilter);
