import { observable, action, computed } from 'mobx';

import type Unloadable from '../types/Unloadable';
import type Storable from '../types/Storable';

import Storage from './Storage';
import IIcoTransportable from '../types/IIcoTransportable';

class UnloadableIco implements Unloadable, Storable {
  public id: number;
  public identifier: string;
  public quality?: string;
  public grade?: number;
  public bags: number;
  public expectedWeight: number;
  public expectedUnits: number;
  public storage?: Storage;

  @observable public unloadedUnits: number | undefined;
  @observable public unloadedWeight: number | undefined;
  @observable public reasonId: number | undefined;

  public constructor(
    { id, units, weight, identifier, quality, grade }: IIcoTransportable,
    storage?: Storage
  ) {
    this.id = id;
    this.bags = parseInt(units);
    this.identifier = identifier;
    this.quality = quality;
    this.grade = grade;
    this.expectedUnits = parseInt(units);
    this.unloadedUnits = 0;
    this.storage = storage;

    if (typeof weight === 'string') {
      this.expectedWeight = parseFloat(weight);
    } else if (typeof weight === 'object') {
      this.expectedWeight = parseFloat(weight?.value.toString()) || 0;
    } else {
      this.expectedWeight = 0;
    }

    this.unloadedWeight = 0;
  }

  @action public setUnloadedUnits = (value?: number) => {
    this.unloadedUnits = value;
  };

  @action public setUnloadedWeight = (value?: number) => {
    this.unloadedWeight = value;
  };

  @action public setReasonId = (value: number | undefined) => {
    this.reasonId = value;
  };

  @computed get isUnloaded(): boolean {
    return !!this.unloadedUnits && !!this.unloadedWeight;
  }
}

export default UnloadableIco;
