import React from 'react';

import { LotSetTraceability, LotTraceability } from '../types/traceability';

import SelectOptionArray from 'types/model/selectOption';
import LotTraceabilityHeader from './LotTraceabilityHeader';
import SetTraceabilityHeader from './SetTraceabilityHeader';

export interface TraceabilityHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  onCollapsibleHandler: (event: React.MouseEvent) => any;
  collapsed: boolean;
  traceability: LotTraceability | LotSetTraceability;
  specialMarks: SelectOptionArray;
}

const TraceabilityHeader = ({ traceability, ...props }: TraceabilityHeaderProps) => {
  if (traceability.type == 'lot') {
    return <LotTraceabilityHeader traceability={traceability} {...props} />;
  } else if (traceability.type == 'set') {
    return <SetTraceabilityHeader traceability={traceability} {...props} />;
  } else {
    return null;
  }
};

export default TraceabilityHeader;
