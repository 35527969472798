import React, { useState } from 'react';
import Routes from 'routes';

import type SensorialAnalysisModel from 'productionOrders/models/SensorialAnalysis';
import type SampleModel from 'productionOrders/models/Sample';
import MarkForCupping from 'shared/MarkForCupping';
import { customFetch } from 'utils/fetch';

export interface SensorialAnalysisProps {
  sample: SampleModel;
  sensorialAnalysis?: SensorialAnalysisModel;
}

function SensorialAnalysis({
  sample: { id, considered_for_cupping, current_roasting },
  sensorialAnalysis,
}: SensorialAnalysisProps) {
  const [consideredForCupping, setConsideredForCupping] =
    useState(considered_for_cupping);

  const onMarkForCuppingClickHandler = () => {
    if (consideredForCupping) {
      window.location.href = Routes.new_sensorial_analysis_path();
    } else {
      customFetch(Routes.mark_for_cupping_api_v1_sample_path(id)).then(() => {
        setConsideredForCupping(true);
      });
    }
  };

  if (sensorialAnalysis) {
    return (
      <div className={`status status--no-pointer status--${sensorialAnalysis.status}`}>
        <span>{sensorialAnalysis.text}</span>
        <svg className='icon'>
          <use xlinkHref={`#${sensorialAnalysis.icon}`} />
        </svg>
      </div>
    );
  } else {
    return (
      <MarkForCupping
        onClick={onMarkForCuppingClickHandler}
        consideredForCupping={consideredForCupping}
        disabled={!current_roasting.id}
      />
    );
  }
}

export default SensorialAnalysis;
