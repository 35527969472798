import React from 'react';
import styled from 'styled-components';
import Icon from 'components/elements/Icon';
import COLORS from 'constants/colors';

export interface AccordionFormHeaderProps {
  expanded: boolean;
  headline: string;
  completed?: boolean;
}

export const HeaderWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  padding: 0.5rem 0;
`;

export const Headline = styled.h4`
  font-weight: bold;
  font-size: 1.25rem;
  padding-left: 1rem;
`;

export const IconWrapper = styled.span`
  align-items: center;
  display: inline-flex;
  font-size: 0.875rem;
`;

const AccordionFormHeader: React.FC<AccordionFormHeaderProps> = ({
  headline,
  expanded,
  completed,
}) => (
  <HeaderWrapper>
    <HeadlineWrapper>
      <IconWrapper>
        {expanded ? (
          <Icon icon='caret-up' color='#111820' />
        ) : (
          <Icon icon='caret-down' color='#006F94' />
        )}
      </IconWrapper>
      <Headline>{headline}</Headline>
    </HeadlineWrapper>

    <IconWrapper>
      <Icon
        icon={completed ? 'check' : 'cross'}
        color={completed ? COLORS.GREEN : COLORS.RED}
      />
    </IconWrapper>
  </HeaderWrapper>
);

export default AccordionFormHeader;
