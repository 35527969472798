import React from 'react';
import { observer } from 'mobx-react';

import styled from 'styled-components';

import useStores from 'utils/useStores';
import SmallButton from './SmallButton';
import type { Bundle } from '../stores/FutureStore';

const ShipperSellerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & > * {
    flex-basis: 100%;
    padding: 0.35em;
  }
`;

export interface PriceFixing {
  bundle: Bundle;
  future: string;
}

const PriceFixing = ({ future, bundle }: PriceFixing) => {
  const { futuresStore } = useStores();
  const editFixed = false;

  const click = () =>
    futuresStore.toggleOverlay({
      future,
      bundle,
      editFixed,
    });

  return (
    <ShipperSellerFlex>
      {bundle.shipperFixing ? (
        <div>{bundle.shipperFixing.price}</div>
      ) : (
        <div>
          <SmallButton onClick={click}>
            {I18n.translate('future_price_fixing.fix_price')}
          </SmallButton>
        </div>
      )}
      {bundle.sellerFixing ? (
        <div>{bundle.sellerFixing.price}</div>
      ) : (
        <div>
          <SmallButton onClick={click}>
            {I18n.translate('future_price_fixing.fix_price')}
          </SmallButton>
        </div>
      )}
    </ShipperSellerFlex>
  );
};

export default observer(PriceFixing);
