import CustomerLeadType from 'types/model/customerLead';
import ContactInfo from './ContactInfo';
import { observable, action } from 'mobx';

export class CustomerLead {
  @observable public companyName: string;
  @observable public mainContactInfo: ContactInfo;
  @observable public secondaryContactInfo?: ContactInfo;

  constructor(customer_lead: CustomerLeadType) {
    this.companyName = customer_lead.company_name;

    this.mainContactInfo = new ContactInfo(customer_lead.main_contact_info);
    this.secondaryContactInfo = new ContactInfo(customer_lead.secondary_contact_info);
  }
  @action setCompanyName = (companyName: string) => {
    this.companyName = companyName;
  };
}
