import React from 'react';
import { customFetch } from 'utils/fetch';

export type ApiResponse = {
  error?: string;
};

const useFetch = <T extends ApiResponse>(url) => {
  const [response, setResponse] = React.useState<T | undefined>();
  const [error, setError] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async (options) => {
    try {
      setLoading(true);

      const json = await customFetch<T>(url, options);

      if (json?.error) {
        throw json.error;
      }

      setResponse(json);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return { fetchData, response, error, loading };
};

export default useFetch;
