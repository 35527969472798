import classNames from 'classnames';
import React from 'react';

export interface IconProps extends React.SVGAttributes<SVGElement> {
  icon: string;
  title?: string;
}

const Icon: React.FC<IconProps> = ({ className, icon, title, ...props }) => (
  <svg className={classNames('icon', className)} {...props}>
    {title && <title>{title}</title>}
    <use xlinkHref={`#${icon}`} />
  </svg>
);

export default Icon;
