import React, { useState, useEffect } from 'react';
import Routes from 'routes';
import { VARIANT } from 'constants/inputTypes';

import Overlay from 'shared/Overlay';

import InputGroupComponent from 'components/groups/InputGroup';
import Grid from 'components/elements/Grid';
import { customFetch } from 'utils/fetch';
import SelectGroup from 'components/groups/SelectGroup';
import CoffeeType from 'types/model/coffee_type';

interface ProceedWithWetCoffeeProps {
  id: number;
  daysSinceReceiving: number;
  canProceed: boolean;
  coffeeTypes: Array<CoffeeType>;
}

const ProceedWithWetCoffee = ({
  id,
  daysSinceReceiving,
  canProceed,
  coffeeTypes,
}: ProceedWithWetCoffeeProps) => {
  const [showOverlay, setOverlay] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dryWeight, setDryWeight] = useState('');
  const [coffeeType, setCoffeeType] = useState('');
  const [proceedEnabled, setProceedEnabled] = useState(false);

  const handleOnPrimaryClick = () => {
    setSubmitted(true);
    customFetch(Routes.wet_reception_set_dry_path(id), {
      dry_weight: dryWeight,
      coffee_type_id: coffeeType,
    }).then((response) => {
      if (!response.success) {
        alert('Something went wrong!');
      }
      window.location.reload();
    });
  };

  useEffect(() => {
    setProceedEnabled(canProceed && !submitted && !!dryWeight && !!coffeeType);
  }, [dryWeight, coffeeType, submitted]);

  const handleOnSecondaryClick = () => setOverlay(false);

  return (
    <>
      {showOverlay && (
        <Overlay
          title={I18n.translate('wet_reception.index.proceed_to_dry')}
          primaryText={I18n.translate('buttons.proceed')}
          onPrimaryClick={handleOnPrimaryClick}
          primaryDisabled={!proceedEnabled}
          secondaryText={I18n.translate('buttons.cancel')}
          onSecondaryClick={handleOnSecondaryClick}
        >
          <div className='l-item-stack'>
            {!canProceed && (
              <span className='error'>
                {I18n.translate('wet_reception.index.missing_data')}
              </span>
            )}
            <Grid grid={10}>
              <InputGroupComponent
                label={I18n.translate('wet_reception.index.days_in_drying')}
                readOnly={true}
                value={daysSinceReceiving}
              />

              <InputGroupComponent
                label={I18n.translate('wet_reception.index.dry_weight')}
                variant={VARIANT.DECIMAL}
                value={dryWeight}
                readOnly={!canProceed}
                append='kg'
                onChange={(e) => setDryWeight(e.target.value)}
              />

              <SelectGroup
                label={I18n.translate('wet_reception.index.dry_coffee_type')}
                placeholder={I18n.translate('wet_reception.index.dry_coffee_placeholder')}
                value={coffeeType}
                options={coffeeTypes}
                onChange={(e) => setCoffeeType(e.target.value)}
              />
            </Grid>
          </div>
        </Overlay>
      )}
      <button
        className={`button button--small button--${canProceed ? 'green' : 'gray'}`}
        onClick={() => setOverlay(true)}
      >
        {I18n.translate('wet_reception.index.proceed')}
      </button>
    </>
  );
};

export default ProceedWithWetCoffee;
