import ControlledSearch from 'components/elements/ControlledSearch';
import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { SampleDataContext } from 'samples/create/components/CreateSample';
import { PRODUCER_MODES } from 'samples/create/components/useProducerForm';

const ProducerFields = (): JSX.Element => {
  const { register, watch, control } = useFormContext();

  const { producers } = useContext(SampleDataContext);
  const watchProducerMode = watch('producerMode', false);

  return (
    <>
      <input type='hidden' name='producerMode' ref={register} />

      {watchProducerMode == PRODUCER_MODES.LEAD && (
        <Grid grid={12}>
          <InputGroupComponent
            label={I18n.translate('attributes.first_name')}
            name='producer_lead[main_contact_info_attributes][first_name]'
            placeholder=' '
            ref={register}
            required={true}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.middle_name')}
            name='producer_lead[main_contact_info_attributes][middle_name]'
            ref={register}
            placeholder=' '
          />
          <InputGroupComponent
            label={I18n.translate('attributes.surname')}
            name='producer_lead[main_contact_info_attributes][surname]'
            ref={register}
            required={true}
            placeholder=' '
          />
          <InputGroupComponent
            label={I18n.translate('attributes.second_surname')}
            name='producer_lead[main_contact_info_attributes][second_surname]'
            ref={register}
            placeholder=' '
          />
          <InputGroupComponent
            label={I18n.translate('attributes.phone_number')}
            name='producer_lead[main_contact_info_attributes][mobile_number]'
            ref={register}
            required={true}
            placeholder=' '
          />
          <InputGroupComponent
            label={I18n.translate('attributes.email_address')}
            name='producer_lead[main_contact_info_attributes][mail]'
            ref={register}
          />
        </Grid>
      )}
      {watchProducerMode == PRODUCER_MODES.KNOWN && (
        <ControlledSearch
          dataCy='create_sample_producer_select'
          label={I18n.translate('samples.create.select_producer')}
          name='sample[producer_id]'
          control={control}
          prompt={I18n.translate('samples.create.select_producer_placeholder')}
          options={producers}
          required
        />
      )}
    </>
  );
};
export default ProducerFields;
