import { action, computed, observable } from 'mobx';

class Conclusion {
  public reasons: any;
  @observable public reasonId = '';
  @observable public accepted = false;
  @observable public rejected = false;

  constructor(reasons) {
    this.reasons = reasons;
  }

  @action public setAccepted = (accepted: boolean) => {
    this.accepted = accepted;
  };

  @action public setRejected = (rejected: boolean) => {
    this.rejected = rejected;
  };

  @action public setReasonId = (id: string) => {
    this.reasonId = id;
  };

  @action public accept = (checked: boolean) => {
    this.setAccepted(checked);

    // do not toggle on unchecking
    if (this.rejected) {
      this.setRejected(false);
    }

    // set reason id to default
    if (this.reasonIsSet) {
      this.setReasonId('');
    }
  };

  @action public reject = (checked: boolean) => {
    this.setRejected(checked);

    if (this.accepted) {
      this.setAccepted(false);
    }

    // set reason id to default
    if (this.reasonIsSet) {
      this.setReasonId('');
    }
  };

  @computed get reasonIsSet(): boolean {
    return this.reasonId !== '';
  }

  @computed get rejectedValue(): string {
    return this.rejected ? '1' : '0';
  }
}

export default Conclusion;
