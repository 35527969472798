import Unloadable from 'transports/types/Unloadable';
import Storage from 'transports/model/Storage';
import Storable from 'transports/types/Storable';

abstract class UnloadableItem implements Unloadable, Storable {
  public abstract get isUnloaded(): boolean;
  public abstract setReasonId(reason: number | undefined): any;
  public abstract setUnloadedUnits(units?: number): any;
  public abstract setUnloadedWeight(weight?: number): any;

  public abstract reasonId: number | undefined;
  public abstract expectedWeight: number;
  public abstract unloadedWeight: number | undefined;
  public abstract expectedUnits: number;
  public abstract unloadedUnits: number | undefined;
  public abstract identifier: string;

  public abstract storage?: Storage;
}

export default UnloadableItem;
