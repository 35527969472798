import React from 'react';
import { observer } from 'mobx-react';

import FuturesHeader from './FuturesHeader';
import FuturesTable from './FuturesTable';
import FuturesOverlay from './FuturesOverlay';

import useStores from 'utils/useStores';
import TableHeadBox from './TableHeadBox';

const FuturesOverview = () => {
  const { futuresStore } = useStores();

  return (
    <>
      <FuturesHeader
        futures={futuresStore.futures}
        unrealised={futuresStore.totalUnrealised}
        realised={futuresStore.totalRealised}
      />
      <TableHeadBox />
      {futuresStore.listContracts.length > 0 || futuresStore.listBundles.length > 0 ? (
        <FuturesTable />
      ) : (
        <span>{I18n.translate('future_price_fixing.no_contracts_available')}</span>
      )}
      {futuresStore.priceFixingModal && (
        <FuturesOverlay
          bundle={futuresStore.priceFixingModal.bundle}
          future={futuresStore.priceFixingModal.future}
          editFixed={futuresStore.priceFixingModal.editFixed}
          close={() => futuresStore.toggleOverlay()}
          onShipperFix={(id, data) => {
            futuresStore.fixOnBundle(id, 'shipperFixing', data);
          }}
          onSellerFix={(id, data) => {
            futuresStore.fixOnBundle(id, 'sellerFixing', data);
          }}
        />
      )}
    </>
  );
};

export default observer(FuturesOverview);
