import React from 'react';
import { ControlledTextInput } from 'components/shared/form/ControlledTextInput';
import { ControlledSelectGroup } from 'components/shared/form/ControlledSelectGroup';
import { ControlledTextAreaInput } from 'components/shared/form/ControlledTextAreaInput';

export const ContractTextAreaInput = (props) => (
  <ControlledTextAreaInput namespace='contract' {...props} />
);
export const ContractTextInput = (props) => (
  <ControlledTextInput namespace='contract' {...props} />
);
export const ContractSelectGroup = (props) => (
  <ControlledSelectGroup namespace='contract' {...props} />
);
