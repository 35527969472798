import { observer } from 'mobx-react';
import React, { useState } from 'react';

import Fraction from '../models/Fraction';
import FractionsTableRow from './FractionsTableRow';
import RecursiveFractionsComponent from './RecursiveFractionsComponent';
import ReprocessRowComponent from './ReprocessRowComponent';
import FractionTotal from './FractionTotal';

export interface MainFractionsComponentProps {
  fraction: Fraction;
  isMainFraction?: boolean;
  cuppingSessionEnabled: boolean;
}

const MainFractionsComponent = ({
  fraction,
  cuppingSessionEnabled,
}: MainFractionsComponentProps) => {
  const [expanded, setExpanded] = useState(
    fraction.reprocesses.length > 0 || fraction.subfractions.length > 0
  );

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <FractionsTableRow
        fraction={fraction}
        className='fractions-table__main-fraction-row'
        isMainFraction={true}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        cuppingSessionEnabled={cuppingSessionEnabled}
      />

      {expanded && (
        <>
          {fraction.subfractions && (
            <RecursiveFractionsComponent
              fractions={fraction.subfractions}
              cuppingSessionEnabled={cuppingSessionEnabled}
            />
          )}

          {fraction.reprocesses.map((reprocess) => {
            return (
              <ReprocessRowComponent
                key={reprocess.id}
                fraction={fraction}
                reprocess={reprocess}
              />
            );
          })}

          <FractionTotal fraction={fraction} />
        </>
      )}

      <tr className='fractions-table__spacer'></tr>
    </>
  );
};

export default observer(MainFractionsComponent);
