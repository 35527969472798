import React, { ChangeEvent, useState } from 'react';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';

import Routes from 'routes';

import { DeliveryOrderAddress } from 'fulfillment/types/types';
import DeliveryOrder from '../../models/DeliveryOrder';

import styled from 'styled-components';
import FormButton from 'exports/components/FormButton';
import InputGroupComponent from 'components/groups/InputGroup';
import OverlayBaseForm from 'shared/Overlay/components/OverlayBaseForm';
import { OverlayFooter, OverlayTitle, OverlayWrapper } from 'shared/Overlay';
import { customFetch } from 'utils/fetch';

const Overlay = styled(OverlayBaseForm)``;

const AddressForm = styled.div`
  margin-bottom: 0.625em;
`;

function AddressOverlay() {
  const { deliveryOrderIndexStore } = useStores();
  const deliveryOrder = deliveryOrderIndexStore.activeDeliveryOrder as DeliveryOrder;
  const deliveryOrderAddress = deliveryOrder.deliveryOrderAddress;

  const defaultValues =
    deliveryOrderAddress !== undefined
      ? {
          id: deliveryOrderAddress.id,
          name: deliveryOrderAddress.name,
          address: deliveryOrderAddress.address,
          postcode: deliveryOrderAddress.postcode,
          city: deliveryOrderAddress.city,
          state: deliveryOrderAddress.state,
        }
      : {
          id: null,
          name: '',
          address: '',
          postcode: '',
          city: '',
          state: '',
        };

  const [deliveryAddress, setDeliveryAddress] = useState(defaultValues);

  const setCustomerAddress = () => {
    setDeliveryAddress({
      id: deliveryAddress.id,
      name: deliveryOrder.customer.name || '',
      address: deliveryOrder.customer.address || '',
      postcode: deliveryOrder.customer.postcode || '',
      city: deliveryOrder.customer.city || '',
      state: deliveryOrder.customer.state || '',
    });
  };

  const setWarehouseAddress = () => {
    setDeliveryAddress({
      id: deliveryAddress.id,
      name: deliveryOrder.destinationWarehouse.name || '',
      address: deliveryOrder.destinationWarehouse.address || '',
      postcode: deliveryOrder.destinationWarehouse.postcode || '',
      city: deliveryOrder.destinationWarehouse.city || '',
      state: deliveryOrder.destinationWarehouse.state || '',
    });
  };

  const setDeliveryAddressField = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.currentTarget;
    const fieldname = name.substring(name.lastIndexOf('[') + 1, name.lastIndexOf(']'));
    setDeliveryAddress({
      ...deliveryAddress,
      [fieldname]: value,
    });
  };

  const saveDeliveryOrderAddress = (
    _event: any,
    newDeliveryAddress: DeliveryOrderAddress
  ) => {
    const payload = {
      delivery_order_address_attributes: {
        ...newDeliveryAddress,
      },
    };

    customFetch(
      Routes.delivery_order_address_api_v1_fulfillment_delivery_order_path(
        deliveryOrder.id
      ),
      payload,
      'POST'
    ).then((_response) => window.location.reload());
  };

  return (
    <OverlayWrapper>
      <Overlay>
        <OverlayTitle>
          {I18n.translate('fulfillment.delivery_order_addresses.delivery_address')}
        </OverlayTitle>

        <AddressForm className='addressSource'>
          <FormButton
            className='button button--small button--green'
            onClick={setCustomerAddress}
          >
            Customer
          </FormButton>
          <FormButton
            className='button button--small button--green'
            onClick={setWarehouseAddress}
          >
            Warehouse
          </FormButton>
        </AddressForm>

        <div className='addressFields'>
          <InputGroupComponent
            type='text'
            name='delivery_order_address[name]'
            value={deliveryAddress.name}
            onChange={setDeliveryAddressField}
            placeholder='Name'
            data-cy='delivery_order_address_name'
          />
          <InputGroupComponent
            type='text'
            name='delivery_order_address[address]'
            value={deliveryAddress.address}
            onChange={setDeliveryAddressField}
            placeholder='Address'
            data-cy='delivery_order_address_address'
          />
          <InputGroupComponent
            type='text'
            name='delivery_order_address[postcode]'
            value={deliveryAddress.postcode}
            onChange={setDeliveryAddressField}
            placeholder='Postcode'
            data-cy='delivery_order_address_postcode'
          />
          <InputGroupComponent
            type='text'
            name='delivery_order_address[city]'
            value={deliveryAddress.city}
            onChange={setDeliveryAddressField}
            placeholder='City'
            data-cy='delivery_order_address_city'
          />
          <InputGroupComponent
            type='text'
            name='delivery_order_address[state]'
            value={deliveryAddress.state}
            onChange={setDeliveryAddressField}
            placeholder='State'
            data-cy='delivery_order_address_state'
          />
        </div>

        <OverlayFooter>
          <FormButton
            className='button button--small'
            onClick={deliveryOrderIndexStore.closeOverlay}
          >
            Cancel
          </FormButton>

          <FormButton
            className='button button--small'
            onClick={(e) => saveDeliveryOrderAddress(e, deliveryAddress)}
          >
            Save
          </FormButton>
        </OverlayFooter>
      </Overlay>
    </OverlayWrapper>
  );
}

export default observer(AddressOverlay);
