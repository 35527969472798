import DeliveryOrder from '../models/DeliveryOrder';
import { deserialise } from 'kitsu-core';
import { action, observable } from 'mobx';
import { JsonApi } from 'types/utils/jsonApi';
import type {
  DeliveryOrder as DeliveryOrderType,
  DeliveryOrderAddress,
} from '../types/types';
import DeliveryOrderCostStore from './DeliveryOrderCostStore';

type DeliveryOrderOverlays = 'notes' | 'address' | 'send' | 'shipping';

class DeliveryOrderIndexStore {
  @observable public deliveryOrderCostStore: DeliveryOrderCostStore;
  @observable public deliveryOrders = observable.array<DeliveryOrder>();
  @observable public activeDeliveryOrder: DeliveryOrder | null = null;
  @observable public activeDeliveryOrderAddress?: DeliveryOrderAddress;
  @observable public activeOverlay?: DeliveryOrderOverlays = undefined;

  constructor(deliveryOrdersData: JsonApi<DeliveryOrderType>) {
    const deserializedDeliveryOrders: DeliveryOrderType[] =
      deserialise(deliveryOrdersData).data;

    const deliveryOrders = deserializedDeliveryOrders.map(
      (deliveryOrderData) => new DeliveryOrder(deliveryOrderData)
    );

    this.deliveryOrders = observable.array(deliveryOrders);
    this.deliveryOrderCostStore = new DeliveryOrderCostStore();
  }

  @action public setActiveDeliveryOrder = (deliveryOrder: DeliveryOrder) => {
    this.activeDeliveryOrder = deliveryOrder;
  };

  /***************************************
   *********** Overlays ******************
   ***************************************/
  @action public closeOverlay = (): void => {
    this.activeDeliveryOrder = null;
    this.activeOverlay = undefined;
  };

  @action public openSendOverlay = async (
    deliveryOrder: DeliveryOrder
  ): Promise<void> => {
    this.setActiveDeliveryOrder(deliveryOrder);
    await this.deliveryOrderCostStore.setDeliveryOrder(deliveryOrder);
    this.activeOverlay = 'send';
  };

  @action public openAddressOverlay = (deliveryOrder: DeliveryOrder): void => {
    this.setActiveDeliveryOrder(deliveryOrder);
    this.activeOverlay = 'address';
  };

  @action public openShippingOptionsOverlay = (deliveryOrder: DeliveryOrder): void => {
    this.setActiveDeliveryOrder(deliveryOrder);
    this.activeOverlay = 'shipping';
  };

  @action public openNotesOverlay = (deliveryOrder: DeliveryOrder): void => {
    this.setActiveDeliveryOrder(deliveryOrder);
    this.activeOverlay = 'notes';
  };

  @action public setPallets = (event): void => {
    this.deliveryOrderCostStore.setPallets(event.currentTarget.value);
  };

  /***************************************
   *********** Object manipulation *******
   ***************************************/
  @action private updateDeliveryOrder = (id, payloadToUpdate): void => {
    const index = this.deliveryOrders.findIndex((p) => p.id === id);
    const toUpdateDeliveryOrder = this.deliveryOrders[index];
    Object.entries(payloadToUpdate).forEach(
      ([key, value]) => (toUpdateDeliveryOrder[key] = value)
    );
  };

  @action public saveDeliveryOrderAddress = (deliveryOrder, payload): void => {
    this.updateDeliveryOrder(deliveryOrder.id, payload);
    this.closeOverlay();
  };

  @action public saveNotes = (deliveryOrder, notes): void => {
    this.updateDeliveryOrder(deliveryOrder.id, { notes: notes });
    this.closeOverlay();
  };
}

export default DeliveryOrderIndexStore;
