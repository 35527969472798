import MillingTraceabilityBody from './MillingTraceabilityBody';
import MillingTraceabilityHeader from './MillingTraceabilityHeader';
import React from 'react';
import type { MillingTraceability as MillingTraceabilityType } from '../types/traceability';

export interface TraceabilityItemProps extends React.HTMLAttributes<HTMLDivElement> {
  traceability: MillingTraceabilityType;
}

const MillingTraceability = ({ traceability }: TraceabilityItemProps) => {
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <div>
      <MillingTraceabilityHeader
        traceability={traceability}
        collapsed={collapsed}
        onCollapsibleHandler={(_event: React.MouseEvent) => setCollapsed(!collapsed)}
      />

      {!collapsed && (
        <MillingTraceabilityBody
          assignedLots={traceability.assigned_lots}
          collapsed={collapsed}
        />
      )}
    </div>
  );
};

export default MillingTraceability;
