import React, { useMemo } from 'react';
import styled from 'styled-components';

import Icon from 'components/elements/Icon';
import { TileList } from 'shared/Tile';

import {
  CenteredSecondaryDetail,
  DateDetail,
  DisclosureDetail,
  IdentifierDetail,
  PrimaryDetail,
  SecondaryDetail,
} from './Detail';

import { TraceabilityDetails } from './Details';
import type { MillingTraceability } from '../types/traceability';
import TypeBadge from './TypeBadge';
import placeholder from 'utils/placeholder';
import { weightToString } from 'utils/weight';

const TextWithEllipsis = styled.div`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

const ContractDetails = styled.div`
  display: grid;

  & > div {
    display: flex;
    justify-content: space-between;
    overflow-x: hidden;
  }
`;

const GridDetails = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
`;

export interface MillingTraceabilityHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onCollapsibleHandler: (event: React.MouseEvent) => any;
  collapsed: boolean;
  traceability: MillingTraceability;
}

function TraceabilityHeader({
  traceability: {
    alternative_ico_id,
    assigned_coffees,
    closed_at,
    contract: { identifier, certificate, mark, price, customer },
    export_date,
    export_identifier,
    grade,
    local_identifier,
    milling_order_identifier,
    production_order_identifier,
    quality,
    units,
    weight,
  },
  collapsed,
  onCollapsibleHandler,
  ...props
}: MillingTraceabilityHeaderProps) {
  const format_secondary_ico = useMemo(() => {
    let string = local_identifier;
    if (alternative_ico_id) {
      string += '\n' + alternative_ico_id;
    }
    return string;
  }, [local_identifier, alternative_ico_id]);
  return (
    <TileList {...props}>
      <TraceabilityDetails>
        <TypeBadge type='ico'>ICO</TypeBadge>

        <DateDetail>{closed_at}</DateDetail>

        <PrimaryDetail data-cy='traceability_quality' align='center'>
          {placeholder(quality)}
        </PrimaryDetail>

        <PrimaryDetail data-cy='traceability_weight' align='right'>
          {weightToString(weight)}
        </PrimaryDetail>

        <DisclosureDetail align='center'>
          {assigned_coffees.length > 0 && (
            <Icon
              onClick={onCollapsibleHandler}
              icon={collapsed ? 'caret-down' : 'caret-up'}
              className={'selected-lots__disclosure-icon'}
            />
          )}
        </DisclosureDetail>

        <IdentifierDetail>{format_secondary_ico}</IdentifierDetail>

        <CenteredSecondaryDetail align='center'>
          {placeholder(grade)}
        </CenteredSecondaryDetail>

        <SecondaryDetail align='right' data-cy='traceability_number_of_bags'>
          {I18n.translate('attributes.units', { count: units })}
        </SecondaryDetail>
      </TraceabilityDetails>

      <ContractDetails>
        <div>
          <TextWithEllipsis>{identifier}</TextWithEllipsis>
          <TextWithEllipsis>{placeholder(certificate)}</TextWithEllipsis>
          <TextWithEllipsis>{`${price.value} ${price.currency}`}</TextWithEllipsis>
        </div>

        <div>
          <TextWithEllipsis>{customer}</TextWithEllipsis>
          <TextWithEllipsis>{placeholder(mark)}</TextWithEllipsis>
        </div>
      </ContractDetails>

      <GridDetails>
        <TextWithEllipsis>{placeholder(production_order_identifier)}</TextWithEllipsis>
        <TextWithEllipsis>{placeholder(milling_order_identifier)}</TextWithEllipsis>
        <TextWithEllipsis>{placeholder(export_identifier)}</TextWithEllipsis>
        <TextWithEllipsis>{placeholder(export_date)}</TextWithEllipsis>
      </GridDetails>
    </TileList>
  );
}

export default TraceabilityHeader;
