import React from 'react';
import Icon from '../elements/Icon';

export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  icon?: string;
}

const Badge: React.FC<BadgeProps> = ({ children, className, ...props }) => {
  let classNames = 'badge';

  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <span className={classNames} {...props}>
      <span>{children}</span>
      {!!props.icon && <Icon icon={props.icon} />}
    </span>
  );
};

export default Badge;
