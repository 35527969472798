import Routes from 'routes';
import ISampleBuilder from 'utils/builders/SampleBuilder';
import Roasting from 'productionOrders/models/Roasting';
import HumidityAnalysis from 'productionOrders/models/HumidityAnalysis';
import PhysicalAnalysis from 'productionOrders/models/PhysicalAnalysis';
import HumidityValidations from 'productionOrders/models/HumidityValidations';
import SensorialAnalysis from 'productionOrders/models/SensorialAnalysis';
import ISample from 'millingOrders/types/ISample';

class SampleBuilder<T extends ISample = ISample> implements ISampleBuilder<T> {
  public constructor(protected sample: T) {}

  public createHumidityAnalysis = (): HumidityAnalysis => {
    const { id, humidity_analysis_validations, current_humidity_analysis } = this.sample;

    const humidityValidations = new HumidityValidations(humidity_analysis_validations);

    return new HumidityAnalysis(
      id,
      current_humidity_analysis,
      humidityValidations,
      Routes.api_v1_sample_humidity_analyses_path(this.sample.id)
    );
  };

  public createPhysicalAnalysis = (): PhysicalAnalysis => {
    const { id, current_qa_physical_analysis } = this.sample;

    return new PhysicalAnalysis(id, current_qa_physical_analysis);
  };

  public createRoasting = (): Roasting => {
    const { id, current_roasting, initial_weight } = this.sample;

    return new Roasting(id, current_roasting, initial_weight);
  };

  public createSensorialAnalysis = (): undefined | SensorialAnalysis => {
    const { current_sensorial_analysis } = this.sample;

    const sensorialAnalysis = current_sensorial_analysis
      ? new SensorialAnalysis(current_sensorial_analysis)
      : undefined;

    return sensorialAnalysis;
  };
}

export default SampleBuilder;
