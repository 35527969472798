import React from 'react';
import SamplesTable from './SamplesTable';
import CuppingTaster from './CuppingTaster';
import ContentHeader from 'components/elements/ContentHeader';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import SelectedSamples from './SelectedSamples';
import useStores from 'utils/useStores';
import DestroyableSamples from './DestroyableSamples';

const CuppingTable = (): JSX.Element => {
  const { cuppingTableStore } = useStores();
  const cuppingTable = cuppingTableStore.cuppingTableId;
  return (
    <div className='content'>
      <AuthenticityToken />
      <ContentHeader title={I18n.translate('headlines.create_cupping_table')}>
        <button name='button' type='submit' className='button'>
          {cuppingTable
            ? I18n.translate('buttons.update')
            : I18n.translate('buttons.create')}
        </button>
      </ContentHeader>
      <SamplesTable />
      <SelectedSamples />
      <DestroyableSamples />
      <CuppingTaster />
      <TextAreaGroupComponent
        label={I18n.translate('cupping_tables.form.observations')}
        name={'cupping_table[observations]'}
      />
      <button name='button' type='submit' className='button'>
        {cuppingTable
          ? I18n.translate('buttons.update')
          : I18n.translate('buttons.create')}
      </button>
    </div>
  );
};

export default CuppingTable;
