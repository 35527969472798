import Icon from 'components/elements/Icon';
import Card from 'exports/components/Card';
import CardPlaceholder from 'exports/components/CardPlaceholder';
import { observer } from 'mobx-react';
import React from 'react';
import { TableCell, TableCellData, TableRowHeader } from 'shared/ScrollableTable';
import useStores from 'utils/useStores';
import CardDropzone from './CardDropzone';
import FormPopup from './FormPopup';
import TableDestinationPort from './TableDestinationPort';

function TableData(): JSX.Element {
  const { exportsStore } = useStores();

  const onDropzoneClick = (event: any) => {
    event.stopPropagation();
    if (
      !exportsStore.selectedIco ||
      !exportsStore.selectedExport ||
      !exportsStore.selectedDestinationPort
    ) {
      throw new Error('NO ICO SELECTED FOR MOVING');
    }
    exportsStore.createNewExport(
      exportsStore.selectedIco,
      exportsStore.selectedExport,
      exportsStore.selectedDestinationPort
    );
    exportsStore.resetSelectedModels();
  };

  return (
    <>
      {exportsStore.filteredDestinationPorts.map((destinationPort, i) => (
        <React.Fragment key={`${destinationPort.name}_${i}`}>
          {exportsStore.dates.map((date, index) => (
            <React.Fragment key={`${date.toUTCString()}_${i}_${index}`}>
              {index === 0 && (
                <TableRowHeader darkBackground={i % 2 === 0}>
                  <TableDestinationPort destinationPort={destinationPort} />
                </TableRowHeader>
              )}

              <TableCell darkBackground={i % 2 === 0}>
                {destinationPort.filterExportsForDate(date).map((exportModel) => (
                  <TableCellData key={`${exportModel.id}_export_card`}>
                    <Card export={exportModel} destinationPort={destinationPort} />
                  </TableCellData>
                ))}

                {exportsStore.selectedExport &&
                  exportsStore.selectedExport.canRemoveIcos &&
                  exportsStore.renderDropzoneForDestinationPort(destinationPort) && (
                    <CardPlaceholder>
                      <Icon icon='cross' color='#A4A9AD' />
                      <CardDropzone onClick={(e: any) => onDropzoneClick(e)} />
                    </CardPlaceholder>
                  )}
              </TableCell>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}

      {exportsStore.showShippingOverlay && exportsStore.selectedExport && (
        <FormPopup
          onPopupClose={() => exportsStore.setShowShippingOverlay(false)}
          export_={exportsStore.selectedExport}
          activeTabIndex={exportsStore.shippingTabIndex}
        />
      )}
    </>
  );
}

export default observer(TableData);
