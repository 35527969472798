import { observer } from 'mobx-react';
import React from 'react';
import useStores from 'utils/useStores';
import AddressOverlay from './AddressOverlay';
import DeliveryOrderRow from './DeliveryOrderRow';
import NotesOverlay from './NotesOverlay';
import SendOverlay from './SendOverlay';
import ShippingOptionsOverlay from './ShippingOptionsOverlay';

function DeliveryOrderTable() {
  const { deliveryOrderIndexStore } = useStores();
  return (
    <>
      <table>
        <thead>
          <tr>
            <th className='delivery-orders-index--edit'></th>
            <th className='delivery-orders-index--delivery align-center table-border'>
              {I18n.translate('fulfillment.delivery_orders.index.do')} |{' '}
              {I18n.translate('fulfillment.delivery_orders.index.customer')}
            </th>
            <th className='table-border align-center'>
              {I18n.translate('fulfillment.delivery_orders.index.warehouse')}
            </th>
            <th className='delivery-orders-index--status table-border'>
              {I18n.translate('fulfillment.delivery_orders.index.status')}
            </th>
            <th className='table-border'>
              {I18n.translate('fulfillment.delivery_orders.index.units_delivered')} |{' '}
              <br />
              {I18n.translate('fulfillment.delivery_orders.index.date_accepted')}
            </th>
            <th className='table-border'>
              {I18n.translate('fulfillment.delivery_orders.index.point_of_release')}
            </th>
            <th className='table-border'>
              {I18n.translate('fulfillment.delivery_orders.index.notes')}
            </th>
            <th>
              {I18n.translate('fulfillment.delivery_orders.index.send_to_customer')}
            </th>
          </tr>
        </thead>
        <tbody>
          {deliveryOrderIndexStore.deliveryOrders.map((deliveryOrder) => {
            return (
              <DeliveryOrderRow key={deliveryOrder.id} deliveryOrder={deliveryOrder} />
            );
          })}
        </tbody>
      </table>
      {deliveryOrderIndexStore.activeDeliveryOrder !== null &&
        deliveryOrderIndexStore.activeDeliveryOrder.destinationWarehouse
          .destination_warehouse_cost &&
        deliveryOrderIndexStore.activeOverlay === 'send' && <SendOverlay />}
      {deliveryOrderIndexStore.activeDeliveryOrder !== null &&
        deliveryOrderIndexStore.activeOverlay === 'address' && <AddressOverlay />}
      {deliveryOrderIndexStore.activeDeliveryOrder !== null &&
        deliveryOrderIndexStore.activeOverlay === 'shipping' && (
          <ShippingOptionsOverlay />
        )}
      {deliveryOrderIndexStore.activeDeliveryOrder !== null &&
        deliveryOrderIndexStore.activeOverlay === 'notes' && <NotesOverlay />}
    </>
  );
}

export default observer(DeliveryOrderTable);
