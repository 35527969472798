import React, { useState } from 'react';
import Overlay from 'shared/Overlay';

import InputGroupComponent from 'components/groups/InputGroup';
import { VARIANT } from 'constants/inputTypes';

export interface RepriceOverlayProps {
  onCloseClick: (event: React.MouseEvent) => void;
  onPrimaryClick: (event: React.MouseEvent, value: string) => void;
  currencySymbol: string;
}

const RepriceOverlay: React.FC<RepriceOverlayProps> = ({
  onCloseClick,
  onPrimaryClick,
  currencySymbol,
}) => {
  const [value, setValue] = useState('');

  const handleOnPrimaryClick = (event: React.MouseEvent) => {
    onPrimaryClick(event, value);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Overlay
      primaryText={I18n.translate('accept_price.index.reprice.overlay.primary')}
      onPrimaryClick={handleOnPrimaryClick}
      secondaryText={I18n.translate('accept_price.index.reprice.overlay.secondary')}
      onSecondaryClick={onCloseClick}
      title={I18n.translate('accept_price.index.reprice.overlay.title')}
    >
      <InputGroupComponent
        data-cy={'price'}
        label={I18n.translate('accept_price.index.reprice.overlay.label')}
        variant={VARIANT.DECIMAL}
        onChange={handleOnChange}
        value={value}
        append={currencySymbol}
        name='price'
      />
    </Overlay>
  );
};

export default RepriceOverlay;
