import React from 'react';
import styled from 'styled-components';

import Icon from 'components/elements/Icon';

export interface CardDropzoneProps {
  onClick: (e: any) => void;
}

const Dropzone = styled.div`
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 111, 148, 0.7);
  cursor: pointer;

  &:hover {
    background: rgba(0, 111, 148, 0.8);
  }

  .icon {
    transform: rotate(45deg);
    font-size: 1.75rem;
  }
`;

const CardDropzone = ({ onClick }: CardDropzoneProps) => (
  <Dropzone onClick={onClick}>
    <Icon icon='cross' color='#ffffff' />
  </Dropzone>
);

export default CardDropzone;
