import { action, computed, observable } from 'mobx';

import { PeabiesPhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';
import SpecialtyGradingSpecification from './SpecialtyGradingSpecification';
import QaDefect from './QaDefect';
import Norm from './Norm';
import { PreparationType } from '../types/Contract';
import Validation from 'types/utils/validations';

class PeabiesPhysicalAnalysis {
  private preparationType: PreparationType;
  private flatBeanPeaberryPercentage: Validation;
  public sampleId: number;
  @observable public sampleWeight: number;
  public type: string;
  public id?: number;
  public fullDefectsGroup1: number;
  public fullDefectsGroup2: number;
  public group1: string[];
  public group2: string[];
  public norm: Norm;
  @observable public sieve0: number;
  @observable public sieve12: number;
  @observable public sieve14: number;
  @observable public observations?: string;
  @observable public flatBeanGrains: number;
  @observable public flatBeanWeight: number;
  @observable public acceptingReasonId?: number;
  public specialtyGradingSpecifications: SpecialtyGradingSpecification[];
  // These fields keep snail_case to work with group definitions from backend
  @observable public full_black: QaDefect;
  @observable public partial_black: QaDefect;
  @observable public full_sour: QaDefect;
  @observable public partial_sour: QaDefect;
  @observable public dried_cherry: QaDefect;
  @observable public fungus_sour: QaDefect;
  @observable public foreign_matter: QaDefect;
  @observable public severe_insect_damage: QaDefect;
  @observable public parchment: QaDefect;
  @observable public broken: QaDefect;
  @observable public withered: QaDefect;
  @observable public immature: QaDefect;
  @observable public floater: QaDefect;
  @observable public hull: QaDefect;
  @observable public shell: QaDefect;
  @observable public slight_insect_damage: QaDefect;
  @observable public state: string;

  constructor(
    sample_id: number,
    physicaAnalysis: PeabiesPhysicalAnalysisType,
    specialtyGradingSpecifications: SpecialtyGradingSpecification[],
    norm: Norm,
    preparationType: PreparationType,
    { flat_bean_peaberry_percentage }: { [key: string]: Validation }
  ) {
    this.sampleId = sample_id;
    this.sampleWeight = physicaAnalysis.sample_weight;
    this.id = physicaAnalysis.id;
    this.type = physicaAnalysis.type;
    this.observations = physicaAnalysis.observations;
    this.sieve0 = physicaAnalysis.sieve_0;
    this.sieve12 = physicaAnalysis.sieve_12;
    this.sieve14 = physicaAnalysis.sieve_14;
    this.fullDefectsGroup1 = physicaAnalysis.full_defects_group_1;
    this.fullDefectsGroup2 = physicaAnalysis.full_defects_group_2;
    this.flatBeanGrains = physicaAnalysis.flat_bean_grains;
    this.flatBeanWeight = physicaAnalysis.flat_bean_weight;
    this.acceptingReasonId = physicaAnalysis.accepting_reason_id;
    this.group1 = physicaAnalysis.group_1;
    this.group2 = physicaAnalysis.group_2;
    this.specialtyGradingSpecifications = specialtyGradingSpecifications;
    this.state = physicaAnalysis.state;
    this.norm = norm;
    this.preparationType = preparationType;
    this.flatBeanPeaberryPercentage = flat_bean_peaberry_percentage;
    this.full_black = new QaDefect(physicaAnalysis.full_black);
    this.partial_black = new QaDefect(physicaAnalysis.partial_black);
    this.full_sour = new QaDefect(physicaAnalysis.full_sour);
    this.partial_sour = new QaDefect(physicaAnalysis.partial_sour);
    this.dried_cherry = new QaDefect(physicaAnalysis.dried_cherry);
    this.fungus_sour = new QaDefect(physicaAnalysis.fungus_sour);
    this.foreign_matter = new QaDefect(physicaAnalysis.foreign_matter);
    this.severe_insect_damage = new QaDefect(physicaAnalysis.severe_insect_damage);
    this.parchment = new QaDefect(physicaAnalysis.parchment);
    this.broken = new QaDefect(physicaAnalysis.broken);
    this.withered = new QaDefect(physicaAnalysis.withered);
    this.immature = new QaDefect(physicaAnalysis.immature);
    this.floater = new QaDefect(physicaAnalysis.floater);
    this.hull = new QaDefect(physicaAnalysis.hull);
    this.shell = new QaDefect(physicaAnalysis.shell);
    this.slight_insect_damage = new QaDefect(physicaAnalysis.slight_insect_damage);
  }

  @action setSampleWeight = (sampleWeight: number) => {
    this.sampleWeight = sampleWeight;
  };

  @action setSieve0 = (sieve0: number) => {
    this.sieve0 = sieve0 ? sieve0 : 0;
  };

  @action setSieve12 = (sieve12: number) => {
    this.sieve12 = sieve12 ? sieve12 : 0;
  };

  @action setSieve14 = (sieve14: number) => {
    this.sieve14 = sieve14 ? sieve14 : 0;
  };

  @action setFlatBeanGrains = (grains: number) => {
    this.flatBeanGrains = grains ? grains : 0;
  };

  @action setFlatBeanWeight = (weight: number) => {
    this.flatBeanWeight = weight ? weight : 0;
  };

  @action setObservations = (observations: string) => {
    this.observations = observations;
  };

  @computed get group1FullDefects() {
    const fullDefects = this.group1?.map((groupIdentifier) => {
      const defectGrains = this[groupIdentifier]?.grains;

      const specialtyGrading = this.specialtyGradingSpecifications.find(
        (specification) => {
          return specification.gradingIdentifier === groupIdentifier;
        }
      );

      const fullDefectEquivalent = specialtyGrading?.fullDefectEquivalent;

      return defectGrains && fullDefectEquivalent
        ? defectGrains / fullDefectEquivalent
        : 0;
    });

    return fullDefects.reduce((prev, current) => prev + current, 0);
  }

  @computed get group1FullWeight() {
    const fullWeights = this.group1.map((groupIdentifier) => {
      const defectWeight = this[groupIdentifier]?.weight;
      return defectWeight ? defectWeight : 0;
    });

    return fullWeights.reduce((prev, current) => prev + current, 0);
  }

  @computed get group1FullGrains() {
    const fullGrains = this.group1.map((groupIdentifier) => {
      const defectGrains = this[groupIdentifier]?.grains;
      return defectGrains ? defectGrains : 0;
    });

    return fullGrains.reduce((prev, current) => prev + current, 0);
  }

  @computed get group2FullDefects() {
    const fullDefects = this.group2.map((groupIdentifier) => {
      const defectGrains = this[groupIdentifier]?.grains;

      const specialtyGrading = this.specialtyGradingSpecifications.find(
        (specification) => {
          return specification.gradingIdentifier === groupIdentifier;
        }
      );

      const fullDefectEquivalent = specialtyGrading?.fullDefectEquivalent;

      return defectGrains && fullDefectEquivalent
        ? defectGrains / fullDefectEquivalent
        : 0;
    });

    return fullDefects.reduce((prev, current) => prev + current, 0);
  }

  @computed get group2FullWeight() {
    const fullWeights = this.group2.map((groupIdentifier) => {
      const defectWeight = this[groupIdentifier]?.weight;
      return defectWeight ? defectWeight : 0;
    });

    return fullWeights.reduce((prev, current) => prev + current, 0);
  }

  @computed get group2FullGrains() {
    const fullGrains = this.group2.map((groupIdentifier) => {
      const defectGrains = this[groupIdentifier]?.grains;
      return defectGrains ? defectGrains : 0;
    });

    return fullGrains.reduce((prev, current) => prev + current, 0);
  }

  @computed get exceedsNorm() {
    const exceedsGroup1 =
      this.group1FullDefects > this.norm.group1Defects + this.norm.tolerance;
    const exceedsGroup2 =
      this.group2FullDefects > this.norm.group2Defects + this.norm.tolerance;
    const exceedsSum =
      this.group1FullDefects + this.group2FullDefects >
      this.norm.group1Defects + this.norm.group2Defects + this.norm.tolerance;

    return exceedsGroup1 || exceedsGroup2 || exceedsSum;
  }

  @computed get isRejectedInitiallyChecked() {
    let rejectInitiallly = false;

    if (this.id) {
      if (this.exceedsNorm) {
        rejectInitiallly = this.acceptingReasonId ? false : true;
      } else if (this.exceedsFlatBeanPeaberryPercentage) {
        rejectInitiallly = true;
      } else if (this.state === 'failed') {
        rejectInitiallly = true;
      }
    } else {
      rejectInitiallly = this.exceedsNorm || this.exceedsFlatBeanPeaberryPercentage;
    }

    return rejectInitiallly;
  }

  @computed get exceedsFlatBeanPeaberryPercentage() {
    let exceeded = false;

    if (
      this.preparationType === 'peaberries' &&
      this.getWeightPercentage(this.flatBeanWeight) > this.flatBeanPeaberryPercentage.max
    ) {
      exceeded = true;
    }

    return exceeded;
  }

  @computed get isValid() {
    return true;
  }

  getWeightPercentage = (weight: number): number => {
    return weight > 0 ? (weight * 100) / this.sampleWeight : 0;
  };

  getWeightPercentageString = (weight: number): string => {
    const percentage = this.getWeightPercentage(weight);

    return percentage > 0 ? `${percentage.toFixed(2)}%` : '---';
  };
}

export default PeabiesPhysicalAnalysis;
