import { action, computed, observable } from 'mobx';
import Routes from 'routes';

import type IDestinationPort from '../types/IDestinationPort';
import Export from '../models/Export';
import { customFetch } from 'utils/fetch';
import { JsonApi } from 'types/utils/jsonApi';
import { deserialise } from 'kitsu-core';

class DestinationPort {
  public id: number;
  public country_id: number;
  public name: string;
  public exports = observable.array<Export>();

  constructor(destinationPort: IDestinationPort) {
    this.id = destinationPort.id;
    this.name = destinationPort.name;

    const exports = destinationPort.exports.map((exportData) => new Export(exportData));
    this.exports = observable.array(exports);
  }

  static fetch = async (destinationPortId: number): Promise<DestinationPort> => {
    const response = await customFetch<JsonApi<Pick<IDestinationPort, 'id' | 'name'>>>(
      Routes.api_v1_destination_port_path(destinationPortId),
      undefined,
      'GET'
    );

    const deserializedDestinationPort: Pick<IDestinationPort, 'id' | 'name'> =
      deserialise(response).data;

    return new DestinationPort({ ...deserializedDestinationPort, exports: [] });
  };

  @action toggleExportsCollapsed = (): void => {
    if (this.allExportsCollapsed) {
      this.exports.map((exportData) => exportData.setCollapsed(false));
    } else {
      this.exports.map((exportData) => exportData.setCollapsed(true));
    }
  };

  @action public addExport = (export_: Export): void => {
    this.exports.push(export_);
  };

  @action public removeExport = (export_: Export): void => {
    this.exports.remove(export_);
  };

  @action public moveExport = (export_: Export, otherPort: DestinationPort): void => {
    otherPort.addExport(export_);
    this.exports.remove(export_);
  };

  @computed get allExportsCollapsed(): boolean {
    return this.exports.every((exportData) => exportData.collapsed === true);
  }

  @computed get allExportsExpanded(): boolean {
    return this.exports.every((exportData) => exportData.collapsed === false);
  }

  @computed get hasExports(): boolean {
    return this.exports.length > 0;
  }

  isExcluded = (excludedDestinationPortNames: string[]): boolean =>
    excludedDestinationPortNames.includes(this.name);

  filterExportsForDate = (date: Date): Export[] => {
    const comparisonMonth = date.getUTCMonth();
    const comparisonYear = date.getUTCFullYear();

    return this.exports.filter(
      (exportData) =>
        exportData.shipmentMonth &&
        exportData.shipmentMonth.getUTCMonth() === comparisonMonth &&
        exportData.shipmentMonth.getUTCFullYear() === comparisonYear
    );
  };
}

export default DestinationPort;
