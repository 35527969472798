import Icon from 'components/elements/Icon';
import InputGroup from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import COLORS from 'constants/colors';
import ThirdPartyImport from 'exports/types/thirdPartyImport';
import React, { ChangeEvent, FocusEvent, useMemo, useState } from 'react';
import Routes from 'routes';
import ErrorsComponent from 'shared/Errors/components/Errors';
import styled from 'styled-components';
import SelectOptionArray from 'types/model/selectOption';
import { customFetch } from 'utils/fetch';
import { trimWhitespaces } from 'utils/string';

export interface IcoPopupProps {
  thirdPartyImport: ThirdPartyImport;
  destinationWarehouses: SelectOptionArray;
  readonly: boolean;
}

const InputGroupWrapper = styled(InputGroup)`
  height: auto !important;
`;

const SelectGroupWrapper = styled.div`
  .input-group {
    min-width: 10rem;
    margin-bottom: 0;
  }

  select {
    height: auto;
  }
`;

const IcoNameWrapper = styled.td`
  min-width: 10rem;
`;

const DestinationWrapper = styled.td`
  min-width: 12rem;
`;

const ButtonWrapper = styled.div`
  min-width: 13rem;
`;

const BinWrapper = styled.div`
  min-width: 7rem;
`;

const WeightWrapper = styled.p`
  font-size: 1rem;
  color: ${COLORS.GRAY};
`;

const Errors = styled(ErrorsComponent)`
  margin-bottom: 0.2em;
`;

const ThirdPartyImportRow = ({
  thirdPartyImport,
  destinationWarehouses,
  readonly,
}: IcoPopupProps) => {
  const [destinationWarehouseId, setDestinationWarehouseId] = useState(
    thirdPartyImport.destination_warehouse_id || ''
  );
  const [arrivalDate, setArrivalDate] = useState(thirdPartyImport.arrival_date || '');
  const [bin, setBin] = useState(thirdPartyImport.bin || '');
  const [isClosed, setIsClosed] = useState(thirdPartyImport.closed || readonly);
  const [thirdPartyIcoId, setThirdPartyIcoId] = useState(
    thirdPartyImport.third_party_ico_id || ''
  );

  const [errors, setErrors] = useState([]);
  const [updateErrors, setUpdateErrors] = useState([]);

  if (!destinationWarehouses) {
    throw new Error('Provide serialized destinationWarehouseData');
  }

  const disabledCloseButton = useMemo(
    () =>
      !arrivalDate ||
      !destinationWarehouseId ||
      !bin ||
      !thirdPartyIcoId ||
      updateErrors.length > 0,
    [arrivalDate, destinationWarehouseId, bin, thirdPartyIcoId, updateErrors]
  );

  const handleOnCloseClick = () => {
    customFetch(
      Routes.close_api_v1_exports_third_party_imports_path(),
      { third_party_import_id: thirdPartyImport.id },
      'POST'
    ).then((response) => {
      if (response.success) {
        setIsClosed(true);
        setErrors([]);
      } else {
        setErrors(response.errors);
      }
    });
  };

  const handleOnBlur = (_event: FocusEvent<HTMLInputElement>): void => {
    // We dont read from the event, because the change handler already set the value
    const thirdPartyImportPayload = {
      third_party_import_id: thirdPartyImport.id,
      arrival_date: arrivalDate,
      destination_warehouse_id: destinationWarehouseId,
      bin: bin,
      third_party_ico_id: thirdPartyIcoId,
    };

    customFetch(
      Routes.api_v1_exports_third_party_imports_path(),
      { third_party_import: thirdPartyImportPayload },
      'POST'
    ).then((response) => {
      if (!response.success) {
        setUpdateErrors(response.errors);
        const { third_party_import } = response;
        if (third_party_import) {
          setArrivalDate(third_party_import.arrival_date);
          setBin(third_party_import.bin);
          setThirdPartyIcoId(third_party_import.third_party_ico_id);
        }
      } else {
        setUpdateErrors([]);
      }
    });
  };

  const handleOnBinChange = (event: FocusEvent<HTMLInputElement>): void => {
    const trimmedValue = trimWhitespaces(event.currentTarget.value);
    setBin(trimmedValue);
  };

  const handleOnThirdPartyIcoIdChange = (event: FocusEvent<HTMLInputElement>): void => {
    const trimmedValue = trimWhitespaces(event.currentTarget.value);
    setThirdPartyIcoId(trimmedValue);
  };

  const handleOnDestinationWarehouseChange = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const destinationId = event.currentTarget.value;

    setDestinationWarehouseId(destinationId);

    const thirdPartyImportPayload = {
      third_party_import_id: thirdPartyImport.id,
      arrival_date: arrivalDate,
      destination_warehouse_id: destinationId,
      bin: bin,
      third_party_ico_id: thirdPartyIcoId,
    };
    customFetch(
      Routes.api_v1_exports_third_party_imports_path(),
      { third_party_import: thirdPartyImportPayload },
      'POST'
    );
  };

  return (
    <tr key={thirdPartyImport.id}>
      <IcoNameWrapper>
        {thirdPartyImport.contract_identifier}
        <WeightWrapper>{thirdPartyImport.weight}</WeightWrapper>
      </IcoNameWrapper>

      <DestinationWrapper>
        {thirdPartyImport.origin_country_code}
        <WeightWrapper>
          {thirdPartyImport.destination_port_name} |{' '}
          {thirdPartyImport.destination_country_code}
        </WeightWrapper>
      </DestinationWrapper>

      <td className='input-table-data'>
        <SelectGroupWrapper>
          <SelectGroup
            placeholder={I18n.translate('exports.transports_popup.select_warehouse')}
            options={destinationWarehouses}
            name='destination_warehouse_id'
            value={destinationWarehouseId}
            onChange={(e) => handleOnDestinationWarehouseChange(e)}
            disabled={isClosed}
          />
        </SelectGroupWrapper>
      </td>

      <td className='input-table-data'>
        <InputGroupWrapper
          name='arrival_date'
          noMargin={true}
          type='date'
          value={arrivalDate}
          onChange={(e) => setArrivalDate(e.target.value)}
          disabled={isClosed}
          onBlur={handleOnBlur}
        />
      </td>

      <td className='input-table-data'>
        <BinWrapper>
          <InputGroupWrapper
            name='bin'
            noMargin={true}
            placeholder='00000000'
            value={bin}
            onChange={handleOnBinChange}
            disabled={isClosed}
            onBlur={handleOnBlur}
          />
        </BinWrapper>
      </td>

      <td className='input-table-data'>
        <BinWrapper>
          <InputGroupWrapper
            name='third_party_ico_id'
            noMargin={true}
            placeholder='00000000'
            value={thirdPartyIcoId}
            onChange={handleOnThirdPartyIcoIdChange}
            disabled={isClosed}
            onBlur={handleOnBlur}
          />
        </BinWrapper>
      </td>

      <td>
        <Errors errors={errors} />
        <Errors errors={updateErrors} />
        <ButtonWrapper>
          {isClosed ? (
            <Icon icon='check' color='#6EA340' />
          ) : (
            <button
              type='button'
              onClick={handleOnCloseClick}
              disabled={disabledCloseButton}
              className='button button--with-blue-border exports-card__popup-button'
            >
              {I18n.translate('buttons.close')}
            </button>
          )}
        </ButtonWrapper>
      </td>
    </tr>
  );
};
export default ThirdPartyImportRow;
