import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';
import React, { useState } from 'react';
import Checkbox from 'shared/Checkbox';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  margin: 1em 0 1.7em 0;
`;

export interface TrailerSelectionProps {
  hasTrailerChecked?: boolean;
  trailerRegistration?: null | string;
}

const TrailerSelection = ({
  hasTrailerChecked = false,
  trailerRegistration,
}: TrailerSelectionProps) => {
  const [checked, setChecked] = useState(hasTrailerChecked);

  return (
    <Grid>
      <CheckboxWrapper>
        <Checkbox
          icon='tick'
          label={I18n.translate('transports.form.has_trailer')}
          name='transport[has_trailer]'
          value='true'
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
      </CheckboxWrapper>

      {checked && (
        <InputGroupComponent
          type='text'
          label={I18n.translate('transports.form.trailer_registration')}
          placeholder={I18n.translate('transports.form.trailer_registration_placeholder')}
          name='transport[trailer_registration]'
          defaultValue={trailerRegistration || ''}
        />
      )}
    </Grid>
  );
};

export default TrailerSelection;
