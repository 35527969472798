import { Option } from 'components/elements/Search';
import PaginatedTableStore from 'components/shared/PaginatedTable/stores/PaginatedTableStore';
import { action, computed, observable } from 'mobx';
import Routes from 'routes';
import { LotGet } from 'types/model/lot';
import LotSetGet from 'types/model/lot_set';
import IConsolidateSample from '../../types/IConsolidateSample';
import SelectOptionArray from 'types/model/selectOption';
import { customFetch } from 'utils/fetch';
import ConsolidateSampleSelectable from '../models/ConsolidateSampleSelectable';
import SelectedSamplesStore from './SelectedSamplesStore';
import {
  PaginatedErrorResponse,
  PaginatedSuccessResponse,
} from 'components/shared/PaginatedTable/utils/paginatedTableCustomFetch';

interface IcoSelect {
  id: number;
  name: string;
  can_reserve_coffee: boolean;
  disabled: boolean;
}

class ConsolidateSamplesStore {
  // Preloaded Options data
  // TODO:
  public qualities: SelectOptionArray = [];
  public sampleTypes: SelectOptionArray = [];
  public marks: SelectOptionArray = [];

  // Filter fields
  @observable public selectedQuality = '';
  @observable public searchString = '';

  // Create sample fields
  @observable public selectedSampleTypeId: number;
  @observable public selectedMarkId?: number;

  // Internal stores
  @observable public paginatedTableStore: PaginatedTableStore<IConsolidateSample>;
  public selectedSamplesStore: SelectedSamplesStore;

  // UI State
  @observable public showOpportunityOverlay = false;
  @observable public showIcoOverlay = false;
  @observable public submitting = false;

  @observable public opportunities: SelectOptionArray = [];
  @observable public icos = Array<IcoSelect>();

  @observable public errors: Array<string> = [];

  constructor(
    sampleTypes: SelectOptionArray,
    marks: SelectOptionArray,
    qualities: SelectOptionArray,
    selectedSampleType?: number
  ) {
    this.sampleTypes = sampleTypes;
    this.marks = marks;
    this.qualities = qualities;

    this.paginatedTableStore = new PaginatedTableStore(
      Routes.paginated_index_api_v1_samples_path,
      this.successHandler,
      this.errorHandler,
      Routes.create_consolidate_samples_path
    );

    this.selectedSamplesStore = new SelectedSamplesStore(
      Routes.create_consolidate_samples_path
    );

    const params = this.paginatedTableStore.urlStringToPaginateParams(
      window.location.href.split('?')[1]
    );

    this.selectedQuality = params.params['quality'] || '';
    this.searchString = params.params['local_identifier'] || '';
    this.selectedMarkId = 0;

    this.selectedSampleTypeId = selectedSampleType || Number(sampleTypes[0].id);
  }

  @action public setSelectedSampleTypeId = (typeId: number): void => {
    this.selectedSampleTypeId = typeId;
  };

  @action public setSelectedMarkId = (markId?: number): void => {
    this.selectedMarkId = markId ? Number(markId) : undefined;
  };

  @action public toggleSampleSelection = (sample: ConsolidateSampleSelectable): void => {
    this.selectedSamplesStore.toggleSampleSelection(sample);
  };

  public findSample = (
    sample: IConsolidateSample
  ): ConsolidateSampleSelectable | undefined => {
    return this.selectedSamplesStore.findSample(sample);
  };

  @action public setSampleSize = (sample: ConsolidateSampleSelectable, value: string) => {
    this.selectedSamplesStore.setSampleSize(sample, value);
  };

  @action public setSampleReservedSize = (sample: IConsolidateSample, value: string) => {
    this.selectedSamplesStore.setSampleReservedSize(sample, value);
  };

  public isSelected = (id: string | number): boolean => {
    return this.selectedSamplesStore.isSelected(id);
  };

  @action public setSelectedQuality = (quality: string): void => {
    this.selectedQuality = quality;
  };

  @action public setSearchString = (searchString: string): void => {
    this.searchString = searchString;
  };

  @action public setSubmitting = (submitting: boolean): void => {
    this.submitting = submitting;
  };

  @action public successHandler = (
    response: PaginatedSuccessResponse<IConsolidateSample>
  ): void => {
    this.paginatedTableStore.updatePagy(response.pagy);
    this.paginatedTableStore.setLoadedRows(response.data);
  };

  public errorHandler = (response: PaginatedErrorResponse) => {
    this.errors = response.errors;
  };

  @computed get searchableIcos(): Option[] {
    return this.icos.map((ico) => ({
      value: ico.id,
      label: ico.name,
      isDisabled: ico.disabled,
    }));
  }

  @computed get selectedSamples() {
    return this.selectedSamplesStore.selectedSamples;
  }

  @computed get selectedLots(): LotGet[] {
    return this.selectedSamples
      .filter(
        (sample) => sample.attributes.lot !== undefined && sample.attributes.lot.id > 0
      )
      .map((sample) => {
        return sample.attributes.lot as LotGet;
      });
  }

  @computed get selectedLotSets(): LotSetGet[] {
    return this.selectedSamples
      .filter(
        (sample) =>
          sample.attributes.lot_set !== undefined && sample.attributes.lot_set.id > 0
      )
      .map((sample) => {
        return sample.attributes.lot_set as LotSetGet;
      });
  }

  @action public fetchIcosOpportunities = (afterCallback: () => void) => {
    customFetch(
      Routes.consolidate_icos_opportunities_api_v1_samples_path(),
      undefined,
      'GET'
    )
      .then((response) => {
        this.opportunities = response.opportunities;
        this.icos = response.icos;
        afterCallback();
      })
      .catch((response) => console.log(response));
  };

  @action public setShowOpportunityOverlay = (newState: boolean) => {
    if (newState) {
      this.fetchIcosOpportunities(() => (this.showOpportunityOverlay = newState));
    } else {
      this.showOpportunityOverlay = newState;
    }
  };

  @action public setShowIcoOverlay = (newState: boolean) => {
    if (newState) {
      this.fetchIcosOpportunities(() => (this.showIcoOverlay = newState));
    } else {
      this.showIcoOverlay = newState;
    }
  };

  @computed get totalWeight(): number {
    return this.selectedSamples.reduce((totalWeight, sample) => {
      const selectedWeight = sample.attributes.sampleSize
        ? parseInt(sample.attributes.sampleSize, 10)
        : 0;
      return totalWeight + selectedWeight;
    }, 0);
  }

  // TODO: Remove magic numbers, at least define constants.
  @computed get isOfferSample(): boolean {
    return this.selectedSampleTypeId === 20;
  }

  @computed get isMixedSample(): boolean {
    return this.selectedSampleTypeId === 60;
  }

  @computed get isPreShipmentSample(): boolean {
    return this.selectedSampleTypeId === 120;
  }

  @computed get isMillSample(): boolean {
    return this.selectedSampleTypeId === 240;
  }

  @computed get isSingleSelectedLabSample(): boolean {
    return (
      this.selectedSamples.length === 1 &&
      this.selectedSamples[0].attributes.sample_type === 'sample_lab'
    );
  }

  @computed get hasValidTotalWeight(): boolean {
    return !this.isPreShipmentSample || this.totalWeight >= 500;
  }

  @computed private get selectedSamplesHaveWeight(): boolean {
    return !this.selectedSamples.find(
      (sample) =>
        !sample.attributes.sampleSize || Number(sample.attributes.sampleSize) < 1
    );
  }

  @computed get isFormValid(): boolean {
    let isValid: boolean;
    if (this.isMixedSample) {
      isValid = this.selectedSamples.length > 1 && !!this.selectedMarkId;
    } else if (this.isPreShipmentSample || this.isMillSample) {
      isValid = this.selectedSamples.length >= 1 && this.hasValidTotalWeight;
    } else {
      isValid = this.isSingleSelectedLabSample || !!this.selectedMarkId;
    }
    return isValid && this.selectedSamplesHaveWeight;
  }
}

export default ConsolidateSamplesStore;
