import React, { useState } from 'react';
import styled from 'styled-components';
import useStores from 'utils/useStores';
import FormButton from 'exports/components/FormButton';
import OverlayBaseForm from 'shared/Overlay/components/OverlayBaseForm';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import { OverlayFooter, OverlayTitle, OverlayWrapper } from 'shared/Overlay';
import { observer } from 'mobx-react';
import DeliveryOrder from '../../models/DeliveryOrder';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';

const Overlay = styled(OverlayBaseForm)``;

function NotesOverlay() {
  const { deliveryOrderIndexStore } = useStores();
  const deliveryOrder = deliveryOrderIndexStore.activeDeliveryOrder as DeliveryOrder;
  const tmpNotes = deliveryOrder !== null ? deliveryOrder.notes : '';

  const [notes, setNotes] = useState(tmpNotes || '');

  const updateNote = (event: any) => {
    setNotes(event.currentTarget.value);
  };

  const saveNote = (_event: any) => {
    const payload = {
      delivery_order: { observations: notes },
    };

    customFetch(
      Routes.api_v1_fulfillment_delivery_order_path(deliveryOrder.id),
      payload,
      'POST'
    ).then((_response) => {
      deliveryOrderIndexStore.saveNotes(deliveryOrder, notes);
    });
  };

  return (
    <OverlayWrapper>
      <Overlay>
        <OverlayTitle>{I18n.translate('fulfillment.delivery_orders.notes')}</OverlayTitle>
        <TextAreaGroupComponent value={notes} onChange={updateNote} />
        <OverlayFooter>
          <FormButton
            className='button button--small'
            onClick={deliveryOrderIndexStore.closeOverlay}
          >
            Cancel
          </FormButton>

          <FormButton className='button button--small' onClick={saveNote}>
            Save
          </FormButton>
        </OverlayFooter>
      </Overlay>
    </OverlayWrapper>
  );
}

export default observer(NotesOverlay);
