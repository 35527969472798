import ExcelsoInventoryModel from 'fulfillment/models/ExcelsoInventoryModel';
import { action, computed, observable } from 'mobx';

import {
  DeliveryOrder,
  GPExcelsoInventory,
  GPSpotExcelsoInventory,
} from '../types/types';

class DeliveryOrderStore {
  public usedIcoIds: string[];
  public usedSpotContractIds: string[];

  @observable public customers = Array<{ id: string; value: string }>();
  @observable public destinationWarehouses = Array<{ id: string; value: string }>();
  @observable public deliveryOrder: DeliveryOrder | undefined;
  @observable public excelsoInventoryItems = observable.array<ExcelsoInventoryModel>();
  @observable public activeExcelsoInventoryModel: ExcelsoInventoryModel;
  @observable public selectedCustomerId?: number = 0;
  @observable public selectedWarehouseId?: number = 0;

  constructor(
    destinationWarehouses: Array<{ id: string; value: string }> = [],
    customers: Array<{ id: string; value: string }> = [],
    usedIcoIds: string[],
    usedSpotContractIds: string[],
    deliveryOrder: DeliveryOrder | undefined
  ) {
    this.destinationWarehouses = observable.array(destinationWarehouses);
    this.customers = observable.array(customers);
    this.usedIcoIds = usedIcoIds;
    this.usedSpotContractIds = usedSpotContractIds;
    this.deliveryOrder =
      deliveryOrder !== undefined ? observable(deliveryOrder) : undefined;

    if (this.deliveryOrder !== undefined) {
      this.setSelectedCustomerId(this.deliveryOrder.customer.id);
      this.setSelectedWarehouseId(this.deliveryOrder.destination_warehouse.id);
    }
  }

  @action public setSelectedCustomerId = (customerId: string | number) => {
    this.selectedCustomerId = Number(customerId);
  };

  @action public setSelectedWarehouseId = (warehouseId: string | number) => {
    this.selectedWarehouseId = Number(warehouseId);
  };

  @action public setExcelsoInventory = (
    excelsoInventoryItems: (GPExcelsoInventory | GPSpotExcelsoInventory)[]
  ) => {
    // Merge with delivery order when available
    const newInventory = excelsoInventoryItems.map((excelsoInventoryItem) => {
      const deliveryOrderItems = this.deliveryOrder?.delivery_order_items || [];

      const deliveryOrderItem = deliveryOrderItems.find((deliveryOrderItem) => {
        if (excelsoInventoryItem.excelso_type === 'spot') {
          return (
            deliveryOrderItem.contract_identifier ==
            excelsoInventoryItem.ico_data.contract_identifier
          );
        } else {
          return deliveryOrderItem.ico_identifier == excelsoInventoryItem.ico_identifier;
        }
      });

      let reserved = false;

      if (excelsoInventoryItem.excelso_type === 'spot') {
        reserved = this.usedSpotContractIds.includes(
          excelsoInventoryItem.ico_data.contract_identifier
        );
      } else {
        reserved = this.usedIcoIds.includes(excelsoInventoryItem.ico_identifier);
      }

      return new ExcelsoInventoryModel(excelsoInventoryItem, deliveryOrderItem, reserved);
    });

    this.excelsoInventoryItems.replace(newInventory);
  };

  @computed get selectedSources(): Array<ExcelsoInventoryModel> {
    return observable.array(this.excelsoInventoryItems.filter((item) => item.checked));
  }

  @computed get selectedSourcesValid(): boolean {
    return (
      this.selectedSources.length > 0 &&
      this.selectedSources.every((item) => parseInt(item.quantity, 10) > 0)
    );
  }

  @computed get isFormValid(): boolean {
    return this.selectedSourcesValid;
  }
}

export default DeliveryOrderStore;
