import { deserialise } from 'kitsu-core';
import { observable } from 'mobx';

import { LotGet } from 'types/model/lot';

import LotCalibrationModel from '../models/LotCalibrationModel';

export interface CalibrationLotModel {
  lot: LotGet;
  model: LotCalibrationModel;
}

class CalibrationStore {
  public calibrationLots = observable.array<CalibrationLotModel>();
  public bags = Array<any>();
  public tagColors = Array<{ id: number; name: string; color: string }>();

  constructor(
    lots: any,
    bags: any,
    tagColors: Array<{ id: number; name: string; color: string }>
  ) {
    const deserialisedLots = deserialise(lots).data;

    this.calibrationLots = observable.array(
      deserialisedLots.map((lot: LotGet) => {
        return {
          lot,
          model: new LotCalibrationModel(lot),
        };
      })
    );

    this.bags = bags;
    this.tagColors = tagColors;
  }
}

export default CalibrationStore;
