import React from 'react';
import InputGroupComponent, { InputGroupProps } from 'components/groups/InputGroup';
import { IFieldInput } from './IFieldInput';

export const ControlledTextInput = ({
  field,
  value,
  onChange,
  namespace,
  ...rest
}: IFieldInput & InputGroupProps) => {
  if (field == undefined || !field.visible) {
    return null;
  }

  value = value != undefined ? value : field.value;

  const fieldNameWithoutId = field.name.replace('_id', '');

  // Poor mans I18n Pluralization
  const namespace_plural =
    namespace.slice(-1) == 'y' ? namespace.slice(0, -1) + 'ies' : namespace + 's';

  const label = field.label
    ? I18n.translate(`${namespace_plural}.form.${field.label}`)
    : I18n.translate(`${namespace_plural}.form.${fieldNameWithoutId}`);

  const onchange = (e, onChangeArg) => {
    if (!onChangeArg) {
      return onChange && onChange(e.target.value);
    }

    const changer = onChangeArg(e) ?? e.target.value;

    onChange && onChange(changer);
  };
  return (
    <InputGroupComponent
      defaultValue={onChange ? undefined : value}
      value={onChange ? value : undefined}
      disabled={!field.enabled}
      label={label}
      readOnly={field.readonly}
      onChange={(e) => onchange(e, rest['onChangeArg'])}
      data-cy={field.name}
      id={`${namespace}_${field.name}`}
      name={`${namespace}[${field.name}]`}
      changeIndicated={field.changeIndicated}
      errors={field.errors}
      required={field.required}
      placeholder={field.placeholder}
      append={field.append}
      {...rest}
    />
  );
};
