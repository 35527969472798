import classNames from 'classnames';
import SpaceBetween from 'components/elements/SpaceBetween';
import React, { useMemo, useState } from 'react';
import { ArrayField, useFormContext } from 'react-hook-form';
import SelectedWSRResourcesRow from 'samples/create/components/warehouse_sample_request/SelectedWSRResourcesRow';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import Tile, { TileDisclosure, TileHeader } from 'shared/Tile';
import Text from 'shared/TextWithSub/components/Text';
import WSRSampleWeightInput from './WSRSampleWeightInput';
import SpotSampleSelectedRow from '../spot_sample/SpotSampleSelectedRow';

interface ISelectedWSRResourcesProps {
  fields: Partial<ArrayField<Record<string, any>, 'fieldArrayId'>>[];
  onDeselect: (index: number) => void;
  resourceTitle: string;
  resourceIdName: string;
  fieldArrayName: string;
  officeWeightInputInTable: boolean;
  officeWeightInputInHeader: boolean;
  officeSampleIdentifier?: string;
}

const SelectedWSRResources = ({
  fields,
  onDeselect,
  resourceTitle,
  resourceIdName,
  fieldArrayName,
  officeWeightInputInTable,
  officeWeightInputInHeader,
  officeSampleIdentifier,
}: ISelectedWSRResourcesProps): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false);

  const { watch, formState } = useFormContext();
  const { isValid, isSubmitting } = formState;
  const fieldArrayData = watch(fieldArrayName);

  //TODO add type for data
  const totalWeight = useMemo(
    () =>
      fieldArrayData?.reduce((acc: number, data) => {
        return (
          acc +
          parseFloat(data.customer_sample_weight?.['value'] || 0) +
          parseFloat(data.office_sample_weight?.['value'] || 0)
        );
      }, 0),

    [fieldArrayData]
  );

  return (
    <Tile collapsibleIndicator={fields.length > 0 && collapsed}>
      <TileHeader>
        <TileDisclosure
          disabled={!fields.length}
          handleCollapsible={() => setCollapsed(!collapsed)}
          collapsed={collapsed}
        />
        <SpaceBetween alignCenter={true}>
          <h4>Selected Samples</h4>
          {!officeWeightInputInTable &&
            (officeWeightInputInHeader ? (
              <WSRSampleWeightInput
                name={'warehouse_sample_request.office_sample_weight'}
                description={I18n.translate('activerecord.models.office')}
                dataCy={'office_sample_weight'}
              />
            ) : (
              <Text
                text={I18n.translate('samples.create.existing_pss', {
                  identifier: officeSampleIdentifier,
                })} // TODO add proper spanish translation
              />
            ))}
          <TextWithSub
            text={`${totalWeight} g`}
            sub={`${fields.length} ${resourceTitle}`}
          />
          <button
            className='button'
            data-cy='button_create_and_print'
            disabled={isSubmitting || !isValid}
          >
            {I18n.translate('buttons.create')}
          </button>
        </SpaceBetween>
      </TileHeader>
      {fields.length > 0 && (
        <table
          className={classNames('table', 'table--inverted-stripes', {
            hidden: collapsed,
          })}
        >
          <tbody>
            {fields.map((field, index) => {
              if (field.type == 'spot_contract') {
                return (
                  <SpotSampleSelectedRow
                    key={field.fieldArrayId}
                    field={field}
                    index={index}
                    onUncheck={onDeselect}
                    resourceIdName={resourceIdName}
                    fieldArrayName={fieldArrayName}
                  />
                );
              } else {
                return (
                  <SelectedWSRResourcesRow
                    key={field.fieldArrayId}
                    field={field}
                    index={index}
                    onUncheck={onDeselect}
                    fieldArrayName={fieldArrayName}
                    resourceIdName={resourceIdName}
                    showOfficeWeightInput={officeWeightInputInTable}
                  />
                );
              }
            })}
          </tbody>
        </table>
      )}
    </Tile>
  );
};
export default SelectedWSRResources;
