import { Provider } from 'mobx-react';
import ExcelsoPhysicalAnalysisStore from 'qaPhysicalAnalyses/stores/ExcelsoPhysicalAnalysisStore';
import React from 'react';
import OverlayStore from 'stores/OverlayStore';
import NormType from 'types/model/norm';
import { ExcelsoPhysicalAnalysisType } from 'types/model/qaPhysicalAnalysis';
import { ReasonGet } from 'types/model/reason';
import SpecialtyGradingSpecificationType from 'types/model/specialtyGradingSpecification';
import ExcelsoPhysicalAnalysisForm from '../components/ExcelsoPhysicalAnalysisForm';
import { JsonApi } from 'types/utils/jsonApi';
import ISample from 'millingOrders/types/ISample';

export interface ExcelsoPhysicalAnalysisContainerProps {
  sample: JsonApi<ISample>;
  physicalAnalysis: ExcelsoPhysicalAnalysisType;
  norm: JsonApi<NormType>;
  reasons: ReasonGet[];
  specialtyGradingSpecifications: SpecialtyGradingSpecificationType[];
  cancelUrl: string;
}

function ExcelsoPhysicalAnalysisContainer({
  sample,
  norm,
  reasons,
  physicalAnalysis,
  specialtyGradingSpecifications,
  cancelUrl = '',
}: ExcelsoPhysicalAnalysisContainerProps): JSX.Element {
  return (
    <Provider
      excelsoPhysicalAnalysisStore={
        new ExcelsoPhysicalAnalysisStore(
          sample,
          norm,
          physicalAnalysis,
          specialtyGradingSpecifications,
          reasons,
          cancelUrl
        )
      }
      overlayStore={new OverlayStore()}
    >
      <ExcelsoPhysicalAnalysisForm />
    </Provider>
  );
}

export default ExcelsoPhysicalAnalysisContainer;
