import React from 'react';
import Taster from './Taster';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.button`
  margin-left: auto;
`;

const CuppingTaster = (): JSX.Element => {
  const { cuppingTableStore } = useStores();
  return (
    <StyledWrapper>
      <StyledButton
        onClick={() => cuppingTableStore.addCuppingCustomer()}
        type='button'
        className='button'
      >
        + Add Taster
      </StyledButton>
      {cuppingTableStore.allCuppingCustomers.map((cuppingCustomer) => (
        <Taster cuppingCustomer={cuppingCustomer} key={cuppingCustomer.identifier} />
      ))}
    </StyledWrapper>
  );
};

export default observer(CuppingTaster);
