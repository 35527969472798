import styled from 'styled-components';

interface SpaceBetweenProps {
  alignCenter?: boolean;
  noWrap?: boolean;
}

const SpaceBetween = styled.div<SpaceBetweenProps>`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: ${(props) => (props.noWrap ? 'nowrap' : 'wrap')};
  align-items: ${(props) => (props.alignCenter ? 'center' : 'flex-start')};
`;

export default SpaceBetween;
