export const equalityFilter = (value?: number | string, filterValue?: string) =>
  !filterValue || value === filterValue;

export const likeFilter = (value?: number | string, filterValue?: string) => {
  if (!filterValue) {
    return true;
  }

  if (!value && filterValue) {
    return false;
  }

  const parsedValue = String(value);

  return parsedValue.toLowerCase().includes(filterValue.toLowerCase());
};
