import { deserialise } from 'kitsu-core';
import { action, computed, observable } from 'mobx';

import type { AvailableCoffeItem as AvailableCoffeeItemType } from 'types/model/lot';
import SelectOptionArray from 'types/model/selectOption';
import AvailableCoffeItem from 'availableCoffee/models/AvailableCoffeItem';
import type AvailableMillingOutputType from 'availableCoffee/types/AvailableMillingOutput';
import { JsonApi } from 'types/utils/jsonApi';
import AvailableMillingOutput from 'availableCoffee/models/AvailableMillingOutput';
import { ProductionOrderType } from 'types/model/productionOrder';

class AvailableCoffeeStore {
  public items = observable.array<AvailableCoffeItem | AvailableMillingOutput>();
  public productionOrderType: ProductionOrderType;
  private exportSize: number;
  public wantedWeight: number;
  public withTable: boolean;
  public productionOrderId?: number;
  public warehouses: SelectOptionArray;
  public qualities: SelectOptionArray;
  public varieties: SelectOptionArray;
  public processTypes: SelectOptionArray;
  public icoIdentifier: string;

  constructor(
    productionOrderType: ProductionOrderType,
    lots: JsonApi<AvailableCoffeeItemType> | undefined = undefined,
    lotSets: JsonApi<AvailableCoffeeItemType> | undefined = undefined,
    millingOutputs: JsonApi<AvailableMillingOutputType> | undefined = undefined,
    reservedItems: Array<{ local_identifier: string; quantity: number }> = [],
    selectedItems: Array<{ local_identifier: string; quantity: number }> = [],
    exportSize: number,
    wantedWeight: number,
    withTable = false,
    productionOrderId: number | undefined,
    warehouses: SelectOptionArray,
    qualities: SelectOptionArray,
    varieties: SelectOptionArray,
    processTypes: SelectOptionArray,
    icoIdentifier = ''
  ) {
    this.productionOrderType = productionOrderType;

    let selectableItems: Array<AvailableCoffeItem | AvailableMillingOutput> = [];

    if (productionOrderType === 'vanilla') {
      const deserialisedLots = deserialise(lots).data as AvailableCoffeeItemType[];

      if (deserialisedLots) {
        selectableItems = deserialisedLots.map((lot) => new AvailableCoffeItem(lot));
      }

      const deserialisedLotSets = deserialise(lotSets).data as AvailableCoffeeItemType[];

      if (deserialisedLotSets) {
        const newSets = deserialisedLotSets.map((set) => new AvailableCoffeItem(set));
        selectableItems = [...selectableItems, ...newSets];
      }
    } else if (productionOrderType === 'caracol') {
      const deserializedMillingOutputs = deserialise(millingOutputs)
        .data as AvailableMillingOutputType[];

      if (deserializedMillingOutputs) {
        selectableItems = deserializedMillingOutputs.map(
          (millingOutput) => new AvailableMillingOutput(millingOutput)
        );
      }
    }

    if (selectedItems) {
      selectableItems.forEach((item: AvailableCoffeItem | AvailableMillingOutput) =>
        selectedItems.forEach((selectedItem) => {
          if (selectedItem.local_identifier === item.attributes.local_identifier) {
            item.setSelected(true);
            item.setQuantity(selectedItem.quantity.toString());
          }
        })
      );
    }

    if (reservedItems) {
      selectableItems.forEach((item: AvailableCoffeItem | AvailableMillingOutput) =>
        reservedItems.forEach((reservedItem) => {
          if (reservedItem.local_identifier === item.attributes.local_identifier) {
            item.setReserved(true);
          }
        })
      );
    }

    this.exportSize = exportSize || 69;
    this.wantedWeight = wantedWeight;
    this.withTable = withTable;
    this.warehouses = warehouses;
    this.qualities = qualities;
    this.varieties = varieties;
    this.processTypes = processTypes;
    this.productionOrderId = productionOrderId;
    this.icoIdentifier = icoIdentifier;
    this.items = observable.array(selectableItems);
  }

  @action public toggleItemSelection = (
    item: AvailableCoffeItem | AvailableMillingOutput
  ) => {
    item.setSelected(!item.selected);
  };

  @computed get selectedSources(): Array<AvailableCoffeItem | AvailableMillingOutput> {
    return observable.array(this.items.filter((item) => item.selected));
  }

  @computed get totalWeight(): number {
    return this.selectedSources.reduce((totalWeight, item) => {
      return parseFloat((totalWeight + parseFloat(item.quantity)).toFixed(2));
    }, 0);
  }

  @computed get totalBags(): number {
    return Math.ceil(this.totalWeight / this.exportSize);
  }

  @computed get alternativeTotalBags(): number {
    return Math.round(this.totalWeight / this.secondaryAverageFactor);
  }

  @computed get averageFactor(): number {
    const totalFactor = this.selectedSources.reduce((averageFactor, item) => {
      if (item instanceof AvailableCoffeItem) {
        return (
          averageFactor + parseFloat(item.quantity) * item.attributes.purchase_factor
        );
      } else {
        return averageFactor;
      }
    }, 0);

    return parseFloat((totalFactor / this.totalWeight).toFixed(2)) || 0;
  }

  @computed get averageFactorInfo(): string {
    const item = this.selectedSources[0];
    if (item instanceof AvailableCoffeItem) {
      return item.attributes.factor_info;
    } else {
      return '';
    }
  }

  @computed get millingFactorTitle(): string {
    const item = this.items[0];
    if (item instanceof AvailableCoffeItem) {
      if (item.attributes.secondary_factor_info === 'factor 14') {
        return I18n.translate('available_coffee.other_factor');
      } else {
        return I18n.translate('available_coffee.milling_factor');
      }
    } else {
      return '';
    }
  }

  @computed get secondaryAverageFactor(): number {
    const totalFactor = this.selectedSources.reduce((averageFactor, item) => {
      if (item instanceof AvailableCoffeItem) {
        return averageFactor + item.attributes.secondary_factor;
      } else {
        return averageFactor;
      }
    }, 0);

    return parseFloat((totalFactor / this.selectedSources.length).toFixed(2)) || 0;
  }

  @computed get secondaryAverageFactorInfo(): string {
    const item = this.selectedSources[0];
    if (item instanceof AvailableCoffeItem) {
      return item.attributes.secondary_factor_info;
    } else {
      return '';
    }
  }

  @computed get estimatedGreen(): number {
    let estimatedGreen = 0;

    if (this.productionOrderType === 'vanilla') {
      estimatedGreen = parseFloat(
        ((this.totalWeight / this.averageFactor) * this.exportSize).toFixed(2)
      );
    } else if (this.productionOrderType === 'caracol') {
      estimatedGreen = parseFloat(this.totalEstimatedCaracol.toFixed(2));
    }

    return estimatedGreen;
  }

  @computed get alternativeEstimatedGreen(): number {
    let estimatedGreen = 0;

    if (this.productionOrderType === 'vanilla') {
      estimatedGreen = parseFloat(
        ((this.totalWeight / this.secondaryAverageFactor) * this.exportSize).toFixed(2)
      );
    } else if (this.productionOrderType === 'caracol') {
      estimatedGreen = parseFloat(this.totalEstimatedCaracol.toFixed(2));
    }

    return estimatedGreen;
  }

  @computed get totalEstimatedCaracol(): number {
    return this.selectedSources.reduce((total, millingOutput) => {
      if (millingOutput instanceof AvailableMillingOutput) {
        const {
          attributes: { recoverable_caracol_percentage },
          quantity,
        } = millingOutput;

        return (total += (recoverable_caracol_percentage * parseInt(quantity)) / 100);
      } else {
        return total;
      }
    }, 0);
  }

  @computed get submitBody(): any {
    const data = {
      assigned_coffee: Array<{
        lot_id?: number;
        lot_set_id?: number;
        quantity: number | string;
      }>(),
    };
    this.selectedSources.forEach((source) => {
      const assignedCoffee = {
        quantity: source.quantity,
      };

      if (source instanceof AvailableCoffeItem) {
        assignedCoffee['lot_id'] = source.attributes.lot_id;
        assignedCoffee['lot_set_id'] = source.attributes.lot_set_id;
      } else if (source instanceof AvailableMillingOutput) {
        assignedCoffee['milling_output_id'] = source.sourceId;
      }

      data.assigned_coffee.push(assignedCoffee);
    });

    return data;
  }

  @computed get estimatedGreenTitle(): string {
    if (this.averageFactorInfo === 'factor 15') {
      return `${I18n.translate('available_coffee.estimate_factor_15')}`;
    } else {
      return `${I18n.translate('available_coffee.estimate_factor_14')}`;
    }
  }

  @computed get alternativeEstimatedGreenTitle(): string {
    if (this.averageFactorInfo === 'factor 15') {
      return `${I18n.translate('available_coffee.estimate_factor_14')}`;
    } else {
      return `${I18n.translate('available_coffee.estimate_factor_15')}`;
    }
  }

  @computed get bagsTitle(): string {
    if (this.averageFactorInfo === 'factor 15') {
      return `${I18n.translate('available_coffee.bags_factor_15')}`;
    } else {
      return `${I18n.translate('available_coffee.bags_factor_14')}`;
    }
  }

  @computed get alternativeBagsTitle(): string {
    if (this.averageFactorInfo === 'factor 15') {
      return `${I18n.translate('available_coffee.bags_factor_14')}`;
    } else {
      return `${I18n.translate('available_coffee.bags_factor_15')}`;
    }
  }
}

export default AvailableCoffeeStore;
