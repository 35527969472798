enum Incoterm {
  'FOB' = 'FOB',
  'EXW' = 'EXW',
  'CIF' = 'CIF',
  'CNF' = 'CNF',
  'DLD' = 'DLD',
  'FOT' = 'FOT',
  'FCA' = 'FCA',
  'DAP' = 'DAP',
  'DDP' = 'DDP',
}

export default Incoterm;
