import React, { useState, useEffect } from 'react';
import SelectOptionArray from 'types/model/selectOption';
import BaseGroupComponent from 'components/groups/BaseGroup';
import SelectGroup from 'components/groups/SelectGroup';

interface DestinationSelectionProps extends React.HTMLAttributes<HTMLDivElement> {
  finalDestinations: {
    mill: SelectOptionArray;
    decaf_plant: SelectOptionArray;
  };
}

function DestinationSelection({ finalDestinations }: DestinationSelectionProps) {
  const [finalDestination, setFinalDestination] = useState<undefined | string>(undefined);
  const [showOriginDestination, setShowOriginDestination] = useState(false);

  useEffect(() => {
    if (finalDestination?.match(/mill-/)) {
      setShowOriginDestination(true);
    } else {
      setShowOriginDestination(false);
    }
  }, [finalDestination]);

  return (
    <div className='l-auto-fill-grid'>
      <BaseGroupComponent label={I18n.translate('transports.form.final_destination')}>
        <select
          onChange={(e) => setFinalDestination(e.target.value)}
          value={finalDestination}
          name='transport[final_destination]'
        >
          {Object.entries(finalDestinations).map(([type, destinations]) => {
            return (
              <optgroup key={type} label={I18n.translate(`activerecord.models.${type}`)}>
                {destinations.map(({ name, value }) => (
                  <option key={value || name} value={value || name}>
                    {name}
                  </option>
                ))}
              </optgroup>
            );
          })}
        </select>
      </BaseGroupComponent>

      {showOriginDestination && (
        <SelectGroup
          label={I18n.translate('activerecord.models.decaf_plant')}
          options={finalDestinations.decaf_plant}
          name='transport[origin]'
        />
      )}
    </div>
  );
}

export default DestinationSelection;
