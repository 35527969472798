import styled from 'styled-components';
import COLORS from 'constants/colors';

interface CardWrapperProps {
  highlighted?: boolean;
}

export const CardWrapper = styled.div<CardWrapperProps>`
  position: relative;
  width: 100%;
  width: 26rem;
  border: 1px solid ${COLORS.BLUE};
  padding: 0.875rem 0.75rem 1.5rem 0.75rem;
  background: ${COLORS.WHITE};
  box-shadow: ${(props) => (props.highlighted ? '0px 0px 10px #006F94' : 'none')};
`;

export default CardWrapper;
