import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import COLORS from 'constants/colors';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import useStores from 'utils/useStores';

import debounce from 'lodash.debounce';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

const TotalWeight = styled.span<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? undefined : COLORS.RED)};
`;

const FormWrapper = styled.div`
  flex-basis: 61.8%;
`;

const TypeWrapper = styled.div`
  width: 40%;
`;

const WeightWrapper = styled.div`
  text-align: center;
  width: 20%;
`;

const FilterForm: React.FC = () => {
  const { consolidateSamplesStore } = useStores();
  const { paginatedTableStore, qualities, searchString, selectedQuality } =
    consolidateSamplesStore;
  const { dataUrl } = paginatedTableStore;

  const handleOnSubmit = (search, quality) => {
    const urlParams = {
      local_identifier: search,
      quality: quality,
    };
    const params = paginatedTableStore.urlStringToPaginateParams(
      window.location.href.split('?')[1]
    );
    paginatedTableStore.loadPage(params['page'], urlParams);
  };

  const filterValueChanged = useMemo(() => debounce(handleOnSubmit, 500), []);

  useMemo(() => {
    filterValueChanged(searchString, selectedQuality);
  }, [searchString, selectedQuality]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    consolidateSamplesStore.setSearchString(e.currentTarget.value);
  };

  const handleQualityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    consolidateSamplesStore.setSelectedQuality(e.currentTarget.value);
  };

  return (
    <FormWrapper>
      <form
        action={dataUrl()}
        acceptCharset='UTF-8'
        method='GET'
        onSubmit={(event) => event.preventDefault()}
      >
        <div className='l-item-stack-horizontal l-item-stack-horizontal--wide l-item-stack-horizontal--space-between'>
          <AuthenticityToken />

          <TypeWrapper>
            <InputGroupComponent
              type='text'
              placeholder={I18n.translate('samples.consolidate.filter')}
              name='local_identifier'
              onChange={handleSearchChange}
              value={searchString}
            />
          </TypeWrapper>

          <TypeWrapper>
            <SelectGroup
              onChange={handleQualityChange}
              name='quality'
              value={consolidateSamplesStore.selectedQuality}
              placeholder={I18n.translate('samples.consolidate.select_quality')}
              options={qualities}
            />
          </TypeWrapper>
          <WeightWrapper>
            <TotalWeight
              className='bold align-right'
              isValid={consolidateSamplesStore.hasValidTotalWeight}
            >
              {`${consolidateSamplesStore.totalWeight} g`}
            </TotalWeight>
          </WeightWrapper>
        </div>
      </form>
    </FormWrapper>
  );
};

export default observer(FilterForm);
