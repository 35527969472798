import ContactInfoType from 'types/model/contactInfo';
import { observable, action } from 'mobx';

class ContactInfo {
  @observable public firstName?: string;
  @observable public middleName?: string;
  @observable public surname?: string;
  @observable public secondSurname?: string;
  @observable public mobileNumber?: string;
  @observable public phoneNumber?: string;
  @observable public emailAddress?: string;
  @observable public contactTitle?: string;
  public id?: number;

  constructor(contact_info: ContactInfoType) {
    this.firstName = contact_info.first_name;
    this.middleName = contact_info.middle_name;
    this.surname = contact_info.surname;
    this.secondSurname = contact_info.second_surname;
    this.mobileNumber = contact_info.mobile_number;
    this.phoneNumber = contact_info.phone_number;
    this.emailAddress = contact_info.email_address;
    this.contactTitle = contact_info.contact_title;
    this.id = contact_info.id;
  }

  @action setFirstName = (firstName: string) => {
    this.firstName = firstName;
  };

  @action setMiddleName = (middleName: string) => {
    this.middleName = middleName;
  };

  @action setSurname = (surname: string) => {
    this.surname = surname;
  };

  @action setSecondSurname = (secondSurname: string) => {
    this.secondSurname = secondSurname;
  };

  @action setMobileNumber = (mobileNumber: string) => {
    this.mobileNumber = mobileNumber;
  };

  @action setPhoneNumber = (phoneNumber: string) => {
    this.phoneNumber = phoneNumber;
  };

  @action setEmailAddress = (emailAddress: string) => {
    this.emailAddress = emailAddress;
  };

  @action setContactTitle = (contactTitle: string) => {
    this.contactTitle = contactTitle;
  };
}
export default ContactInfo;
