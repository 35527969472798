import FilterBarWrapper from 'components/elements/FilterBar';
import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import useStores from 'utils/useStores';
import debounce from 'lodash.debounce';

const FilterBar: React.FC = () => {
  const { asyncProductionOrdersStore } = useStores();

  const {
    states,
    years,
    customerIdString,
    selectedState,
    selectedYear,
    paginatedTableStore,
  } = asyncProductionOrdersStore;

  const handleOnSubmit = (customerId, state, year) => {
    const urlParams = {
      state: state,
      year: year,
      customer_id: customerId,
    };
    const params = paginatedTableStore.urlStringToPaginateParams(
      window.location.href.split('?')[1]
    );
    paginatedTableStore.loadPage(params['page'], urlParams);
  };

  const filterValueChanged = useMemo(() => debounce(handleOnSubmit, 500), []);

  useMemo(() => {
    filterValueChanged(customerIdString, selectedState, selectedYear);
  }, [customerIdString, selectedState, selectedYear]);

  return (
    <FilterBarWrapper columns='15em 12em 10.5em'>
      <InputGroupComponent
        name='identifier'
        type='text'
        placeholder={I18n.translate('milling.production_orders.search_placeholder')}
        onChange={(event) =>
          asyncProductionOrdersStore.setCustomerIdString(event?.currentTarget.value)
        }
        value={customerIdString}
      />
      <SelectGroup
        name='state'
        onChange={(event) =>
          asyncProductionOrdersStore.setSelectedState(event?.currentTarget.value)
        }
        options={states}
        placeholder={I18n.translate('milling.production_orders.select_a_state')}
        value={selectedState}
      />

      <SelectGroup
        name='year'
        onChange={(event) =>
          asyncProductionOrdersStore.setSelectedYear(event?.currentTarget.value)
        }
        options={years}
        placeholder={I18n.translate('milling.production_orders.select_a_year')}
        value={selectedYear}
      />
    </FilterBarWrapper>
  );
};

export default observer(FilterBar);
