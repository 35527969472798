import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';

import Grid from 'components/elements/Grid';
import InputGroup from 'components/groups/InputGroup';
import FormFieldSet from 'exports/components/FormFieldSet';
import PackingListModel from '../../models/PackingList';
import { useForm } from 'react-hook-form';
import AccordionFormBody from '../AccordionFormBody';
import { observer } from 'mobx-react';

export interface PackingListProps {
  packingList: PackingListModel;
  shippingAdviceContainerNumber?: string;
}

const PackingListWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

const PackingList = ({
  packingList,
  shippingAdviceContainerNumber,
}: PackingListProps) => {
  const { register, reset, handleSubmit, formState } = useForm({
    defaultValues: packingList,
  });

  const onFinalizeClick = async () => {
    await packingList.finalize();
  };

  const onSaveClick = handleSubmit(async (formData: any) => {
    await packingList.save(formData);

    reset(formData);
  });

  return (
    <PackingListWrapper>
      <AccordionFormBody
        isFinalizable={
          packingList.isFinalizable &&
          shippingAdviceContainerNumber != '' &&
          shippingAdviceContainerNumber != null
        }
        isDirty={formState.isDirty}
        isSubmitting={formState.isSubmitting}
        finalized={packingList.finalized}
        onSubmitClick={onSaveClick}
        onFinalizeClick={onFinalizeClick}
        downloadUrl={
          shippingAdviceContainerNumber == '' || shippingAdviceContainerNumber == null
            ? ''
            : packingList.downloadUrl
        }
      >
        <FormFieldSet disabled={packingList.finalized}>
          <Grid grid={16}>
            <InputGroup
              id='seal_number'
              name='seal_number'
              label={I18n.translate('exports.form.packing_list.seal_number_label')}
              placeholder={I18n.translate(
                'exports.form.packing_list.seal_number_placeholder'
              )}
              ref={register}
            />

            <InputGroup
              id='container_number'
              name='container_number'
              label={I18n.translate(
                'exports.form.shipping_advice.container_number_label'
              )}
              disabled={true}
              value={shippingAdviceContainerNumber}
            />
          </Grid>
        </FormFieldSet>
      </AccordionFormBody>
    </PackingListWrapper>
  );
};

export default observer(PackingList);
