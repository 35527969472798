import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Routes from 'routes';
import COLORS from 'constants/colors';

import useStores from 'utils/useStores';

import Icon from 'components/elements/Icon';
import Input from 'components/elements/Input';
import Select from 'components/elements/Select';

const FilterWrapper = styled.div`
  background: ${COLORS.WHITE};
`;

const FilterRow = styled.form`
  align-items: center;
  display: flex;
  margin: 0 2.5rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${COLORS.BORDERS};
`;

const SearchWrapper = styled.div`
  width: 15rem;
`;

const Filter = () => {
  const { exportsStore } = useStores();
  const { filters } = exportsStore;

  return (
    <FilterWrapper>
      <FilterRow method='GET' action={Routes.exports_path()}>
        <button
          type='button'
          onClick={() => exportsStore.setShowHarbourOverlay(true)}
          className='button button--with-blue-border exports__harbor-button'
        >
          <Icon icon='harbor' color='#006F94' />
        </button>

        {exportsStore.originCountries.length > 1 && (
          <Select
            data-cy='export-select-country'
            defaultValue={filters.originCountry}
            options={exportsStore.originCountries}
            placeholder={I18n.translate('exports.select_an_origin_country')}
            className='exports__select-year'
            name='filters[origin_country]'
          />
        )}

        <Select
          data-cy='samples_export_select_year'
          defaultValue={filters.year}
          options={exportsStore.years}
          placeholder={I18n.translate('milling.production_orders.select_a_year')}
          className='exports__select-year'
          name='filters[year]'
        />

        <SearchWrapper>
          <Input
            placeholder={I18n.translate('exports.find_contract_or_ico')}
            defaultValue={filters.contractExportIco}
            name='filters[contract_export_ico]'
          />
        </SearchWrapper>

        <button className='button exports__search-button' type='submit'>
          {I18n.translate('buttons.search')}
        </button>
      </FilterRow>
    </FilterWrapper>
  );
};

export default observer(Filter);
