import React from 'react';

import { DeserializedGrade } from 'types/model/grade';

import SelectGroup from 'components/groups/SelectGroup';
import Overlay from 'shared/Overlay';

export interface AdjustGradeOverlayProps {
  grades: DeserializedGrade[];
  currentGrade: number;
  onPrimaryClick?: (event: React.MouseEvent, values: any) => void;
  onSecondaryClick?: (event: React.MouseEvent) => void;
  onChange?: (event: React.ChangeEvent) => void;
  title: string;
}

function AdjustGradeOverlay({
  onPrimaryClick,
  onSecondaryClick,
  grades,
  currentGrade,
  ...props
}: AdjustGradeOverlayProps): JSX.Element {
  const [lotGrade, setLotGrade] = React.useState(currentGrade);

  const handleOnPrimaryClick = (event: React.MouseEvent): void => {
    if (onPrimaryClick) {
      onPrimaryClick(event, {
        grade: lotGrade,
      });
    }
  };

  const handleOnSecondaryClick = (event: React.MouseEvent): void => {
    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
  };

  const handleOnSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setLotGrade(parseInt(event.target.value, 10));
  };

  const transformedGrades = grades.map(({ id, grade, quality_code }) => ({
    id,
    name: quality_code,
    value: grade.toString(),
  }));

  return (
    <Overlay
      onPrimaryClick={handleOnPrimaryClick}
      primaryText={I18n.translate('buttons.save')}
      onSecondaryClick={handleOnSecondaryClick}
      secondaryText={I18n.translate('buttons.close')}
      {...props}
    >
      <SelectGroup
        label={I18n.translate('attributes.grade')}
        onChange={handleOnSelectChange}
        options={transformedGrades}
        value={lotGrade}
      />
    </Overlay>
  );
}

export default AdjustGradeOverlay;
