import styled from 'styled-components';
import COLORS from 'constants/colors';

interface TableCellProps {
  darkBackground?: boolean;
}

const TableCell = styled.div<TableCellProps>`
  padding: 0.875rem;
  background: ${(props) =>
    props.darkBackground ? COLORS.MEDIUM_LIGHT_GRAY : COLORS.WHITE};
`;

export default TableCell;
