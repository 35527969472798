import React from 'react';
import Routes from 'routes';

import { customFetch } from 'utils/fetch';

import { NewTestCard } from 'shared/LotInfoBox';
import { RoastingOverlay } from 'shared/Roasting';
import { TestResult } from 'shared/TestGrid';
import IRoastingResult from '../../types/IRoastingResult';
import ISample from '../../types/ISample';
import useStores from 'utils/useStores';

export interface RoastingsProps extends React.HTMLAttributes<HTMLDivElement> {
  roastings: IRoastingResult[];
  sample: ISample;
  enableNewButton: boolean;
}

function Roastings({ roastings, sample, enableNewButton }: RoastingsProps) {
  const { analyseAndAdjustStore } = useStores();
  const { roastingResults } = analyseAndAdjustStore;

  const [showOverlay, setShowOverlay] = React.useState(false);

  const roastingValidations = {
    pppt: sample.roasting_validations?.pppt || {
      min: 0,
      max: 100,
    },
    sampleWeight: sample.roasting_validations?.sampleWeight || { max: sample.weight },
  };

  const roastingSampleWeight = sample.roasting_sample_weight;

  const handleOnPrimaryClick = async (
    _event: React.MouseEvent,
    values: any
  ): Promise<void> => {
    await customFetch(Routes.api_v1_sample_roastings_path(sample.id), values);

    window.location.reload();
  };

  const handleOnSecondaryClick = (_event: React.MouseEvent) => {
    setShowOverlay(false);
  };

  const handleOnButtonClick = (_event: React.MouseEvent) => {
    if (!enableNewButton) {
      analyseAndAdjustStore.setEnableFlash(true);
      return;
    }
    return setShowOverlay(true);
  };

  const identifier = () => {
    if (sample.lot_id || sample.lot_set_id) {
      return sample.parent_frontend_identifier || sample.local_identifier;
    } else {
      return sample.frontend_identifier;
    }
  };

  return (
    <div>
      {roastings.map((roasting) => (
        <TestResult key={roasting.id} test={roasting} />
      ))}

      {showOverlay && (
        <RoastingOverlay
          identifier={identifier()}
          validations={roastingValidations}
          roastingSampleWeight={roastingSampleWeight}
          results={roastingResults}
          onPrimaryClick={handleOnPrimaryClick}
          onSecondaryClick={handleOnSecondaryClick}
        />
      )}

      {!sample.depleted && (
        <NewTestCard onClick={handleOnButtonClick}>
          <button
            className={`button button--small button--${
              enableNewButton ? 'green' : 'gray'
            }`}
          >
            + {I18n.translate('buttons.new')}
          </button>
        </NewTestCard>
      )}
    </div>
  );
}

export default Roastings;
