import styled from 'styled-components';
import COLORS from 'constants/colors';

const TableCorner = styled.div`
  z-index: 10;
  position: sticky;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0;
  left: 0;
  padding: 1.875rem 1.875rem 0 2.5rem;
  background: ${COLORS.WHITE};

  &:after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 50%;
    right: -1rem;
    bottom: 0;
    opacity: 0.1;
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 1) 7.88%,
        rgba(255, 255, 255, 0) 89.09%
      ),
      linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

export default TableCorner;
