import classNames from 'classnames';
import React from 'react';

import Badge, { BadgeProps } from 'components/shared/Badge';

export interface SensorialAnalysisBadge extends BadgeProps {
  kind?: string;
  label: string;
}

const SensorialAnalysisBadge: React.FC<SensorialAnalysisBadge> = ({
  className,
  kind,
  label,
  ...props
}) => {
  const badgeClassNames = classNames(className, {
    [`badge__body--${label}`]: kind !== 'overall',
    badge__overall: kind === 'overall',
  });

  return (
    <Badge {...props} className={badgeClassNames}>
      {label}
    </Badge>
  );
};

export default SensorialAnalysisBadge;
