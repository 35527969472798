import React from 'react';
import { ControlledTextInput } from 'components/shared/form/ControlledTextInput';
import { ControlledSelectGroup } from 'components/shared/form/ControlledSelectGroup';
import { ControlledTextAreaInput } from 'components/shared/form/ControlledTextAreaInput';
import { ControlledCheckbox } from 'components/shared/form/ControlledCheckbox';

export const OpportunityTextAreaInput = (props) => (
  <ControlledTextAreaInput namespace='opportunity' {...props} />
);
export const OpportunityTextInput = (props) => (
  <ControlledTextInput namespace='opportunity' {...props} />
);
export const OpportunitySelectGroup = (props) => (
  <ControlledSelectGroup namespace='opportunity' {...props} />
);
export const OpportunityCheckbox = (props) => (
  <ControlledCheckbox namespace='opportunity' {...props} />
);
