import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { belowUpperBound } from 'utils/validators';

import { VARIANT } from 'constants/inputTypes';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import TextareaGroupComponent from 'components/groups/TextareaGroup';
import { ReasonCheckbox } from 'shared/Checkbox';

import { Icons } from 'shared/Checkbox';

import useStores from 'utils/useStores';
import SelectOptionArray from 'types/model/selectOption';
import { ReasonGet } from 'types/model/reason';
import { Validation } from 'components/forms/lot/PhysicalAnalysisComponent';

interface WetPhysicalAnalysisProps {
  reasons: ReasonGet[];
  smells: SelectOptionArray;
  validations: {
    [key: string]: Validation;
  };
  hasPrepayment: boolean;
  estimatedGreen: number;
}

function capitalizeFirstLetter(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function WetPhysicalAnalysisComponent({
  reasons,
  validations,
  smells,
  hasPrepayment,
  estimatedGreen,
}: WetPhysicalAnalysisProps) {
  const { overlayStore, wetPhysicalAnalysisStore } = useStores();

  useEffect(() => {
    if (wetPhysicalAnalysisStore.acceptingReasonId) {
      overlayStore.setReasonId(wetPhysicalAnalysisStore.acceptingReasonId);
      overlayStore.setValid(false);
      overlayStore.setOverrideValidation(true);
    }
  }, [wetPhysicalAnalysisStore.acceptingReasonId]);

  useEffect(() => {
    if (overlayStore.isValid && overlayStore.overrideValidation) {
      overlayStore.setOverrideValidation(false);
    }
  }, [overlayStore.isValid]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setter: any) => {
    setter(e.target.value);
  };

  const belowUpperBoundValidator = (checkValue: string, compareValue: string) => {
    const checkValueLength = wetPhysicalAnalysisStore[checkValue].length;

    const validator =
      checkValueLength === 0 ||
      (checkValueLength >= 1 &&
        belowUpperBound(
          parseFloat(wetPhysicalAnalysisStore[checkValue]),
          parseFloat(validations[compareValue].max)
        ));

    overlayStore.setValid(validator);
    return validator;
  };

  const renderFieldPair = (field: string, readOnly = false) => {
    return (
      <React.Fragment key={field}>
        <InputGroupComponent
          label={I18n.translate(`wet_physical_analysis.${field}`)}
          append='#'
          name={`wet_physical_analysis[${field}_amount]`}
          variant={VARIANT.INTEGER}
          value={wetPhysicalAnalysisStore[`${field}Amount`] || ''}
          placeholder='0'
          validator={() => belowUpperBoundValidator(`${field}Amount`, `${field}Amount`)}
          onChange={(e) =>
            !readOnly
              ? handleInputChange(
                  e,
                  wetPhysicalAnalysisStore[`set${capitalizeFirstLetter(field)}Amount`]
                )
              : null
          }
          readOnly={readOnly}
        />
        <InputGroupComponent
          label={`${I18n.translate(`wet_physical_analysis.${field}`)} ${I18n.translate(
            'attributes.weight'
          )}`}
          name={`wet_physical_analysis[${field}_weight]`}
          append='gr'
          variant={VARIANT.DECIMAL}
          value={wetPhysicalAnalysisStore[`${field}Weight`] || ''}
          placeholder='0.00'
          validator={() =>
            belowUpperBoundValidator(`${field}Percentage`, `${field}Percentage`)
          }
          onChange={(e) =>
            !readOnly
              ? handleInputChange(
                  e,
                  wetPhysicalAnalysisStore[`set${capitalizeFirstLetter(field)}Weight`]
                )
              : null
          }
          info={
            wetPhysicalAnalysisStore[`${field}Percentage`]
              ? `${wetPhysicalAnalysisStore[`${field}Percentage`]}%`
              : '--'
          }
          readOnly={readOnly}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
        >
          <InputGroupComponent
            label={I18n.translate('wet_physical_analysis.sample_weight')}
            variant={VARIANT.DECIMAL}
            append='gr'
            value={wetPhysicalAnalysisStore.sampleWeight || ''}
            readOnly={true}
          />
          <InputGroupComponent
            name='wet_physical_analysis[humidity]'
            label={I18n.translate('wet_physical_analysis.humidity')}
            variant={VARIANT.DECIMAL}
            append='%'
            value={wetPhysicalAnalysisStore.humidity || ''}
            placeholder='0.00'
            onChange={(e) => handleInputChange(e, wetPhysicalAnalysisStore.setHumidity)}
            required={true}
          />
          <InputGroupComponent
            name='wet_physical_analysis[hours_of_fermentation]'
            label={I18n.translate('wet_physical_analysis.hours_of_fermentation')}
            variant={VARIANT.INTEGER}
            append='#'
            value={wetPhysicalAnalysisStore.hoursOfFermentation || ''}
            placeholder='0'
            onChange={(e) =>
              handleInputChange(e, wetPhysicalAnalysisStore.setHoursOfFermentation)
            }
            required={true}
          />
          <SelectGroup
            name='wet_physical_analysis[smell_id]'
            label={I18n.translate('wet_physical_analysis.smell')}
            options={smells}
            placeholder={I18n.translate('wet_physical_analysis.select_a_smell')}
            onChange={(e) => wetPhysicalAnalysisStore.setSmellId(e.target.value)}
            value={wetPhysicalAnalysisStore.smellId || ''}
            required={true}
          />
        </div>
      </div>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
        >
          {[
            'broca',
            'cherry',
            'ferment',
            'pulp',
            'fungus',
            'stained',
            'black',
            'mold',
          ].map((field) => {
            return renderFieldPair(field);
          })}
          {renderFieldPair('pasilla1', true)}

          {hasPrepayment && (
            <InputGroupComponent
              label='Estimated Green'
              append='kg'
              value={estimatedGreen}
              readOnly
            />
          )}
        </div>
      </div>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
        >
          {['broken', 'bitten', 'vano', 'green'].map((field) => {
            return renderFieldPair(field);
          })}
          {renderFieldPair('pasilla2', true)}
        </div>
      </div>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(18em, 1fr))' }}
        >
          <div className='input-group'>
            <TextareaGroupComponent
              placeholder='max. 200 characters'
              name='wet_physical_analysis[observations]'
              id='observations'
              label={I18n.translate('attributes.observations')}
              value={wetPhysicalAnalysisStore.observations || ''}
              onChange={(e) => wetPhysicalAnalysisStore.setObservations(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
        >
          <ReasonCheckbox
            reasons={reasons}
            name='wet_physical_analysis[reject_reason_id]'
            icon={Icons.cross}
            label={I18n.translate('attributes.rejected')}
            fieldName='wet_physical_analysis[accepting_reason_id]'
          />
        </div>
      </div>
    </>
  );
}

export default observer(WetPhysicalAnalysisComponent);
