import { Provider } from 'mobx-react';
import React from 'react';
import ExportsStore, { SelectOptions } from 'exports/stores/ExportsStore';
import Exports from 'exports/components/Exports';
import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';

import type IDestinationPort from '../types/IDestinationPort';
import { JsonApi } from 'types/utils/jsonApi';

export interface ExportsContainerProps {
  dates: string[];
  destinationPorts: JsonApi<IDestinationPort>;
  selectOptions: SelectOptions;
}

const ExportsContainer = ({
  dates,
  destinationPorts,
  selectOptions,
}: ExportsContainerProps) => (
  <AbilityContext.Provider value={Ability}>
    <Provider exportsStore={new ExportsStore(dates, destinationPorts, selectOptions)}>
      <Exports columnCount={dates.length + 1} />
    </Provider>
  </AbilityContext.Provider>
);

export default ExportsContainer;
