import styled from 'styled-components';
import COLORS from 'constants/colors';

const TableData = styled.td`
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: ${COLORS.BLACK};
`;

export default TableData;
