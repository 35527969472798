import React from 'react';

import { VARIANT } from 'constants/inputTypes';

import InputGroupComponent from 'components/groups/InputGroup';
import Overlay from 'shared/Overlay';

export interface AdjustWeightProps {
  currentWeight: number;
  title: string;
  onPrimaryClick?: (event: React.MouseEvent, values: any) => void;
  onSecondaryClick?: (event: React.MouseEvent) => void;
  onChange?: (event: React.ChangeEvent) => void;
}

const AdjustWeight: React.FC<AdjustWeightProps> = ({
  currentWeight,
  onPrimaryClick,
  onSecondaryClick,
  ...props
}) => {
  const [sampleWeight, setSampleWeight] = React.useState(currentWeight.toString());
  const [isValid, setIsValid] = React.useState(true);

  const handleOnPrimaryClick = (event: React.MouseEvent) => {
    if (onPrimaryClick) {
      onPrimaryClick(event, {
        weight: parseInt(sampleWeight, 10),
      });
    }
  };

  const handleOnSecondaryClick = (event: React.MouseEvent) => {
    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSampleWeight(event.target.value);
  };

  const weightValidator = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedWeight = parseInt(e.target.value, 10);

    const isValidValue =
      !isNaN(parsedWeight) && parsedWeight >= 0 && parsedWeight <= currentWeight;

    setIsValid(isValidValue);

    return isValidValue;
  };

  return (
    <Overlay
      onPrimaryClick={handleOnPrimaryClick}
      primaryText={I18n.translate('buttons.save')}
      onSecondaryClick={handleOnSecondaryClick}
      secondaryText={I18n.translate('buttons.close')}
      primaryDisabled={!isValid}
      {...props}
    >
      <InputGroupComponent
        variant={VARIANT.INTEGER}
        label={`${I18n.translate('attributes.net_weight')} (gr)`}
        value={sampleWeight}
        onChange={handleOnChange}
        validator={weightValidator}
        min={0}
        max={currentWeight}
      />
    </Overlay>
  );
};

export default AdjustWeight;
