import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';

import FieldSet from 'components/elements/FieldSet';
import Checkbox from 'shared/Checkbox';

const LegQuestionnaire: React.FC = () => {
  const { legStore } = useStores();

  return (
    <FieldSet>
      <h3>{I18n.translate('transports.leg_questionnaire.info')}</h3>
      <ul>
        {Array.from(Array(5), (_, i) => (
          <li key={i}>
            <Checkbox
              label={I18n.translate(`transports.leg_questionnaire.question_${i + 1}`)}
              onChange={(checked) => legStore.setQuestionnaireAnswer(checked, i)}
              checked={legStore.questionnaireAnswers[i]}
            />
          </li>
        ))}
      </ul>
    </FieldSet>
  );
};

export default observer(LegQuestionnaire);
