import React from 'react';

import Checkbox from 'shared/Checkbox';

interface SelectForCuppingComponentProps {
  id: number;
  checked: boolean;
  disabled: boolean;
}

const SelectForCuppingComponent = ({
  checked,
  disabled,
}: SelectForCuppingComponentProps) => {
  const handleChange = () => {
    // do nothing
  };

  return (
    <Checkbox
      icon='tick'
      inline={true}
      checked={checked}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default SelectForCuppingComponent;
