import React from 'react';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
import Sample from 'customerSamples/models/Sample';

export interface CustomerSampleValuesButtonProps {
  customerSample: Sample;
  className: string;
  children: string;
}

function CustomerSampleValuesButton({
  customerSample,
  className,
  children,
}: CustomerSampleValuesButtonProps): JSX.Element {
  const { customerSamplesDashboardStore } = useStores();

  const setSelectedSample = (): void => {
    customerSamplesDashboardStore?.setSelectedSample(customerSample);
  };

  return (
    <button type='button' className={className} onClick={setSelectedSample}>
      {children}
    </button>
  );
}

export default observer(CustomerSampleValuesButton);
