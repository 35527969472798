import BaseGroupComponent from 'components/groups/BaseGroup';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useProducerForm, {
  PRODUCER_MODES,
} from 'samples/create/components/useProducerForm';

function ProducerToggleButton() {
  const { toggleProducerMode, producerMode } = useProducerForm();
  const { register, setValue } = useFormContext();

  useEffect(() => setValue('producerMode', producerMode), [producerMode]);

  return (
    <BaseGroupComponent emptyLabel={true}>
      <div
        onClick={toggleProducerMode}
        className={`button button--block ${
          producerMode == PRODUCER_MODES.LEAD ? '' : 'button--green'
        }`}
      >
        {producerMode == PRODUCER_MODES.KNOWN &&
          I18n.translate('samples.create.new_producer_lead')}
        {producerMode == PRODUCER_MODES.LEAD &&
          I18n.translate('samples.create.regular_producer')}
        <input type='hidden' name='producerMode' ref={register} />
      </div>
    </BaseGroupComponent>
  );
}
export default ProducerToggleButton;
