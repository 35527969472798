import { action, computed, observable } from 'mobx';

import { WetLot } from 'types/model/lot';
import LotLocation from 'types/model/lot_location';

class WetLotModel {
  public attributes: WetLot;
  @observable latestHumidity: number;
  @observable latestLocation: LotLocation;

  constructor(lot: WetLot) {
    this.attributes = lot;
    this.latestHumidity = (lot.latest_drying?.humidity as number) || 0;
    this.latestLocation = lot.location;
  }

  @action public setLatestHumidity(humidity: number) {
    this.latestHumidity = humidity;
  }

  @action public setLatestLocation(location: LotLocation) {
    this.latestLocation = location;
  }

  @computed get canProceed() {
    let canProceed = true;

    if (this.attributes.humidity_bounds.lower) {
      canProceed = this.latestHumidity >= this.attributes.humidity_bounds.lower;
    }

    if (canProceed && this.attributes.humidity_bounds.upper) {
      canProceed = this.latestHumidity <= this.attributes.humidity_bounds.upper;
    }

    canProceed = canProceed && this.hasLocation && this.attributes.has_physical;

    return canProceed;
  }

  @computed get hasLocation() {
    return (
      !!this.latestLocation.area &&
      !!this.latestLocation.bin &&
      !!this.latestLocation.level &&
      !!this.latestLocation.position
    );
  }
}

export default WetLotModel;
