import styled from 'styled-components';

interface FilterBarProps {
  columns?: string;
}

const FilterBar = styled.div<FilterBarProps>`
  display: grid;
  grid-template-columns: ${(props) => (props.columns ? props.columns : null)};
  grid-auto-flow: ${(props) => (props.columns ? null : 'column')};
  grid-column-gap: 1.25em;
  margin-bottom: 0.625em;
  align-items: center;
`;

export default FilterBar;
