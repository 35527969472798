import styled from 'styled-components';

export const Details = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, max(1.375em));
  grid-row-gap: 0.75em;
  max-height: 5em;
`;

export const TraceabilityDetails = styled(Details)`
  grid-template-areas: 'action identity grade physics';
  grid-template-columns: 2.5em 8.125em minmax(3em, 1fr) minmax(max-content, 6em);
  grid-column-gap: 0.9375em;
  position: relative;
`;

export default Details;
