import { action, computed, observable } from 'mobx';

import ITransportSample from 'samples/types/ITransportSample';
import ReceptionOption from './ReceptionOption';

class SampleModel {
  public attributes: ITransportSample;
  @observable public receptionOption?: ReceptionOption;

  constructor(sample: ITransportSample) {
    this.attributes = sample;
  }

  @action addReceptionOption = (receptionOpion: ReceptionOption): void => {
    this.receptionOption = receptionOpion;
  };

  @action removeReceptionOption = (): void => {
    this.receptionOption = undefined;
  };

  @computed get isUnloaded() {
    return !!this.receptionOption;
  }
}

export default SampleModel;
