import { Provider } from 'mobx-react';
import React from 'react';

import OverviewTableStore from '../stores/OverviewTableStore';
import OverlayStore from 'stores/OverlayStore';
import OverviewTable from '../components/OverviewTable';
import ISample from '../types/ISample';
import SelectOptionArray from 'types/model/selectOption';
import { JsonApi } from 'types/utils/jsonApi';

interface IndexContainerProps {
  samples: JsonApi<ISample>;
  acceptingReasons: SelectOptionArray;
  roastingResults: SelectOptionArray;
  roastingSampleWeight: number;
}

function IndexContainer({
  samples,
  acceptingReasons,
  roastingResults,
  ...props
}: IndexContainerProps): JSX.Element {
  return (
    <Provider
      overlayStore={new OverlayStore({})}
      overviewTableStore={
        new OverviewTableStore(samples, { acceptingReasons, roastingResults })
      }
    >
      <OverviewTable {...props} />
    </Provider>
  );
}

export default IndexContainer;
