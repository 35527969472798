import React, { ChangeEvent } from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import ApprovalTermSelect from '../ApprovalTermSelect';
import { ContractSelectGroup, ContractTextAreaInput } from '../ContractFields';

export const AdditionalInformation = ({
  contractQualityIdField,
  contractQualityId,
  setContractQualityId,
  approvalTermIdField,
  observationsField,
  observations,
  setObservations,
}) => (
  <FieldSetWithTitle title={I18n.translate('contracts.form.additional_information')}>
    <Grid>
      <ContractSelectGroup
        field={contractQualityIdField}
        value={contractQualityId}
        onChange={setContractQualityId}
      />

      {approvalTermIdField && approvalTermIdField.options && (
        <ApprovalTermSelect
          field={approvalTermIdField}
          terms={approvalTermIdField.options}
          selected_approval_term_id={parseInt(approvalTermIdField.value || '-1')}
        />
      )}
    </Grid>
    <ContractTextAreaInput
      field={observationsField}
      value={observations}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setObservations(e.target.value)}
    />
  </FieldSetWithTitle>
);
