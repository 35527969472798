import React from 'react';
import Checkbox from 'shared/Checkbox';
import SelectGroup from 'components/groups/SelectGroup';
import InputGroupComponent from 'components/groups/InputGroup';
import useStores from 'utils/useStores';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
  border: 2px solid #000;
  padding: 5px 10px;
  border-radius: 5px;
  gap: 20px;
`;

const StyledExternalAttributes = styled.div`
  flex: 0 0 100%;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  gap: 30px;
`;

const StyledTaster = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 75%;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledCheckBoxWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  flex: 0 0 10%;
`;

const StyledRemovedWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex: 0 0 10%;
`;

const Taster = ({ cuppingCustomer }): JSX.Element => {
  const identifier = cuppingCustomer.identifier;
  const options = [
    { name: 'internal', value: 'internal' },
    { name: 'external', value: 'external' },
  ];
  const { cuppingTableStore } = useStores();

  const disabled = () => {
    return cuppingCustomer.id && !cuppingCustomer.can_be_deleted;
  };

  const userSelect = () => {
    return (
      <SelectGroup
        label={I18n.translate('cupping_tables.form.cupping_table_users.user')}
        name={`cupping_table[cupping_table_users_attributes][${identifier}][user_id]`}
        options={cuppingTableStore.userOptions}
        placeholder={'Select ...'}
        className='input-group--w-100'
        required={true}
        value={cuppingCustomer.userId}
        disabled={disabled()}
        onChange={(e) =>
          cuppingTableStore.setSelectedUser(cuppingCustomer, e.target.value)
        }
      />
    );
  };

  const externalCustomer = () => {
    return (
      <>
        {cuppingCustomer.customerId ? (
          <>
            <StyledExternalAttributes>
              <SelectGroup
                label={I18n.translate('cupping_tables.form.cupping_table_users.customer')}
                name={`cupping_table[cupping_table_users_attributes][${identifier}][customer_id]`}
                options={cuppingTableStore.customerOptions}
                onChange={(e) =>
                  cuppingTableStore.setSelectedCustomer(cuppingCustomer, e.target.value)
                }
                className='input-group--w-50'
                value={cuppingCustomer.customerId}
                required={true}
                disabled={disabled()}
                placeholder={'Select ...'}
              />
              <SelectGroup
                label={I18n.translate(
                  'cupping_tables.form.cupping_table_users.cupping_customer'
                )}
                name={`cupping_table[cupping_table_users_attributes][${identifier}][cupping_customer_id]`}
                options={
                  cuppingTableStore.cuppingCustomersOptions[cuppingCustomer.customerId]
                }
                className='input-group--w-50'
                placeholder={'Select ...'}
                required={true}
                value={cuppingCustomer.tasterId}
                disabled={disabled()}
                onChange={(e) =>
                  cuppingTableStore.setSelectedTaster(cuppingCustomer, e.target.value)
                }
              />
            </StyledExternalAttributes>
            {userSelect()}
          </>
        ) : (
          <SelectGroup
            label={I18n.translate('cupping_tables.form.cupping_table_users.customer')}
            name={`cupping_table[cupping_table_users_attributes][${identifier}][customer_id]`}
            options={cuppingTableStore.customerOptions}
            onChange={(e) =>
              cuppingTableStore.setSelectedCustomer(cuppingCustomer, e.target.value)
            }
            className='input-group--w-100'
            value={cuppingCustomer.customerId}
            required={true}
            disabled={disabled()}
            placeholder={'Select ...'}
          />
        )}
      </>
    );
  };

  return (
    <StyledWrapper className={cuppingCustomer.destroyable ? 'hidden--important' : ''}>
      {cuppingCustomer.destroyable && (
        <InputGroupComponent
          name={`cupping_table[cupping_table_users_attributes][${identifier}][_destroy]`}
          value={1}
          className='hidden--important'
        />
      )}
      {cuppingCustomer.id && (
        <InputGroupComponent
          name={`cupping_table[cupping_table_users_attributes][${identifier}][id]`}
          value={cuppingCustomer.id}
          className='hidden--important'
        />
      )}
      <StyledCheckBoxWrapper>
        {options.map((option) => (
          <Checkbox
            key={option.name}
            label={option.name}
            icon={'tick'}
            id={`cupping_table_users_attributes_${option.value}_${identifier}`}
            name={`cupping_table[cupping_table_users_attributes][${identifier}][user_type_${identifier}]`}
            value={`${cuppingCustomer.type}`}
            round={true}
            asRadioButton={true}
            onChange={() => {
              cuppingTableStore.setSelectedCustomerType(cuppingCustomer, option.value);
            }}
            checked={cuppingCustomer.type === option.value}
            disabled={disabled()}
            required={true}
          />
        ))}
      </StyledCheckBoxWrapper>

      <StyledTaster>
        {cuppingCustomer.type === 'internal' && userSelect()}
        {cuppingCustomer.type === 'external' && externalCustomer()}
      </StyledTaster>
      {cuppingTableStore.allCuppingCustomers.length > 1 && (
        <StyledRemovedWrapper>
          <button
            onClick={() => cuppingTableStore.removeCuppingCustomer(cuppingCustomer)}
            type='button'
            className='button button--required'
            disabled={disabled()}
          >
            X Remove
          </button>
        </StyledRemovedWrapper>
      )}
    </StyledWrapper>
  );
};

export default observer(Taster);
