import styled from 'styled-components';

import COLORS from 'constants/colors';

const NewTest = styled.div`
  height: 6.25em;
  border: 1px dashed ${COLORS.GRAY};
  text-align: center;

  &:before {
    display: flex;
    content: '';
    margin: 0 auto;
    border-bottom: 1px dashed ${COLORS.GRAY};
    width: 94%;
    height: 1.75em;
    margin-bottom: 0.75em;
  }
`;

export default NewTest;
