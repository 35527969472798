import React from 'react';
import TextAreaGroupComponent, {
  TextAreaGroupProps,
} from 'components/groups/TextareaGroup';
import { IFieldInput } from './IFieldInput';

export const ControlledTextAreaInput = ({
  field,
  namespace,
  ...rest
}: IFieldInput & TextAreaGroupProps) => {
  if (field == undefined || !field.visible) {
    return null;
  }
  const actualValue = rest.value || field.value;
  const fieldNameWithoutId = field.name.replace('_id', '');

  // Poor mans I18n Pluralization
  const namespace_plural =
    namespace.slice(-1) == 'y' ? namespace.slice(0, -1) + 'ies' : namespace + 's';

  return (
    <TextAreaGroupComponent
      defaultValue={rest.onChange ? undefined : actualValue}
      id={`${namespace}_${field.name}`}
      disabled={!field?.enabled}
      label={I18n.translate(`${namespace_plural}.form.${fieldNameWithoutId}`)}
      readOnly={field?.readonly}
      data-cy={field.name}
      name={`${namespace}[${field.name}]`}
      changeIndicated={field.changeIndicated}
      {...rest}
    />
  );
};
