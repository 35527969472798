import React from 'react';
import { observer } from 'mobx-react';

import SelectedSubproducts from 'subproducts/candidates/components/SelectedSubproducts';
import SubproductsTable from 'subproducts/candidates/components/SubproductsTable';
import ContentHeader from 'components/elements/ContentHeader';
import Can from 'components/shared/Can';

const AssignCoffee: React.FC = () => {
  return (
    <div className='content'>
      <ContentHeader title={I18n.translate('headlines.subproducts_candidates')} />

      <Can I='create' a='Subproducts::ProductionOrder'>
        <SelectedSubproducts />
      </Can>

      <SubproductsTable />
    </div>
  );
};

export default observer(AssignCoffee);
