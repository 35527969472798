import { observer } from 'mobx-react';
import React from 'react';

import SelectGroup from 'components/groups/SelectGroup';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import Grid from 'components/elements/Grid';

import LeadType from 'types/model/lead';
import { IField } from 'components/shared/form/IField';
import useField from 'customers/hooks/useField';

interface CustomerLeadSelectProps {
  selectedLeadId?: number;
  leads: LeadType[];
  path: string;
  fields: IField[];
}

const CustomerLeadSelect = ({
  selectedLeadId,
  leads,
  path,
  fields,
}: CustomerLeadSelectProps) => {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    window.location.href = id ? `${path}/${id}` : path;
  };

  const selectableLeads = leads.map((lead) => ({
    id: lead.id,
    name: lead.title,
  }));

  const customerLeadField = useField(fields, 'customer_lead');

  return (
    <FieldSetWithTitle title={I18n.translate('customers.form.select_from_lead')}>
      <Grid grid={24}>
        <SelectGroup
          label={I18n.translate('customers.form.lead_label')}
          placeholder={I18n.translate('opportunities.form.select_customer_lead')}
          options={selectableLeads}
          value={selectedLeadId ? selectedLeadId : undefined}
          onChange={(e) => onChange(e)}
          disabled={!customerLeadField?.enabled}
        />
      </Grid>
    </FieldSetWithTitle>
  );
};

export default observer(CustomerLeadSelect);
