import React from 'react';

export interface InputWithSuggestionsProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  listId: string;
  label?: string;
}

export const InputWithSuggestions: React.FC<InputWithSuggestionsProps> = ({
  children,
  listId,
  type = 'text',
  id,
  label = I18n.translate('attributes.id_name'),
  ...inputProps
}) => (
  <div className='input-group'>
    <label htmlFor={id}>{label}</label>

    <input id={id} type={type} list={listId} {...inputProps} />

    <datalist id={listId}>{children}</datalist>
  </div>
);

export default InputWithSuggestions;
