import React, { useState } from 'react';
import Grid from 'components/elements/Grid';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { OpportunitySelectGroup, OpportunityTextInput } from '../OpportunityFields';

interface IThirdPartyShipper {
  id: number;
  value: string;
  country_id: number;
}

export const OpportunityCoffee = ({
  originCountryIdsField,
  originCountryIds,
  setOriginCountryIds,
  thirdPartyShipperField,
  markIdField,
  qualityIdField,
  certificateIdField,
  estimatedKgField,
  contractType,
}) => {
  const [thirdPartyShipper, setThirdPartyShipper] = useState<IThirdPartyShipper>();
  const disableMark = originCountryIds.length > 1;

  let filteredMarks = markIdField?.options;

  if (originCountryIds.length == 1 || thirdPartyShipper) {
    filteredMarks = filteredMarks.filter(
      (mark) =>
        (originCountryIds.length == 1 &&
          mark.countryId == parseInt(originCountryIds[0])) ||
        thirdPartyShipper?.country_id === mark.countryId
    );
  }
  if (disableMark) {
    filteredMarks = [];
  }

  const handleThirdPartySelect = (value: string) => {
    const newThirdPartyShipper = thirdPartyShipperField.options.find(
      (tps: IThirdPartyShipper) => tps.id === parseInt(value, 10)
    );
    setThirdPartyShipper(newThirdPartyShipper);
  };

  return (
    <FieldSetWithTitle title={I18n.translate('opportunities.form.coffee')}>
      <Grid>
        {contractType === 'third_party' && (
          <OpportunitySelectGroup
            field={thirdPartyShipperField}
            value={thirdPartyShipper?.id || ''}
            onChange={handleThirdPartySelect}
          />
        )}
        {contractType === 'fwd' && (
          <OpportunitySelectGroup
            field={originCountryIdsField}
            value={originCountryIds}
            onChange={setOriginCountryIds}
            multiple={true}
            overrideName={'opportunity[origin_country_ids][]'}
          />
        )}
        {contractType === 'local' && (
          <OpportunitySelectGroup
            field={originCountryIdsField}
            value={
              originCountryIds.length > 0
                ? originCountryIds[0]
                : originCountryIdsField.options[0].id
            }
            onChange={(id) => setOriginCountryIds([id])}
            overrideName={'opportunity[origin_country_ids][]'}
          />
        )}
        <div>
          <OpportunitySelectGroup
            field={markIdField}
            overrideOptions={filteredMarks}
            value={markIdField?.value}
            disabled={disableMark}
          />
          {disableMark && (
            <span>
              <input type='hidden' name='opportunity[mark_id]' value='' />
            </span>
          )}
        </div>
        <OpportunityTextInput
          field={estimatedKgField}
          type='number'
          min={0}
          step='1'
          append='kg'
        />
        <OpportunitySelectGroup field={qualityIdField} value={qualityIdField?.value} />
        <OpportunitySelectGroup
          field={certificateIdField}
          value={certificateIdField?.value}
        />
      </Grid>
    </FieldSetWithTitle>
  );
};
