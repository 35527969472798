import { observer } from 'mobx-react';
import React from 'react';
import Tile, { TileDisclosure, TileHeader } from 'shared/Tile';
import useStores from 'utils/useStores';

interface PaginatedTileProps {
  renderSelectedData: () => React.ReactNode;
  hasSelectedData: boolean;
}

const PaginatedTile: React.FC<PaginatedTileProps> = ({
  renderSelectedData,
  hasSelectedData,
  children,
}) => {
  const { paginatedTableStore } = useStores();

  const { tileHeaderCollapsed } = paginatedTableStore;

  const handleDisclosureClick = () => {
    paginatedTableStore.toggleTileHeaderCollapsed();
  };

  return (
    <Tile collapsibleIndicator={tileHeaderCollapsed}>
      <TileHeader className='selected-lots__header'>
        <TileDisclosure
          disabled={!hasSelectedData}
          handleCollapsible={handleDisclosureClick}
          collapsed={tileHeaderCollapsed}
        />
        {children}
      </TileHeader>
      {!paginatedTableStore.tileHeaderCollapsed && renderSelectedData()}
    </Tile>
  );
};

export default observer(PaginatedTile);
