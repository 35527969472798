import { Provider } from 'mobx-react';
import React from 'react';
import SendSampleIndex from '../components/SendSampleIndex';
import TransportSamplesStore from '../stores/TransportSamplesStore';

const TransportSamplesIndexContainer: React.FC = () => (
  <Provider transportSamplesStore={new TransportSamplesStore()}>
    <SendSampleIndex />
  </Provider>
);

export default TransportSamplesIndexContainer;
