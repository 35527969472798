import { observer } from 'mobx-react';
import React from 'react';
import useStores from 'utils/useStores';
import PaginatedTable from './PaginatedTable';

interface TableWrapperProps {
  renderTableHeader: (dataRow: any) => React.ReactNode;
  renderTableData: (dataRow: any) => React.ReactNode;
}

const TableWrapper: React.FC<TableWrapperProps> = ({
  renderTableHeader,
  renderTableData,
}) => {
  const { paginatedTableStore } = useStores();

  const { loadedRows, storeState } = paginatedTableStore;

  const handlePaginationClick = (event: MouseEvent) => {
    const target = event.target as HTMLAnchorElement;

    const params = paginatedTableStore.urlStringToPaginateParams(
      target.href.split('?')[1]
    );

    paginatedTableStore.loadPage(params['page'], params['params']);
  };

  const renderTableRows = () => {
    if (loadedRows.length > 0) {
      return loadedRows.map((loadedRow) => {
        return renderTableData(loadedRow);
      });
    } else {
      return <>No data</>;
    }
  };

  const renderTableHeading = () => {
    if (loadedRows.length > 0) {
      const keys = Object.keys(loadedRows[0]);
      return renderTableHeader(keys);
    } else {
      return <>No data</>;
    }
  };

  const renderTable = () => {
    if (storeState === 'idle') {
      return loadedRows.length > 0 ? (
        <PaginatedTable handlePaginationClick={handlePaginationClick}>
          <thead>{renderTableHeading()}</thead>
          <tbody>{renderTableRows()}</tbody>
        </PaginatedTable>
      ) : (
        <span>No data</span>
      );
    } else if (storeState === 'loading') {
      return <div>Loading</div>;
    } else {
      return <div>Error</div>;
    }
  };

  return renderTable();
};

export default observer(TableWrapper);
