import { deserialise } from 'kitsu-core';

import type DecafOrderType from 'decaf/types/DecafOrder';
import type { JsonApi } from 'types/utils/jsonApi';
import type Ico from 'types/model/ico';

import Validation from 'types/utils/validations';
import DecafOrder from 'decaf/models/DecafOrder';
import SelectOptionArray from 'types/model/selectOption';

export type RoastingValidation = {
  pppt: Validation;
};

class QualityAssuranceStore {
  public decafOrders: DecafOrder[];
  public humidityAcceptingReasons: SelectOptionArray;
  public roastingResults: SelectOptionArray;
  public roastingSampleWeight: number;
  public roastingValidations: RoastingValidation;

  public constructor(
    decafOrders: JsonApi<DecafOrderType, Ico>,
    humidityAcceptingReasons: SelectOptionArray,
    roastingResults: SelectOptionArray,
    roastingSampleWeight: number,
    roastingValidations: RoastingValidation
  ) {
    const deserializedDecafOrders = deserialise(decafOrders).data as DecafOrderType[];

    this.decafOrders = deserializedDecafOrders.map(
      (decafOrder) => new DecafOrder(decafOrder)
    );

    this.humidityAcceptingReasons = deserialise(
      humidityAcceptingReasons
    ) as SelectOptionArray;

    this.roastingResults = deserialise(roastingResults).data as SelectOptionArray;
    this.roastingSampleWeight = roastingSampleWeight;
    this.roastingValidations = roastingValidations;
  }
}

export default QualityAssuranceStore;
