import { computed } from 'mobx';

import type PhysicalAnalysisType from '../types/PhysicalAnalysis';

class PhysicalAnalysis {
  public sampleId: number;
  public id?: number;
  public type?: string;
  public exceedsNorm = false;
  public state?: string;
  public fullDefectsString?: string;

  constructor(sampleId: number, physicalAnalysis?: PhysicalAnalysisType) {
    this.sampleId = sampleId;

    if (physicalAnalysis) {
      this.id = physicalAnalysis.id;
      this.type = physicalAnalysis.type;
      this.exceedsNorm = physicalAnalysis.exceeds_norm;
      this.state = physicalAnalysis.state;
      this.fullDefectsString = physicalAnalysis.full_defects_string;
    }
  }

  @computed get persisted() {
    return !!this.id;
  }

  @computed get failed() {
    return this.persisted && this.state === 'failed';
  }

  @computed get passed() {
    return this.persisted && this.state === 'passed';
  }
}

export default PhysicalAnalysis;
