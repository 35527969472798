import classnames from 'classnames';
import React, { useState } from 'react';
import Routes from 'routes';

import SpaceBetween from 'components/elements/SpaceBetween';
import Checkbox from 'shared/Checkbox';

import { customFetch } from 'utils/fetch';

interface PendingSampleColumnProps {
  id: number;
}

const PendingSampleColumn = ({ id }: PendingSampleColumnProps) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (value: boolean) => {
    setChecked(value);
  };

  const buttonClass = classnames('button', 'button--small', {
    'button--gray': !checked,
    'button--green': checked,
  });

  const handleButtonClick = () => {
    let path;
    if (checked) {
      path = Routes.pending_random_samples_create_path(id);
    } else {
      path = Routes.pending_random_samples_discard_path(id);
    }
    customFetch(path, {}).then(
      () => (window.location.href = Routes.pending_random_samples_path())
    );
  };

  return (
    <SpaceBetween alignCenter={true}>
      <Checkbox icon='tick' checked={checked} label='300g' onChange={handleChange} />
      <button className={buttonClass} onClick={handleButtonClick}>
        {checked ? 'done' : 'close'}
      </button>
    </SpaceBetween>
  );
};

export default PendingSampleColumn;
