import React from 'react';
import styled from 'styled-components';

import IResult from 'types/utils/IResult';

import COLORS from 'constants/colors';

import SpaceBetween from 'components/elements/SpaceBetween';

export const TestResult = styled.div`
  min-width: 12.5em;
  min-height: 8.75em;
  background: ${COLORS.LIGHT_GRAY};
`;

const Header = styled.div`
  display: flex;
  height: 1.75em;
  background: ${COLORS.TABLE_BORDERS};
  font-size: 0.875em;
  padding: 0 1em;
`;

const Results = styled.div`
  display: grid;
  padding: 0.4375em 1em;
  grid-template-columns: 1fr auto;

  span {
    color: ${COLORS.SUB_TEXT};
    text-align: right;
  }
`;

const Name = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 9em;
`;

export interface TestResultProps {
  test: IResult;
}

const TestResultComponent: React.FC<TestResultProps> = ({
  test: { created_at, created_by, values },
}) => (
  <TestResult>
    <Header>
      <SpaceBetween alignCenter={true} noWrap={true}>
        {created_by && <Name>{created_by}</Name>}
        {created_at && <span>{I18n.l('time.formats.long', created_at.toString())}</span>}
      </SpaceBetween>
    </Header>

    <Results>
      {values.map((item) => (
        <React.Fragment key={item.test}>
          <strong>{item.test}:</strong>
          <span>{item.value}</span>
        </React.Fragment>
      ))}
    </Results>
  </TestResult>
);

export default TestResultComponent;
