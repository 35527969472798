import React from 'react';
import styled from 'styled-components';
import Routes from 'routes';
import { SingleValue } from 'react-select';

import csrfToken from 'utils/csrfToken';

import GroupedSearch, { SingleOption } from 'components/elements/GroupedSearch';

const FacilitySelect = styled.div`
  color: #fff;
  max-width: 200px;
`;

interface FacilityOption {
  icon: string;
  label: string;
  selected: boolean;
  type: string;
  value: number;
}

interface Option {
  label: string;
  options: Array<SingleOption>;
}

interface FacilitySelectProps {
  facilities: Array<Option>;
  selectedFacility: FacilityOption;
}

const handleSubmit = (option: SingleValue<SingleOption>): void => {
  if (option) {
    fetch(Routes.set_issueable_path(), {
      body: JSON.stringify({
        issueable: {
          id: option.value,
          type: option.type,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken(),
      },
      method: 'post',
    }).then(() => {
      window.location.pathname = '/';
    });
  }
};

function FacilitySelectComponent({ facilities, selectedFacility }: FacilitySelectProps) {
  return (
    <FacilitySelect data-cy='select_warehouse'>
      <label htmlFor='warehouse'>{I18n.translate('menu.headline')}</label>

      <GroupedSearch
        placeholder={I18n.translate('menu.select_a_facility')}
        selectOption={selectedFacility}
        options={facilities}
        onChange={handleSubmit}
      />
    </FacilitySelect>
  );
}

export default FacilitySelectComponent;
