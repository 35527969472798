import DestinationWarehouseCost from './DestinationWarehouseCost';

export type DeliveryOrderState =
  | 'new'
  | 'sent'
  | 'in_progress'
  | 'accepted'
  | 'released'
  | 'rejected';

export interface DeliveryOrder {
  id: number;
  customer: Customer;
  destination_warehouse: DestinationWarehouse;
  delivery_order_items?: DeliveryOrderItem[];
  delivery_order_address?: DeliveryOrderAddress;
  delivery_order_shipping_option?: DeliveryOrderShippingOption;
  has_address: boolean;
  total_units: string;
  identifier: string;
  accepted_date: string;
  editable: boolean;
  notes: string;
  state: DeliveryOrderState;
  downloadUrl?: string;
  dispatched: boolean;
}

export interface Customer {
  id: number;
  name: string;
  address: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  accounting_identifier: string;
}

export interface DestinationWarehouse {
  id: number;
  code: string;
  name: string;
  address: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  destination_warehouse_cost?: DestinationWarehouseCost;
}

interface Contract {
  id: number;
  main_identifier: string;
  customer: string;
  origin_country_code: string;
  warehouse: string;
  packaging_type: string;
  mark: string;
  units: number;
}

export interface DeliveryOrderItem {
  id: number;
  deliveryOrder: DeliveryOrder;
  ico_identifier: string;
  contract_identifier: string;
  units_to_deliver: number;
  bin: string;
}

export interface DeliveryOrderAddress {
  id: number | null;
  name: string;
  address: string;
  postcode: string;
  city: string;
  state: string;
}

export enum TransportationMode {
  RAILWAY = 'Railway',
  BARGE = 'Barge',
  TRUCK = 'Truck',
  VESSEL = 'Vessel',
}

export enum Incoterm {
  EXW = 'EXW',
  FCA = 'FCA',
  DAP = 'DAP',
  DDP = 'DDP',
}

export interface DeliveryOrderShippingOption {
  id: number | null;
  ship_with_vollers: boolean;
  transportation_mode: TransportationMode;
  load_date: Date;
  remarks: string;
  incoterm: Incoterm;
  dispatched: boolean;
}

export interface Ico {
  alternative_ico_id: string;
  contract_identifier: string;
  contract: Contract;
  customer: string;
  destination_warehouse: string;
  ico_with_secondary_number: string;
  id: number;
  identifier: string;
  mark: string;
  origin_country_code: string;
  packaging_capacity: string;
  packaging_type: string;
  pre_shipment_sample_required: boolean;
  produced_weight?: {
    value: string; // Float
    unit: string;
  };
  quality: string;
}

export interface GPBaseExcelsoInventory {
  ico_identifier: string;
  quantity: string;
  unitOfMeasurement: string;
  bin: string;
  legacy: boolean;
  display_identifier?: string;
  units: number;
  remaining_units: number;
  export_status: string;
}

export interface GPExcelsoInventory extends GPBaseExcelsoInventory {
  ico_data: Ico;
  excelso_type: 'regular' | 'third_party';
}

export interface GPSpotExcelsoInventory extends GPBaseExcelsoInventory {
  excelso_type: 'spot';
  ico_data: Ico & { internal_contract_identifier: string };
  contract_data: {
    units: number;
    available_units: number;
  };
}
