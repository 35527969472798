import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';
import useStores from 'utils/useStores';

import Icon from 'components/elements/Icon';
import CardHeader from './CardHeader';
import CardDropzone from './CardDropzone';
import IcoPopup from './IcoPopup';
import CardWrapper from './CardWrapper';
import IcoItem from './IcoItem';

import DestinationPort from '../models/DestinationPort';

import Tile from 'shared/Tile/components/Tile';
import TileDisclosure from 'shared/Tile/components/TileDisclosure';
import TileHeader from 'shared/Tile/components/TileHeader';
import Ico from 'exports/models/Ico';
import Export from 'exports/models/Export';
import ExportIcon from './ExportIcon';
import { AbilityContext } from 'components/shared/Can';

export interface CardProps {
  export: Export;
  destinationPort: DestinationPort;
}

const IcoList = styled.ul`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${COLORS.BORDERS};
  margin: 0 0.5rem;
  padding-top: 0.5rem;
`;

const TileSummary = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 100%;
`;

const TileSummaryItem = styled.div`
  align-items: center;
  display: inline-flex;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const IcoText = styled.span`
  flex: 0 0 auto;
  margin-right: 0.25rem;
  font-size: 0.875rem;
`;

const TileHeaderWrapper = styled(TileHeader)`
  min-height: 2rem;

  .exports-card__ico-icon {
    font-size: 1.25rem;
    padding-right: 0.25rem;
  }

  .selected-lots__disclosure {
    padding-left: 0;
  }
`;

const Card = ({ export: exportModel, destinationPort }: CardProps) => {
  const ability = useContext(AbilityContext);

  const canEditExport = ability.can('edit', 'Exports::Export');

  const { exportsStore } = useStores();
  const {
    filters: { contractExportIco },
  } = exportsStore;

  const handleOnListClick = () => {
    exportsStore.openExportDetails(exportModel, 0);
  };

  const handleOnStartShippingClick = () => {
    exportsStore.openExportDetails(exportModel, 1);
  };

  const handleOnEndShippingClick = () => {
    exportsStore.openExportDetails(exportModel, 2);
  };

  const onIcoClick = (ico: Ico) => {
    if (exportsStore?.selectedIco === ico) {
      exportsStore.resetSelectedModels();
    } else {
      exportsStore.resetSelectedModels();
      exportsStore.setSelectedExport(exportModel);
      exportsStore.setSelectedIco(ico);
      exportsStore.setSelectedDestinationPort(destinationPort);
    }
  };

  const onDropzoneClick = () => {
    if (
      !exportsStore.selectedIco ||
      !exportsStore.selectedExport ||
      !exportsStore.selectedDestinationPort
    ) {
      throw new Error('NO ICO SELECTED FOR MOVING');
    }
    exportsStore.moveIcoToExport(
      exportsStore.selectedIco,
      exportsStore.selectedExport,
      exportModel
    );
    exportsStore.resetSelectedModels();
  };

  const renderDropzone =
    exportsStore.selectedExport &&
    exportsStore.selectedExport.canRemoveIcos &&
    exportsStore.selectedIco &&
    exportsStore.renderDropzoneForDestinationPort(destinationPort) &&
    exportModel.canBeTargetable(exportsStore.selectedExport, exportsStore.selectedIco);

  return (
    <CardWrapper>
      <CardHeader
        exportModel={exportModel}
        onListClick={handleOnListClick}
        onStartShippingClick={handleOnStartShippingClick}
        onEndShippingClick={handleOnEndShippingClick}
      />

      <Tile collapsibleIndicator={exportModel.collapsed} noMargin={true}>
        <>
          <TileHeaderWrapper>
            <TileDisclosure
              handleCollapsible={exportModel.toggleCollapsed}
              collapsed={exportModel.collapsed}
            />
            <TileSummary>
              <TileSummaryItem>
                <ExportIcon type='RED_CROSS' className='exports-card__ico-icon' />

                {exportModel.countIcosWithError}
              </TileSummaryItem>

              <TileSummaryItem>
                <ExportIcon type='ORANGE_RING' className='exports-card__ico-icon' />

                {exportModel.countIcosInProductionOrder}
              </TileSummaryItem>

              <TileSummaryItem>
                <ExportIcon type='YELLOW_RING' className='exports-card__ico-icon' />

                {exportModel.countIcosInMilling}
              </TileSummaryItem>

              <TileSummaryItem>
                <ExportIcon type='CHECKMARK' className='exports-card__ico-icon' />

                {exportModel.countIcosQaApproved}
              </TileSummaryItem>
            </TileSummary>
          </TileHeaderWrapper>

          {!exportModel.collapsed && (
            <IcoList>
              {exportModel.icos.map((ico) => (
                <IcoItem
                  key={ico.id}
                  onClick={canEditExport ? () => onIcoClick(ico) : () => false}
                  isSelectedIco={
                    exportsStore.selectedIco && exportsStore.selectedIco === ico
                  }
                  highlighted={
                    !!contractExportIco &&
                    (ico.identifier.includes(contractExportIco) ||
                      ico.contract_identifier.includes(contractExportIco))
                  }
                  disabled={!canEditExport}
                >
                  {exportsStore.selectedIco && exportsStore.selectedIco === ico && (
                    <IcoPopup ico={ico} />
                  )}

                  <ExportIcon
                    type={ico.icoStateIcon}
                    className='exports-card__ico-icon'
                  />

                  <IcoText>{ico.identifier}</IcoText>

                  <ExportIcon
                    type={ico.preshipmentSampleStateIcon}
                    className='exports-card__ico-icon'
                  />

                  <Icon
                    icon='coffee'
                    color={
                      exportsStore.selectedIco && exportsStore.selectedIco === ico
                        ? '#ffffff'
                        : '#111820'
                    }
                    className='exports-card__ico-icon'
                  />
                </IcoItem>
              ))}
            </IcoList>
          )}
        </>
      </Tile>

      {renderDropzone && <CardDropzone onClick={onDropzoneClick} />}
    </CardWrapper>
  );
};

export default observer(Card);
