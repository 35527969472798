import React from 'react';
import styled from 'styled-components';
import MenuItem, { MenuItemProps } from '../elements/MenuItem';

import { COLORS } from 'constants/colors';
import { SubMenuItem } from 'types/model/menuItem';

interface LinkItemProps {
  active?: boolean;
}

export interface MenuLinkComponentProps extends MenuItemProps {
  menuItem: SubMenuItem;
}

const LinkItem = styled.a<LinkItemProps>`
  font-weight: 400;
  color: ${(props) => (props.active ? COLORS.WHITE : COLORS.MENU_TEXT_COLOR)};
  margin: 0 -0.5em;
  padding: 0 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.1s ease-out;

  &:hover {
    color: ${COLORS.WHITE};
    background: ${(props) => (props.active ? 'transparent' : 'rgba(255,255,255,0.1)')};
  }
`;

const MenuLinkComponent: React.FC<MenuLinkComponentProps> = ({ menuItem, ...props }) => {
  return (
    <MenuItem {...props}>
      <LinkItem active={menuItem.active} href={menuItem.path} data-cy='link_item'>
        <span>{menuItem.title}</span>
        {!!menuItem.notification_count && menuItem.notification_count > 0 && (
          <span className='badge'>{menuItem.notification_count}</span>
        )}
      </LinkItem>
    </MenuItem>
  );
};

export default MenuLinkComponent;
