import { observer } from 'mobx-react';
import React from 'react';

import { SelectableLot } from '../types/Lot';

import useStores from 'utils/useStores';

import LotItem from './LotItem';

export interface LotTableProps extends React.HTMLAttributes<HTMLTableElement> {
  lots: Array<SelectableLot>;
}

const LotTable: React.FC<LotTableProps> = ({ lots, ...props }) => {
  const { setStore } = useStores();

  return (
    <table {...props}>
      <thead>
        <tr>
          <th className='align-left'>ID</th>

          <th className='sets-table__column--sensorial table-border align-left'></th>

          <th className='table-border'>{I18n.translate('lot_sets.index.quality')}</th>

          <th className='table-border'>{I18n.translate('lot_sets.index.final_score')}</th>

          <th className='sets-table__column--weight table-border'>
            {I18n.translate('lot_sets.index.total')}
          </th>

          <th className='table-border align-left'>
            {I18n.translate('lot_sets.index.producer')}
          </th>

          <th>{I18n.translate('lot_sets.index.add_to_set')}</th>
        </tr>
      </thead>

      <tbody>
        {lots.map((lot) => {
          const disabled = Object.entries(setStore.selectedRequirements).some(
            ([key, value]) => {
              return lot.attributes[key] !== value;
            }
          );

          const onLotCheck = () => {
            if (!lot.selected) {
              setStore.selectLot(lot);
            } else {
              setStore.deselectLot(lot);
            }
          };

          return (
            <LotItem
              key={lot.attributes.id}
              lot={lot}
              checked={lot.selected}
              disabled={disabled}
              onCheck={onLotCheck}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default observer(LotTable);
