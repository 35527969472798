import React from 'react';
import styled from 'styled-components';

import COLORS from 'constants/colors';
import { observer } from 'mobx-react';

import Opportunity from 'customerSamples/models/Opportunity';
import SpotOpportunity from 'customerSamples/models/SpotOpportunity';
import PssInternalContract from 'customerSamples/models/PssInternalContract';

export interface SampleEntityHeaderProps {
  sampleEntity: Opportunity | SpotOpportunity | PssInternalContract;
}

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${COLORS.BORDERS};
`;

const HeaderItem = styled.div`
  position: relative;
  padding: 0 0.625rem;
  font-size: 1.25rem;

  &:first-child {
    padding-left: 0;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 1px;
    height: 100%;
    background: ${COLORS.BLACK};
  }
`;

function SampleEntityHeader({ sampleEntity }: SampleEntityHeaderProps): JSX.Element {
  return (
    <HeaderRow>
      <HeaderItem>{sampleEntity.estimatedDeliveryDate}</HeaderItem>
      {(sampleEntity instanceof Opportunity ||
        sampleEntity instanceof SpotOpportunity) && (
        <HeaderItem data-cy='opportunity-header-id'>
          <b>{sampleEntity.mainIdentifier}</b>
        </HeaderItem>
      )}
      {sampleEntity.customer?.name && (
        <HeaderItem>{sampleEntity.customer.name}</HeaderItem>
      )}
    </HeaderRow>
  );
}

export default observer(SampleEntityHeader);
