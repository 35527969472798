// import Routes, { production_orders_path } from 'routes';
import Grid from 'components/elements/Grid';
import SelectGroup from 'components/groups/SelectGroup';
import React, { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import type ProductionOrderType from '../types/ProductionOrder';
import type { ProductionOrderType as ProductionOrderTypes } from 'types/model/productionOrder';
import SelectOptionArray from 'types/model/selectOption';
import UnitsAndFractions from './UnitsAndFractions';
import Checkbox from 'shared/Checkbox';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';

export interface ProductionOrderProps {
  productionOrder: ProductionOrderType;
  types: SelectOptionArray;
  mills: SelectOptionArray;
  hasMark: boolean;
  granulometries: SelectOptionArray;
  capacity: number;
  units: number;
  editable?: boolean;
  confidential?: boolean;
  observations?: string;
}

export type ProductionOrderForm = {
  production_order: {
    production_order_type?: ProductionOrderTypes;
    mill_id?: number;
    granulometry_id?: number;
    fractions_amount?: number;
    confidential: boolean;
    observations?: string;
  };
};

function ProductionOrder({
  productionOrder,
  hasMark,
  types,
  mills,
  granulometries,
  capacity,
  units,
  editable = true,
}: ProductionOrderProps) {
  const methods = useForm<ProductionOrderForm>({
    defaultValues: {
      production_order: {
        production_order_type: productionOrder.production_order_type,
        mill_id: productionOrder.mill_id,
        granulometry_id: productionOrder.granulometry_id,
        fractions_amount: productionOrder.fractions_amount || 1,
        confidential: productionOrder.confidential || false,
        observations: productionOrder.observations,
      },
    },
  });

  const { register, reset, watch, getValues, control } = methods;
  const [isChecked, setIsChecked] = useState(productionOrder.confidential || false);

  const handleOnProductionOrderTypeChange = (): void => {
    reset({
      production_order: {
        ...getValues()['production_order'],
        fractions_amount: 1,
        confidential: false,
      },
    });
  };

  const millEditable = useMemo(() => productionOrder.aasm_state === 'initialized', []);

  const isVanillaProductionOrder =
    watch('production_order[production_order_type]') === 'vanilla';

  return (
    <FormProvider {...methods}>
      <FieldSetWithTitle
        title={I18n.translate('production_orders.form.production_details')}
        width='none'
      >
        <Grid grid={14}>
          <SelectGroup
            label={I18n.translate('production_orders.form.type')}
            options={types}
            disabled={!editable || hasMark}
            name='production_order[production_order_type]'
            onChange={handleOnProductionOrderTypeChange}
            ref={register}
          />

          <SelectGroup
            label={I18n.translate('production_orders.form.mill')}
            placeholder={I18n.translate('production_orders.form.select_a_mill')}
            options={mills}
            disabled={!millEditable}
            name={'production_order[mill_id]'}
            ref={register}
          />

          <SelectGroup
            label={I18n.translate('production_orders.form.granulometry')}
            placeholder={I18n.translate('production_orders.form.select_a_granulometry')}
            options={granulometries}
            name={'production_order[granulometry_id]'}
            ref={register}
          />
        </Grid>
      </FieldSetWithTitle>

      <FieldSetWithTitle
        title={I18n.translate('production_orders.form.coffee_details')}
        width='none'
      >
        <UnitsAndFractions
          units={units}
          capacity={capacity}
          fractionsConfigurable={isVanillaProductionOrder}
        />
      </FieldSetWithTitle>

      <FieldSetWithTitle
        title={I18n.translate('production_orders.form.additional_information')}
        width='none'
      >
        <Controller
          control={control}
          name='production_order[confidential]'
          render={({ name, ref }) => (
            <Checkbox
              label={I18n.translate('production_orders.form.confidential')}
              icon='tick'
              inline
              readOnly={!isVanillaProductionOrder}
              value={isChecked ? 'true' : 'false'}
              onChange={setIsChecked}
              checked={isChecked}
              name={name}
              ref={ref}
            />
          )}
        />
      </FieldSetWithTitle>

      <FieldSetWithTitle
        title={I18n.translate('production_orders.form.marking_observations')}
        width='none'
      >
        <TextAreaGroupComponent name='production_order[observations]' ref={register} />
      </FieldSetWithTitle>
    </FormProvider>
  );
}

export default ProductionOrder;
