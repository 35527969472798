import PaginatedTableStore from 'components/shared/PaginatedTable/stores/PaginatedTableStore';
import { action, computed, observable } from 'mobx';

import ITransportSample from 'samples/types/ITransportSample';
import Routes from 'routes';
import SelectedTranportSamplesStore from './SelectedTransportSamplesStore';
import SelectableTransportSampleModel from '../models/SelectableTransportSampleModel';
import {
  PaginatedErrorResponse,
  PaginatedSuccessResponse,
} from 'components/shared/PaginatedTable/utils/paginatedTableCustomFetch';

class SendSamplesStore {
  @observable public paginatedTableStore: PaginatedTableStore<ITransportSample>;
  @observable public selectedSamplesStore: SelectedTranportSamplesStore;

  // Filter fields
  @observable public searchString = '';

  @observable public errors: Array<string> = [];

  constructor() {
    this.paginatedTableStore = new PaginatedTableStore(
      Routes.api_v1_samples_transport_samples_paginated_index_path,
      this.successHandler,
      this.errorHandler,
      Routes.new_sample_transport_path
    );

    this.selectedSamplesStore = new SelectedTranportSamplesStore();
  }

  // TODO add proper type for response parameter
  @action public successHandler = (
    response: PaginatedSuccessResponse<ITransportSample>
  ): void => {
    this.paginatedTableStore.updatePagy(response.pagy);
    this.paginatedTableStore.setLoadedRows(response.data);
  };

  @action public errorHandler = (response: PaginatedErrorResponse): void => {
    this.errors = response.errors;
  };

  @action public toggleSampleSelection = (sample: SelectableTransportSampleModel) => {
    this.selectedSamplesStore.toggleSampleSelection(sample);
  };

  @action public setSearchString = (searchString: string): void => {
    this.searchString = searchString;
  };

  @computed get totalWeight(): number {
    return this.selectedSamples.reduce(
      (totalWeight, { attributes }) => totalWeight + attributes.weight,
      0
    );
  }

  @computed get totalSamples(): number {
    return this.selectedSamples.length;
  }

  @computed get isFormValid(): boolean {
    return this.selectedSamples.length > 0;
  }

  @computed get selectedSamples(): SelectableTransportSampleModel[] {
    return this.selectedSamplesStore.selectedSamples;
  }

  public findSample = (
    sample: ITransportSample
  ): SelectableTransportSampleModel | undefined => {
    return this.selectedSamplesStore.findSample(sample);
  };
}

export default SendSamplesStore;
