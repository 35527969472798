import { action, observable, computed } from 'mobx';
import IFinalizeableResource from '../types/IFinalizeableResource';

export class FinalizeableDependency {
  @observable
  private finalizableDependencies = observable.array<IFinalizeableResource>();

  @action
  public add = (dependency: IFinalizeableResource): void => {
    this.finalizableDependencies.push(dependency);
  };

  @action
  public remove = (dependency: IFinalizeableResource): void => {
    this.finalizableDependencies.remove(dependency);
  };

  @computed
  public get finalized(): boolean {
    return this.finalizableDependencies.every((dependency) => dependency.finalized);
  }
}

export default FinalizeableDependency;
