import styled from 'styled-components';

const FlexContentWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
  max-width: 8rem;
  text-align: center;

  span:only-child {
    width: 100%;
  }
`;

export default FlexContentWrapper;
