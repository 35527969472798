const csrfToken = (): string => {
  let csrfTokenString = '';

  const csrfMeta: HTMLMetaElement | null = document.querySelector('[name=csrf-token]');

  if (csrfMeta !== null) {
    csrfTokenString = csrfMeta.content;
  }

  return csrfTokenString;
};

export default csrfToken;
