import Grid from 'components/elements/Grid';
import InputGroup from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import COLORS from 'constants/colors';
import FormFieldSet from 'exports/components/FormFieldSet';
import { observer } from 'mobx-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import DestinationWarehouse from 'types/model/destinationWarehouse';
import AvailabilityNoticeModel from '../../models/AvailabilityNotice';
import AccordionFormBody from '../AccordionFormBody';

export interface AvailabilityNoticeProps {
  availabilityNotice: AvailabilityNoticeModel;
  destinationWarehouses: DestinationWarehouse[];
}

const AvailabilityNoticeWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

const AvailabilityNotice = ({
  availabilityNotice,
  destinationWarehouses,
}: AvailabilityNoticeProps) => {
  const { register, reset, handleSubmit, formState } = useForm({
    defaultValues: availabilityNotice,
  });

  const onFinalizeClick = async () => {
    await availabilityNotice.finalize();
  };

  const onSaveClick = handleSubmit(async (formData: any) => {
    await availabilityNotice.save(formData);

    reset(formData);
  });

  return (
    <AvailabilityNoticeWrapper>
      <AccordionFormBody
        isFinalizable={availabilityNotice.isFinalizable}
        isDirty={formState.isDirty}
        isSubmitting={formState.isSubmitting}
        finalized={availabilityNotice.finalized}
        onSubmitClick={onSaveClick}
        onFinalizeClick={onFinalizeClick}
        downloadUrl={undefined}
      >
        <FormFieldSet noBorder={true} disabled={availabilityNotice.finalized}>
          <Grid grid={16}>
            <InputGroup
              id='date_of_availability'
              label={I18n.translate(
                'exports.form.availability_notice.date_of_availability_label'
              )}
              type='date'
              placeholder={I18n.translate(
                'exports.form.availability_notice.date_of_availability_placeholder'
              )}
              name='date_of_availability'
              ref={register}
            />

            <SelectGroup
              label={I18n.translate(
                'exports.form.availability_notice.arrival_warehouse_label'
              )}
              placeholder={I18n.translate(
                'exports.form.availability_notice.arrival_warehouse_placeholder'
              )}
              name='arrival_warehouse_id'
              options={destinationWarehouses}
              ref={register}
            />
          </Grid>
        </FormFieldSet>
      </AccordionFormBody>
    </AvailabilityNoticeWrapper>
  );
};

export default observer(AvailabilityNotice);
