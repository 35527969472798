import { observer } from 'mobx-react';
import React from 'react';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import Grid from 'components/elements/Grid';

import Customer from 'customers/models/Customer';
import { ReasonGet } from 'types/model/reason';
import { IField } from 'components/shared/form/IField';
import useStores from 'utils/useStores';
import useField from 'customers/hooks/useField';

export interface CustomerDetailsProps {
  customer: Customer;
  leadSources: ReasonGet[];
  customerOwners: ReasonGet[];
  fields: IField[];
}

const CustomerDetails = ({
  customer,
  leadSources,
  customerOwners,
  fields,
}: CustomerDetailsProps) => {
  const customerLeadSourceField = useField(fields, 'customer_lead_source');
  const customerOwnerField = useField(fields, 'customer_owner');
  const customerAccountingIdField = useField(fields, 'customer_accounting_id');
  const companySizeIdField = useField(fields, 'company_size_id');
  const marketTypeIdField = useField(fields, 'market_type_id');

  const {
    customerStore: { companySizes, marketTypes },
  } = useStores();

  return (
    <FieldSetWithTitle title={I18n.translate('customers.form.customer_details')}>
      <Grid grid={12}>
        <SelectGroup
          name='customer[lead_source_id]'
          label={I18n.translate('attributes.lead_source')}
          options={leadSources}
          placeholder={I18n.translate('customers.form.select_lead_source')}
          onChange={(e) => customer.setLeadSourceId(e.target.value)}
          required={true}
          value={customer.leadSourceId || ''}
          disabled={!customerLeadSourceField?.enabled}
        />

        <SelectGroup
          name='customer[customer_owner_id]'
          label={I18n.translate('attributes.sales_representative')}
          options={customerOwners}
          placeholder={I18n.translate('customers.form.select_sales_representative')}
          onChange={(e) => customer.setCustomerOwnerId(e.target.value)}
          required={true}
          value={customer.customerOwnerId || ''}
          disabled={!customerOwnerField?.enabled}
        />

        <InputGroupComponent
          label={I18n.translate('attributes.accounting_id')}
          name='customer[accounting_id]'
          value={customer.accountingId || ''}
          placeholder=''
          readOnly={true}
          disabled={!customerAccountingIdField?.enabled}
        />

        <SelectGroup
          name='customer[company_size_id]'
          label={I18n.translate('attributes.company_size')}
          options={companySizes}
          placeholder={I18n.translate('customers.form.select_company_size')}
          onChange={(e) => customer.setCompanySizeId(e.target.value)}
          required={true}
          value={customer.companySizeId || ''}
          disabled={!companySizeIdField?.enabled}
        />

        <SelectGroup
          name='customer[market_type_id]'
          label={I18n.translate('attributes.market_type')}
          options={marketTypes}
          placeholder={I18n.translate('customers.form.select_market_type')}
          onChange={(e) => customer.setMarketTypeId(e.target.value)}
          required={true}
          value={customer.marketTypeId || ''}
          disabled={!marketTypeIdField?.enabled}
        />
      </Grid>
    </FieldSetWithTitle>
  );
};

export default observer(CustomerDetails);
