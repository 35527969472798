import styled from 'styled-components';

import { COLORS } from 'constants/colors';

interface OverlayBaseFormProps {
  noPadding?: boolean;
  autoWidth?: boolean;
}

const OverlayBaseForm = styled.div<OverlayBaseFormProps>`
  position: relative;
  overflow: auto;
  min-width: ${(props) => (props.autoWidth ? 'none' : '36.125rem')};
  max-width: ${(props) => (props.autoWidth ? 'none' : '61rem')};
  padding: ${(props) => (props.noPadding ? '0' : '1.25em')};
  background-color: ${COLORS.WHITE};
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5), 0 20px 40px -20px rgba(0, 0, 0, 0.67);
  max-height: 95vh;
  text-align: left;
`;

export default OverlayBaseForm;
