import React, { useState } from 'react';
import { customFetch } from 'utils/fetch';

import Routes from 'routes';
import ConfirmationOverlay from 'shared/ConfirmationOverlay';
import Can from 'components/shared/Can';

export interface LotAnnulButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  id: string;
  identifier: string;
}

const LotAnnulButton: React.FC<LotAnnulButtonProps> = ({ id, identifier }) => {
  const [showOverlay, setOverlay] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleOnAnnulClick = () => {
    setOverlay(true);
  };

  const handleOnPrimaryClick = () => {
    setSubmitted(true);
    customFetch(Routes.annul_api_v1_lot_path(id)).then(() => {
      window.location.reload();
    });
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  return (
    <Can I='annul' a='API::V1::Lot'>
      {showOverlay && (
        <ConfirmationOverlay
          title={I18n.translate('traceability.annul.confirm.title', {
            identifier,
          })}
          confirmText={I18n.translate('traceability.annul.confirm.primary')}
          onConfirmClick={handleOnPrimaryClick}
          primaryDisabled={submitted}
          cancelText={I18n.translate('traceability.annul.confirm.secondary')}
          onCancelClick={handleOnSecondaryClick}
        />
      )}

      <button
        onClick={handleOnAnnulClick}
        type='button'
        className='button button--small button--ghost'
        data-cy='traceability_annul_button'
      >
        {I18n.translate('traceability.annul.button')}
      </button>
    </Can>
  );
};

export default LotAnnulButton;
