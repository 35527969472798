import { computed, observable, action } from 'mobx';

import type { IIcoImport, IcoImportState } from 'exports/types/api';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';
import * as API from 'exports/types/api';

export type IcoWithIcoImport = Pick<
  API.IIco,
  | 'id'
  | 'identifier'
  | 'weight'
  | 'origin_country_code'
  | 'destination_port_name'
  | 'destination_country_code'
> &
  PartialPick<IIcoImport, 'destination_warehouse_id' | 'bin' | 'arrival_date' | 'state'>;

class IcoImport {
  @observable id?: number;
  @observable destinationWarehouseId?: number;
  @observable bin: string;
  @observable arrivalDate: string;
  @observable state: IcoImportState;

  public identifier: string;
  public weight: string;
  public originCountryCode?: string;
  public destinationPortName?: string;
  public destinationCountryCode?: string;

  constructor({
    id,
    identifier,
    destination_warehouse_id,
    bin = '',
    state = 'new',
    arrival_date = new Date().toISOString().split('T')[0],
    weight = '',
    origin_country_code,
    destination_port_name,
    destination_country_code,
  }: IcoWithIcoImport) {
    this.id = id;
    this.identifier = identifier;
    this.destinationWarehouseId = destination_warehouse_id;
    this.bin = bin;
    this.arrivalDate = arrival_date;
    this.state = state;
    this.weight = weight;
    this.originCountryCode = origin_country_code;
    this.destinationPortName = destination_port_name;
    this.destinationCountryCode = destination_country_code;
  }

  @action public setDestinationWarehouse = (id: number) => {
    this.destinationWarehouseId = id;
  };

  @action public setBin = (value: string) => {
    this.bin = value;
  };

  @action public setArrivalDate = (value: string) => {
    this.arrivalDate = value;
  };

  @action public createOrUpdateIcoImport = () => {
    const icoImportPayload = {
      ico_id: this.id,
      destination_warehouse_id: this.destinationWarehouseId,
      arrival_date: this.arrivalDate,
      bin: this.bin,
    };

    customFetch(
      Routes.create_or_update_api_v1_exports_ico_imports_path(),
      { ico_import: icoImportPayload },
      'POST'
    ).then((response) => {
      if (response.success === true) {
        this.state = 'closed';
      }
    });
  };

  @computed get isClosed(): boolean {
    return this.state === 'closed';
  }

  @computed get isCloseable(): boolean {
    return !!(this.arrivalDate && this.destinationWarehouseId && this.bin);
  }
}

export default IcoImport;
