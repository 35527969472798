import styled from 'styled-components';

import COLORS from 'constants/colors';

export const Detail = styled.div<{
  align?: 'left' | 'center' | 'right';
}>`
  align-self: center;
  text-align: ${({ align }) => (align ? align : 'left')};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DateDetail = styled(Detail)`
  font-size: 0.875em;
  font-weight: 400;
  color: ${COLORS.BLACK_70};
`;

export const PrimaryDetail = styled(Detail)`
  font-size: 1.125em;
  font-weight: 400;
`;

export const SecondaryDetail = styled(PrimaryDetail)`
  color: ${COLORS.GRAY};
`;

export const CenteredSecondaryDetail = styled(SecondaryDetail)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IdentifierDetailLink = styled(Detail)`
  font-family: 'Courier Prime';
  color: ${COLORS.BLUE};

  &:hover {
    cursor: pointer;
  }
`;

export const IdentifierDetail = styled(Detail)`
  font-family: 'Courier Prime';
  color: ${COLORS.BLACK};
  white-space: pre-line;
  line-height: 1em;
`;

export const DisclosureDetail = styled(Detail)`
  &:hover {
    cursor: pointer;
  }

  & > svg {
    color: ${COLORS.BLUE};
  }
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 0.5em;
  right: 7em;
`;

export default Detail;
