import { AbilityContext } from 'components/shared/Can';
import React, { useState } from 'react';
import Ability from 'utils/permissions/ability';
import RoastingOverlay, { RoastingOverlayProps, Validations } from './RoastingOverlay';
import RoastingOverlayButton from './RoastingOverlayButton';

export interface RoastingProps extends Omit<RoastingOverlayProps, 'reroast'> {
  text: string;
  status: boolean;
  icon: string;
  showReroast?: boolean;
  showEnterRoasting?: boolean;
  validations?: Validations;
}

function Roasting({
  onPrimaryClick,
  onSecondaryClick,
  roasting,
  status,
  text,
  icon,
  showReroast = true,
  showEnterRoasting = false,
  ...props
}: RoastingProps) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [reroast, setReroast] = useState(false);

  const handleOnPrimaryClick = (event: React.MouseEvent, values: {}) => {
    setShowOverlay(false);

    if (onPrimaryClick) {
      onPrimaryClick(event, values);
    }
  };

  const handleOnSecondaryClick = (event: React.MouseEvent) => {
    setShowOverlay(false);

    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
  };

  const handleOnButtonClick = (state: boolean) => {
    setReroast(state);
    setShowOverlay(true);
  };

  return (
    <>
      {showOverlay && (
        <RoastingOverlay
          roasting={roasting}
          reroast={reroast}
          onPrimaryClick={handleOnPrimaryClick}
          onSecondaryClick={handleOnSecondaryClick}
          {...props}
        />
      )}
      <AbilityContext.Provider value={Ability}>
        <RoastingOverlayButton
          roasting={roasting}
          onClick={(state: boolean) => handleOnButtonClick(state)}
          status={status}
          text={text}
          icon={icon}
          showReroast={showReroast}
          showEnterRoasting={showEnterRoasting}
        />
      </AbilityContext.Provider>
    </>
  );
}

export default Roasting;
