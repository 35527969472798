import { Provider } from 'mobx-react';
import React from 'react';

import { likeFilter } from 'utils/filter';
import FilterContextProvider from 'utils/filterContext';

import TransportLegIndex from 'transports/components/TransportLegIndex';
import TransportStore, { Transportables } from 'transports/stores/TransportStore';

interface TransportLegIndexContainerProps {
  transportables: Transportables;
  sortable?: boolean;
}

const filterConfiguration = {
  local_identifier: {
    filter: likeFilter,
    value: undefined,
  },
};

const TransportLegIndexContainer = ({
  transportables,
  sortable = true,
}: TransportLegIndexContainerProps) => (
  <Provider transportStore={new TransportStore(transportables, sortable)}>
    <FilterContextProvider value={filterConfiguration}>
      <TransportLegIndex />
    </FilterContextProvider>
  </Provider>
);

export default TransportLegIndexContainer;
