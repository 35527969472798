import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { belowUpperBound, isInBound } from 'utils/validators';

import { VARIANT } from 'constants/inputTypes';

import InputGroupComponent from 'components/groups/InputGroup';
import TextareaGroupComponent from 'components/groups/TextareaGroup';
import { ReasonCheckbox } from 'shared/Checkbox';

import { Icons } from 'shared/Checkbox';

import useStores from 'utils/useStores';
import SpaceBetween from 'components/elements/SpaceBetween';
import { ReasonGet } from 'types/model/reason';

export interface Validation {
  max: string;
}

interface PhysicalAnalysisProps {
  reasons: ReasonGet[];
  validations: {
    mermaPercentage: Validation;
    sieve0Percentage: Validation;
    sieve12Percentage: Validation;
    sieve14Percentage: Validation;
    sieve15Percentage: Validation;
    factor14: Validation;
    factor15: Validation;
    group1Percentage: Validation;
    group2Percentage: Validation;
    brocaPercentage: Validation;
    paloteoPercentage: Validation;
    ripioPercentage: Validation;
    guayabasPercentage: Validation;
    caracolPercentage: Validation;
  };
}

const PhysicalAnalysisComponent = ({ reasons, validations }: PhysicalAnalysisProps) => {
  const { overlayStore, physicalAnalysisStore } = useStores();
  const [errors, setErrors] = useState<string[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setter: any) => {
    setter(e.target.value);
  };

  const belowUpperBoundValidator = (
    standardValue: string,
    currentValue: string,
    inputValue?: string
  ) => {
    let validator = true;

    if (validations[standardValue].max !== null && inputValue !== '') {
      validator = belowUpperBound(
        parseFloat(physicalAnalysisStore[currentValue]),
        parseFloat(validations[standardValue].max)
      );
    }

    if (!validator) {
      if (!errors.includes(standardValue)) {
        setErrors([...errors, standardValue]);
      }
    } else {
      setErrors(errors.filter((error) => error !== standardValue));
    }
    return validator;
  };

  useEffect(() => {
    overlayStore.setValid(errors.length === 0);
  }, [errors]);

  useEffect(() => {
    if (physicalAnalysisStore.acceptingReasonId) {
      overlayStore.setReasonId(physicalAnalysisStore.acceptingReasonId);
      overlayStore.setValid(false);
      overlayStore.setOverrideValidation(true);
    }
  }, [physicalAnalysisStore.acceptingReasonId]);

  useEffect(() => {
    if (overlayStore.isValid && overlayStore.overrideValidation) {
      overlayStore.setOverrideValidation(false);
    }
  }, [overlayStore.isValid]);

  useEffect(() => {
    const validFactor14 =
      belowUpperBound(
        parseFloat(physicalAnalysisStore.factor14) || 0,
        parseFloat(validations.factor14.max)
      ) || !validations.factor14.max;

    const validFactor15 =
      belowUpperBound(
        parseFloat(physicalAnalysisStore.factor15),
        parseFloat(validations.factor15.max)
      ) || !validations.factor15.max;

    let tmpErrors = errors;

    if (!validFactor14) {
      if (!errors.includes('factor14')) {
        tmpErrors = [...errors, 'factor14'];
      }
    } else {
      if (errors.includes('factor14')) {
        tmpErrors = tmpErrors.filter((error) => error !== 'factor14');
      }
    }

    if (!validFactor15) {
      if (!errors.includes('factor15')) {
        tmpErrors = [...tmpErrors, 'factor15'];
      }
    } else {
      if (errors.includes('factor15')) {
        tmpErrors = tmpErrors.filter((error) => error !== 'factor15');
      }
    }

    setErrors(tmpErrors);
  }, [physicalAnalysisStore.sieve14, physicalAnalysisStore.sieve15]);

  const greenValidator = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validator = isInBound(parseFloat(e.target.value), 1, 300);

    if (!validator) {
      if (!errors.includes('green')) {
        setErrors([...errors, 'green']);
      }
    } else {
      setErrors(errors.filter((error) => error !== 'green'));
    }

    return validator;
  };

  const sieveSumValidator = () => {
    return (
      parseFloat(physicalAnalysisStore.sieveSum) >=
        parseFloat(physicalAnalysisStore.green) *
          (1 - physicalAnalysisStore.sumTolerance / 100) &&
      parseFloat(physicalAnalysisStore.sieveSum) <=
        parseFloat(physicalAnalysisStore.green)
    );
  };

  overlayStore.setFilled(true);

  return (
    <>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
        >
          <InputGroupComponent
            label={I18n.translate('attributes.green')}
            name='green'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.green || ''}
            placeholder='0.00'
            validator={greenValidator}
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setGreen)}
            required={true}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.merma')}
            name='merma'
            append='%'
            value={physicalAnalysisStore.mermaPercentage}
            validator={() =>
              belowUpperBoundValidator('mermaPercentage', 'mermaPercentage')
            }
            readOnly
            disabled
          />
          <InputGroupComponent
            label={I18n.translate('attributes.sieve_sum')}
            append='Σ'
            value={physicalAnalysisStore.sieveSum}
            validator={() => sieveSumValidator()}
            readOnly
            disabled
          />
          <span className='empty-cell' />
          <InputGroupComponent
            label={I18n.translate('attributes.sieve_0')}
            name='sieve_0'
            infoname='sieve_0_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.sieve0 || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.sieve0Percentage
                ? `${physicalAnalysisStore.sieve0Percentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'sieve0Percentage',
                'sieve0Percentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setSieve0)}
            required={true}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.sieve_12')}
            name='sieve_12'
            infoname='sieve_12_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.sieve12 || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.sieve12Percentage
                ? `${physicalAnalysisStore.sieve12Percentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'sieve12Percentage',
                'sieve12Percentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setSieve12)}
            required={true}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.sieve_14')}
            name='sieve_14'
            infoname='sieve_14_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.sieve14 || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.sieve14Percentage
                ? `${physicalAnalysisStore.sieve14Percentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'sieve14Percentage',
                'sieve14Percentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setSieve14)}
            required={true}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.sieve_15_18')}
            name='sieve_15'
            infoname='sieve_15_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.sieve15 || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.sieve15Percentage
                ? `${physicalAnalysisStore.sieve15Percentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'sieve15Percentage',
                'sieve15Percentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setSieve15)}
            required={true}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.factor_14')}
            name='factor_14'
            validator={() => belowUpperBoundValidator('factor14', 'factor14')}
            value={physicalAnalysisStore.factor14}
            readOnly
          />
          <InputGroupComponent
            label={I18n.translate('attributes.factor_15')}
            name='factor_15'
            validator={() => belowUpperBoundValidator('factor15', 'factor15')}
            value={physicalAnalysisStore.factor15}
            readOnly
          />
          <InputGroupComponent
            label={I18n.translate('attributes.yield_14')}
            name='yield_14'
            value={physicalAnalysisStore.yield14}
            readOnly
          />
          <InputGroupComponent
            label={I18n.translate('attributes.yield_15')}
            name='yield_15'
            value={physicalAnalysisStore.yield15}
            readOnly
          />
        </div>
      </div>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
        >
          <InputGroupComponent
            label={I18n.translate('attributes.group_1')}
            name='group_1'
            infoname='group_1_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.group1 || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.group1Percentage
                ? `${physicalAnalysisStore.group1Percentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'group1Percentage',
                'group1Percentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setGroup1)}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.group_2')}
            name='group_2'
            infoname='group_2_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.group2 || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.group2Percentage
                ? `${physicalAnalysisStore.group2Percentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'group2Percentage',
                'group2Percentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setGroup2)}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.broca')}
            name='broca'
            infoname='broca_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.broca || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.brocaPercentage
                ? `${physicalAnalysisStore.brocaPercentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'brocaPercentage',
                'brocaPercentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setBroca)}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.paloteo')}
            name='paloteo'
            infoname='paloteo_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.paloteo || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.paloteoPercentage
                ? `${physicalAnalysisStore.paloteoPercentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'paloteoPercentage',
                'paloteoPercentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setPaloteo)}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.ripio')}
            name='ripio'
            infoname='ripio_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.ripio || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.ripioPercentage
                ? `${physicalAnalysisStore.ripioPercentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'ripioPercentage',
                'ripioPercentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setRipio)}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.guayabas')}
            name='guayabas'
            infoname='guayabas_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.guayabas || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.guayabasPercentage
                ? `${physicalAnalysisStore.guayabasPercentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'guayabasPercentage',
                'guayabasPercentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setGuayabas)}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.caracol')}
            name='caracol'
            infoname='caracol_percentage'
            variant={VARIANT.DECIMAL}
            append='gr'
            value={physicalAnalysisStore.caracol || ''}
            placeholder='0.00'
            info={
              physicalAnalysisStore.caracolPercentage
                ? `${physicalAnalysisStore.caracolPercentage}%`
                : '--'
            }
            validator={(e) =>
              belowUpperBoundValidator(
                'caracolPercentage',
                'caracolPercentage',
                e.target.value
              )
            }
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setCaracol)}
          />
          <InputGroupComponent
            label={I18n.translate('attributes.density')}
            name='density'
            variant={VARIANT.DECIMAL}
            placeholder='0.00'
            value={physicalAnalysisStore.density || ''}
            onChange={(e) => handleInputChange(e, physicalAnalysisStore.setDensity)}
          />
        </div>
      </div>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(18em, 1fr))' }}
        >
          <div className='input-group'>
            <TextareaGroupComponent
              placeholder='max. 200 characters'
              data-cy='physical_analysis_observations'
              name='observations'
              id='observations'
              label={I18n.translate('attributes.observations')}
              value={physicalAnalysisStore.observations || ''}
              onChange={(e) => physicalAnalysisStore.setObservations(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='fieldset'>
        <div
          className='l-auto-fill-grid'
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(9em, 1fr))' }}
        >
          <ReasonCheckbox
            reasons={reasons}
            name='reject_reason'
            icon={Icons.cross}
            label={I18n.translate('attributes.rejected')}
          />
        </div>
      </div>
      <div className='fieldset'>
        <SpaceBetween>
          <button
            type='button'
            className='button button--gray'
            onClick={() => window.history.back()}
          >
            {I18n.translate('buttons.cancel')}
          </button>
          <button
            type='submit'
            name='commit'
            disabled={!sieveSumValidator()}
            className='button'
            data-cy='save_physical_analysis'
            data-disable-with='Save'
          >
            {I18n.translate('buttons.save')}
          </button>
        </SpaceBetween>
      </div>
    </>
  );
};

export default observer(PhysicalAnalysisComponent);
