import { LotGet } from 'types/model/lot';
import { LotSetGet } from 'types/model/lot_set';
import LostCoffee from 'types/model/lostCoffee';
import { Transportable } from 'transports/stores/TransportStore';
import type DecafIco from 'decaf/types/Ico';

function savelyDeriveFloat(value: string | number): number {
  return parseFloat(value.toString());
}

export default function mapTransportableAttributes(
  transportable: LotGet | LotSetGet | LostCoffee | DecafIco
): Transportable {
  switch (transportable.type) {
    case 'lot':
    case 'lot_set': {
      return {
        ...transportable,
        producer: transportable.producer,
        weight: transportable.weight,
        certification: transportable.certification,
        quality: transportable.quality,
        localIdentifier: transportable.local_identifier,
        conventional: transportable.agreed_to_no_premium_price,
        bags: transportable.number_calibrated_bags,
        weightString: transportable.weight_string,
      };
    }
    case 'lost_coffee': {
      const origin = transportable.lot || transportable.lot_set;

      return {
        ...transportable,
        lostCoffee: true,
        localIdentifier: origin.local_identifier,
        producer: origin.producer,
        certification: origin.certification,
        conventional: origin.agreed_to_no_premium_price,
        quality: origin.quality,
        weightString: transportable.weight_string,
      };
    }
    case 'ico': {
      const weightString = `${transportable.weight.value} ${transportable.weight.unit}`;

      return {
        ...transportable,
        localIdentifier: transportable.main_identifier,
        producer: '-',
        certification: transportable.certificate,
        quality: transportable.quality,
        bags: parseInt(transportable.units),
        weight: savelyDeriveFloat(transportable.weight.value),
        weightString,
      };
    }
  }
}
