import styled from 'styled-components';

const OverlayWrapper = styled.div`
  position: fixed;
  z-index: 800;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);

  .l-item-stack-horizontal > *:not(:last-child) {
    margin-right: 1.25em;
  }
`;

export default OverlayWrapper;
