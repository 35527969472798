import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';

import FullscreenSensorialAnalysis from './FullscreenSensorialAnalysis';

import { COLORS } from 'constants/colors';
import Icon from 'components/elements/Icon';

const FullscreenCuppingSession: React.FC<{}> = () => {
  const { cuppingSessionStore } = useStores();

  const length = cuppingSessionStore?.sensorialAnalyses.length;
  return (
    <>
      <section className='sensorial-analyses__overflow-container'>
        <div
          className='sensorial-analyses'
          style={{
            gridTemplateColumns: `13.25rem repeat(${length}, minmax(auto, 28rem))`,
            // note: the number of headings used here
            gridTemplateRows: `repeat(10, auto)`,
            width: `${length * 28}rem`,
          }}
        >
          <div className='sensorial-analyses__corner' />

          <div className='sensorial-analyses__row-head sensorial-analyses__row-head--score'>
            {I18n.translate('sensorial_analysis.score')}
          </div>

          <div className='sensorial-analyses__row-head sensorial-analyses__row-head--flavour'>
            {I18n.translate('sensorial_analysis.flavour')}

            <div className='cupping-card__flavors-info cupping-card__flavors-info--fullscreen'>
              <Icon icon='info' className='icon--small' color={COLORS.BLUE} />

              <span>{I18n.translate('sensorial_analysis.minimum_flavours')}</span>
            </div>
          </div>

          <div className='sensorial-analyses__row-head'>
            {I18n.translate('sensorial_analysis.overall')}
          </div>

          <div className='sensorial-analyses__row-head'>
            {I18n.translate('sensorial_analysis.body')}
          </div>

          <div className='sensorial-analyses__row-head'>
            {I18n.translate('sensorial_analysis.alternatives')}
          </div>

          <div className='sensorial-analyses__row-head'>
            {I18n.translate('sensorial_analysis.defects')}
          </div>

          <div className='sensorial-analyses__row-head'>
            {I18n.translate('sensorial_analysis.roast')}
          </div>

          <div className='sensorial-analyses__row-head sensorial-analyses__row-head--observations'>
            {I18n.translate('sensorial_analysis.observations')}
          </div>

          <div className='sensorial-analyses__row-head sensorial-analyses__row-head--conclusion'>
            {I18n.translate('sensorial_analysis.conclusion')}
          </div>

          {cuppingSessionStore?.sensorialAnalyses.map(
            (sensorialAnalysis, index, array) => (
              <FullscreenSensorialAnalysis
                key={index}
                sensorialAnalysis={sensorialAnalysis}
                lotsCount={array.length}
                count={index + 1}
                cuppingSessionEnabled={cuppingSessionStore?.cuppingSessionEnabled}
              />
            )
          )}
        </div>
      </section>
      <div className='sensorial-analyses__fullscreen-button-container'>
        <a className='button button--cancel' onClick={() => window?.history.back()}>
          {I18n.translate('buttons.cancel')}
        </a>
        <input
          type='submit'
          name='commit'
          value={I18n.translate('buttons.save')}
          className='button'
          data-cy='cupping_session_save'
          disabled={
            !cuppingSessionStore?.cuppingSessionEnabled &&
            !cuppingSessionStore?.allAnalysisCompleted
          }
        />

        {cuppingSessionStore?.cuppingSessionEnabled && (
          <input
            type='submit'
            name='commit'
            value={I18n.translate('buttons.finalize')}
            className='button'
            data-cy='cupping_session_save'
            style={{ marginLeft: '0.25 rem' }}
            disabled={!cuppingSessionStore?.allAnalysisCompleted}
          />
        )}
      </div>
    </>
  );
};

export default observer(FullscreenCuppingSession);
