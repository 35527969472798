import { observer } from 'mobx-react';
import React from 'react';
import Routes from 'routes';

import useStores from 'utils/useStores';

import ContentHeader from 'components/elements/ContentHeader';

import SensorialAnalysis from './SensorialAnalysis';

function CuppingSession(): JSX.Element {
  const { cuppingSessionStore } = useStores();
  return (
    <>
      <ContentHeader title={I18n.translate('headlines.cupping_session')}>
        <a className='button button--cancel' onClick={() => window?.history.back()}>
          {I18n.translate('buttons.cancel')}
        </a>

        <a
          className='button'
          data-cy='fullscreen-cupping-session'
          href={Routes.horizontal_session_sensorial_analyses_path({
            samples: cuppingSessionStore.sampleIdsForFullScreenViewLink,
            cupping_table_id: cuppingSessionStore.cuppingTableId,
          })}
        >
          {I18n.translate('sensorial_analysis.fullscreen_view')}
        </a>

        <input
          type='submit'
          name='commit'
          value={I18n.translate('buttons.save')}
          className='button'
          data-cy='cupping_session_save'
          disabled={
            !cuppingSessionStore?.cuppingSessionEnabled &&
            !cuppingSessionStore?.allAnalysisCompleted
          }
        />

        {cuppingSessionStore?.cuppingSessionEnabled && (
          <input
            type='submit'
            name='commit'
            value={I18n.translate('buttons.finalize')}
            className='button'
            data-cy='cupping_session_save'
            disabled={!cuppingSessionStore?.allAnalysisCompleted}
          />
        )}
      </ContentHeader>

      <div className='l-item-stack'>
        {cuppingSessionStore?.sensorialAnalyses.map((sensorialAnalysis, index, array) => (
          <SensorialAnalysis
            key={index}
            sensorialAnalysis={sensorialAnalysis}
            lotsCount={array.length}
            count={index + 1}
            cuppingSessionEnabled={cuppingSessionStore?.cuppingSessionEnabled}
          />
        ))}
      </div>
    </>
  );
}

export default observer(CuppingSession);
