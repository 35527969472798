import React from 'react';
import styled from 'styled-components';
import ToolTip from 'shared/ToolTip/components/ToolTip';

import { observer } from 'mobx-react';

import TableData from 'customerSamples/components/TableData';
import CustomerSampleValue from 'customerSamples/models/CustomerSampleValue';
import IconWrapper from 'customerSamples/components/IconWrapper';
import FlexContentWrapper from 'customerSamples/components/FlexContentWrapper';

export interface CustomerSampleAnalysisProps {
  customerSampleValue?: CustomerSampleValue;
  customerSampleIdentifier: string;
}

const ScoreWrapper = styled(FlexContentWrapper)`
  max-width: 3.5rem;
`;

function CustomerSampleAnalysis({
  customerSampleIdentifier,
  customerSampleValue,
}: CustomerSampleAnalysisProps): JSX.Element {
  return (
    <>
      <TableData className='align-center'>
        {customerSampleValue?.humidityAnalysisPercentage
          ? `${customerSampleValue.humidityAnalysisPercentage}%`
          : '-'}
      </TableData>

      <TableData className='align-center'>
        {customerSampleValue?.waterAnalysisPercentage
          ? customerSampleValue.waterAnalysisPercentage
          : '-'}
      </TableData>

      <TableData className='align-center border-right'>
        <ScoreWrapper>
          <span>{customerSampleValue?.sensorialScore}</span>
          {/* Render Span if no observations for only-child css rule */}
          {!customerSampleValue?.observations && <span />}
          {customerSampleValue?.sensorialScore && customerSampleValue?.observations && (
            <IconWrapper>
              <ToolTip
                title={customerSampleIdentifier}
                icon='info'
                infos={[
                  {
                    label: I18n.translate('customer_samples_dashboard.observations'),
                    value: customerSampleValue?.observations,
                  },
                ]}
              />
            </IconWrapper>
          )}
        </ScoreWrapper>
      </TableData>
    </>
  );
}

export default observer(CustomerSampleAnalysis);
