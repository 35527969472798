import React from 'react';
import { useFormContext } from 'react-hook-form';
import useArrivalSampleIcos from 'samples/create/components/arrival_sample/useArrivalSampleIcos';
import SampleDestinationWarehouseSelect from 'samples/create/components/SampleDestinationWarehouseSelect';
import WSRArrivalSampleSelectionTable from './arrival_sample/WSRArrivalSampleSelectionTable';

const CreateArrivalSample = (): JSX.Element => {
  const { watch, register } = useFormContext();

  const destinationWarehouseId = watch(
    'warehouse_sample_request[destination_warehouse_id]'
  );

  const { icos } = useArrivalSampleIcos(destinationWarehouseId);

  return (
    <>
      <input
        type='hidden'
        value='sample_arrival'
        name='warehouse_sample_request[sample_type]'
        ref={register}
      />
      <div className='l-item-stack-horizontal l-item-stack-horizontal--wide'>
        <SampleDestinationWarehouseSelect />
      </div>
      <WSRArrivalSampleSelectionTable icos={icos} />
    </>
  );
};
export default CreateArrivalSample;
