import { observable } from 'mobx';
import Sample from 'customerSamples/models/Sample';

import type OpportunityType from 'customerSamples/types/OpportunityType';
import SampleRowEntity from './SampleRowEntity';
import Contract from 'customerSamples/models/Contract';

export class Opportunity extends SampleRowEntity {
  @observable public samples: Sample[];
  public contract?: Contract;

  constructor(opportunity: OpportunityType) {
    super(opportunity);

    if (opportunity.contract) {
      this.contract = new Contract(opportunity.contract);
    }

    const samples = opportunity.samples?.map((sample) => new Sample(sample));
    this.samples = observable.array(samples);
  }
}

export default Opportunity;
