import React, { useState } from 'react';
import styled from 'styled-components';

import COLORS from 'constants/colors';

import InputGroup from 'components/groups/InputGroup';
import FormButton from './FormButton';

const RowWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${COLORS.BORDERS};
  padding-bottom: 1rem;
`;

const DateText = styled.div`
  padding-right: 1.875rem;
  font-size: 1rem;
  font-weight: bold;
`;

const DateWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`;
const DateInput = styled.div`
  .input-group {
    margin-bottom: 0;
  }

  input {
    width: 11rem;
    text-align: center;
    font-size: 1rem;
  }
`;

export interface ShipmentDateProps {
  text: string;
  value?: string;
  disabled?: boolean;
  onSave: (date: string) => void;
}

const ShipmentDate = ({ text, value, onSave, disabled = false }: ShipmentDateProps) => {
  const [shipmentDate, setShipmentDate] = useState(value);
  const onChangeHandler = (e) => {
    setShipmentDate(e.target.value);
  };

  return (
    <RowWrapper>
      <DateWrapper>
        <DateText>{text}</DateText>

        <DateInput>
          <InputGroup
            disabled={disabled}
            id='shipment_date'
            defaultValue={shipmentDate}
            type='date'
            onChange={onChangeHandler}
          />
        </DateInput>
      </DateWrapper>
      <FormButton>
        <button
          disabled={disabled}
          type='button'
          className='button'
          onClick={() => shipmentDate && onSave(shipmentDate)}
        >
          {I18n.translate('buttons.save')}
        </button>
      </FormButton>
    </RowWrapper>
  );
};

export default ShipmentDate;
