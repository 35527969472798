import React from 'react';
import { observer } from 'mobx-react';

import type { AccordionHeaderProps } from 'shared/Accordion/components/AccordionWrapper';
import AccordionFormHeader from '../AccordionFormHeader';

export interface TransportHeaderProps extends AccordionHeaderProps {
  completed?: boolean;
}

const TransportHeader = ({ expanded, completed = false }: TransportHeaderProps) => (
  <AccordionFormHeader
    expanded={expanded}
    headline={I18n.translate('exports.form.transport.header')}
    completed={completed}
  />
);

export default observer(TransportHeader);
