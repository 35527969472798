import React, { useState } from 'react';
import Routes from 'routes';

import RepriceOverlay from './RepriceOverlay';
import { customFetch } from 'utils/fetch';

export interface RepriceButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  lot_id: string;
  final_price_id: string;
  currency_symbol: string;
}

const RepriceButton: React.FC<RepriceButtonProps> = ({
  lot_id,
  final_price_id,
  currency_symbol,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const handleOnClick = () => setShowOverlay(true);
  const handleOnCloseClick = () => setShowOverlay(false);
  const handleOnPrimaryClick = async (event: React.MouseEvent, price: string) => {
    try {
      await customFetch(
        Routes.reprice_api_v1_lot_final_price_path(lot_id, final_price_id),
        { price },
        'POST'
      );

      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {showOverlay && (
        <RepriceOverlay
          onPrimaryClick={handleOnPrimaryClick}
          onCloseClick={handleOnCloseClick}
          currencySymbol={currency_symbol}
        />
      )}

      <button
        onClick={handleOnClick}
        type='button'
        className='button button--small button--ghost'
        data-cy='decline_price'
      >
        {I18n.translate('accept_price.index.reprice.button')}
      </button>
    </>
  );
};

export default RepriceButton;
