import React from 'react';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';

import AdjustWeightOverlay from './AdjustWeightOverlay';

export interface AdjustWeightProps extends React.HTMLAttributes<HTMLDivElement> {
  currentWeight: number;
  sampleId: number;
  identifier: string;
}

const AdjustWeight: React.FC<AdjustWeightProps> = ({
  currentWeight,
  sampleId,
  identifier,
}) => {
  const [showOverlay, setShowOverlay] = React.useState(false);

  const handleOnPrimaryClick = (event: React.MouseEvent, values: { weight: number }) => {
    customFetch(Routes.adjust_weight_api_v1_sample_path(sampleId), values).then(() => {
      setShowOverlay(false);
      window.location.reload();
    });
  };

  const handleOnSecondaryClick = (_event: React.MouseEvent) => {
    setShowOverlay(false);
  };

  const handleOnButtonClick = (_event: React.MouseEvent) => {
    setShowOverlay(true);
  };

  return (
    <>
      {showOverlay && (
        <AdjustWeightOverlay
          title={`${identifier} - ${I18n.translate('buttons.adjust_weight')}`}
          currentWeight={currentWeight}
          onPrimaryClick={handleOnPrimaryClick}
          onSecondaryClick={handleOnSecondaryClick}
        />
      )}

      <button className='button button--small' onClick={handleOnButtonClick}>
        {I18n.translate('buttons.adjust_weight')}
      </button>
    </>
  );
};

export default AdjustWeight;
