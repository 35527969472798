import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import COLORS from 'constants/colors';
import Grid from 'components/elements/Grid';
import InputGroup from 'components/groups/InputGroup';
import Checkbox from 'shared/Checkbox';

import ResponsibilityLetter from '../../models/ResponsibilityLetter';
import type ResponsibilityLetterType from '../../types/responsibilityLetter';

import FormFieldSet from '../FormFieldSet';
import AccordionFormBody from '../AccordionFormBody';

export interface ResponsiblityLetterProps {
  responsibilityLetter: ResponsibilityLetter;
  vesselName?: string;
  voyageNumber?: string;
  transportCompany?: string;
}

const ResponsiblityLetterWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

const ResponsiblityLetter = ({
  responsibilityLetter,
  vesselName,
  voyageNumber,
  transportCompany,
}: ResponsiblityLetterProps) => {
  const {
    register,
    formState: { isSubmitting, isDirty },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: responsibilityLetter,
  });

  const handleOnSubmitClick = handleSubmit(async (data: ResponsibilityLetterType) => {
    await responsibilityLetter.save(data);

    reset(data);
  });

  const handleOnFinalizeClick = handleSubmit(async () => {
    await responsibilityLetter.finalize();
  });

  return (
    <ResponsiblityLetterWrapper>
      <AccordionFormBody
        isFinalizable={responsibilityLetter.isFinalizable}
        isDirty={isDirty || responsibilityLetter.id == null}
        isSubmitting={isSubmitting}
        finalized={responsibilityLetter.finalized}
        onSubmitClick={handleOnSubmitClick}
        onFinalizeClick={handleOnFinalizeClick}
        downloadUrl={undefined}
      >
        <FormFieldSet disabled={responsibilityLetter.finalized}>
          <Grid grid={16}>
            <InputGroup
              label={I18n.translate(
                'exports.form.responsibility_letter.vessel_name_label'
              )}
              disabled={true}
              value={vesselName}
            />

            <InputGroup
              label={I18n.translate(
                'exports.form.responsibility_letter.voyage_number_label'
              )}
              disabled={true}
              value={voyageNumber}
            />

            <InputGroup
              id='transport_company'
              label={I18n.translate(
                'exports.form.responsibility_letter.transport_company_label'
              )}
              disabled={true}
              value={transportCompany}
            />

            <Checkbox
              label={I18n.translate('exports.form.responsibility_letter.is_vuce_label')}
              icon='tick'
              ref={register}
              name='is_vuce'
              checked={responsibilityLetter.is_vuce}
            />
          </Grid>
        </FormFieldSet>
      </AccordionFormBody>
    </ResponsiblityLetterWrapper>
  );
};

export default observer(ResponsiblityLetter);
