import React from 'react';
import styled from 'styled-components';

import { Grid, OverflowContainer } from 'shared/ScrollableTable';
import Filter from 'exports/components/Filter';
import TableHeader from 'exports/components/TableHeader';
import TableData from 'exports/components/TableData';
import HarbourSelectOverlay from 'exports/components/HarbourSelectOverlay';

export interface ExportsProps {
  columnCount: number;
}

const GridWrapper = styled(Grid)`
  height: calc(100% - 4rem);
`;

const Exports = ({ columnCount }: ExportsProps) => {
  return (
    <OverflowContainer>
      <Filter />

      <GridWrapper columns={columnCount}>
        <TableHeader />

        <TableData />

        <HarbourSelectOverlay />
      </GridWrapper>
    </OverflowContainer>
  );
};

export default Exports;
