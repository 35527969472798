import SpecialtyGradingSpecificationType from 'types/model/specialtyGradingSpecification';

class SpecialtyGradingSpecification {
  public name: string;
  public fullDefectEquivalent: number;
  public gradingIdentifier: string;

  constructor(specialtyGradingSpecification: SpecialtyGradingSpecificationType) {
    this.name = specialtyGradingSpecification.name;
    this.fullDefectEquivalent = specialtyGradingSpecification.full_defect_equivalent;
    this.gradingIdentifier = specialtyGradingSpecification.grading_identifier;
  }
}

export default SpecialtyGradingSpecification;
