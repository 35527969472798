import { action, computed, observable } from 'mobx';
import { deserialise } from 'kitsu-core';

import type IDestinationPort from '../types/IDestinationPort';
import DestinationPort from '../models/DestinationPort';
import Export from '../models/Export';
import Ico from '../models/Ico';
import Consignee from '../types/consignee';
import CustomsOfficer from '../types/customsOfficer';
import ShippingLine from '../types/shippingLine';
import ShippingMode from '../types/shippingMode';
import SelectOptionArray from 'types/model/selectOption';
import Freight from '../types/freight';
import BusinessPartner from '../types/businessPartner';
import CustomsBroker from '../types/customsBroker';
import Dock from '../types/dock';
import IPort from '../types/IPort';
import TransportCompany from 'types/model/transportCompany';
import ContainerStuffing from 'exports/types/containerStuffing';
import DestinationWarehouse from 'types/model/destinationWarehouse';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';
import { JsonApi } from 'types/utils/jsonApi';
import parseUSVString from 'utils/parseUSVString';
import type IExport from '../types/IExport';

export interface SelectOptions {
  lotTypes: SelectOptionArray;
  consignees: Consignee[];
  customsOfficers: CustomsOfficer[];
  insurers: BusinessPartner[];
  shippingLines: ShippingLine[];
  shippingModes: ShippingMode[];
  freights: Freight[];
  insurances: SelectOptionArray;
  naturesOfOperation: SelectOptionArray;
  paymentForms: SelectOptionArray;
  blPrintInstructions: SelectOptionArray;
  ports: IPort[];
  docks: Dock[];
  customsBrokers: CustomsBroker[];
  transportCompanies: TransportCompany[];
  containerStuffings: ContainerStuffing[];
  containerStuffers: ContainerStuffing[];
  timberBambooPackaging: SelectOptionArray;
  treatmentCertification: SelectOptionArray;
  destinationWarehouses: DestinationWarehouse[];
  years: SelectOptionArray;
  originCountries: SelectOptionArray;
}

class ExportsStore {
  @observable public dates: Array<Date>;
  @observable public selectedExport: Export | undefined;
  @observable public selectedIco: Ico | undefined;
  @observable public selectedDestinationPort: DestinationPort | undefined;
  @observable public showHarbourOverlay: boolean;
  @observable public showShippingOverlay: boolean;
  @observable public shippingTabIndex: number;

  public lotTypes: SelectOptionArray;
  public customsOfficers: CustomsOfficer[];
  public consignees: Consignee[];
  public insurers: BusinessPartner[];
  public shippingLines: ShippingLine[];
  public shippingModes: ShippingMode[];
  public insurances: SelectOptionArray;
  public freights: SelectOptionArray;
  public naturesOfOperation: SelectOptionArray;
  public paymentForms: SelectOptionArray;
  public blPrintInstructions: SelectOptionArray;
  public ports: IPort[];
  public docks: Dock[];
  public customsBrokers: CustomsBroker[];
  public transportCompanies: TransportCompany[];
  public containerStuffings: ContainerStuffing[];
  public containerStuffers: ContainerStuffing[];
  public timberBambooPackaging: SelectOptionArray;
  public treatmentCertification: SelectOptionArray;
  public destinationWarehouses: DestinationWarehouse[];
  public excludedDestinationPortNames = observable.array<string>();
  public destinationPorts = observable.array<DestinationPort>();
  public years: SelectOptionArray;
  public originCountries: SelectOptionArray;
  public filters: { contractExportIco?: string; year?: string; originCountry?: string };

  constructor(
    dates: string[],
    serializedDestinationPorts: JsonApi<IDestinationPort>,
    selectOptions: SelectOptions
  ) {
    this.setFilters();
    this.dates = dates.map((date) => new Date(date));

    this.selectedExport = undefined;
    this.selectedIco = undefined;
    this.selectedDestinationPort = undefined;
    this.showHarbourOverlay = false;
    this.showShippingOverlay = false;
    this.shippingTabIndex = 0;

    this.setupSelectOptions(selectOptions);

    const deserializedDestinationPorts: IDestinationPort[] = deserialise(
      serializedDestinationPorts
    ).data;

    const destinationPorts: DestinationPort[] = deserializedDestinationPorts.map(
      (destinationPort) => new DestinationPort(destinationPort)
    );
    this.destinationPorts = observable.array(destinationPorts);

    this.excludedDestinationPortNames = observable.array([]);
  }

  private setFilters(): void {
    const filters = parseUSVString(window.location.search);

    this.filters = {
      contractExportIco: filters['filters[contract_export_ico]'],
      originCountry: filters['filters[origin_country]'],
      year: filters['filters[year]'],
    };
  }

  private setupSelectOptions({
    lotTypes,
    customsOfficers,
    consignees,
    insurers,
    shippingLines,
    shippingModes,
    insurances,
    freights,
    naturesOfOperation,
    paymentForms,
    blPrintInstructions,
    ports,
    docks,
    customsBrokers,
    transportCompanies,
    containerStuffings,
    timberBambooPackaging,
    treatmentCertification,
    destinationWarehouses,
    years,
    originCountries,
  }: SelectOptions): void {
    this.lotTypes = deserialise(lotTypes).data as SelectOptionArray;
    this.customsOfficers = deserialise(customsOfficers).data as CustomsOfficer[];
    this.consignees = deserialise(consignees).data as Consignee[];
    this.insurers = deserialise(insurers).data as BusinessPartner[];
    this.shippingLines = deserialise(shippingLines).data as ShippingLine[];
    this.shippingModes = deserialise(shippingModes).data as ShippingMode[];
    this.freights = (deserialise(freights).data as Freight[]).map(({ id, charges }) => ({
      id,
      name: charges,
    }));
    this.insurances = deserialise(insurances).data as SelectOptionArray;
    this.naturesOfOperation = deserialise(naturesOfOperation).data as SelectOptionArray;
    this.paymentForms = deserialise(paymentForms).data as SelectOptionArray;
    this.blPrintInstructions = blPrintInstructions;
    this.ports = deserialise(ports).data as IPort[];
    this.docks = deserialise(docks).data as Dock[];
    this.customsBrokers = deserialise(customsBrokers).data as CustomsBroker[];
    this.transportCompanies = deserialise(transportCompanies).data as TransportCompany[];

    this.timberBambooPackaging = timberBambooPackaging;
    this.treatmentCertification = treatmentCertification;
    this.destinationWarehouses = deserialise(destinationWarehouses)
      .data as DestinationWarehouse[];
    this.years = years;
    this.originCountries = originCountries;

    this.setupContainerStuffing(containerStuffings);
  }

  setupContainerStuffing = (containerStuffings: ContainerStuffing[]) => {
    const deserializedContainerStuffings = deserialise(containerStuffings)
      .data as ContainerStuffing[];

    this.containerStuffings = deserializedContainerStuffings.filter(
      ({ stuffing }) => stuffing
    );
    this.containerStuffers = deserializedContainerStuffings.filter(
      ({ stuffer }) => stuffer
    );
  };

  @action public setShowHarbourOverlay = (bool: boolean): void => {
    this.showHarbourOverlay = bool;
  };

  @action public setShowShippingOverlay = (bool: boolean, index?: number): void => {
    this.showShippingOverlay = bool;

    if (bool) {
      this.setSelectedDestinationPort(undefined);
      this.setSelectedIco(undefined);
    }

    index !== undefined && this.setShippingTabIndex(index);
  };

  @action private setShippingTabIndex = (index: number): void => {
    this.shippingTabIndex = index;
  };

  @action public setSelectedDestinationPort = (
    destinationPort: DestinationPort | undefined
  ): void => {
    this.selectedDestinationPort = destinationPort;
  };

  @action public openExportDetails = async (
    exportData: Export,
    tabIndex: number
  ): Promise<void> => {
    this.selectedExport = exportData;
    this.setShowShippingOverlay(true, tabIndex);
    await exportData.fetchExportDetails();
  };

  @action public setSelectedExport = (exportData: Export | undefined): void => {
    this.selectedExport = exportData;
  };

  @action public setSelectedIco = (ico: Ico | undefined): void => {
    this.selectedIco = ico;
  };

  @action public resetSelectedModels = () => {
    this.setSelectedDestinationPort(undefined);
    this.setSelectedExport(undefined);
    this.setSelectedIco(undefined);
  };

  @action public toggleExcludedDestinationPort = (name: string): void => {
    if (this.excludedDestinationPortNames.includes(name)) {
      this.excludedDestinationPortNames.remove(name);
    } else {
      this.excludedDestinationPortNames.push(name);
    }
  };

  @action public toggleExcludedDestinationPorts = (): void => {
    if (this.excludedDestinationPortNames.length) {
      this.excludedDestinationPortNames.clear();
    } else {
      this.excludedDestinationPortNames.replace(
        observable.array(
          this.destinationPorts.map((destinationPort) => destinationPort.name)
        )
      );
    }
  };

  @action public renderDropzoneForDestinationPort = (
    destinationPort: DestinationPort
  ): boolean => {
    return (
      (this.selectedIco && this.selectedDestinationPort === destinationPort) || false
    );
  };

  @action public createNewExport = (
    ico: Ico,
    fromExport: Export,
    fromDestinationPort: DestinationPort
  ): void => {
    customFetch(
      Routes.create_for_ico_api_v1_exports_path(),
      { ico_id: ico.id },
      'POST'
    ).then((response) => {
      if (response.success === true) {
        const { export_from, export_to } = response;
        const fromExportAttributes = deserialise(export_from)?.data as IExport;
        const toExportAttributes = deserialise(export_to).data as IExport;
        const destinationPort = this.destinationPorts.find(
          ({ name }) => name == fromDestinationPort.name
        );

        fromExport.icos.remove(ico);

        if (destinationPort) {
          destinationPort.addExport(new Export(toExportAttributes));
        }

        if (fromExportAttributes) {
          fromExport.updateAttributes(fromExportAttributes);
        } else {
          this.removeExport(fromExport);
        }
      }
    });
  };

  @action public moveIcoToExport = (
    ico: Ico,
    fromExport: Export,
    toExport: Export
  ): void => {
    customFetch(
      Routes.move_ico_api_v1_exports_path(),
      { ico_id: ico.id, export_to_id: toExport.id },
      'POST'
    ).then((response) => {
      if (response.success === true) {
        const { export_from, export_to } = response;
        const fromExportAttributes: IExport = deserialise(export_from).data;
        const toExportAttributes: IExport = deserialise(export_to).data;

        fromExport.icos.remove(ico);
        toExport.icos.push(ico);

        if (fromExportAttributes) {
          fromExport.updateAttributes(fromExportAttributes);
        } else {
          this.removeExport(fromExport);
        }

        toExport.updateAttributes(toExportAttributes);
      }
    });
  };

  @action public moveExport = async (
    export_: Export,
    toDestinationPortId: number
  ): Promise<void> => {
    const fromPort = this.destinationPorts.find(
      ({ id }) => id === export_.destinationPortId
    );

    await export_.updateDestinationPort(toDestinationPortId);

    const toPort = this.destinationPorts.find(({ id }) => id === toDestinationPortId);

    if (fromPort && toPort) {
      fromPort.moveExport(export_, toPort);
    } else if (fromPort && !toPort) {
      const newToPort = await DestinationPort.fetch(toDestinationPortId);
      fromPort.moveExport(export_, newToPort);
      this.destinationPorts.push(newToPort);
    }

    if (fromPort && !fromPort.hasExports) {
      this.destinationPorts.remove(fromPort);
    }
  };

  @action private removeExport = (exportToRemove: Export): void => {
    const destinationPort = this.destinationPorts.find((destinationPort) =>
      destinationPort.exports.find((export_) => export_.id === exportToRemove.id)
    );

    destinationPort?.removeExport(exportToRemove);
  };

  @computed get sortedDestinationPorts(): DestinationPort[] {
    return this.destinationPorts.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  @computed get filteredDestinationPorts(): DestinationPort[] {
    return this.sortedDestinationPorts.filter(
      ({ name }) => !this.excludedDestinationPortNames.includes(name)
    );
  }
}

export default ExportsStore;
