import { Ability as CaslAbility } from '@casl/ability';

interface IPermission {
  id: Number;
  resource: string;
  action: string;
}

const loadPermissions = () => {
  const permissionsElement = document.getElementById('permissions');
  if (
    permissionsElement === null ||
    permissionsElement.dataset.permissions == undefined
  ) {
    throw new Error('Could not load Permissions');
  }
  const permissions: IPermission[] = JSON.parse(permissionsElement.dataset.permissions);
  const transformedPermissions = permissions.map(({ resource, action }) => ({
    action,
    subject: resource,
  }));
  return transformedPermissions;
};

const Ability = new CaslAbility(loadPermissions());

export default Ability;
