import Drying from './drying';
import LotLocation from './lot_location';
import ISample from './ISample';

export enum LOT_STATE {
  INITIALIZED = 'initialized',
  FAILED = 'failed',
  RETURNED = 'returned',
  ANALYSED = 'analysed',
  PRICE_ACCEPTED = 'price_accepted',
  STOCKED = 'stocked',
  IN_TRANSIT = 'in_transit',
  AT_MILL = 'at_mill',
  IN_PRODUCTION = 'in_production',
  PRODUCED = 'produced',
  LOST = 'lost',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
  ANNULLED = 'annulled',
}

export interface LotBase {
  id: number;
  local_identifier: string;
  weight_string: string;
  received_in_warehouse_at: string;
  available_bags: number;
}

export interface LotGet extends LotBase {
  type: 'lot';
  state: LOT_STATE;
  purchase_sample: ISample;
  lab_sample?: ISample;
  agreed_to_no_premium_price: boolean;
  quality: string;
  grade: number;
  number_producer_bags: number;
  number_calibrated_bags: number;
  weight: number;
  current_weight: {
    weight_kg: string;
  };
  weight_minus_reserved_weight: {
    weight_kg: string;
  };
  final_score: number;
  certification: string;
  process_type: string;
  municipality: string;
  producer: string;
  original_grade: number;
  calibration_lab_sample_size: number;
  lot_tag: {
    start_number: number;
    end_number: number;
    color: string;
  };
}

export interface SelectableLot {
  selected?: boolean;
  attributes: LotGet;
}

export interface WetLot extends LotBase {
  producer: string;
  certification: string;
  location: LotLocation;
  latest_drying: Drying;
  days_in_drying: number;
  can_proceed: boolean;
  dryings: Array<Drying>;
  humidity_bounds: {
    lower: number;
    upper: number;
  };
  has_physical: boolean;
  passed_physical: boolean;
}

export type AvailableCoffeeBase = {
  available_weight: number;
  bag_info: string;
  certification?: string;
  current_number_bags: number;
  factor_info: string;
  final_score: number;
  grade?: number;
  local_identifier: string;
  lot_id?: number;
  lot_set_id?: number;
  process_type?: string;
  purchase_factor: number;
  purchase_type?: string;
  quality?: string;
  quantity: string;
  reserved: boolean;
  reservered?: boolean;
  secondary_factor_info: string;
  secondary_factor: number;
  selected?: boolean;
  total_weight: number;
  variety?: string;
  warehouse: string;
};

export type LotAvailableCoffee = AvailableCoffeeBase & {
  lot_id: number;
  lot_set_id: undefined;
};

export type LotSetAvailableCoffee = AvailableCoffeeBase & {
  lot_id: undefined;
  lot_set_id: number;
};

export type AvailableCoffeItem = LotAvailableCoffee | LotSetAvailableCoffee;

export type DeserializedSelectableLot = Deserialize<SelectableLot>;
