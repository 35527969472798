import { observer } from 'mobx-react';
import React from 'react';

import Fraction from 'productionOrders/models/Fraction';

export interface FractionTotalProps {
  fraction: Fraction;
}

function FractionTotal({ fraction }: FractionTotalProps) {
  return (
    <tr>
      <td></td>

      <td>
        <b>{I18n.translate('fractions.total')}</b>
      </td>

      <td>{`${fraction.totalGreen}kg`}</td>

      <td>{`${fraction.totalRejected}kg`}</td>

      <td>{`${fraction.totalLoss}kg`}</td>

      <td colSpan={3} className='border-right-black'></td>

      <td colSpan={2} className='align-center'>
        {fraction.isReprocessable && (
          <button
            onClick={fraction.createReprocess}
            className='button button--ghost button--small'
            type='button'
          >
            {I18n.translate('fractions.reprocess')}
          </button>
        )}
      </td>
    </tr>
  );
}

export default observer(FractionTotal);
