import React from 'react';
import SelectOptionArray from 'types/model/selectOption';

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOptionArray;
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    { options, placeholder, multiple, required, value, className, onChange, ...props },
    ref
  ) => {
    return (
      <select
        ref={ref}
        className={className}
        multiple={multiple}
        value={!ref && onChange ? value || '' : undefined}
        defaultValue={!ref && !onChange ? value || '' : undefined}
        onChange={onChange}
        required={required}
        {...props}
      >
        {!multiple && placeholder && (
          <option key='placeholder' value=''>
            {placeholder}
          </option>
        )}

        {options.map(({ id, name, value, disabled }) => (
          <option key={id || value} value={value || id} disabled={disabled}>
            {name}
          </option>
        ))}
      </select>
    );
  }
);

Select.displayName = 'Select';

export default Select;
