import { computed, observable } from 'mobx';

import type { IIco, IcoState } from '../types/IIco';

import { ICustomerSampleValueState } from 'types/model/ICustomerSampleValue';
import { IIcoImport } from 'exports/types/api';
import PackagingType from 'exports/types/packagingType';
import { IconType } from 'exports/components/ExportIcon';

class Ico {
  public identifier: string;
  public contract_identifier: string;
  public state: IcoState;
  public units: string;
  public mark_name: string;
  public unit_price: number;
  public weightToBeProduced: number;
  public preShipmentSampleState?: ICustomerSampleValueState;
  public prePhipmentSampleRequired: boolean;
  public canBetransported: boolean;

  @observable public id: number;
  @observable public packaging_type: PackagingType;
  @observable public alternative_ico_id?: string;
  @observable public ico_import?: IIcoImport;

  constructor({
    id,
    identifier,
    contract_identifier,
    packaging_type,
    units,
    state,
    mark_name,
    unit_price,
    pre_shipment_sample_required,
    current_pre_shipment_sample,
    weight_to_be_produced_kg,
    can_be_transported,
  }: IIco) {
    this.id = id;
    this.identifier = identifier;
    this.contract_identifier = contract_identifier;
    this.packaging_type = packaging_type;
    this.units = units;
    this.state = state;
    this.mark_name = mark_name;
    this.unit_price = unit_price;
    this.prePhipmentSampleRequired = pre_shipment_sample_required;
    this.preShipmentSampleState = current_pre_shipment_sample?.state;
    this.weightToBeProduced = weight_to_be_produced_kg;
    this.canBetransported = can_be_transported;
  }

  @computed get preshipmentSampleStateIcon() {
    if (this.prePhipmentSampleRequired) {
      switch (this.preShipmentSampleState) {
        case 'rejected': {
          return 'RED_CROSS';
        }
        case 'accepted': {
          return 'CHECKMARK';
        }
        case 'initial':
        default: {
          return 'GREY_CLOCK';
        }
      }
    } else {
      return 'CHECKMARK';
    }
  }

  @computed get icoStateIcon(): IconType {
    if (this.state === 'in_production_order') {
      return 'YELLOW_RING';
    } else if (['in_production', 'production_finalised', 'in_qa'].includes(this.state)) {
      return 'ORANGE_RING';
    } else if (this.canBetransported) {
      return 'CHECKMARK';
    } else {
      return 'RED_CROSS';
    }
  }
}

export default Ico;
