import React, { useContext } from 'react';
import styled from 'styled-components';

import { AbilityContext } from 'components/shared/Can';
import { addTestingIdentifier } from 'utils/testing';

export interface RoastingOverlayButtonProps {
  roasting: any;
  text: string;
  icon: string;
  status: boolean;
  disabled?: boolean;
  showReroast?: boolean;
  showEnterRoasting?: boolean;
  onClick: (state: boolean) => void;
}

const ReRoastWrapper = styled.span`
  .button {
    margin-left: 1.25em;
    white-space: nowrap;
  }
`;

const RoastingOverlayButton: React.FC<RoastingOverlayButtonProps> = ({
  roasting,
  text,
  icon,
  status,
  showReroast = true,
  showEnterRoasting = false,
  onClick,
  ...props
}) => {
  const ability = useContext(AbilityContext);

  const handleOnReroastClick = (_event: React.MouseEvent) => {
    if (onClick) {
      onClick(true);
    }
  };

  const handleEditClick = (_event: React.MouseEvent) => {
    if (onClick) {
      onClick(false);
    }
  };

  const renderButton = () => {
    if (roasting) {
      return (
        <>
          {showEnterRoasting ? (
            <button
              type='button'
              onClick={handleEditClick}
              className='button button--small button--ghost'
              {...addTestingIdentifier('enter_roasting_button')}
            >
              <span>{I18n.translate('buttons.enter')}</span>
            </button>
          ) : (
            <ReRoastWrapper>
              <div onClick={handleEditClick} className={`status status--${status}`}>
                <span>{text}</span>
                <svg className='icon'>
                  <use xlinkHref={`#${icon}`} />
                </svg>
              </div>

              {showReroast && status === false && (
                <button
                  type='button'
                  onClick={handleOnReroastClick}
                  className='button button--small button--ghost'
                  {...props}
                >
                  {I18n.translate('widgets.lot_status_table.reroast')}
                </button>
              )}
            </ReRoastWrapper>
          )}
        </>
      );
    } else {
      return (
        <button
          type='button'
          onClick={handleEditClick}
          className='button button--small button--ghost'
          disabled={!ability.can('create', 'Roasting')}
          {...addTestingIdentifier('create_roasting_button')}
          {...props}
        >
          {I18n.translate('widgets.lot_status_table.enter')}
        </button>
      );
    }
  };

  return renderButton();
};

export default RoastingOverlayButton;
