import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { VARIANT } from 'constants/inputTypes';

import Grid from 'components/elements/Grid';
import Icon from 'components/elements/Icon';

import Overlay from 'shared/Overlay';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';

import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';

import { LotCalibrationModel } from '../models/LotCalibrationModel';
import useStores from 'utils/useStores';
import BaseGroupComponent from 'components/groups/BaseGroup';
import Input from 'components/elements/Input';

interface CalibrationTablePackagingProps {
  grade: number;
  quality: string;
  model: LotCalibrationModel;
  disabled?: boolean;
}

const LotTag = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: 1.375em 4.25em 1.375em;
  grid-gap: 0.5em;
  align-items: center;
  margin: 0 -0.5em;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  > *:first-child {
    grid-column: 2;
  }
`;

const LotTagComponent = ({
  model,
  grade,
  quality,
  disabled,
}: CalibrationTablePackagingProps) => {
  const { calibrationStore } = useStores();
  const startInput = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (model.showOverlay) {
      startInput?.current?.focus();
    }
  }, [model.showOverlay]);

  const toggleOverlay = (state: boolean) => {
    model.setShowOverlay(state);
  };

  const selectedColor = calibrationStore.tagColors.find((color) => {
    if (model.color) {
      return color.id === parseInt(model.color, 10);
    }
  });

  return (
    <LotTag disabled={disabled}>
      <TextWithSub
        dataCy='calibration_lot_tag_grade'
        text={quality}
        sub={grade}
        textClass='button button--ghost button--small button--block'
        onClick={() => toggleOverlay(true)}
      />
      <Icon
        icon='flag'
        color={selectedColor ? selectedColor.color : '#efeeec'}
        onClick={() => toggleOverlay(false)}
      />
      {model.showErrorOverlay && (
        <Overlay
          title={I18n.translate('calibration.lot_tag.not_fitting_headline')}
          primaryText={I18n.translate('buttons.close')}
          onPrimaryClick={() => toggleOverlay(false)}
        >
          <p>{I18n.translate('calibration.lot_tag.not_fitting_text')}</p>
        </Overlay>
      )}
      {model.showOverlay &&
        ((disabled && (
          <Overlay
            title='Info'
            primaryText={I18n.translate('buttons.close')}
            onPrimaryClick={() => toggleOverlay(false)}
          >
            <p>Please select a BagType first</p>
          </Overlay>
        )) || (
          <Overlay
            title={I18n.translate('calibration.lot_tag.title')}
            primaryText={I18n.translate('buttons.save')}
            secondaryText={I18n.translate('buttons.cancel')}
            onPrimaryClick={() => toggleOverlay(false)}
            primaryDisabled={!model.allFilled}
            onSecondaryClick={() => model.resetTag() && toggleOverlay(false)}
          >
            <Grid grid={9}>
              <BaseGroupComponent
                label={I18n.translate('calibration.lot_tag.start_number')}
              >
                <Input
                  ref={startInput}
                  data-cy='lot_tag_start_number'
                  value={model.startNumber || ''}
                  placeholder='0'
                  onChange={(e) => {
                    model.setStartNumber(parseInt(e.target.value, 10));
                  }}
                />
              </BaseGroupComponent>
              <InputGroupComponent
                data-cy='lot_tag_end_number'
                label={I18n.translate('calibration.lot_tag.end_number')}
                value={model.endNumber || ''}
                placeholder='0'
                variant={VARIANT.INTEGER}
                readOnly={true}
              />
              <SelectGroup
                data-cy='lot_tag_color'
                label={I18n.translate('calibration.lot_tag.color')}
                placeholder={I18n.translate('calibration.lot_tag.color_placeholder')}
                value={model.color}
                options={calibrationStore.tagColors}
                onChange={(e) => model.setColor(e.target.value)}
              />
            </Grid>
          </Overlay>
        ))}
    </LotTag>
  );
};

export default observer(LotTagComponent);
