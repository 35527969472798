import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { COLORS } from 'constants/colors';

import Icon from 'components/elements/Icon';

import useStores from 'utils/useStores';

const FileUploader = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(9em, 1fr));
  grid-template-rows: 9em;
  grid-gap: 1.625em;
  flex-grow: 1;
`;

const FileInput = styled.input`
  opacity: 0;
  grid-area: 1 / 1 / 2 / 2;
  cursor: pointer;
`;

const FakeFileInput = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #006f94;
  border-radius: 0.125em;
  color: ${COLORS.BLUE};
  font-size: 4em;
  font-family: 'Courier Prime';
  pointer-events: none;
`;

const FileImageWrapper = styled.div`
  position: relative;
`;

const FileImage = styled.img`
  object-fit: cover;
  width: 10.375em;
  height: 9.125em;
  border-radius: 0.3125em;
`;

const RemoveImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 2.125em;
  width: 2.125em;
  justify-content: center;
  align-items: center;
  background: ${COLORS.RED};
  color: ${COLORS.WHITE};
  cursor: pointer;
`;

interface FileUploaderImageProps {
  file: File;
  handleRemoveFile: (file: File) => void;
}

const FileUploaderImage: React.FC<FileUploaderImageProps> = ({
  file,
  handleRemoveFile,
}) => {
  const [url, setUrl] = useState(
    'https://fakeimg.pl/166x144/?text=Loading...&font_size=20'
  );
  const reader = new FileReader();

  useEffect(() => {
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      setUrl(e.target.result);
    };
  });

  const handleRemoveClick = () => {
    handleRemoveFile(file);
  };

  return (
    <FileImageWrapper>
      <FileImage src={url} />
      <RemoveImage onClick={handleRemoveClick}>
        <Icon icon='cross'></Icon>
      </RemoveImage>
    </FileImageWrapper>
  );
};

const FileUploaderComponent: React.FC = () => {
  const { fileUploaderStore } = useStores();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;

    if (files) {
      fileUploaderStore.addFiles([...Array.from(files)]);
    }

    // reset the input file to recognize same files more then once
    e.target.value = '';
  };

  const handleRemoveFile = (file: File) => {
    fileUploaderStore.removeFile(file);
  };

  const renderFiles = () => {
    return fileUploaderStore.files.map((file) => {
      return (
        <FileUploaderImage
          key={file.name}
          file={file}
          handleRemoveFile={handleRemoveFile}
        />
      );
    });
  };

  return (
    <div className='input-group'>
      <label>{I18n.translate('file_uploader.add')}</label>
      <FileUploader data-cy='file_uploader'>
        <FileInput
          type='file'
          multiple={true}
          onChange={handleFileChange}
          data-cy='file_input'
        />
        <FakeFileInput>+</FakeFileInput>
        {renderFiles()}
      </FileUploader>
    </div>
  );
};

export default observer(FileUploaderComponent);
