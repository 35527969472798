import React, { useEffect } from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';

import Grid from 'components/elements/Grid';
import InputGroup from 'components/groups/InputGroup';
import FormFieldSet from 'exports/components/FormFieldSet';
import ImporterSecurityFillingModel from '../../models/ImporterSecurityFilling';
import type ImporterSecurityFillingType from '../../types/importerSecurityFilling';

import { useForm, FormProvider } from 'react-hook-form';
import AccordionFormBody from '../AccordionFormBody';
import { observer } from 'mobx-react';
import ContainerStuffing from './containerStuffing';
import SelectGroup from 'components/groups/SelectGroup';

export interface ImporterSecurityFillingProps {
  importerSecurityFilling: ImporterSecurityFillingModel;
  containerMovement: string;
  carrierName: string;
  billOfLading: string;
  destinationPortId: number;
}

const ImporterSecurityFillingWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

const ImporterSecurityFilling = ({
  importerSecurityFilling,
  containerMovement,
  carrierName,
  billOfLading,
  destinationPortId,
}: ImporterSecurityFillingProps) => {
  const methods = useForm({
    defaultValues: importerSecurityFilling,
  });

  const { register, reset, handleSubmit, formState } = methods;

  useEffect(() => {
    importerSecurityFilling.fetchDestinationWarehouses(destinationPortId);
  }, [destinationPortId]);

  const onSaveClick = handleSubmit(async (formData: ImporterSecurityFillingType) => {
    await importerSecurityFilling.save(formData);

    reset(formData);
  });

  const handleOnFinalizeClick = handleSubmit(async () => {
    await importerSecurityFilling.finalize();
  });

  return (
    <FormProvider {...methods}>
      <ImporterSecurityFillingWrapper>
        <AccordionFormBody
          isFinalizable={importerSecurityFilling.isFinalizable}
          isDirty={formState.isDirty}
          isSubmitting={formState.isSubmitting}
          finalized={importerSecurityFilling.finalized}
          onSubmitClick={onSaveClick}
          onFinalizeClick={handleOnFinalizeClick}
          downloadUrl={importerSecurityFilling.downloadUrl}
        >
          <FormFieldSet disabled={importerSecurityFilling.finalized}>
            <Grid grid={16}>
              <InputGroup
                id='container_movement'
                label={I18n.translate('exports.form.isf.container_movement_label')}
                placeholder={I18n.translate(
                  'exports.form.isf.container_movement_placeholder'
                )}
                disabled={true}
                value={containerMovement}
              />

              <InputGroup
                id='carrier_name'
                label={I18n.translate('exports.form.isf.carrier_name_label')}
                placeholder={I18n.translate('exports.form.isf.carrier_name_placeholder')}
                disabled={true}
                value={carrierName}
              />

              <InputGroup
                id='carrier_booking'
                label={I18n.translate('exports.form.isf.carrier_booking_label')}
                placeholder={I18n.translate(
                  'exports.form.isf.carrier_booking_placeholder'
                )}
                ref={register}
                name='carrier_booking'
              />

              <InputGroup
                id='bill_of_lading'
                label={I18n.translate('exports.form.isf.bill_of_lading_label')}
                placeholder={I18n.translate(
                  'exports.form.isf.bill_of_lading_placeholder'
                )}
                disabled={true}
                value={billOfLading}
              />

              <ContainerStuffing />

              <InputGroup
                id='harmonized_tariff_schedule'
                label={I18n.translate(
                  'exports.form.isf.harmonized_tariff_schedule_label'
                )}
                placeholder={I18n.translate(
                  'exports.form.isf.harmonized_tariff_schedule_placeholder'
                )}
                disabled={true}
                readOnly={true}
              />

              <SelectGroup
                label={I18n.translate('exports.form.isf.destination_warehouse_label')}
                placeholder={I18n.translate(
                  'exports.form.isf.destination_warehouse_placeholder'
                )}
                options={importerSecurityFilling.destination_warehouse_options}
                ref={register}
                name='destination_warehouse_id'
              />
            </Grid>
          </FormFieldSet>
        </AccordionFormBody>
      </ImporterSecurityFillingWrapper>
    </FormProvider>
  );
};

export default observer(ImporterSecurityFilling);
