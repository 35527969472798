import React from 'react';

import Icon from 'components/elements/Icon';

import FormFooter from './FormFooter';
import FormButton from './FormButton';

import styled from 'styled-components';
import { observer } from 'mobx-react';

const DownloadLink = styled.a``;

export interface AccordionFormBodyProps {
  onSubmitClick(event: React.FormEvent<HTMLFormElement>): void;
  onFinalizeClick(event: React.MouseEvent<HTMLButtonElement>): void;
  isDirty: boolean;
  isSubmitting: boolean;
  finalized: boolean;
  isFinalizable: boolean;
  downloadUrl?: string;
  children: React.ReactNode;
}

function AccordionFormBody({
  finalized,
  onSubmitClick,
  onFinalizeClick,
  downloadUrl,
  isDirty,
  isFinalizable,
  isSubmitting,
  children,
}: AccordionFormBodyProps) {
  return (
    <form onSubmit={onSubmitClick}>
      {children}

      <FormFooter>
        {!finalized && (
          <FormButton>
            <button
              disabled={!isDirty || isSubmitting}
              type='submit'
              className='button button--green'
            >
              {I18n.translate('buttons.save')}
            </button>
          </FormButton>
        )}

        {!isDirty && isFinalizable && !finalized && (
          <FormButton>
            <button
              type='button'
              className='button'
              onClick={onFinalizeClick}
              disabled={isSubmitting}
            >
              {I18n.translate('buttons.finalize')}
            </button>
          </FormButton>
        )}

        {finalized && downloadUrl && (
          <FormButton>
            <DownloadLink
              className='button button--medium-blue'
              href={downloadUrl}
              target='_blank'
            >
              <Icon icon='download' color='#ffffff' />
            </DownloadLink>
          </FormButton>
        )}
      </FormFooter>
    </form>
  );
}

export default observer(AccordionFormBody);
