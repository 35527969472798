import React, { useState } from 'react';

type IOneClickButtonProps = React.ComponentPropsWithoutRef<'button'>;

const OneClickButton = ({ onClick, ...props }: IOneClickButtonProps): JSX.Element => {
  const [wasClicked, setWasClicked] = useState(false);
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (wasClicked) {
      e.preventDefault();
      return;
    } else {
      onClick && onClick(e);
      setWasClicked(true);
    }
  };
  return <button onClick={onClickHandler} {...props} />;
};
export default OneClickButton;
