import { observable } from 'mobx';
import IConsolidateSample from '../../types/IConsolidateSample';
import Selectable from './Selectable';

class ConsolidateSampleSelectable extends Selectable {
  @observable public quantity: number;
  public attributes: IConsolidateSample;

  constructor(attributes: IConsolidateSample, selected = false) {
    super(selected);
    this.attributes = attributes;
  }
}

export default ConsolidateSampleSelectable;
