import { action, computed, IObservableArray, observable } from 'mobx';
import CustomerType from 'types/model/customer';

export class Customer {
  @observable public name?: string;
  @observable public fiscalId?: string;
  @observable public address?: string;
  @observable public city?: string;
  @observable public state?: string;
  @observable public postcode?: string;
  @observable public customerPhoneNumber?: string;
  @observable public customerMobileNumber?: string;
  @observable public customerEmailAddresses: IObservableArray<string> =
    observable.array<string>(['']);
  @observable public website?: string;
  @observable public addressEqualsBillingAddress?: boolean;
  @observable public billingAddress?: string;
  @observable public billingCity?: string;
  @observable public billingState?: string;
  @observable public billingPostcode?: string;
  @observable public contactPersonalIdNumber?: string;
  @observable public currency?: string;
  @observable public unitOfMeasure?: string;
  @observable public accountingId?: string;
  @observable public firstName?: string;
  @observable public middleName?: string;
  @observable public surname?: string;
  @observable public secondSurname?: string;
  @observable public mobileNumber?: string;
  @observable public phoneNumber?: string;
  @observable public emailAddress?: string;
  @observable public penalty?: number;
  @observable public active?: boolean;
  @observable public paymentTermId?: number;
  @observable public arbitrationId?: number;
  @observable public qualificationId?: number;
  @observable public leadSourceId?: number;
  @observable public customerOwnerId?: number;
  @observable public countryId?: number;
  @observable public mainContactInfoId?: number;
  @observable public companySizeId?: string;
  @observable public marketTypeId?: string;

  constructor(customer?: CustomerType) {
    if (customer) {
      this.name = customer.name;
      this.fiscalId = customer.fiscal_id;
      this.address = customer.address;
      this.city = customer.city;
      this.state = customer.state;
      this.postcode = customer.postcode;
      this.customerPhoneNumber = customer.customer_phone_number;
      this.customerEmailAddresses = observable.array(
        customer.customer_email_addresses?.length
          ? customer.customer_email_addresses
          : ['']
      );
      this.website = customer.website;
      this.billingAddress = customer.billing_address;
      this.billingCity = customer.billing_city;
      this.billingState = customer.billing_state;
      this.billingPostcode = customer.billing_postcode;
      this.contactPersonalIdNumber = customer.contact_personal_id_number;
      this.currency = customer.currency;
      this.unitOfMeasure = customer.unit_of_measure;
      this.accountingId = customer.accounting_id;
      this.penalty = customer.penalty;
      this.active = customer.active;
      this.paymentTermId = customer.payment_term_id;
      this.arbitrationId = customer.arbitration_id;
      this.qualificationId = customer.qualification_id;
      this.leadSourceId = customer.lead_source_id;
      this.customerOwnerId = customer.customer_owner_id;
      this.countryId = customer.country_id;
      this.firstName = customer.first_name;
      this.middleName = customer.middle_name;
      this.surname = customer.surname;
      this.secondSurname = customer.second_surname;
      this.mobileNumber = customer.mobile_number;
      this.phoneNumber = customer.phone_number;
      this.emailAddress = customer.email_address;
      this.mainContactInfoId = customer.main_contact_info_id;
      this.addressEqualsBillingAddress = customer.address_equals_billing_address;
      this.companySizeId = customer.company_size_id;
      this.marketTypeId = customer.market_type_id;
    }
  }

  @action setName(name: string) {
    this.name = name;
  }

  @action setFiscalId(fiscalId: string) {
    this.fiscalId = fiscalId;
  }

  @action setAddress(address: string, equalsBilling?: boolean) {
    this.address = address;
    equalsBilling && this.setBillingAddress(address);
  }

  @action setCity(city: string, equalsBilling?: boolean) {
    this.city = city;
    equalsBilling && this.setBillingCity(city);
  }

  @action setState(state: string, equalsBilling?: boolean) {
    this.state = state;
    equalsBilling && this.setBillingState(state);
  }

  @action setPostcode(postcode: string, equalsBilling?: boolean) {
    this.postcode = postcode;
    equalsBilling && this.setBillingPostcode(postcode);
  }

  @action setCustomerPhoneNumber(customerPhoneNumber: string) {
    this.customerPhoneNumber = customerPhoneNumber;
  }

  @action setCustomerEmailAddress(customerEmailAddress: string, index: number) {
    this.customerEmailAddresses[index] = customerEmailAddress;
  }

  @action removeCustomerEmailAddress(index: number) {
    if (this.customerEmailAddresses.length <= 1) {
      this.customerEmailAddresses[index] = '';
    } else {
      this.customerEmailAddresses.splice(index, 1);
    }
  }

  @action addNewCustomerEmailAddressInput() {
    this.customerEmailAddresses.push('');
  }

  @action setWebsite(website: string) {
    this.website = website;
  }

  @action setBillingAddress(billingAddress?: string) {
    this.billingAddress = billingAddress;
  }

  @action setBillingCity(billingCity?: string) {
    this.billingCity = billingCity;
  }

  @action setBillingState(billingState?: string) {
    this.billingState = billingState;
  }

  @action setBillingPostcode(billingPostcode?: string) {
    this.billingPostcode = billingPostcode;
  }

  @action setContactPersonalIdNumber(contactPersonalIdNumber: string) {
    this.contactPersonalIdNumber = contactPersonalIdNumber;
  }

  @action setCurrency(currency: string) {
    this.currency = currency;
  }

  @action setUnitOfMeasure(unitOfMeasure: string) {
    this.unitOfMeasure = unitOfMeasure;
  }

  @action setAccountingId(accountingId: string) {
    this.accountingId = accountingId;
  }

  @action setPenalty(penalty: string) {
    this.penalty = penalty ? parseFloat(penalty) : undefined;
  }

  @action setActive(active: boolean) {
    this.active = active;
  }

  @action setPaymentTermId(paymentTermId: number) {
    this.paymentTermId = paymentTermId;
  }

  @action setArbitrationId(arbitrationId: number | string) {
    this.arbitrationId = arbitrationId ? Number(arbitrationId) : undefined;
  }

  @action setQualificationId(qualificationId: number | string) {
    this.qualificationId = qualificationId ? Number(qualificationId) : undefined;
  }

  @action setLeadSourceId(leadSourceId: number | string) {
    this.leadSourceId = leadSourceId ? Number(leadSourceId) : undefined;
  }

  @action setCustomerOwnerId(customerOwnerId: number | string) {
    this.customerOwnerId = customerOwnerId ? Number(customerOwnerId) : undefined;
  }

  @action setCountryId(countryId: number | string) {
    this.countryId = countryId ? Number(countryId) : undefined;
  }

  @action setFirstName(name: string) {
    this.firstName = name;
  }

  @action setMiddleName(name: string) {
    this.middleName = name;
  }

  @action setSurname(name: string) {
    this.surname = name;
  }

  @action setSecondSurname(name: string) {
    this.secondSurname = name;
  }

  @action setMobileNumber(number: string) {
    this.mobileNumber = number;
  }

  @action setPhoneNumber(number: string) {
    this.phoneNumber = number;
  }

  @action setEmailAddress(email: string) {
    this.emailAddress = email;
  }

  @action setCompanySizeId(companySizeId: string) {
    this.companySizeId = companySizeId;
  }

  @action setMarketTypeId(marketTypeId: string) {
    this.marketTypeId = marketTypeId;
  }

  @computed get isValid() {
    return (
      !!this.leadSourceId &&
      !!this.customerOwnerId &&
      !!this.countryId &&
      !!this.name &&
      !!this.fiscalId &&
      !!this.address &&
      !!this.city &&
      !!this.postcode &&
      !!this.customerPhoneNumber &&
      !!this.customerEmailAddresses.length &&
      !!this.billingAddress &&
      !!this.billingCity &&
      !!this.billingPostcode &&
      !!this.currency &&
      !!this.arbitrationId &&
      !!this.qualificationId &&
      !!this.emailAddress &&
      !!this.firstName &&
      !!this.surname
    );
  }

  @computed get emptyLastEmailEntry(): boolean {
    return !this.customerEmailAddresses[this.customerEmailAddresses.length - 1];
  }
}

export default Customer;
