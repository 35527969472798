import React, { useState, useMemo, useEffect } from 'react';
import SelectOptionArray, { SelectOption } from 'types/model/selectOption';
import debounce from 'lodash.debounce';

export interface AsyncInputWithSuggestionsProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  dataSource: (searchInput: string) => Promise<SelectOptionArray>;
  successCallback: (response: any) => SelectOptionArray;
  errorCallback: (response: any) => SelectOptionArray;
  // defaultOptions: SelectOptionArray;
  id: string;
  listId: string;
  // prompt: string;
  label?: string;
}

export const AsyncInputWithSuggestions: React.FC<AsyncInputWithSuggestionsProps> = ({
  dataSource,
  successCallback,
  errorCallback,
  // defaultOptions,
  id,
  listId,
  label,
  ...inputProps
}) => {
  const [options, setOptions] = useState<SelectOptionArray>([]);

  const myEventHandler = (inputValue: string) => {
    if (inputValue.length > 2) {
      dataSource(inputValue)
        .then((response: any) => {
          const options = successCallback(response);
          setOptions(options);
        })
        .catch((errorResponse: any) => {
          errorCallback(errorResponse);
        });
    }
  };

  useEffect(() => {
    return () => {
      debouncedHandler.cancel();
      // throttledHandler.cancel();
    };
  }, []);

  const debouncedHandler = useMemo(() => {
    return debounce(myEventHandler, 150);
  }, []);
  // const throttledHandler = useMemo(() => {
  //   return throttle(myEventHandler, 150);
  // }, []);

  const handleKeyUp = (event) => {
    if (event.currentTarget) {
      const inputValue = event.currentTarget.value || '';
      debouncedHandler(inputValue);
    }
  };

  const renderRows = () => {
    return options.map((option: SelectOption) => {
      return (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      );
    });
  };

  return (
    <div className='input-group'>
      <div className='input-group'>
        {label && <label htmlFor={id}>{label}</label>}

        <input id={id} type='text' list={listId} {...inputProps} onKeyUp={handleKeyUp} />

        <datalist id={listId}>{renderRows()}</datalist>
      </div>
    </div>
  );
};

export default AsyncInputWithSuggestions;
