import React, { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import BaseFields from 'samples/create/components/BaseFields';
import { SampleDataContext } from 'samples/create/components/CreateSample';
import SampleLotSelect from 'samples/create/components/SampleLotSelect';
import SampleSourceSelect from 'samples/create/components/SampleSourceSelect';
import Errors from 'shared/Errors/components/Errors';
import { decodeEntityString, IEntity } from 'utils/entities';

function CreateAuditSample() {
  const { watch } = useFormContext();
  const watchLotOrLotSet: string = watch('sample[lot_or_lot_set]');
  const lotOrLotSet = useMemo(() => {
    if (watchLotOrLotSet) {
      return decodeEntityString(watchLotOrLotSet);
    } else {
      return null;
    }
  }, [watchLotOrLotSet]) as IEntity<'Lot' | 'LotSet'> | null;
  const { lots_and_sets } = useContext(SampleDataContext);

  if (lots_and_sets.length == 0) {
    return (
      <Errors
        errors={[
          I18n.translate('samples.create.no_lots_or_sets_available_to_create_sample'),
        ]}
      />
    );
  }

  return (
    <>
      <SampleLotSelect />
      <SampleSourceSelect
        sourcesData={{ id: lotOrLotSet?.id }}
        // This should probably be refactored into one controller.
        sourcesPath={() => {
          if (lotOrLotSet?.model == 'Lot') {
            return `/samples/lot_sources`;
          } else if (lotOrLotSet?.model == 'LotSet') {
            return `/samples/lot_set_sources`;
          }
          return '';
        }}
        disabled={!watchLotOrLotSet}
      />
      <BaseFields showCoffeeTypeSelection={false} />
    </>
  );
}

export default CreateAuditSample;
