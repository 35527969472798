import React from 'react';
import SelectedWSRResources from 'samples/create/components/warehouse_sample_request/SelectedWSRResources';
import useSelectionFieldArray from './useSelectionFieldArray';

type WSRSelectionTableProps<T> = {
  resources: T[];
  resourceFormName: string;
  resourceTitle: string;
  resourceIdName: string;
  officeWeightInputInTable?: boolean;
  officeWeightInputInHeader?: boolean;
  officeSampleIdentifier?: string;
  children: (
    insert: (
      index: number,
      value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
      shouldFocus?: boolean | undefined
    ) => void,
    deselect: (
      resource:
        | number
        | (T & {
            selected: boolean;
          })
    ) => void,
    selectedResource: (T & {
      selected: boolean;
    })[]
  ) => JSX.Element;
};

const WSRSelectionTable = <T extends { id: string | number }>({
  resources,
  resourceFormName,
  resourceTitle,
  resourceIdName,
  officeWeightInputInTable = true,
  officeWeightInputInHeader = false,
  officeSampleIdentifier,
  children,
}: WSRSelectionTableProps<T>): JSX.Element => {
  const { fields, insert, deselect, selectedResources } = useSelectionFieldArray<T>(
    resourceFormName,
    resources
  );

  return (
    <>
      <SelectedWSRResources
        onDeselect={deselect}
        fields={fields}
        resourceTitle={resourceTitle}
        fieldArrayName={resourceFormName}
        resourceIdName={resourceIdName}
        officeWeightInputInTable={officeWeightInputInTable}
        officeWeightInputInHeader={officeWeightInputInHeader}
        officeSampleIdentifier={officeSampleIdentifier}
      />
      {children(insert, deselect, selectedResources)}
    </>
  );
};
export default WSRSelectionTable;
