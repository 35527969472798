import { action, observable } from 'mobx';

import { Subproduct } from 'types/model/subproduct';

class SelectableSubproductModel {
  public attributes: Subproduct;

  @observable public quantity: number;
  @observable public selected: boolean;

  constructor(subproduct: Subproduct) {
    this.attributes = subproduct;
    this.selected = false;
    this.quantity = subproduct.available_weight;
  }

  @action public setQuantity = (value: string) => {
    this.quantity = parseFloat(value);
  };

  @action public setSelected = (selected: boolean) => {
    this.selected = selected;
  };
}

export default SelectableSubproductModel;
