import { Provider } from 'mobx-react';
import React from 'react';

import UnloadStore from '../stores/UnloadStore';
import OverlayStore from 'stores/OverlayStore';
import type { Transport } from 'types/model/transport';

import UnloadTransport, {
  UnloadTransportProps,
} from 'transports/components/UnloadTransport';
import SelectOptionArray from 'types/model/selectOption';

interface UnloadTransportContainerProps extends UnloadTransportProps {
  receptionDifferenceReasons: SelectOptionArray;
  transport: Transport;
  unloadTransportPath: string;
  redirectPath: string;
}

const UnloadTransportContainer = ({
  receptionDifferenceReasons,
  transport,
  unloadTransportPath,
  redirectPath,
  ...props
}: UnloadTransportContainerProps) => (
  <Provider
    overlayStore={new OverlayStore()}
    unloadStore={
      new UnloadStore(
        transport,
        receptionDifferenceReasons,
        unloadTransportPath,
        redirectPath
      )
    }
  >
    <UnloadTransport {...props} />
  </Provider>
);

export default UnloadTransportContainer;
