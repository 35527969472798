import InputGroupComponent from 'components/groups/InputGroup';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Checkbox from 'shared/Checkbox';
import styled from 'styled-components';
import { displayWithCurrency, exchangedValue } from 'utils/currency';
import useStores from 'utils/useStores';
import LoadoutFee from './LoadoutFee';
import PalletizingCost from './PalletizingCost';

const DeliveryCostLegend = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.625rem;
`;

function DestinationWarehouseCosts() {
  const { deliveryOrderIndexStore } = useStores();
  const { deliveryOrderCostStore } = deliveryOrderIndexStore;
  const { deliveryOrderCost } = deliveryOrderCostStore;

  const { rebaggingFee, orderPickFeeCost, totalCosts, destinationWarehouseCost } =
    deliveryOrderCost;

  const {
    sameCurrency,
    exchangeRate,
    rushFeeCost,
    ipccHeatTreatedFeeCost,
    samplingFeeCost,
    nonHeatTreatedFeeCost,
  } = destinationWarehouseCost;

  const { from: fromCurrency, to: toCurrency, rate, closed_at } = exchangeRate;

  return (
    <div>
      {!sameCurrency && (
        <DeliveryCostLegend>
          <ul>
            <li>{`${I18n.translate('activerecord.models.exchange_rate')}: ${rate.toFixed(
              4
            )}`}</li>
            <li>{`${I18n.translate(
              'activerecord.attributes.delivery_order.closed_at'
            )}: ${closed_at}`}</li>
          </ul>
        </DeliveryCostLegend>
      )}

      <table className='table table--full-border'>
        <thead>
          <tr>
            <th colSpan={2}></th>

            <th>
              {I18n.translate('fulfillment.delivery_orders.index.costs_in', {
                iso_code: fromCurrency.iso_code,
              })}
            </th>

            {!sameCurrency && (
              <th>
                {I18n.translate('fulfillment.delivery_orders.index.costs_in', {
                  iso_code: toCurrency.iso_code,
                })}
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          <LoadoutFee deliveryOrderCost={deliveryOrderCost} />

          <PalletizingCost />

          {ipccHeatTreatedFeeCost && (
            <tr>
              <td colSpan={2}>
                <Checkbox
                  condensed={true}
                  label='IPCC Heat Treated Fee'
                  checked={deliveryOrderCost.ipccHeatTreatedFeeChecked}
                  onChange={deliveryOrderCost.toggleIpccHeatTreatedFeeChecked}
                />
              </td>

              <td>
                {displayWithCurrency(ipccHeatTreatedFeeCost.toFixed(2), fromCurrency)}
              </td>

              {!sameCurrency && (
                <td>
                  {displayWithCurrency(
                    exchangedValue(ipccHeatTreatedFeeCost, exchangeRate.rate),
                    toCurrency
                  )}
                </td>
              )}
            </tr>
          )}

          {nonHeatTreatedFeeCost && (
            <tr>
              <td colSpan={2}>
                <Checkbox
                  condensed={true}
                  label='Non Heat Treated Fee'
                  checked={deliveryOrderCost.nonHeatTreatedFeeChecked}
                  onChange={deliveryOrderCost.toggleNonHeatTreatedFeeChecked}
                />
              </td>

              <td>{displayWithCurrency(nonHeatTreatedFeeCost, fromCurrency)}</td>

              {!sameCurrency && (
                <td>
                  {displayWithCurrency(
                    exchangedValue(nonHeatTreatedFeeCost, exchangeRate.rate),
                    toCurrency
                  )}
                </td>
              )}
            </tr>
          )}

          {orderPickFeeCost && (
            <tr>
              <td colSpan={2}>
                <Checkbox
                  condensed={true}
                  label='Order Pick Fee'
                  checked={deliveryOrderCost.orderPickFeeChecked}
                  onChange={deliveryOrderCost.toggleOrderPickFeeChecked}
                />
              </td>

              <td>{displayWithCurrency(orderPickFeeCost.toFixed(2), fromCurrency)}</td>

              {!sameCurrency && (
                <td>
                  {displayWithCurrency(
                    exchangedValue(orderPickFeeCost, exchangeRate.rate),
                    toCurrency
                  )}
                </td>
              )}
            </tr>
          )}

          {rushFeeCost !== null && (
            <tr>
              <td colSpan={2}>
                <Checkbox
                  condensed={true}
                  label='Rush Fee'
                  checked={deliveryOrderCost.rushFeeChecked}
                  onChange={deliveryOrderCost.toggleRushFeeChecked}
                />
              </td>

              <td>{displayWithCurrency(rushFeeCost.toFixed(2), fromCurrency)}</td>

              {!sameCurrency && (
                <td>
                  {displayWithCurrency(
                    exchangedValue(rushFeeCost, exchangeRate.rate),
                    toCurrency
                  )}
                </td>
              )}
            </tr>
          )}

          {rebaggingFee !== null && (
            <tr>
              <td colSpan={2}>
                <Checkbox
                  condensed={true}
                  label='Rebagging'
                  checked={deliveryOrderCost.rebaggingFeeChecked}
                  onChange={deliveryOrderCost.toggleRebaggingFeeChecked}
                />
              </td>

              <td>{displayWithCurrency(rebaggingFee, fromCurrency)}</td>

              {!sameCurrency && (
                <td>
                  {displayWithCurrency(
                    exchangedValue(rebaggingFee, exchangeRate.rate),
                    toCurrency
                  )}
                </td>
              )}
            </tr>
          )}

          {samplingFeeCost && (
            <tr>
              <td colSpan={2}>
                <Checkbox
                  condensed={true}
                  label='Sampling'
                  checked={deliveryOrderCost.samplingFeeChecked}
                  onChange={deliveryOrderCost.toggleSamplingFeeChecked}
                />
              </td>

              <td>{displayWithCurrency(samplingFeeCost, fromCurrency)}</td>

              {!sameCurrency && (
                <td>
                  {displayWithCurrency(
                    exchangedValue(samplingFeeCost, exchangeRate.rate),
                    toCurrency
                  )}
                </td>
              )}
            </tr>
          )}

          <tr>
            <td colSpan={2}>
              <Checkbox
                condensed={true}
                label='Freight'
                checked={deliveryOrderCost.freightFeeChecked}
                onChange={deliveryOrderCost.toggleFreightFeeChecked}
              />
            </td>

            <td>
              <InputGroupComponent
                append={fromCurrency.symbol}
                type='number'
                name='freight'
                min={0}
                step={0.01}
                disabled={!deliveryOrderCost.freightFeeChecked}
                value={deliveryOrderCost.freightFee}
                onChange={(event) =>
                  deliveryOrderCost.setFreightFee(event.currentTarget.value)
                }
                inline
              />
            </td>

            {!sameCurrency && (
              <td>
                {displayWithCurrency(
                  exchangedValue(deliveryOrderCost.freightFee, exchangeRate.rate),
                  toCurrency
                )}
              </td>
            )}
          </tr>
          <tr>
            <td colSpan={2}>
              <b>Total</b>
            </td>

            <td>
              <b>{displayWithCurrency(totalCosts.toFixed(2), fromCurrency)}</b>
            </td>

            {!sameCurrency && (
              <td>
                <b>
                  {displayWithCurrency(
                    exchangedValue(totalCosts, exchangeRate.rate),
                    toCurrency
                  )}
                </b>
              </td>
            )}
          </tr>

          <tr>
            <td colSpan={4}>
              <Checkbox
                condensed={true}
                label='No costs'
                checked={deliveryOrderCost.noCostChecked}
                onChange={deliveryOrderCost.toggleNoCostChecked}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>PO#</td>
            <td>
              <input
                type='text'
                value={deliveryOrderCost.poNumber}
                onChange={(event) =>
                  deliveryOrderCost.setPoNumber(event.currentTarget.value)
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default observer(DestinationWarehouseCosts);
