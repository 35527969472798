import SelectGroup from 'components/groups/SelectGroup';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Overlay from 'shared/Overlay';
import { OverlayProps } from 'shared/Overlay/components/Overlay';
import OverlayButton from 'shared/Overlay/components/OverlayButton';
import SelectOptionArray from 'types/model/selectOption';
import useStores from 'utils/useStores';
import ProductionOrderStore from '../stores/ProductionOrderStore';
import ProductionOrder from '../types/ProductionOrder';

function Button(props: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button className='button button--small button--required' {...props}>
      Add Mark
    </button>
  );
}

export interface AddMarkOverlayProps extends Pick<OverlayProps, 'onSecondaryClick'> {
  marks: SelectOptionArray;
}

function AddMarkOverlay({ marks, ...props }: AddMarkOverlayProps) {
  const { productionOrderStore } =
    useStores<{ productionOrderStore: ProductionOrderStore }>();
  const [markId, setMarkId] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);

  const handleOnPrimaryClick = async () => {
    setSubmitting(true);

    try {
      await productionOrderStore.saveMark(markId);

      window.location.reload();
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <Overlay
      title='Add Mark'
      primaryText={I18n.translate('buttons.save')}
      onPrimaryClick={handleOnPrimaryClick}
      primaryDisabled={!markId || submitting}
      secondaryText={I18n.translate('buttons.close')}
      {...props}
    >
      <SelectGroup
        options={marks}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
          setMarkId(event.target.value)
        }
        value={markId}
        placeholder='Please select a mark'
      />
    </Overlay>
  );
}

export interface AddMarkProps {
  productionOrder: ProductionOrder;
}

function AddMark({ productionOrder }: AddMarkProps) {
  const { productionOrderStore } =
    useStores<{ productionOrderStore: ProductionOrderStore }>();

  return (
    <OverlayButton
      button={Button}
      onClick={() => productionOrderStore.selectProductionOrder(productionOrder)}
      overlay={({ closeOverlay }) => (
        <AddMarkOverlay
          onSecondaryClick={closeOverlay}
          marks={productionOrderStore.markOptions}
        />
      )}
    />
  );
}

export default observer(AddMark);
