import { Provider } from 'mobx-react';
import React from 'react';

import AvailableExcessOrOrphanCoffeeComponent from 'availableExcessOrOrphanCoffee/components/AvailableExcessOrOrphanCoffeeComponent';
import AvailableExcessOrOrphanCoffeeStore from 'availableExcessOrOrphanCoffee/stores/AvailableExcessOrOrphanCoffeeStore';
import { AbilityContext } from 'components/shared/Can';
import Ability from 'utils/permissions/ability';
import SelectOptionArray from 'types/model/selectOption';

export type OrphanStatus =
  | undefined
  | 'REQUESTED'
  | 'IN_PRODUCTION'
  | 'AVAILABLE'
  | 'REJECTED';

interface AvailableExcessOrOrphanCoffeeContainerProps {
  items: any;
  missingWeight: string;
  millingOrderId: number;
  orphanStatus: OrphanStatus;
  qualities: SelectOptionArray;
}

const AvailableExcessOrOrphanCoffeeContainer: React.FC<
  AvailableExcessOrOrphanCoffeeContainerProps
> = ({ items, missingWeight, millingOrderId, orphanStatus, qualities }) => {
  return (
    <AbilityContext.Provider value={Ability}>
      <Provider
        availableExcessOrOrphanCoffeeStore={
          new AvailableExcessOrOrphanCoffeeStore(
            items,
            missingWeight,
            millingOrderId,
            orphanStatus,
            qualities
          )
        }
      >
        <AvailableExcessOrOrphanCoffeeComponent />
      </Provider>
    </AbilityContext.Provider>
  );
};

export default AvailableExcessOrOrphanCoffeeContainer;
