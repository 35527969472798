import React, { useState, useEffect } from 'react';
import Overlay from 'shared/Overlay';
import Checkbox from 'shared/Checkbox';
import SelectOptionArray from 'types/model/selectOption';
import BaseGroupComponent from 'components/groups/BaseGroup';
import ErrorsComponent from 'shared/Errors/components/Errors';
import { IField } from 'components/shared/form/IField';

interface ApprovalTermSelectProps {
  terms: SelectOptionArray;
  selected_approval_term_id: number;
  field: IField;
}

const ApprovalTermSelect = ({
  field,
  terms,
  selected_approval_term_id,
}: ApprovalTermSelectProps) => {
  const [showSelectOverlay, setSelectOverlay] = useState(false);
  const [approvalTermId, setApprovalTermId] = useState(selected_approval_term_id || '-1');
  const [selectedTermName, setSelectedTermName] = useState('');

  useEffect(() => {
    setSelectedTermName(terms.find((term) => term.id === approvalTermId)?.name || '');
  }, [approvalTermId]);

  return (
    <>
      {showSelectOverlay && (
        <Overlay
          title={I18n.translate('contracts.select_approval_terms')}
          primaryText={I18n.translate('buttons.save')}
          onPrimaryClick={() => setSelectOverlay(false)}
          secondaryText={I18n.translate('buttons.cancel')}
          onSecondaryClick={() => setSelectOverlay(false)}
        >
          {terms.map((term) => (
            <Checkbox
              key={term.id}
              label={term.name}
              icon={'tick'}
              round={true}
              labelMultiline={true}
              asRadioButton={true}
              iconColor={'GREEN'}
              id={`contract_approval_term_id_${term.id}`}
              name={`contract[approval_term_id]_temp`}
              value={term.id.toString()}
              onChange={() => setApprovalTermId(term.id)}
              checked={approvalTermId === term.id}
            />
          ))}
        </Overlay>
      )}
      <BaseGroupComponent label={I18n.translate('contracts.form.approval_terms') + '*'}>
        <input
          type='text'
          readOnly={true}
          onClick={() => setSelectOverlay(true)}
          placeholder={I18n.translate('contracts.select_approval_terms')}
          value={selectedTermName}
          data-cy='approval_terms'
        />
        {field?.errors && <ErrorsComponent errors={field?.errors} />}
        <input type='hidden' name={`contract[approval_term_id]`} value={approvalTermId} />
      </BaseGroupComponent>
    </>
  );
};

export default ApprovalTermSelect;
