import React from 'react';
import styled from 'styled-components';

import SpaceBetween from 'components/elements/SpaceBetween';
import * as LotInfoBox from 'shared/LotInfoBox';
import TestGrid, { TestGridHeader, TestResult } from 'shared/TestGrid';

import {
  OverlayBaseForm,
  OverlayFooter,
  OverlayPortal,
  OverlayWrapper,
} from 'shared/Overlay';
import { OverlayProps } from 'shared/Overlay/components/Overlay';
import { LotTraceability } from '../types/traceability';
import Routes from 'routes';
import { weightToString } from 'utils/weight';

export type LotPurchaseInformationOverlayProps = PartialPick<
  OverlayProps,
  'id' | 'title' | 'primaryText' | 'children'
> & {
  traceability: LotTraceability;
};

const LotPurchaseInformationOverlay = ({
  traceability: {
    local_identifier,
    current_weight,
    initial_weight,
    current_number_bags,
    humidity_analysis,
    physical_analysis,
    roasting,
    sensorial_analysis,
    price,
    producer,
    farm,
    certificate,
    process_type,
    id,
  },
  onPrimaryClick,
  ...props
}: LotPurchaseInformationOverlayProps) => {
  return (
    <OverlayPortal>
      <OverlayWrapper {...props}>
        <OverlayBaseForm>
          <LotInfoBox.Header>
            <SpaceBetween>
              <span>
                <LotInfoBox.HeaderInfo>
                  {I18n.translate('traceability.overlay_headline', {
                    identifier: local_identifier,
                  })}
                </LotInfoBox.HeaderInfo>
                <a
                  href={Routes.dry_lot_reception_lot_path(id)}
                  target='_blank'
                  rel='noreferrer'
                  className='button button--small button--ghost'
                >
                  {I18n.translate('buttons.reprint_label')}
                </a>
              </span>

              <span>
                {current_number_bags && (
                  <LotInfoBox.HeaderInfo>
                    {I18n.translate('lot_sets.index.bags', {
                      count: current_number_bags,
                    })}
                  </LotInfoBox.HeaderInfo>
                )}

                <LotInfoBox.HeaderInfo>{price}</LotInfoBox.HeaderInfo>
              </span>
            </SpaceBetween>
          </LotInfoBox.Header>

          <LotInfoBox.Header>
            <SpaceBetween>
              <span>
                <LotInfoBox.SubHeaderInfo>
                  <strong>{producer}</strong>
                </LotInfoBox.SubHeaderInfo>

                <LotInfoBox.SubHeaderInfo>{farm}</LotInfoBox.SubHeaderInfo>

                {certificate && (
                  <LotInfoBox.SubHeaderInfo>{certificate}</LotInfoBox.SubHeaderInfo>
                )}

                <LotInfoBox.SubHeaderInfo>{process_type}</LotInfoBox.SubHeaderInfo>
              </span>

              <span>
                <LotInfoBox.SubHeaderInfo>
                  {I18n.translate('traceability.total_weight', {
                    weight: weightToString(current_weight),
                  })}
                  {' / '}
                  {I18n.translate('traceability.received_weight', {
                    weight: weightToString(initial_weight),
                  })}
                </LotInfoBox.SubHeaderInfo>
              </span>
            </SpaceBetween>
          </LotInfoBox.Header>

          <TestGrid>
            <div>
              {humidity_analysis && (
                <TestGridHeader>{I18n.translate('lots.index.humidity')}</TestGridHeader>
              )}
              {physical_analysis && (
                <TestGridHeader>
                  {I18n.translate('lots.index.physical_anal')}
                </TestGridHeader>
              )}
              {roasting && (
                <TestGridHeader>{I18n.translate('lots.index.roasting')}</TestGridHeader>
              )}
              {sensorial_analysis && (
                <TestGridHeader>
                  {I18n.translate('lot_sets.index.sensorial_analysis')}
                </TestGridHeader>
              )}
            </div>

            <div>
              {humidity_analysis && (
                <div>
                  <TestResult test={humidity_analysis} />
                </div>
              )}

              {physical_analysis && (
                <div>
                  <TestResult test={physical_analysis} />
                </div>
              )}

              {roasting && (
                <div>
                  <TestResult test={roasting} />
                </div>
              )}

              {sensorial_analysis && (
                <div>
                  <TestResult test={sensorial_analysis} />
                </div>
              )}
            </div>
          </TestGrid>

          <OverlayFooter>
            <button type='button' onClick={onPrimaryClick} className='button'>
              {I18n.translate('buttons.close')}
            </button>
          </OverlayFooter>
        </OverlayBaseForm>
      </OverlayWrapper>
    </OverlayPortal>
  );
};

export default styled(LotPurchaseInformationOverlay)`
  ${OverlayBaseForm} {
    min-width: 90%;
  }
`;
