import React, { useMemo, useState } from 'react';

import BaseGroupComponent from 'components/groups/BaseGroup';
import Overlay from 'shared/Overlay';
import Input from 'components/elements/Input';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import styled from 'styled-components';
import classNames from 'classnames';
import SelectGroup from 'components/groups/SelectGroup';
import FilterBar from 'components/elements/FilterBar';

const TableHead = styled.thead`
  th:not(:first-child):not(:last-child) {
    text-align: center;
  }
`;

const TableBody = styled.tbody`
  td:not(:first-child):not(:last-child) {
    text-align: center;
  }
`;

export interface AvailableCoffeeType {
  id: number;
  identifier: {
    name: string;
    value: string;
  };
  ico_with_secondary_number?: string;
  ico_state: string;
  origin_country: string;
  units: number;
  mark: string;
  grade: string;
  quality: string;
  certificate: string;
  packagingType: string;
  preparation?: string;
}

interface AvailableCoffeeProps {
  buttonTitle: string;
  availableCoffee: Array<AvailableCoffeeType>;
  onSelect: (coffee: AvailableCoffeeType) => void;
  emptyLabel?: boolean;
}

const toSimpleOptions = (values) =>
  values.filter((v) => !!v).map((v) => ({ id: v, name: v }));

const filterableValues = (allCoffee: Array<AvailableCoffeeType>) => ({
  origins: toSimpleOptions([
    ...new Set(allCoffee.map((coffee) => coffee.origin_country)),
  ]),
  marks: toSimpleOptions([...new Set(allCoffee.map((coffee) => coffee.mark))]),
  qualities: toSimpleOptions([...new Set(allCoffee.map((coffee) => coffee.quality))]),
});

const AvailableCoffee = ({
  buttonTitle,
  availableCoffee,
  onSelect,
  emptyLabel = true,
}: AvailableCoffeeProps) => {
  const [showOverlay, setOverlay] = useState(false);
  const [selected, setSelected] = useState(false);

  const handleSelect = (id: number) => {
    const selectedCoffee = availableCoffee.find((c) => c.id == id);
    if (selectedCoffee) {
      onSelect(selectedCoffee);
      setSelected(true);
      setOverlay(false);
    }
  };

  const buttonClass = classNames([
    'button',
    'button--block',
    {
      'button--green': !selected,
      'button--second': selected,
    },
  ]);

  const availableFilters = useMemo(() => filterableValues(availableCoffee), []);

  const [icoNumberFilter, setIcoNumberFilter] = useState<string>('');
  const [originFilter, setOriginFilter] = useState<string>('');
  const [qualityFilter, setQualityFilter] = useState<string>('');
  const [markFilter, setMarkFilter] = useState<string>('');

  const filteredCoffee = useMemo(
    () =>
      availableCoffee.filter(
        (coffee) =>
          (icoNumberFilter == '' || coffee.identifier.value.includes(icoNumberFilter)) &&
          (originFilter == '' || coffee.origin_country == originFilter) &&
          (qualityFilter == '' || coffee.quality == qualityFilter) &&
          (markFilter == '' || coffee.mark == markFilter)
      ),
    [originFilter, qualityFilter, markFilter, icoNumberFilter]
  );
  return (
    <>
      {showOverlay && (
        <Overlay
          title={I18n.translate('available_coffee_overlay.title')}
          secondaryText={I18n.translate('buttons.cancel')}
          onSecondaryClick={() => setOverlay(false)}
        >
          <div className='l-item-stack'>
            <FilterBar>
              <Input
                placeholder={I18n.translate('available_coffee_overlay.find_coffee')}
                value={icoNumberFilter}
                onChange={(e) => setIcoNumberFilter(e.target.value)}
              />
              <SelectGroup
                noMargin
                value={originFilter}
                options={availableFilters.origins}
                placeholder={I18n.translate('available_coffee_overlay.select_a_origin')}
                onChange={(e) => setOriginFilter(e.target.value)}
              />
              <SelectGroup
                noMargin
                value={qualityFilter}
                options={availableFilters.qualities}
                placeholder={I18n.translate('available_coffee_overlay.select_a_grade')}
                onChange={(e) => setQualityFilter(e.target.value)}
              />
              <SelectGroup
                noMargin
                value={markFilter}
                options={availableFilters.marks}
                placeholder={I18n.translate('available_coffee_overlay.select_a_mark')}
                onChange={(e) => setMarkFilter(e.target.value)}
              />
            </FilterBar>
            <table className='table table--inverted-stripes'>
              <TableHead>
                <tr>
                  <th className='table-border'>ID</th>
                  <th className='table-border'>{I18n.translate('attributes.origin')}</th>
                  <th className='table-border'>
                    {I18n.translate('available_coffee_overlay.ico_state')}
                  </th>
                  <th className='table-border'>
                    {I18n.translate('available_coffee_overlay.units')}
                  </th>
                  <th className='table-border'>{I18n.translate('attributes.mark')}</th>
                  <th className='table-border'>{I18n.translate('attributes.grade')}</th>
                  <th></th>
                </tr>
              </TableHead>

              <TableBody>
                {filteredCoffee.map((coffee) => (
                  <tr key={coffee.id}>
                    <td className='table-border'>
                      {coffee.ico_with_secondary_number || coffee.identifier.name}
                    </td>
                    <td className='table-border'>{coffee.origin_country}</td>
                    <td className='table-border'>{coffee.ico_state}</td>
                    <td className='table-border'>{coffee.units}</td>
                    <td className='table-border'>{coffee.mark}</td>
                    <td className='table-border'>
                      <TextWithSub text={coffee.quality} sub={coffee.grade} />
                    </td>
                    <td className='align-right'>
                      <div
                        className='button button--small button--ghost'
                        onClick={() => handleSelect(coffee.id)}
                      >
                        {I18n.translate('buttons.select')}
                      </div>
                    </td>
                  </tr>
                ))}
              </TableBody>
            </table>
            <div /> {/* acting as spacer  */}
          </div>
        </Overlay>
      )}

      <BaseGroupComponent emptyLabel={emptyLabel}>
        <div onClick={() => setOverlay(true)} className={buttonClass}>
          {buttonTitle}
        </div>
      </BaseGroupComponent>
    </>
  );
};

export default AvailableCoffee;
