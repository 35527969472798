import styled from 'styled-components';

interface GridProps {
  columns?: number;
}

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, minmax(auto, 30rem))` : '1fr'};
  grid-auto-rows: min-content;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

export default Grid;
