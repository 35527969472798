import { action, computed, observable } from 'mobx';

import type { TransportLegPostBody } from 'types/model/transportLeg';

class LegStore {
  @observable public dispatchDate = new Date().toISOString().substr(0, 10);
  @observable public nextDestinationId = -1;
  @observable public responsibleForInspectionId = -1;
  @observable public responsibleForDispatchingId = -1;
  @observable public responsibleForLoading = Array<number>();
  @observable public responsibleForLoadingExternal = '';
  @observable public transportTags = '';
  @observable public observations = '';
  @observable public questionnaireAnswers = Array<boolean>(
    false,
    false,
    false,
    false,
    false
  );
  @observable public questionnaireNeeded = false;

  public constructor(nextDestinationId: number) {
    this.nextDestinationId = nextDestinationId || -1;
  }

  @action public setDispatchDate = (value: string) => {
    this.dispatchDate = value;
  };

  @action public setNextDestinationId = (value: number) => {
    this.nextDestinationId = value;
  };

  @action public setResponsibleForInspectionId = (value: number) => {
    this.responsibleForInspectionId = value;
  };

  @action public setResponsibleForDispatchingId = (value: number) => {
    this.responsibleForDispatchingId = value;
  };

  @action public setResponsibleForLoading = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.responsibleForLoading = Array.from(e.target.selectedOptions, (selectedOption) =>
      parseInt(selectedOption.value, 10)
    );
  };

  @action public appendTextToResponsibleForLoadingExternal = (text: string) => {
    this.responsibleForLoadingExternal = `${this.responsibleForLoadingExternal}${text}\n`;
  };

  @action public setResponsibleForLoadingExternal = (value: string) => {
    this.responsibleForLoadingExternal = value;
  };

  @action public setTransportTags = (value: string) => {
    this.transportTags = value;
  };

  @action public setObservations = (value: string) => {
    this.observations = value;
  };

  @action public setQuestionnaireAnswer = (state: boolean, position: number) => {
    this.questionnaireAnswers[position] = state;
  };

  @action public setQuestionnaireNeeded = (state: boolean) => {
    this.questionnaireNeeded = state;
  };

  @computed get readyForSubmit(): boolean {
    return (
      this.dispatchDate.length > 0 &&
      this.nextDestinationId > 0 &&
      this.responsibleForInspectionId > 0 &&
      this.responsibleForDispatchingId > 0 &&
      this.responsibleForLoading.length > 0 &&
      this.transportTags.length > 0
    );
  }

  @computed get formBody(): TransportLegPostBody {
    const formBody: TransportLegPostBody = {
      leg: {
        dispatch_date: this.dispatchDate,
        dispatched_by_id: this.responsibleForDispatchingId,
        inspected_by_id: this.responsibleForInspectionId,
        loaded_by_externals: this.responsibleForLoadingExternal,
        loaded_by_internal_ids: this.responsibleForLoading,
        observations: this.observations,
        questionnaire_answers: this.questionnaireAnswers,
        transport_tags: this.transportTags,
      },
      transport: {
        next_destination_warehouse_id: this.nextDestinationId,
      },
    };

    if (!this.questionnaireNeeded) {
      delete formBody.leg.questionnaire_answers;
    }

    return formBody;
  }
}

export default LegStore;
