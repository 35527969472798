import {
  DeliveryOrderAddress,
  DeliveryOrderItem,
  DeliveryOrderShippingOption,
  DeliveryOrderState,
  DeliveryOrder as DeliveryOrderType,
  Customer,
  DestinationWarehouse,
} from '../types/types';
import { computed } from 'mobx';

export class DeliveryOrder {
  public id: number;
  public customer: Customer;
  public destinationWarehouse: DestinationWarehouse;
  public deliveryOrderItems?: DeliveryOrderItem[] = [];
  public deliveryOrderAddress?: DeliveryOrderAddress;
  public deliveryOrderShippingOption?: DeliveryOrderShippingOption;
  public hasAddress: boolean;
  public totalUnits: string;
  public identifier: string;
  public acceptedDate: string;
  public editable: boolean;
  public notes: string;
  public state: DeliveryOrderState;
  public downloadUrl?: string;

  constructor(deliveryOrder: DeliveryOrderType) {
    this.id = deliveryOrder.id;
    this.customer = deliveryOrder.customer;
    this.destinationWarehouse = deliveryOrder.destination_warehouse;
    this.deliveryOrderItems = deliveryOrder.delivery_order_items;
    this.deliveryOrderAddress = deliveryOrder.delivery_order_address;
    this.deliveryOrderShippingOption = deliveryOrder.delivery_order_shipping_option;
    this.hasAddress = deliveryOrder.has_address;
    this.totalUnits = deliveryOrder.total_units;
    this.identifier = deliveryOrder.identifier;
    this.acceptedDate = deliveryOrder.accepted_date;
    this.editable = deliveryOrder.editable;
    this.notes = deliveryOrder.notes;
    this.state = deliveryOrder.state;
    this.downloadUrl = deliveryOrder.downloadUrl;
  }

  @computed get sent(): boolean {
    return ['sent', 'accepted'].includes(this.state);
  }

  @computed get released(): boolean {
    return ['in_progress', 'released'].includes(this.state);
  }
}

export default DeliveryOrder;
