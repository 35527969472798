import styled from 'styled-components';
import COLORS from 'constants/colors';

export default styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  position: relative;
  margin: 1rem 0;
  background: ${COLORS.MEDIUM_LIGHT_GRAY};

  /* Hide Scrollbars */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    z-index: 1;
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid ${COLORS.BLUE};
  }
`;
