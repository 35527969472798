import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';

import ContentHeader from 'components/elements/ContentHeader';
import OverviewTableRow from './OverviewTableRow';

import Routes from 'routes';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';

export interface OverviewTableProps {
  roastingSampleWeight: number;
}

function OverviewTable({ roastingSampleWeight }: OverviewTableProps) {
  const { overviewTableStore } = useStores();

  const renderTableRows = () => {
    return overviewTableStore.samples.map((sample) => {
      return (
        <OverviewTableRow
          sample={sample}
          key={sample.local_identifier}
          roastingSampleWeight={roastingSampleWeight}
        />
      );
    });
  };

  return (
    <form
      action={Routes.vertical_session_sensorial_analyses_path()}
      acceptCharset='UTF-8'
      method='post'
      className='content'
    >
      <AuthenticityToken />

      <ContentHeader title={I18n.translate('headlines.organize_cupping_session')}>
        <a className='button button--cancel' href='/lots'>
          {I18n.translate('buttons.cancel')}
        </a>

        <button
          name='button'
          type='submit'
          disabled={overviewTableStore.selectedSamplesCount === 0}
          className='button'
          data-cy='cupping_session_run_with_samples_button'
        >
          {I18n.translate('lots.index.run_cupping_with_samples', {
            count: overviewTableStore.selectedSamplesCount,
          })}
        </button>
      </ContentHeader>

      <div className='lots lots-index'>
        <table>
          <thead>
            <tr>
              <th className='lots-index--edit'></th>

              <th className='lots-index--delivery align-left'>
                {I18n.translate('lots.index.delivery')}
              </th>

              <th className='align-left'>ID</th>

              <th className='lots-index--weight align-right table-border'>
                {I18n.translate('lots.index.total')}
              </th>

              <th className='table-border'>{I18n.translate('lots.index.humidity')}</th>

              <th className='table-border'>
                {I18n.translate('lots.index.physical_anal')}
              </th>

              <th className='lots-index--roasting table-border'>
                {I18n.translate('lots.index.roasting')}
              </th>

              <th></th>
            </tr>
          </thead>

          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
    </form>
  );
}

export default observer(OverviewTable);
