import React from 'react';
import { observer } from 'mobx-react';

import Grid from 'components/elements/Grid';
import InputGroupComponent from 'components/groups/InputGroup';
import DefectWrapper from './DefectWrapper';
import PercentageAppendComponent from './PercentageAppendComponent';

import ExcelsoPhysicalAnalysis from '../models/ExcelsoPhysicalAnalysis';
import PeabiesPhysicalAnalysis from '../models/PeabiesPhysicalAnalysis';

export interface SecondGroupComponentProps {
  physicalAnalysis: ExcelsoPhysicalAnalysis | PeabiesPhysicalAnalysis;
}

const SecondGroupComponent = ({ physicalAnalysis }: SecondGroupComponentProps) => (
  <>
    <div className='fieldset'>
      <Grid grid={16}>
        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.parchment')}
            value={physicalAnalysis.parchment.grains}
            onChange={(e) =>
              physicalAnalysis.parchment.setGrains(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append='#'
            name='parchment_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.parchment.weight}
            onChange={(e) =>
              physicalAnalysis.parchment.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.parchment.weight
                )}
              />
            }
            name='parchment_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.broken')}
            value={physicalAnalysis.broken.grains}
            onChange={(e) => physicalAnalysis.broken.setGrains(parseInt(e.target.value))}
            selectTextOnFocus={true}
            append='#'
            name='broken_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.broken.weight}
            onChange={(e) => physicalAnalysis.broken.setWeight(parseInt(e.target.value))}
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.broken.weight
                )}
              />
            }
            name='broken_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.withered')}
            value={physicalAnalysis.withered.grains}
            onChange={(e) =>
              physicalAnalysis.withered.setGrains(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append='#'
            name='withered_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.withered.weight}
            onChange={(e) =>
              physicalAnalysis.withered.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.withered.weight
                )}
              />
            }
            name='withered_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.immature')}
            value={physicalAnalysis.immature.grains}
            onChange={(e) =>
              physicalAnalysis.immature.setGrains(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append='#'
            name='immature_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.immature.weight}
            onChange={(e) =>
              physicalAnalysis.immature.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.immature.weight
                )}
              />
            }
            name='immature_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.floater')}
            value={physicalAnalysis.floater.grains}
            onChange={(e) => physicalAnalysis.floater.setGrains(parseInt(e.target.value))}
            selectTextOnFocus={true}
            append='#'
            name='floater_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.floater.weight}
            selectTextOnFocus={true}
            onChange={(e) => physicalAnalysis.floater.setWeight(parseInt(e.target.value))}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.floater.weight
                )}
              />
            }
            name='floater_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.hull')}
            value={physicalAnalysis.hull.grains}
            onChange={(e) => physicalAnalysis.hull.setGrains(parseInt(e.target.value))}
            selectTextOnFocus={true}
            append='#'
            name='hull_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.hull.weight}
            onChange={(e) => physicalAnalysis.hull.setWeight(parseInt(e.target.value))}
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.hull.weight
                )}
              />
            }
            name='hull_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.shell')}
            value={physicalAnalysis.shell.grains}
            onChange={(e) => physicalAnalysis.shell.setGrains(parseInt(e.target.value))}
            selectTextOnFocus={true}
            append='#'
            name='shell_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.shell.weight}
            onChange={(e) => physicalAnalysis.shell.setWeight(parseInt(e.target.value))}
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.shell.weight
                )}
              />
            }
            name='shell_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>

        <DefectWrapper>
          <InputGroupComponent
            label={I18n.translate('qa_physical_analysis.slight_insect_damage')}
            value={physicalAnalysis.slight_insect_damage.grains}
            onChange={(e) =>
              physicalAnalysis.slight_insect_damage.setGrains(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append='#'
            name='slight_insect_damage_grains'
          />

          <InputGroupComponent
            value={physicalAnalysis.slight_insect_damage.weight}
            onChange={(e) =>
              physicalAnalysis.slight_insect_damage.setWeight(parseInt(e.target.value))
            }
            selectTextOnFocus={true}
            append={
              <PercentageAppendComponent
                percentage={physicalAnalysis.getWeightPercentageString(
                  physicalAnalysis.slight_insect_damage.weight
                )}
              />
            }
            name='slight_insect_damage_weight'
            type='number'
            max={physicalAnalysis.sampleWeight}
          />
        </DefectWrapper>
      </Grid>

      <Grid grid={9}>
        <InputGroupComponent
          label={I18n.translate('qa_physical_analysis.group_2')}
          value={physicalAnalysis.group2FullGrains}
          readOnly={true}
          disabled={true}
          append='#'
          name='group_2_grains'
          type='number'
        />

        <InputGroupComponent
          label={I18n.translate('qa_physical_analysis.group_2_weight')}
          value={physicalAnalysis.group2FullWeight}
          readOnly={true}
          disabled={true}
          append={
            <PercentageAppendComponent
              percentage={physicalAnalysis.getWeightPercentageString(
                physicalAnalysis.group2FullWeight
              )}
            />
          }
          name='group_2_weight'
          type='number'
        />

        <InputGroupComponent
          label={I18n.translate('qa_physical_analysis.full_defects_group_2')}
          value={physicalAnalysis.group2FullDefects.toFixed(2)}
          readOnly={true}
          disabled={true}
          name='full_defects_group_2'
          type='number'
        />
      </Grid>
    </div>
  </>
);

export default observer(SecondGroupComponent);
