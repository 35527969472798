import styled from 'styled-components';
import COLORS from 'constants/colors';

const OverflowContainer = styled.div`
  max-width: 100vw;
  height: calc(100vh - 3rem);
  position: relative;
  background: ${COLORS.LIGHT_GRAY};
`;

export default OverflowContainer;
