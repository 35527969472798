import React from 'react';
import { ISpotSampleIco } from './useSpotSampleIcos';

const IcoInfos = ({ ico }: { ico: ISpotSampleIco }): JSX.Element => {
  return (
    <>
      <p>
        <b>{I18n.translate('activerecord.models.internal_contract')}:</b>{' '}
        {ico.contract?.main_identifier}
      </p>
      <p>
        <b>{I18n.translate('activerecord.models.quality')}:</b> {ico.quality?.code}
      </p>
      <p>
        <b>{I18n.translate('activerecord.models.mark')}:</b> {ico.mark?.name}
      </p>
      <p>
        <b>{I18n.translate('activerecord.models.packaging_type')}:</b>{' '}
        {ico.packaging_type?.name}
      </p>
      {ico.bin && (
        <p>
          <b>{I18n.translate('attributes.bin')}</b> {ico.bin}
        </p>
      )}
      <br />
    </>
  );
};
export default IcoInfos;
