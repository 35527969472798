import React from 'react';

import LogEntry from 'millings/productionLog/models/LogEntry';

interface ProductionObservationProps {
  entry: LogEntry;
}

const ProductionLogObservations = ({
  entry: {
    setObservations,
    attributes: { observations },
  },
}: ProductionObservationProps) => {
  if (observations) {
    return <span>{observations}</span>;
  }

  return <textarea rows={3} onChange={(e) => setObservations(e.target.value)} />;
};

export default ProductionLogObservations;
