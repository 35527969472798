import React from 'react';

import ContractTraceabilityHeader from '../components/ContractTraceabilityHeader';
import DeliveryOrderList from './DeliveryOrderList';
import {
  GeneralContractTraceability,
  InteralContractTraceability,
} from '../types/ContractTraceability';
import MainContractDetails from './MainContractDetails';
import SpotContractList from './SpotContractList';

export interface ContractTraceabilityProps {
  traceability: GeneralContractTraceability | InteralContractTraceability;
}

function ContractTraceability({ traceability }: ContractTraceabilityProps) {
  const [collapsed, setCollapsed] = React.useState(true);

  const onCollapsibleHandler = () => setCollapsed(!collapsed);

  return (
    <div>
      <ContractTraceabilityHeader
        traceability={traceability}
        collapsed={collapsed}
        onCollapsibleHandler={onCollapsibleHandler}
      />

      {!collapsed && <MainContractDetails traceability={traceability} />}

      {traceability.type === 'contract' && !collapsed && (
        <DeliveryOrderList deliveryOrders={traceability.delivery_orders} />
      )}

      {traceability.type === 'internal_contract' && !collapsed && (
        <SpotContractList spotContracts={traceability.spot_contracts} />
      )}
    </div>
  );
}

export default ContractTraceability;
