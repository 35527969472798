import { action, computed, observable } from 'mobx';
import { deserialise } from 'kitsu-core';

import CheckboxGroup from '../models/CheckboxGroup';
import RadioGroup from '../models/RadioGroup';
import SensorialAnalysis from '../models/SensorialAnalysis';

import { DefectGet } from 'types/model/defect';
import { DescriptorBodyGet } from 'types/model/descriptorBody';
import { DescriptorFlavourGet } from 'types/model/descriptorFlavour';
import { DescriptorOverallGet } from 'types/model/descriptorOverall';
import { DescriptorRoastGet } from 'types/model/descriptorRoast';
import { AlternativeGet } from 'types/model/alternative';
import Conclusion from '../models/Conclusion';
import SelectOptionArray from 'types/model/selectOption';
import ISample from 'cuppingSession/types/ISample';

interface Choices {
  descriptorFlavours: DescriptorFlavourGet[];
  descriptorOveralls: DescriptorOverallGet[];
  descriptorBodies: DescriptorBodyGet[];
  descriptorRoasts: DescriptorRoastGet[];
  defects: DefectGet[];
  alternatives: AlternativeGet[];
  reasons: SelectOptionArray;
}

class CuppingSessionStore {
  private samples: ISample[] = [];
  public sensorialAnalyses = observable.array<SensorialAnalysis>();
  public cuppingSessionEnabled = false;
  public cuppingTableId: string;
  @observable public cuppingCardIndex: number;
  @observable public overlayIndex: number;

  constructor(
    samples: ISample[],
    cuppingSessionEnabled: boolean,
    cuppingTableId: string,
    {
      defects,
      alternatives,
      descriptorFlavours,
      descriptorOveralls,
      descriptorBodies,
      descriptorRoasts,
      reasons,
    }: Choices
  ) {
    this.samples = deserialise(samples).data;
    this.cuppingSessionEnabled = cuppingSessionEnabled;
    this.cuppingTableId = cuppingTableId;

    const sensorialAnalyses = this.samples.map((sample) => {
      const current_attributes =
        sample?.current_sensorial_analyses?.data?.attributes || {};
      const {
        selected_defects = [],
        selected_flavours = [],
        selected_alternatives = [],
      } = current_attributes;

      return new SensorialAnalysis(sample, {
        conclusion: new Conclusion(reasons),
        defects: new CheckboxGroup<any>(defects, selected_defects),
        descriptorBodies: new RadioGroup(descriptorBodies),
        descriptorFlavours: new CheckboxGroup<DescriptorFlavourGet>(
          descriptorFlavours,
          selected_flavours
        ),
        descriptorOveralls: new RadioGroup(descriptorOveralls),
        descriptorRoasts: new RadioGroup(descriptorRoasts),
        alternatives: new CheckboxGroup<any>(alternatives, selected_alternatives),
      });
    });

    this.sensorialAnalyses = observable.array(sensorialAnalyses);
    this.cuppingCardIndex = -1;
    this.overlayIndex = -1;
  }

  @action public selectOverlay = (cuppingCardIndex: number, overlayIndex: number) => {
    this.cuppingCardIndex = cuppingCardIndex;
    this.overlayIndex = overlayIndex;
  };

  @action public deselectOverlay = () => {
    this.cuppingCardIndex = -1;
    this.overlayIndex = -1;
  };

  @action public selectNextOverlay = (currentIndex: number) => {
    let nextIndex = currentIndex + 1;
    if (nextIndex === this.sensorialAnalyses.length + 1) {
      nextIndex = 1;
    }

    if (this.sensorialAnalyses[nextIndex - 1].completed) {
      this.selectNextOverlay(nextIndex);
    } else {
      this.cuppingCardIndex = nextIndex;
    }
  };

  @action public selectPrevOverlay = (currentIndex: number) => {
    let prevIndex = currentIndex - 1;
    if (prevIndex === 0) {
      prevIndex = this.sensorialAnalyses.length;
    }

    if (this.sensorialAnalyses[prevIndex - 1].completed) {
      this.selectPrevOverlay(prevIndex);
    } else {
      this.cuppingCardIndex = prevIndex;
    }
  };

  @computed get sampleIdsForFullScreenViewLink(): string {
    return this.samples.map((l) => l.id).join(',');
  }

  @computed get allAnalysisCompleted(): boolean {
    return (
      this.sensorialAnalyses.filter((sensorialAnalysis) => !sensorialAnalysis.completed)
        .length === 0
    );
  }

  @computed get oneAnalysisLeft(): boolean {
    return (
      this.sensorialAnalyses.filter((sensorialAnalysis) => !sensorialAnalysis.completed)
        .length === 1
    );
  }
}

export default CuppingSessionStore;
