import React from 'react';
import styled from 'styled-components';

import type AssignedCoffeeType from '../types/assignedCoffee';

import AssignedCoffee from './AssignedCoffee';

interface TraceabilityBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  collapsed: boolean;
  assignedCoffees: AssignedCoffeeType[];
}

const TraceabilityBody: React.FC<TraceabilityBodyProps> = ({
  assignedCoffees,
  className,
}) => (
  <div className={className}>
    {assignedCoffees.map((assignedCoffee) => (
      <AssignedCoffee key={assignedCoffee.id} assignedCoffee={assignedCoffee} />
    ))}
  </div>
);

export default styled(TraceabilityBody)`
  margin: ${(props) => (!props.collapsed ? '0.42em 0 2.5em' : 0)};
`;
