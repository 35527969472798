import React, { useState } from 'react';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';

import ConfirmationOverlay from 'shared/ConfirmationOverlay';
import { addTestingIdentifier } from 'utils/testing';

export interface ConfirmFinalisedFractionProps {
  id: number;
}

const ConfirmFinalisedFractionComponent: React.FC<ConfirmFinalisedFractionProps> = ({
  id,
}) => {
  const [showOverlay, setOverlay] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleFinalizeClick = () => {
    setOverlay(true);
  };

  const handleOnPrimaryClick = () => {
    setSubmitted(true);
    if (!submitted) {
      customFetch(Routes.fractionate_milling_order_path(id), {}, 'PATCH').then(() => {
        window.location.href = Routes.milling_orders_path();
      });
    }
  };

  const handleOnSecondaryClick = () => setOverlay(false);

  return (
    <>
      {showOverlay && (
        <ConfirmationOverlay
          title={I18n.translate('fractions.confirm')}
          confirmText={I18n.translate('buttons.confirm')}
          onConfirmClick={handleOnPrimaryClick}
          cancelText={I18n.translate('buttons.cancel')}
          primaryDisabled={submitted}
          onCancelClick={handleOnSecondaryClick}
        />
      )}

      <button
        onClick={handleFinalizeClick}
        type='button'
        className='button'
        {...addTestingIdentifier('finalize_fraction_button')}
      >
        {I18n.translate('buttons.finalize')}
      </button>
    </>
  );
};

export default ConfirmFinalisedFractionComponent;
