import React from 'react';
import Routes from 'routes';

import Icon from 'components/elements/Icon';
import { WetLot } from 'types/model/lot';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import STATUS from 'types/utils/status';
import StatusText from 'shared/StatusText';

interface BaseWetInfoProps {
  lot: WetLot;
}

const BaseWetInfo = ({ lot }: BaseWetInfoProps) => {
  const wetPhysicalAnalysisPath = lot.has_physical
    ? Routes.edit_lot_wet_physical_analysis_path
    : Routes.new_lot_wet_physical_analysis_path;

  return (
    <>
      <td className='lots-index--edit align-center'>
        <a href={Routes.edit_lot_path(lot.id)} data-cy='edit_lot'>
          <Icon icon='pen' />
        </a>
      </td>
      <td>
        <TextWithSub
          text={lot.local_identifier}
          textClass={'lot--id'}
          sub={I18n.l('time.formats.short', lot.received_in_warehouse_at)}
        />
      </td>
      <td className='lots-index--weight align-right table-border'>{lot.weight_string}</td>
      <td className='align-center table-border'>
        <a href={wetPhysicalAnalysisPath(lot.id)}>
          {(lot.has_physical && (
            <StatusText status={lot.passed_physical ? STATUS.OK : STATUS.FAILED}>
              {I18n.translate(
                `shared.status.${lot.passed_physical ? 'passed' : 'failed'}`
              )}
            </StatusText>
          )) || (
            <div className='button button--small button--ghost button--block'>
              {I18n.translate('widgets.lot_status_table.analyse')}
            </div>
          )}
        </a>
      </td>
    </>
  );
};

export default BaseWetInfo;
